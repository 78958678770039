import React from 'react';
import { Modal } from 'react-bootstrap';
import './MoneylineProbabilityModal.css';

const MoneylineProbabilityModal = ({ show, onHide, team, odds }) => {
    const calculateProbability = (odds) => {
        if (!odds) return 0;
        
        // Convert American odds to probability
        if (odds > 0) {
            return (100 / (odds + 100)) * 100;
        } else {
            return (Math.abs(odds) / (Math.abs(odds) + 100)) * 100;
        }
    };

    const probability = calculateProbability(odds);

    return (
        <Modal show={show} onHide={onHide} centered className="probability-modal">
            <Modal.Header closeButton>
                <Modal.Title>Win Probability</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="probability-container">
                    <div className="team-probability">
                        <h5>{team} {odds > 0 ? '+' : ''}{odds}</h5>
                        <div className="prob-details">
                            <div className="prob-row">
                                <span>Implied Win Probability:</span>
                                <span>{probability.toFixed(1)}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MoneylineProbabilityModal; 
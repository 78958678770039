import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Form, Table, Button, Spinner, Alert, OverlayTrigger, Tooltip, Badge, Dropdown } from 'react-bootstrap';
import './OddsDisplay.css';
import PlayerPropsModal from './PlayerPropsModal';
import MoneylineProbabilityModal from './MoneylineProbabilityModal';
import SpreadProbabilityModal from './SpreadProbabilityModal';
import TotalProbabilityModal from './TotalProbabilityModal';
import { API_BASE_URL, KNOWN_API_ENDPOINTS, isNbaApiOffHours } from '../config/constants';
import { useAuth } from '../contexts/AuthContext';
import { BsArrowClockwise, BsExclamationTriangle, BsInfoCircle, BsExclamationTriangleFill, BsExclamationCircle } from 'react-icons/bs';
import { firestore } from '../config/firebase';
import { collection, addDoc, serverTimestamp, increment, doc, updateDoc } from 'firebase/firestore';
import { OddsContext } from '../App';
import { useUserActivity } from '../contexts/UserActivityContext';

// Add timezone helper
const formatInEST = (date) => {
    return new Date(date).toLocaleString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
};

// Add this helper function to calculate hold percentage
const calculateHoldPercentage = (odds) => {
    // Convert American odds to implied probabilities
    const convertOddsToProb = (americanOdds) => {
        if (!americanOdds) return 0;
        if (americanOdds > 0) {
            return 100 / (americanOdds + 100);
        } else {
            return (-americanOdds) / (-americanOdds + 100);
        }
    };

    const homeProb = convertOddsToProb(odds.moneyline.home);
    const awayProb = convertOddsToProb(odds.moneyline.away);
    
    // Calculate hold percentage
    const holdPercentage = ((homeProb + awayProb) - 1) * 100;
    return holdPercentage.toFixed(1);
};

// Add this helper function to get hold percentage styling
const getHoldStyle = (holdPercentage) => {
    const hold = parseFloat(holdPercentage);
    if (hold <= 2) return 'excellent-hold';  // Green
    if (hold <= 4) return 'good-hold';       // Light green
    if (hold <= 6) return 'fair-hold';       // Yellow
    return 'high-hold';                      // Red
};

// Add this helper function to get hold percentage tooltip text
const getHoldTooltip = (holdPercentage) => {
    const hold = parseFloat(holdPercentage);
    if (hold <= 2) return "Excellent value (≤2% hold) - Very favorable odds with minimal bookmaker margin";
    if (hold <= 4) return "Good value (2-4% hold) - Favorable odds with reasonable bookmaker margin";
    if (hold <= 6) return "Fair value (4-6% hold) - Average odds with standard bookmaker margin";
    return "High hold (>6%) - Less favorable odds with higher bookmaker margin";
};

// Helper function to get the appropriate class for API info display based on count
const getApiInfoClass = (count) => {
    if (count < 10) return 'danger';
    if (count < 50) return 'warning';
    if (count < 250) return 'caution'; // 25% of 1000 requests
    return '';
};

// Helper to get the most recent API request count
const getCurrentApiCount = () => {
    const storedCount = localStorage.getItem('apiRequestsRemaining');
    return storedCount ? parseInt(storedCount, 10) : null;
};

// Helper function to filter games by date
const filterGamesByDate = (games, selectedDate) => {
    if (!games || !Array.isArray(games)) {
        return [];
    }
    
    // Create date range in EST
    const startDate = new Date(`${selectedDate}T00:00:00-05:00`);
    const endDate = new Date(`${selectedDate}T23:59:59-05:00`);
    
    return games.filter(game => {
        const gameDate = new Date(game.commence_time);
        return gameDate >= startDate && gameDate <= endDate;
    });
};

const OddsDisplay = ({ applyColor }) => {
    const { isAdmin, user } = useAuth();
    const { isActive } = useUserActivity();
    
    // Use the context instead of local state
    const {
        odds, setOdds,
        loading: oddsLoading, setLoading: setOddsLoading,
        error: oddsError, setError: setOddsError,
        date, setDate,
        hasLoaded, setHasLoaded,
        fetchOdds,
        requestsRemaining, setRequestsRemaining
    } = useContext(OddsContext);
    
    // Keep local state for UI-specific items
    const [showProps, setShowProps] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null);
    const [propsData, setPropsData] = useState(null);
    const [loadingProps, setLoadingProps] = useState(false);
    const [showMoneylineModal, setShowMoneylineModal] = useState(false);
    const [selectedMoneyline, setSelectedMoneyline] = useState(null);
    const [showSpreadModal, setShowSpreadModal] = useState(false);
    const [showTotalModal, setShowTotalModal] = useState(false);
    const [selectedSpread, setSelectedSpread] = useState(null);
    const [selectedTotal, setSelectedTotal] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(() => {
        // Only initialize from localStorage if user is admin
        if (isAdmin) {
            const savedAutoRefresh = localStorage.getItem('oddsAutoRefresh');
            return savedAutoRefresh === 'true';
        }
        return false;
    });
    const [countdown, setCountdown] = useState(45);
    const REFRESH_INTERVAL = 45; // seconds
    const [lastFetchDate, setLastFetchDate] = useState(() => {
        const savedDate = localStorage.getItem('oddsLastFetchDate');
        return savedDate || null;
    });
    const [currentApiEndpoint, setCurrentApiEndpoint] = useState(() => {
        // Initialize with Production API for most reliable access
        if (process.env.NODE_ENV === 'development' && KNOWN_API_ENDPOINTS.local) {
            return KNOWN_API_ENDPOINTS.local;
        }
        return KNOWN_API_ENDPOINTS.production;
    });
    const [failedEndpoints, setFailedEndpoints] = useState(new Set());

    // Add rate limiting state and constants
    const REFRESH_LIMIT = 10; // Maximum refreshes per hour for non-admin users
    const REFRESH_WINDOW = 60 * 60 * 1000; // 1 hour in milliseconds

    // Function to check if user can refresh odds
    const canRefreshOdds = () => {
        if (isAdmin) return true; // Admins have unlimited refreshes

        const refreshHistory = JSON.parse(localStorage.getItem('oddsRefreshHistory') || '[]');
        const now = Date.now();
        
        // Filter out old refresh attempts
        const recentRefreshes = refreshHistory.filter(time => now - time < REFRESH_WINDOW);
        
        // Update localStorage with filtered history
        localStorage.setItem('oddsRefreshHistory', JSON.stringify(recentRefreshes));
        
        // Check if user has exceeded the limit
        return recentRefreshes.length < REFRESH_LIMIT;
    };

    // Function to record a refresh attempt
    const recordRefreshAttempt = () => {
        if (isAdmin) return; // Don't record admin refreshes
        
        const refreshHistory = JSON.parse(localStorage.getItem('oddsRefreshHistory') || '[]');
        refreshHistory.push(Date.now());
        localStorage.setItem('oddsRefreshHistory', JSON.stringify(refreshHistory));
    };

    // Function to get remaining refreshes
    const getRemainingRefreshes = () => {
        if (isAdmin) return '∞'; // Admins have unlimited refreshes

        const refreshHistory = JSON.parse(localStorage.getItem('oddsRefreshHistory') || '[]');
        const now = Date.now();
        const recentRefreshes = refreshHistory.filter(time => now - time < REFRESH_WINDOW);
        return Math.max(0, REFRESH_LIMIT - recentRefreshes.length);
    };

    // Function to get time until next refresh is available
    const getTimeUntilNextRefresh = () => {
        if (isAdmin) return 0; // Admins don't need to wait

        const refreshHistory = JSON.parse(localStorage.getItem('oddsRefreshHistory') || '[]');
        if (refreshHistory.length === 0) return 0;

        const oldestRefresh = Math.min(...refreshHistory);
        const timeElapsed = Date.now() - oldestRefresh;
        return Math.max(0, REFRESH_WINDOW - timeElapsed);
    };

    const tryNextEndpoint = useCallback(() => {
        const availableEndpoints = Object.values(KNOWN_API_ENDPOINTS)
            .filter(endpoint => endpoint && !failedEndpoints.has(endpoint));

        if (availableEndpoints.length > 0) {
            const nextEndpoint = availableEndpoints[0];
            console.log('Switching to next endpoint:', nextEndpoint);
            setCurrentApiEndpoint(nextEndpoint);
            return true;
        }
        return false;
    }, [failedEndpoints]);

    // Update the effect to handle auto-refresh with the context's fetchOdds
    useEffect(() => {
        if (!autoRefresh || !isActive) return; // Don't refresh if auto-refresh is off or user is inactive

        const countdownInterval = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    if (isActive) { // Only fetch if user is active
                        fetchOdds(date);
                    }
                    return REFRESH_INTERVAL;
                }
                return prev - 1;
            });
        }, 1000);

        // Initial fetch on enable
        if (autoRefresh && isActive) {
            fetchOdds(date);
        }

        return () => clearInterval(countdownInterval);
    }, [autoRefresh, fetchOdds, date, isActive]);

    const toggleAutoRefresh = () => {
        const newValue = !autoRefresh;
        setAutoRefresh(newValue);
        localStorage.setItem('oddsAutoRefresh', newValue.toString());
        
        if (newValue) {
            setCountdown(REFRESH_INTERVAL);
            // Force an immediate fetch when turning on
            fetchOdds(date);
        }
    };

    const handlePlayerProps = async (game) => {
        // Check if we're in the off-hours period
        if (isNbaApiOffHours()) {
            console.log("Skipping props API call during NBA off-hours (1am-6am EST)");
            return;
        }
        
        setLoadingProps(true);
        setSelectedGame(game);
        
        try {
            console.log(`Fetching player props for game ID: ${game.id}`);
            const response = await fetch(`${API_BASE_URL}/api/player-props/${game.id}`, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            
            // Log detailed information about the response structure
            console.log('Props data received:', data);
            
            // Check if we have the expected data structure
            console.log('Data structure:', {
                hasBookmakers: Boolean(data.data.bookmakers),
                bookmakerCount: data.data.bookmakers?.length || 0,
                hasMarketEfficiency: Boolean(data.data.market_efficiency),
                hasFanduelRecommendations: Boolean(data.data.fanduel_recommendations)
            });
            
            if (data.data.market_efficiency) {
                console.log('Market efficiency data:', data.data.market_efficiency);
            }
            
            if (data.data.bookmakers && data.data.bookmakers.length > 0) {
                // Log each bookmaker and their markets
                data.data.bookmakers.forEach(bookmaker => {
                    console.log(`Bookmaker: ${bookmaker.title}, Markets: ${bookmaker.markets?.length || 0}`);
                    
                    if (bookmaker.markets && bookmaker.markets.length > 0) {
                        // Log market types
                        const marketTypes = [...new Set(bookmaker.markets.map(m => m.key))];
                        console.log(`Market types: ${marketTypes.join(', ')}`);
                    }
                });
            } else {
                console.warn('No bookmakers data available in the response');
            }
            
            setPropsData(data);
            if (data.requests_remaining !== undefined) {
                updateApiCount(data.requests_remaining);
            }
        } catch (error) {
            console.error('Error fetching player props:', error);
            setPropsData(null);
        } finally {
            setLoadingProps(false);
        }
    };

    const refreshProps = async (game) => {
        // Check if we're in the off-hours period
        if (isNbaApiOffHours()) {
            console.log("Skipping props API call during NBA off-hours (1am-6am EST)");
            return;
        }
        
        setLoadingProps(true);
        try {
            console.log(`Refreshing player props for game ID: ${game.id}`);
            const response = await fetch(`${API_BASE_URL}/api/player-props/${game.id}`, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            console.log('Refreshed props data received:', data);
            
            // Check if we have the expected data structure
            console.log('Data structure:', {
                hasBookmakers: Boolean(data.data.bookmakers),
                bookmakerCount: data.data.bookmakers?.length || 0,
                hasMarketEfficiency: Boolean(data.data.market_efficiency),
                hasFanduelRecommendations: Boolean(data.data.fanduel_recommendations)
            });
            
            // Update the props data state
            setPropsData(data);
            if (data.requests_remaining !== undefined) {
                updateApiCount(data.requests_remaining);
            }
        } catch (error) {
            console.error('Error refreshing player props:', error);
            setPropsData(null);
        } finally {
            setLoadingProps(false);
        }
    };

    const formatOdds = (odds) => {
        if (odds === null || odds === undefined) return '-';
        return odds > 0 ? `+${odds}` : odds;
    };

    const getOddsClass = (odds) => {
        if (odds === null || odds === undefined) return '';
        return odds > 0 ? 'positive-odds' : 'negative-odds';
    };

    const formatGameTime = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/New_York',
            timeZoneName: 'short'
        });
    };

    const getOddsColor = (odds) => {
        if (!odds || odds === '-') return '#666'; // Gray for no odds
        // Convert odds to number and check if positive/negative
        const oddsNum = parseInt(odds);
        return oddsNum > 0 ? '#28a745' : '#dc3545'; // Green for positive, Red for negative
    };

    const processGameOdds = (game) => {
        console.log('Processing game odds:', game);
        
        if (!game || !game.bookmakers || game.bookmakers.length === 0) {
            console.warn('No bookmakers data for game:', game);
            return {
                moneyline: { home: null, away: null },
                spread: { 
                    home: { point: null, price: null },
                    away: { point: null, price: null }
                },
                total: {
                    over: { point: null, price: null },
                    under: { point: null, price: null }
                }
            };
        }

        // Get the first bookmaker's odds
        const bookmaker = game.bookmakers[0];
        console.log('Using bookmaker:', bookmaker);

        const markets = bookmaker.markets || [];
        console.log('Available markets:', markets);

        // Find the relevant markets
        const moneylineMarket = markets.find(m => m.key === 'h2h') || {};
        const spreadMarket = markets.find(m => m.key === 'spreads') || {};
        const totalMarket = markets.find(m => m.key === 'totals') || {};

        // Process the odds
        const processed = {
            moneyline: {
                home: moneylineMarket.outcomes?.find(o => o.name === game.home_team)?.price,
                away: moneylineMarket.outcomes?.find(o => o.name === game.away_team)?.price
            },
            spread: {
                home: {
                    point: spreadMarket.outcomes?.find(o => o.name === game.home_team)?.point,
                    price: spreadMarket.outcomes?.find(o => o.name === game.home_team)?.price
                },
                away: {
                    point: spreadMarket.outcomes?.find(o => o.name === game.away_team)?.point,
                    price: spreadMarket.outcomes?.find(o => o.name === game.away_team)?.price
                }
            },
            total: {
                over: {
                    point: totalMarket.outcomes?.find(o => o.name === 'Over')?.point,
                    price: totalMarket.outcomes?.find(o => o.name === 'Over')?.price
                },
                under: {
                    point: totalMarket.outcomes?.find(o => o.name === 'Under')?.point,
                    price: totalMarket.outcomes?.find(o => o.name === 'Under')?.price
                }
            }
        };

        console.log('Processed odds:', processed);
        return processed;
    };

    const handleMoneylineClick = (team, odds) => {
        console.log('Moneyline clicked:', team, odds); // Debug log
        setSelectedMoneyline({
            team: team,
            odds: parseInt(odds) // Ensure odds is a number
        });
        setShowMoneylineModal(true);
    };

    const handleSpreadClick = (team, spread, odds) => {
        console.log('Spread clicked:', team, spread, odds); // Debug log
        setSelectedSpread({
            team: team,
            spread: parseFloat(spread),
            odds: parseInt(odds)
        });
        setShowSpreadModal(true);
    };

    const handleTotalClick = (type, point, odds) => {
        console.log('Total clicked:', type, point, odds); // Debug log
        setSelectedTotal({
            type: type,
            point: parseFloat(point),
            odds: parseInt(odds)
        });
        setShowTotalModal(true);
    };

    useEffect(() => {
        if (selectedMoneyline) {
            console.log('Selected Moneyline:', selectedMoneyline);
        }
        if (selectedSpread) {
            console.log('Selected Spread:', selectedSpread);
        }
        if (selectedTotal) {
            console.log('Selected Total:', selectedTotal);
        }
    }, [selectedMoneyline, selectedSpread, selectedTotal]);

    // Add date change handler with logging
    const handleDateChange = async (event) => {
        const newDate = event.target.value;
        console.log('\n=== Date Change ===');
        console.log('Old date:', date);
        console.log('New date:', newDate);
        setDate(newDate);
        
        // Only fetch if user is authenticated and not during off-hours
        if (user) {
            console.log("User is authenticated, fetching data directly for new date:", newDate);
            
            // Check if we're in the off-hours period
            if (isNbaApiOffHours()) {
                console.log("Skipping API call during NBA off-hours (1am-6am EST)");
                return;
            }
            
            setHasLoaded(false); // Reset the loaded status to force a data refresh
            
            try {
                setOddsLoading(true);
                const url = `${API_BASE_URL}/api/odds?date=${newDate}`;
                
                console.log("Directly fetching odds for date:", newDate);
                const response = await fetch(url, {
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json'
                    }
                });
                
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
                }
                
                const responseData = await response.json();
                
                // Handle both array and object response formats
                let oddsData;
                if (Array.isArray(responseData)) {
                    oddsData = responseData;
                } else if (responseData.data && Array.isArray(responseData.data)) {
                    oddsData = responseData.data;
                    // Update remaining requests if available
                    if (responseData.remaining_requests) {
                        const remaining = parseInt(responseData.remaining_requests);
                        updateApiCount(remaining);
                    }
                } else {
                    throw new Error('Unexpected API response format');
                }
                
                // Filter games for selected date
                const filteredOddsForDate = filterGamesByDate(oddsData, newDate);
                
                setOdds(filteredOddsForDate);
                
            } catch (error) {
                console.error('Error directly fetching odds:', error);
                setOddsError(error.message || 'Failed to fetch odds data');
            } finally {
                setOddsLoading(false);
            }
        } else {
            console.log("User not authenticated, skipping fetch on date change");
        }
    };

    // Add debug logging for odds state
    useEffect(() => {
        console.log('Current odds state:', odds);
        if (odds && odds.length > 0) {
            console.log('First game commence time:', new Date(odds[0].commence_time).toLocaleString());
        }
    }, [odds]);

    const handleShowProps = async (gameId) => {
        // Find the game object from the odds array
        const game = odds.find(g => g.id === gameId);
        if (!game) {
            console.error('Game not found for ID:', gameId);
            return;
        }

        // Set the state variables to open the modal
        setSelectedGame(game);
        setShowProps(true);
        setLoadingProps(true);
        
        try {
            console.log(`Fetching player props for game ID: ${gameId}`);
            const response = await fetch(`${API_BASE_URL}/api/player-props/${gameId}`, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });
            
            if (!response.ok) {
                throw new Error(`Error fetching props: ${response.status}`);
            }
            
            const data = await response.json();
            console.log('Props data received:', data);
            
            // Check if we have the expected data structure
            console.log('Data structure:', {
                hasBookmakers: Boolean(data.data.bookmakers),
                bookmakerCount: data.data.bookmakers?.length || 0,
                hasMarketEfficiency: Boolean(data.data.market_efficiency),
                hasFanduelRecommendations: Boolean(data.data.fanduel_recommendations)
            });
            
            if (data.data.market_efficiency) {
                console.log('Market efficiency data:', data.data.market_efficiency);
            }
            
            setPropsData(data);
            if (data.requests_remaining !== undefined) {
                updateApiCount(data.requests_remaining);
            }
        } catch (error) {
            console.error('Error fetching props:', error);
            setPropsData(null);
        } finally {
            setLoadingProps(false);
        }
    };

    const DebugView = ({ gameId }) => {
        const [debugData, setDebugData] = useState(null);

        useEffect(() => {
            const fetchDebugData = async () => {
                try {
                    const response = await fetch(`${API_BASE_URL}/api/debug/redis/${gameId}`, {
                        credentials: 'same-origin',
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
                    const data = await response.json();
                    setDebugData(data);
                } catch (error) {
                    console.error('Debug fetch error:', error);
                }
            };

            fetchDebugData();
            // Refresh every 30 seconds, but only if user is active
            const interval = setInterval(() => {
                if (isActive) {
                    fetchDebugData();
                }
            }, 30000);
            return () => clearInterval(interval);
        }, [gameId, isActive]);

        if (!debugData) return null;

        return (
            <div className="debug-panel">
                <h4>Debug Info</h4>
                <div>Snapshots: {debugData.snapshot_count}</div>
                <div>Redis Connected: {debugData.redis_connected ? '✅' : '❌'}</div>
                {debugData.newest && (
                    <div>
                        <h5>Latest Snapshot:</h5>
                        <pre>{JSON.stringify(debugData.newest, null, 2)}</pre>
                    </div>
                )}
            </div>
        );
    };

    const switchApiEndpoint = (endpoint) => {
        console.log(`Switching API endpoint to: ${endpoint}`);
        if (!endpoint) {
            console.error('Invalid endpoint provided');
            return;
        }
        setCurrentApiEndpoint(endpoint);
        // Clear failed endpoints when manually switching
        setFailedEndpoints(new Set());
        // Refetch data with new endpoint
        fetchOdds(date).catch(error => {
            console.error('Error fetching odds with new endpoint:', error);
        });
    };

    const getCurrentEndpointLabel = useCallback(() => {
        if (currentApiEndpoint.includes('localhost')) return 'Local';
        return 'Production';
    }, [currentApiEndpoint]);

    // Render API endpoint switcher for admin users
    const renderApiEndpointSwitcher = () => {
        if (!isAdmin) return null;
        
        return (
            <div className="api-endpoint-switcher">
                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" size="sm" id="api-endpoint-dropdown">
                        API: {getCurrentEndpointLabel()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item 
                            onClick={() => switchApiEndpoint(KNOWN_API_ENDPOINTS.production)}
                            active={currentApiEndpoint === KNOWN_API_ENDPOINTS.production}
                        >
                            Production API
                        </Dropdown.Item>
                        {process.env.NODE_ENV === 'development' && (
                        <Dropdown.Item 
                                onClick={() => switchApiEndpoint(KNOWN_API_ENDPOINTS.local)}
                            active={currentApiEndpoint.includes('localhost')}
                        >
                            Local Development
                        </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    // Modify the initial load effect
    useEffect(() => {
        console.log("OddsDisplay initial load check");
        console.log("Current hasLoaded status:", hasLoaded);
        console.log("Authentication status:", user ? "User is logged in" : "User is not logged in");
        
        // Don't automatically fetch on initial load
        // The data will only be loaded when user changes date or clicks refresh
    }, [user]);

    // Create a refresh handler function
    const handleRefresh = async () => {
        console.log("Manual refresh requested");
        
        // Check rate limit before proceeding
        if (!canRefreshOdds()) {
            const timeUntilNext = getTimeUntilNextRefresh();
            const minutesUntilNext = Math.ceil(timeUntilNext / (60 * 1000));
            setOddsError(`Rate limit reached. Please wait ${minutesUntilNext} minutes before refreshing again.`);
            return;
        }
        
        // Only fetch if user is authenticated and not during off-hours
        if (user) {
            // Check if we're in the off-hours period
            if (isNbaApiOffHours()) {
                console.log("Skipping API call during NBA off-hours (1am-6am EST)");
                return;
            }
            
            console.log("User is authenticated, fetching odds data directly");
            setHasLoaded(false); // Reset the loaded status
            
            try {
                setOddsLoading(true);
                const url = `${API_BASE_URL}/api/odds?date=${date}`;
                
                console.log("Directly fetching odds for date:", date);
                const response = await fetch(url, {
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json'
                    }
                });
                
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
                }
                
                const responseData = await response.json();
                
                // Handle both array and object response formats
                let oddsData;
                if (Array.isArray(responseData)) {
                    oddsData = responseData;
                } else if (responseData.data && Array.isArray(responseData.data)) {
                    oddsData = responseData.data;
                    // Update remaining requests if available
                    if (responseData.remaining_requests) {
                        const remaining = parseInt(responseData.remaining_requests);
                        updateApiCount(remaining);
                    }
                } else {
                    throw new Error('Unexpected API response format');
                }
                
                // Filter games for selected date
                const filteredOddsForCurrentDate = filterGamesByDate(oddsData, date);
                
                setOdds(filteredOddsForCurrentDate);
                
                // Record successful refresh attempt
                recordRefreshAttempt();
                
            } catch (error) {
                console.error('Error directly fetching odds:', error);
                setOddsError(error.message || 'Failed to fetch odds data');
            } finally {
                setOddsLoading(false);
            }
        } else {
            console.log("User not authenticated in OddsDisplay component");
        }
    };

    // Add refresh limit display to the header
    const renderRefreshLimitInfo = () => {
        if (isAdmin) return null; // Don't show limit info for admins
        
        const remaining = getRemainingRefreshes();
        const timeUntilNext = getTimeUntilNextRefresh();
        
        if (timeUntilNext > 0) {
            const minutesUntilNext = Math.ceil(timeUntilNext / (60 * 1000));
            return (
                <div className="api-info warning">
                    <i className="fas fa-clock me-2"></i>
                    {remaining} refreshes remaining. Next refresh in {minutesUntilNext} minutes
                </div>
            );
        }
        
        return (
            <div className="api-info">
                <i className="fas fa-sync me-2"></i>
                {remaining} refreshes remaining this hour
            </div>
        );
    };

    // Effect to update API requests remaining whenever it changes in localStorage
    useEffect(() => {
        const handleStorageChange = () => {
            const currentCount = getCurrentApiCount();
            if (currentCount !== null && currentCount !== requestsRemaining) {
                // This is inside a special effect handler, so we keep the direct state update
                setRequestsRemaining(currentCount);
            }
        };
        
        // Check on mount and whenever the component re-renders
        handleStorageChange();
        
        // Also listen for storage events (in case another component updates localStorage)
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [requestsRemaining]);

    // When updating the requestsRemaining in the component:
    const updateApiCount = (remaining) => {
        // Update localStorage
        localStorage.setItem('apiRequestsRemaining', remaining);
        // Update component state
        setRequestsRemaining(remaining);
    };

    if (oddsLoading) {
        return (
            <div className="loading-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div className="odds-container">
            <div className="odds-header">
                <div className="header-left">
                    <h2>Game Odds</h2>
                </div>
                <div className="header-right">
                    <div className="controls-group">
                        <div className="date-picker">
                            <input
                                type="date"
                                className="date-input"
                                value={date}
                                onChange={handleDateChange}
                            />
                        </div>
                        {renderRefreshLimitInfo()}
                        <button
                            className="refresh-button"
                            onClick={handleRefresh}
                            disabled={oddsLoading || (!isAdmin && !canRefreshOdds())}
                        >
                            <div className="refresh-button-content">
                                {oddsLoading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <>
                                        <BsArrowClockwise className="refresh-icon" />
                                        Refresh Odds
                                    </>
                                )}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div className="auto-refresh-controls">
                {isAdmin && (
                    <div className="auto-refresh-toggle">
                        <Form.Check
                            type="switch"
                            id="auto-refresh-switch"
                            label="Auto-refresh"
                            checked={autoRefresh}
                            onChange={toggleAutoRefresh}
                        />
                    </div>
                )}
                {isAdmin && autoRefresh && (
                    <div className="countdown-timer">
                        Refreshing in {countdown}s
                    </div>
                )}
                {isAdmin && requestsRemaining !== null && (
                    <div className={`api-info ${getApiInfoClass(requestsRemaining)}`}>
                        {requestsRemaining < 10 ? <BsExclamationTriangleFill /> : 
                         requestsRemaining < 50 ? <BsExclamationCircle /> : 
                         <BsInfoCircle />} API Requests: {requestsRemaining}
                    </div>
                )}
            </div>
            
            <div className="odds-content">
                {oddsError ? (
                    <Alert variant="danger">
                        <Alert.Heading>Error Loading Odds Data</Alert.Heading>
                        <p>
                            There was a problem connecting to the NBA Stats API. This could be due to:
                        </p>
                        <ul>
                            <li>The API server may be down or unreachable</li>
                            <li>There might be a configuration issue with the API endpoint</li>
                            <li>Your network connection may be experiencing issues</li>
                        </ul>
                        <p className="mb-0">
                            Technical details: {oddsError}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button onClick={handleRefresh} variant="outline-danger">
                                <BsArrowClockwise /> Try Again
                            </Button>
                        </div>
                    </Alert>
                ) : oddsLoading ? (
                    <div className="loading-container">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : odds && odds.length > 0 ? (
                    <div className="odds-table-container">
                        <Table responsive className="odds-table">
                            <thead>
                                <tr>
                                    <th className="matchup-column">Matchup</th>
                                    <th className="moneyline-column">Moneyline</th>
                                    <th className="spread-column">Spread</th>
                                    <th className="total-column">Total</th>
                                    <th className="actions-column">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {odds.map((game, index) => {
                                    console.log('Processing game:', game);
                                    const processedOdds = processGameOdds(game);
                                    console.log('Processed odds:', processedOdds);

                                    return (
                                        <tr key={game.id || index}>
                                            <td className="matchup-column">
                                                <div className="team-info">
                                                    <span className="home-label">HOME</span>
                                                    <span>{game.home_team}</span>
                                                    <div className="game-details">
                                                        <span className="game-time">{formatGameTime(game.commence_time)}</span>
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={<Tooltip>{getHoldTooltip(calculateHoldPercentage(processedOdds))}</Tooltip>}
                                                        >
                                                            <Badge 
                                                                className={`hold-badge ${getHoldStyle(calculateHoldPercentage(processedOdds))}`}
                                                            >
                                                                {calculateHoldPercentage(processedOdds)}% Hold
                                                            </Badge>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <span className="away-label">AWAY</span>
                                                    <span>{game.away_team}</span>
                                                </div>
                                            </td>
                                            <td className="moneyline-column">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Click for win probability</Tooltip>}
                                                >
                                                    <div 
                                                        className={`odds ${getOddsClass(processedOdds.moneyline.home)}`}
                                                        onClick={() => handleMoneylineClick(game.home_team, processedOdds.moneyline.home)}
                                                        style={{ color: getOddsColor(processedOdds.moneyline.home) }}
                                                    >
                                                        {formatOdds(processedOdds.moneyline.home)}
                                                    </div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip>Click for win probability</Tooltip>}
                                                >
                                                    <div 
                                                        className={`odds ${getOddsClass(processedOdds.moneyline.away)}`}
                                                        onClick={() => handleMoneylineClick(game.away_team, processedOdds.moneyline.away)}
                                                        style={{ color: getOddsColor(processedOdds.moneyline.away) }}
                                                    >
                                                        {formatOdds(processedOdds.moneyline.away)}
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td className="spread-column">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Click for spread probability</Tooltip>}
                                                >
                                                    <div 
                                                        className="odds"
                                                        onClick={() => handleSpreadClick(game.home_team, processedOdds.spread.home.point, processedOdds.spread.home.price)}
                                                    >
                                                        {processedOdds.spread.home.point} ({formatOdds(processedOdds.spread.home.price)})
                                                    </div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip>Click for spread probability</Tooltip>}
                                                >
                                                    <div 
                                                        className="odds"
                                                        onClick={() => handleSpreadClick(game.away_team, processedOdds.spread.away.point, processedOdds.spread.away.price)}
                                                    >
                                                        {processedOdds.spread.away.point} ({formatOdds(processedOdds.spread.away.price)})
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td className="total-column">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>Click for over/under probability</Tooltip>}
                                                >
                                                    <div 
                                                        className="odds"
                                                        onClick={() => handleTotalClick('over', processedOdds.total.over?.point, processedOdds.total.over?.price)}
                                                    >
                                                        O {processedOdds.total.over?.point} ({formatOdds(processedOdds.total.over?.price)})
                                                    </div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip>Click for over/under probability</Tooltip>}
                                                >
                                                    <div 
                                                        className="odds"
                                                        onClick={() => handleTotalClick('under', processedOdds.total.under?.point, processedOdds.total.under?.price)}
                                                    >
                                                        U {processedOdds.total.under?.point} ({formatOdds(processedOdds.total.under?.price)})
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                            <td className="actions-column">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => handleShowProps(game.id)}
                                                    className="props-button"
                                                >
                                                    Player Props
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <Alert variant="info">
                        <Alert.Heading>No Odds Data Loaded</Alert.Heading>
                        <p>
                            To view odds for the selected date, please click the <strong>Refresh Odds</strong> button above.
                        </p>
                        <p>
                            If no data appears after refreshing, there may not be any NBA games scheduled for this date, or the odds may not be available yet.
                        </p>
                        <div className="d-flex justify-content-center mt-3">
                            <Button onClick={handleRefresh} variant="primary">
                                <BsArrowClockwise className="me-2" /> Refresh Odds
                            </Button>
                        </div>
                    </Alert>
                )}
            </div>
            <PlayerPropsModal
                show={showProps}
                onHide={() => setShowProps(false)}
                gameData={selectedGame}
                propsData={propsData}
                loading={loadingProps}
                refreshProps={refreshProps}
            />
            <MoneylineProbabilityModal
                show={showMoneylineModal}
                onHide={() => setShowMoneylineModal(false)}
                team={selectedMoneyline?.team}
                odds={selectedMoneyline?.odds}
            />
            <SpreadProbabilityModal
                show={showSpreadModal}
                onHide={() => setShowSpreadModal(false)}
                team={selectedSpread?.team}
                spread={selectedSpread?.spread}
                odds={selectedSpread?.odds}
            />
            <TotalProbabilityModal
                show={showTotalModal}
                onHide={() => setShowTotalModal(false)}
                total={selectedTotal?.point}
                type={selectedTotal?.type}
                odds={selectedTotal?.odds}
            />
            
            {/* Debug info section */}
            {selectedGame?.id && (
                <DebugView gameId={selectedGame?.id} />
            )}
            
            {isAdmin && (
                <div className="admin-controls">
                    {renderApiEndpointSwitcher()}
                </div>
            )}

            <div className="disclaimer">
                <div className="disclaimer-icon">
                    <i className="fas fa-exclamation-triangle"></i>
                </div>
                <div>
                    <p>
                        <strong>Disclaimer:</strong> The odds and statistical information displayed on this page are sourced from third-party providers 
                        and are for informational purposes only. While we strive for accuracy, we cannot guarantee the 
                        correctness of all data. Odds may change rapidly and vary between sportsbooks. Users should verify all information 
                        before making decisions based on this data. Please be aware of your local jurisdiction's laws regarding sports wagering.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OddsDisplay; 
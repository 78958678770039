import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        // Prevent navigation for now
        e.preventDefault();
        // TODO: Implement navigation to policy pages
        console.log('Clicked:', e.target.text);
    };

    const navigateToSupport = (e) => {
        e.preventDefault();
        navigate('/support');
        // Wait for navigation to complete before scrolling
        setTimeout(() => {
            const contactSection = document.getElementById('contact-us');
            if (contactSection) {
                contactSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <footer className="app-footer">
            <Container>
                <Row className="footer-content">
                    <Col md={3} sm={6} className="footer-section">
                        <h5>Legal</h5>
                        <ul>
                            <li>
                                <a href="#" onClick={handleClick}>
                                    Terms of Use
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={handleClick}>
                                    Privacy Policy
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} sm={6} className="footer-section">
                        <h5>Trust & Safety</h5>
                        <ul>
                            <li>
                                <a href="#" onClick={handleClick}>
                                    Security
                                </a>
                            </li>
                            <li>
                                <a 
                                    href="https://www.americangaming.org/resources/responsible-gaming-regulations-and-statutes-guide/" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    Responsible Gaming
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} sm={6} className="footer-section">
                        <h5>Sponsors</h5>
                        <ul>
                            <li>
                                <a href="#" onClick={handleClick}>
                                    Our Partners
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={handleClick}>
                                    Become a Sponsor
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} sm={6} className="footer-section">
                        <h5>Contact</h5>
                        <ul>
                            <li>
                                <a href="/support" onClick={navigateToSupport} className="support-link">
                                    Support
                                </a>
                            </li>
                            <li>
                                <a href="/support" onClick={navigateToSupport} className="support-link">
                                    Business Inquiries
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="footer-bottom">
                    <Col>
                        <p>© 2024 ClutchSports NBA Stats Hub. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer; 
import React, { useState, useEffect, createContext } from 'react';
import { Nav, TabPane, TabContainer, Container, ProgressBar, Alert, Spinner } from 'react-bootstrap';
import OddsDisplay from './components/OddsDisplay';
import SupportTab from './components/SupportTab';
import AboutTab from './components/AboutTab';
import Footer from './components/Footer';
import AuthButtons from './components/AuthButtons';
import HomePage from './components/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { 
    BrowserRouter, 
    Routes, 
    Route, 
    Navigate,
    useLocation,
    useNavigate
} from 'react-router-dom';
import UserDashboard from './components/UserDashboard';
import { CSRFProvider } from './contexts/CSRFContext';
import ProtectedTab from './components/ProtectedTab';
import PaidFeature from './components/PaidFeature';
import PlansTab from './components/PlansTab';
import Checkout from './components/Checkout';
import Layout from './components/Layout';
import { API_BASE_URL, isNbaApiOffHours } from './config/constants';
import StatsNew from './components/StatsNew';
import ResearchTab from './components/ResearchTab';
import UserProfileTab from './components/UserProfileTab';
import LayOddsTab from './components/LayOddsTab';
import FeedbackButton from './components/FeedbackButton';
import { UserActivityProvider } from './contexts/UserActivityContext';
import InactivityWarningModal from './components/InactivityWarningModal';
import ApiLimitAlert from './components/ApiLimitAlert';
import PromoBanner from './components/PromoBanner';
import SecurityMonitorDashboard from './components/admin/SecurityMonitorDashboard';
import { initializeScheduledTasks } from './utils/scheduledTasks';
import { StatsProvider } from './contexts/StatsContext';
// Import Bootstrap Icons CSS
import 'bootstrap-icons/font/bootstrap-icons.css';

// Create a context for storing LayOddsTab state
export const LayOddsContext = createContext({
    games: [],
    loading: false,
    error: null,
    suggestedParlays: [],
    parlayStats: {
        totalValue: 0,
        potentialReturn: 0,
        oddsImpliedProbability: 0
    },
    setGames: () => {},
    setLoading: () => {},
    setError: () => {},
    setSuggestedParlays: () => {},
    setParlayStats: () => {},
    date: '',
    setDate: () => {},
    displayDate: '',
    setDisplayDate: () => {},
    fetchOdds: () => {},
    hasLoaded: false,
    setHasLoaded: () => {}
});

// Create a context for storing OddsDisplay state
export const OddsContext = createContext({
    odds: [],
    loading: false,
    error: null,
    setOdds: () => {},
    setLoading: () => {},
    setError: () => {},
    date: '',
    setDate: () => {},
    fetchOdds: () => {},
    hasLoaded: false,
    setHasLoaded: () => {},
    requestsRemaining: null,
    setRequestsRemaining: () => {}
});

// Move PrivateRoute component above App
const PrivateRoute = ({ children, adminOnly = false }) => {
    const { user, loading, isAdmin } = useAuth();
    
    if (loading) {
        return <div>Loading...</div>;
    }
    
    if (!user) {
        return <Navigate to="/" />;
    }

    if (adminOnly && !isAdmin) {
        return <Navigate to="/" />;
    }
    
    return children;
};

// Add ProtectedRoute for role-based access control
const ProtectedRoute = ({ children, requiredRole }) => {
    const { user, loading, isAdmin } = useAuth();
    
    if (loading) {
        return <div>Loading...</div>;
    }
    
    if (!user) {
        return <Navigate to="/" />;
    }

    if (requiredRole === 'admin' && !isAdmin) {
        return <Navigate to="/" />;
    }
    
    return children;
};

// Simple AdminLayout component
const AdminLayout = ({ children }) => {
    return (
        <div className="admin-layout" style={{ 
            backgroundColor: '#121212', 
            color: 'white',
            minHeight: '100vh',
            padding: '20px'
        }}>
            <div className="admin-header" style={{
                borderBottom: '1px solid #333',
                paddingBottom: '15px',
                marginBottom: '20px'
            }}>
                <h2 style={{ 
                    color: 'white', 
                    fontWeight: 'bold' 
                }}>
                    Admin Dashboard
                </h2>
            </div>
            <div className="admin-content">
                {children}
            </div>
        </div>
    );
};

// Color coding configuration
const COLORS = {
    high: '#28a745',    // Green
    medium: '#ffc107',  // Yellow
    low: '#dc3545',     // Red
    default: '#6c757d'  // Gray
};

const THRESHOLDS = {
    odds: { high: 1.5, medium: 1, low: 0.5 },
    props: { high: 1.2, medium: 0.8, low: 0.5 },
    // Add other threshold categories as needed
};

// Color coding configuration for odds
const ODDS_COLORS = {
    positive: '#28a745',  // Green for positive odds/value
    negative: '#dc3545', // Red for negative odds/value
    neutral: '#6c757d',  // Gray for neutral/default
};

const fetchWithRetry = async (url, retries = 3) => {
    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText);
            }
            return response;
        } catch (error) {
            if (i === retries - 1) throw error;
            console.log(`Retry ${i + 1}/${retries} after error:`, error);
            await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
        }
    }
};

const fetchTeamStats = async (teamId, season) => {
  try {
    const response = await fetch(`${API_BASE_URL}/team-stats/${teamId}/${season}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Origin': 'https://clutchnba-com-4d7bf.web.app'
      }
    });
    
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    return await response.json();
  } catch (error) {
    console.error('Error fetching team stats:', error);
    throw error;
  }
};

// Add a utility function to update the API count consistently
const updateGlobalApiCount = (remaining) => {
    // First get the old value to check if it changed
    const oldValue = localStorage.getItem('apiRequestsRemaining');
    const newValue = remaining.toString();
    
    // Update localStorage
    localStorage.setItem('apiRequestsRemaining', newValue);
    console.log('Updated API requests remaining:', remaining);
    
    // Dispatch a storage event to notify other components of the change
    // This helps components listening for storage events to update
    if (oldValue !== newValue) {
        window.dispatchEvent(new StorageEvent('storage', {
            key: 'apiRequestsRemaining',
            oldValue,
            newValue,
            storageArea: localStorage
        }));
    }
};

// Create a new AppContent component that uses the router hooks
function AppContent() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, checkPaidAccess, isAdmin } = useAuth();
    
    // Determine active tab based on current path
    const getActiveTabFromPath = () => {
        const path = location.pathname;
        if (path === '/') return 'home';
        if (path.includes('/dashboard')) return 'dashboard';
        if (path.includes('/odds')) return 'odds';
        if (path.includes('/layodds')) return 'layodds';
        if (path.includes('/stats')) return 'stats';
        if (path.includes('/plans')) return 'plans';
        if (path.includes('/checkout')) return 'checkout';
        if (path.includes('/support')) return 'support';
        if (path.includes('/about')) return 'about';
        if (path.includes('/research')) return 'research';
        if (path.includes('/profile')) return 'profile';
        return 'home'; // Default tab is now home
    };
    
    const [activeTab, setActiveTab] = useState(getActiveTabFromPath());
    
    // Update activeTab when location changes
    useEffect(() => {
        setActiveTab(getActiveTabFromPath());
    }, [location.pathname]);

    // Initialize scheduled tasks
    useEffect(() => {
        // Only initialize if user is admin - these will send emails
        if (isAdmin) {
            console.log('Initializing scheduled tasks for admin');
            initializeScheduledTasks();
        }
    }, [isAdmin]);

    const handleTabSelect = (key) => {
        setActiveTab(key);
        // Navigate to the appropriate route based on the selected tab
        switch(key) {
            case 'home':
                navigate('/');
                break;
            case 'dashboard':
                navigate('/dashboard');
                break;
            case 'stats':
                navigate('/stats');
                break;
            case 'odds':
                navigate('/odds');
                break;
            case 'layodds':
                navigate('/layodds');
                break;
            case 'research':
                navigate('/research');
                break;
            case 'plans':
                navigate('/plans');
                break;
            case 'checkout':
                console.log("Direct navigation to checkout attempted, redirecting to plans");
                navigate('/plans');
                break;
            case 'support':
                navigate('/support');
                break;
            case 'about':
                navigate('/about');
                break;
            case 'profile':
                navigate('/profile');
                break;
            default:
                navigate('/');
        }
    };

    // Add state for LayOddsTab
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [suggestedParlays, setSuggestedParlays] = useState([]);
    const [parlayStats, setParlayStats] = useState({
        totalValue: 0,
        potentialReturn: 0,
        oddsImpliedProbability: 0
    });
    const [date, setDate] = useState(() => {
        const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        return new Date(estDate).toISOString().split('T')[0];
    });
    const [displayDate, setDisplayDate] = useState('');
    const [hasLoaded, setHasLoaded] = useState(false);

    // Create the context value
    const layOddsContextValue = {
        games,
        loading,
        error,
        suggestedParlays,
        parlayStats,
        setGames,
        setLoading,
        setError,
        setSuggestedParlays,
        setParlayStats,
        date,
        setDate,
        displayDate,
        setDisplayDate,
        hasLoaded,
        setHasLoaded,
        fetchOdds: async () => {
            // Check if user is authenticated before fetching
            if (!user) {
                console.log("User not authenticated, not fetching odds");
                return;
            }
            
            // Check if we're in the off-hours period (1am-6am EST)
            if (isNbaApiOffHours()) {
                console.log("Skipping odds API call during NBA off-hours (1am-6am EST)");
                return;
            }
            
            setLoading(true);
            
            try {
                // Specifically request player props in the API call - focus only on the most inefficient markets
                const url = `${API_BASE_URL}/api/odds?date=${date}&markets=h2h,spreads,totals,player_points,player_rebounds,player_assists`;
                
                console.log("Fetching odds for date:", date);
                const response = await fetch(url, {
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json'
                    }
                });
                
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
                }
                
                const responseData = await response.json();
                
                // Log the response data structure
                console.log("API Response structure:", {
                    isArray: Array.isArray(responseData),
                    hasDataProp: responseData.data !== undefined,
                    hasRemainingRequests: responseData.remaining_requests !== undefined,
                    remainingRequests: responseData.remaining_requests,
                    topLevelKeys: Object.keys(responseData)
                });
                
                // Handle both array and object response formats
                let gamesData;
                if (Array.isArray(responseData)) {
                    gamesData = responseData;
                } else if (responseData.data && Array.isArray(responseData.data)) {
                    gamesData = responseData.data;
                } else {
                    throw new Error('Unexpected API response format');
                }
                
                // Define filterGamesByDate function here
                const filterGamesByDate = (games, selectedDate) => {
                    if (!games || !Array.isArray(games)) {
                        return [];
                    }
                    
                    // Create date range in EST
                    const startDate = new Date(`${selectedDate}T00:00:00-05:00`);
                    const endDate = new Date(`${selectedDate}T23:59:59-05:00`);
                    
                    return games.filter(game => {
                        const gameDate = new Date(game.commence_time);
                        return gameDate >= startDate && gameDate <= endDate;
                    });
                };

                // Filter games for selected date
                const filteredGames = filterGamesByDate(gamesData, date);
                console.log("Filtered games:", filteredGames);
                
                // Fetch player props for each game
                const gamesWithProps = await Promise.all(filteredGames.map(async (game) => {
                    try {
                        if (!game.id) {
                            console.warn(`Game has no ID, skipping player props fetch: ${game.home_team} vs ${game.away_team}`);
                            return game;
                        }
                        
                        console.log(`Fetching player props for game ID: ${game.id}`);
                        const propsResponse = await fetch(`${API_BASE_URL}/api/player-props/${game.id}`, {
                            credentials: 'same-origin',
                            headers: {
                                'Accept': 'application/json'
                            }
                        });
                        
                        if (!propsResponse.ok) {
                            console.warn(`Could not fetch player props for game ${game.id}: ${propsResponse.status}`);
                            return game;
                        }
                        
                        const propsData = await propsResponse.json();
                        
                        // Extract and process props from the response data
                        // Note: In a real implementation, you would parse and process the player props here
                        // This is simplified for clarity
                        
                        return {
                            ...game,
                            playerProps: propsData.data?.props || []
                        };
                    } catch (error) {
                        console.warn(`Error getting player props for ${game.home_team} vs ${game.away_team}:`, error);
                    }
                    
                    return game;
                }));
                
                setGames(gamesWithProps);
                setHasLoaded(true);
                
                // Note: We're not generating parlays here because that's handled in the LayOddsTab component
                // when it receives the updated games data
                
                return gamesWithProps;
            } catch (error) {
                console.error('Error fetching odds:', error);
                setError(error.message || 'Failed to fetch odds data');
                setGames([]);
                setSuggestedParlays([]);
                throw error;
            } finally {
                setLoading(false);
            }
        }
    };

    // Add state for OddsDisplay
    const [odds, setOdds] = useState([]);
    const [oddsLoading, setOddsLoading] = useState(false);
    const [oddsError, setOddsError] = useState(null);
    const [oddsDate, setOddsDate] = useState(() => {
        const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        return new Date(estDate).toISOString().split('T')[0];
    });
    const [oddsHasLoaded, setOddsHasLoaded] = useState(false);
    const [requestsRemaining, setRequestsRemaining] = useState(() => {
        const savedCount = localStorage.getItem('apiRequestsRemaining');
        return savedCount ? parseInt(savedCount, 10) : null;
    });

    // Create the OddsContext value
    const oddsContextValue = {
        odds,
        loading: oddsLoading,
        error: oddsError,
        setOdds,
        setLoading: setOddsLoading,
        setError: setOddsError,
        date: oddsDate,
        setDate: setOddsDate,
        hasLoaded: oddsHasLoaded,
        setHasLoaded: setOddsHasLoaded,
        requestsRemaining,
        setRequestsRemaining,
        fetchOdds: async (date) => {
            // Check if user is authenticated before fetching
            const currentUser = user; // Capture the current user value
            
            if (!currentUser) {
                console.log("User not authenticated, skipping fetch for odds");
                console.log("Auth state:", currentUser ? "Authenticated" : "Not authenticated");
                setOddsLoading(false);
                return;
            }
            
            console.log("User is authenticated in App.js, fetching odds data");
            setOddsLoading(true);
            setOddsError(null);
            
            try {
                const url = `${API_BASE_URL}/api/odds?date=${date}`;
                
                console.log("Fetching odds for date:", date);
                const response = await fetch(url, {
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json'
                    }
                });
                
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
                }
                
                const responseData = await response.json();
                
                // Log the response data structure
                console.log("API Response structure:", {
                    isArray: Array.isArray(responseData),
                    hasDataProp: responseData.data !== undefined,
                    hasRemainingRequests: responseData.remaining_requests !== undefined,
                    remainingRequests: responseData.remaining_requests,
                    topLevelKeys: Object.keys(responseData)
                });
                
                // Handle both array and object response formats
                let oddsData;
                if (Array.isArray(responseData)) {
                    oddsData = responseData;
                } else if (responseData.data && Array.isArray(responseData.data)) {
                    oddsData = responseData.data;
                    // Set remaining requests if available
                    if (responseData.remaining_requests) {
                        const remaining = parseInt(responseData.remaining_requests);
                        setRequestsRemaining(remaining);
                        updateGlobalApiCount(remaining);
                        console.log('API requests remaining:', remaining);
                    }
                } else {
                    throw new Error('Unexpected API response format');
                }
                
                // Define filterGamesByDate function here
                const filterGamesByDate = (games, selectedDate) => {
                    if (!games || !Array.isArray(games)) {
                        return [];
                    }
                    
                    // Create date range in EST
                    const startDate = new Date(`${selectedDate}T00:00:00-05:00`);
                    const endDate = new Date(`${selectedDate}T23:59:59-05:00`);
                    
                    return games.filter(game => {
                        const gameDate = new Date(game.commence_time);
                        return gameDate >= startDate && gameDate <= endDate;
                    });
                };

                // Filter games for selected date
                const filteredGames = filterGamesByDate(oddsData, date);
                
                setOdds(filteredGames);
                setOddsHasLoaded(true);
                
                return filteredGames;
            } catch (error) {
                console.error('Error fetching odds:', error);
                setOddsError(error.message || 'Failed to fetch odds data');
                setOdds([]);
                throw error;
            } finally {
                setOddsLoading(false);
            }
        }
    };

    // Monitor changes in API requests remaining
    useEffect(() => {
        if (requestsRemaining !== null) {
            // Parse as integer to ensure we have a proper number
            const remaining = parseInt(requestsRemaining, 10);
            
            // Update localStorage with the latest value
            updateGlobalApiCount(remaining);
            
            // Calculate percentage remaining with proper total of 100,000
            const totalRequests = 100000; // Updated total API requests limit
            
            // Add safety check to ensure we don't get unreasonable percentages
            if (remaining > totalRequests) {
                console.warn('Warning: API requests remaining exceeds total! This suggests an API response issue. Capping percentage at 100%.');
                console.log(`Raw values: remaining=${remaining}, total=${totalRequests}`);
            }
            
            // Cap percentage at 100%
            const percentRemaining = Math.min((remaining / totalRequests) * 100, 100);
            console.log('API requests percentage remaining:', percentRemaining.toFixed(1) + '%');
        }
    }, [requestsRemaining]);

    return (
        <div className="app-container">
            <PromoBanner />
            <CSRFProvider>
                <LayOddsContext.Provider value={layOddsContextValue}>
                    <OddsContext.Provider value={oddsContextValue}>
                        <StatsProvider>
                            <Layout>
                                <TabContainer activeKey={activeTab} onSelect={handleTabSelect}>
                                    <Nav className="mb-4" variant="tabs" fill>
                                        <Nav.Item>
                                            <Nav.Link eventKey="home">Home</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="stats">
                                                <PaidFeature type="tab">Stats</PaidFeature>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="odds">
                                                <PaidFeature type="tab">Odds</PaidFeature>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="layodds">
                                                <PaidFeature type="tab">Lay Odds</PaidFeature>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="research">
                                                <PaidFeature type="tab">Research</PaidFeature>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="plans">Plans</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="support">Support</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="about">About</Nav.Link>
                                        </Nav.Item>
                                        {user && (
                                            <>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="profile">Profile</Nav.Link>
                                                </Nav.Item>
                                            </>
                                        )}
                                    </Nav>

                                    <div className="tab-content">
                                        {activeTab === "home" && <HomePage />}
                                        {activeTab === "stats" && (
                                            <PaidFeature>
                                                <StatsNew />
                                            </PaidFeature>
                                        )}
                                        {activeTab === "odds" && (
                                            <PaidFeature>
                                                <OddsDisplay />
                                            </PaidFeature>
                                        )}
                                        {activeTab === "layodds" && (
                                            <PaidFeature>
                                                <LayOddsTab />
                                            </PaidFeature>
                                        )}
                                        {activeTab === "research" && (
                                            <PaidFeature>
                                                <ResearchTab />
                                            </PaidFeature>
                                        )}
                                        {activeTab === "plans" && <PlansTab />}
                                        {activeTab === "checkout" && <Checkout />}
                                        {activeTab === "support" && <SupportTab />}
                                        {activeTab === "about" && <AboutTab />}
                                        {activeTab === "dashboard" && user && (
                                            <PrivateRoute>
                                                <UserDashboard />
                                            </PrivateRoute>
                                        )}
                                        {activeTab === "profile" && user && (
                                            <PrivateRoute>
                                                <UserProfileTab />
                                            </PrivateRoute>
                                        )}
                                    </div>
                                    <Footer />
                                </TabContainer>
                            </Layout>
                            <FeedbackButton />
                            <InactivityWarningModal />
                            {isAdmin && <ApiLimitAlert requestsRemaining={requestsRemaining} totalRequests={100000} />}
                        </StatsProvider>
                    </OddsContext.Provider>
                </LayOddsContext.Provider>
            </CSRFProvider>
        </div>
    );
}

function App() {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [suggestedParlays, setSuggestedParlays] = useState([]);
    const [parlayStats, setParlayStats] = useState({
        totalValue: 0,
        potentialReturn: 0,
        oddsImpliedProbability: 0
    });
    const [date, setDate] = useState(() => {
        const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        return new Date(estDate).toISOString().split('T')[0];
    });
    const [displayDate, setDisplayDate] = useState('');
    const [hasLoaded, setHasLoaded] = useState(false);

    // Add state for OddsDisplay
    const [odds, setOdds] = useState([]);
    const [oddsLoading, setOddsLoading] = useState(false);
    const [oddsError, setOddsError] = useState(null);
    const [oddsDate, setOddsDate] = useState(() => {
        const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        return new Date(estDate).toISOString().split('T')[0];
    });
    const [oddsHasLoaded, setOddsHasLoaded] = useState(false);
    const [requestsRemaining, setRequestsRemaining] = useState(() => {
        const savedCount = localStorage.getItem('apiRequestsRemaining');
        return savedCount ? parseInt(savedCount, 10) : null;
    });

    // Let's use a simpler approach - go back to the original structure
    return (
        <BrowserRouter>
            <AuthProvider>
                <UserActivityProvider>
                    <Routes>
                        <Route path="/" element={<AppContent />} />
                        <Route path="/dashboard" element={<AppContent />} />
                        <Route path="/odds" element={<AppContent />} />
                        <Route path="/layodds" element={<AppContent />} />
                        <Route path="/stats" element={<AppContent />} />
                        <Route path="/plans" element={<AppContent />} />
                        <Route path="/checkout" element={<AppContent />} />
                        <Route path="/support" element={<AppContent />} />
                        <Route path="/about" element={<AppContent />} />
                        <Route path="/research" element={<AppContent />} />
                        <Route path="/profile" element={<AppContent />} />
                        <Route 
                            path="/admin/security-monitoring" 
                            element={
                                <ProtectedRoute requiredRole="admin">
                                    <AdminLayout>
                                        <SecurityMonitorDashboard />
                                    </AdminLayout>
                                </ProtectedRoute>
                            } 
                        />
                    </Routes>
                </UserActivityProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;

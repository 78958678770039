// Based on 2023-24 NBA season statistics
export const STAT_THRESHOLDS = {
    points: {
        excellent: 25.0, // Top 20 scorers average ~25+ PPG
        good: 20.0,     // Above average scorer
        average: 15.0   // Role player scoring
    },
    assists: {
        excellent: 7.0, // Top 15 playmakers average 7+ APG
        good: 5.0,      // Good playmaking
        average: 3.0    // Basic ball movement
    },
    rebounds: {
        excellent: 9.0, // Top 15 rebounders average 9+ RPG
        good: 6.5,      // Good rebounder
        average: 4.0    // Expected rebounds
    },
    steals: {
        excellent: 1.6, // Top 10 defenders average 1.6+ SPG
        good: 1.2,      // Good defender
        average: 0.8    // Basic defense
    },
    blocks: {
        excellent: 1.5, // Top 15 shot blockers average 1.5+ BPG
        good: 1.0,      // Good rim protection
        average: 0.5    // Basic rim protection
    },
    per: {
        excellent: 22.0, // All-Star level
        good: 18.0,     // Above average player
        average: 15.0   // League average is ~15
    },
    usagePercentage: {
        excellent: 28.0, // Primary option
        good: 23.0,     // Secondary option
        average: 18.0   // Role player
    },
    winShares: {
        excellent: 7.0, // All-Star level
        good: 4.0,     // Quality starter
        average: 2.0   // Rotation player
    },
    boxPlusMinus: {
        excellent: 3.0, // All-Star level
        good: 1.0,     // Above average
        average: -1.0  // Below average
    },
    minutes: {
        excellent: 30.0,
        good: 25.0,
        average: 20.0
    },
    fgPercent: {
        excellent: 50.0,
        good: 45.0,
        average: 40.0
    },
    threePtPercent: {
        excellent: 50.0,
        good: 45.0,
        average: 40.0
    }
};

export const getStatColorClass = (value, statType) => {
    if (!value || !STAT_THRESHOLDS[statType]) return '';

    const thresholds = STAT_THRESHOLDS[statType];
    
    if (value >= thresholds.excellent) {
        return 'stat-excellent';
    } else if (value >= thresholds.good) {
        return 'stat-good';
    } else if (value >= thresholds.average) {
        return 'stat-average';
    } else {
        return 'stat-below-average';
    }
};

// Keep existing getStatColor function for backwards compatibility
export const getStatColor = (value, statType) => {
    if (!value || !STAT_THRESHOLDS[statType]) return 'var(--text-primary)';

    const thresholds = STAT_THRESHOLDS[statType];
    
    if (value >= thresholds.excellent) {
        return 'var(--stat-excellent)';
    } else if (value >= thresholds.good) {
        return 'var(--stat-good)';
    } else if (value >= thresholds.average) {
        return 'var(--stat-average)';
    } else {
        return 'var(--stat-below-average)';
    }
}; 
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './TeamStats.css';

const TeamStats = ({ team1Stats, team2Stats, team1Name, team2Name, matchupMode }) => {
    const renderStatCard = (stats, teamName, isMatchup) => (
        <Card className="team-stats-card">
            <Card.Header>
                <h5>{teamName} {isMatchup ? 'Head-to-Head Stats' : 'Season Stats'}</h5>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={6} md={3}>
                        <div className="stat-box">
                            <label>Record</label>
                            <span>{stats.wins}-{stats.losses}</span>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="stat-box">
                            <label>Win %</label>
                            <span>{stats.winPercentage}%</span>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="stat-box">
                            <label>PPG</label>
                            <span>{stats.averagePointsScored}</span>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="stat-box">
                            <label>OPP PPG</label>
                            <span>{stats.averagePointsAllowed}</span>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );

    return (
        <div className="team-stats-container">
            {team1Stats && (
                <>
                    {renderStatCard(team1Stats.overall, team1Name, false)}
                    {matchupMode && renderStatCard(team1Stats.matchup, team1Name, true)}
                </>
            )}
            {team2Stats && (
                <>
                    {renderStatCard(team2Stats.overall, team2Name, false)}
                    {matchupMode && renderStatCard(team2Stats.matchup, team2Name, true)}
                </>
            )}
        </div>
    );
};

export default TeamStats; 
import React from 'react';
import { Button } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import LoginModal from './LoginModal';
import SignupModal from './SignupModal';
import './AuthButtons.css';

const AuthButtons = () => {
    const { 
        user, 
        logout, 
        setShowLoginModal, 
        showLoginModal, 
        setShowSignupModal, 
        showSignupModal 
    } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error('Failed to log out:', error);
        }
    };

    return (
        <>
            <div className="auth-buttons">
                {user ? (
                    <>
                        <span className="user-email">{user.email}</span>
                        <Button 
                            variant="outline-primary" 
                            onClick={handleLogout}
                            className="logout-button"
                        >
                            Logout
                        </Button>
                    </>
                ) : (
                    <>
                        <Button 
                            variant="outline-primary" 
                            onClick={() => setShowLoginModal(true)}
                            className="login-button"
                        >
                            Login
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={() => setShowSignupModal(true)}
                            className="join-button"
                        >
                            Join
                        </Button>
                    </>
                )}
            </div>

            <LoginModal 
                show={showLoginModal} 
                onHide={() => setShowLoginModal(false)} 
            />
            <SignupModal 
                show={showSignupModal} 
                onHide={() => setShowSignupModal(false)} 
            />
        </>
    );
};

export default AuthButtons; 
import { collection, query, where, getDocs, Timestamp, addDoc, setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { sendTrialExpirationReminder, sendSubscriptionExpirationNotification } from './emailUtils';

/**
 * Check if a reminder has already been sent today for a specific user and type
 * @param {string} userId - User ID
 * @param {string} reminderType - Type of reminder (e.g., '1day', '3day', 'expired')
 * @returns {Promise<boolean>} - Whether reminder has been sent today
 */
const hasReminderBeenSentToday = async (userId, reminderType) => {
  try {
    // Get today's date at midnight for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Create a document ID using userId and reminderType
    const trackingDocId = `${userId}_${reminderType}_${today.toISOString().split('T')[0]}`;
    const trackingRef = doc(firestore, 'emailReminders', trackingDocId);
    
    // Check if tracking document exists
    const trackingDoc = await getDoc(trackingRef);
    return trackingDoc.exists();
  } catch (error) {
    console.error('Error checking if reminder has been sent:', error);
    // Default to false to ensure critical reminders are sent even if check fails
    return false;
  }
};

/**
 * Record that a reminder has been sent today
 * @param {string} userId - User ID
 * @param {string} reminderType - Type of reminder (e.g., '1day', '3day', 'expired')
 * @param {string} email - User's email address
 * @returns {Promise<void>}
 */
const recordReminderSent = async (userId, reminderType, email) => {
  try {
    // Get today's date at midnight for the record
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Create a document ID using userId and reminderType
    const trackingDocId = `${userId}_${reminderType}_${today.toISOString().split('T')[0]}`;
    const trackingRef = doc(firestore, 'emailReminders', trackingDocId);
    
    // Record the reminder
    await setDoc(trackingRef, {
      userId,
      reminderType,
      email,
      sentAt: new Date().toISOString(),
      date: today.toISOString().split('T')[0]
    });
    
    console.log(`Recorded ${reminderType} reminder sent to ${email}`);
  } catch (error) {
    console.error('Error recording reminder sent:', error);
    // Continue execution even if recording fails
  }
};

/**
 * Check for users with soon-to-expire trial subscriptions and send reminder emails
 * This function should be called from a scheduler or cloud function
 */
export const sendTrialExpirationReminders = async () => {
  try {
    console.log('Starting trial expiration check...');
    const usersRef = collection(firestore, 'users');
    const now = new Date();
    
    // Calculate dates for 1-day and 3-day warnings
    const oneDayFromNow = new Date(now);
    oneDayFromNow.setDate(now.getDate() + 1);
    const oneDayEnd = new Date(oneDayFromNow);
    oneDayEnd.setHours(23, 59, 59, 999);
    
    const threeDaysFromNow = new Date(now);
    threeDaysFromNow.setDate(now.getDate() + 3);
    const threeDaysEnd = new Date(threeDaysFromNow);
    threeDaysEnd.setHours(23, 59, 59, 999);
    
    // Query for trials ending in about 1 day
    const oneDayQuery = query(
      usersRef,
      where('subscription.status', '==', 'active'),
      where('subscription.plan', '==', 'premium-trial'),
      where('subscription.trialEndDate', '>=', now.toISOString()),
      where('subscription.trialEndDate', '<=', oneDayEnd.toISOString())
    );
    
    // Query for trials ending in about 3 days
    const threeDaysQuery = query(
      usersRef,
      where('subscription.status', '==', 'active'),
      where('subscription.plan', '==', 'premium-trial'),
      where('subscription.trialEndDate', '>=', oneDayEnd.toISOString()),
      where('subscription.trialEndDate', '<=', threeDaysEnd.toISOString())
    );
    
    // Get users with trials ending in about 1 day
    const oneDaySnapshot = await getDocs(oneDayQuery);
    const oneDayUsers = oneDaySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      daysLeft: 1
    }));
    
    // Get users with trials ending in about 3 days
    const threeDaysSnapshot = await getDocs(threeDaysQuery);
    const threeDayUsers = threeDaysSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      daysLeft: 3
    }));
    
    console.log(`Found ${oneDayUsers.length} users with trials ending in 1 day`);
    console.log(`Found ${threeDayUsers.length} users with trials ending in 3 days`);
    
    // Track how many emails were actually sent
    let oneDayRemindersSent = 0;
    let threeDayRemindersSent = 0;
    
    // Send emails to users with trials ending in 1 day
    for (const user of oneDayUsers) {
      if (user.email) {
        // Check if reminder has already been sent today
        const alreadySent = await hasReminderBeenSentToday(user.id, '1day');
        
        if (!alreadySent) {
          await sendTrialExpirationReminder(user);
          await recordReminderSent(user.id, '1day', user.email);
          console.log(`Sent 1-day reminder to: ${user.email}`);
          oneDayRemindersSent++;
        } else {
          console.log(`Skipped 1-day reminder to ${user.email} - already sent today`);
        }
      }
    }
    
    // Send emails to users with trials ending in 3 days
    for (const user of threeDayUsers) {
      if (user.email) {
        // Check if reminder has already been sent today
        const alreadySent = await hasReminderBeenSentToday(user.id, '3day');
        
        if (!alreadySent) {
          await sendTrialExpirationReminder(user);
          await recordReminderSent(user.id, '3day', user.email);
          console.log(`Sent 3-day reminder to: ${user.email}`);
          threeDayRemindersSent++;
        } else {
          console.log(`Skipped 3-day reminder to ${user.email} - already sent today`);
        }
      }
    }
    
    console.log('Trial expiration check completed successfully');
    console.log(`Sent ${oneDayRemindersSent} 1-day reminders and ${threeDayRemindersSent} 3-day reminders`);
    
    return {
      oneDayReminders: oneDayUsers.length,
      threeDayReminders: threeDayUsers.length,
      oneDayRemindersSent,
      threeDayRemindersSent,
      total: oneDayRemindersSent + threeDayRemindersSent
    };
  } catch (error) {
    console.error('Error checking for trial expirations:', error);
    throw error;
  }
};

/**
 * Check for expired trial subscriptions and send expiration notifications
 * This function should be called from a scheduler or cloud function
 */
export const processExpiredTrials = async () => {
  try {
    console.log('Starting expired trials check...');
    const usersRef = collection(firestore, 'users');
    const now = new Date();
    
    // Query for trials that have already ended but haven't been processed
    const expiredTrialsQuery = query(
      usersRef,
      where('subscription.status', '==', 'active'),
      where('subscription.plan', '==', 'premium-trial'),
      where('subscription.trialEndDate', '<', now.toISOString())
    );
    
    const expiredTrialsSnapshot = await getDocs(expiredTrialsQuery);
    const expiredTrials = expiredTrialsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    console.log(`Found ${expiredTrials.length} expired trial subscriptions`);
    
    // Track how many notifications were actually sent and subscriptions updated
    let notificationsSent = 0;
    let subscriptionsUpdated = 0;
    
    // Process expired trials
    for (const user of expiredTrials) {
      // 1. Update subscription status to 'expired'
      try {
        const userRef = doc(firestore, 'users', user.id);
        await updateDoc(userRef, {
          'subscription.status': 'expired',
          'subscription.expiryDate': now.toISOString(),
          'subscription.updateReason': 'Trial period ended'
        });
        subscriptionsUpdated++;
        console.log(`Updated subscription status to expired for user: ${user.email || user.id}`);
      } catch (error) {
        console.error(`Failed to update subscription status for user ${user.id}:`, error);
      }
      
      // 2. Send expiration email notification
      if (user.email) {
        // Check if expiration notification has already been sent today
        const alreadySent = await hasReminderBeenSentToday(user.id, 'expired');
        
        if (!alreadySent) {
          await sendSubscriptionExpirationNotification(user);
          await recordReminderSent(user.id, 'expired', user.email);
          console.log(`Sent trial expiration notification to: ${user.email}`);
          notificationsSent++;
        } else {
          console.log(`Skipped expiration notification to ${user.email} - already sent today`);
        }
      }
    }
    
    console.log('Expired trials check completed successfully');
    console.log(`Updated ${subscriptionsUpdated} subscriptions and sent ${notificationsSent} expiration notifications`);
    
    return {
      expiredTrialsProcessed: expiredTrials.length,
      expiredTrialsUpdated: subscriptionsUpdated,
      expiredTrialsNotificationsSent: notificationsSent
    };
  } catch (error) {
    console.error('Error processing expired trials:', error);
    throw error;
  }
};

/**
 * Fix accounts with inconsistent subscription status
 * This can be run manually to fix existing accounts that have expired trials but still show 'active' status
 */
export const fixInconsistentSubscriptions = async () => {
  try {
    console.log('Starting inconsistent subscriptions fix...');
    const usersRef = collection(firestore, 'users');
    const now = new Date();
    
    // Query for trials that have already ended but still show active status
    const inconsistentSubsQuery = query(
      usersRef,
      where('subscription.status', '==', 'active'),
      where('subscription.plan', '==', 'premium-trial')
    );
    
    const inconsistentSubsSnapshot = await getDocs(inconsistentSubsQuery);
    const inconsistentSubs = inconsistentSubsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    console.log(`Found ${inconsistentSubs.length} potential inconsistent subscriptions, checking dates...`);
    
    // Filter to only include those that have actually expired
    const expiredSubs = inconsistentSubs.filter(user => {
      if (!user.subscription?.trialEndDate) return false;
      
      const trialEndDate = new Date(user.subscription.trialEndDate);
      return trialEndDate < now;
    });
    
    console.log(`Found ${expiredSubs.length} expired trials with inconsistent 'active' status`);
    let fixed = 0;
    
    // Fix each inconsistent subscription
    for (const user of expiredSubs) {
      try {
        const userRef = doc(firestore, 'users', user.id);
        await updateDoc(userRef, {
          'subscription.status': 'expired',
          'subscription.expiryDate': now.toISOString(),
          'subscription.updateReason': 'Data consistency fix - Trial ended but status was still active'
        });
        fixed++;
        console.log(`Fixed inconsistent subscription for user: ${user.email || user.id}`);
      } catch (error) {
        console.error(`Failed to fix subscription for user ${user.id}:`, error);
      }
    }
    
    console.log(`Fixed ${fixed} inconsistent subscriptions`);
    return { fixed };
  } catch (error) {
    console.error('Error fixing inconsistent subscriptions:', error);
    throw error;
  }
};

/**
 * Setup function for initializing the scheduled tasks
 * This should be called during app initialization
 */
export const initializeScheduledTasks = () => {
  if (typeof window !== 'undefined') {
    // Only run in browser environment
    console.log('Setting up scheduled email tasks...');
    
    // Track if we've already initialized to prevent duplicate tasks
    if (window._scheduledTasksInitialized) {
      console.log('Scheduled tasks already initialized, skipping');
      return;
    }
    
    // Check for trial expirations once per day
    const checkTrialExpirations = () => {
      sendTrialExpirationReminders()
        .catch(err => console.error('Error running trial expiration check:', err));
      
      processExpiredTrials()
        .catch(err => console.error('Error processing expired trials:', err));
    };
    
    // Run after a 5 second delay (first run)
    setTimeout(checkTrialExpirations, 5000);
    
    // Schedule daily checks (86400000 ms = 24 hours)
    setInterval(checkTrialExpirations, 86400000);
    
    // Mark as initialized
    window._scheduledTasksInitialized = true;
    
    console.log('Scheduled tasks initialized');
  }
};

export default {
  sendTrialExpirationReminders,
  processExpiredTrials,
  initializeScheduledTasks,
  fixInconsistentSubscriptions
}; 
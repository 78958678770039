import React, { useEffect, useState } from 'react';
import { Modal, Button, CloseButton } from 'react-bootstrap';
import { useUserActivity } from '../contexts/UserActivityContext';
import { useAuth } from '../contexts/AuthContext';

const InactivityWarningModal = () => {
  const { warningShown, updateActivity, timeRemaining } = useUserActivity();
  const { currentUser } = useAuth();
  const [countdown, setCountdown] = useState(60); // 60 seconds countdown
  
  useEffect(() => {
    if (!warningShown) {
      setCountdown(60);
      return;
    }
    
    // Start countdown when warning is shown
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    
    return () => clearInterval(timer);
  }, [warningShown]);
  
  const handleStayLoggedIn = () => {
    updateActivity();
  };
  
  const handleClose = () => {
    updateActivity(); // Reset the timer when closing the modal
  };
  
  // Don't render if user is not logged in
  if (!currentUser) return null;
  
  return (
    <Modal 
      show={warningShown} 
      backdrop="static" 
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Session Timeout Warning</Modal.Title>
        <CloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <p>
          Due to inactivity, you will be automatically logged out in <strong>{countdown}</strong> seconds.
        </p>
        <p>
          This helps us reduce unnecessary API calls and preserve your API quota.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleStayLoggedIn}>
          Stay Logged In
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InactivityWarningModal; 
import { collection, query, where, getDocs, doc, updateDoc, increment } from 'firebase/firestore';
import { firestore } from '../config/firebase';

/**
 * Validates a promo code and returns discount information if valid
 * @param {string} code - The promo code to validate
 * @returns {Promise<Object>} - Object containing validation result and discount info
 */
export const validatePromoCode = async (code) => {
  if (!code || code.trim() === '') {
    return {
      valid: false,
      message: 'Please enter a promo code',
      discountPercentage: 0
    };
  }

  try {
    // Query Firestore for the promo code
    const promoCodesRef = collection(firestore, 'promoCodes');
    const q = query(promoCodesRef, where('code', '==', code.toUpperCase()));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return {
        valid: false,
        message: 'Invalid promo code',
        discountPercentage: 0
      };
    }

    // Get the promo code data
    const promoCodeDoc = querySnapshot.docs[0];
    const promoCodeData = promoCodeDoc.data();
    const promoCodeId = promoCodeDoc.id;

    // Check if the promo code is active
    if (!promoCodeData.active) {
      return {
        valid: false,
        message: 'This promo code is no longer active',
        discountPercentage: 0
      };
    }

    // Check if the promo code has expired
    if (promoCodeData.expiryDate) {
      const expiryDate = new Date(promoCodeData.expiryDate);
      const now = new Date();
      if (now > expiryDate) {
        return {
          valid: false,
          message: 'This promo code has expired',
          discountPercentage: 0
        };
      }
    }

    // Check if the promo code has reached its maximum uses
    if (promoCodeData.usedCount >= promoCodeData.maxUses) {
      return {
        valid: false,
        message: 'This promo code has reached its maximum number of uses',
        discountPercentage: 0
      };
    }

    // Promo code is valid
    return {
      valid: true,
      message: `${promoCodeData.discountPercentage}% discount applied`,
      discountPercentage: promoCodeData.discountPercentage,
      promoCodeId: promoCodeId
    };
  } catch (error) {
    console.error('Error validating promo code:', error);
    
    // If there's a permission error, try the fallback validation method
    if (error.code === 'permission-denied') {
      return validatePromoCodeFallback(code);
    }
    
    return {
      valid: false,
      message: 'Error validating promo code',
      discountPercentage: 0
    };
  }
};

/**
 * Fallback validation method when Firestore permissions fail
 * This validates common promo codes hardcoded in the app
 * In production, this would ideally call a server API endpoint
 */
const validatePromoCodeFallback = async (code) => {
  console.log('Using fallback validation for promo code:', code);
  
  // Normalize the code
  const normalizedCode = code.trim().toUpperCase();
  
  // List of valid promo codes
  // In a real-world scenario, this would be an API call to validate
  const validCodes = {
    'WELCOME10': { discountPercentage: 10, id: 'welcome-10-percent' },
    'WELCOME20': { discountPercentage: 20, id: 'welcome-20-percent' },
    'NEWUSER': { discountPercentage: 15, id: 'new-user-15-percent' },
    'SPRING2023': { discountPercentage: 25, id: 'spring-2023-promo' },
    'SUMMER50': { discountPercentage: 50, id: 'summer-50-percent' },
    'NBA2023': { discountPercentage: 30, id: 'nba-2023-30-percent' },
    'STATS25': { discountPercentage: 25, id: 'stats-25-percent' },
    'PREMIUM15': { discountPercentage: 15, id: 'premium-15-percent' },
    'CLUTCH50': { discountPercentage: 50, id: 'clutch-50-percent' },
    // Add any other promo codes that should be valid
  };
  
  // Check if the code exists in our valid codes
  if (validCodes[normalizedCode]) {
    console.log('Fallback validation success for code:', normalizedCode, 'with discount:', validCodes[normalizedCode].discountPercentage);
    return {
      valid: true,
      message: `${validCodes[normalizedCode].discountPercentage}% discount applied`,
      discountPercentage: validCodes[normalizedCode].discountPercentage,
      promoCodeId: validCodes[normalizedCode].id
    };
  }
  
  console.log('Fallback validation failed for code:', normalizedCode);
  return {
    valid: false,
    message: 'Invalid promo code',
    discountPercentage: 0
  };
};

/**
 * Calculates the discounted price based on the original price and discount percentage
 * @param {number} originalPrice - The original price
 * @param {number} discountPercentage - The discount percentage
 * @returns {Object} - Object containing the discounted price and discount amount
 */
export const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
  if (!originalPrice || !discountPercentage) {
    return {
      originalPrice: originalPrice || 0,
      discountedPrice: originalPrice || 0,
      discountAmount: 0
    };
  }

  const discountAmount = (originalPrice * discountPercentage) / 100;
  const discountedPrice = originalPrice - discountAmount;

  return {
    originalPrice,
    discountedPrice: Math.max(0, discountedPrice), // Ensure price doesn't go below 0
    discountAmount
  };
};

/**
 * Records the use of a promo code
 * @param {string} promoCodeId - The ID of the promo code
 * @returns {Promise<boolean>} - Whether the operation was successful
 */
export const recordPromoCodeUse = async (promoCodeId) => {
  if (!promoCodeId) return false;

  try {
    const promoCodeRef = doc(firestore, 'promoCodes', promoCodeId);
    await updateDoc(promoCodeRef, {
      usedCount: increment(1)
    });
    return true;
  } catch (error) {
    console.error('Error recording promo code use:', error);
    
    // If there's a permission error, log it but don't fail the checkout
    // In a production app, we might want to store this in localStorage 
    // or call a secure server API to record the usage
    if (error.code === 'permission-denied') {
      console.log('Permission denied when recording promo use. Will need admin to update counts.');
      
      // Store usage in localStorage for potential future sync
      try {
        const usedPromoCodes = JSON.parse(localStorage.getItem('usedPromoCodes') || '[]');
        usedPromoCodes.push({
          promoCodeId,
          timestamp: new Date().toISOString(),
          userId: localStorage.getItem('userId') || 'unknown'
        });
        localStorage.setItem('usedPromoCodes', JSON.stringify(usedPromoCodes));
      } catch (storageError) {
        console.warn('Error storing promo code use in localStorage:', storageError);
      }
      
      // Return true so checkout can still complete
      return true;
    }
    
    return false;
  }
}; 
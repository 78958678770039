import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Modal, Alert, Spinner } from 'react-bootstrap';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, query, orderBy } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './UpdatesManager.css';

const UpdatesManager = () => {
    const [updates, setUpdates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editingUpdate, setEditingUpdate] = useState(null);

    const [formData, setFormData] = useState({
        title: '',
        content: '',
        type: 'announcement' // Default type
    });

    // Quill editor modules and formats
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image'],
            ['clean']
        ],
    };
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'color', 'background',
        'link', 'image'
    ];

    // Fetch updates from Firestore
    const fetchUpdates = async () => {
        try {
            const updatesRef = collection(firestore, 'updates');
            const q = query(updatesRef, orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(q);
            const updatesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUpdates(updatesData);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching updates:', err);
            setError('Failed to load updates');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUpdates();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const updateData = {
                ...formData,
                createdAt: new Date().toISOString(),
                date: new Date().toISOString().split('T')[0]
            };

            if (editingUpdate) {
                // Update existing update
                await updateDoc(doc(firestore, 'updates', editingUpdate.id), updateData);
            } else {
                // Create new update
                await addDoc(collection(firestore, 'updates'), updateData);
            }

            // Reset form and refresh updates
            setFormData({ title: '', content: '', type: 'announcement' });
            setShowModal(false);
            setEditingUpdate(null);
            await fetchUpdates();
        } catch (err) {
            console.error('Error saving update:', err);
            setError('Failed to save update');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (updateId) => {
        if (window.confirm('Are you sure you want to delete this update?')) {
            setLoading(true);
            try {
                await deleteDoc(doc(firestore, 'updates', updateId));
                await fetchUpdates();
            } catch (err) {
                console.error('Error deleting update:', err);
                setError('Failed to delete update');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleEdit = (update) => {
        setEditingUpdate(update);
        setFormData({
            title: update.title,
            content: update.content,
            type: update.type
        });
        setShowModal(true);
    };

    // Handle content change for the rich text editor
    const handleContentChange = (content) => {
        setFormData({...formData, content});
    };

    return (
        <div className="updates-manager">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>Manage Updates</h3>
                <Button variant="primary" onClick={() => setShowModal(true)}>
                    Create New Update
                </Button>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}

            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div className="updates-list">
                    {updates.map(update => (
                        <Card key={update.id} className="mb-3 update-card">
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <Card.Title>{update.title}</Card.Title>
                                        {update.type === 'article' ? (
                                            <Card.Text>
                                                <em>Rich content article - preview not available</em>
                                            </Card.Text>
                                        ) : (
                                            <Card.Text>{update.content}</Card.Text>
                                        )}
                                        <div className="text-muted">
                                            Type: {update.type}
                                            <br />
                                            Date: {new Date(update.createdAt).toLocaleDateString()}
                                        </div>
                                    </div>
                                    <div className="update-actions">
                                        <Button 
                                            variant="outline-primary" 
                                            size="sm" 
                                            className="me-2"
                                            onClick={() => handleEdit(update)}
                                        >
                                            Edit
                                        </Button>
                                        <Button 
                                            variant="outline-danger" 
                                            size="sm"
                                            onClick={() => handleDelete(update.id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            )}

            <Modal 
                show={showModal} 
                onHide={() => {
                    setShowModal(false);
                    setEditingUpdate(null);
                    setFormData({ title: '', content: '', type: 'announcement' });
                }}
                size={formData.type === 'article' ? 'lg' : 'md'}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{editingUpdate ? 'Edit Update' : 'Create New Update'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                value={formData.title}
                                onChange={(e) => setFormData({...formData, title: e.target.value})}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Type</Form.Label>
                            <Form.Select
                                value={formData.type}
                                onChange={(e) => setFormData({...formData, type: e.target.value})}
                            >
                                <option value="announcement">Announcement</option>
                                <option value="update">Update</option>
                                <option value="tip">Tip</option>
                                <option value="article">Article</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Content</Form.Label>
                            {formData.type === 'article' ? (
                                <div className="rich-editor-container">
                                    <ReactQuill
                                        theme="snow"
                                        value={formData.content}
                                        onChange={handleContentChange}
                                        modules={modules}
                                        formats={formats}
                                        style={{ height: '300px', marginBottom: '50px' }}
                                    />
                                </div>
                            ) : (
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter content"
                                    value={formData.content}
                                    onChange={(e) => setFormData({...formData, content: e.target.value})}
                                    required
                                />
                            )}
                        </Form.Group>

                        <div className="d-flex justify-content-end">
                            <Button variant="secondary" className="me-2" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                {editingUpdate ? 'Save Changes' : 'Create Update'}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UpdatesManager; 
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FloatingLabel } from 'react-bootstrap';
import { FaCommentAlt } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import './FeedbackButton.css';
import { useLocation } from 'react-router-dom';

// EmailJS configuration using the same values as in SupportTab
const EMAILJS_SERVICE_ID = 'service_q47p57p';
const EMAILJS_TEMPLATE_ID = 'template_da14ql6';
const EMAILJS_PUBLIC_KEY = 'CEdz41fV7CQ6WGhes';

const FeedbackButton = () => {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    // Initialize EmailJS
    useEffect(() => {
        try {
            emailjs.init(EMAILJS_PUBLIC_KEY);
            console.log('EmailJS initialized successfully in FeedbackButton');
        } catch (error) {
            console.error('EmailJS initialization error:', error?.name);
        }
    }, []);

    // Check if we should hide the button based on current path
    const isAboutPage = location.pathname.includes('/about');
    const isSupportPage = location.pathname.includes('/support');
    
    // Don't render the button on the About or Support pages
    if (isAboutPage || isSupportPage) {
        return null;
    }

    const handleOpen = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false);
        // Reset form and status after modal is closed
        setTimeout(() => {
            setFormData({ name: '', email: '', message: '' });
            setSubmitStatus(null);
        }, 300);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        // Use the same EmailJS configuration as in SupportTab
        emailjs.send(
            EMAILJS_SERVICE_ID,
            EMAILJS_TEMPLATE_ID,
            {
                from_name: formData.name,
                from_email: formData.email,
                subject: 'Feedback from NBA Stats App',
                message: formData.message
            }
        )
        .then((response) => {
            console.log('Email sent successfully:', response);
            setSubmitStatus('success');
            setIsSubmitting(false);
            
            // Reset form after successful submission
            setFormData({ name: '', email: '', message: '' });
            
            // Close modal after a delay
            setTimeout(() => {
                handleClose();
            }, 3000);
        })
        .catch((error) => {
            console.error('Error sending email:', error);
            setSubmitStatus('error');
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <div className="feedback-button" onClick={handleOpen}>
                <FaCommentAlt />
                <span>Feedback</span>
            </div>

            <Modal show={showModal} onHide={handleClose} centered className="feedback-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Send Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {submitStatus === 'success' ? (
                        <div className="text-center py-4">
                            <div className="success-checkmark">
                                <div className="check-icon">
                                    <span className="icon-line line-tip"></span>
                                    <span className="icon-line line-long"></span>
                                </div>
                            </div>
                            <h4 className="mt-3">Thank You!</h4>
                            <p>Your feedback has been sent successfully.</p>
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <FloatingLabel label="Your Name" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Your Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            
                            <Form.Group className="mb-3">
                                <FloatingLabel label="Email Address" className="mb-3">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email Address"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            
                            <Form.Group className="mb-3">
                                <FloatingLabel label="Your Feedback" className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Your Feedback"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        style={{ height: '120px' }}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            
                            {submitStatus === 'error' && (
                                <div className="alert alert-danger" role="alert">
                                    There was an error sending your feedback. Please try again.
                                </div>
                            )}
                            
                            <div className="d-grid">
                                <Button 
                                    variant="primary" 
                                    type="submit" 
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Sending...' : 'Send Feedback'}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FeedbackButton; 
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import AvatarSelector from './AvatarSelector';
import UpdatesManager from './UpdatesManager';
import AdminTools from './AdminTools';
import './UserProfileTab.css';

console.log('UpdatesManager component:', UpdatesManager);

const UserProfileTab = () => {
    const {
        user,
        userSubscription,
        isAdmin,
        logout,
        isOnTrial,
        hasPaidSubscription,
        hasExpiredTrial,
        getSubscriptionType,
        getTrialDaysRemaining,
        updateUserAvatar
    } = useAuth();

    console.log('Is Admin:', isAdmin);

    const [showAvatarSelector, setShowAvatarSelector] = useState(false);
    const [currentAvatar, setCurrentAvatar] = useState('');
    const [avatarUpdated, setAvatarUpdated] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (user && user.photoURL) {
            setCurrentAvatar(user.photoURL);
        }
    }, [user]);

    // Add useEffect to check for payment success URL parameter
    useEffect(() => {
        // Check for payment success parameter in URL
        const params = new URLSearchParams(window.location.search);
        const paymentSuccess = params.get('paymentSuccess');
        const paymentCancelled = params.get('paymentCancelled');
        
        if (paymentSuccess === 'true') {
            // Show success message
            setAlertMessage('Thank you for your subscription! Your account is being updated. This may take a few minutes to reflect in your profile.');
            setAlertType('success');
            setShowAlert(true);
            
            // Remove the parameter from URL without reloading the page
            const url = new URL(window.location);
            url.searchParams.delete('paymentSuccess');
            window.history.replaceState({}, '', url);
            
            // Auto-hide alert after 10 seconds
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 10000);
            
            return () => clearTimeout(timer);
        }
        
        if (paymentCancelled === 'true') {
            // Show cancellation message
            setAlertMessage('Your payment was cancelled. You can try again when you\'re ready.');
            setAlertType('warning');
            setShowAlert(true);
            
            // Remove the parameter from URL without reloading the page
            const url = new URL(window.location);
            url.searchParams.delete('paymentCancelled');
            window.history.replaceState({}, '', url);
            
            // Auto-hide alert after 8 seconds
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 8000);
            
            return () => clearTimeout(timer);
        }
    }, []);

    // If user is not logged in, show a message
    if (!user) {
        return (
            <div className="user-profile-container">
                <Alert variant="warning">
                    <strong>Not Logged In</strong>
                    <p>Please log in to view your profile information.</p>
                </Alert>
            </div>
        );
    }

    // Format date for better readability
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    // Get badge color based on subscription type
    const getBadgeColor = () => {
        const subType = getSubscriptionType();
        switch (subType) {
            case 'admin': return 'primary';
            case 'paid': return 'success';
            case 'trial': return 'warning';
            case 'expired-trial': return 'danger';
            default: return 'secondary';
        }
    };

    // Get status text based on subscription type
    const getStatusText = () => {
        // First check if trial has expired - this takes precedence
        if (hasExpiredTrial()) return 'Expired Trial';
        
        const subType = getSubscriptionType();
        switch (subType) {
            case 'admin': return 'Administrator';
            case 'paid': return 'Premium Member';
            case 'trial': return 'Trial Member';
            case 'expired-trial': return 'Expired Trial';
            default: return 'Free Member';
        }
    };

    // Function to get subscription display text
    const getSubscriptionPlanText = () => {
        if (isAdmin) return 'Admin';
        if (hasExpiredTrial()) return 'Free (Expired Trial)';
        if (isOnTrial()) return 'Premium (Trial)';
        if (hasPaidSubscription()) return 'Premium';
        return 'Free';
    };

    const trialDays = getTrialDaysRemaining();

    // Determine which alerts to show
    const showAdminAlert = isAdmin;
    const showPaidAlert = hasPaidSubscription();
    const showTrialAlert = isOnTrial() && !hasExpiredTrial(); // Don't show trial alert if expired
    const showUpgradeSection = hasExpiredTrial() || (!hasPaidSubscription() && !isOnTrial() && !isAdmin);

    // Handle avatar selection
    const handleOpenAvatarSelector = () => {
        setShowAvatarSelector(true);
    };

    const handleCloseAvatarSelector = () => {
        setShowAvatarSelector(false);
    };

    const handleAvatarSelect = async (avatarUrl) => {
        setCurrentAvatar(avatarUrl);
        
        try {
            await updateUserAvatar(avatarUrl);
            setAvatarUpdated(true);
            
            // Reset the notification after 3 seconds
            setTimeout(() => {
                setAvatarUpdated(false);
            }, 3000);
        } catch (error) {
            console.error('Error updating avatar:', error);
            // You could add error handling here if needed
        }
    };

    return (
        <div className="user-profile-container">
            <Row>
                <Col md={12} lg={12}>
                    <Card className="profile-card">
                        <Card.Header>
                            <h4>User Profile</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className={`profile-avatar ${avatarUpdated ? 'new-avatar-selected' : ''}`}>
                                {currentAvatar ? (
                                    <img src={currentAvatar} alt="User Avatar" />
                                ) : (
                                    <div className="avatar-placeholder">
                                        {user.displayName ? user.displayName[0].toUpperCase() : user.email[0].toUpperCase()}
                                    </div>
                                )}
                                <Button 
                                    variant="outline-primary" 
                                    size="sm" 
                                    className="edit-avatar-btn"
                                    onClick={handleOpenAvatarSelector}
                                >
                                    Change Avatar
                                </Button>
                            </div>
                            <div className="profile-details">
                                <div className="user-name">
                                    {user.displayName || 'User'}
                                </div>
                                <div className="user-email">
                                    {user.email}
                                </div>
                                <div className={`status-badge bg-${getBadgeColor()} mt-2`}>
                                    {getStatusText()}
                                </div>
                                {avatarUpdated && (
                                    <div className="avatar-updated-alert">
                                        Avatar updated successfully!
                                    </div>
                                )}
                            </div>
                        </Card.Body>
                    </Card>

                    {/* Add UpdatesManager for admin users */}
                    {isAdmin && (
                        <>
                            <Card className="profile-card mt-4">
                                <Card.Header>
                                    <h4>Updates Management</h4>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <UpdatesManager />
                                </Card.Body>
                            </Card>
                            
                            <Card className="profile-card mt-4">
                                <Card.Header>
                                    <h4>Admin Tools</h4>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <AdminTools />
                                </Card.Body>
                            </Card>
                        </>
                    )}

                    <Card className="subscription-card">
                        <Card.Header>
                            <h4>Subscription Details</h4>
                        </Card.Header>
                        <Card.Body>
                            <table className="subscription-table">
                                <tbody>
                                    <tr>
                                        <td className="field-name">Account Status</td>
                                        <td className="field-value">{getStatusText()}</td>
                                    </tr>
                                    <tr>
                                        <td className="field-name">Subscription Type</td>
                                        <td className="field-value">
                                            {getSubscriptionPlanText()}
                                        </td>
                                    </tr>
                                    {isOnTrial() && (
                                        <tr>
                                            <td className="field-name">Trial Ends</td>
                                            <td className="field-value">
                                                {formatDate(userSubscription?.trialEnd)}
                                                {trialDays > 0 && (
                                                    <span className="days-left"> ({trialDays} days left)</span>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {hasPaidSubscription() && (
                                        <>
                                            <tr>
                                                <td className="field-name">Next Billing Date</td>
                                                <td className="field-value">
                                                    {formatDate(userSubscription?.currentPeriodEnd)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="field-name">Subscription Started</td>
                                                <td className="field-value">
                                                    {formatDate(userSubscription?.startDate)}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <td className="field-name">Account Created</td>
                                        <td className="field-value">
                                            {formatDate(user.metadata.creationTime)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="field-name">Last Login</td>
                                        <td className="field-value">
                                            {formatDate(user.metadata.lastSignInTime)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* Alerts section - only render if needed */}
                            {(showAdminAlert || showPaidAlert || showTrialAlert) && (
                                <div className="alerts-section">
                                    {showAdminAlert && (
                                        <Alert variant="primary" className="admin-alert">
                                            <strong>Administrator Account</strong>
                                            <p className="mb-0">You have admin privileges for this application.</p>
                                        </Alert>
                                    )}

                                    {showTrialAlert && (
                                        <Alert variant="warning" className="trial-alert">
                                            <strong>Trial Membership</strong>
                                            <p className="mb-0">You have {trialDays} days remaining in your trial. Upgrade to a paid plan to continue enjoying premium features.</p>
                                        </Alert>
                                    )}

                                    {showPaidAlert && (
                                        <Alert variant="success" className="paid-alert">
                                            <strong>Premium Membership</strong>
                                            <p className="mb-0">Thank you for being a premium member! You have full access to all features.</p>
                                        </Alert>
                                    )}
                                </div>
                            )}

                            {showUpgradeSection && (
                                <div className="upgrade-section">
                                    <Button variant="warning" href="/plans">
                                        Upgrade to Premium
                                    </Button>
                                </div>
                            )}

                            <div className="btn-container">
                                <Button className="logout-btn" onClick={logout}>
                                    Log Out
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>

                    {/* Add debug info */}
                    <div style={{display: 'none'}}>
                        Debug Info:
                        <pre>
                            {JSON.stringify({
                                isAdmin,
                                userRole: user?.role,
                                email: user?.email
                            }, null, 2)}
                        </pre>
                    </div>
                </Col>
            </Row>

            {/* Avatar Selector Modal */}
            <AvatarSelector 
                show={showAvatarSelector}
                handleClose={handleCloseAvatarSelector}
                onAvatarSelect={handleAvatarSelect}
                currentAvatarUrl={currentAvatar}
            />

            {showAlert && (
                <div className={`alert-message ${alertType}`}>
                    {alertMessage}
                </div>
            )}
        </div>
    );
};

export default UserProfileTab; 
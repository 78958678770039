import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import './PromoBanner.css';

const PromoBanner = () => {
  const [banner, setBanner] = useState(null);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  
  // Function to fetch banner data
  const fetchBanner = async () => {
    try {
      const bannerRef = doc(firestore, 'settings', 'banner');
      const bannerDoc = await getDoc(bannerRef);
      
      if (bannerDoc.exists()) {
        const bannerData = bannerDoc.data();
        if (bannerData.active) {
          setBanner(bannerData);
          
          // Only respect session storage for manually closed banners
          // If admin changed the banner since last closure, show it anyway
          const lastBannerId = localStorage.getItem('lastBannerId');
          const currentBannerId = bannerData.updatedAt || '';
          
          if (localStorage.getItem('promoBannerClosed') === 'true' && lastBannerId === currentBannerId) {
            setVisible(false);
          } else {
            setVisible(true);
            // Store current banner ID to track changes
            localStorage.setItem('lastBannerId', currentBannerId);
            // Reset closed state when banner is updated by admin
            localStorage.removeItem('promoBannerClosed');
          }
        } else {
          setBanner(null);
          setVisible(false);
        }
      } else {
        setBanner(null);
        setVisible(false);
      }
    } catch (error) {
      console.error('Error fetching banner:', error);
      setBanner(null);
      setVisible(false);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch when component mounts
  useEffect(() => {
    fetchBanner();
    
    // Set up periodic refresh (every minute)
    const intervalId = setInterval(() => {
      fetchBanner();
    }, 60 * 1000);
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  // Also fetch when route changes (user navigates to a different page)
  useEffect(() => {
    const handleRouteChange = () => {
      fetchBanner();
    };
    
    window.addEventListener('popstate', handleRouteChange);
    
    // Add listener for page visibility changes (tab focus/unfocus)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        fetchBanner();
      }
    });
    
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
      document.removeEventListener('visibilitychange', fetchBanner);
    };
  }, []);

  const handleClose = () => {
    setVisible(false);
    // Store in localStorage so it persists across tabs but in the same browser
    localStorage.setItem('promoBannerClosed', 'true');
    
    // Store banner ID to know if admin updated it
    if (banner && banner.updatedAt) {
      localStorage.setItem('lastBannerId', banner.updatedAt);
    }
  };

  if (loading || !banner || !visible) {
    return null;
  }

  const bannerStyle = {
    backgroundColor: banner.backgroundColor || '#29abe2',
    color: banner.textColor || '#ffffff'
  };

  return (
    <div className="promo-banner" style={bannerStyle}>
      <div className="promo-banner-content">
        <span>{banner.text}</span>
        {banner.link && (
          <a href={banner.link} className="promo-banner-link" style={{ color: banner.textColor || '#ffffff' }}>
            Learn More
          </a>
        )}
      </div>
      <button className="promo-banner-close" onClick={handleClose} style={{ color: banner.textColor || '#ffffff' }}>
        ×
      </button>
    </div>
  );
};

export default PromoBanner; 
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Modal, Table, Spinner, Alert, Badge, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import './PlayerPropsModal.css';
import { API_BASE_URL } from '../config/constants';
import { BsArrowUp, BsArrowDown, BsDash, BsStar, BsInfoCircle, BsArrowClockwise } from 'react-icons/bs';
import PropsTable from './PropsTable';

// Add jQuery reference 
const $ = window.$ || {};

const PlayerPropsModal = ({ show, onHide, gameData, propsData, loading, refreshProps }) => {
    const [historicalData, setHistoricalData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentProps, setCurrentProps] = useState(null);
    const [historicalProps, setHistoricalProps] = useState(null);
    const [historicalError, setHistoricalError] = useState(null);
    const [error, setError] = useState(null);
    const [localPropsData, setLocalPropsData] = useState(null);
    const [activeTab, setActiveTab] = useState('AllProps');
    const [sortKey, setSortKey] = useState('edge');
    const [sortDirection, setSortDirection] = useState('desc');
    const [searchTerm, setSearchTerm] = useState('');
    const [showConfidenceOptions, setShowConfidenceOptions] = useState(false);

    // Add useRef to track component mount state
    const isMounted = useRef(false);

    // Add useEffect for component mount
    useEffect(() => {
        console.log("PlayerPropsModal mounted");
        isMounted.current = true;
        
        // Initialize tooltips on component mount if modal is shown
        if (show) {
            const mountTimeout = setTimeout(() => {
                initializeAllTooltips();
            }, 300);
            
            return () => clearTimeout(mountTimeout);
        }
        
        // Cleanup function to run when component unmounts
        return () => {
            console.log("PlayerPropsModal unmounted");
            isMounted.current = false;
            
            // Clean up any tooltips when component unmounts
            try {
                if (window.$ && window.$.fn && window.$.fn.tooltip) {
                    $('[data-toggle="tooltip"]').tooltip('dispose');
                }
            } catch (error) {
                console.error('Error cleaning up tooltips:', error);
            }
        };
    }, []);

    useEffect(() => {
        console.log('PlayerPropsModal rendered with props:', { 
            gameData, 
            propsData,
            propsDataStructure: propsData ? Object.keys(propsData) : 'No propsData',
            dataProperty: propsData?.data ? Object.keys(propsData.data) : 'No data property',
            bookmakers: propsData?.data?.bookmakers ? propsData.data.bookmakers.length : 'No bookmakers',
            recommendations: propsData?.data?.fanduel_recommendations ? 'Has recommendations' : 'No recommendations',
            marketEfficiency: propsData?.data?.market_efficiency ? 'Has market efficiency' : 'No market efficiency'
        });
        
        if (propsData && !localPropsData) {
            console.log("Setting localPropsData with propsData");
            // Check for movement data in propsData
            if (propsData.data && propsData.data.bookmakers) {
                let movementFound = false;
                let movementSamples = [];
                
                // Sample a few outcomes to check for movement data
                for (const bookmaker of propsData.data.bookmakers.slice(0, 2)) {
                    for (const market of bookmaker.markets.slice(0, 2)) {
                        for (const outcome of market.outcomes.slice(0, 2)) {
                            if (outcome.movement) {
                                movementFound = true;
                                movementSamples.push({
                                    player: outcome.description,
                                    market: market.key,
                                    movement: outcome.movement
                                });
                            }
                        }
                    }
                }
                
                console.log("Movement data check:", {
                    found: movementFound,
                    samples: movementSamples
                });
            }
            
            setLocalPropsData(propsData);
        }
    }, [gameData, propsData, localPropsData]);

    useEffect(() => {
        const fetchHistoricalData = async () => {
            if (!gameData?.id) return;
            
            try {
                const response = await fetch(`${API_BASE_URL}/api/historical-props/${gameData.id}`, {
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json'
                    }
                });
                const data = await response.json();
                
                if (data.status === 'success') {
                    setHistoricalData(data);
                    setHistoricalError(null);
                } else {
                    setHistoricalError(data.message);
                    setHistoricalData(null);
                }
            } catch (error) {
                console.warn('Historical props not available:', error);
                setHistoricalError('Unable to fetch historical data');
                setHistoricalData(null);
            }
        };

        fetchHistoricalData();
    }, [gameData]);

    useEffect(() => {
        if (show && gameData?.id) {
            // Only fetch props if we don't already have data for this game
            const currentGameId = localPropsData?.data?.game_id;
            if (!localPropsData || currentGameId !== gameData.id) {
            fetchProps();
        }
        }
    }, [show, gameData?.id, localPropsData]);

    const fetchProps = async () => {
        try {
            setIsLoading(true);
            setError(null);
            
            console.log(`Fetching props for game ID: ${gameData.id}`);
            
            // Fetch current props
            const response = await fetch(`${API_BASE_URL}/api/player-props/${gameData.id}`, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });
            const current = await response.json();
            
            // Fetch historical props
            const histResponse = await fetch(`${API_BASE_URL}/api/historical-props/${gameData.id}`, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });
            const historical = await histResponse.json();
            
            // Log detailed information about the historical data
            console.log("Historical data response:", {
                status: historical.status,
                hasSnapshots: historical.data && historical.data.snapshots ? historical.data.snapshots.length : 0,
                timespan: historical.data && historical.data.snapshots && historical.data.snapshots.length > 1 ? 
                    `${historical.data.snapshots[0].timestamp} to ${historical.data.snapshots[historical.data.snapshots.length-1].timestamp}` : 'N/A'
            });
            
            // Log detailed information about the response
            console.log("API Response:", {
                current: current,
                historical: historical
            });
            
            // Check if we have valid bookmakers data
            const bookmakers = current?.data?.bookmakers || [];
            console.log(`Received ${bookmakers.length} bookmakers from API`);
            
            if (bookmakers.length === 0) {
                console.warn("No bookmakers data found in API response");
                console.log("Full API response:", JSON.stringify(current));
            } else {
                // Check for movement data in the response
                let movementFound = false;
                for (const bookmaker of bookmakers.slice(0, 1)) {
                    for (const market of bookmaker.markets.slice(0, 1)) {
                        for (const outcome of market.outcomes.slice(0, 3)) {
                            if (outcome.movement) {
                                movementFound = true;
                                console.log("Movement data example:", {
                                    player: outcome.description,
                                    market: market.key,
                                    movement: outcome.movement
                                });
                                break;
                            }
                        }
                        if (movementFound) break;
                    }
                    if (movementFound) break;
                }
                
                if (!movementFound) {
                    console.log("No movement data found in API response - this might be why the movement column is empty");
                }
                
                // Log each bookmaker and their markets
                bookmakers.forEach(bookmaker => {
                    console.log(`Bookmaker: ${bookmaker.title}, Markets: ${bookmaker.markets?.length || 0}`);
                });
            }
            
            // Only update localPropsData if we have new data or no existing data
            const currentGameId = current?.data?.game_id;
            const localGameId = localPropsData?.data?.game_id;
            
            if (!localPropsData || currentGameId !== localGameId) {
                console.log("Setting localPropsData with current data");
                setLocalPropsData(current);
            } else {
                console.log("Keeping existing localPropsData");
            }
            
            setHistoricalData(historical);
            console.log("Fetched props data:", current);
            
        } catch (err) {
            console.error("Error fetching props:", err);
            setError("Failed to load player props data");
        } finally {
            setIsLoading(false);
        }
    };

    const calculateMedian = (numbers) => {
        if (!numbers.length) return null;
        const sorted = numbers.slice().sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);
        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }
        return sorted[middle];
    };

    const formatOdds = (odds) => {
        if (odds === null || odds === undefined) return '-';
        return odds > 0 ? `+${odds}` : odds;
    };

    const american_to_implied_prob = (odds) => {
        if (odds === null || odds === undefined) return 0;
        if (odds > 0) {
            return 100 / (odds + 100);
        } else {
            return Math.abs(odds) / (Math.abs(odds) + 100);
        }
    };

    const getOddsClass = (odds) => {
        if (!odds) return '';
        return odds > 0 ? 'positive-odds' : 'negative-odds';
    };

    const getGradeColor = (grade) => {
        const colors = {
            'A+': '#28a745',
            'A': '#34c759',
            'B+': '#5856d6',
            'B': '#7d7aff',
            'C+': '#ff9500',
            'C': '#ff3b30',
            'C-': '#ff453a',
            'D': '#dc3545',
            'N/A': '#6c757d'
        };
        return colors[grade] || '#6c757d';
    };

    const calculateValueGrade = (currentProps, historicalStats) => {
        if (!currentProps?.line || !historicalStats?.median_line) return 'N/A';

        const lineDiff = Math.abs(currentProps.line - historicalStats.median_line);
        const linePercentDiff = (lineDiff / historicalStats.median_line) * 100;
        
        const currentOverOdds = oddsToProb(currentProps.over);
        const historicalOverRate = historicalStats.over_win_rate * 100;
        const oddsEdge = Math.abs(currentOverOdds - historicalOverRate);

        const score = (linePercentDiff * 0.6) + (oddsEdge * 0.4);

        if (score >= 15) return 'A+';
        if (score >= 12) return 'A';
        if (score >= 9) return 'B+';
        if (score >= 7) return 'B';
        if (score >= 5) return 'C+';
        if (score >= 3) return 'C';
        if (score >= 1) return 'C-';
        return 'D';
    };

    const oddsToProb = (odds) => {
        if (!odds) return 0;
        return odds > 0 
            ? (100 / (odds + 100)) * 100 
            : (Math.abs(odds) / (Math.abs(odds) + 100)) * 100;
    };

    const formatCategory = (category) => {
        return category.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    };

    const processPropsData = (rawPropsData) => {
        if (!rawPropsData?.bookmakers) return {};
        
        console.log("Processing props data for movement tracking");
        let movementTracking = {
            totalOutcomes: 0,
            outcomesWithMovement: 0,
            movementValues: []
        };
        
        const processedData = {};
        
        rawPropsData.bookmakers.forEach(bookmaker => {
            bookmaker.markets.forEach(market => {
                if (!processedData[market.key]) {
                    processedData[market.key] = { players: {} };
                }
                
                market.outcomes.forEach(outcome => {
                    movementTracking.totalOutcomes++;
                    if (outcome.movement) {
                        movementTracking.outcomesWithMovement++;
                        if (movementTracking.movementValues.length < 5) { // Just sample a few
                            movementTracking.movementValues.push({
                                player: outcome.description,
                                market: market.key,
                                line_movement: outcome.movement.line_movement,
                                odds_movement: outcome.movement.odds_movement
                            });
                        }
                    }
                    
                    const playerName = outcome.description;
                    if (!processedData[market.key].players[playerName]) {
                        processedData[market.key].players[playerName] = {};
                    }
                    if (!processedData[market.key].players[playerName][bookmaker.key]) {
                        processedData[market.key].players[playerName][bookmaker.key] = {
                            line: outcome.point
                        };
                    }
                    processedData[market.key].players[playerName][bookmaker.key][outcome.name.toLowerCase()] = outcome.price;
                    
                    // Add movement data when available
                    if (outcome.movement) {
                        processedData[market.key].players[playerName].movement = outcome.movement;
                    }
                });
            });
        });
        
        console.log("Movement tracking results:", movementTracking);
        return processedData;
    };

    const getBetRecommendation = (odds, historicalStats) => {
        if (!historicalStats || !odds) return null;

        const overRate = historicalStats.over_win_rate;
        const medianLine = historicalStats.median_line;
        const currentLine = odds.line;

        if (!overRate || !medianLine || !currentLine || historicalStats.total_games < 10) {
            return null;
        }

        const lineEdge = (medianLine - currentLine) / medianLine;
        const overEdge = overRate - 0.5;

        if (Math.abs(lineEdge) > 0.1 || Math.abs(overEdge) > 0.15) {
            return {
                recommendation: lineEdge > 0 ? 'OVER' : 'UNDER',
                confidence: Math.min(Math.abs(lineEdge) * 100, Math.abs(overEdge) * 100)
            };
        }

        return null;
    };

    const calculateProjectedValue = (currentLine, historicalLine, odds, winRate) => {
        if (!currentLine || !historicalLine || !odds || !winRate) return 0;

        const oddsProb = oddsToProb(odds);
        const historicalProb = winRate * 100;
        const lineDiff = Math.abs(currentLine - historicalLine) / historicalLine;

        const probEdge = Math.abs(oddsProb - historicalProb);
        const lineEdge = lineDiff * 100;

        return (probEdge * 0.7) + (lineEdge * 0.3);
    };

    const getBestOdds = (props) => {
        if (!props) return null;
        
        const bookmakers = Object.keys(props);
        if (bookmakers.length === 0) return null;

        let bestBook = bookmakers[0];
        let bestValue = -Infinity;

        bookmakers.forEach(bookmaker => {
            const overValue = props[bookmaker].over || -Infinity;
            const underValue = props[bookmaker].under || -Infinity;
            const maxValue = Math.max(overValue, underValue);
            
            if (maxValue > bestValue) {
                bestValue = maxValue;
                bestBook = bookmaker;
            }
        });

        return {
            ...props[bestBook],
            bookmaker: bestBook
        };
    };

    const getBestBookmaker = (props, category) => {
        if (!props) return null;
        
        const bestOdds = getBestOdds(props);
        if (!bestOdds) return null;

        const bookmakers = Object.keys(props);
        let bestValue = -Infinity;
        let bestBookmaker = null;

        bookmakers.forEach(bookmaker => {
            const bookmakerProps = props[bookmaker];
            const overValue = bookmakerProps?.over || -Infinity;
            const underValue = bookmakerProps?.under || -Infinity;
            const maxValue = Math.max(overValue, underValue);
            
            if (maxValue > bestValue) {
                bestValue = maxValue;
                bestBookmaker = bookmaker;
            }
        });

        return {
            name: bestBookmaker,
            value: bestValue,
            category,
            line: props[bestBookmaker]?.line,
            overOdds: props[bestBookmaker]?.over,
            underOdds: props[bestBookmaker]?.under
        };
    };

    const renderPlayerName = (player, stats, props) => {
        const bestOdds = getBestOdds(props);
        const betRec = getBetRecommendation(bestOdds, stats);

        return (
            <div className="player-name-container">
                {betRec && (
                    <Badge 
                        bg={betRec.recommendation === 'OVER' ? 'success' : 'danger'}
                        className="bet-direction-badge"
                    >
                        BET {betRec.recommendation}
                    </Badge>
                )}
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            <div>Last {stats?.total_games || 0} Games:</div>
                            <div>Median: {stats?.median_line?.toFixed(1) || 'N/A'}</div>
                            <div>Over Rate: {stats?.over_win_rate ? `${(stats.over_win_rate * 100).toFixed(1)}%` : 'N/A'}</div>
                            {stats?.last_10 && (
                                <div className="last-10-trend">
                                    <div>Recent Results:</div>
                                    {stats.last_10.map((game, i) => (
                                        <div key={i} className="game-result">
                                            Line: {game.line} | Actual: {game.actual}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Tooltip>
                    }
                >
                    <span className="player-name">{player}</span>
                </OverlayTrigger>
            </div>
        );
    };

    const renderLineMovement = (movement) => {
        if (!movement) {
            console.log("Movement data is null or undefined");
            return null;
        }
        
        console.log("Movement data received:", movement);
        
        // Lower threshold from 0.5 to 0.1 for line movement and from 5 to 2 for odds movement
        if (Math.abs(movement.line_movement) < 0.1 && Math.abs(movement.odds_movement) < 2) {
            console.log("Movement below threshold - Line:", movement.line_movement, "Odds:", movement.odds_movement);
            return null;
        }

        const { line_movement, odds_movement, time_elapsed } = movement;
        
        console.log("Displaying movement - Line:", line_movement, "Odds:", odds_movement, "Time:", time_elapsed);
        
        const getMovementColor = (value) => {
            if (value > 0) return 'text-success';
            if (value < 0) return 'text-danger';
            return 'text-muted';
        };

        return (
            <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip>
                        <div>Line moved: {line_movement > 0 ? '+' : ''}{line_movement.toFixed(1)}</div>
                        <div>Odds moved: {odds_movement > 0 ? '+' : ''}{odds_movement}</div>
                        <div>Over {time_elapsed ? time_elapsed.toFixed(1) : '?'} hours</div>
                    </Tooltip>
                }
            >
                <span className={`movement-indicator ${getMovementColor(line_movement)}`}>
                    {line_movement > 0 ? <BsArrowUp /> : line_movement < 0 ? <BsArrowDown /> : <BsDash />}
                </span>
            </OverlayTrigger>
        );
    };

    const FanDuelRecommendations = ({ recommendations }) => {
        // First define all hooks before any conditional returns
        // Initialize tooltips specifically for this component
        const hasRecommendations = !!recommendations;
        
        useEffect(() => {
            console.log("🔍 TOOLTIP DEBUG: FanDuelRecommendations component mounted");
            
            if (!hasRecommendations) {
                console.log("🔍 TOOLTIP DEBUG: No recommendations data available");
                return;
            }
            
            // Debug log full recommendations data
            console.log("🔍 FULL RECOMMENDATIONS DATA:", JSON.stringify(recommendations));
            
            // Check for market efficiency data in bets
            const checkForEfficiency = (bets) => {
                if (!bets || !bets.length) return false;
                let hasEfficiency = false;
                bets.forEach((bet, index) => {
                    console.log(`🔍 Checking bet ${index}:`, bet);
                    if (bet.market_efficiency !== undefined) {
                        console.log(`🔍 Found market_efficiency: ${bet.market_efficiency}`);
                        hasEfficiency = true;
                    } else {
                        console.log(`🔍 No market_efficiency found in bet`);
                    }
                });
                return hasEfficiency;
            };
            
            const overHasEfficiency = checkForEfficiency(recommendations.best_over_bets);
            const underHasEfficiency = checkForEfficiency(recommendations.best_under_bets);
            console.log(`🔍 Market efficiency found in bets: Over=${overHasEfficiency}, Under=${underHasEfficiency}`);
            
            // Allow time for DOM rendering
            const tooltipTimeout = setTimeout(() => {
                try {
                    if (window.$ && window.$.fn && window.$.fn.tooltip) {
                        console.log("🔍 TOOLTIP DEBUG: Initializing FanDuel recommendation tooltips");
                        $('.bet-cell [data-toggle="tooltip"]').tooltip('dispose').tooltip({
                            html: true,
                            container: 'body',
                            placement: 'auto',
                            template: '<div class="tooltip enhanced-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                        });
                    }
                } catch (error) {
                    console.error("Error initializing FanDuel tooltips:", error);
                }
            }, 500);
            
            return () => clearTimeout(tooltipTimeout);
        }, [hasRecommendations]);

        // Now do the conditional return
        if (!recommendations) return null;

        // Debug for data structure
        console.log("FanDuelRecommendations data:", recommendations);

        const { best_over_bets = [], best_under_bets = [] } = recommendations;

        // Helper function to group bets by market type
        const groupBetsByMarket = (bets) => {
            console.log("Grouping bets by market:", bets);
            return bets?.reduce((acc, bet) => {
                const marketKey = bet.market_key;
                if (!acc[marketKey]) {
                    acc[marketKey] = [];
                }
                acc[marketKey].push(bet);
                return acc;
            }, {}) || {};
        };

        // Format market names for display
        const formatCategory = (market) => {
            const categories = {
                'player_points': 'Points',
                'player_rebounds': 'Rebounds',
                'player_assists': 'Assists',
                'player_threes': '3-Pointers',
                'player_pts_rebs_asts': 'PRA',
                'player_pts_rebs': 'Points + Rebounds',
                'player_pts_asts': 'Points + Assists',
                'player_rebs_asts': 'Rebounds + Assists'
            };
            return categories[market] || market.replace('player_', '').replace(/_/g, ' ');
        };

        // Group bets by market
        const overBetsByMarket = groupBetsByMarket(best_over_bets);
        const underBetsByMarket = groupBetsByMarket(best_under_bets);

        // Get all markets from both over and under bets
        const allMarkets = [...new Set([
            ...Object.keys(overBetsByMarket),
            ...Object.keys(underBetsByMarket)
        ])].sort();

        console.log("All markets:", allMarkets);
        console.log("Over bets by market:", overBetsByMarket);
        console.log("Under bets by market:", underBetsByMarket);

        return (
            <div className="fanduel-recommendations mb-4">
                <h5 className="recommendations-header">
                    <span className="fanduel-logo">FanDuel</span> Top Recommendations
                </h5>
                <div className="table-responsive">
                    <table className="table table-dark table-bordered recommendations-table">
                        <thead>
                            <tr>
                                <th>Market</th>
                                <th>Best Over Bets</th>
                                <th>Best Under Bets</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allMarkets.map((market) => (
                                <tr key={market}>
                                    <td className="market-cell">
                                        {formatCategory(market)}
                                    </td>
                                    <BetCell bet={overBetsByMarket[market]?.[0]} type="over" market={market} />
                                    <BetCell bet={underBetsByMarket[market]?.[0]} type="under" market={market} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    // Separate component for bet cells to improve readability
    const BetCell = ({ bet, type, market }) => {
        // Move all hooks before conditional returns
        const hasBet = !!bet;
        
        // Generate tooltip content - safely handle case where bet is null
        const tooltipContent = useMemo(() => {
            if (!hasBet) return null;
            
            console.log(`Generating tooltip for ${type} bet:`, bet);
            
            // Create reasons array
            const reasons = [];

            // Add consensus edge if available
            if (bet.consensus_edge && bet.consensus_edge > 0) {
                reasons.push(`Edge: ${bet.consensus_edge.toFixed(1)}% advantage vs. market consensus`);
            }

            // Add historical win rate if available
            if (bet.historical_win_rate && bet.historical_win_rate > 0) {
                const rate = (bet.historical_win_rate * 100).toFixed(0);
                const sample = bet.sample_size || 0;
                reasons.push(`Historical Success: ${rate}% win rate (${sample} samples)`);
            }

            // Add pinnacle edge if available
            if (bet.pinnacle_edge && bet.pinnacle_edge > 0) {
                reasons.push(`Sharp Book Edge: ${bet.pinnacle_edge.toFixed(1)}% vs Pinnacle odds`);
            }

            // Process market efficiency data
            let efficiencyText = '';
            let efficiencyColor = '';
            let efficiencyBgColor = '';
            let efficiencyPercentage = '';
            let hasEfficiency = false;
            
            // Enhanced market efficiency lookup with detailed logging and fallback behavior
            console.log("Looking for market efficiency in bet:", {
                bet_market_key: bet.market_key,
                has_direct_efficiency: bet.market_efficiency !== undefined,
                efficiency_value: bet.market_efficiency
            });

            // Get efficiency value with fallback
            let efficiency = null;
            if (bet.market_efficiency !== undefined) {
                // Direct access (preferred)
                efficiency = parseFloat(bet.market_efficiency);
                console.log("Using direct market efficiency value:", efficiency);
                hasEfficiency = true;
            }

            // Proceed if we have efficiency data
            if (efficiency !== null) {
                hasEfficiency = true;
                efficiencyPercentage = (efficiency * 100).toFixed(0);
                
                if (efficiency < 0.6) {
                    efficiencyText = 'Low (more value)';
                    efficiencyColor = '#28a745';
                    efficiencyBgColor = 'rgba(40, 167, 69, 0.1)';
                } else if (efficiency < 0.8) {
                    efficiencyText = 'Medium (some value)';
                    efficiencyColor = '#ffc107';
                    efficiencyBgColor = 'rgba(255, 193, 7, 0.1)';
                } else {
                    efficiencyText = 'High (fewer opportunities)';
                    efficiencyColor = '#dc3545';
                    efficiencyBgColor = 'rgba(220, 53, 69, 0.1)';
                }
                
                reasons.push(`Market Efficiency: ${efficiencyText}`);
                console.log("Market efficiency processed:", {
                    text: efficiencyText,
                    color: efficiencyColor,
                    percentage: efficiencyPercentage,
                    hasEfficiency
                });
            } else {
                console.log("No market efficiency data found for this bet");
            }
            
            // Add default reason if no reasons
            if (reasons.length === 0) {
                reasons.push('Based on our betting algorithm analysis');
            }
            
            return (
                <div style={{ maxWidth: '280px', textAlign: 'left' }}>
                    <div style={{ fontWeight: 'bold', borderBottom: '1px solid rgba(255,255,255,0.2)', paddingBottom: '4px', marginBottom: '6px' }}>
                        {bet.description || ''} {bet.point || ''} {bet.name || type.toUpperCase()}
                    </div>
                    
                    {/* Direct efficiency indicator rendering */}
                    {hasEfficiency && (
                        <div style={{ 
                            margin: '0 0 10px 0',
                            padding: '8px',
                            borderRadius: '4px',
                            backgroundColor: efficiencyBgColor,
                            borderLeft: `3px solid ${efficiencyColor}`
                        }}>
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center'
                            }}>
                                <div>
                                    <div style={{ fontWeight: 'bold', fontSize: '13px' }}>Market Efficiency</div>
                                    <div style={{ fontSize: '12px' }}>{efficiencyText}</div>
                                </div>
                                <div style={{ 
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: efficiencyColor,
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '15px',
                                    marginLeft: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                                }}>
                                    {efficiencyPercentage}%
                                </div>
                            </div>
                        </div>
                    )}
                    
                    <div style={{ marginBottom: '4px' }}>
                        <strong>Recommendation basis:</strong>
                    </div>
                    <ul style={{ paddingLeft: '16px', margin: 0 }}>
                        {reasons.map((reason, index) => {
                            const [label, explanation] = reason.includes(':') 
                                ? [reason.split(':')[0], reason.split(':').slice(1).join(':').trim()] 
                                : [null, reason];
                            
                            return (
                                <li key={index} style={{ marginBottom: '3px', fontSize: '13px' }}>
                                    {label ? (
                                        <>
                                            <strong>{label}:</strong> {explanation}
                                        </>
                                    ) : (
                                        reason
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                    <div style={{ marginTop: '6px', paddingTop: '4px', borderTop: '1px solid rgba(255,255,255,0.1)' }}>
                        <strong style={{ color: '#28a745' }}>Confidence: {bet.edge_rating ? '★'.repeat(bet.edge_rating) : 'N/A'}</strong>
                        <div style={{ fontSize: '12px', color: '#aaa', marginTop: '2px' }}>
                            Odds: {bet.price ? (bet.price > 0 ? '+' + bet.price : bet.price) : 'N/A'}
                        </div>
                    </div>
                </div>
            );
        }, [bet, type, hasBet]);

        // Log the tooltip component for debugging
        useEffect(() => {
            if (!hasBet) return;
            console.log(`🔍 TOOLTIP DEBUG: BetCell mounted for ${type} bet in ${market} market`);
            if (!hasBet) {
                console.log(`🔍 TOOLTIP DEBUG: No bet data for ${type} in ${market}`);
            }
        }, [market, type, hasBet]);

        if (!bet) return <td className="bet-cell">-</td>;
        
        return (
            <td className="bet-cell">
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Tooltip className="enhanced-tooltip">
                            {tooltipContent}
                        </Tooltip>
                    }
                >
                    <div className="bet-content">
                        <div className="bet-player">{bet.description}</div>
                        <div className="bet-details">
                            <span className="bet-point">{bet.point}</span>
                            <span className={`bet-type ${type === 'over' ? 'over-type' : 'under-type'}`}>
                                {bet.name || type.toUpperCase()}
                            </span>
                            {bet.price && (
                                <span className={`bet-odds ${bet.price > 0 ? 'positive-odds' : 'negative-odds'}`}>
                                    {bet.price > 0 ? '+' : ''}{bet.price}
                                </span>
                            )}
                        </div>
                        {bet.edge_rating > 0 && (
                            <div className="bet-rating">
                                {[...Array(bet.edge_rating)].map((_, i) => (
                                    <span key={i} className="rating-star">★</span>
                                ))}
                            </div>
                        )}
                    </div>
                </OverlayTrigger>
            </td>
        );
    };

    const EVBadge = ({ odds, pinnacleOdds, allOdds }) => {
        const evData = calculateEV(pinnacleOdds, odds, allOdds);
        
        if (!evData) return null;

        const getStarRating = (edge) => {
            const edgeValue = parseFloat(edge);
            if (edgeValue >= 8) return 5;
            if (edgeValue >= 6) return 4;
            if (edgeValue >= 4) return 3;
            if (edgeValue >= 2) return 2;
            if (edgeValue > 0) return 1;
            return 0;
        };

        const starRating = getStarRating(evData.ev);

        return (
            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip className="ev-tooltip">
                        <div>Market Average: {evData.marketAvgProb}%</div>
                        <div>Edge: +{evData.ev}%</div>
                        {evData.isSignificant && 
                            <div className="significant-edge">
                                {[...Array(starRating)].map((_, i) => (
                                    <span key={i} style={{ color: '#ffc107' }}>★</span>
                                ))}
                            </div>
                        }
                    </Tooltip>
                }
            >
                <Badge className={`ev-badge ${getEVClass(parseFloat(evData.ev))}`}>
                    {evData.ev}%
                    {evData.isSignificant && starRating > 0 && (
                        <span className="edge-rating">
                            <span style={{ color: '#ffc107' }}>★</span>
                        </span>
                    )}
                </Badge>
            </OverlayTrigger>
        );
    };

    const renderPropsTable = () => {
        console.log(`renderPropsTable called with bookmakers: ${localPropsData?.data?.bookmakers?.length || 0}`);
        
        if (!localPropsData || !localPropsData.data || !localPropsData.data.bookmakers || localPropsData.data.bookmakers.length === 0) {
            console.log("No bookmakers data available in propsData:", localPropsData);
            return (
                <Alert variant="info">
                    No player props data available for this game. Props are typically available a few hours before game time.
                </Alert>
            );
        }
        
        // Log the structure of the bookmakers data
        console.log("Rendering props table with bookmakers:", 
            localPropsData.data.bookmakers.map(b => `${b.title} (${b.markets?.length || 0} markets)`).join(", ")
        );
        
        // Group props by market type first, then by player
        const propsByMarket = {};
        
        // Define the markets we want to display
        const marketTypes = ['player_points', 'player_rebounds', 'player_assists', 'player_threes'];
        
        // Initialize the market objects
        marketTypes.forEach(marketType => {
            propsByMarket[marketType] = {
                players: {},
                title: formatMarketName(marketType)
            };
        });
        
        // Process bookmakers data
        localPropsData.data.bookmakers.forEach(bookmaker => {
            if (!bookmaker.markets) return;
            
            bookmaker.markets.forEach(market => {
                if (!market.outcomes || !marketTypes.includes(market.key)) return;
                
                market.outcomes.forEach(outcome => {
                    const playerName = outcome.description;
                    const marketType = market.key;
                    const isOver = outcome.name.toLowerCase() === 'over';
                    
                    // Initialize player data if not exists
                    if (!propsByMarket[marketType].players[playerName]) {
                        propsByMarket[marketType].players[playerName] = {
                            name: playerName,
                            line: outcome.point,
                            pinnacle: { over: null, under: null },
                            fanduel: { over: null, under: null },
                            draftkings: { over: null, under: null },
                            movement: null,
                            best_over: null,
                            best_under: null
                        };
                    }
                    
                    // Add bookmaker data
                    const bookmakerKey = bookmaker.key.toLowerCase();
                    if (bookmakerKey === 'pinnacle' || bookmakerKey === 'fanduel' || bookmakerKey === 'draftkings') {
                        propsByMarket[marketType].players[playerName][bookmakerKey][isOver ? 'over' : 'under'] = {
                            price: outcome.price,
                            edge: outcome.edge_info?.consensus_edge || 0
                        };
                    }
                    
                    // Add movement data
                    if (outcome.movement) {
                        propsByMarket[marketType].players[playerName].movement = outcome.movement;
                    }
                });
            });
        });
        
        // Find best odds for each player
        Object.values(propsByMarket).forEach(market => {
            Object.values(market.players).forEach(player => {
                let bestOverOdds = -Infinity;
                let bestUnderOdds = -Infinity;
                let bestOverBookmaker = null;
                let bestUnderBookmaker = null;
                
                ['pinnacle', 'fanduel', 'draftkings'].forEach(bookmaker => {
                    if (player[bookmaker].over && player[bookmaker].over.price > bestOverOdds) {
                        bestOverOdds = player[bookmaker].over.price;
                        bestOverBookmaker = bookmaker;
                    }
                    
                    if (player[bookmaker].under && player[bookmaker].under.price > bestUnderOdds) {
                        bestUnderOdds = player[bookmaker].under.price;
                        bestUnderBookmaker = bookmaker;
                    }
                });
                
                player.best_over = bestOverBookmaker;
                player.best_under = bestUnderBookmaker;
            });
        });
        
        // Render tables for each market type
        return (
            <div className="props-tables-container">
                {marketTypes.map(marketType => {
                    const market = propsByMarket[marketType];
                    const players = Object.values(market.players);
                    
                    if (players.length === 0) return null;
                    
                    return (
                        <PropsTable 
                            key={marketType}
                            data={players}
                            marketTitle={market.title}
                            formatOdds={formatOdds}
                            formatBookmaker={formatBookmaker}
                        />
                    );
                })}
            </div>
        );
    };
    
    const formatMarketName = (marketKey) => {
        const marketNames = {
            'player_points': 'Points',
            'player_rebounds': 'Rebounds',
            'player_assists': 'Assists',
            'player_threes': 'Three Pointers'
        };
        return marketNames[marketKey] || marketKey;
    };
    
    const renderMarketRows = (marketData) => {
        // Group by line value
        const lineGroups = {};
        
        marketData.forEach(item => {
            if (!lineGroups[item.point]) {
                lineGroups[item.point] = [];
            }
            lineGroups[item.point].push(item);
        });
        
        return Object.keys(lineGroups).map(line => {
            const items = lineGroups[line];
            const overItems = items.filter(item => item.name.toLowerCase() === 'over');
            const underItems = items.filter(item => item.name.toLowerCase() === 'under');
            
            return items.map((item, index) => {
                // Only show bookmaker name on first row for this line
                const showBookmaker = index === 0;
                const overOdds = overItems.find(o => o.bookmaker === item.bookmaker)?.price;
                const underOdds = underItems.find(o => o.bookmaker === item.bookmaker)?.price;
                const edge = item.edge_info?.consensus_edge;
                
                return (
                    <tr key={`${item.bookmaker}-${line}-${item.name}`}>
                        {showBookmaker && <td rowSpan={items.length}>{item.bookmaker}</td>}
                        {showBookmaker && <td rowSpan={items.length}>{line}</td>}
                        <td className={overOdds > 0 ? 'positive-odds' : 'negative-odds'}>
                            {formatOdds(overOdds)}
                        </td>
                        <td className={underOdds > 0 ? 'positive-odds' : 'negative-odds'}>
                            {formatOdds(underOdds)}
                        </td>
                        <td>
                            {edge ? `${edge.toFixed(2)}%` : '-'}
                        </td>
                    </tr>
                );
            });
        }).flat();
    };
    
    const calculateValue = (prop) => {
        const currentLine = prop.odds?.fanduel?.over?.line;
        
        const historicalLine = prop.historical;
        
        console.log('Calculating value:', {
            player: prop.player,
            currentLine,
            historicalLine,
            odds: prop.odds
        });
        
        if (!currentLine || !historicalLine) {
            console.log('Missing line data:', {
                player: prop.player,
                hasCurrentLine: !!currentLine,
                hasHistoricalLine: !!historicalLine
            });
            return null;
        }

        const diff = currentLine - historicalLine;
        const percentChange = (diff / historicalLine) * 100;

        console.log('Value calculation result:', {
            player: prop.player,
            diff,
            percentChange,
            trend: diff > 0 ? 'up' : diff < 0 ? 'down' : 'neutral'
        });

        return {
            value: percentChange.toFixed(1),
            trend: diff > 0 ? 'up' : diff < 0 ? 'down' : 'neutral'
        };
    };

    const findHistoricalOutcome = (bookmakers, bookmakerKey, marketKey, player) => {
        for (const bookmaker of bookmakers) {
            if (bookmaker.key === bookmakerKey) {
                for (const market of bookmaker.markets) {
                    if (market.key === marketKey) {
                        return market.outcomes.find(o => 
                            o.description === player && o.name === 'Over'
                        );
                    }
                }
            }
        }
        return null;
    };

    const processedProps = processPropsData(localPropsData);

    const renderValueTrend = (valueData) => {
        if (!valueData) return 'N/A';
        
        return (
            <div className="value-indicator">
                <div className={`value-display value-${getTrendClass(valueData.value)}`}>
                    {valueData.value}%
                    <span className={`trend-${valueData.trend}`}>
                        {valueData.trend === 'up' ? '↑' : valueData.trend === 'down' ? '↓' : '→'}
                    </span>
                </div>
            </div>
        );
    };

    const getTrendClass = (value) => {
        const absValue = Math.abs(parseFloat(value));
        if (absValue >= 5) return 'high';
        if (absValue >= 2) return 'medium';
        if (absValue > 0) return 'low';
        return 'neutral';
    };

    const getLineMovementClass = (historical, current) => {
        if (!historical || !current) return '';
        const diff = current - historical;
        if (Math.abs(diff) >= 2) return 'movement-significant';
        if (Math.abs(diff) >= 1) return 'movement-moderate';
        if (Math.abs(diff) > 0) return 'movement-slight';
        return 'movement-none';
    };

    const formatBookmaker = (bookmaker) => {
        const bookStyles = {
            fanduel: { color: '#1493ff', text: 'FANDUEL' },
            draftkings: { color: '#3cca98', text: 'DRAFTKINGS' },
            pinnacle: { color: '#ffd700', text: 'PINNACLE' }
        };

        return (
            <span style={{ 
                color: bookStyles[bookmaker]?.color, 
                fontWeight: '600' 
            }}>
                {bookStyles[bookmaker]?.text}
            </span>
        );
    };

    const getLineMovementStyle = (historical, current) => {
        if (!historical || !current) return {};
        const diff = current - historical;
        const styles = {
            fontWeight: '600',
        };

        if (Math.abs(diff) >= 2) {
            styles.color = '#ff4d4d';
        } else if (Math.abs(diff) >= 1) {
            styles.color = '#ffd700';
        } else if (Math.abs(diff) > 0) {
            styles.color = '#00ff00';
        } else {
            styles.color = '#a0a0a0';
        }

        return styles;
    };

    const isOddsInTargetRange = (odds) => {
        return (odds >= -220 && odds <= 110);
    };

    const calculateMarketAverage = (odds) => {
        const probabilities = Object.entries(odds).map(([bookmaker, bookie]) => {
            if (bookie?.over?.odds) {
                return oddsToNoVigProb(bookie.over.odds);
            }
            return null;
        }).filter(prob => prob !== null);
        
        if (probabilities.length === 0) return null;
        
        const avgProb = probabilities.reduce((a, b) => a + b, 0) / probabilities.length;
        
        return avgProb;
    };

    const isOddsAboveMarket = (odds, marketAvg) => {
        const oddsProb = oddsToNoVigProb(odds);
        const pricingGap = ((1 / oddsProb) - (1 / marketAvg)) * 100;
        return pricingGap >= 5 && pricingGap <= 10;
    };

    const calculateEV = (pinnacleOdds, fanduelOdds, allBookieOdds) => {
        if (!pinnacleOdds || !fanduelOdds || !allBookieOdds) {
            return null;
        }
        
        const marketAvg = calculateMarketAverage(allBookieOdds);
        if (!marketAvg) {
            return null;
        }

        const pinnacleProb = oddsToNoVigProb(pinnacleOdds);
        const fanduelProb = oddsToNoVigProb(fanduelOdds);
        
        const trueProb = (pinnacleProb * 0.3) + (marketAvg * 0.7);
        
        const ev = ((1 / fanduelProb) * trueProb - 1) * 100;

        return {
            ev: ev.toFixed(1),
            marketAvgProb: (marketAvg * 100).toFixed(1),
            isSignificant: ev >= 2
        };
    };

    const oddsToNoVigProb = (odds) => {
        if (!odds) return null;
        if (odds > 0) {
            return 100 / (odds + 100);
        }
        return Math.abs(odds) / (Math.abs(odds) + 100);
    };

    const getEVClass = (ev) => {
        if (ev < 0) return 'negative';
        if (ev >= 5) return 'high';
        if (ev >= 3) return 'medium';
        return 'low';
    };

    // Convert renderMarketEfficiency to a proper React component
    const MarketEfficiencyDisplay = ({ efficiencyData }) => {
        console.log('Rendering market efficiency component with data:', efficiencyData);
        
        if (!efficiencyData || Object.keys(efficiencyData).length === 0) {
            return (
                <div className="market-efficiency-section mb-4">
                    <h5 className="section-header">Market Efficiency Analysis</h5>
                    <div className="alert alert-info">
                        Market efficiency data is not available for this game.
                    </div>
                </div>
            );
        }
        
        const getEfficiencyColor = (score) => {
            if (score >= 0.9) return '#28a745';
            if (score >= 0.7) return '#17a2b8';
            if (score >= 0.5) return '#ffc107';
            return '#dc3545';
        };
        
        const getEfficiencyLabel = (score) => {
            if (score >= 0.9) return 'Highly Efficient';
            if (score >= 0.7) return 'Moderately Efficient';
            if (score >= 0.5) return 'Some Inefficiency';
            return 'Inefficient';
        };

        // Updated function to create tooltip content as JSX
        const createEfficiencyTooltip = (market, data) => {
            const marketNames = {
                'player_points': 'Points',
                'player_rebounds': 'Rebounds',
                'player_assists': 'Assists',
                'player_threes': '3-Pointers',
                'player_pts_rebs_asts': 'PRA',
                'player_pts_rebs': 'Points + Rebounds',
                'player_pts_asts': 'Points + Assists',
                'player_rebs_asts': 'Rebounds + Assists'
            };
            
            const marketName = marketNames[market] || market.replace('player_', '').replace('_', ' ');
            const score = data.efficiency_score || 0;
            const sampleSize = data.sample_size || 0;
            const stdDev = data.std_dev || 0;
            
            // Simple explanation text based on score
            let explanation = "Very efficient market with minimal pricing discrepancies.";
            if (score < 0.9 && score >= 0.7) explanation = "Highly efficient market with minor pricing differences.";
            else if (score < 0.7 && score >= 0.5) explanation = "Moderately efficient market with noticeable differences.";
            else if (score < 0.5 && score >= 0.3) explanation = "Less efficient market with significant discrepancies.";
            else if (score < 0.3) explanation = "Inefficient market with major pricing differences.";
            
            // Betting advice based on efficiency score
            let advice = "Fewer value opportunities.";
            if (score < 0.9 && score >= 0.7) advice = "Value bets are harder to find.";
            else if (score < 0.7 && score >= 0.5) advice = "Some value opportunities exist.";
            else if (score < 0.5 && score >= 0.3) advice = "Good potential for value bets.";
            else if (score < 0.3) advice = "Many potential value opportunities.";
            
            return (
                <div style={{ maxWidth: '280px', textAlign: 'left' }}>
                    <div style={{ fontWeight: 'bold', borderBottom: '1px solid rgba(255,255,255,0.2)', paddingBottom: '4px', marginBottom: '6px' }}>
                        {marketName} Market Analysis
                    </div>
                    <div style={{ marginBottom: '8px' }}>
                        <div><strong>Efficiency:</strong> {(score * 100).toFixed(1)}%</div>
                        <div><strong>Sample:</strong> {sampleSize} lines</div>
                        <div><strong>Std Dev:</strong> {stdDev.toFixed(3)}</div>
                    </div>
                    <div style={{ borderTop: '1px solid rgba(255,255,255,0.1)', paddingTop: '6px', fontSize: '0.9em' }}>
                        {explanation}<br/>
                        <strong style={{ color: '#ffc107', display: 'block', marginTop: '4px' }}>{advice}</strong>
                    </div>
                </div>
            );
        };
        
        return (
            <div className="market-efficiency-section mb-4">
                <h5 className="section-header">Market Efficiency Analysis</h5>
                <div className="efficiency-description mb-2">
                    <small>Higher efficiency indicates tighter lines across bookmakers. Lower efficiency may present value opportunities.</small>
                </div>
                <div className="efficiency-metrics">
                    {Object.entries(efficiencyData).map(([market, data]) => {
                        const marketName = market.replace('player_', '').replace('_', ' ');
                        const score = data.efficiency_score || 0;
                        
                        return (
                            <OverlayTrigger
                                key={market}
                                placement="auto"
                                overlay={
                                    <Tooltip className="enhanced-tooltip">
                                        {createEfficiencyTooltip(market, data)}
                                    </Tooltip>
                                }
                            >
                                <div 
                                    className="efficiency-metric"
                                    id={`efficiency-metric-${market}`}
                                >
                                    <div className="metric-header">{marketName.toUpperCase()}</div>
                                    <div className="metric-value" style={{ color: getEfficiencyColor(score) }}>
                                        {(score * 100).toFixed(0)}%
                                    </div>
                                    <div className="metric-label">{getEfficiencyLabel(score)}</div>
                                    <div className="metric-details">
                                        <small>Sample: {data.sample_size || 0} lines</small>
                                        {data.std_dev !== undefined && (
                                            <small> | Std Dev: {data.std_dev.toFixed(2)}</small>
                                        )}
                                    </div>
                                </div>
                            </OverlayTrigger>
                        );
                    })}
                </div>
            </div>
        );
    };

    // Add a general tooltip initialization effect that runs whenever the modal is shown
    useEffect(() => {
        console.log("PlayerPropsModal show state changed:", show);
        if (show) {
            // Allow time for DOM rendering
            const initTimeout = setTimeout(() => {
                console.log("Initializing all tooltips in PlayerPropsModal");
                initializeAllTooltips();
            }, 300);
            
            return () => clearTimeout(initTimeout);
        }
    }, [show]);

    // Add a useEffect that reinitializes tooltips whenever propsData changes
    useEffect(() => {
        if (show && localPropsData) {
            console.log("Props data changed, reinitializing tooltips");
            const dataTimeout = setTimeout(() => {
                initializeAllTooltips();
            }, 300);
            
            return () => clearTimeout(dataTimeout);
        }
    }, [show, localPropsData]);

    // Function to initialize all tooltips
    const initializeAllTooltips = () => {
        console.log("🔍 TOOLTIP DEBUG: initializeAllTooltips called", new Date().toISOString());
        console.log("🔍 TOOLTIP DEBUG: jQuery available?", !!window.$ && !!window.$.fn && !!window.$.fn.tooltip);
        console.log("🔍 TOOLTIP DEBUG: tooltip elements count:", document.querySelectorAll('[data-toggle="tooltip"]').length);
        
        try {
            if (window.$ && window.$.fn && window.$.fn.tooltip) {
                // First dispose any existing tooltips
                $('[data-toggle="tooltip"]').tooltip('dispose');
                
                // Then initialize with proper settings
                $('[data-toggle="tooltip"]').tooltip({
                    html: true,
                    container: 'body',
                    boundary: 'window',
                    template: '<div class="tooltip enhanced-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
                });
                
                console.log('🔍 TOOLTIP DEBUG: All tooltips initialized successfully');
            } else {
                console.warn('jQuery not available for tooltip initialization');
            }
        } catch (error) {
            console.error('Error initializing tooltips:', error);
        }
    };

    // Handle refresh button click
    const handleRefresh = () => {
        if (gameData && !loading) {
            refreshProps(gameData);
        }
    };

    // Helper function to convert probability to American odds
    const probabilityToAmericanOdds = (probability) => {
        // Convert percentage to decimal
        const decimalProb = probability / 100;
        
        if (decimalProb > 0.5) {
            // Favorite: probability > 50%
            return Math.round(-100 * (decimalProb / (1 - decimalProb)));
        } else {
            // Underdog: probability <= 50%
            return Math.round(100 * ((1 - decimalProb) / decimalProb));
        }
    };

    // In your render method where you display the odds
    const renderPropRow = (prop, hitRate, probability) => {
        const odds = probabilityToAmericanOdds(probability);
        return (
            <tr key={prop}>
                <td>{prop}</td>
                <td>{hitRate}</td>
                <td className={probability >= 50 ? 'text-success' : 'text-danger'}>
                    {probability.toFixed(1)}%
                </td>
                <td className={odds > 0 ? 'text-success' : 'text-danger'}>
                    {odds > 0 ? `+${odds}` : odds}
                </td>
            </tr>
        );
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            className="props-modal dark-theme"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex justify-content-between align-items-center w-100">
                    <div>
                        Player Props: {gameData?.home_team} vs {gameData?.away_team}
                    </div>
                    <div className="d-flex align-items-center">
                        {loading && (
                            <Spinner 
                                animation="border" 
                                size="sm" 
                                className="me-2" 
                                role="status"
                            />
                        )}
                        <Button 
                            variant="outline-primary" 
                            size="sm" 
                            onClick={handleRefresh}
                            disabled={loading}
                            className="refresh-props-btn"
                        >
                            <BsArrowClockwise /> Refresh Props
                        </Button>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    localPropsData ? (
                        <>
                            {localPropsData?.data?.fanduel_recommendations && (
                                <FanDuelRecommendations recommendations={localPropsData.data.fanduel_recommendations} />
                            )}
                            
                            {localPropsData?.data?.market_efficiency && (
                                <MarketEfficiencyDisplay efficiencyData={localPropsData.data.market_efficiency} />
                            )}
                            
                            <div className="bookmaker-tabs">
                                {renderPropsTable()}
                            </div>
                        </>
                    ) : (
                        <div className="loading-container">
                            <p>No player props data available.</p>
                            <button 
                                className="btn btn-primary mt-3" 
                                onClick={fetchProps}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Loading...' : 'Fetch Props Data'}
                            </button>
                        </div>
                    )
                )}
            </Modal.Body>
        </Modal>
    );
};

export default PlayerPropsModal; 
import React, { useEffect, useState } from 'react';
import { Card, Button, Container, Row, Col, Badge, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import './PlansTab.css';

// Custom CSS for trial elements
const trialStyles = `
    .trial-badge {
        background-color: #00aaff;
        color: white;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        display: inline-block;
    }
    
    .trial-note {
        font-size: 14px;
        color: #aaaaaa;
        margin-top: 5px;
        font-style: italic;
    }
    
    .trial-notice {
        margin-top: 15px;
        padding: 10px;
        background-color: rgba(0, 170, 255, 0.1);
        border-radius: 5px;
        font-size: 14px;
        display: flex;
        align-items: flex-start;
    }
    
    .trial-notice i {
        color: #00aaff;
        margin-right: 8px;
        font-size: 16px;
        margin-top: 2px;
    }
    
    .trial-notice p {
        margin: 0;
        line-height: 1.4;
    }
    
    .current-plan-badge {
        background-color: #28a745;
        color: white;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        display: inline-block;
    }
    
    .trial-days-remaining {
        background-color: #ffc107;
        color: #212529;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        margin-left: 10px;
        display: inline-block;
    }
    
    .plan-card.current-plan {
        border: 2px solid #28a745;
        box-shadow: 0 0 15px rgba(40, 167, 69, 0.25);
    }
`;

// Add styles once at app startup instead of per component
if (!document.getElementById('plans-global-styles')) {
    const styleEl = document.createElement('style');
    styleEl.id = 'plans-global-styles';
    styleEl.innerHTML = trialStyles;
    document.head.appendChild(styleEl);
}

// Add inline CSS for new badge
const styles = {
    expiredTrialBadge: {
        backgroundColor: '#dc3545',
        color: 'white',
        padding: '3px 10px',
        borderRadius: '4px',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        margin: '5px auto 10px auto',
        display: 'inline-block'
    },
    expiredTrialNote: {
        color: '#dc3545',
        fontSize: '0.8rem',
        marginTop: '5px'
    }
};

const PlansTab = () => {
    const { 
        user, 
        setShowLoginModal, 
        loginWithGoogle, 
        isOnTrial, 
        hasPaidSubscription, 
        getTrialDaysRemaining,
        userSubscription,
        hasExpiredTrial
    } = useAuth();
    const [showWebLoginForm, setShowWebLoginForm] = useState(true);
    const navigate = useNavigate();
    
    // Get user's subscription status
    const isOnTrialPeriod = isOnTrial();
    const isPremiumSubscriber = hasPaidSubscription();
    const hasTrialExpired = hasExpiredTrial(); 
    const trialDaysRemaining = getTrialDaysRemaining();

    const handleSelectPlan = async (planId) => {
        console.log("handleSelectPlan called, user:", user ? user.uid : "not logged in");
        
        if (!user) {
            console.log("No user, showing login modal");
            setShowLoginModal(true);
        } else {
            console.log("User is logged in, navigating to checkout with plan info");
            // Navigate to checkout with plan details
            let planInfo;
            
            switch(planId) {
                case 'premium':
                    planInfo = { 
                        planId: 'premium-trial',
                        hasTrial: true,
                        trialDays: 3,
                        price: 39.99,
                        paypalPlanId: 'P-935348957S011462WM7FGR2I'
                    };
                    break;
                    
                // Add cases for future plan types here
                
                default:
                    planInfo = { 
                        planId: 'premium-trial',
                        hasTrial: true,
                        trialDays: 3,
                        price: 39.99,
                        paypalPlanId: 'P-935348957S011462WM7FGR2I'
                    };
            }
            
            console.log("Plan info:", planInfo);
            
            // Use navigate with the checkout path
            navigate('/checkout', { 
                state: planInfo
            });
            
            console.log("Navigation to checkout completed");
        }
    };

    // Available plans - structured as an array for future expansion
    const availablePlans = [
        {
            id: 'premium',
            name: 'Premium Plan',
            price: 39.99,
            period: 'month',
            hasTrial: true,
            trialDays: 3,
            features: [
                'Advanced Player Statistics',
                'Team Performance Analytics',
                'Head-to-Head Matchup Analysis',
                'Game Odds & Predictions',
                'Historical Data Access',
                'Live Game Insights',
                'Custom Research Tools'
            ]
        }
        // Add more plan types here in the future
    ];

    // Determine button text based on subscription status
    const getButtonText = (plan) => {
        if (!user) return 'Sign In to Subscribe';
        
        if (isPremiumSubscriber && plan.id === 'premium') {
            return 'Current Plan';
        }
        
        if (isOnTrialPeriod && plan.id === 'premium') {
            return 'Currently on Trial';
        }
        
        if (hasTrialExpired && plan.id === 'premium') {
            return 'Upgrade Now';
        }
        
        return 'Select Plan';
    };
    
    // Determine if button should be disabled
    const isButtonDisabled = (plan) => {
        // Only disable the premium button if user is currently on an active premium plan
        // Don't disable for expired trials - they should be able to upgrade
        return (isPremiumSubscriber && plan.id === 'premium') || 
               (isOnTrialPeriod && !hasTrialExpired && plan.id === 'premium');
    };

    return (
        <Container className="plans-container">
            <h2 className="text-center mb-4">NBA Stats Premium Access</h2>
            <p className="text-center mb-5">
                Get unlimited access to all our premium features and take your NBA analysis to the next level
            </p>
            
            {isOnTrialPeriod && trialDaysRemaining > 0 && (
                <div className="trial-notice text-center mb-4">
                    <p>
                        <i className="bi bi-info-circle-fill me-2"></i>
                        You are currently on a trial plan. You have <strong>{trialDaysRemaining} days</strong> remaining in your trial period.
                    </p>
                </div>
            )}
            
            <Row className="justify-content-center">
                {availablePlans.map((plan) => (
                    <Col key={plan.id} md={6} lg={4}>
                        <Card className={`plan-card ${(isPremiumSubscriber || isOnTrialPeriod) && plan.id === 'premium' ? 'current-plan' : ''}`}>
                            <Card.Header className="text-center">
                                <h3>{plan.name}</h3>
                                {plan.hasTrial && !isPremiumSubscriber && !isOnTrialPeriod && !hasTrialExpired && (
                                    <div className="trial-badge">{plan.trialDays}-Day Free Trial</div>
                                )}
                                {isPremiumSubscriber && plan.id === 'premium' && (
                                    <div className="current-plan-badge">Current Plan</div>
                                )}
                                {isOnTrialPeriod && plan.id === 'premium' && (
                                    <>
                                        <div className="current-plan-badge">Trial Active</div>
                                        <div className="trial-days-remaining">{trialDaysRemaining} days left</div>
                                    </>
                                )}
                                {hasTrialExpired && plan.id === 'premium' && (
                                    <div style={styles.expiredTrialBadge}>Trial Expired</div>
                                )}
                            </Card.Header>
                            <Card.Body>
                                <div className="price-section">
                                    <span className="price">${plan.price}</span>
                                    <span className="period">/{plan.period}</span>
                                    {plan.hasTrial && !isPremiumSubscriber && !isOnTrialPeriod && !hasTrialExpired && (
                                        <div className="trial-note">after {plan.trialDays}-day free trial</div>
                                    )}
                                    {hasTrialExpired && plan.id === 'premium' && (
                                        <div style={styles.expiredTrialNote}>Your trial has expired</div>
                                    )}
                                </div>
                                <ul className="features-list">
                                    {plan.features.map((feature, index) => (
                                        <li key={index}>
                                            <i className="bi bi-check"></i>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                <Button 
                                    variant={isButtonDisabled(plan) ? "secondary" : "primary"}
                                    className="select-plan-btn"
                                    onClick={() => handleSelectPlan(plan.id)}
                                    disabled={isButtonDisabled(plan)}
                                >
                                    {getButtonText(plan)}
                                </Button>
                                {!user && (
                                    <div className="login-options mt-3">
                                        <span className="or-continue" style={{ color: '#ffffff' }}>Or continue with</span>
                                        <Button 
                                            variant="outline-secondary" 
                                            className="google-login-btn"
                                            onClick={async () => {
                                                try {
                                                    await loginWithGoogle();
                                                    navigate('/checkout', { 
                                                        state: { 
                                                            planId: 'premium-trial',
                                                            hasTrial: true,
                                                            trialDays: 3,
                                                            price: 39.99,
                                                            paypalPlanId: 'P-935348957S011462WM7FGR2I'
                                                        } 
                                                    });
                                                } catch (error) {
                                                    console.error('Google login error:', error);
                                                }
                                            }}
                                        >
                                            <i className="bi bi-google me-2"></i>
                                            Google
                                        </Button>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default PlansTab; 
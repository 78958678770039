import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { Container, Spinner, Alert, Button, Card, Row, Col, Badge, Table, Form, Nav, Tab } from 'react-bootstrap';
import { BsArrowClockwise, BsInfoCircle, BsTrophy } from 'react-icons/bs';
import { API_BASE_URL, KNOWN_API_ENDPOINTS } from '../config/constants';
import { LayOddsContext } from '../App';
import './LayOddsTab.css';
import { useAuth } from '../contexts/AuthContext'; // Add this import
import LayBuilder from './LayBuilder';

const LayOddsTab = () => {
    // Add auth context
    const { user, isAdmin } = useAuth();
    
    // Add state for active subtab
    const [activeSubtab, setActiveSubtab] = useState('main');
    
    // Add state for player props filters
    const [propFilters, setPropFilters] = useState({
        propType: 'all',
        minEdge: 0,
        minRating: 0,
        minImpliedProbability: 0,
        gameStatus: 'all', // 'all', 'live', 'scheduled'
        multiBookOnly: false // Add this new filter for multi-bookmaker props
    });
    
    // Use state from context instead of local state
    const {
        games, setGames,
        loading, setLoading,
        error, setError,
        suggestedParlays, setSuggestedParlays,
        parlayStats, setParlayStats,
        date, setDate,
        displayDate, setDisplayDate,
        hasLoaded, setHasLoaded,
        fetchOdds: contextFetchOdds
    } = useContext(LayOddsContext);
    
    // Format date for display
    const formatDate = (dateStr) => {
        // Create date with explicit timezone to avoid offset issues
        const date = new Date(dateStr + 'T00:00:00-05:00'); // Using EST timezone
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/New_York' };
        return date.toLocaleDateString('en-US', options);
    };
    
    // Format time in EST
    const formatGameTime = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/New_York'
        });
    };
    
    // Filter games by date
    const filterGamesByDate = useCallback((games, selectedDate) => {
        if (!games || !Array.isArray(games)) {
            return [];
        }
        
        // Create date range in EST
        const startDate = new Date(`${selectedDate}T00:00:00-05:00`);
        const endDate = new Date(`${selectedDate}T23:59:59-05:00`);
        
        return games.filter(game => {
            const gameDate = new Date(game.commence_time);
            return gameDate >= startDate && gameDate <= endDate;
        });
    }, []);

    // Process odds to extract useful data
    const processGameOdds = (game) => {
        if (!game || !game.bookmakers || !Array.isArray(game.bookmakers)) {
            return {
                moneyline: { home: null, away: null },
                spread: { home: { point: null, price: null }, away: { point: null, price: null } },
                total: { over: { point: null, price: null }, under: { point: null, price: null } },
                playerProps: []
            };
        }

        // Find FanDuel bookmaker first, fallback to other bookmakers for player props
        const fanduelBk = game.bookmakers.find(bk => bk.key === 'fanduel');
        const backupBk = game.bookmakers[0]; // Use first bookmaker as backup
        
        // Default structure if no bookmakers are found
        const defaultOdds = {
            moneyline: { home: null, away: null },
            spread: { home: { point: null, price: null }, away: { point: null, price: null } },
            total: { over: { point: null, price: null }, under: { point: null, price: null } },
            playerProps: []
        };
        
        if (!fanduelBk && !backupBk) return defaultOdds;
        
        // Use FanDuel for main markets if available, otherwise use backup
        const mainBk = fanduelBk || backupBk;
        const markets = mainBk.markets || [];
        
        // Extract standard markets
        const moneylineMarket = markets.find(m => m.key === 'h2h') || {};
        const spreadMarket = markets.find(m => m.key === 'spreads') || {};
        const totalMarket = markets.find(m => m.key === 'totals') || {};
        
        // Enhanced player prop extraction - search all bookmakers
        let playerPropMarkets = [];
        
        // First check FanDuel
        if (fanduelBk && fanduelBk.markets) {
            playerPropMarkets = fanduelBk.markets.filter(m => 
            m.key.includes('player') || 
            m.key.includes('points') || 
            m.key.includes('rebounds') || 
            m.key.includes('assists') || 
            m.key.includes('threes') ||
            m.key.includes('3pt') ||
            m.key.includes('blocks') ||
            m.key.includes('steals')
            ).map(m => ({...m, bookmaker: 'fanduel'}));
        }
        
        // If not many props from FanDuel, check other bookmakers
        if (playerPropMarkets.length < 5) {
            game.bookmakers.forEach(bookmaker => {
                if (bookmaker.key !== 'fanduel' && bookmaker.markets) {
                    const propMarkets = bookmaker.markets.filter(m => 
                        m.key.includes('player') || 
                        m.key.includes('points') || 
                        m.key.includes('rebounds') || 
                        m.key.includes('assists') || 
                        m.key.includes('threes') ||
                        m.key.includes('3pt') ||
                        m.key.includes('blocks') ||
                        m.key.includes('steals')
                    ).map(m => ({...m, bookmaker: bookmaker.key}));
                    
                    if (propMarkets.length > 0) {
                        playerPropMarkets = [...playerPropMarkets, ...propMarkets];
                    }
                }
            });
        }

        // Log available prop market types
        try {
            const propTypes = new Set();
            playerPropMarkets.forEach(m => propTypes.add(m.key));
            if (Math.random() < 0.3) { // Only log for ~30% of games to avoid console spam
                console.log(`Available prop markets for ${game.home_team} vs ${game.away_team}:`, {
                    marketTypes: Array.from(propTypes),
                    hasBlocksMarket: propTypes.has('player_blocks'),
                    hasStealMarket: propTypes.has('player_steals'),
                    hasThreesMarket: propTypes.has('player_threes'),
                    totalPropMarkets: playerPropMarkets.length
                });
            }
        } catch (err) {
            console.error("Error logging prop market types:", err);
        }

        // Process player props
        let playerProps = [];
        
        // Set to track processed props and avoid duplicates
        const processedPropKeys = new Set();
        
        // Process standard player props from markets with enhanced logging
        console.log(`Processing ${playerPropMarkets.length} player prop markets for ${game.home_team} vs ${game.away_team}`);
        
        playerPropMarkets.forEach(market => {
            const propType = market.key;
            const outcomes = market.outcomes || [];
            const bookmaker = market.bookmaker || 'unknown';
            
            outcomes.forEach(outcome => {
                if (outcome.name && (outcome.point !== undefined || propType.includes('player_match')) && outcome.price) {
                    // Different formats based on API response
                    let playerName, selection;
                    
                    // Fix player name parsing
                    if (outcome.name.includes('Over ') || outcome.name.includes('Under ')) {
                        // Format: "Player Name Over 5.5" or "Player Name Under 5.5"
                        const parts = outcome.name.split(' ');
                        const selectionIndex = parts.findIndex(p => p === 'Over' || p === 'Under');
                        
                        if (selectionIndex > 0) {
                            playerName = parts.slice(0, selectionIndex).join(' ');
                            selection = parts[selectionIndex];
                        } else {
                            playerName = outcome.name;
                            selection = outcome.description || '';
                        }
                    } else if (outcome.description && (outcome.description.includes('Over') || outcome.description.includes('Under'))) {
                        // Format from description: "Over 5.5" or "Under 5.5"
                        playerName = outcome.name;
                        selection = outcome.description.includes('Over') ? 'Over' : 'Under';
                    } else {
                        // Other formats - use name as playerName and description for selection
                        playerName = outcome.name;
                        selection = outcome.description || '';
                    }
                    
                    // Enhanced log for troubleshooting
                    if (Math.random() < 0.1) { // Log 10% of props to avoid spam
                        console.log(`Found prop: ${playerName} ${selection} ${outcome.point} (${propType}) from ${bookmaker}`);
                    }
                    
                    // Generate a unique key to avoid duplicate props
                    const propKey = `${playerName}-${propType}-${outcome.point || 0}-${selection}`;
                    
                        if (!processedPropKeys.has(propKey)) {
                            processedPropKeys.add(propKey);
                    
                        // Enhanced prop object with more data
                    playerProps.push({
                        playerName,
                        propType,
                            selection,
                        point: outcome.point,
                        price: outcome.price,
                            stat_type: propType.replace('player_', '').replace('_', ' '),
                            team: outcome.team,
                            bookmaker: bookmaker, // Include bookmaker info
                            // Calculate market efficiency based on prop type
                            marketEfficiency: propType.includes('points') ? 1.1 : 
                                             propType.includes('rebounds') ? 1.3 : 
                                             propType.includes('assists') ? 1.3 : 
                                             propType.includes('threes') ? 1.35 :
                                             propType.includes('blocks') ? 1.4 :
                                             propType.includes('steals') ? 1.4 : 1.2,
                        });
                    }
                }
            });
        });
        
        // If game has direct playerProps from separate API call
        if (game.playerProps && Array.isArray(game.playerProps) && game.playerProps.length > 0) {
            game.playerProps.forEach(prop => {
                if (prop.player_name && prop.line && prop.odds) {
                    playerProps.push({
                        playerName: prop.player_name,
                        propType: prop.prop_type || 'player_points', 
                        selection: prop.selection || 'Over',
                        point: parseFloat(prop.line),
                        price: parseInt(prop.odds),
                        team: prop.team || 'Unknown'
                    });
                }
            });
        }

        // Process the odds
        return {
            moneyline: {
                home: moneylineMarket.outcomes?.find(o => o.name === game.home_team)?.price,
                away: moneylineMarket.outcomes?.find(o => o.name === game.away_team)?.price
            },
            spread: {
                home: {
                    point: spreadMarket.outcomes?.find(o => o.name === game.home_team)?.point,
                    price: spreadMarket.outcomes?.find(o => o.name === game.home_team)?.price
                },
                away: {
                    point: spreadMarket.outcomes?.find(o => o.name === game.away_team)?.point,
                    price: spreadMarket.outcomes?.find(o => o.name === game.away_team)?.price
                }
            },
            total: {
                over: {
                    point: totalMarket.outcomes?.find(o => o.name === 'Over')?.point,
                    price: totalMarket.outcomes?.find(o => o.name === 'Over')?.price
                },
                under: {
                    point: totalMarket.outcomes?.find(o => o.name === 'Under')?.point,
                    price: totalMarket.outcomes?.find(o => o.name === 'Under')?.price
                }
            },
            playerProps
        };
    };

    // Add this helper function to check for duplicate legs
    const isDuplicateBet = (bet1, bet2) => {
        // For player props, check player name, prop type, and selection
        if (bet1.betType === 'player_prop' && bet2.betType === 'player_prop') {
            return (
                bet1.playerName === bet2.playerName &&
                bet1.propType === bet2.propType &&
                bet1.selection === bet2.selection &&
                bet1.point === bet2.point
            );
        }
        
        // For other bet types, check team, bet type, and selection
        return (
            bet1.betType === bet2.betType &&
            bet1.team === bet2.team &&
            bet1.selection === bet2.selection
        );
    };

    // Add a function to deduplicate the all bets list
    const deduplicateBets = (bets) => {
        const uniqueBets = [];
        const seen = new Set();
        
        bets.forEach(bet => {
            // Create a unique key for the bet
            let key;
            if (bet.betType === 'player_prop') {
                key = `${bet.betType}_${bet.playerName}_${bet.propType}_${bet.selection}_${bet.point}`;
            } else {
                key = `${bet.betType}_${bet.team}_${bet.selection}`;
            }
            
            // Only add if not seen before
            if (!seen.has(key)) {
                seen.add(key);
                uniqueBets.push(bet);
            }
        });
        
        return uniqueBets;
    };

    // Find the best value bets across all games
    const generateSuggestedParlays = (games) => {
        // Local versions of helper functions to avoid reference errors
        function localCountPropLegs(legs) {
            return legs.filter(leg => leg.betType === 'player_prop').length;
        }
        
        function localCountStandardLegs(legs) {
            return legs.filter(leg => leg.betType !== 'player_prop').length;
        }
        
        function localCalculateAverageEV(legs) {
            // Calculate individual EVs for each leg
            const evValues = legs.map(leg => 
                parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0, leg.isAvoidRange))
            );
            
            // Calculate the average EV
            const avgEV = evValues.reduce((sum, ev) => sum + ev, 0) / evValues.length;
            
            // Return the average EV formatted to 1 decimal place
            return avgEV.toFixed(1);
        }
        
        function localCalculateCorrelationStrength(legs) {
            const correlatedLegs = legs.filter(leg => 
                legs.some(otherLeg => 
                    leg !== otherLeg && 
                    leg.gameId === otherLeg.gameId &&
                    ((leg.betType === 'moneyline' && otherLeg.betType === 'player_prop') ||
                     (leg.betType === 'player_prop' && otherLeg.betType === 'moneyline'))
                )
            );
            return correlatedLegs.length >= 2 ? 'Strong' : 'Moderate';
        }
        
        function hasAnyCorrelation(legs) {
            // Check if any two legs are from the same game
            for (let i = 0; i < legs.length; i++) {
                for (let j = i + 1; j < legs.length; j++) {
                    if (legs[i].gameId === legs[j].gameId) {
                        return true;
                    }
                }
            }
            return false;
        }
        
        function allowSameGameBets(bet1, bet2) {
            // Allow moneyline + player prop combinations
            return (bet1.betType === 'moneyline' && bet2.betType === 'player_prop') ||
                   (bet1.betType === 'player_prop' && bet2.betType === 'moneyline');
        }
        
        if (!games || games.length === 0) return [];

        // Set a maximum number of games to process based on the number of games
        // This prevents exponential computation with large game counts
        const MAX_GAMES_TO_PROCESS = games.length > 8 ? 6 : (games.length > 6 ? 8 : games.length);
        
        console.log(`Processing ${games.length} total games. Limiting parlay generation to ${MAX_GAMES_TO_PROCESS} games for performance.`);
        
        // If there are many games, select the most promising ones
        let gamesToProcess = games;
        if (games.length > MAX_GAMES_TO_PROCESS) {
            // Sort games by start time (prioritize earlier games)
            const sortedGames = [...games].sort((a, b) => {
                return new Date(a.commence_time) - new Date(b.commence_time);
            });
            
            // Take a subset of games to prevent browser freezing with large slates
            gamesToProcess = sortedGames.slice(0, MAX_GAMES_TO_PROCESS);
            console.log(`Reduced games for parlay processing from ${games.length} to ${MAX_GAMES_TO_PROCESS}`);
        }

        // Enhanced player props debugging
        let totalPropsFound = 0;
        gamesToProcess.forEach(game => {
            // Extract all bookmakers that might have player props
            const bookmakers = game.bookmakers || [];
            const playerPropMarkets = [];
            
            // Search through all bookmakers for player prop markets
            bookmakers.forEach(bookmaker => {
                const markets = bookmaker.markets || [];
                const propMarkets = markets.filter(m => 
                    m.key.includes('player') || 
                    m.key.includes('points') || 
                    m.key.includes('rebounds') || 
                    m.key.includes('assists') || 
                    m.key.includes('threes') ||
                    m.key.includes('blocks') ||
                    m.key.includes('steals')
                );
                
                if (propMarkets.length > 0) {
                    playerPropMarkets.push(...propMarkets.map(m => ({
                        ...m,
                        bookmaker: bookmaker.key
                    })));
                }
            });
            
            // Log the total count of prop markets found
            const playerPropsCount = playerPropMarkets.length;
            totalPropsFound += playerPropsCount;
            console.log(`Game ${game.home_team} vs ${game.away_team} has ${playerPropsCount} player prop markets`);
        });
        console.log(`Found a total of ${totalPropsFound} player prop markets across all games`);

        // Process each game to find best value bets
        const bestBets = gamesToProcess.map(game => {
            const processedOdds = processGameOdds(game);
            const valueOptions = [];

            // Add moneyline options if available
            if (processedOdds.moneyline.home) {
                // Calculate hold percentage for moneyline bets
                const holdPercentage = calculateHoldPercentage(
                    processedOdds.moneyline.home, 
                    processedOdds.moneyline.away
                );
                
                // Check if moneyline odds are within target ranges
                const homeOdds = processedOdds.moneyline.home;
                const isWithinTargetRange = (
                    (homeOdds >= -200 && homeOdds <= 100) || 
                    (homeOdds >= 150 && homeOdds <= 250)
                );
                const isAvoidRange = homeOdds <= -333 || homeOdds > 300;
                
                // Apply odds range factor - boost for target range, penalize more heavily for avoid range
                const oddsRangeFactor = isWithinTargetRange ? 1.2 : (isAvoidRange ? 0.4 : 1.0);
                
                valueOptions.push({
                    gameId: game.id,
                    homeTeam: game.home_team,
                    awayTeam: game.away_team,
                    gameTime: game.commence_time,
                    betType: 'moneyline',
                    selection: 'home',
                    team: game.home_team,
                    odds: homeOdds,
                    value: calculateBetValue(homeOdds) * (holdPercentage < 0.05 ? 1.1 : 1) * oddsRangeFactor,
                    holdPercentage,
                    isWithinTargetRange,
                    isAvoidRange,
                    bookmaker: 'FanDuel'
                });
            }

            if (processedOdds.moneyline.away) {
                // Calculate hold percentage for moneyline bets
                const holdPercentage = calculateHoldPercentage(
                    processedOdds.moneyline.home, 
                    processedOdds.moneyline.away
                );
                
                // Check if moneyline odds are within target ranges
                const awayOdds = processedOdds.moneyline.away;
                const isWithinTargetRange = (
                    (awayOdds >= -200 && awayOdds <= 100) || 
                    (awayOdds >= 150 && awayOdds <= 250)
                );
                const isAvoidRange = awayOdds <= -333 || awayOdds > 300;
                
                // Apply odds range factor - boost for target range, penalize more heavily for avoid range
                const oddsRangeFactor = isWithinTargetRange ? 1.2 : (isAvoidRange ? 0.4 : 1.0);
                
                valueOptions.push({
                    gameId: game.id,
                    homeTeam: game.home_team,
                    awayTeam: game.away_team,
                    gameTime: game.commence_time,
                    betType: 'moneyline',
                    selection: 'away',
                    team: game.away_team,
                    odds: awayOdds,
                    value: calculateBetValue(awayOdds) * (holdPercentage < 0.05 ? 1.1 : 1) * oddsRangeFactor,
                    holdPercentage,
                    isWithinTargetRange,
                    isAvoidRange,
                    bookmaker: 'FanDuel'
                });
            }

            // Add spread options if available
            if (processedOdds.spread.home.point && processedOdds.spread.home.price) {
                valueOptions.push({
                    gameId: game.id,
                    homeTeam: game.home_team,
                    awayTeam: game.away_team,
                    gameTime: game.commence_time,
                    betType: 'spread',
                    selection: 'home',
                    team: game.home_team,
                    point: processedOdds.spread.home.point,
                    odds: processedOdds.spread.home.price,
                    value: calculateBetValue(processedOdds.spread.home.price),
                    bookmaker: 'FanDuel'
                });
            }

            if (processedOdds.spread.away.point && processedOdds.spread.away.price) {
                valueOptions.push({
                    gameId: game.id,
                    homeTeam: game.home_team,
                    awayTeam: game.away_team,
                    gameTime: game.commence_time,
                    betType: 'spread',
                    selection: 'away',
                    team: game.away_team,
                    point: processedOdds.spread.away.point,
                    odds: processedOdds.spread.away.price,
                    value: calculateBetValue(processedOdds.spread.away.price),
                    bookmaker: 'FanDuel'
                });
            }

            // Add total options if available
            if (processedOdds.total.over.point && processedOdds.total.over.price) {
                valueOptions.push({
                    gameId: game.id,
                    homeTeam: game.home_team,
                    awayTeam: game.away_team,
                    gameTime: game.commence_time,
                    betType: 'total',
                    selection: 'over',
                    point: processedOdds.total.over.point,
                    odds: processedOdds.total.over.price,
                    value: calculateBetValue(processedOdds.total.over.price),
                    bookmaker: 'FanDuel'
                });
            }

            if (processedOdds.total.under.point && processedOdds.total.under.price) {
                valueOptions.push({
                    gameId: game.id,
                    homeTeam: game.home_team,
                    awayTeam: game.away_team,
                    gameTime: game.commence_time,
                    betType: 'total',
                    selection: 'under',
                    point: processedOdds.total.under.point,
                    odds: processedOdds.total.under.price,
                    value: calculateBetValue(processedOdds.total.under.price),
                    bookmaker: 'FanDuel'
                });
            }

            // Add player prop options if available - apply market efficiency factors
            if (processedOdds.playerProps && processedOdds.playerProps.length > 0) {
                processedOdds.playerProps.forEach(prop => {
                    // Apply market efficiency factor if available, or use default boost
                    const efficiencyFactor = prop.marketEfficiency || 1.5;
                    const propBaseValue = calculateBetValue(prop.price) * (efficiencyFactor * 0.9);
                    
                    valueOptions.push({
                        gameId: game.id,
                        homeTeam: game.home_team,
                        awayTeam: game.away_team,
                        gameTime: game.commence_time,
                        betType: 'player_prop',
                        propType: prop.propType || prop.prop_type,
                        statType: prop.stat_type || propTypeDisplay(prop.propType || prop.prop_type),
                        playerName: prop.playerName || prop.player_name,
                        selection: prop.selection,
                        point: prop.point || prop.line,
                        odds: prop.price || prop.odds,
                        team: prop.team,
                        value: propBaseValue,
                        bookmaker: prop.bookmaker || 'FanDuel',
                        marketEfficiency: efficiencyFactor
                    });
                });
            }

            // Find the best value bet from all options for this game
            const bestBet = valueOptions.sort((a, b) => b.value - a.value)[0];
            console.log(`Best bet for ${game.home_team} vs ${game.away_team}:`, bestBet);
            return bestBet;
        }).filter(bet => bet); // Remove any undefined values

        // Get additional player prop bets for diversity - increase from top 3 to top 5
        const playerPropBets = gamesToProcess.flatMap(game => {
            const processedOdds = processGameOdds(game);
            let propOptions = [];
            
            if (processedOdds.playerProps && processedOdds.playerProps.length > 0) {
                console.log(`Found ${processedOdds.playerProps.length} player props for ${game.home_team} vs ${game.away_team}`);
                
                // Group props by type for better diversity
                const pointsProps = [];
                const reboundsProps = [];
                const assistsProps = [];
                const threesProps = [];
                const blocksProps = [];
                const stealsProps = [];
                const otherProps = [];
                
                // Enhanced prop processing with better logging
                processedOdds.playerProps.forEach(prop => {
                    // Skip props without required data
                    if (!prop.playerName || !prop.selection || prop.point === undefined || prop.price === undefined) {
                        return;
                    }
                    
                    const propType = prop.propType || prop.prop_type || '';
                    
                    // Use existing market efficiency or calculate based on prop type
                    let efficiencyFactor = prop.marketEfficiency || 1.0;
                    
                    // Create the prop object with complete data
                    const propObj = {
                            gameId: game.id,
                            homeTeam: game.home_team,
                            awayTeam: game.away_team,
                            gameTime: game.commence_time,
                            betType: 'player_prop',
                        propType: propType,
                        statType: prop.stat_type || propTypeDisplay(propType),
                        playerName: prop.playerName,
                            selection: prop.selection,
                        point: prop.point,
                        odds: prop.price,
                        team: prop.team || (Math.random() > 0.5 ? game.home_team : game.away_team), // Fallback team assignment
                        value: calculateBetValue(prop.price) * efficiencyFactor,
                            bookmaker: prop.bookmaker || 'FanDuel',
                            marketEfficiency: efficiencyFactor
                        };
                    
                    // Log a sample of props to verify processing
                    if (Math.random() < 0.05) {
                        console.log(`Processed prop bet: ${propObj.playerName} ${propObj.selection} ${propObj.point} ${propObj.propType} (${propObj.odds})`);
                    }
                    
                    // Add to prop options
                    propOptions.push(propObj);
                });
            }
            
            // Sort props by value in descending order
            propOptions.sort((a, b) => b.value - a.value);
            
            // Take top 10 props for each game to ensure diversity across games
            return propOptions.slice(0, 10);
        });

        console.log(`Total player prop bets found: ${playerPropBets.length}`);

        // Combine best game bets with top player props
        let allBets = [...bestBets];
        
        // Ensure player props are included if any were found
        if (playerPropBets.length > 0) {
            allBets = [...allBets, ...playerPropBets];
            console.log(`Added ${playerPropBets.length} player props to bet options`);
        }
        
        // Deduplicate bets before filtering and sorting
        allBets = deduplicateBets(allBets);
        console.log(`Total unique bets after deduplication: ${allBets.length} (from ${bestBets.length + playerPropBets.length} original bets)`);
        
        // Filter all bets by hold percentage first
        allBets = filterBetsByHold(allBets);
        
        // Sort all bets by value to ensure we're getting the best options
        allBets = allBets.sort((a, b) => b.value - a.value);
        
        // Log prop bet percentage for verification
        const propCount = allBets.filter(bet => bet.betType === 'player_prop').length;
        const propPercentage = allBets.length > 0 ? Math.round((propCount / allBets.length) * 100) : 0;
        console.log(`Player props make up ${propPercentage}% of total bets (${propCount}/${allBets.length})`);
        
        // Log the top bets for verification
        console.log(`Top bet options:`, allBets.slice(0, 3));
        
        const parlays = [];

        // Only generate 2-3 leg parlays to keep hold manageable
        // 2-leg parlays
        if (allBets.length >= 2) {
            for (let i = 0; i < allBets.length - 1; i++) {
                for (let j = i + 1; j < allBets.length; j++) {
                    // Check for duplicates
                    if (isDuplicateBet(allBets[i], allBets[j])) {
                        continue;
                    }
                    
                    // Only skip if both bets are non-props from the same game
                    // Allow moneyline + player prop from same game for correlated bets
                    const sameGame = allBets[i].gameId === allBets[j].gameId;
                    const allowCorrelation = 
                        sameGame && 
                        ((allBets[i].betType === 'moneyline' && allBets[j].betType === 'player_prop') ||
                         (allBets[i].betType === 'player_prop' && allBets[j].betType === 'moneyline'));
                    
                    // If same game and not a valid correlation, skip
                    if (sameGame && !allowCorrelation &&
                        (allBets[i].betType !== 'player_prop' || allBets[j].betType !== 'player_prop')) {
                        continue;
                    }
                    
                    // For moneyline + player prop from same game, verify team alignment for correlation
                    if (allowCorrelation) {
                        const moneylineBet = allBets[i].betType === 'moneyline' ? allBets[i] : allBets[j];
                        const propBet = allBets[i].betType === 'player_prop' ? allBets[i] : allBets[j];
                        
                        // Only allow if the player's team matches the moneyline team
                        if (propBet.team !== moneylineBet.team) {
                        continue;
                        }
                    }
                    
                    const legs = [allBets[i], allBets[j]];
                    const combinedHold = calculateCombinedHold(legs);
                    
                    // Skip if combined hold is too high - more lenient limit
                    if (combinedHold > 0.08) { // Increased from 0.07 to 0.08 (8% maximum combined hold)
                        continue;
                    }
                    
                    // Check if all legs have positive EV
                    const allPositiveEV = legs.every(leg => 
                        parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0, leg.isAvoidRange)) > 0
                    );
                    
                    // Calculate base confidence
                    let confidence = calculateParlayConfidence(legs);
                    
                    // Apply correlation boost if applicable
                    if (allowCorrelation) {
                        confidence *= 1.15; // 15% boost for correlated legs
                    }
                    
                    const parlay = {
                        id: `parlay-${i}-${j}`,
                        name: `2-Team Lay`,
                        legs,
                        totalOdds: calculateParlayOdds(legs),
                        confidence: confidence,
                        combinedHold,
                        allPositiveEV,
                        isCorrelated: allowCorrelation
                    };
                    parlays.push(parlay);
                }
            }
        }

        // 3-leg parlays
        if (allBets.length >= 3) {
            for (let i = 0; i < allBets.length - 2; i++) {
                for (let j = i + 1; j < allBets.length - 1; j++) {
                    // Check for duplicates
                    if (isDuplicateBet(allBets[i], allBets[j])) {
                        continue;
                    }
                    
                    // Allow moneyline + player prop from same game
                    const sameGameIJ = allBets[i].gameId === allBets[j].gameId;
                    const allowCorrelationIJ = 
                        sameGameIJ && 
                        ((allBets[i].betType === 'moneyline' && allBets[j].betType === 'player_prop') ||
                         (allBets[i].betType === 'player_prop' && allBets[j].betType === 'moneyline'));
                    
                    // Skip invalid combinations
                    if (sameGameIJ && !allowCorrelationIJ &&
                        (allBets[i].betType !== 'player_prop' || allBets[j].betType !== 'player_prop')) {
                        continue;
                    }
                    
                    // Verify team alignment for correlated bets
                    if (allowCorrelationIJ) {
                        const moneylineBet = allBets[i].betType === 'moneyline' ? allBets[i] : allBets[j];
                        const propBet = allBets[i].betType === 'player_prop' ? allBets[i] : allBets[j];
                        
                        if (propBet.team !== moneylineBet.team) {
                            continue;
                        }
                    }
                    
                    for (let k = j + 1; k < allBets.length; k++) {
                        // Check for duplicates
                        if (isDuplicateBet(allBets[i], allBets[k]) || isDuplicateBet(allBets[j], allBets[k])) {
                            continue;
                        }
                        
                        // Check for correlations with k
                        const sameGameIK = allBets[i].gameId === allBets[k].gameId;
                        const sameGameJK = allBets[j].gameId === allBets[k].gameId;
                        
                        const allowCorrelationIK = 
                            sameGameIK && 
                            ((allBets[i].betType === 'moneyline' && allBets[k].betType === 'player_prop') ||
                             (allBets[i].betType === 'player_prop' && allBets[k].betType === 'moneyline'));
                             
                        const allowCorrelationJK = 
                            sameGameJK && 
                            ((allBets[j].betType === 'moneyline' && allBets[k].betType === 'player_prop') ||
                             (allBets[j].betType === 'player_prop' && allBets[k].betType === 'moneyline'));
                            
                        // Skip invalid combinations
                        if ((sameGameIK && !allowCorrelationIK && 
                             (allBets[i].betType !== 'player_prop' || allBets[k].betType !== 'player_prop')) || 
                            (sameGameJK && !allowCorrelationJK && 
                             (allBets[j].betType !== 'player_prop' || allBets[k].betType !== 'player_prop'))) {
                            continue;
                        }
                        
                        // Verify team alignment for each correlation
                        if (allowCorrelationIK) {
                            const moneylineBet = allBets[i].betType === 'moneyline' ? allBets[i] : allBets[k];
                            const propBet = allBets[i].betType === 'player_prop' ? allBets[i] : allBets[k];
                            
                            if (propBet.team !== moneylineBet.team) {
                            continue;
                            }
                        }
                        
                        if (allowCorrelationJK) {
                            const moneylineBet = allBets[j].betType === 'moneyline' ? allBets[j] : allBets[k];
                            const propBet = allBets[j].betType === 'player_prop' ? allBets[j] : allBets[k];
                            
                            if (propBet.team !== moneylineBet.team) {
                                continue;
                            }
                        }
                        
                        const legs = [allBets[i], allBets[j], allBets[k]];
                        const combinedHold = calculateCombinedHold(legs);
                        
                        // Skip if combined hold is too high - more lenient limit
                        if (combinedHold > 0.12) { // Increased from 0.10 to 0.12 (12% maximum combined hold)
                            continue;
                        }
                        
                        // Check if all legs have positive EV
                        const allPositiveEV = legs.every(leg => 
                            parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0, leg.isAvoidRange)) > 0
                        );
                        
                        // Calculate base confidence
                        let confidence = calculateParlayConfidence(legs);
                        
                        // Apply correlation boost if applicable
                        if (allowCorrelationIJ || allowCorrelationIK || allowCorrelationJK) {
                            confidence *= 1.15; // 15% boost for correlated legs
                        }
                        
                        const parlay = {
                            id: `parlay-${i}-${j}-${k}`,
                            name: `3-Team Lay`,
                            legs,
                            totalOdds: calculateParlayOdds(legs),
                            confidence: confidence,
                            combinedHold,
                            allPositiveEV,
                            isCorrelated: allowCorrelationIJ || allowCorrelationIK || allowCorrelationJK
                        };
                        parlays.push(parlay);
                    }
                }
            }
        }

        // Add 4-leg parlays for maximum profit
        if (allBets.length >= 4) {
            for (let i = 0; i < allBets.length - 3; i++) {
                for (let j = i + 1; j < allBets.length - 2; j++) {
                    // Check for duplicates
                    if (isDuplicateBet(allBets[i], allBets[j])) {
                        continue;
                    }
                    
                    // Check for correlations between i and j
                    const sameGameIJ = allBets[i].gameId === allBets[j].gameId;
                    const allowCorrelationIJ = 
                        sameGameIJ && 
                        ((allBets[i].betType === 'moneyline' && allBets[j].betType === 'player_prop') ||
                         (allBets[i].betType === 'player_prop' && allBets[j].betType === 'moneyline'));
                        
                    // Skip invalid combinations
                    if (sameGameIJ && !allowCorrelationIJ &&
                        (allBets[i].betType !== 'player_prop' || allBets[j].betType !== 'player_prop')) {
                        continue;
                    }
                    
                    // Verify team alignment for correlated bets
                    if (allowCorrelationIJ) {
                        const moneylineBet = allBets[i].betType === 'moneyline' ? allBets[i] : allBets[j];
                        const propBet = allBets[i].betType === 'player_prop' ? allBets[i] : allBets[j];
                        
                        if (propBet.team !== moneylineBet.team) {
                            continue;
                        }
                    }
                    
                    for (let k = j + 1; k < allBets.length - 1; k++) {
                        // Check for duplicates
                        if (isDuplicateBet(allBets[i], allBets[k]) || isDuplicateBet(allBets[j], allBets[k])) {
                            continue;
                        }
                        
                        // Check for correlations with k
                        const sameGameIK = allBets[i].gameId === allBets[k].gameId;
                        const sameGameJK = allBets[j].gameId === allBets[k].gameId;
                        
                        const allowCorrelationIK = 
                            sameGameIK && 
                            ((allBets[i].betType === 'moneyline' && allBets[k].betType === 'player_prop') ||
                             (allBets[i].betType === 'player_prop' && allBets[k].betType === 'moneyline'));
                             
                        const allowCorrelationJK = 
                            sameGameJK && 
                            ((allBets[j].betType === 'moneyline' && allBets[k].betType === 'player_prop') ||
                             (allBets[j].betType === 'player_prop' && allBets[k].betType === 'moneyline'));
                            
                        // Skip invalid combinations
                        if ((sameGameIK && !allowCorrelationIK && 
                             (allBets[i].betType !== 'player_prop' || allBets[k].betType !== 'player_prop')) || 
                            (sameGameJK && !allowCorrelationJK && 
                             (allBets[j].betType !== 'player_prop' || allBets[k].betType !== 'player_prop'))) {
                            continue;
                        }
                        
                        // Verify team alignment for each correlation
                        if (allowCorrelationIK) {
                            const moneylineBet = allBets[i].betType === 'moneyline' ? allBets[i] : allBets[k];
                            const propBet = allBets[i].betType === 'player_prop' ? allBets[i] : allBets[k];
                            
                            if (propBet.team !== moneylineBet.team) {
                                continue;
                            }
                        }
                        
                        if (allowCorrelationJK) {
                            const moneylineBet = allBets[j].betType === 'moneyline' ? allBets[j] : allBets[k];
                            const propBet = allBets[j].betType === 'player_prop' ? allBets[j] : allBets[k];
                            
                            if (propBet.team !== moneylineBet.team) {
                                continue;
                            }
                        }
                        
                        for (let l = k + 1; l < allBets.length; l++) {
                            // Check for duplicates
                            if (isDuplicateBet(allBets[i], allBets[l]) || 
                                isDuplicateBet(allBets[j], allBets[l]) || 
                                isDuplicateBet(allBets[k], allBets[l])) {
                                continue;
                            }
                            
                            // Check for correlations with l
                            const sameGameIL = allBets[i].gameId === allBets[l].gameId;
                            const sameGameJL = allBets[j].gameId === allBets[l].gameId;
                            const sameGameKL = allBets[k].gameId === allBets[l].gameId;
                            
                            const allowCorrelationIL = 
                                sameGameIL && 
                                ((allBets[i].betType === 'moneyline' && allBets[l].betType === 'player_prop') ||
                                 (allBets[i].betType === 'player_prop' && allBets[l].betType === 'moneyline'));
                                
                            const allowCorrelationJL = 
                                sameGameJL && 
                                ((allBets[j].betType === 'moneyline' && allBets[l].betType === 'player_prop') ||
                                 (allBets[j].betType === 'player_prop' && allBets[l].betType === 'moneyline'));
                                
                            const allowCorrelationKL = 
                                sameGameKL && 
                                ((allBets[k].betType === 'moneyline' && allBets[l].betType === 'player_prop') ||
                                 (allBets[k].betType === 'player_prop' && allBets[l].betType === 'moneyline'));
                            
                            // Skip invalid combinations
                            if ((sameGameIL && !allowCorrelationIL && 
                                 (allBets[i].betType !== 'player_prop' || allBets[l].betType !== 'player_prop')) || 
                                (sameGameJL && !allowCorrelationJL && 
                                 (allBets[j].betType !== 'player_prop' || allBets[l].betType !== 'player_prop')) ||
                                (sameGameKL && !allowCorrelationKL && 
                                 (allBets[k].betType !== 'player_prop' || allBets[l].betType !== 'player_prop'))) {
                                continue;
                            }
                            
                            // Verify team alignment for each correlation
                            if (allowCorrelationIL) {
                                const moneylineBet = allBets[i].betType === 'moneyline' ? allBets[i] : allBets[l];
                                const propBet = allBets[i].betType === 'player_prop' ? allBets[i] : allBets[l];
                                
                                if (propBet.team !== moneylineBet.team) {
                                    continue;
                                }
                            }
                            
                            if (allowCorrelationJL) {
                                const moneylineBet = allBets[j].betType === 'moneyline' ? allBets[j] : allBets[l];
                                const propBet = allBets[j].betType === 'player_prop' ? allBets[j] : allBets[l];
                                
                                if (propBet.team !== moneylineBet.team) {
                                    continue;
                                }
                            }
                            
                            if (allowCorrelationKL) {
                                const moneylineBet = allBets[k].betType === 'moneyline' ? allBets[k] : allBets[l];
                                const propBet = allBets[k].betType === 'player_prop' ? allBets[k] : allBets[l];
                                
                                if (propBet.team !== moneylineBet.team) {
                                    continue;
                                }
                            }
                            
                            const legs = [allBets[i], allBets[j], allBets[k], allBets[l]];
                            const combinedHold = calculateCombinedHold(legs);
                            
                            // Skip if combined hold is too high - more lenient limit
                            if (combinedHold > 0.15) { // Increased from 0.13 to 0.15 (15% maximum combined hold)
                                continue;
                            }
                            
                            // Check if all legs have positive EV
                            const allPositiveEV = legs.every(leg => 
                                parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0, leg.isAvoidRange)) > 0
                            );
                            
                            // Calculate base confidence
                            let confidence = calculateParlayConfidence(legs);
                            
                            // Apply correlation boost if applicable
                            const hasCorrelation = allowCorrelationIJ || allowCorrelationIK || allowCorrelationJK || 
                                                 allowCorrelationIL || allowCorrelationJL || allowCorrelationKL;
                            
                            if (hasCorrelation) {
                                confidence *= 1.15; // 15% boost for correlated legs
                            }
                            
                            // Apply prop type diversity bonus
                            const propDiversityBonus = calculatePropTypeDiversityBonus(legs);
                            confidence *= propDiversityBonus;
                            
                            // Count number of teams in the parlay
                            const uniqueTeams = new Set();
                            legs.forEach(leg => {
                                if (leg.team) uniqueTeams.add(leg.team);
                                // For player props, add both teams for diversity counting
                                if (leg.betType === 'player_prop') {
                                    uniqueTeams.add(leg.homeTeam);
                                    uniqueTeams.add(leg.awayTeam);
                                }
                            });
                            
                            // Create parlay object
                            const parlay = {
                                id: `parlay-${i}-${j}-${k}-${l}`,
                                name: `4-Team Lay`,
                                legs,
                                totalOdds: calculateParlayOdds(legs),
                                confidence: confidence,
                                combinedHold,
                                allPositiveEV,
                                isCorrelated: hasCorrelation,
                                teamCount: uniqueTeams.size,
                                propDiversityBonus: propDiversityBonus
                            };
                            parlays.push(parlay);
                        }
                    }
                }
            }
        }

        // Sort parlays by a combination of confidence, hold percentage, and leg count
        const sortedParlays = parlays.sort((a, b) => {
            // First prioritize parlays with all positive EV legs
            if (a.allPositiveEV && !b.allPositiveEV) return -1;
            if (!a.allPositiveEV && b.allPositiveEV) return 1;
            
            // Calculate prop type diversity bonus
            let aPropDiversity = calculatePropTypeDiversityBonus(a.legs);
            let bPropDiversity = calculatePropTypeDiversityBonus(b.legs);
            
            // Apply bonus to each parlay's score
            const aScore = a.confidence * (1 - a.combinedHold) * aPropDiversity;
            const bScore = b.confidence * (1 - b.combinedHold) * bPropDiversity;
            return bScore - aScore;
        });

        // Helper function to calculate diversity bonus for player prop types
        function calculatePropTypeDiversityBonus(legs) {
            if (!legs || legs.length === 0) return 1.0;
            
            // Only consider player prop legs
            const propLegs = legs.filter(leg => leg.betType === 'player_prop');
            if (propLegs.length <= 1) return 1.0; // No bonus for 0-1 props
            
            // Count different prop types
            const propTypes = new Set();
            
            propLegs.forEach(leg => {
                const propType = leg.propType || leg.statType || "";
                
                if (propType.includes('Points')) {
                    propTypes.add('points');
                } else if (propType.includes('Rebounds')) {
                    propTypes.add('rebounds');
                } else if (propType.includes('Assists')) {
                    propTypes.add('assists');
                } else if (propType.includes('3PT') || propType.includes('Threes') || propType.includes('3-Point')) {
                    propTypes.add('threes');
                } else if (propType.includes('Blocks')) {
                    propTypes.add('blocks');
                } else if (propType.includes('Steals')) {
                    propTypes.add('steals');
                } else if (propType.includes('Double') || propType.includes('Triple')) {
                    propTypes.add('double');
                } else {
                    propTypes.add('other');
                }
            });
            
            // Calculate bonus based on diversity
            const uniqueTypesCount = propTypes.size;
            const maxTypes = Math.min(propLegs.length, 6); // Max number of possible unique types
            
            // Bonus ranges from 1.0 (no diversity) to 1.25 (maximum diversity)
            const bonus = 1.0 + (uniqueTypesCount / maxTypes) * 0.25;
            
            return bonus;
        }

        // Add enhanced parlay generation for 4, 5, and 6 leg parlays
        // Only proceed if we have enough filtered bets
        if (allBets.length >= 4) {
            // Generate 4-leg parlays
            generateMultiLegParlays(allBets, 4, parlays);
            
            // Generate 5-leg parlays if we have enough bets
            if (allBets.length >= 5) {
                generateMultiLegParlays(allBets, 5, parlays);
                
                // Generate 6-leg parlays if we have enough bets
                if (allBets.length >= 6) {
                    generateMultiLegParlays(allBets, 6, parlays);
                }
            }
        }

        // Helper function to generate n-leg parlays
        function generateMultiLegParlays(bets, legCount, parlayArray) {
            // Helper function to check if any legs are correlated
            // This is now defined at the top of generateSuggestedParlays
            
            // Helper function to calculate a diversity bonus based on prop types
            function calculatePropTypeDiversityBonus(legs) {
                // Only player prop legs
                const propLegs = legs.filter(leg => leg.betType === 'player_prop');
                if (propLegs.length <= 1) return 1.0; // No bonus for 0-1 props
                
                // Count different prop types
                const propTypes = new Set();
                
                propLegs.forEach(leg => {
                    const propType = leg.propType || leg.statType || "";
                    
                    if (propType.includes('Points')) {
                        propTypes.add('points');
                    } else if (propType.includes('Rebounds')) {
                        propTypes.add('rebounds');
                    } else if (propType.includes('Assists')) {
                        propTypes.add('assists');
                    } else if (propType.includes('3PT') || propType.includes('Threes') || propType.includes('3-Point')) {
                        propTypes.add('threes');
                    } else if (propType.includes('Blocks')) {
                        propTypes.add('blocks');
                    } else if (propType.includes('Steals')) {
                        propTypes.add('steals');
                    } else if (propType.includes('Double') || propType.includes('Triple')) {
                        propTypes.add('double');
                    } else {
                        propTypes.add('other');
                    }
                });
                
                // Calculate bonus based on diversity
                const uniqueTypesCount = propTypes.size;
                const maxTypes = Math.min(propLegs.length, 6); // Max number of possible unique types
                
                // Bonus ranges from 1.0 (no diversity) to 1.25 (maximum diversity)
                const bonus = 1.0 + (uniqueTypesCount / maxTypes) * 0.25;
                
                return bonus;
            }
            
            // Local versions of helper functions to avoid reference errors
            function localCountPropLegs(legs) {
                return legs.filter(leg => leg.betType === 'player_prop').length;
            }
            
            function localCountStandardLegs(legs) {
                return legs.filter(leg => leg.betType !== 'player_prop').length;
            }
            
            function localCalculateAverageEV(legs) {
                // Calculate individual EVs for each leg
                const evValues = legs.map(leg => 
                    parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0, leg.isAvoidRange))
                );
                
                // Calculate the average EV
                const avgEV = evValues.reduce((sum, ev) => sum + ev, 0) / evValues.length;
                
                // Return the average EV formatted to 1 decimal place
                return avgEV.toFixed(1);
            }
            
            function localCalculateCorrelationStrength(legs) {
                const correlatedLegs = legs.filter(leg => 
                    legs.some(otherLeg => 
                        leg !== otherLeg && 
                        leg.gameId === otherLeg.gameId &&
                        ((leg.betType === 'moneyline' && otherLeg.betType === 'player_prop') ||
                         (leg.betType === 'player_prop' && otherLeg.betType === 'moneyline'))
                    )
                );
                return correlatedLegs.length >= 2 ? 'Strong' : 'Moderate';
            }
            
            // Use recursion to generate combinations of legCount legs
            function generateCombinations(startIdx, currentLegs) {
                // Base case: we have selected legCount legs
                if (currentLegs.length === legCount) {
                    // Check if the combination is valid
                    if (isValidParlayCombo(currentLegs)) {
                        const combinedHold = calculateCombinedHold(currentLegs);
                        const holdThreshold = 0.08 + (legCount - 2) * 0.02; // Increase threshold for more legs
                        
                        // Skip if combined hold is too high
                        if (combinedHold <= holdThreshold) {
                            // Check if all legs have positive EV
                            const allPositiveEV = currentLegs.every(leg => 
                                parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0, leg.isAvoidRange)) > 0
                            );
                            
                            // Calculate base confidence
                            let confidence = calculateParlayConfidence(currentLegs);
                            
                            // Apply correlation boost if applicable
                            const hasCorrelation = hasAnyCorrelation(currentLegs);
                            if (hasCorrelation) {
                                confidence *= 1.15; // 15% boost for correlated legs
                            }
                            
                            // Apply prop type diversity bonus
                            const propDiversityBonus = calculatePropTypeDiversityBonus(currentLegs);
                            confidence *= propDiversityBonus;
                            
                            // Count number of teams in the parlay
                            const uniqueTeams = new Set();
                            currentLegs.forEach(leg => {
                                if (leg.team) uniqueTeams.add(leg.team);
                                // For player props, add both teams for diversity counting
                                if (leg.betType === 'player_prop') {
                                    uniqueTeams.add(leg.homeTeam);
                                    uniqueTeams.add(leg.awayTeam);
                                }
                            });
                            
                            // Create parlay object
                            const parlay = {
                                id: `parlay-${currentLegs.map(leg => bets.indexOf(leg)).join('-')}`,
                                name: `${legCount}-Team Lay`,
                                legs: [...currentLegs],
                                totalOdds: calculateParlayOdds(currentLegs),
                                confidence: confidence,
                                combinedHold,
                                allPositiveEV,
                                isCorrelated: hasCorrelation,
                                teamCount: uniqueTeams.size,
                                propDiversityBonus: propDiversityBonus
                            };
                            
                            // Calculate additional metrics
                            parlay.propCount = localCountPropLegs(parlay.legs);
                            parlay.standardCount = parlay.legs.length - parlay.propCount;
                            parlay.averageEV = localCalculateAverageEV(parlay.legs);
                            parlay.normalizedConfidence = normalizeConfidence(parlay.confidence);
                            
                            if (parlay.isCorrelated) {
                                parlay.correlationStrength = localCalculateCorrelationStrength(parlay.legs);
                            }
                            
                            parlayArray.push(parlay);
                            
                            // Limit the number of parlays to avoid too many combinations
                            const maxParlayCombos = 50 + (legCount * 10); // Higher limit for exploring combinations
                            if (parlayArray.filter(p => p.legs.length === legCount).length >= maxParlayCombos) {
                                return true; // Signal to stop generating more
                            }
                        }
                    }
                    return false;
                }
                
                // Check if we've generated enough parlays of this leg count already
                const maxParlayCombos = 50 + (legCount * 10);
                if (parlayArray.filter(p => p.legs.length === legCount).length >= maxParlayCombos) {
                    return true; // Signal to stop generating more
                }
                
                // Recursive case: try adding more legs
                for (let i = startIdx; i < bets.length; i++) {
                    // Add current bet to legs
                    currentLegs.push(bets[i]);
                    
                    // Recursive call
                    const shouldStop = generateCombinations(i + 1, currentLegs);
                    if (shouldStop) return true;
                    
                    // Backtrack
                    currentLegs.pop();
                }
                
                return false;
            }
            
            // Start generating combinations
            generateCombinations(0, []);
        }

        // Helper function to check if a combination is valid (no duplicates, proper same-game handling)
        function isValidParlayCombo(legs) {
            // Check for duplicates
            for (let i = 0; i < legs.length; i++) {
                for (let j = i + 1; j < legs.length; j++) {
                    if (isDuplicateBet(legs[i], legs[j])) {
                        return false;
                    }
                    
                    // Same game checks
                    const sameGame = legs[i].gameId === legs[j].gameId;
                    if (sameGame) {
                        // Allow player prop + moneyline from same team
                        const allowCorrelation = 
                            (legs[i].betType === 'moneyline' && legs[j].betType === 'player_prop') ||
                            (legs[i].betType === 'player_prop' && legs[j].betType === 'moneyline');
                            
                        // For moneyline + player prop, ensure team alignment
                        if (allowCorrelation) {
                            const moneylineBet = legs[i].betType === 'moneyline' ? legs[i] : legs[j];
                            const propBet = legs[i].betType === 'player_prop' ? legs[i] : legs[j];
                            
                            if (propBet.team !== moneylineBet.team) {
                                return false;
                            }
                        } else if (legs[i].betType !== 'player_prop' || legs[j].betType !== 'player_prop') {
                            // If not both player props and not a valid correlation, disallow
                            return false;
                        }
                    }
                }
            }
            
            // Count number of teams in the parlay - enforce variety
            const uniqueTeams = new Set();
            legs.forEach(leg => {
                if (leg.team) uniqueTeams.add(leg.team);
                // For player props, add both teams for diversity counting
                if (leg.betType === 'player_prop') {
                    uniqueTeams.add(leg.homeTeam);
                    uniqueTeams.add(leg.awayTeam);
                }
            });
            
            // Verify we don't exceed the max number of different teams (8)
            return uniqueTeams.size <= 8;
        }

        // Enhance fallback mechanism for larger parlays
        // Add fallback logic for when no parlays are generated
        if (parlays.length === 0) {
            console.log("No parlays generated with initial criteria - applying ENHANCED fallback mechanism");
            
            // Helper function for same game check - define at the top
            function allowSameGameBets(bet1, bet2) {
                return (bet1.betType === 'moneyline' && bet2.betType === 'player_prop') ||
                       (bet1.betType === 'player_prop' && bet2.betType === 'moneyline');
            }
            
            // Helper function to check if any legs are correlated - define at the top
            function hasAnyCorrelation(legs) {
                for (let i = 0; i < legs.length; i++) {
                    for (let j = i + 1; j < legs.length; j++) {
                        if (legs[i].gameId === legs[j].gameId && 
                            ((legs[i].betType === 'moneyline' && legs[j].betType === 'player_prop') ||
                             (legs[i].betType === 'player_prop' && legs[j].betType === 'moneyline'))) {
                            return true;
                        }
                    }
                }
                return false;
            }
            
            // Fallback #1: Try just using raw bets before hold filtering
            let fallbackBets = [...bestBets, ...playerPropBets];
            fallbackBets = deduplicateBets(fallbackBets);
            
            // Sort by value regardless of hold
            fallbackBets = fallbackBets.sort((a, b) => {
                // Prioritize positive expected value
                const evA = parseFloat(calculateExpectedValue(a.odds, a.value, a.marketEfficiency || 1.0, a.isAvoidRange));
                const evB = parseFloat(calculateExpectedValue(b.odds, b.value, b.marketEfficiency || 1.0, b.isAvoidRange));
                
                // Apply an odds range factor to the score
                const oddsRangeFactorA = a.isAvoidRange ? 0.4 : (a.isWithinTargetRange ? 1.2 : 1.0);
                const oddsRangeFactorB = b.isAvoidRange ? 0.4 : (b.isWithinTargetRange ? 1.2 : 1.0);
                
                // Use a mix of EV and value for sorting, now with odds range factor
                const scoreA = evA > 0 ? (a.value * 0.5 + evA * 0.5) * oddsRangeFactorA : a.value * 0.4 * oddsRangeFactorA;
                const scoreB = evB > 0 ? (b.value * 0.5 + evB * 0.5) * oddsRangeFactorB : b.value * 0.4 * oddsRangeFactorB;
                
                return scoreB - scoreA;
            });
            
            // EXTREMELY LENIENT: Only filter out strongly negative EV
            fallbackBets = fallbackBets.filter(bet => {
                const ev = parseFloat(calculateExpectedValue(bet.odds, bet.value, bet.marketEfficiency || 1.0, bet.isAvoidRange));
                // Additional filtering for avoid range bets
                if (bet.isAvoidRange) {
                    return ev > 2.0; // Require much higher EV for avoid range
                }
                return ev > -2;  // Allow even slightly negative EV bets in desperate fallback
            });
            
            console.log(`Fallback has ${fallbackBets.length} total bets to work with after filtering`);
            
            // Helper function to calculate combined hold
            function calculateCombinedHold(legs) {
                return legs.reduce((total, leg) => {
                    const hold = leg.holdPercentage || (convertOddsToImpliedProbability(leg.odds) / 100 * 1.05) - 1;
                    return total + hold;
                }, 0);
            }
            
            // Helper function to calculate parlay confidence
            function calculateParlayConfidence(legs) {
                return legs.reduce((total, leg) => total + leg.value, 0) / legs.length;
            }
            
            // Generate simple 2-leg parlays with minimal restrictions
            if (fallbackBets.length >= 2) {
                for (let i = 0; i < Math.min(fallbackBets.length - 1, 15); i++) {
                    for (let j = i + 1; j < Math.min(fallbackBets.length, 25); j++) {
                        if (isDuplicateBet(fallbackBets[i], fallbackBets[j])) {
                            continue;
                        }
                        
                        if (fallbackBets[i].gameId === fallbackBets[j].gameId) {
                            // Still avoid same-game parlays except player prop + moneyline
                            const sameGame = fallbackBets[i].gameId === fallbackBets[j].gameId;
                            const allowCorrelation = 
                                sameGame && 
                                ((fallbackBets[i].betType === 'moneyline' && fallbackBets[j].betType === 'player_prop') ||
                                 (fallbackBets[i].betType === 'player_prop' && fallbackBets[j].betType === 'moneyline'));
                            
                            if (sameGame && !allowCorrelation) continue;
                        }
                        
                        const legs = [fallbackBets[i], fallbackBets[j]];
                        const combinedHold = calculateCombinedHold(legs);
                        
                        // Much more lenient hold threshold
                        if (combinedHold > 0.35) continue; // Super lenient in fallback for 2-leg parlays
                        
                        const confidence = calculateParlayConfidence(legs);
                        
                        parlays.push({
                            id: `fallback-parlay-${i}-${j}`,
                            name: `2-Team Lay`,
                            legs,
                            totalOdds: calculateParlayOdds(legs),
                            confidence: confidence,
                            combinedHold,
                            allPositiveEV: true,
                            isCorrelated: fallbackBets[i].gameId === fallbackBets[j].gameId && allowSameGameBets(fallbackBets[i], fallbackBets[j]),
                            isFallback: true
                        });
                        
                        // Add metrics
                        const lastParlay = parlays[parlays.length - 1];
                        lastParlay.propCount = localCountPropLegs(lastParlay.legs);
                        lastParlay.standardCount = lastParlay.legs.length - lastParlay.propCount;
                        lastParlay.averageEV = localCalculateAverageEV(lastParlay.legs);
                        lastParlay.normalizedConfidence = normalizeConfidence(lastParlay.confidence);
                        if (lastParlay.isCorrelated) {
                            lastParlay.correlationStrength = localCalculateCorrelationStrength(lastParlay.legs);
                        }
                    }
                    
                    // Break early if we have enough 2-leg parlays
                    if (parlays.filter(p => p.legs.length === 2).length >= 4) break;
                }
            }
            
            // Generate 3-leg parlays with minimal restrictions
            if (fallbackBets.length >= 3) {
                for (let i = 0; i < Math.min(fallbackBets.length - 2, 10); i++) {
                    for (let j = i + 1; j < Math.min(fallbackBets.length - 1, 15); j++) {
                        if (isDuplicateBet(fallbackBets[i], fallbackBets[j])) {
                            continue;
                        }
                        
                        // Handle same game checks for i and j
                        const sameGameIJ = fallbackBets[i].gameId === fallbackBets[j].gameId;
                        const allowCorrelationIJ = 
                            sameGameIJ && 
                            ((fallbackBets[i].betType === 'moneyline' && fallbackBets[j].betType === 'player_prop') ||
                             (fallbackBets[i].betType === 'player_prop' && fallbackBets[j].betType === 'moneyline'));
                        
                        if (sameGameIJ && !allowCorrelationIJ) continue;
                        
                        for (let k = j + 1; k < Math.min(fallbackBets.length, 25); k++) {
                            if (isDuplicateBet(fallbackBets[i], fallbackBets[k]) || isDuplicateBet(fallbackBets[j], fallbackBets[k])) {
                                continue;
                            }
                            
                            // Handle same game checks for i, j, and k
                            const sameGameIK = fallbackBets[i].gameId === fallbackBets[k].gameId;
                            const sameGameJK = fallbackBets[j].gameId === fallbackBets[k].gameId;
                            
                            const allowCorrelationIK = 
                                sameGameIK && 
                                ((fallbackBets[i].betType === 'moneyline' && fallbackBets[k].betType === 'player_prop') ||
                                 (fallbackBets[i].betType === 'player_prop' && fallbackBets[k].betType === 'moneyline'));
                            
                            const allowCorrelationJK = 
                                sameGameJK && 
                                ((fallbackBets[j].betType === 'moneyline' && fallbackBets[k].betType === 'player_prop') ||
                                 (fallbackBets[j].betType === 'player_prop' && fallbackBets[k].betType === 'moneyline'));
                            
                            if ((sameGameIK && !allowCorrelationIK) || (sameGameJK && !allowCorrelationJK)) {
                                continue;
                            }
                            
                            const legs = [fallbackBets[i], fallbackBets[j], fallbackBets[k]];
                            const combinedHold = calculateCombinedHold(legs);
                            
                            // Even more lenient hold threshold for 3-leg fallback
                            if (combinedHold > 0.40) continue; // Super lenient in fallback for 3-leg parlays
                            
                            const confidence = calculateParlayConfidence(legs);
                            
                            parlays.push({
                                id: `fallback-parlay-${i}-${j}-${k}`,
                                name: `3-Team Lay`,
                                legs,
                                totalOdds: calculateParlayOdds(legs),
                                confidence: confidence,
                                combinedHold,
                                allPositiveEV: true,
                                isCorrelated: allowCorrelationIJ || allowCorrelationIK || allowCorrelationJK,
                                isFallback: true
                            });
                            
                            // Add metrics
                            const lastParlay = parlays[parlays.length - 1];
                            lastParlay.propCount = localCountPropLegs(lastParlay.legs);
                            lastParlay.standardCount = lastParlay.legs.length - lastParlay.propCount;
                            lastParlay.averageEV = localCalculateAverageEV(lastParlay.legs);
                            lastParlay.normalizedConfidence = normalizeConfidence(lastParlay.confidence);
                            if (lastParlay.isCorrelated) {
                                lastParlay.correlationStrength = localCalculateCorrelationStrength(lastParlay.legs);
                            }
                        }
                        
                        // Break early if we have enough 3-leg parlays
                        if (parlays.filter(p => p.legs.length === 3).length >= 3) break;
                    }
                    // Break early if we have enough 3-leg parlays
                    if (parlays.filter(p => p.legs.length === 3).length >= 3) break;
                }
            }
            
            // Generate 4-leg parlays with minimal restrictions
            if (fallbackBets.length >= 4) {
                for (let i = 0; i < Math.min(fallbackBets.length - 3, 8); i++) {
                    for (let j = i + 1; j < Math.min(fallbackBets.length - 2, 12); j++) {
                        if (isDuplicateBet(fallbackBets[i], fallbackBets[j])) {
                            continue;
                        }
                        
                        // Basic same game check
                        const sameGameIJ = fallbackBets[i].gameId === fallbackBets[j].gameId;
                        const allowCorrelationIJ = 
                            sameGameIJ && 
                            ((fallbackBets[i].betType === 'moneyline' && fallbackBets[j].betType === 'player_prop') ||
                             (fallbackBets[i].betType === 'player_prop' && fallbackBets[j].betType === 'moneyline'));
                        
                        if (sameGameIJ && !allowCorrelationIJ) continue;
                        
                        // Only try a few combinations for third leg
                        for (let k = j + 1; k < Math.min(fallbackBets.length - 1, 14); k++) {
                            if (isDuplicateBet(fallbackBets[i], fallbackBets[k]) || isDuplicateBet(fallbackBets[j], fallbackBets[k])) {
                                continue;
                            }
                            
                            // Skip if too many same game bets
                            const sameGameIK = fallbackBets[i].gameId === fallbackBets[k].gameId;
                            const sameGameJK = fallbackBets[j].gameId === fallbackBets[k].gameId;
                            
                            if ((sameGameIK && !allowSameGameBets(fallbackBets[i], fallbackBets[k])) || 
                                (sameGameJK && !allowSameGameBets(fallbackBets[j], fallbackBets[k]))) {
                                continue;
                            }
                            
                            // Only try a few combinations for fourth leg
                            for (let l = k + 1; l < Math.min(fallbackBets.length, 16); l++) {
                                if (isDuplicateBet(fallbackBets[i], fallbackBets[l]) || 
                                    isDuplicateBet(fallbackBets[j], fallbackBets[l]) || 
                                    isDuplicateBet(fallbackBets[k], fallbackBets[l])) {
                                    continue;
                                }
                                
                                // Skip if too many same game bets
                                const sameGameIL = fallbackBets[i].gameId === fallbackBets[l].gameId;
                                const sameGameJL = fallbackBets[j].gameId === fallbackBets[l].gameId;
                                const sameGameKL = fallbackBets[k].gameId === fallbackBets[l].gameId;
                                
                                if ((sameGameIL && !allowSameGameBets(fallbackBets[i], fallbackBets[l])) || 
                                    (sameGameJL && !allowSameGameBets(fallbackBets[j], fallbackBets[l])) ||
                                    (sameGameKL && !allowSameGameBets(fallbackBets[k], fallbackBets[l]))) {
                                    continue;
                                }
                                
                                const legs = [fallbackBets[i], fallbackBets[j], fallbackBets[k], fallbackBets[l]];
                                const combinedHold = calculateCombinedHold(legs);
                                
                                // Extremely lenient hold threshold for 4-leg fallback 
                                if (combinedHold > 0.45) continue; // Super lenient in fallback for 4-leg parlays
                                
                                const confidence = calculateParlayConfidence(legs);
                                
                                parlays.push({
                                    id: `fallback-parlay-${i}-${j}-${k}-${l}`,
                                    name: `4-Team Lay`,
                                    legs,
                                    totalOdds: calculateParlayOdds(legs),
                                    confidence: confidence,
                                    combinedHold,
                                    allPositiveEV: true,
                                    isCorrelated: hasAnyCorrelation(legs),
                                    isFallback: true
                                });
                                
                                // Add metrics
                                const lastParlay = parlays[parlays.length - 1];
                                lastParlay.propCount = localCountPropLegs(lastParlay.legs);
                                lastParlay.standardCount = lastParlay.legs.length - lastParlay.propCount;
                                lastParlay.averageEV = localCalculateAverageEV(lastParlay.legs);
                                lastParlay.normalizedConfidence = normalizeConfidence(lastParlay.confidence);
                                if (lastParlay.isCorrelated) {
                                    lastParlay.correlationStrength = localCalculateCorrelationStrength(lastParlay.legs);
                                }
                                
                                // If we have enough 4-leg parlays, exit early
                                if (parlays.filter(p => p.legs.length === 4).length >= 3) break;
                            }
                            // If we have enough 4-leg parlays, exit early
                            if (parlays.filter(p => p.legs.length === 4).length >= 3) break;
                        }
                        // If we have enough 4-leg parlays, exit early
                        if (parlays.filter(p => p.legs.length === 4).length >= 3) break;
                    }
                    // If we have enough 4-leg parlays, exit early
                    if (parlays.filter(p => p.legs.length === 4).length >= 3) break;
                }
            }
            
            console.log(`Generated ${parlays.length} fallback parlays: ` + 
                        `${parlays.filter(p => p.legs.length === 2).length} two-leg, ` +
                        `${parlays.filter(p => p.legs.length === 3).length} three-leg, ` +
                        `${parlays.filter(p => p.legs.length === 4).length} four-leg`);

            // Super emergency fallback: If we still don't have parlays, retry with no restrictions
            if (parlays.length === 0) {
                console.log("EMERGENCY FALLBACK ACTIVATED: Using last-resort criteria with no restrictions");
                
                // Take all available bets without filtering
                let emergencyBets = [...allBets]; // Use allBets which should contain all bets before any filtering
                emergencyBets = deduplicateBets(emergencyBets);
                
                // Sort by basic value and take top 25
                emergencyBets = emergencyBets.sort((a, b) => b.value - a.value).slice(0, 25);
                
                // Generate simple 2-leg parlays with no restrictions
                if (emergencyBets.length >= 2) {
                    for (let i = 0; i < Math.min(emergencyBets.length - 1, 10); i++) {
                        for (let j = i + 1; j < Math.min(emergencyBets.length, 15); j++) {
                            if (isDuplicateBet(emergencyBets[i], emergencyBets[j])) {
                                continue;
                            }
                            
                            const legs = [emergencyBets[i], emergencyBets[j]];
                            
                            parlays.push({
                                id: `emergency-parlay-${i}-${j}`,
                                name: `2-Team Lay`,
                                legs,
                                totalOdds: calculateParlayOdds(legs),
                                confidence: 0.5, // Low confidence
                                combinedHold: 0.5, // Assume high hold
                                allPositiveEV: false,
                                isCorrelated: false,
                                isFallback: true,
                                isEmergency: true
                            });
                            
                            // Add standard metrics
                            const lastParlay = parlays[parlays.length - 1];
                            lastParlay.propCount = localCountPropLegs(lastParlay.legs);
                            lastParlay.standardCount = lastParlay.legs.length - lastParlay.propCount;
                            lastParlay.averageEV = "N/A"; // Not calculated for emergency
                            lastParlay.correlationStrength = "N/A";
                            lastParlay.normalizedConfidence = 30; // Fixed low value for emergency fallbacks
                            
                            if (parlays.length >= 10) break;
                        }
                        if (parlays.length >= 10) break;
                    }
                    
                    console.log(`Generated ${parlays.length} EMERGENCY fallback parlays`);
                }
            }
        }

        // Select a mix of parlays based on leg count
        const twoLegParlays = sortedParlays.filter(p => p.legs.length === 2).slice(0, 4);  // Take top 4
        const threeLegParlays = sortedParlays.filter(p => p.legs.length === 3).slice(0, 3); // Take top 3
        const fourLegParlays = sortedParlays.filter(p => p.legs.length === 4).slice(0, 3);  // Take top 3
        
        // Add support for 5 and 6 leg parlays
        const fiveLegParlays = sortedParlays.filter(p => p.legs.length === 5).slice(0, 3); // Take top 3
        const sixLegParlays = sortedParlays.filter(p => p.legs.length === 6).slice(0, 2);  // Take top 2
        
        // Combine parlays of different sizes
        let topParlays = [...twoLegParlays, ...threeLegParlays, ...fourLegParlays, ...fiveLegParlays, ...sixLegParlays];
        
        // If we don't have enough parlays, fill with the best remaining ones
        if (topParlays.length < 15) {
            const remainingParlays = sortedParlays.filter(p => 
                !topParlays.some(tp => tp.id === p.id)
            ).slice(0, 15 - topParlays.length);
            
            topParlays = [...topParlays, ...remainingParlays];
        }
        
        // Ensure we have at most 15 parlays
        topParlays = topParlays.slice(0, 15);
        
        // Add helper functions for parlay metrics
        const calculateAverageEV = (legs) => {
            // Calculate individual EVs for each leg
            const evValues = legs.map(leg => 
                parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0))
            );
            
            // Calculate the average EV
            const avgEV = evValues.reduce((sum, ev) => sum + ev, 0) / evValues.length;
            
            // Return the average EV formatted to 1 decimal place
            return avgEV.toFixed(1);
        };
        
        const calculateCorrelationStrength = (legs) => {
            const correlatedLegs = legs.filter(leg => 
                legs.some(otherLeg => 
                    leg !== otherLeg && 
                    leg.gameId === otherLeg.gameId &&
                    ((leg.betType === 'moneyline' && otherLeg.betType === 'player_prop') ||
                     (leg.betType === 'player_prop' && otherLeg.betType === 'moneyline'))
                )
            );
            return correlatedLegs.length >= 2 ? 'Strong' : 'Moderate';
        };
        
        const countPropLegs = (legs) => {
            return legs.filter(leg => leg.betType === 'player_prop').length;
        };
        
        const countStandardLegs = (legs) => {
            return legs.filter(leg => leg.betType !== 'player_prop').length;
        };
        
        // Add a function to calculate the diversity of prop types in a parlay
        const calculatePropTypeDiversity = (legs) => {
            // Only consider player prop legs
            const propLegs = legs.filter(leg => leg.betType === 'player_prop');
            if (propLegs.length <= 1) return 1.0; // No diversity with 0-1 props
            
            // Count different prop types
            const propTypes = new Set();
            
            propLegs.forEach(leg => {
                const propType = leg.propType || leg.statType || "";
                
                if (propType.includes('Points')) {
                    propTypes.add('points');
                } else if (propType.includes('Rebounds')) {
                    propTypes.add('rebounds');
                } else if (propType.includes('Assists')) {
                    propTypes.add('assists');
                } else if (propType.includes('3PT') || propType.includes('Threes') || propType.includes('3-Point')) {
                    propTypes.add('threes');
                } else if (propType.includes('Blocks')) {
                    propTypes.add('blocks');
                } else if (propType.includes('Steals')) {
                    propTypes.add('steals');
                } else if (propType.includes('Double') || propType.includes('Triple')) {
                    propTypes.add('double');
                } else {
                    propTypes.add('other');
                }
            });
            
            // Calculate diversity score (0-100 scale)
            const uniqueTypesCount = propTypes.size;
            const maxPossibleTypes = Math.min(propLegs.length, 7); // Max of 7 different prop types
            
            // Return diversity as percentage
            return Math.round((uniqueTypesCount / maxPossibleTypes) * 100);
        };
        
        // Add these metrics to each parlay
        topParlays.forEach(parlay => {
            // Calculate individual EVs for each leg first
            parlay.legs.forEach(leg => {
                leg.evValue = parseFloat(calculateExpectedValue(leg.odds, leg.value, leg.marketEfficiency || 1.0, leg.isAvoidRange)).toFixed(1);
            });
            
            parlay.averageEV = calculateAverageEV(parlay.legs);
            parlay.correlationStrength = parlay.isCorrelated ? calculateCorrelationStrength(parlay.legs) : null;
            parlay.propCount = countPropLegs(parlay.legs);
            parlay.standardCount = countStandardLegs(parlay.legs);
            parlay.normalizedConfidence = normalizeConfidence(parlay.confidence);
            parlay.propTypeDiversity = calculatePropTypeDiversity(parlay.legs);
        });

        // Sort the final parlays by our combined metrics including diversity
        topParlays.sort((a, b) => {
            // First prioritize non-emergency parlays
            if (!a.isEmergency && b.isEmergency) return -1;
            if (a.isEmergency && !b.isEmergency) return 1;
            
            // Then prioritize non-fallback parlays
            if (!a.isFallback && b.isFallback) return -1;
            if (a.isFallback && !b.isFallback) return 1;
            
            // Calculate base score from confidence and hold
            let aScore = parseFloat(a.normalizedConfidence) * (1 - (a.combinedHold || 0));
            let bScore = parseFloat(b.normalizedConfidence) * (1 - (b.combinedHold || 0));
            
            // Add diversity bonuses (up to 25% boost for diverse prop types)
            const aDiversityBoost = 1 + ((a.propTypeDiversity || 0) / 100) * 0.25;
            const bDiversityBoost = 1 + ((b.propTypeDiversity || 0) / 100) * 0.25;
            
            aScore *= aDiversityBoost;
            bScore *= bDiversityBoost;
            
            // Give slight preference to parlays with more legs
            aScore *= (1 + (a.legs.length * 0.02));
            bScore *= (1 + (b.legs.length * 0.02));
            
            return bScore - aScore;
        });
        
        // Add a check to make sure no parlay has duplicate legs
        topParlays.forEach((parlay, index) => {
            const legIds = new Set();
            const hasDuplicates = parlay.legs.some(leg => {
                let id;
                if (leg.betType === 'player_prop') {
                    id = `${leg.betType}_${leg.playerName}_${leg.propType}_${leg.selection}_${leg.point}`;
                } else {
                    id = `${leg.betType}_${leg.team}_${leg.selection}`;
                }
                
                if (legIds.has(id)) {
                    console.warn(`Parlay ${index + 1} has duplicate leg: ${id}`);
                    return true;
                }
                
                legIds.add(id);
                return false;
            });
            
            if (hasDuplicates) {
                console.warn(`WARNING: Parlay ${index + 1} has duplicate legs!`);
            }
        });
        
        console.log("Generated top parlays:", topParlays);

        // Calculate overall stats
        if (topParlays.length > 0) {
            const avgValues = {
                totalValue: topParlays.reduce((sum, p) => sum + p.legs.reduce((s, l) => s + l.value, 0), 0) / topParlays.length,
                potentialReturn: topParlays.reduce((sum, p) => sum + calculatePotentialReturn(p.totalOdds, 10), 0) / topParlays.length,
                oddsImpliedProbability: topParlays.reduce((sum, p) => sum + (100 / convertOddsToImpliedProbability(p.totalOdds)), 0) / topParlays.length
            };
            
            setParlayStats({
                totalValue: avgValues.totalValue.toFixed(2),
                potentialReturn: avgValues.potentialReturn.toFixed(2),
                oddsImpliedProbability: avgValues.oddsImpliedProbability.toFixed(2)
            });
        }

        return topParlays;
    };

    // Calculate hold percentage for moneyline bets
    const calculateHoldPercentage = (homeOdds, awayOdds) => {
        if (!homeOdds || !awayOdds) return 0.1; // Default to 10% if we don't have both odds
        
        // Convert American odds to implied probabilities
        const homeProb = convertOddsToImpliedProbability(homeOdds) / 100;
        const awayProb = convertOddsToImpliedProbability(awayOdds) / 100;
        
        // Calculate hold percentage
        const hold = (homeProb + awayProb) - 1;
        
        // Return as a positive percentage between 0 and 1
        return Math.max(0, hold);
    };

    // Calculate combined hold percentage for a parlay
    const calculateCombinedHold = (legs) => {
        if (!legs || legs.length === 0) return 0;
        
        // Calculate individual hold percentages
        const holdPercentages = legs.map(leg => {
            if (leg.holdPercentage !== undefined) return leg.holdPercentage;
            
            // For player props and other bets, estimate hold based on odds
            const impliedProb = convertOddsToImpliedProbability(leg.odds) / 100;
            // Estimate 5% markup for player props, 3% for other bet types
            const markup = leg.betType === 'player_prop' ? 0.05 : 0.03;
            return Math.max(0, (impliedProb * (1 + markup)) - 1); // Estimate markup
        });
        
        // Calculate combined hold - use sum for combined effect
        const combinedHold = holdPercentages.reduce((total, hold) => total + hold, 0);
        return combinedHold;
    };

    // Filter bets based on hold percentage and odds range
    const filterBetsByHold = (bets) => {
        return bets.filter(bet => {
            // Calculate or retrieve hold percentage
            let holdPercentage = bet.holdPercentage;
            
            // Fall back to estimating hold for non-moneyline bets
            if (holdPercentage === undefined) {
                const impliedProb = convertOddsToImpliedProbability(bet.odds) / 100;
                // Use different markup factors for different bet types
                const markup = bet.betType === 'player_prop' ? 0.05 : 0.03;
                holdPercentage = (impliedProb * (1 + markup)) - 1;
            }
            
            // Calculate estimated EV
            const evPercentage = parseFloat(calculateExpectedValue(bet.odds, bet.value, bet.marketEfficiency || 1.0, bet.isAvoidRange));
            
            // Apply stricter filtering for bets in the avoid range
            if (bet.isAvoidRange) {
                // For bets in avoid range, require significantly higher EV
                const minRequiredEV = bet.betType === 'player_prop' ? 3.0 : 4.0;
                return evPercentage >= minRequiredEV && holdPercentage <= 0.04;
            }
            
            // EV range constraints based on market type
            if (bet.betType === 'player_prop') {
                // Special handling for negative odds player props from Fanduel
                if (bet.odds < 0 && bet.bookmaker && bet.bookmaker.toLowerCase() === 'fanduel') {
                    // Be more permissive for Fanduel negative odds props
                    return evPercentage >= 0.8 && holdPercentage <= 0.06;
                }
                
                // Much wider EV range for props based on market inefficiency
                const propMinEV = bet.marketEfficiency >= 1.3 ? 0.5 : 0.8; // Very low minimum thresholds
                const propMaxEV = bet.marketEfficiency >= 1.3 ? 12 : 10; // Much wider maximum
                return evPercentage >= propMinEV && evPercentage <= propMaxEV && holdPercentage <= 0.055; // Increased hold limit further
            } else {
                // Much less strict EV range for standard markets
                return evPercentage >= 1.0 && evPercentage <= 6 && holdPercentage <= 0.05; // Wider range
            }
        });
    };

    // Calculate value score for a bet (simple version for now)
    const calculateBetValue = (odds) => {
        if (!odds) return 0;
        
        // For positive odds: higher is better value but apply 10% reduction
        if (odds > 0) {
            return (odds / 100) * 0.9;
        } 
        // For negative odds: closer to 0 is better value, apply 10% boost
        else {
            return (100 / Math.abs(odds)) * 1.1;
        }
    };

    // Calculate combined odds for a parlay
    const calculateParlayOdds = (legs) => {
        if (!legs || legs.length === 0) return 0;

        // Convert American odds to decimal
        const decimalOdds = legs.map(leg => {
            const odds = leg.odds;
            if (odds > 0) {
                return 1 + (odds / 100);
            } else {
                return 1 + (100 / Math.abs(odds));
            }
        });

        // Multiply all decimal odds
        const combinedDecimal = decimalOdds.reduce((product, odd) => product * odd, 1);

        // Convert back to American odds
        if (combinedDecimal >= 2) {
            return Math.round((combinedDecimal - 1) * 100);
        } else {
            return Math.round(-100 / (combinedDecimal - 1));
        }
    };

    // Calculate confidence score for a parlay (simple version)
    const calculateParlayConfidence = (legs) => {
        if (!legs || legs.length === 0) return 0;
        
        // Average the value scores of all legs and adjust for parlay length
        const avgValue = legs.reduce((sum, leg) => sum + leg.value, 0) / legs.length;
        
        // Penalize longer parlays slightly to account for increased risk
        return avgValue * (1 - ((legs.length - 1) * 0.1));
    };
    
    // Normalize confidence to a 0-100 scale for display purposes
    const normalizeConfidence = (rawConfidence) => {
        // Based on analysis of typical confidence values:
        // - Player props with +150 odds and 1.5 efficiency could have raw confidence around 2.0-3.0
        // - Standard bets typically range from 0.5-1.5
        // - With correlation boosts, values can reach 3.0+
        
        // Define reasonable maximum expected raw confidence value
        const theoreticalMax = 3.5;
        
        // Normalize to 0-100 scale
        let normalizedValue = (rawConfidence / theoreticalMax) * 100;
        
        // Cap at 100%
        normalizedValue = Math.min(normalizedValue, 100);
        
        // Floor at 5%
        normalizedValue = Math.max(normalizedValue, 5);
        
        return normalizedValue;
    };

    // Calculate implied probability from odds
    const convertOddsToImpliedProbability = (americanOdds) => {
        if (americanOdds > 0) {
            return (100 / (americanOdds + 100)) * 100;
        } else {
            return (Math.abs(americanOdds) / (Math.abs(americanOdds) + 100)) * 100;
        }
    };

    // Calculate potential return from a $10 bet
    const calculatePotentialReturn = (odds, stake = 10) => {
        if (odds > 0) {
            return stake + (stake * (odds / 100));
        } else {
            return stake + (stake * (100 / Math.abs(odds)));
        }
    };

    // Format odds with + or - sign
    const formatOdds = (odds) => {
        if (!odds) return '';
        return odds > 0 ? `+${odds}` : `${odds}`;
    };

    // Check if odds are positive
    const isPositiveOdds = (odds) => {
        return odds > 0;
    };

    // Fetch odds data
    const fetchOdds = async () => {
        return fetchOddsForDate(date);
    };
    
    // Helper function to fetch odds with a specific date
    const fetchOddsForDate = async (specificDate) => {
        setLoading(true);
        setHasLoaded(false);
        setError(null);
        setAnalyzingOdds(true);
        setProcessingProgress(0);
        
        try {
            // Format date in YYYY-MM-DD format
            const formattedDate = specificDate || date;
            
            // Use production URL directly since the API_BASE_URL appears to be undefined
            const apiUrl = KNOWN_API_ENDPOINTS.production;
            const endpoint = '/api/odds'; // Update to use the new API endpoint path
            const response = await fetch(`${apiUrl}${endpoint}?date=${formattedDate}`, {
                credentials: 'same-origin', // Use same-origin for credentials
                headers: {
                    'Accept': 'application/json',
                }
            });
            
            if (!response.ok) {
                throw new Error(`Failed to fetch odds: ${response.status} ${response.statusText}`);
            }

            // Parse the response
            const data = await response.json();
            
            // Handle both array and object response formats
            let gamesData;
            if (Array.isArray(data)) {
                gamesData = data;
            } else if (data.data && Array.isArray(data.data)) {
                gamesData = data.data;
            } else {
                throw new Error('Unexpected API response format');
            }
            
            // Log the fetched games data
            console.log(`Fetched ${gamesData.length} games for date: ${formattedDate}`);
            
            // Filter games for the selected date
            const filterGamesByDate = (games, selectedDate) => {
                if (!games || !Array.isArray(games)) {
                    return [];
                }
                
                // Create date range in EST
                const startDate = new Date(`${selectedDate}T00:00:00-05:00`);
                const endDate = new Date(`${selectedDate}T23:59:59-05:00`);
                
                return games.filter(game => {
                    const gameDate = new Date(game.commence_time);
                    return gameDate >= startDate && gameDate <= endDate;
                });
            };
            
            const filteredGames = filterGamesByDate(gamesData, formattedDate);
            
            // Fetch player props for each game - this was missing in the refactored version
            const gamesWithProps = await Promise.all(filteredGames.map(async (game) => {
                try {
                    if (!game.id) {
                        console.warn(`Game has no ID, skipping player props fetch: ${game.home_team} vs ${game.away_team}`);
                        return game;
                    }
                    
                    console.log(`Fetching player props for game ID: ${game.id}`);
                    const propsResponse = await fetch(`${apiUrl}/api/player-props/${game.id}`, {
                        credentials: 'same-origin',
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
                    
                    if (!propsResponse.ok) {
                        console.warn(`Could not fetch player props for game ${game.id}: ${propsResponse.status}`);
                        return game;
                    }
                    
                    const propsData = await propsResponse.json();
                    
                    // Extract player props from the API response
                    // The player props are not directly in data.props but need to be extracted from bookmakers
                    // Create an array to store the extracted player props
                    let playerProps = [];
                    
                    // Process data from bookmakers
                    if (propsData.data && propsData.data.bookmakers) {
                        // Log available bookmakers
                        if (propsData.data.bookmakers && propsData.data.bookmakers.length > 0) {
                            const bookmakerNames = propsData.data.bookmakers.map(b => b.key || b.name).filter(Boolean);
                            console.log(`Available bookmakers for game ${game.id}: ${bookmakerNames.join(', ')}`);
                        }
                        
                        // Extract player props from bookmaker markets
                        propsData.data.bookmakers.forEach(bookmaker => {
                            if (bookmaker.markets) {
                                // Log bookmaker info for debugging
                                console.log(`Processing bookmaker: ${bookmaker.key || bookmaker.name} with ${bookmaker.markets.length} markets`);
                                
                                bookmaker.markets.forEach(market => {
                                    if (market.key && (
                                        market.key.includes('player_points') || 
                                        market.key.includes('player_rebounds') || 
                                        market.key.includes('player_assists') || 
                                        market.key.includes('player_threes') || 
                                        market.key.includes('player_blocks') || 
                                        market.key.includes('player_steals')
                                    )) {
                                        if (market.outcomes) {
                                            market.outcomes.forEach(outcome => {
                                                if (outcome.name && outcome.price && (outcome.point !== undefined || market.key.includes('player_match'))) {
                                                    // Determine player name and selection (Over/Under)
                                                    let playerName, selection;
                                                    
                                                    if (outcome.name.includes('Over ') || outcome.name.includes('Under ')) {
                                                        const parts = outcome.name.split(' ');
                                                        const selectionIndex = parts.findIndex(p => p === 'Over' || p === 'Under');
                                                        
                                                        if (selectionIndex > 0) {
                                                            playerName = parts.slice(0, selectionIndex).join(' ');
                                                            selection = parts[selectionIndex];
                                                        } else {
                                                            playerName = outcome.name;
                                                            selection = outcome.description || '';
                                                        }
                                                    } else if (outcome.description && (outcome.description.includes('Over') || outcome.description.includes('Under'))) {
                                                        playerName = outcome.name;
                                                        selection = outcome.description.includes('Over') ? 'Over' : 'Under';
                                                    } else {
                                                        playerName = outcome.name;
                                                        selection = outcome.description || '';
                                                    }
                                                    
                                                    // Add to player props array with bookmaker information
                                                    playerProps.push({
                                                        player_name: playerName,
                                                        prop_type: market.key,
                                                        selection: selection,
                                                        line: outcome.point,
                                                        odds: outcome.price,
                                                        team: outcome.team || '',
                                                        bookmaker: bookmaker.key || bookmaker.name || 'unknown',
                                                        source: bookmaker.key || bookmaker.name || 'unknown',
                                                        isFanduel: (bookmaker.key === 'fanduel' || bookmaker.name === 'fanduel')
                                                    });
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                    
                    // Process data from FanDuel recommendations (if available)
                    if (propsData.data && propsData.data.fanduel_recommendations) {
                        const recommendations = propsData.data.fanduel_recommendations;
                        
                        // Process best over bets
                        if (recommendations.best_over_bets && Array.isArray(recommendations.best_over_bets)) {
                            recommendations.best_over_bets.forEach(bet => {
                                if (bet.name && bet.point && bet.price && bet.market_key) {
                                    playerProps.push({
                                        player_name: bet.name,
                                        prop_type: bet.market_key,
                                        selection: 'Over',
                                        line: bet.point,
                                        odds: bet.price,
                                        team: bet.team || '',
                                        source: 'fanduel',
                                        bookmaker: 'fanduel',
                                        provider: 'fanduel',
                                        isFanduel: true
                                    });
                                }
                            });
                        }
                        
                        // Process best under bets
                        if (recommendations.best_under_bets && Array.isArray(recommendations.best_under_bets)) {
                            recommendations.best_under_bets.forEach(bet => {
                                if (bet.name && bet.point && bet.price && bet.market_key) {
                                    playerProps.push({
                                        player_name: bet.name,
                                        prop_type: bet.market_key,
                                        selection: 'Under',
                                        line: bet.point,
                                        odds: bet.price,
                                        team: bet.team || '',
                                        source: 'fanduel',
                                        bookmaker: 'fanduel',
                                        provider: 'fanduel',
                                        isFanduel: true
                                    });
                                }
                            });
                        }
                    }
                    
                    console.log(`Extracted ${playerProps.length} player props for ${game.home_team} vs ${game.away_team}`);
                    
                    // Add the player props data to the game object
                    return {
                        ...game,
                        playerProps: playerProps
                    };
                } catch (error) {
                    console.warn(`Error getting player props for ${game.home_team} vs ${game.away_team}:`, error);
                    return game;
                }
            }));
            
            // Update games state with the games that now have player props
            setGames(gamesWithProps);
            
            // Display date for UI
            setDisplayDate(formatDate(formattedDate));
            
            // Set analyzing state to show the user we're working
            setAnalyzingOdds(true);
            
            // Determine processing approach based on game count
            if (filteredGames.length > 8) {
                console.log(`Large slate detected (${filteredGames.length} games). Using optimized processing.`);
                
                // Generate limited parlay suggestions using the main thread first
                const gamesToProcess = [...gamesWithProps].sort((a, b) => 
                    new Date(a.commence_time) - new Date(b.commence_time)
                ).slice(0, 6);
                
                // First generate a small set of parlays to show immediately
                const initialParlays = generateSuggestedParlays(gamesToProcess);
                setSuggestedParlays(initialParlays);
                
                // Process all games in a non-blocking way
                setTimeout(() => {
                    try {
                        // Use MAX_GAMES parameter in generateSuggestedParlays
                        const allParlays = generateSuggestedParlays(gamesWithProps);
                        console.log(`Generated ${allParlays.length} parlays from all ${gamesWithProps.length} games`);
                        setSuggestedParlays(allParlays);
                        setAnalyzingOdds(false);
                    } catch (err) {
                        console.error("Error generating complete parlays:", err);
                        // Keep the initial parlays if there's an error
                        setAnalyzingOdds(false);
                    }
                }, 100);
            } else {
                // For smaller slates, generate parlays normally
                const parlays = generateSuggestedParlays(gamesWithProps);
                setSuggestedParlays(parlays);
                setAnalyzingOdds(false);
            }
            
            setHasLoaded(true);
            setLoading(false);
            
            return gamesWithProps;
        } catch (error) {
            console.error('Error fetching odds:', error);
            setError(error.message || 'Failed to fetch odds data');
            setGames([]);
            setSuggestedParlays([]);
            
            // Still set hasLoaded to true to prevent infinite loading state
            setHasLoaded(true);
            
            // If this is a specific error related to parlay generation, try to recover
            if (error.toString().includes('countPropLegs') || 
                error.toString().includes('Cannot access') || 
                error.toString().includes('before initialization')) {
                console.log('Recovering from parlay generation error...');
                // Set empty parlays but keep the games data if available
                setSuggestedParlays([]);
            }
        } finally {
            setLoading(false);
            setAnalyzingOdds(false);
        }
    };

    // Handle date change
    const handleDateChange = (event) => {
        const newDate = event.target.value;
        console.log("Date changed to:", newDate);
        setDate(newDate);
        setDisplayDate(formatDate(newDate));
        
        // Only fetch odds if the user is logged in
        if (user) {
            setHasLoaded(false); // Reset the loaded status to force a data refresh
            
            // Reset current data to avoid showing stale data
            setGames([]);
            setSuggestedParlays([]);
            
            // Use the new date directly in the fetch to avoid race conditions
            // with React's state updates that might not have completed yet
            fetchOddsForDate(newDate);
        }
    };

    // useEffect hook for fetching data - remove automatic fetch
    useEffect(() => {
        console.log("LayOddsTab useEffect running with date:", date);
        console.log("Current hasLoaded status:", hasLoaded);
        
        // Update display date whenever date changes
        setDisplayDate(formatDate(date));
        
        // No automatic fetching on component load
        // Data will only be fetched when user changes date or clicks refresh
    }, [date, setDisplayDate, formatDate]);

    // Function to handle manual refresh
    const handleRefresh = () => {
        console.log("Manual refresh requested");
        
        // Check rate limit before proceeding
        if (!canRefreshLays()) {
            const timeUntilNext = getTimeUntilNextRefresh();
            const minutesUntilNext = Math.ceil(timeUntilNext / (60 * 1000));
            setError(`Rate limit reached. Please wait ${minutesUntilNext} minutes before refreshing again.`);
            return;
        }
        
        // Only fetch if the user is logged in
        if (user) {
            setHasLoaded(false); // Reset the loaded status
            
            // Reset current data to avoid showing stale data
            setGames([]);
            setSuggestedParlays([]);
            
            // Use the current date directly to avoid race conditions
            fetchOddsForDate(date)
                .then(() => {
                    // Record successful refresh attempt
                    recordRefreshAttempt();
                })
                .catch(err => {
                    console.error('Error refreshing lays:', err);
                });
        }
    };

    // Render bet description
    const renderBetDescription = (bet) => {
        if (bet.betType === 'moneyline') {
            const evPercentage = calculateExpectedValue(bet.odds, bet.value, 1.0, bet.isAvoidRange);
            // Add badge for target range moneylines
            const oddsRangeBadge = bet.isWithinTargetRange ? (
                <Badge 
                    className={`odds-range-badge target`}
                    title="Target odds range"
                >
                    Target Range
                </Badge>
            ) : (bet.isAvoidRange ? (
                <Badge 
                    className={`odds-range-badge avoid`}
                    title="Avoid odds range"
                >
                    Caution
                </Badge>
            ) : null);
            
            return (
                <div className="bet-description">
                    <div>{bet.team} ML</div>
                    <div className="bet-metadata">
                        <Badge 
                            className={`ev-badge ${Number(evPercentage) > 0 ? 'positive-ev' : 'negative-ev'}`}
                            title="Estimated Edge"
                        >
                            EV: {evPercentage > 0 ? '+' : ''}{evPercentage}%
                        </Badge>
                        {oddsRangeBadge}
                    </div>
                </div>
            );
        } else if (bet.betType === 'spread') {
            const evPercentage = calculateExpectedValue(bet.odds, bet.value, 1.0, bet.isAvoidRange);
            return (
                <div className="bet-description">
                    <div>{bet.team} {bet.point > 0 ? '+' : ''}{bet.point}</div>
                    <div className="bet-metadata">
                        <Badge 
                            className={`ev-badge ${Number(evPercentage) > 0 ? 'positive-ev' : 'negative-ev'}`}
                            title="Estimated Edge"
                        >
                            EV: {evPercentage > 0 ? '+' : ''}{evPercentage}%
                        </Badge>
                    </div>
                </div>
            );
        } else if (bet.betType === 'total') {
            const evPercentage = calculateExpectedValue(bet.odds, bet.value, 1.0, bet.isAvoidRange);
            return (
                <div className="bet-description">
                    <div>{bet.selection.toUpperCase()} {bet.point}</div>
                    <div className="bet-metadata">
                        <Badge 
                            className={`ev-badge ${Number(evPercentage) > 0 ? 'positive-ev' : 'negative-ev'}`}
                            title="Estimated Edge"
                        >
                            EV: {evPercentage > 0 ? '+' : ''}{evPercentage}%
                        </Badge>
                    </div>
                </div>
            );
        } else if (bet.betType === 'player_prop') {
            // Use the statType field if available, otherwise format the propType
            const stat = bet.statType || propTypeDisplay(bet.propType);
            const evPercentage = calculateExpectedValue(bet.odds, bet.value, bet.marketEfficiency || 1.0, bet.isAvoidRange);
            
            return (
                <div className="player-prop-description">
                    <span className="prop-stat">
                        {stat}
                    </span>
                    <span className="prop-detail">
                        {bet.selection} {bet.point}
                    </span>
                    <div className="bet-metadata">
                        {bet.bookmaker && (
                            <span className="bookmaker-tag">
                                {bet.bookmaker}
                            </span>
                        )}
                        <Badge 
                            className={`ev-badge ${Number(evPercentage) > 0 ? 'positive-ev' : 'negative-ev'}`}
                            title="Estimated Edge"
                        >
                            EV: {evPercentage > 0 ? '+' : ''}{evPercentage}%
                        </Badge>
                        {getMarketEfficiencyBadge(bet)}
                    </div>
                </div>
            );
        }
        return '';
    };

    // Get confidence class
    const getConfidenceClass = (confidence) => {
        // Use normalized confidence values (0-100 scale)
        const normalizedConfidence = normalizeConfidence(confidence);
        
        if (normalizedConfidence >= 80) return 'high-confidence';
        if (normalizedConfidence >= 50) return 'medium-confidence';
        return 'low-confidence';
    };

    // Helper function to format prop type
    function propTypeDisplay(propType) {
        if (!propType) return 'Unknown';
        if (propType.includes('points') && propType.includes('rebounds') && propType.includes('assists')) return 'PRA';
        if (propType.includes('points') && propType.includes('rebounds')) return 'Points+Rebounds';
        if (propType.includes('points') && propType.includes('assists')) return 'Points+Assists';
        if (propType.includes('rebounds') && propType.includes('assists')) return 'Rebounds+Assists';
        if (propType.includes('points')) return 'Points';
        if (propType.includes('rebounds')) return 'Rebounds';
        if (propType.includes('assists')) return 'Assists';
        if (propType.includes('threes')) return '3PT Made';
        if (propType.includes('steals')) return 'Steals';
        if (propType.includes('blocks')) return 'Blocks';
        if (propType.includes('double')) return 'Double-Double';
        if (propType.includes('triple')) return 'Triple-Double';
        
        // Format the prop type from the API
        return propType
            .replace('player_', '')
            .replace('_', ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    // Add a function to calculate the explicit EV percentage
    const calculateExpectedValue = (odds, value, marketEfficiency = 1.0, isAvoidRange = false) => {
        // Convert American odds to implied probability
        const impliedProb = convertOddsToImpliedProbability(odds) / 100;
        
        // Apply a penalty factor for bets in the avoid odds range
        const avoidRangePenalty = isAvoidRange ? 0.5 : 1.0; // 50% penalty for avoid range
        
        // Base edge calculation
        let estimatedEdgePercentage;
        
        // Different edge calculations based on odds type - less conservative
        if (odds > 0) {
            // For positive odds, be less conservative with edge estimates
            estimatedEdgePercentage = (value * 85) - 100; // Increased from 75 to 85
        } else {
            // For negative odds, slightly enhanced edge calculation
            estimatedEdgePercentage = (value * 105) - 100; // Increased from 100 to 105
        }
        
        // Apply market efficiency factor and avoid range penalty
        estimatedEdgePercentage *= marketEfficiency * avoidRangePenalty;
        
        // Cap EV ranges based on market efficiency - wider ranges
        if (marketEfficiency >= 1.4) { // Highly inefficient markets (blocks, steals)
            return Math.min(Math.max(estimatedEdgePercentage, -6), 10).toFixed(1); // Widened range
        } else if (marketEfficiency >= 1.3) { // Medium-high inefficiency (rebounds, assists)
            return Math.min(Math.max(estimatedEdgePercentage, -5), 8).toFixed(1); // Widened range
        } else if (marketEfficiency >= 1.1) { // Slightly inefficient (points)
            return Math.min(Math.max(estimatedEdgePercentage, -4), 7).toFixed(1); // Widened range
        } else { // Standard markets (moneyline, spreads, totals)
            return Math.min(Math.max(estimatedEdgePercentage, -3), 6).toFixed(1); // Widened range
        }
    };

    // Add function to get badge text for market inefficiency
    const getMarketEfficiencyBadge = (bet) => {
        if (bet.betType !== 'player_prop') return null;
        
        // Display the inefficiency level based on market type
        const efficiencyFactor = bet.marketEfficiency || 1.0;
        
        if (efficiencyFactor >= 1.4) {
            return (
                <Badge className="inefficiency-badge high">
                    High Inefficiency
                </Badge>
            );
        } else if (efficiencyFactor >= 1.3) {
            return (
                <Badge className="inefficiency-badge medium-high">
                    Medium-High Inefficiency
                </Badge>
            );
        } else if (efficiencyFactor >= 1.1) {
            return (
                <Badge className="inefficiency-badge medium">
                    Medium Inefficiency
                </Badge>
            );
        }
        
        return null;
    };

    // State for UI
    const [analyzingOdds, setAnalyzingOdds] = useState(false);

    // State for worker-based processing
    const [usingWorker, setUsingWorker] = useState(false);
    const [processingProgress, setProcessingProgress] = useState(0);
    
    // Function to create a worker blob for handling parlay generation in a separate thread
    const createParlayWorker = (parlayData) => {
        // Create a blob containing the worker code
        const workerCode = `
            self.onmessage = function(e) {
                const { allBets } = e.data;
                const parlays = [];
                let progress = 0;
                const totalCombinations = (allBets.length * (allBets.length - 1)) / 2; // nC2 combinations
                let processedCombinations = 0;
                
                // Process 2-leg parlays
                for (let i = 0; i < allBets.length - 1; i++) {
                    for (let j = i + 1; j < allBets.length; j++) {
                        // Skip duplicates and same game non-correlated bets
                        const sameGameId = allBets[i].gameId === allBets[j].gameId;
                        const bothNonProps = 
                            allBets[i].betType !== 'player_prop' && 
                            allBets[j].betType !== 'player_prop';
                            
                        if (sameGameId && bothNonProps) {
                            processedCombinations++;
                            continue;
                        }
                        
                        // Create parlay object with calculated odds
                        const legs = [allBets[i], allBets[j]];
                        
                        // Calculate total odds (simplified version)
                        let totalOdds = 0;
                        if (allBets[i].odds > 0 && allBets[j].odds > 0) {
                            totalOdds = ((allBets[i].odds/100 + 1) * (allBets[j].odds/100 + 1) - 1) * 100;
                        } else if (allBets[i].odds < 0 && allBets[j].odds < 0) {
                            totalOdds = ((100/Math.abs(allBets[i].odds) + 1) * (100/Math.abs(allBets[j].odds) + 1) - 1) * 100;
                        } else {
                            // Mixed positive and negative odds
                            const odds1 = allBets[i].odds > 0 ? (allBets[i].odds/100 + 1) : (100/Math.abs(allBets[i].odds) + 1);
                            const odds2 = allBets[j].odds > 0 ? (allBets[j].odds/100 + 1) : (100/Math.abs(allBets[j].odds) + 1);
                            totalOdds = (odds1 * odds2 - 1) * 100;
                        }
                        
                        parlays.push({
                            id: \`parlay-\${i}-\${j}\`,
                            name: '2-Team Lay',
                            legs,
                            totalOdds: totalOdds
                        });
                        
                        processedCombinations++;
                        if (processedCombinations % 50 === 0) {
                            progress = Math.round((processedCombinations / totalCombinations) * 100);
                            self.postMessage({ type: 'progress', progress });
                        }
                    }
                }
                
                // Return the results
                self.postMessage({ 
                    type: 'complete', 
                    parlays: parlays.sort((a, b) => b.totalOdds - a.totalOdds).slice(0, 25)
                });
            };
        `;
        
        // Create a blob URL
        const blob = new Blob([workerCode], { type: 'application/javascript' });
        return new Worker(URL.createObjectURL(blob));
    };

    // Add helper function to format player name
    const formatPlayerName = (name) => {
        if (!name) return '';
        return name.split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ).join(' ');
    };

    // Add helper function for team abbreviations
    const teamAbbreviations = {
        'Atlanta Hawks': 'ATL',
        'Boston Celtics': 'BOS',
        'Brooklyn Nets': 'BKN',
        'Charlotte Hornets': 'CHA',
        'Chicago Bulls': 'CHI',
        'Cleveland Cavaliers': 'CLE',
        'Dallas Mavericks': 'DAL',
        'Denver Nuggets': 'DEN',
        'Detroit Pistons': 'DET',
        'Golden State Warriors': 'GSW',
        'Houston Rockets': 'HOU',
        'Indiana Pacers': 'IND',
        'Los Angeles Clippers': 'LAC',
        'Los Angeles Lakers': 'LAL',
        'Memphis Grizzlies': 'MEM',
        'Miami Heat': 'MIA',
        'Milwaukee Bucks': 'MIL',
        'Minnesota Timberwolves': 'MIN',
        'New Orleans Pelicans': 'NOP',
        'New York Knicks': 'NYK',
        'Oklahoma City Thunder': 'OKC',
        'Orlando Magic': 'ORL',
        'Philadelphia 76ers': 'PHI',
        'Phoenix Suns': 'PHX',
        'Portland Trail Blazers': 'POR',
        'Sacramento Kings': 'SAC',
        'San Antonio Spurs': 'SAS',
        'Toronto Raptors': 'TOR',
        'Utah Jazz': 'UTA',
        'Washington Wizards': 'WAS'
    };

    // Add helper function to calculate rating from edge
    const calculateRatingFromEdge = (edge) => {
        if (edge === null || edge === undefined) return 5.0;
        
        // Convert edge to a 1-10 scale with more realistic thresholds
        if (edge >= 8) return 9.5;         // Excellent value
        else if (edge >= 6) return 8.5;    // Very good value
        else if (edge >= 4) return 7.5;    // Good value
        else if (edge >= 2) return 6.5;    // Above average value
        else if (edge >= 1) return 5.5;    // Slight value
        else if (edge >= 0) return 5.0;    // Minimal value
        else return 4.0;                   // No clear value
    };

    const getValueBadge = (edge) => {
        if (!edge) return '';
        if (edge < 0) return ' ⚠️';  // Warning emoji for negative edge
        if (edge >= 8) return ' 🔥';  // Fire emoji for excellent value
        if (edge >= 4) return ' ⭐';   // Star emoji for good value
        return '';
    };

    // Process player props for a game
    const processGameProps = (game) => {
        if (!game || !game.playerProps || !Array.isArray(game.playerProps)) {
            return [];
        }

        // Log what props we're starting with
        console.log('Processing player props for game:', game.home_team, 'vs', game.away_team);
        console.log('Raw player props sample:', game.playerProps.slice(0, 3));
        
        // Count bookmakers for debugging
        const allBookmakers = game.playerProps
            .map(prop => prop.bookmaker || prop.source)
            .filter(Boolean);
        const uniqueBookmakers = [...new Set(allBookmakers)];
        console.log(`Found props from ${uniqueBookmakers.length} bookmakers:`, uniqueBookmakers.join(', '));

        // First, organize props by player + prop type + line + selection to find market consensus
        const propsByKey = {};

        // Step 1: Group props by their unique key to compare across sportsbooks
        game.playerProps.forEach(prop => {
            let displayPropType = prop.prop_type || prop.market_name || 'Points';
            displayPropType = propTypeDisplay(displayPropType);
            
            const playerName = prop.playerName || prop.player_name;
            const point = prop.point || prop.line || 0;
            const selection = prop.selection || 'Over';
            
            // Create a unique key for grouping identical props across sportsbooks
            const propKey = `${playerName.toLowerCase()}_${displayPropType.toLowerCase()}_${point}_${selection.toLowerCase()}`;
            
            // Determine bookmaker
            let bookmaker = 'unknown';
            let isFanduel = false;
            
            if (prop.isFanduel) {
                isFanduel = true;
                bookmaker = 'fanduel';
            } else if (prop.bookmaker) {
                bookmaker = prop.bookmaker.toLowerCase();
                isFanduel = bookmaker === 'fanduel';
            } else if (prop.source) {
                bookmaker = prop.source.toLowerCase();
                isFanduel = bookmaker === 'fanduel';
            }
            
            // Initialize this prop group if it doesn't exist
            if (!propsByKey[propKey]) {
                propsByKey[propKey] = {
                    player_name: playerName,
                    market_name: displayPropType,
                    point: point,
                    selection: selection,
                    bookmakers: [],
                    odds: {}
                };
            }
            
            // Add this bookmaker's odds
            const odds = prop.price || prop.odds || -110;
            propsByKey[propKey].bookmakers.push(bookmaker);
            propsByKey[propKey].odds[bookmaker] = odds;
        });

        // Step 2: Process each prop group and calculate market comparison
        const processedProps = [];
        
        Object.values(propsByKey).forEach(propGroup => {
            // Calculate market efficiency based on prop type with more conservative multipliers
            const marketEfficiency = 
                propGroup.market_name.toLowerCase().includes('point') ? 1.05 :
                propGroup.market_name.toLowerCase().includes('rebound') ? 1.1 :
                propGroup.market_name.toLowerCase().includes('assist') ? 1.1 :
                propGroup.market_name.toLowerCase().includes('three') ? 1.15 :
                propGroup.market_name.toLowerCase().includes('block') ? 1.2 :
                propGroup.market_name.toLowerCase().includes('steal') ? 1.2 : 1.1;
            
            // Get FanDuel odds if available, otherwise use first available odds
            let odds = propGroup.odds['fanduel'] || Object.values(propGroup.odds)[0];
            
            let baseValue = calculateBetValue(odds);
            let marketComparisonBoost = 1.0;
            
            // If we have multiple bookmakers, calculate market comparison
            const uniqueBookmakers = [...new Set(propGroup.bookmakers)];
            const isMultiBook = uniqueBookmakers.length > 1;
            
            if (isMultiBook) {
                // Calculate average odds across all bookmakers
                let allOddsValues = Object.values(propGroup.odds);
                let avgOdds = allOddsValues.reduce((sum, odd) => sum + Number(odd), 0) / allOddsValues.length;
                
                // If FanDuel odds are better than average, boost the edge (with moderated boost values)
                if (propGroup.odds['fanduel'] && propGroup.odds['fanduel'] > avgOdds) {
                    // Calculate how much better FanDuel odds are
                    const fdOdds = propGroup.odds['fanduel'];
                    
                    // For American odds, higher positive or less negative is better
                    const oddsAdvantage = fdOdds > 0 ? 
                        (fdOdds - avgOdds) / 100 : // Positive odds
                        (Math.abs(avgOdds) - Math.abs(fdOdds)) / 100; // Negative odds
                    
                    // Apply boost based on advantage size (with moderated maximum boost)
                    if (oddsAdvantage > 0.2) marketComparisonBoost = 1.25; // Major advantage
                    else if (oddsAdvantage > 0.1) marketComparisonBoost = 1.15; // Significant advantage
                    else if (oddsAdvantage > 0.05) marketComparisonBoost = 1.1; // Moderate advantage
                    else if (oddsAdvantage > 0) marketComparisonBoost = 1.05; // Slight advantage
                }
            }
            
            // Calculate final edge with market comparison boost
            const calculatedEdge = (baseValue * marketEfficiency * marketComparisonBoost).toFixed(1);
            
            // Calculate rating from edge
            const rating = calculateRatingFromEdge(parseFloat(calculatedEdge));
            
            // Prepare the processed prop
            processedProps.push({
                player_name: propGroup.player_name,
                market_name: propGroup.market_name,
                point: propGroup.point,
                selection: propGroup.selection,
                odds: odds,
                edge: calculatedEdge,
                rating: rating.toFixed(1),
                isFanduel: true, // Since we're showing FanDuel odds
                bookmakers: uniqueBookmakers.join(', '),
                bookmakerCount: uniqueBookmakers.length,
                hasMarketEdge: marketComparisonBoost > 1,
                isMultiBook: isMultiBook
            });
        });

        // Log processed props
        console.log('Processed props count:', processedProps.length);
        console.log('Props with market comparison boost:', processedProps.filter(p => p.hasMarketEdge).length);
        
        // Sort by edge value in descending order
        processedProps.sort((a, b) => parseFloat(b.edge) - parseFloat(a.edge));

        // Return top 10 props
        return processedProps.slice(0, 10);
    };

    // Check if game is live based on commence time
    const isGameLive = (gameTime) => {
        const now = new Date();
        const gameStart = new Date(gameTime);
        
        // Game has started but probably not finished yet (assuming games last around 2.5 hours)
        const gameHasStarted = gameStart < now;
        const gameEndEstimate = new Date(gameStart);
        gameEndEstimate.setHours(gameEndEstimate.getHours() + 2.5);
        const gameHasEnded = gameEndEstimate < now;
        
        return gameHasStarted && !gameHasEnded;
    };

    // Check if game is in the future (scheduled) or in the past (finished)
    const isGameScheduled = (gameTime) => {
        const now = new Date();
        const gameStart = new Date(gameTime);
        return gameStart > now;
    };

    const isGameFinished = (gameTime) => {
        const now = new Date();
        const gameStart = new Date(gameTime);
        const gameEndEstimate = new Date(gameStart);
        gameEndEstimate.setHours(gameEndEstimate.getHours() + 2.5);
        return gameEndEstimate < now;
    };

    // Filter player props based on current filter settings
    const filterPlayerProps = (props, game) => {
        if (!props || !Array.isArray(props)) return [];
        
        return props.filter(prop => {
            // Filter by prop type
            if (propFilters.propType !== 'all') {
                const propTypeLower = prop.market_name.toLowerCase();
                const propTypeToCheck = propFilters.propType.toLowerCase();
                
                // Special handling for 3-pointers
                if (propTypeToCheck === '3-pointers' || propTypeToCheck === 'threes') {
                    if (!propTypeLower.includes('3pt') && 
                        !propTypeLower.includes('three') && 
                        !propTypeLower.includes('3-point')) {
                        return false;
                    }
                } else if (!propTypeLower.includes(propTypeToCheck)) {
                    return false;
                }
            }
            
            // Filter by minimum edge
            if (parseFloat(prop.edge) < propFilters.minEdge) {
                return false;
            }
            
            // Filter by minimum rating
            const rating = calculateRatingFromEdge(parseFloat(prop.edge));
            if (rating < propFilters.minRating) {
                return false;
            }
            
            // Filter by minimum implied probability
            const impliedProbability = convertOddsToImpliedProbability(prop.odds);
            if (impliedProbability < propFilters.minImpliedProbability) {
                return false;
            }
            
            // Filter by game status (live/scheduled)
            if (propFilters.gameStatus !== 'all') {
                const gameIsLive = isGameLive(game.commence_time);
                const gameIsScheduled = isGameScheduled(game.commence_time);
                const gameIsFinished = isGameFinished(game.commence_time);
                
                if (propFilters.gameStatus === 'live' && !gameIsLive) {
                    return false;
                } else if (propFilters.gameStatus === 'scheduled' && (!gameIsScheduled || gameIsLive || gameIsFinished)) {
                    return false;
                }
            }
            
            // Filter by multi-bookmaker props (M tag)
            if (propFilters.multiBookOnly && !prop.isMultiBook) {
                return false;
            }
            
            return true;
        });
    };

    // Handle filter changes
    const handleFilterChange = (filterName, value) => {
        setPropFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };

    // Add rate limiting state and constants
    const REFRESH_LIMIT = 10; // Maximum refreshes per hour for non-admin users
    const REFRESH_WINDOW = 60 * 60 * 1000; // 1 hour in milliseconds

    // Function to check if user can refresh lays
    const canRefreshLays = () => {
        if (isAdmin) return true; // Admins have unlimited refreshes

        const refreshHistory = JSON.parse(localStorage.getItem('layRefreshHistory') || '[]');
        const now = Date.now();
        
        // Filter out old refresh attempts
        const recentRefreshes = refreshHistory.filter(time => now - time < REFRESH_WINDOW);
        
        // Update localStorage with filtered history
        localStorage.setItem('layRefreshHistory', JSON.stringify(recentRefreshes));
        
        // Check if user has exceeded the limit
        return recentRefreshes.length < REFRESH_LIMIT;
    };

    // Function to record a refresh attempt
    const recordRefreshAttempt = () => {
        if (isAdmin) return; // Don't record admin refreshes
        
        const refreshHistory = JSON.parse(localStorage.getItem('layRefreshHistory') || '[]');
        refreshHistory.push(Date.now());
        localStorage.setItem('layRefreshHistory', JSON.stringify(refreshHistory));
    };

    // Function to get remaining refreshes
    const getRemainingRefreshes = () => {
        if (isAdmin) return '∞'; // Admins have unlimited refreshes

        const refreshHistory = JSON.parse(localStorage.getItem('layRefreshHistory') || '[]');
        const now = Date.now();
        const recentRefreshes = refreshHistory.filter(time => now - time < REFRESH_WINDOW);
        return Math.max(0, REFRESH_LIMIT - recentRefreshes.length);
    };

    // Function to get time until next refresh is available
    const getTimeUntilNextRefresh = () => {
        if (isAdmin) return 0; // Admins don't need to wait

        const refreshHistory = JSON.parse(localStorage.getItem('layRefreshHistory') || '[]');
        if (refreshHistory.length === 0) return 0;

        const oldestRefresh = Math.min(...refreshHistory);
        const timeElapsed = Date.now() - oldestRefresh;
        return Math.max(0, REFRESH_WINDOW - timeElapsed);
    };

    // Add refresh limit display to the header
    const renderRefreshLimitInfo = () => {
        if (isAdmin) return null; // Don't show limit info for admins
        
        const remaining = getRemainingRefreshes();
        const timeUntilNext = getTimeUntilNextRefresh();
        
        if (timeUntilNext > 0) {
            const minutesUntilNext = Math.ceil(timeUntilNext / (60 * 1000));
            return (
                <div className="api-info warning">
                    <i className="fas fa-clock me-2"></i>
                    {remaining} refreshes remaining. Next refresh in {minutesUntilNext} minutes
                </div>
            );
        }
        
        return (
            <div className="api-info">
                <i className="fas fa-sync me-2"></i>
                {remaining} refreshes remaining this hour
            </div>
        );
    };

    // Add a render content function to handle the complex conditional rendering
    const renderContent = () => {
        if (error) {
    return (
                <Alert variant="danger" className="mt-4">
                    <div className="d-flex align-items-center">
                        <strong>Error loading odds data.</strong>
                        <span className="ms-3">{error}</span>
                </div>
                </Alert>
            );
        }
        
        if (loading) {
            return (
                <div className="loading-container">
                    <Spinner animation="border" variant="primary" />
                    <div>Loading odds data...</div>
                </div>
            );
        }
        
        if (activeSubtab === 'main') {
            // Main tab content
            return (
                suggestedParlays.length > 0 ? (
                <>
                    <div className="parlays-stats">
                        <Card className="stats-card">
                            <Card.Body>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <div className="stat-item">
                                            <div className="stat-value">{parlayStats.totalValue}</div>
                                            <div className="stat-label">Average Value Score</div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <div className="stat-item">
                                            <div className="stat-value">${parlayStats.potentialReturn}</div>
                                            <div className="stat-label">Average Potential Return</div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <div className="stat-item">
                                            <div className="stat-value">{parlayStats.oddsImpliedProbability}%</div>
                                            <div className="stat-label">Average Implied Probability</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                    
                    <div className="parlays-grid">
                        {suggestedParlays.map((parlay, index) => (
                            <Card key={parlay.id} className="parlay-card">
                                <Card.Header>
                                    <div className="parlay-header">
                                        <div className="parlay-title">
                                            <BsTrophy className={`trophy-icon ${index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'}`} />
                                            <h3>{parlay.name}</h3>
                                            {parlay.isCorrelated && (
                                                <div className="correlated-indicator" title="Contains correlated bets (same-game)">
                                                    <BsInfoCircle className="correlated-icon" />
                                                    <span>Correlated</span>
                                                </div>
                                            )}
                                        </div>
                                        <Badge 
                                            className={`confidence-badge ${getConfidenceClass(parlay.confidence)} ${parlay.isEmergency ? 'emergency-fallback' : ''}`}
                                        >
                                            <span className="confidence-stars">
                                                {parlay.isEmergency 
                                                    ? '⚠️' 
                                                    : Array(Math.ceil(parlay.normalizedConfidence / 20)).fill('★').join('')
                                                }
                                            </span>
                                            {parlay.isEmergency ? 'Emergency Lay' : `${parlay.normalizedConfidence.toFixed(0)}% Confidence`}
                                        </Badge>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="table-responsive">
                                    <Table className="parlay-legs-table">
                                        <thead>
                                            <tr>
                                                <th>Game</th>
                                                <th>Bet</th>
                                                <th>Odds</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {parlay.legs.map((leg, i) => (
                                                <tr 
                                                    key={`${parlay.id}-leg-${i}`}
                                                    className={leg.betType === 'player_prop' ? 'player-prop-row' : ''}
                                                >
                                                    <td>
                                                        {leg.betType === 'player_prop' ? (
                                                            <div className="game-info">
                                                                <div className="player-name">{leg.playerName}</div>
                                                                <div className="prop-team">
                                                                    {leg.homeTeam} vs {leg.awayTeam}
                                                                </div>
                                                                <div className="game-time">{formatGameTime(leg.gameTime)}</div>
                                                                {leg.bookmaker && (
                                                                    <div className="bookmaker-info">
                                                                        <span className="bookmaker-badge">{leg.bookmaker}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className="game-info">
                                                                <div>{leg.homeTeam} vs {leg.awayTeam}</div>
                                                                <div className="game-time">{formatGameTime(leg.gameTime)}</div>
                                                                {leg.bookmaker && (
                                                                    <div className="bookmaker-info">
                                                                        <span className="bookmaker-badge">{leg.bookmaker}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>{renderBetDescription(leg)}</td>
                                                    <td 
                                                        className="odds-cell"
                                                        data-positive={isPositiveOdds(leg.odds)}
                                                        data-negative={!isPositiveOdds(leg.odds)}
                                                    >
                                                        {formatOdds(leg.odds)}
                                                        {leg.betType === 'moneyline' && leg.holdPercentage < 0.05 && (
                                                            <span className="low-hold-badge" title="Low hold percentage (good value)">🔥</span>
                                                        )}
                                                        <div className="ev-indicator">
                                                            <span className={`ev-badge ${leg.evValue > 0 ? 'positive-ev' : 'negative-ev'}`} title="Expected Value">
                                                                {leg.evValue > 0 ? '+' : ''}{leg.evValue}%
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    </div>
                                    <div className="parlay-summary">
                                        <div className="parlay-odds">
                                            <span className="label">Parlay Odds:</span>
                                            <span 
                                                className="value"
                                                data-positive={isPositiveOdds(parlay.totalOdds)}
                                                data-negative={!isPositiveOdds(parlay.totalOdds)}
                                            >
                                                {formatOdds(parlay.totalOdds)}
                                            </span>
                                        </div>
                                        <div className="parlay-return">
                                            <span className="label">$10 Returns:</span>
                                            <span className="value">${calculatePotentialReturn(parlay.totalOdds, 10).toFixed(2)}</span>
                                        </div>
                                        <div className="parlay-hold">
                                            <span className="label">Combined Hold:</span>
                                            <span className={`value ${parlay.combinedHold <= 0.08 ? 'good-hold' : 'high-hold'}`}>
                                                {(parlay.combinedHold * 100).toFixed(1)}%
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="parlay-metrics">
                                        <div className="ev-summary">
                                            <span className="label">Average EV per Leg:</span>
                                            <span className="value">{parlay.averageEV}%</span>
                                        </div>
                                        {parlay.isCorrelated && (
                                            <div className="correlation-strength">
                                                <span className="label">Correlation:</span>
                                                <span className="value">{parlay.correlationStrength}</span>
                                            </div>
                                        )}
                                        <div className="market-mix">
                                            <span className="label">Bet Mix:</span>
                                            <span className="value">
                                                {parlay.propCount} Props / {parlay.standardCount} ML/Spreads
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                        
                        <div className="disclaimer">
                            <div className="disclaimer-icon">
                                <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div>
                                <p>
                                    <strong>Disclaimer:</strong> The parlay and selection suggestions shown here are based on algorithmic analysis 
                                    and historical data. They are provided for informational purposes only and should not be considered as 
                                    financial advice. Past performance is not indicative of future results. Users must be 21+ years of age 
                                    and adhere to their local laws and regulations regarding sports wagering.
                                </p>
                            </div>
                    </div>
                </>
            ) : (
                <Alert variant="info">
                        <p>No suggested parlays available for the selected date.</p>
                        <p>Try selecting a different date with more games, or check back closer to game time when more odds are available.</p>
                    </Alert>
                )
            );
        } 
        
        if (activeSubtab === 'playerProps') {
            // Player props tab content
            return (
                <div className="player-props-content">
                    <h3 className="best-bets-heading">FanDuel Best Player Props</h3>
                    
                    {/* Player Props Filters */}
                    <div className="player-props-filters dark-theme">
                        <Form>
                            <Row className="align-items-center mb-3">
                                <Col xs={12} md={2}>
                                    <Form.Group>
                                        <Form.Label>Prop Type</Form.Label>
                                        <Form.Select 
                                            value={propFilters.propType}
                                            onChange={(e) => handleFilterChange('propType', e.target.value)}
                                            className="dark-select"
                                        >
                                            <option value="all">All Props</option>
                                            <option value="points">Points</option>
                                            <option value="rebounds">Rebounds</option>
                                            <option value="assists">Assists</option>
                                            <option value="3-pointers">3-Pointers</option>
                                            <option value="blocks">Blocks</option>
                                            <option value="steals">Steals</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group>
                                        <Form.Label>Min Edge (%)</Form.Label>
                                        <Form.Select 
                                            value={propFilters.minEdge}
                                            onChange={(e) => handleFilterChange('minEdge', parseFloat(e.target.value))}
                                            className="dark-select"
                                        >
                                            <option value="0">Any</option>
                                            <option value="1">1%+</option>
                                            <option value="1.5">1.5%+</option>
                                            <option value="2">2%+</option>
                                            <option value="3">3%+</option>
                                            <option value="4">4%+</option>
                                            <option value="5">5%+</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group>
                                        <Form.Label>Min Rating</Form.Label>
                                        <Form.Select 
                                            value={propFilters.minRating}
                                            onChange={(e) => handleFilterChange('minRating', parseFloat(e.target.value))}
                                            className="dark-select"
                                        >
                                            <option value="0">Any</option>
                                            <option value="5">5.0+</option>
                                            <option value="5.5">5.5+</option>
                                            <option value="6">6.0+</option>
                                            <option value="6.5">6.5+</option>
                                            <option value="7">7.0+</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group>
                                        <Form.Label>Min Probability (%)</Form.Label>
                                        <Form.Select 
                                            value={propFilters.minImpliedProbability}
                                            onChange={(e) => handleFilterChange('minImpliedProbability', parseFloat(e.target.value))}
                                            className="dark-select"
                                        >
                                            <option value="0">Any</option>
                                            <option value="50">50%+</option>
                                            <option value="52">52%+</option>
                                            <option value="55">55%+</option>
                                            <option value="58">58%+</option>
                                            <option value="60">60%+</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2}>
                                    <Form.Group>
                                        <Form.Label>Game Status</Form.Label>
                                        <Form.Select 
                                            value={propFilters.gameStatus}
                                            onChange={(e) => handleFilterChange('gameStatus', e.target.value)}
                                            className="dark-select"
                                        >
                                            <option value="all">All Games</option>
                                            <option value="live">Live Games</option>
                                            <option value="scheduled">Scheduled Games</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={2} className="d-flex align-items-end">
                                    <Button 
                                        variant="outline-secondary" 
                                        className="w-100 dark-button"
                                        onClick={() => setPropFilters({
                                            propType: 'all',
                                            minEdge: 0,
                                            minRating: 0,
                                            minImpliedProbability: 0,
                                            gameStatus: 'all',
                                            multiBookOnly: false
                                        })}
                                    >
                                        Reset Filters
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="mb-2">
                                    <Form.Check 
                                        type="checkbox"
                                        id="multiBookFilter"
                                        label="Show only multi-bookmaker props (M tag)"
                                        checked={propFilters.multiBookOnly}
                                        onChange={(e) => handleFilterChange('multiBookOnly', e.target.checked)}
                                        className="dark-checkbox"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    
                    {/* Filter Summary */}
                    {(propFilters.propType !== 'all' || 
                      propFilters.minEdge > 0 || 
                      propFilters.minRating > 0 || 
                      propFilters.minImpliedProbability > 0 || 
                      propFilters.gameStatus !== 'all' ||
                      propFilters.multiBookOnly) && (
                        <div className="filter-summary dark-theme">
                            <span className="filter-summary-label">Active Filters:</span>
                            {propFilters.propType !== 'all' && (
                                <Badge bg="primary" className="filter-badge">
                                    Prop: {propFilters.propType}
                                </Badge>
                            )}
                            {propFilters.minEdge > 0 && (
                                <Badge bg="primary" className="filter-badge">
                                    Edge: {propFilters.minEdge}%+
                                </Badge>
                            )}
                            {propFilters.minRating > 0 && (
                                <Badge bg="primary" className="filter-badge">
                                    Rating: {propFilters.minRating}+
                                </Badge>
                            )}
                            {propFilters.minImpliedProbability > 0 && (
                                <Badge bg="primary" className="filter-badge">
                                    Probability: {propFilters.minImpliedProbability}%+
                                </Badge>
                            )}
                            {propFilters.gameStatus !== 'all' && (
                                <Badge bg="primary" className="filter-badge">
                                    Status: {propFilters.gameStatus}
                                </Badge>
                            )}
                            {propFilters.multiBookOnly && (
                                <Badge bg="primary" className="filter-badge">
                                    Multi-bookmaker only
                                </Badge>
                            )}
                            
                            <div className="filter-results-count">
                                {(() => {
                                    // Calculate total filtered props
                                    const totalFilteredProps = games.reduce((total, game) => {
                                        const gameProps = processGameProps(game);
                                        const filteredProps = filterPlayerProps(gameProps, game);
                                        return total + filteredProps.length;
                                    }, 0);
                                    
                                    return (
                                        <Badge bg="secondary">
                                            {totalFilteredProps} props found
                                        </Badge>
                                    );
                                })()}
                            </div>
                        </div>
                    )}
                    
                    {games.some(game => {
                        const gameProps = processGameProps(game);
                        const filteredProps = filterPlayerProps(gameProps, game);
                        return filteredProps.length > 0;
                    }) ? (
                        <>
                            {games.map(game => {
                                const gameProps = processGameProps(game);
                                if (gameProps.length === 0) return null;

                                // Apply filters to the processed props
                                const filteredProps = filterPlayerProps(gameProps, game);
                                if (filteredProps.length === 0) return null;

                                return (
                                    <div key={`props-${game.id}`} className="best-props-game-row mb-3">
                                        <div className="best-props-game-header">
                                            <div className="game-matchup">
                                                <strong>
                                                    {teamAbbreviations[game.home_team] || game.home_team} vs {teamAbbreviations[game.away_team] || game.away_team}
                                                </strong>
                                                <span className="game-time">
                                                    {isGameLive(game.commence_time) ? (
                                                        <span className="live-badge">LIVE</span>
                                                    ) : (
                                                        new Date(game.commence_time).toLocaleTimeString('en-US', {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            timeZone: 'America/New_York'
                                                        }) + ' ET'
                                                    )}
                                                </span>
                                            </div>
                                            <div className="view-all-props">
                                                <Button 
                                                    variant="outline-primary" 
                                                    size="sm"
                                                    onClick={() => handleRefresh()}
                                                    disabled={loading || (!isAdmin && !canRefreshLays())}
                                                >
                                                    {!isAdmin && !canRefreshLays() ? (
                                                        `Limit: ${getRemainingRefreshes()} left`
                                                    ) : (
                                                        "Refresh Props"
                                                    )}
                        </Button>
                    </div>
                                        </div>
                                        <table className="best-props-table">
                                            <thead>
                                                <tr>
                                                    <th>Selection</th>
                                                    <th>Prop</th>
                                                    <th>Line</th>
                                                    <th>Player</th>
                                                    <th>Odds</th>
                                                    <th>Edge</th>
                                                    <th>Rating</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredProps.map((prop, index) => (
                                                    <tr key={`${game.id}-${index}`} className="prop-row">
                                                        <td className="player-name">
                                                            {prop.selection}
                                                        </td>
                                                        <td className="prop-type">
                                                            {prop.market_name}
                                                        </td>
                                                        <td className="prop-line">
                                                            {typeof prop.point === 'number' ? prop.point.toFixed(1) : prop.point}
                                                        </td>
                                                        <td className="prop-selection">
                                                            <span className={prop.selection === 'Over' ? 'over-selection' : 'under-selection'}>
                                                                {formatPlayerName(prop.player_name)}
                                                                <span className="bookmaker-badge fanduel-badge">FD</span>
                                                            </span>
                                                            {prop.bookmakerCount > 1 && (
                                                                <div className="bookmaker-count" title={`Available at ${prop.bookmakerCount} sportsbooks`}>
                                                                    <small>{prop.bookmakerCount} books</small>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="prop-odds">
                                                            <span className={prop.odds > 0 ? 'positive-odds' : 'negative-odds'}>
                                                                {formatOdds(prop.odds)}
                                                            </span>
                                                            {prop.hasMarketEdge && (
                                                                <span className="market-edge-icon" title="Better odds than market average">📈</span>
                                                            )}
                                                        </td>
                                                        <td className="prop-edge">
                                                            {prop.edge}%
                                                            {prop.hasMarketEdge && (
                                                                <span className="edge-badge market-edge">M</span>
                                                            )}
                                                        </td>
                                                        <td className={`prop-value ${
                                                            parseFloat(prop.edge) >= 8 ? 'high-value' : 
                                                            parseFloat(prop.edge) >= 4 ? 'medium-value' : 
                                                            'low-value'}`}>
                                                            {calculateRatingFromEdge(parseFloat(prop.edge)).toFixed(1)}
                                                            {getValueBadge(parseFloat(prop.edge))}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                );
                            })}
            
            <div className="disclaimer">
                            <div className="disclaimer-icon">
                                <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div>
                                <p>
                                    <strong>Disclaimer:</strong> The player props shown here are derived from current market odds and 
                                    our proprietary edge calculation algorithm. All ratings and recommendations are for informational 
                                    purposes only. Actual outcomes may vary based on player performance, injuries, and other unforeseen 
                                    factors. Users should be aware of the inherent uncertainties in sports predictions.
                </p>
            </div>
                        </div>
                    </>
                ) : (
                    <Alert variant="info">
                        No player props available for the selected date. Props typically become available a few hours before game time.
                    </Alert>
                )}
            </div>
            );
        }
        
        if (activeSubtab === 'builder') {
            // Lay Builder tab
            return games && games.length > 0 ? (
                <LayBuilder gameData={games} />
            ) : (
                <Alert variant="info">
                    No betting options available for this date. Please try another date.
                </Alert>
            );
        }
        
        return null;
    };

    // Update the return statement to use the render content function
    return (
        <Container fluid className="lay-odds-container">
            {/* Header section */}
            <div className="lay-odds-header">
                <div className="header-left">
                    <h2 className="mb-2">Lay the Odds</h2>
                    <p className="header-description">
                        Optimal lays based on value analysis
                    </p>
                </div>
                <div className="header-right">
                    <div className="controls-group">
                        <div className="date-picker">
                            <input
                                type="date"
                                className="date-input"
                                value={date}
                                onChange={handleDateChange}
                            />
                        </div>
                        {renderRefreshLimitInfo()}
                        <Button
                            className="refresh-button"
                            onClick={handleRefresh}
                            disabled={loading || (!isAdmin && !canRefreshLays())}
                        >
                            <div className="refresh-button-content">
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <>
                                        <BsArrowClockwise className="refresh-icon" />
                                        Refresh Lays
                                    </>
                                )}
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
            
            {/* Tabs navigation */}
            <Nav variant="tabs" className="mb-3 lay-odds-subtabs">
                <Nav.Item>
                    <Nav.Link 
                        active={activeSubtab === 'main'}
                        onClick={() => setActiveSubtab('main')}
                    >
                        Main
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link 
                        active={activeSubtab === 'playerProps'}
                        onClick={() => setActiveSubtab('playerProps')}
                    >
                        Player Props
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link 
                        active={activeSubtab === 'builder'}
                        onClick={() => setActiveSubtab('builder')}
                    >
                        Lay Builder
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            
            {/* Content area */}
            {renderContent()}
        </Container>
    );
};

export default LayOddsTab; 
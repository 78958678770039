import React, { useMemo } from 'react';
import { 
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';
import { Table, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { BsDash, BsArrowUp, BsArrowDown } from 'react-icons/bs';
import './PlayerPropsModal.css';

const PropsTable = ({ data, marketTitle, formatOdds, formatBookmaker }) => {
  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: 'Player',
        accessorKey: 'name',
        cell: ({ getValue }) => <div className="player-name">{getValue()}</div>,
      },
      {
        id: 'line',
        header: 'Line',
        accessorKey: 'line',
        cell: ({ getValue }) => <div className="line-value">{getValue()}</div>,
      },
      {
        id: 'pinnacleOver',
        header: 'Pinnacle Over',
        accessorFn: (row) => row.pinnacle.over,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <BsDash />;
          
          const className = value.price > 0 ? 'positive-odds' : 'negative-odds';
          
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value.edge ? `${value.edge.toFixed(1)}% edge` : 'No edge data'}
                </Tooltip>
              }
            >
              <span className={className}>
                {formatOdds(value.price)}
                {value.edge > 0 && (
                  <Badge 
                    className={`ev-badge ${value.edge > 5 ? 'high' : value.edge > 2 ? 'medium' : 'low'}`} 
                    style={{marginLeft: '4px'}}
                  >
                    EV+
                  </Badge>
                )}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: 'pinnacleUnder',
        header: 'Pinnacle Under',
        accessorFn: (row) => row.pinnacle.under,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <BsDash />;
          
          const className = value.price > 0 ? 'positive-odds' : 'negative-odds';
          
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value.edge ? `${value.edge.toFixed(1)}% edge` : 'No edge data'}
                </Tooltip>
              }
            >
              <span className={className}>
                {formatOdds(value.price)}
                {value.edge > 0 && (
                  <Badge 
                    className={`ev-badge ${value.edge > 5 ? 'high' : value.edge > 2 ? 'medium' : 'low'}`} 
                    style={{marginLeft: '4px'}}
                  >
                    EV+
                  </Badge>
                )}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: 'fanduelOver',
        header: 'FanDuel Over',
        accessorFn: (row) => row.fanduel.over,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <BsDash />;
          
          const className = value.price > 0 ? 'positive-odds' : 'negative-odds';
          
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value.edge ? `${value.edge.toFixed(1)}% edge` : 'No edge data'}
                </Tooltip>
              }
            >
              <span className={className}>
                {formatOdds(value.price)}
                {value.edge > 0 && (
                  <Badge 
                    className={`ev-badge ${value.edge > 5 ? 'high' : value.edge > 2 ? 'medium' : 'low'}`} 
                    style={{marginLeft: '4px'}}
                  >
                    EV+
                  </Badge>
                )}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: 'fanduelUnder',
        header: 'FanDuel Under',
        accessorFn: (row) => row.fanduel.under,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <BsDash />;
          
          const className = value.price > 0 ? 'positive-odds' : 'negative-odds';
          
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value.edge ? `${value.edge.toFixed(1)}% edge` : 'No edge data'}
                </Tooltip>
              }
            >
              <span className={className}>
                {formatOdds(value.price)}
                {value.edge > 0 && (
                  <Badge 
                    className={`ev-badge ${value.edge > 5 ? 'high' : value.edge > 2 ? 'medium' : 'low'}`} 
                    style={{marginLeft: '4px'}}
                  >
                    EV+
                  </Badge>
                )}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: 'draftkingsOver',
        header: 'DraftKings Over',
        accessorFn: (row) => row.draftkings.over,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <BsDash />;
          
          const className = value.price > 0 ? 'positive-odds' : 'negative-odds';
          
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value.edge ? `${value.edge.toFixed(1)}% edge` : 'No edge data'}
                </Tooltip>
              }
            >
              <span className={className}>
                {formatOdds(value.price)}
                {value.edge > 0 && (
                  <Badge 
                    className={`ev-badge ${value.edge > 5 ? 'high' : value.edge > 2 ? 'medium' : 'low'}`} 
                    style={{marginLeft: '4px'}}
                  >
                    EV+
                  </Badge>
                )}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: 'draftkingsUnder',
        header: 'DraftKings Under',
        accessorFn: (row) => row.draftkings.under,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <BsDash />;
          
          const className = value.price > 0 ? 'positive-odds' : 'negative-odds';
          
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value.edge ? `${value.edge.toFixed(1)}% edge` : 'No edge data'}
                </Tooltip>
              }
            >
              <span className={className}>
                {formatOdds(value.price)}
                {value.edge > 0 && (
                  <Badge 
                    className={`ev-badge ${value.edge > 5 ? 'high' : value.edge > 2 ? 'medium' : 'low'}`} 
                    style={{marginLeft: '4px'}}
                  >
                    EV+
                  </Badge>
                )}
              </span>
            </OverlayTrigger>
          );
        },
      },
      {
        id: 'movement',
        header: 'Movement',
        accessorKey: 'movement',
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <BsDash />;
          
          const lineMovement = value.line_movement;
          const oddsMovement = value.odds_movement;
          
          return (
            <div className="movement-container">
              {lineMovement !== 0 && (
                <span className={`movement-indicator ${lineMovement > 0 ? 'text-success' : 'text-danger'}`}>
                  {lineMovement > 0 ? <BsArrowUp /> : <BsArrowDown />}
                  {Math.abs(lineMovement).toFixed(1)}
                </span>
              )}
              {oddsMovement !== 0 && (
                <span className={`movement-indicator ${oddsMovement > 0 ? 'text-success' : 'text-danger'}`}>
                  {oddsMovement > 0 ? <BsArrowUp /> : <BsArrowDown />}
                  {Math.abs(oddsMovement)}
                </span>
              )}
            </div>
          );
        },
      },
      {
        id: 'bestBook',
        header: 'Best Book',
        accessorFn: row => ({ best_over: row.best_over, best_under: row.best_under }),
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <div className="d-flex justify-content-between">
              <small>O: {value.best_over ? formatBookmaker(value.best_over) : '-'}</small>
              <small>U: {value.best_under ? formatBookmaker(value.best_under) : '-'}</small>
            </div>
          );
        },
      },
    ],
    [formatOdds, formatBookmaker]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="market-section mb-4">
      <h5 className="market-title">{marketTitle}</h5>
      <div className="table-responsive">
        <Table striped bordered hover variant="dark" size="sm" className="props-table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className="header-row">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className={
                      header.column.getIsSorted()
                        ? header.column.getIsSorted() === 'desc'
                          ? 'sort-desc'
                          : 'sort-asc'
                        : ''
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <span>
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          ' 🔽'
                        ) : (
                          ' 🔼'
                        )
                      ) : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PropsTable; 
import { RAPID_API_CONFIG } from '../config/constants';

// Add delay between requests to avoid rate limiting
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const makeApiRequest = async (url, options = {}) => {
    // Match exact header format and order from working request
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-rapidapi-ua': 'RapidAPI-Playground',
        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY,
        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
        'specificMethodHeaders': '[object Object]'  // Add as string exactly as shown in working request
    };

    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                ...headers,
                ...options.headers
            }
        });

        // Remove detailed request logging - security improvement
        console.log('Making API request to:', new URL(url).pathname);

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`API Error: ${response.status} - ${errorText}`);
        }

        const data = await response.json();
        await delay(200);
        return data;
    } catch (err) {
        console.error('API Request failed for path:', new URL(url).pathname);
        throw err;
    }
}; 
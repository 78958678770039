import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Tabs, 
  Tab, 
  Button, 
  Alert, 
  Grid, 
  Card, 
  CardContent, 
  CardHeader, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  IconButton, 
  Chip, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  CheckCircle as CheckCircleIcon,
  Visibility as VisibilityIcon,
  Flag as FlagIcon,
  Block as BlockIcon
} from '@mui/icons-material';
import { firestore } from '../../config/firebase';
import { collection, query, orderBy, limit, getDocs, where, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { format, formatDistanceToNow } from 'date-fns';

// Component for the Security Monitoring Dashboard
const SecurityMonitorDashboard = () => {
  const { user, isAdmin } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [alertsCount, setAlertsCount] = useState(0);
  const [securityAlerts, setSecurityAlerts] = useState([]);
  const [securityLogs, setSecurityLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLog, setSelectedLog] = useState(null);
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [alertNote, setAlertNote] = useState('');
  const [logFilterLevel, setLogFilterLevel] = useState('all');
  const [alertFilterStatus, setAlertFilterStatus] = useState('all');
  const [refreshKey, setRefreshKey] = useState(0);

  // Define styles for the dashboard
  const styles = {
    container: {
      color: 'white',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      padding: '0 10px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '25px',
      padding: '15px 0',
      borderBottom: '1px solid rgba(41, 171, 226, 0.3)',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: 'white',
      margin: 0,
      textShadow: '0 0 10px rgba(41, 171, 226, 0.5)'
    },
    refreshButton: {
      backgroundColor: 'rgba(41, 171, 226, 0.2)',
      border: '1px solid rgba(41, 171, 226, 0.5)',
      color: 'white',
      padding: '8px 16px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: 'rgba(41, 171, 226, 0.4)',
        boxShadow: '0 0 15px rgba(41, 171, 226, 0.6)'
      }
    },
    statsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
      gap: '16px',
      marginBottom: '30px'
    },
    statCard: {
      backgroundColor: '#1a1a1a',
      padding: '20px',
      borderRadius: '8px',
      border: '1px solid #333',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '3px',
        background: 'linear-gradient(90deg, rgba(41, 171, 226, 0.8) 0%, rgba(41, 171, 226, 0.2) 100%)'
      }
    },
    dangerStatCard: {
      backgroundColor: '#1a1a1a',
      padding: '20px',
      borderRadius: '8px',
      border: '1px solid #333',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '3px',
        background: 'linear-gradient(90deg, rgba(220, 53, 69, 0.8) 0%, rgba(220, 53, 69, 0.2) 100%)'
      }
    },
    statTitle: {
      fontSize: '1rem',
      color: '#aaa',
      marginBottom: '10px',
      fontWeight: 500
    },
    statValue: {
      fontSize: '3.5rem',
      fontWeight: '700',
      color: '#29abe2',
      margin: '5px 0 10px 0',
      fontFamily: '"Orbitron", sans-serif',
      textShadow: '0 0 10px rgba(41, 171, 226, 0.3)'
    },
    alertStatValue: {
      fontSize: '3.5rem',
      fontWeight: '700',
      color: '#dc3545',
      margin: '5px 0 10px 0',
      fontFamily: '"Orbitron", sans-serif',
      textShadow: '0 0 10px rgba(220, 53, 69, 0.3)'
    },
    statSubtext: {
      fontSize: '0.9rem',
      color: '#999',
      marginTop: '5px'
    },
    tabs: {
      display: 'flex',
      margin: '24px 0',
      borderBottom: '1px solid rgba(41, 171, 226, 0.3)',
    },
    tab: {
      padding: '12px 24px',
      cursor: 'pointer',
      color: '#aaa',
      borderBottom: '3px solid transparent',
      transition: 'all 0.3s ease',
      fontWeight: 500,
      position: 'relative',
      '&:hover': {
        color: 'white',
        backgroundColor: 'rgba(41, 171, 226, 0.1)'
      }
    },
    activeTab: {
      color: '#29abe2',
      borderBottom: '3px solid #29abe2',
      fontWeight: 600,
      backgroundColor: 'rgba(41, 171, 226, 0.1)'
    },
    filterBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '20px',
      alignItems: 'center',
      padding: '10px 15px',
      backgroundColor: 'rgba(26, 26, 26, 0.7)',
      borderRadius: '6px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)'
    },
    filterLabel: {
      marginRight: '10px',
      color: '#ccc',
      fontWeight: 500
    },
    select: {
      backgroundColor: '#2a2a2a',
      color: 'white',
      border: '1px solid #444',
      borderRadius: '4px',
      padding: '8px 14px'
    },
    tableContainer: {
      backgroundColor: 'rgba(26, 26, 26, 0.7)',
      borderRadius: '8px',
      border: '1px solid #333',
      overflow: 'hidden',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
    },
    tableHead: {
      backgroundColor: '#212121',
    },
    tableHeaderCell: {
      padding: '14px 16px',
      textAlign: 'left',
      color: '#29abe2',
      fontWeight: 600,
      borderBottom: '1px solid #333',
      fontSize: '0.95rem',
      textTransform: 'uppercase',
      letterSpacing: '0.5px'
    },
    tableCell: {
      padding: '12px 16px',
      borderBottom: '1px solid rgba(51, 51, 51, 0.7)',
      color: '#e0e0e0',
      fontSize: '0.95rem',
      transition: 'background-color 0.2s ease'
    },
    tableRow: {
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: 'rgba(41, 171, 226, 0.1)'
      }
    },
    actionButton: {
      backgroundColor: 'transparent',
      border: '1px solid rgba(41, 171, 226, 0.5)',
      borderRadius: '4px',
      color: '#29abe2',
      padding: '6px 12px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: 'rgba(41, 171, 226, 0.2)',
        boxShadow: '0 0 10px rgba(41, 171, 226, 0.4)'
      }
    },
    noData: {
      textAlign: 'center',
      padding: '40px 20px',
      color: '#aaa',
      fontStyle: 'italic'
    },
    chip: {
      borderRadius: '4px',
      padding: '4px 8px',
      fontWeight: 600,
      fontSize: '0.8rem',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
    },
    criticalChip: {
      backgroundColor: 'rgba(220, 53, 69, 0.2)',
      color: '#dc3545',
      border: '1px solid rgba(220, 53, 69, 0.3)'
    },
    errorChip: {
      backgroundColor: 'rgba(220, 53, 69, 0.15)',
      color: '#ff6b70',
      border: '1px solid rgba(220, 53, 69, 0.2)'
    },
    warningChip: {
      backgroundColor: 'rgba(255, 193, 7, 0.15)',
      color: '#ffc107',
      border: '1px solid rgba(255, 193, 7, 0.2)'
    },
    infoChip: {
      backgroundColor: 'rgba(41, 171, 226, 0.15)',
      color: '#29abe2',
      border: '1px solid rgba(41, 171, 226, 0.2)'
    },
    debugChip: {
      backgroundColor: 'rgba(108, 117, 125, 0.15)',
      color: '#adb5bd',
      border: '1px solid rgba(108, 117, 125, 0.2)'
    },
    dialogPaper: {
      backgroundColor: '#1a1a1a',
      color: 'white',
      boxShadow: '0 0 30px rgba(0, 0, 0, 0.5)',
      border: '1px solid #333',
      maxWidth: '800px',
      width: '100%'
    },
    dialogTitle: {
      borderBottom: '1px solid #333',
      padding: '16px 24px'
    },
    dialogContent: {
      padding: '24px'
    },
    dialogActions: {
      borderTop: '1px solid #333',
      padding: '16px 24px'
    },
    button: {
      transition: 'all 0.3s ease',
      textTransform: 'none',
      fontWeight: 500
    },
    primary: {
      backgroundColor: 'rgba(41, 171, 226, 0.2)',
      border: '1px solid rgba(41, 171, 226, 0.5)',
      color: '#29abe2',
      '&:hover': {
        backgroundColor: 'rgba(41, 171, 226, 0.4)',
        boxShadow: '0 0 15px rgba(41, 171, 226, 0.4)'
      }
    }
  };

  // Check if user has admin privileges
  useEffect(() => {
    if (!isAdmin) {
      // Redirect non-admin users
      window.location.href = '/';
    }
  }, [isAdmin]);

  // Fetch security alerts and logs
  useEffect(() => {
    const fetchSecurityData = async () => {
      setLoading(true);
      try {
        // Fetch unacknowledged alerts
        const alertsQuery = query(
          collection(firestore, 'securityAlerts'),
          where('acknowledged', '==', false),
          orderBy('timestamp', 'desc'),
          limit(20)
        );
        const alertsSnapshot = await getDocs(alertsQuery);
        const alertsData = alertsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        }));
        setSecurityAlerts(alertsData);
        setAlertsCount(alertsData.length);

        // Fetch security logs with filter
        let logsQuery;
        if (logFilterLevel === 'all') {
          logsQuery = query(
            collection(firestore, 'securityLogs'),
            orderBy('timestamp', 'desc'),
            limit(100)
          );
        } else {
          logsQuery = query(
            collection(firestore, 'securityLogs'),
            where('level', '==', logFilterLevel.toUpperCase()),
            orderBy('timestamp', 'desc'),
            limit(100)
          );
        }
        
        const logsSnapshot = await getDocs(logsQuery);
        const logsData = logsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        }));
        setSecurityLogs(logsData);
      } catch (error) {
        console.error("Error fetching security data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSecurityData();
  }, [logFilterLevel, refreshKey]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle viewing log details
  const handleViewLog = (log) => {
    setSelectedLog(log);
    setOpenLogDialog(true);
  };

  // Handle viewing alert details
  const handleViewAlert = (alert) => {
    setSelectedAlert(alert);
    setAlertNote('');
    setOpenAlertDialog(true);
  };

  // Handle acknowledging an alert
  const handleAcknowledgeAlert = async () => {
    if (!selectedAlert) return;
    
    try {
      const alertRef = doc(firestore, 'securityAlerts', selectedAlert.id);
      await updateDoc(alertRef, {
        acknowledged: true,
        acknowledgedBy: user.uid,
        acknowledgedAt: new Date(),
        notes: alertNote
      });
      
      // Remove the acknowledged alert from the list
      setSecurityAlerts(securityAlerts.filter(alert => alert.id !== selectedAlert.id));
      setAlertsCount(prevCount => prevCount - 1);
      setOpenAlertDialog(false);
    } catch (error) {
      console.error("Error acknowledging alert:", error);
    }
  };

  // Handle refreshing the data
  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
  };

  // Get icon based on log level
  const getLevelIcon = (level) => {
    switch(level) {
      case 'CRITICAL':
        return <ErrorIcon color="error" />;
      case 'ERROR':
        return <ErrorIcon sx={{ color: 'error.main' }} />;
      case 'WARN':
        return <WarningIcon sx={{ color: 'warning.main' }} />;
      case 'INFO':
        return <InfoIcon sx={{ color: 'info.main' }} />;
      case 'DEBUG':
        return <InfoIcon sx={{ color: 'action.active' }} />;
      default:
        return <InfoIcon />;
    }
  };

  // Get color based on log level
  const getLevelColor = (level) => {
    switch(level) {
      case 'CRITICAL':
        return 'error';
      case 'ERROR':
        return 'error';
      case 'WARN':
        return 'warning';
      case 'INFO':
        return 'info';
      case 'DEBUG':
        return 'default';
      default:
        return 'default';
    }
  };

  // Format timestamp for display
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'Unknown';
    try {
      return format(timestamp, 'MMM d, yyyy HH:mm:ss');
    } catch (e) {
      return 'Invalid date';
    }
  };

  // Format relative time for display
  const formatRelativeTime = (timestamp) => {
    if (!timestamp) return 'Unknown';
    try {
      return formatDistanceToNow(timestamp, { addSuffix: true });
    } catch (e) {
      return 'Invalid date';
    }
  };

  // Dashboard summary cards
  const DashboardSummary = () => (
    <Grid container spacing={3} mb={4}>
      <Grid item xs={12} md={6} lg={3}>
        <Box sx={{
          backgroundColor: '#1a1a1a',
          borderRadius: '8px',
          border: '1px solid #333',
          padding: '20px',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '3px',
            background: alertsCount > 0 
              ? 'linear-gradient(90deg, rgba(220, 53, 69, 0.8) 0%, rgba(220, 53, 69, 0.2) 100%)' 
              : 'linear-gradient(90deg, rgba(41, 171, 226, 0.8) 0%, rgba(41, 171, 226, 0.2) 100%)'
          }
        }}>
          <Typography variant="subtitle1" color="#aaa" fontWeight={500} mb={1}>
            Active Alerts
          </Typography>
          <Typography 
            variant="h3" 
            sx={{
              color: alertsCount > 0 ? '#dc3545' : '#29abe2',
              fontWeight: 700,
              fontSize: '3rem',
              my: 1,
              textShadow: alertsCount > 0 
                ? '0 0 10px rgba(220, 53, 69, 0.3)' 
                : '0 0 10px rgba(41, 171, 226, 0.3)'
            }}
          >
            {alertsCount}
          </Typography>
          <Typography variant="body2" color="#999">
            {alertsCount === 0 ? "No active alerts" : "Alerts requiring attention"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Box sx={{
          backgroundColor: '#1a1a1a',
          borderRadius: '8px', 
          border: '1px solid #333',
          padding: '20px',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '3px',
            background: 'linear-gradient(90deg, rgba(41, 171, 226, 0.8) 0%, rgba(41, 171, 226, 0.2) 100%)'
          }
        }}>
          <Typography variant="subtitle1" color="#aaa" fontWeight={500} mb={1}>
            Today's Critical Events
          </Typography>
          <Typography 
            variant="h3" 
            sx={{
              color: '#29abe2',
              fontWeight: 700,
              fontSize: '3rem',
              my: 1,
              textShadow: '0 0 10px rgba(41, 171, 226, 0.3)'
            }}
          >
            {securityLogs.filter(log => 
              log.level === 'CRITICAL' && 
              log.timestamp > new Date(new Date().setHours(0,0,0,0))
            ).length}
          </Typography>
          <Typography variant="body2" color="#999">Events in the last 24 hours</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Box sx={{
          backgroundColor: '#1a1a1a',
          borderRadius: '8px', 
          border: '1px solid #333',
          padding: '20px',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '3px',
            background: 'linear-gradient(90deg, rgba(41, 171, 226, 0.8) 0%, rgba(41, 171, 226, 0.2) 100%)'
          }
        }}>
          <Typography variant="subtitle1" color="#aaa" fontWeight={500} mb={1}>
            Failed Logins (24h)
          </Typography>
          <Typography 
            variant="h3" 
            sx={{
              color: '#29abe2',
              fontWeight: 700,
              fontSize: '3rem',
              my: 1,
              textShadow: '0 0 10px rgba(41, 171, 226, 0.3)'
            }}
          >
            {securityLogs.filter(log => 
              log.eventType === 'auth.login.failure' && 
              log.timestamp > new Date(Date.now() - 24 * 60 * 60 * 1000)
            ).length}
          </Typography>
          <Typography variant="body2" color="#999">Authentication failures</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Box sx={{
          backgroundColor: '#1a1a1a',
          borderRadius: '8px', 
          border: '1px solid #333',
          padding: '20px',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '3px',
            background: 'linear-gradient(90deg, rgba(41, 171, 226, 0.8) 0%, rgba(41, 171, 226, 0.2) 100%)'
          }
        }}>
          <Typography variant="subtitle1" color="#aaa" fontWeight={500} mb={1}>
            Access Denied Events
          </Typography>
          <Typography 
            variant="h3" 
            sx={{
              color: '#29abe2',
              fontWeight: 700,
              fontSize: '3rem',
              my: 1,
              textShadow: '0 0 10px rgba(41, 171, 226, 0.3)'
            }}
          >
            {securityLogs.filter(log => 
              log.eventType === 'auth.access.denied' &&
              log.timestamp > new Date(Date.now() - 24 * 60 * 60 * 1000)
            ).length}
          </Typography>
          <Typography variant="body2" color="#999">Unauthorized access attempts</Typography>
        </Box>
      </Grid>
    </Grid>
  );

  // Get custom chip style based on log level
  const getChipStyle = (level) => {
    switch(level) {
      case 'CRITICAL':
        return {...styles.chip, ...styles.criticalChip};
      case 'ERROR':
        return {...styles.chip, ...styles.errorChip};
      case 'WARN':
        return {...styles.chip, ...styles.warningChip};
      case 'INFO':
        return {...styles.chip, ...styles.infoChip};
      case 'DEBUG':
        return {...styles.chip, ...styles.debugChip};
      default:
        return {...styles.chip, ...styles.infoChip};
    }
  };

  // Return loading state if data is still loading
  if (!isAdmin) {
    return <Typography>Unauthorized</Typography>;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3, 
        pb: 2,
        borderBottom: '1px solid rgba(41, 171, 226, 0.3)'
      }}>
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            color: 'white', 
            fontWeight: 'bold', 
            fontSize: '2rem',
            textShadow: '0 0 10px rgba(41, 171, 226, 0.4)'
          }}
        >
          Security Monitoring Dashboard
        </Typography>
        <Button 
          variant="outlined" 
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          sx={{
            backgroundColor: 'rgba(41, 171, 226, 0.1)',
            color: '#29abe2',
            border: '1px solid rgba(41, 171, 226, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(41, 171, 226, 0.2)',
              borderColor: '#29abe2',
              boxShadow: '0 0 15px rgba(41, 171, 226, 0.4)'
            },
            transition: 'all 0.3s ease',
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          Refresh
        </Button>
      </Box>

      {alertsCount > 0 && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3, 
            backgroundColor: 'rgba(220, 53, 69, 0.1)', 
            color: '#ff6b70',
            border: '1px solid rgba(220, 53, 69, 0.3)',
            borderRadius: '6px'
          }}
          action={
            <Button 
              color="inherit" 
              size="small" 
              onClick={() => setActiveTab(1)}
              sx={{
                textTransform: 'none',
                fontWeight: 600
              }}
            >
              View Alerts
            </Button>
          }
        >
          There are {alertsCount} unacknowledged security alerts that require attention.
        </Alert>
      )}

      <DashboardSummary />

      <Box sx={{ 
        display: 'flex', 
        borderBottom: '1px solid rgba(41, 171, 226, 0.3)',
        mb: 3
      }}>
        <Box 
          onClick={() => setActiveTab(0)}
          sx={{ 
            py: 1.5, 
            px: 3, 
            cursor: 'pointer',
            position: 'relative',
            color: activeTab === 0 ? '#29abe2' : '#aaa',
            fontWeight: activeTab === 0 ? 600 : 400,
            '&:hover': {
              color: activeTab === 0 ? '#29abe2' : 'white',
              backgroundColor: 'rgba(41, 171, 226, 0.1)'
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -1,
              left: 0,
              width: '100%',
              height: 3,
              backgroundColor: activeTab === 0 ? '#29abe2' : 'transparent',
              transition: 'all 0.3s ease'
            },
            transition: 'all 0.3s ease'
          }}
        >
          SECURITY LOGS
        </Box>
        <Box 
          onClick={() => setActiveTab(1)}
          sx={{ 
            py: 1.5, 
            px: 3, 
            cursor: 'pointer',
            position: 'relative',
            color: activeTab === 1 ? '#29abe2' : '#aaa',
            fontWeight: activeTab === 1 ? 600 : 400,
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: activeTab === 1 ? '#29abe2' : 'white',
              backgroundColor: 'rgba(41, 171, 226, 0.1)'
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -1,
              left: 0,
              width: '100%',
              height: 3,
              backgroundColor: activeTab === 1 ? '#29abe2' : 'transparent',
              transition: 'all 0.3s ease'
            },
            transition: 'all 0.3s ease'
          }}
        >
          ALERTS
          {alertsCount > 0 && (
            <Box
              component="span"
              sx={{
                ml: 1,
                backgroundColor: 'rgba(220, 53, 69, 0.2)',
                color: '#ff6b70',
                borderRadius: '10px',
                padding: '2px 8px',
                fontSize: '0.75rem',
                fontWeight: 'bold'
              }}
            >
              {alertsCount}
            </Box>
          )}
        </Box>
      </Box>

      {activeTab === 0 && (
        <Box mb={3}>
          <Box display="flex" justifyContent="flex-end" mb={2} sx={{
            backgroundColor: 'rgba(26, 26, 26, 0.7)',
            border: '1px solid #333',
            borderRadius: '8px',
            padding: '12px 15px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)'
          }}>
            <FormControl variant="outlined" size="small">
              <InputLabel sx={{ color: '#aaa' }}>Log Level</InputLabel>
              <Select
                value={logFilterLevel}
                onChange={(e) => setLogFilterLevel(e.target.value)}
                label="Log Level"
                sx={{
                  backgroundColor: '#2a2a2a',
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#444'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#29abe2'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#29abe2'
                  },
                  '& .MuiSelect-icon': {
                    color: '#29abe2'
                  }
                }}
              >
                <MenuItem value="all" sx={{ backgroundColor: '#1a1a1a', color: 'white', '&:hover': { backgroundColor: '#333' } }}>All Levels</MenuItem>
                <MenuItem value="debug" sx={{ backgroundColor: '#1a1a1a', color: 'white', '&:hover': { backgroundColor: '#333' } }}>Debug</MenuItem>
                <MenuItem value="info" sx={{ backgroundColor: '#1a1a1a', color: 'white', '&:hover': { backgroundColor: '#333' } }}>Info</MenuItem>
                <MenuItem value="warn" sx={{ backgroundColor: '#1a1a1a', color: 'white', '&:hover': { backgroundColor: '#333' } }}>Warning</MenuItem>
                <MenuItem value="error" sx={{ backgroundColor: '#1a1a1a', color: 'white', '&:hover': { backgroundColor: '#333' } }}>Error</MenuItem>
                <MenuItem value="critical" sx={{ backgroundColor: '#1a1a1a', color: 'white', '&:hover': { backgroundColor: '#333' } }}>Critical</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <TableContainer component={Paper} sx={{ 
            backgroundColor: 'rgba(26, 26, 26, 0.7)',
            borderRadius: '8px',
            border: '1px solid #333',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            overflow: 'hidden'
          }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#212121' }}>
                <TableRow>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Level</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Timestamp</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Event Type</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Message</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>User ID</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ color: '#aaa', padding: '40px 20px', fontStyle: 'italic' }}>Loading...</TableCell>
                  </TableRow>
                ) : securityLogs.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ color: '#aaa', padding: '40px 20px', fontStyle: 'italic' }}>No security logs found</TableCell>
                  </TableRow>
                ) : (
                  securityLogs.map((log) => (
                    <TableRow key={log.id} hover sx={{ 
                      '&:hover': { 
                        backgroundColor: 'rgba(41, 171, 226, 0.1)',
                        transition: 'background-color 0.2s ease'
                      }
                    }}>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>
                        <Chip 
                          icon={getLevelIcon(log.level)} 
                          label={log.level} 
                          color={getLevelColor(log.level)} 
                          size="small" 
                          sx={{
                            borderRadius: '4px',
                            fontWeight: 600,
                            fontSize: '0.75rem',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>{formatRelativeTime(log.timestamp)}</TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>{log.eventType}</TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>{log.message}</TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>{log.user?.userId || 'N/A'}</TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>
                        <IconButton 
                          size="small" 
                          onClick={() => handleViewLog(log)}
                          sx={{
                            color: '#29abe2',
                            '&:hover': {
                              backgroundColor: 'rgba(41, 171, 226, 0.2)',
                              transition: 'all 0.2s ease',
                              boxShadow: '0 0 10px rgba(41, 171, 226, 0.3)'
                            }
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {activeTab === 1 && (
        <Box mb={3}>
          <TableContainer component={Paper} sx={{ 
            backgroundColor: 'rgba(26, 26, 26, 0.7)',
            borderRadius: '8px',
            border: '1px solid #333',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            overflow: 'hidden'
          }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#212121' }}>
                <TableRow>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Severity</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Timestamp</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Event Type</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Message</TableCell>
                  <TableCell sx={{ 
                    color: '#29abe2', 
                    fontWeight: 600, 
                    borderBottom: '1px solid #333',
                    fontSize: '0.875rem',
                    letterSpacing: '0.5px',
                    textTransform: 'uppercase'
                  }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center" sx={{ color: '#aaa', padding: '40px 20px', fontStyle: 'italic' }}>Loading...</TableCell>
                  </TableRow>
                ) : securityAlerts.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center" sx={{ color: '#aaa', padding: '40px 20px', fontStyle: 'italic' }}>No unacknowledged alerts</TableCell>
                  </TableRow>
                ) : (
                  securityAlerts.map((alert) => (
                    <TableRow key={alert.id} hover sx={{ 
                      '&:hover': { 
                        backgroundColor: 'rgba(41, 171, 226, 0.1)',
                        transition: 'background-color 0.2s ease'
                      }
                    }}>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>
                        <Chip 
                          icon={<ErrorIcon />} 
                          label={alert.level || 'CRITICAL'} 
                          color="error" 
                          size="small" 
                          sx={{
                            borderRadius: '4px',
                            fontWeight: 600,
                            fontSize: '0.75rem',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>{formatRelativeTime(alert.timestamp)}</TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>{alert.eventType}</TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>{alert.message}</TableCell>
                      <TableCell sx={{ 
                        color: '#e0e0e0', 
                        borderBottom: '1px solid rgba(51, 51, 51, 0.7)'
                      }}>
                        <IconButton 
                          size="small" 
                          color="primary" 
                          onClick={() => handleViewAlert(alert)}
                          sx={{
                            color: '#29abe2',
                            '&:hover': {
                              backgroundColor: 'rgba(41, 171, 226, 0.2)',
                              transition: 'all 0.2s ease',
                              boxShadow: '0 0 10px rgba(41, 171, 226, 0.3)'
                            }
                          }}
                        >
                          <FlagIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Dialog
        open={openLogDialog}
        onClose={() => setOpenLogDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: '#1a1a1a',
            color: 'white',
            boxShadow: '0 0 30px rgba(0, 0, 0, 0.5)',
            border: '1px solid #333',
            maxWidth: '800px',
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden'
          }
        }}
      >
        {selectedLog && (
          <>
            <DialogTitle sx={{ 
              borderBottom: '1px solid #333',
              padding: '16px 24px',
              backgroundColor: '#212121'
            }}>
              <Box display="flex" alignItems="center">
                {getLevelIcon(selectedLog.level)}
                <Typography variant="h6" component="span" ml={1} color="white" fontWeight={600}>
                  Security Log Details: {selectedLog.eventType}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: '24px', backgroundColor: '#1a1a1a' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Timestamp</Typography>
                  <Typography variant="body1" color="white">
                    {formatTimestamp(selectedLog.timestamp)} ({formatRelativeTime(selectedLog.timestamp)})
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Message</Typography>
                  <Typography variant="body1" color="white">{selectedLog.message}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Level</Typography>
                  <Chip 
                    label={selectedLog.level} 
                    color={getLevelColor(selectedLog.level)} 
                    size="small" 
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Event Type</Typography>
                  <Typography variant="body1" color="white">{selectedLog.eventType}</Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">User Context</Typography>
                  <Box sx={{ 
                    backgroundColor: 'rgba(26, 26, 26, 0.5)', 
                    padding: '10px', 
                    borderRadius: '4px',
                    border: '1px solid #333',
                    overflow: 'auto',
                    maxHeight: '200px'
                  }}>
                    <pre style={{ margin: 0, color: '#e0e0e0' }}>
                      {JSON.stringify(selectedLog.user, null, 2)}
                    </pre>
                  </Box>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">System Context</Typography>
                  <Box sx={{ 
                    backgroundColor: 'rgba(26, 26, 26, 0.5)', 
                    padding: '10px', 
                    borderRadius: '4px',
                    border: '1px solid #333',
                    overflow: 'auto',
                    maxHeight: '200px'
                  }}>
                    <pre style={{ margin: 0, color: '#e0e0e0' }}>
                      {JSON.stringify(selectedLog.system, null, 2)}
                    </pre>
                  </Box>
                </Grid>
                
                {selectedLog.data && Object.keys(selectedLog.data).length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Additional Data</Typography>
                    <Box sx={{ 
                      backgroundColor: 'rgba(26, 26, 26, 0.5)', 
                      padding: '10px', 
                      borderRadius: '4px',
                      border: '1px solid #333',
                      overflow: 'auto',
                      maxHeight: '200px'
                    }}>
                      <pre style={{ margin: 0, color: '#e0e0e0' }}>
                        {JSON.stringify(selectedLog.data, null, 2)}
                      </pre>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ 
              borderTop: '1px solid #333',
              padding: '16px 24px',
              backgroundColor: '#212121'
            }}>
              <Button 
                onClick={() => setOpenLogDialog(false)}
                sx={{
                  backgroundColor: 'rgba(41, 171, 226, 0.1)',
                  color: '#29abe2',
                  border: '1px solid rgba(41, 171, 226, 0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(41, 171, 226, 0.2)',
                    borderColor: '#29abe2',
                    boxShadow: '0 0 10px rgba(41, 171, 226, 0.3)'
                  },
                  textTransform: 'none',
                  fontWeight: 500
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: '#1a1a1a',
            color: 'white',
            boxShadow: '0 0 30px rgba(0, 0, 0, 0.5)',
            border: '1px solid #333',
            maxWidth: '800px',
            width: '100%',
            borderRadius: '8px',
            overflow: 'hidden'
          }
        }}
      >
        {selectedAlert && (
          <>
            <DialogTitle sx={{ 
              borderBottom: '1px solid #333',
              padding: '16px 24px',
              backgroundColor: '#212121'
            }}>
              <Box display="flex" alignItems="center">
                <ErrorIcon sx={{ color: '#dc3545' }} />
                <Typography variant="h6" component="span" ml={1} color="white" fontWeight={600}>
                  Security Alert: {selectedAlert.eventType}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: '24px', backgroundColor: '#1a1a1a' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Timestamp</Typography>
                  <Typography variant="body1" color="white">
                    {formatTimestamp(selectedAlert.timestamp)} ({formatRelativeTime(selectedAlert.timestamp)})
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Message</Typography>
                  <Typography variant="body1" color="white">{selectedAlert.message}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#ccc">Event Details</Typography>
                  <Box sx={{ 
                    backgroundColor: 'rgba(26, 26, 26, 0.5)', 
                    padding: '10px', 
                    borderRadius: '4px',
                    border: '1px solid #333',
                    overflow: 'auto',
                    maxHeight: '200px',
                    mb: 3
                  }}>
                    <pre style={{ margin: 0, color: '#e0e0e0' }}>
                      {JSON.stringify({
                        eventType: selectedAlert.eventType,
                        level: selectedAlert.level,
                        user: selectedAlert.user,
                        system: selectedAlert.system,
                        data: selectedAlert.data
                      }, null, 2)}
                    </pre>
                  </Box>
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    label="Resolution Notes"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={alertNote}
                    onChange={(e) => setAlertNote(e.target.value)}
                    placeholder="Describe how this alert was handled and any remediation steps taken..."
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: '#2a2a2a',
                        color: 'white',
                        '& fieldset': {
                          borderColor: '#444',
                        },
                        '&:hover fieldset': {
                          borderColor: '#29abe2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#29abe2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#aaa',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#29abe2',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ 
              borderTop: '1px solid #333',
              padding: '16px 24px',
              backgroundColor: '#212121'
            }}>
              <Button 
                onClick={() => setOpenAlertDialog(false)}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#aaa',
                  border: '1px solid #444',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    borderColor: '#aaa',
                  },
                  textTransform: 'none',
                  fontWeight: 500,
                  mr: 1
                }}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleAcknowledgeAlert}
                disabled={!alertNote.trim()}
                sx={{
                  backgroundColor: alertNote.trim() ? 'rgba(41, 171, 226, 0.8)' : 'rgba(41, 171, 226, 0.3)',
                  color: 'white',
                  border: 'none',
                  '&:hover': {
                    backgroundColor: alertNote.trim() ? '#29abe2' : 'rgba(41, 171, 226, 0.3)',
                    boxShadow: alertNote.trim() ? '0 0 15px rgba(41, 171, 226, 0.5)' : 'none'
                  },
                  textTransform: 'none',
                  fontWeight: 500
                }}
              >
                Acknowledge & Resolve
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default SecurityMonitorDashboard; 
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ArticleModal.css';

const ArticleModal = ({ 
  show, 
  onHide, 
  articleData, 
  onSave, 
  type = 'article',
  postToUpdates,
  setPostToUpdates
}) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (articleData) {
      setTitle(articleData.title || '');
      setContent(articleData.content || '');
    }
  }, [articleData]);

  const handleSave = () => {
    onSave({
      title,
      content,
      type
    });
    onHide();
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['link', 'code-block'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'align',
    'link', 'code-block'
  ];

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      size="lg" 
      centered 
      className="article-modal dark-theme"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>{type === 'changelog' ? 'Edit Changelog' : 'Edit Article'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter title"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Content</Form.Label>
            <div className="rich-editor-container">
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                modules={modules}
                formats={formats}
                style={{ height: '600px', marginBottom: '50px' }}
              />
            </div>
          </Form.Group>
          {type === 'changelog' && (
            <Form.Group className="mt-3">
              <Form.Check
                type="checkbox"
                id="post-to-updates"
                label="Post to Updates section on homepage"
                checked={postToUpdates}
                onChange={(e) => setPostToUpdates(e.target.checked)}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArticleModal; 
import React, { useEffect, useState, useRef, Component } from 'react';
import { Container, Spinner, Alert, Form, Button, InputGroup, Badge } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import './Checkout.css';
import { validatePromoCode, calculateDiscountedPrice, recordPromoCodeUse } from '../utils/promoCodeUtils';
import { sendPremiumSubscriptionConfirmation } from '../utils/emailUtils';
import { useUserActivity } from '../contexts/UserActivityContext';

// Custom CSS for trial elements (matching PlansTab)
const trialStyles = `
    .trial-notice {
        margin-top: 15px;
        padding: 10px;
        background-color: rgba(0, 170, 255, 0.1);
        border-radius: 5px;
        font-size: 14px;
        display: flex;
        align-items: flex-start;
    }
    
    .trial-notice i {
        color: #00aaff;
        margin-right: 8px;
        font-size: 16px;
        margin-top: 2px;
    }
    
    .trial-notice p {
        margin: 0;
        line-height: 1.4;
    }
`;

// Add styles once at app startup instead of per component
if (!document.getElementById('checkout-global-styles')) {
    const styleEl = document.createElement('style');
    styleEl.id = 'checkout-global-styles';
    styleEl.innerHTML = trialStyles;
    document.head.appendChild(styleEl);
}

// Global error handler for PayPal script errors
const setupGlobalErrorHandler = () => {
    const originalWindowOnError = window.onerror;
    
    window.onerror = function(message, source, lineno, colno, error) {
        // Check if this is a PayPal related error
        if (source && (source.includes('paypal') || source === 'Script error.')) {
            console.warn('PayPal script error caught by global handler:', { message, source, lineno, colno });
            // Return true to prevent the error from bubbling up
            return true;
        }
        
        // Otherwise, pass to the original handler if it exists
        if (typeof originalWindowOnError === 'function') {
            return originalWindowOnError(message, source, lineno, colno, error);
        }
        
        // If no original handler, let the error propagate
        return false;
    };
    
    // Return a cleanup function
    return () => {
        window.onerror = originalWindowOnError;
    };
};

// PayPal button component using hosted buttons
const PayPalHostedButton = ({ onSuccess, onError, onCancel, user, promoCode, promoCodeValid, promoCodeDiscount, calculatePriceWithDiscount }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [buttonsRendered, setButtonsRendered] = useState(false);
    const [cancelMessage, setCancelMessage] = useState(null);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const containerRef = useRef(null);
    const scriptRef = useRef(null);
    const paymentWindowRef = useRef(null);
    const clickTimeoutRef = useRef(null);
    const { updateActivity } = useUserActivity();

    // Reset error and cancel message when component re-renders
    useEffect(() => {
        setError(null);
        setCancelMessage(null);
    }, []);
    
    // Handle window focus events to detect when payment popup is closed
    useEffect(() => {
        let modalOpenTime = 0;
        
        const handleFocus = () => {
            // If we were in the middle of a payment process but now we're back
            if (paymentInProgress) {
                // Update user activity to prevent timeout during payment process
                updateActivity();
                
                // Only treat as cancellation if enough time has passed since modal opened
                // to avoid false positives on immediate closes
                const timeElapsed = Date.now() - modalOpenTime;
                
                // Give a short delay to allow for normal redirections
                setTimeout(() => {
                    try {
                        // Only trigger cancel if we're still in progress (no success/error happened)
                        if (paymentInProgress) {
                            console.log("Payment window appears to have been closed");
                            setPaymentInProgress(false);
                            // Clear any pending timeouts
                            if (clickTimeoutRef.current) {
                                clearTimeout(clickTimeoutRef.current);
                                clickTimeoutRef.current = null;
                            }
                            // Treat as a cancellation
                            setCancelMessage("Payment window closed. You can try again when you're ready.");
                            if (onCancel) {
                                onCancel();
                            }
                        }
                    } catch (err) {
                        console.error("Error handling focus event:", err);
                        setError("An error occurred. Please try again.");
                        setPaymentInProgress(false);
                    }
                }, 500);
            }
        };
        
        const handleBlur = () => {
            // When window loses focus, this might be the PayPal popup opening
            // Record the time for later comparison
            if (paymentInProgress) {
                modalOpenTime = Date.now();
                // Update activity timestamp to prevent auto-logout during checkout
                updateActivity();
            }
        };
        
        // Listen for both focus and blur events
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);
        
        // Error handling fallback - if payment is in progress for too long
        // without success/cancel/error, reset the state
        const fallbackTimer = setTimeout(() => {
            if (paymentInProgress) {
                console.log("Payment flow reset by fallback timer");
                setPaymentInProgress(false);
                setCancelMessage("Payment process timed out. Please try again.");
                if (onCancel) {
                    onCancel();
                }
            }
        }, 60000); // 1 minute timeout
        
        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
            clearTimeout(fallbackTimer);
            if (clickTimeoutRef.current) {
                clearTimeout(clickTimeoutRef.current);
            }
        };
    }, [paymentInProgress, onCancel, updateActivity]);

    // Load PayPal script once on mount
    useEffect(() => {
        // Function to load the PayPal script
        const loadPayPalScript = () => {
            setLoading(true);
            
            // Create the script element with updated components for Orders API
            const script = document.createElement('script');
            script.src = "https://www.paypal.com/sdk/js?client-id=Adpc5XL2jAgbpNEdJsLY6-LDupBLlJZStTBP5WHt5FJ89g_5ap0pdRcWEzeMeYUyMPqWewdnoADbcUrk&vault=true&intent=subscription&components=buttons&currency=USD";
            script.id = 'paypal-sdk-js';
            script.async = true;
            script.crossOrigin = "anonymous";
            
            // Enhanced error handling
            script.onerror = (error) => {
                console.error("PayPal script failed to load:", error);
                setError("Failed to load payment processor. Please refresh the page or try again later.");
                setLoading(false);
                if (onError) {
                    onError("Payment system unavailable. Please try again later.");
                }
            };

            let retryCount = 0;
            const MAX_RETRIES = 50; // 5 seconds total with 100ms intervals

            // Add load event listener
            script.addEventListener('load', () => {
                console.log("PayPal script loaded successfully");
                
                // Function to check for PayPal object
                const checkPayPal = () => {
                    console.log("Checking PayPal object availability:", {
                        paypalExists: !!window.paypal,
                        buttonsExists: window.paypal?.Buttons,
                        containerExists: !!containerRef.current
                    });

                    if (window.paypal?.Buttons) {
                        console.log("PayPal Buttons API available, rendering button");
                        renderButton();
                        return;
                    }
                    
                    retryCount++;
                    if (retryCount >= MAX_RETRIES) {
                        console.error("PayPal initialization timed out after", MAX_RETRIES, "attempts");
                        setError("Payment system failed to initialize. Please refresh the page.");
                        setLoading(false);
                        return;
                    }
                    
                    console.log(`PayPal object not available yet, retrying... (attempt ${retryCount}/${MAX_RETRIES})`);
                    setTimeout(checkPayPal, 100);
                };
                
                // Start checking for PayPal object
                checkPayPal();
            }, { once: true });
            
            // Save a reference to the script for cleanup
            scriptRef.current = script;
            
            // Remove any existing PayPal scripts before adding new one
            const existingScript = document.getElementById('paypal-sdk-js');
            if (existingScript) {
                console.log("Removing existing PayPal script");
                existingScript.remove();
            }
            
            // Add the script to the document
            console.log("Adding PayPal script to document");
            document.body.appendChild(script);
        };
        
        // Function to render the PayPal button when script is loaded
        const renderButton = () => {
            console.log("Starting button render process");
            
            // Make sure PayPal is loaded and container exists
            if (!window.paypal?.Buttons || !containerRef.current) {
                console.error("Cannot render PayPal button:", {
                    paypalExists: !!window.paypal,
                    buttonsExists: !!window.paypal?.Buttons,
                    containerExists: !!containerRef.current
                });
                setError("Payment system unavailable");
                setLoading(false);
                return;
            }
            
            try {
                console.log("Clearing container and preparing to render button");
                // Clear the container first
                while (containerRef.current.firstChild) {
                    containerRef.current.removeChild(containerRef.current.firstChild);
                }
                
                // Get the current price info with any discounts applied
                const priceInfo = calculatePriceWithDiscount();
                console.log("Price info calculated:", priceInfo);
                
                // Now render the PayPal button with dynamic pricing
                const buttons = window.paypal.Buttons({
                    style: {
                        shape: 'rect',
                        color: 'gold',
                        layout: 'vertical',
                        label: 'subscribe'
                    },
                    onClick: (data, actions) => {
                        // When the button is clicked, update activity and set payment in progress
                        console.log("PayPal button clicked");
                        updateActivity();
                        setPaymentInProgress(true);
                        return actions.resolve();
                    },
                    createSubscription: async (data, actions) => {
                        try {
                            if (promoCodeValid && promoCodeDiscount === 50) {
                                // Create subscription with the discounted plan
                                console.log("Creating subscription with 50% off first month plan");
                                return actions.subscription.create({
                                    'plan_id': 'P-5TY05556WW059640XM7OCM4I', // $19.99 plan ID
                                    'auto_renewal': true,
                                    'application_context': {
                                        'user_action': 'CONTINUE'
                                    }
                                });
                            } else {
                                // No promo code, use regular plan
                                console.log("Creating subscription with regular plan");
                                return actions.subscription.create({
                                    'plan_id': 'P-3GM697692S4597422M7NQC2Y' // Regular $39.99 plan ID
                                });
                            }
                        } catch (error) {
                            console.error("Error in createSubscription:", error);
                            setError("Failed to create subscription. Please try again.");
                            throw error;
                        }
                    },
                    onApprove: async (data, actions) => {
                        console.log("Subscription approved:", data);
                        
                        if (promoCodeValid && promoCodeDiscount === 50) {
                            try {
                                // After successful subscription creation, make an API call to your backend
                                // to schedule the plan switch after the first month
                                console.log("Scheduling plan switch for subscription:", data.subscriptionID);
                                
                                // Store the plan switch information in your database
                                const planSwitchData = {
                                    subscriptionId: data.subscriptionID,
                                    currentPlanId: 'P-5TY05556WW059640XM7OCM4I',
                                    nextPlanId: 'P-3GM697692S4597422M7NQC2Y',
                                    switchDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // 30 days from now
                                };
                                
                                // You'll need to implement this function to store the plan switch data
                                await storePlanSwitchData(planSwitchData);
                            } catch (error) {
                                console.error("Error scheduling plan switch:", error);
                                // Continue with subscription success even if plan switch scheduling fails
                                // Your backend can handle the switch later
                            }
                        }
                        
                        updateActivity(); // Update activity when approved
                        onSuccess(data.subscriptionID);
                    },
                    onError: (err) => {
                        console.error("PayPal error:", err);
                        setError(err.message || "Payment failed. Please try again.");
                        setPaymentInProgress(false);
                        if (onError) {
                            onError(err.message || "Payment failed. Please try again.");
                        }
                    },
                    onCancel: () => {
                        console.log("Payment cancelled by user");
                        setPaymentInProgress(false);
                        if (onCancel) {
                            onCancel();
                        }
                    }
                });
                
                if (!buttons.isEligible()) {
                    console.error("PayPal Buttons not eligible for rendering");
                    throw new Error("PayPal Buttons not eligible for rendering");
                }
                
                buttons.render(containerRef.current)
                    .then(() => {
                        console.log("PayPal button rendered successfully");
                        setError(null);
                        setButtonsRendered(true);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error("Error rendering PayPal button:", err);
                        setError("Failed to initialize payment options");
                        setButtonsRendered(false);
                        setLoading(false);
                    });
            } catch (err) {
                console.error("Error in renderButton:", err);
                setError("Failed to initialize payment options");
                setButtonsRendered(false);
                setLoading(false);
            }
        };
        
        // Load the script
        loadPayPalScript();
        
        // Cleanup function
        return () => {
            if (scriptRef.current) {
                scriptRef.current.remove();
            }
            if (containerRef.current) {
                while (containerRef.current.firstChild) {
                    containerRef.current.removeChild(containerRef.current.firstChild);
                }
            }
        };
    }, [onSuccess, onError, onCancel]);

    // Setup global error handler for PayPal
    useEffect(() => {
        const cleanupErrorHandler = setupGlobalErrorHandler();
        return cleanupErrorHandler;
    }, []);

    return (
        <div className="paypal-button-container">
            <div ref={containerRef} className={buttonsRendered ? "mt-4" : ""}></div>
            {loading && !buttonsRendered && (
                <div className="text-center my-4">
                    <Spinner animation="border" variant="primary" />
                    <p className="mt-2">Loading payment options...</p>
                </div>
            )}
            {error && !buttonsRendered && (
                <Alert variant="danger" className="my-3">
                    {typeof error === 'string' ? error : 'An error occurred during payment. Please try again.'}
                </Alert>
            )}
            {cancelMessage && (
                <Alert variant="info" className="my-3">
                    <i className="fas fa-info-circle me-2"></i>
                    {cancelMessage}
                </Alert>
            )}
        </div>
    );
};

// Traditional PayPal Subscribe Button component
const TraditionalPayPalButton = () => {
    // Reference to track if the form was submitted
    const [paymentInitiated, setPaymentInitiated] = useState(false);
    const formRef = useRef(null);
    
    // Handle when a window is closed after being opened by this component
    useEffect(() => {
        const handleBlur = () => {
            if (paymentInitiated) {
                // Reset after a delay to give time for redirects to complete
                setTimeout(() => {
                    setPaymentInitiated(false);
                }, 1000);
            }
        };
        
        // Add event listeners for when a user returns to the tab
        window.addEventListener('focus', handleBlur);
        
        return () => {
            window.removeEventListener('focus', handleBlur);
        };
    }, [paymentInitiated]);
    
    // Handle form submission
    const handleSubmit = (e) => {
        setPaymentInitiated(true);
    };
    
    return (
        <div className="traditional-paypal-button">
            <form 
                ref={formRef}
                action="https://www.paypal.com/cgi-bin/webscr" 
                method="post" 
                target="_blank"
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="FSLL534TYCZPA" />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="hidden" name="return" value={window.location.origin + "/?tab=profile&paymentSuccess=true"} />
                <input type="hidden" name="cancel_return" value={window.location.origin + "/checkout?paymentCancelled=true"} />
                <input 
                    type="image" 
                    src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif" 
                    border="0" 
                    name="submit" 
                    title="PayPal - The safer, easier way to pay online!" 
                    alt="Subscribe" 
                    className="paypal-subscribe-button"
                />
            </form>
            {paymentInitiated && (
                <div className="payment-in-progress">
                    <p className="small text-muted mt-1">
                        <i className="fas fa-circle-notch fa-spin me-1"></i>
                        Payment processing in new tab...
                    </p>
                </div>
            )}
        </div>
    );
};

// Error boundary component to catch and handle any uncaught errors
class PaymentErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: null };
    }
    
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI
        return { hasError: true };
    }
    
    componentDidCatch(error, errorInfo) {
        // Log the error to console
        console.error("Payment Error Boundary caught an error:", error, errorInfo);
        this.setState({ errorInfo });
    }
    
    render() {
        if (this.state.hasError) {
            // Render fallback UI
            return (
                <div className="error-boundary-fallback">
                    <Alert variant="danger">
                        <h5>Payment System Error</h5>
                        <p>There was a problem with the payment system. Please try again later.</p>
                        <Button 
                            variant="outline-light" 
                            size="sm" 
                            onClick={() => this.setState({ hasError: false })}
                            className="mt-2"
                        >
                            Try Again
                        </Button>
                    </Alert>
                </div>
            );
        }
        
        return this.props.children;
    }
}

const storePlanSwitchData = async (planSwitchData) => {
    try {
        const planSwitchesRef = collection(firestore, 'planSwitches');
        await addDoc(planSwitchesRef, {
            ...planSwitchData,
            createdAt: new Date().toISOString(),
            status: 'pending'
        });
        console.log("Plan switch data stored successfully");
    } catch (error) {
        console.error("Error storing plan switch data:", error);
        throw error;
    }
};

const Checkout = () => {
    const { user, setUserSubscription, userSubscription, hasPaidSubscription, hasExpiredTrial } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [plan] = useState(location.state?.selectedPlan || 'premium');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [unauthorized, setUnauthorized] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeValid, setPromoCodeValid] = useState(false);
    const [promoCodeDiscount, setPromoCodeDiscount] = useState(0);
    const [validatingPromoCode, setValidatingPromoCode] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [paymentCancelled, setPaymentCancelled] = useState(false);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [promoCodeResult, setPromoCodeResult] = useState(null);

    // Define the beforeunload handler
    const handleBeforeUnload = (e) => {
        if (paymentInProgress) {
            // For modern browsers
            e.preventDefault();
            // For older browsers
            const message = "Payment in progress. Are you sure you want to leave?";
            e.returnValue = message;
            return message;
        }
    };

    // Add beforeunload handler to warn about leaving during checkout
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [paymentInProgress]);

    // If not, this might be a direct navigation which we want to prevent
    useEffect(() => {
        if (!user) {
            console.log("No user in checkout, redirecting to home");
            navigate("/");
            return;
        }
        
        console.log("User is present in checkout:", user.uid);
        
        // Store user ID in localStorage for potential promo code tracking
        try {
            localStorage.setItem('userId', user.uid);
        } catch (e) {
            console.warn('Unable to store user ID in localStorage:', e);
        }
        
        // Check if user already has an active subscription
        // But allow users with expired trials to purchase
        if (userSubscription && 
            userSubscription.status === 'active' && 
            !hasExpiredTrial()) {
            console.log("User already has an active subscription, redirecting to home");
            setError("You already have an active subscription.");
            setUnauthorized(true);
            return;
        }
        
        // Check if user came from plans selection (has state)
        if (!location.state) {
            console.log("Direct navigation to checkout detected, redirecting to plans");
            
            // Custom message for users with expired trials
            if (hasExpiredTrial()) {
                setError("Your trial has expired. Please select a plan to continue with subscription.");
            } else {
                setError("Please select a plan first before proceeding to checkout.");
            }
            
            setUnauthorized(true);
            return;
        }
    }, [user, navigate, location, userSubscription, hasExpiredTrial]);

    // Get plan info from location state
    const planInfo = location.state || { planId: 'premium-trial', price: 39.99 };
    
    // Set loading to false after a short delay to allow UI to render
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        
        return () => clearTimeout(timer);
    }, []);

    // Check URL parameters for payment cancellation
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('paymentCancelled') === 'true') {
            setPaymentCancelled(true);
            // Remove the parameter from the URL without page reload
            const url = new URL(window.location);
            url.searchParams.delete('paymentCancelled');
            window.history.replaceState({}, '', url);
            
            // Auto-hide the message after 8 seconds
            const timer = setTimeout(() => {
                setPaymentCancelled(false);
            }, 8000);
            
            return () => clearTimeout(timer);
        }
    }, [location]);

    // Handle promo code validation
    const handleValidatePromoCode = async (e) => {
        e.preventDefault();
        
        if (!promoCode.trim()) {
            setPromoCodeResult({
                valid: false,
                message: 'Please enter a promo code'
            });
            return;
        }
        
        setValidatingPromoCode(true);
        
        try {
            // Use the proper validation utility to check the promo code
            const validationResult = await validatePromoCode(promoCode.trim());
            
            setPromoCodeResult(validationResult);
            setPromoCodeValid(validationResult.valid);
            
            if (validationResult.valid) {
                // Update the discount percentage for price calculations
                setPromoCodeDiscount(validationResult.discountPercentage);
                
                // Extra logging for debugging
                console.log(`Promo code ${promoCode} successfully validated with ${validationResult.discountPercentage}% discount`);
            } else {
                // Reset discount if code is invalid
                setPromoCodeDiscount(0);
                console.log(`Promo code ${promoCode} validation failed: ${validationResult.message}`);
            }
        } catch (error) {
            console.error('Error validating promo code:', error);
            
            // Provide a more user-friendly error message
            let errorMessage = 'Error validating promo code';
            
            if (error.code === 'permission-denied') {
                errorMessage = 'We\'re having trouble validating your promo code. Please try again or contact support.';
            } else if (error.code === 'unavailable' || error.code === 'network-request-failed') {
                errorMessage = 'Network error. Please check your connection and try again.';
            }
            
            setPromoCodeResult({
                valid: false,
                message: errorMessage
            });
            setPromoCodeValid(false);
            setPromoCodeDiscount(0);
        } finally {
            setValidatingPromoCode(false);
        }
    };
    
    // Clear promo code
    const handleClearPromoCode = () => {
        setPromoCode('');
        setPromoCodeResult(null);
        setPromoCodeValid(false);
        setPromoCodeDiscount(0);
    };

    // Calculate discounted price
    const calculatePriceWithDiscount = () => {
        const basePrice = planInfo.price || 39.99;
        
        if (!promoCodeValid || promoCodeDiscount <= 0) {
            return {
                originalPrice: basePrice,
                discountedPrice: basePrice,
                discountAmount: 0,
                discountPercentage: 0
            };
        }
        
        // Use the utility function to calculate discount
        return calculateDiscountedPrice(basePrice, promoCodeDiscount);
    };

    // Handle successful subscription
    const handleSubscriptionSuccess = async (subscriptionId) => {
        setPaymentInProgress(false);
        try {
            const now = new Date();
            const trialEndDate = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000); // 3 days from now
            
            // Get price information with any applicable discount
            const priceInfo = calculatePriceWithDiscount();
            
            // Add subscription to user's Firestore document
            const userRef = doc(firestore, 'users', user.uid);
            const subscriptionData = {
                subscription: {
                    status: 'active',
                    type: 'premium',
                    subscriptionId: subscriptionId,
                    startDate: now.toISOString(),
                    trialEndDate: trialEndDate.toISOString(),
                    updatedAt: now.toISOString(),
                    amount: priceInfo.discountedPrice, // Store the discounted price
                    originalAmount: priceInfo.originalPrice, // Store the original price too
                    discountApplied: promoCodeValid ? promoCodeDiscount : 0,
                    currency: 'USD',
                    paymentMethod: 'paypal',
                    plan: planInfo.planId || 'premium-monthly-with-trial',
                    promoCode: promoCodeValid ? promoCode.trim() : null,
                    discountDetails: promoCodeValid ? {
                        code: promoCode.trim(),
                        percentage: promoCodeDiscount,
                        amountSaved: priceInfo.discountAmount,
                        appliedAt: now.toISOString()
                    } : null
                }
            };

            console.log("Updating user document with subscription data:", subscriptionData);
            await updateDoc(userRef, subscriptionData);
            console.log("User document updated successfully");
            
            // Create a payment record in the payments collection
            const paymentRef = collection(firestore, 'payments');
            const paymentData = {
                userId: user.uid,
                userEmail: user.email,
                date: now.toISOString(),
                amount: priceInfo.discountedPrice, // Store the discounted price
                originalAmount: priceInfo.originalPrice, // Store the original price too
                discountAmount: promoCodeValid ? priceInfo.discountAmount : 0,
                discountPercentage: promoCodeValid ? promoCodeDiscount : 0,
                currency: 'USD',
                status: 'completed',
                description: promoCodeValid 
                    ? `Premium Subscription with ${promoCodeDiscount}% discount (${promoCode})`
                    : 'Premium Subscription (standard rate)',
                paymentMethod: 'paypal',
                subscriptionId: subscriptionId,
                plan: planInfo.planId || 'premium-monthly-with-trial',
                promoCode: promoCodeValid ? promoCode.trim() : null,
                discountDetails: promoCodeValid ? {
                    code: promoCode.trim(),
                    percentage: promoCodeDiscount,
                    amountSaved: priceInfo.discountAmount,
                    appliedAt: now.toISOString()
                } : null,
                receiptNumber: `INV-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
                receiptUrl: null // Would be generated by a server-side function in production
            };
            
            await addDoc(paymentRef, paymentData);
            console.log("Payment record created successfully");
            
            // Record promo code usage if a valid code was used
            if (promoCodeValid && promoCodeResult?.promoCodeId) {
                try {
                    await recordPromoCodeUse(promoCodeResult.promoCodeId);
                    console.log("Promo code usage recorded");
                    
                    // Store successful promo code in localStorage
                    try {
                        const successfulPromoCodes = JSON.parse(localStorage.getItem('successfulPromoCodes') || '[]');
                        successfulPromoCodes.push({
                            code: promoCode,
                            promoCodeId: promoCodeResult.promoCodeId,
                            discountPercentage: promoCodeDiscount,
                            usedAt: now.toISOString(),
                            subscriptionId: subscriptionId,
                            amountSaved: priceInfo.discountAmount
                        });
                        localStorage.setItem('successfulPromoCodes', JSON.stringify(successfulPromoCodes));
                    } catch (storageError) {
                        console.warn('Error storing successful promo code in localStorage:', storageError);
                    }
                } catch (error) {
                    console.warn("Failed to record promo code usage, but continuing with subscription:", error);
                }
            }
            
            setUserSubscription(subscriptionData.subscription);
            
            // Send subscription confirmation email with discount information
            await sendPremiumSubscriptionConfirmation({
                email: user.email,
                displayName: user.displayName,
                subscription: subscriptionData.subscription,
                discountApplied: promoCodeValid ? {
                    code: promoCode,
                    percentage: promoCodeDiscount,
                    amountSaved: priceInfo.discountAmount,
                    finalPrice: priceInfo.discountedPrice
                } : null
            });
            console.log("Subscription confirmation email sent");
            
            // Show success message and redirect
            setError(null);
            console.log("Redirecting to stats tab after successful subscription");
            navigate('/?tab=stats');
        } catch (error) {
            console.error('Error updating subscription in database:', error);
            setError('Your subscription was created, but we had trouble updating your account. Please contact support.');
        }
    };

    // Handle error in subscription process
    const handleSubscriptionError = (errorMessage) => {
        setPaymentInProgress(false);
        setError(errorMessage);
    };

    // Handle cancel subscription
    const handleSubscriptionCancel = () => {
        // Reset any errors
        setError(null);
        setPaymentInProgress(false);
        // Show cancellation message
        setPaymentCancelled(true);
        
        // Auto-hide the message after 8 seconds
        setTimeout(() => {
            setPaymentCancelled(false);
        }, 8000);
    };
    
    // Handle payment initiation
    const handlePaymentInitiation = () => {
        setPaymentInProgress(true);
        setPaymentCancelled(false);
        setError(null);
    };
    
    // Handle going back to plans
    const handleBackToPlans = () => {
        // Only show confirmation if payment is in progress
        if (paymentInProgress) {
            const confirmed = window.confirm("A payment process might be in progress. Are you sure you want to go back?");
            if (!confirmed) {
                return;
            }
        }
        navigate('/plans');
    };

    // If unauthorized, show a message
    if (unauthorized) {
        return (
            <Container className="checkout-container text-center">
                <Alert variant="warning" className="my-5">
                    <h4>Access Restricted</h4>
                    <p>{error}</p>
                    <Button 
                        variant="primary" 
                        onClick={() => navigate('/plans')}
                        className="mt-3"
                    >
                        View Plans
                    </Button>
                </Alert>
            </Container>
        );
    }

    if (!user) {
        console.log("No user, returning null");
        return null;
    }

    return (
        <Container className="checkout-container">
            <div className="checkout-header">
                <Button 
                    variant="outline-secondary" 
                    className="back-button mb-3"
                    onClick={handleBackToPlans}
                >
                    <i className="fas fa-arrow-left me-2"></i> Back to Plans
                </Button>
                <h2>Complete Your Subscription</h2>
                <p>You're subscribing to the {planInfo.name || 'Premium'} plan</p>
            </div>
            
            {paymentCancelled && (
                <Alert variant="info" className="my-3">
                    <i className="fas fa-info-circle me-2"></i>
                    Payment was cancelled. You can continue shopping or try again when you're ready.
                </Alert>
            )}
            
            {/* Promo Code Section */}
            <div className="promo-code-section">
                <h4>Have a Promo Code?</h4>
                <p className="text-muted">Enter your code below to receive a discount</p>
                <Form onSubmit={handleValidatePromoCode}>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Enter promo code"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                            disabled={validatingPromoCode || promoCodeValid}
                            className={promoCodeValid ? "border-success" : ""}
                        />
                        {promoCode && !promoCodeValid && (
                            <Button 
                                variant="outline-secondary"
                                onClick={handleClearPromoCode}
                                disabled={validatingPromoCode}
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        )}
                        {promoCodeValid ? (
                            <Button 
                                variant="outline-success" 
                                onClick={handleClearPromoCode}
                            >
                                <i className="fas fa-check me-1"></i> Applied
                            </Button>
                        ) : (
                            <Button 
                                variant="primary" 
                                type="submit"
                                disabled={validatingPromoCode || !promoCode.trim()}
                            >
                                {validatingPromoCode ? (
                                    <>
                                        <Spinner animation="border" size="sm" />
                                        <span className="ms-2">Verifying</span>
                                    </>
                                ) : (
                                    'Apply'
                                )}
                            </Button>
                        )}
                    </InputGroup>
                    
                    {promoCodeResult && !promoCodeValid && (
                        <Alert variant="danger">
                            <i className="fas fa-exclamation-circle me-2"></i>
                            {promoCodeResult.message}
                        </Alert>
                    )}
                    
                    {promoCodeValid && (
                        <Alert variant="success">
                            <i className="fas fa-check-circle me-2"></i>
                            {promoCodeResult.message}
                        </Alert>
                    )}
                </Form>
            </div>
            
            {/* Price Summary */}
            <div className="price-summary">
                <h4>Order Summary</h4>
                <div className="price-details">
                    {/* Calculate the price values */}
                    {(() => {
                        const priceInfo = calculatePriceWithDiscount();
                        return (
                            <>
                                <div className="price-row">
                                    <span>Subscription Price:</span>
                                    <span>${priceInfo.originalPrice.toFixed(2)}/month</span>
                                </div>
                                
                                {promoCodeValid && promoCodeDiscount > 0 && (
                                    <div className="price-row discount">
                                        <span>First Month Discount ({promoCodeDiscount}%):</span>
                                        <span className="text-success">-${priceInfo.discountAmount.toFixed(2)}</span>
                                    </div>
                                )}
                                
                                <div className="price-row total">
                                    <span>Total:</span>
                                    <div className="d-flex flex-column align-items-end">
                                        {promoCodeValid && promoCodeDiscount > 0 ? (
                                            <>
                                                <span className="text-success">${priceInfo.discountedPrice.toFixed(2)} first month</span>
                                                <small className="text-muted">Then ${priceInfo.originalPrice.toFixed(2)}/month</small>
                                            </>
                                        ) : (
                                            <span>${priceInfo.discountedPrice.toFixed(2)}/month</span>
                                        )}
                                    </div>
                                </div>
                                
                                {promoCodeValid && promoCodeDiscount > 0 && (
                                    <div className="price-row savings small text-muted mt-2">
                                        <span>You save:</span>
                                        <span>${priceInfo.discountAmount.toFixed(2)} on your first month</span>
                                    </div>
                                )}
                            </>
                        );
                    })()}
                </div>
            </div>
            
            <div className="trial-notice">
                <i className="fas fa-info-circle"></i>
                <p>Your subscription includes a 3-day free trial. You won't be charged until the trial period ends.</p>
            </div>
            
            {error && (
                <Alert variant="danger" className="mt-3">
                    {error}
                </Alert>
            )}
            
            {/* Payment Options */}
            <div className="payment-options">
                <h4>Payment Method</h4>
                <p className="text-muted">Secure payment via PayPal</p>
                
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="payment-card">
                            <div className="payment-card-header">
                                <h5>PayPal Subscription</h5>
                                <img 
                                    src="/assets/paypal-logo.png" 
                                    alt="PayPal"
                                    className="payment-method-logo"
                                    onError={(e) => {
                                        e.target.src = 'https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg';
                                        e.target.onerror = null;
                                    }}
                                />
                            </div>
                            <div className="payment-card-body">
                                <p>Pay securely via PayPal. You can use your PayPal account or credit card.</p>
                                
                                <div className="paypal-button-wrapper">
                                    {loading ? (
                                        <div className="text-center mb-4">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            <p className="mt-2">Preparing checkout...</p>
                                        </div>
                                    ) : (
                                        <PaymentErrorBoundary>
                                            <PayPalHostedButton
                                                onSuccess={handleSubscriptionSuccess}
                                                onError={handleSubscriptionError}
                                                onCancel={handleSubscriptionCancel}
                                                user={user}
                                                promoCode={promoCode}
                                                promoCodeValid={promoCodeValid}
                                                promoCodeDiscount={promoCodeDiscount}
                                                calculatePriceWithDiscount={calculatePriceWithDiscount}
                                            />
                                        </PaymentErrorBoundary>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 mb-4">
                        <div className="payment-info-card">
                            <div className="payment-info-header">
                                <h5>Subscription Details</h5>
                            </div>
                            <div className="payment-info-body">
                                <div className="subscription-detail">
                                    <span className="detail-label">Type:</span>
                                    <span className="detail-value">Monthly Subscription</span>
                                </div>
                                <div className="subscription-detail">
                                    <span className="detail-label">First Billing:</span>
                                    <span className="detail-value">Today</span>
                                </div>
                                <div className="subscription-detail">
                                    <span className="detail-label">Trial Period:</span>
                                    <span className="detail-value">3 Days Free</span>
                                </div>
                                <div className="subscription-detail">
                                    <span className="detail-label">Renewal Date:</span>
                                    <span className="detail-value">Monthly after trial</span>
                                </div>
                                <div className="subscription-detail">
                                    <span className="detail-label">Can Cancel:</span>
                                    <span className="detail-value">Anytime</span>
                                </div>
                                
                                <div className="payment-notice mt-4">
                                    <i className="fas fa-info-circle me-2"></i>
                                    <span>After completing payment with PayPal, please check your profile page to confirm your subscription is active. If your subscription doesn't appear active within 5 minutes, please contact support.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="checkout-footer">
                <p>By subscribing, you agree to our <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.</p>
                <p>Need help? <a href="/support">Contact our support team</a>.</p>
            </div>
        </Container>
    );
};

export default Checkout; 
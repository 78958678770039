import React, { createContext, useState, useContext, useEffect } from 'react';
import { API_BASE_URL } from '../config/constants';

export const CSRFContext = createContext({});

export const CSRFProvider = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState(null);

    const fetchCSRFToken = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/csrf-token`, {
                // Don't use credentials mode for CSRF token to avoid CORS issues
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) throw new Error('Failed to fetch CSRF token');
            const data = await response.json();
            setCsrfToken(data.csrfToken);
        } catch (error) {
            console.error('Failed to fetch CSRF token:', error);
        }
    };

    useEffect(() => {
        fetchCSRFToken();
    }, []);

    return (
        <CSRFContext.Provider value={{ csrfToken, refreshToken: fetchCSRFToken }}>
            {children}
        </CSRFContext.Provider>
    );
}; 
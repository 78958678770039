// API Configuration
// This setup adapts to different deployment scenarios
// 1. Use environment variable if available (for customized deployments)
// 2. Use localhost for development
// 3. Try the original production URL as fallback
export const API_BASE_URL = process.env.REACT_APP_API_URL || '/api'; // Always use relative path by default

// Known API endpoints for fallback
export const KNOWN_API_ENDPOINTS = {
    production: 'https://nba-stats-api-928923237285.us-central1.run.app',
    backup: '/api', // Relative path if API is deployed at same domain under /api
    local: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : null
};

// NBA API off-hours configuration
export const NBA_API_OFF_HOURS = {
    startHour: 1, // 1am EST
    endHour: 6,   // 6am EST
    timezone: 'America/New_York' // EST timezone
};

/**
 * Checks if the current time is within the NBA API off-hours period (1am-6am EST)
 * when API calls should be avoided to save quota since odds are not typically updated
 * during this period
 * 
 * @returns {boolean} True if current time is within the off-hours period
 */
export const isNbaApiOffHours = () => {
    // Get current time in EST
    const now = new Date().toLocaleString('en-US', { timeZone: NBA_API_OFF_HOURS.timezone });
    const estTime = new Date(now);
    const currentHour = estTime.getHours();
    
    // Check if current hour is within off-hours period
    return currentHour >= NBA_API_OFF_HOURS.startHour && currentHour < NBA_API_OFF_HOURS.endHour;
};

// Log API configuration without sensitive information
console.log('API Configuration:', {
    environment: process.env.NODE_ENV,
    baseUrl: API_BASE_URL,
    envVarPresent: !!process.env.REACT_APP_API_URL,
    availableEndpoints: Object.keys(KNOWN_API_ENDPOINTS).filter(key => KNOWN_API_ENDPOINTS[key])
});

// Firebase configuration using environment variables
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Add RapidAPI configuration
export const RAPID_API_CONFIG = {
    BASE_URL: 'https://basketball-head.p.rapidapi.com',
    API_HOST: process.env.RAPID_API_HOST || 'basketball-head.p.rapidapi.com',
    API_KEY: process.env.REACT_APP_RAPID_API_KEY
};

// Log config status without exposing sensitive information
console.log('API Config status:', {
    baseUrl: RAPID_API_CONFIG.BASE_URL,
    host: RAPID_API_CONFIG.API_HOST,
    keyPresent: !!RAPID_API_CONFIG.API_KEY,
    keyLength: RAPID_API_CONFIG.API_KEY ? RAPID_API_CONFIG.API_KEY.length : 0
}); 
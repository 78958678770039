import React from 'react';
import { Modal } from 'react-bootstrap';
import './MoneylineProbabilityModal.css';  // We can reuse the same styles

const TotalProbabilityModal = ({ show, onHide, total, type, odds }) => {
    const calculateProbability = (odds) => {
        if (!odds) return 0;
        
        // Convert American odds to probability
        if (odds > 0) {
            return (100 / (odds + 100)) * 100;
        } else {
            return (Math.abs(odds) / (Math.abs(odds) + 100)) * 100;
        }
    };

    const calculateTotalProbability = (odds, point, type) => {
        if (!odds || !point) return 0;
        
        const baseProb = calculateProbability(odds);
        const totalFactor = point * 0.005; // 0.5% adjustment per point
        
        return type === 'over'
            ? baseProb * (1 - totalFactor)
            : baseProb * (1 + totalFactor);
    };

    const probability = calculateTotalProbability(odds, total, type);

    return (
        <Modal show={show} onHide={onHide} centered className="probability-modal">
            <Modal.Header closeButton>
                <Modal.Title>Total Probability</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="probability-container">
                    <div className="team-probability">
                        <h5>{type === 'over' ? 'Over' : 'Under'} {total}</h5>
                        <div className="prob-details">
                            <div className="prob-row">
                                <span>Base Probability:</span>
                                <span>{calculateProbability(odds).toFixed(1)}%</span>
                            </div>
                            <div className="prob-row">
                                <span>Adjusted Probability:</span>
                                <span>{probability.toFixed(1)}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TotalProbabilityModal; 
import React from 'react';
import { Modal } from 'react-bootstrap';
import './MoneylineProbabilityModal.css';  // We can reuse the same styles

const SpreadProbabilityModal = ({ show, onHide, team, spread, odds }) => {
    const calculateProbability = (odds) => {
        if (!odds) return 0;
        
        // Convert American odds to probability
        if (odds > 0) {
            return (100 / (odds + 100)) * 100;
        } else {
            return (Math.abs(odds) / (Math.abs(odds) + 100)) * 100;
        }
    };

    const calculateSpreadProbability = (odds, spread) => {
        if (!odds || !spread) return 0;
        
        const baseProb = calculateProbability(odds);
        // Adjust probability based on spread
        const spreadFactor = Math.abs(spread) * 0.02; // 2% adjustment per point
        
        return spread > 0 
            ? baseProb * (1 + spreadFactor)  // Underdog adjustment
            : baseProb * (1 - spreadFactor); // Favorite adjustment
    };

    const probability = calculateSpreadProbability(odds, spread);

    return (
        <Modal show={show} onHide={onHide} centered className="probability-modal">
            <Modal.Header closeButton>
                <Modal.Title>Spread Probability</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="probability-container">
                    <div className="team-probability">
                        <h5>{team} {spread > 0 ? '+' : ''}{spread}</h5>
                        <div className="prob-details">
                            <div className="prob-row">
                                <span>Base Probability:</span>
                                <span>{calculateProbability(odds).toFixed(1)}%</span>
                            </div>
                            <div className="prob-row">
                                <span>Adjusted for Spread:</span>
                                <span>{probability.toFixed(1)}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SpreadProbabilityModal; 
import React, { useState, useEffect } from 'react';
import { Container, Table, Alert, Card, Button, Spinner, Badge } from 'react-bootstrap';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { API_BASE_URL } from '../config/constants';
import { generateReceipt, formatCurrency as formatCurrencyUtil } from '../utils/pdfUtils';
import './UserDashboard.css';
import { useNavigate } from 'react-router-dom';

const UserDashboard = () => {
    const { user, userSubscription } = useAuth();
    const [payments, setPayments] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [generatingReceipt, setGeneratingReceipt] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        
        const loadDashboardData = async () => {
            setLoading(true);
            
            try {
                if (user) {
                    // Start both fetch operations in parallel
                    const subscriptionPromise = fetchSubscriptionDetails().catch(err => {
                        console.error("Subscription details fetch failed:", err);
                        return null; // Return null on error
                    });
                    
                    const paymentsPromise = fetchPaymentHistory().catch(err => {
                        console.error("Payment history fetch failed:", err);
                        return []; // Return empty array on error
                    });
                    
                    // Wait for both to complete (or fail gracefully)
                    await Promise.allSettled([subscriptionPromise, paymentsPromise]);
                }
            } catch (err) {
                console.error("Dashboard data loading error:", err);
                // Don't set global error - component-specific errors are handled in their respective functions
            } finally {
                // Only update state if component is still mounted
                if (isMounted) {
                    setLoading(false);
                }
            }
        };
        
        loadDashboardData();
        
        // Cleanup function to prevent state updates on unmounted component
        return () => {
            isMounted = false;
        };
    }, [user]);

    // Fetch the user's subscription details from Firestore
    const fetchSubscriptionDetails = async () => {
        try {
            const userRef = doc(firestore, 'users', user.uid);
            const userDoc = await getDoc(userRef);
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setSubscriptionDetails(userData.subscription || null);
                return userData.subscription;
            }
            return null;
        } catch (err) {
            console.error('Error fetching subscription details:', err);
            // Don't show error to user, just log it and continue with null subscription
            return null;
        }
    };

    // Fetch payment history from Firestore
    const fetchPaymentHistory = async () => {
        try {
            if (!user) return [];
            
            // Use try-catch for each Firestore operation to handle permission errors gracefully
            try {
                const paymentsRef = collection(firestore, 'payments');
                const q = query(
                    paymentsRef,
                    where('userId', '==', user.uid),
                    orderBy('timestamp', 'desc')
                );
                
                const querySnapshot = await getDocs(q);
                const payments = [];
                
                querySnapshot.forEach((doc) => {
                    payments.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                
                setPayments(payments);
                return payments;
            } catch (permissionError) {
                console.warn("Permission error fetching payments:", permissionError);
                // Fall back to using subscription data if available
                return fallbackToSubscriptionData();
            }
        } catch (error) {
            console.error("Error in payment history process:", error);
            // Don't show error to user, just set empty payments
            setPayments([]);
            return [];
        }
    };
    
    // Fallback method when payment collection access fails
    const fallbackToSubscriptionData = () => {
        // Only create synthetic payment if we have subscription data
        if (userSubscription) {
            try {
                // Only add synthetic record if subscription was paid (not a free trial)
                if (userSubscription.plan && userSubscription.plan !== 'free' && 
                    userSubscription.amount && !isNaN(parseFloat(userSubscription.amount))) {
                    const syntheticPayment = {
                        id: 'initial-subscription',
                        amount: parseFloat(userSubscription.amount) || 0,
                        currency: userSubscription.currency || 'USD',
                        timestamp: userSubscription.startDate,
                        description: `Initial ${userSubscription.plan} subscription`,
                        status: userSubscription.status
                    };
                    setPayments([syntheticPayment]);
                    return [syntheticPayment];
                } else {
                    // Free trial or no payment info
                    setPayments([]);
                    return [];
                }
            } catch (err) {
                console.warn("Error creating fallback payment data:", err);
                setPayments([]);
                return [];
            }
        } else {
            setPayments([]);
            return [];
        }
    };

    // Format date to a friendly string
    const formatDate = (timestamp) => {
        if (!timestamp) return 'Unknown';
        
        try {
            const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
            return date.toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            });
        } catch (err) {
            console.error("Error formatting date:", err);
            return 'Invalid date';
        }
    };

    // Format currency with proper symbol - using the utility function
    const formatCurrency = (amount, currency = 'USD') => {
        if (amount === undefined || amount === null || isNaN(amount)) {
            return '$0.00';
        }
        
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
        }).format(amount);
    };

    // Generate and download a receipt
    const handleDownloadReceipt = async (payment) => {
        try {
            setGeneratingReceipt(true);
            
            // Validate payment data before attempting to generate receipt
            if (!payment || typeof payment !== 'object') {
                throw new Error('Invalid payment data');
            }
            
            // Ensure required payment fields exist or provide defaults
            const safePayment = {
                id: payment.id || `payment-${Date.now()}`,
                amount: payment.amount || 0,
                currency: payment.currency || 'USD',
                timestamp: payment.timestamp || new Date(),
                description: payment.description || 'Subscription Payment',
                status: payment.status || 'completed',
                receiptNumber: payment.receiptNumber || `RCPT-${Date.now()}-${Math.floor(Math.random() * 10000)}`
            };
            
            // Get user data for the receipt
            const userData = {
                uid: user?.uid || 'unknown',
                email: user?.email || 'unknown@example.com',
                displayName: user?.displayName || 'Customer'
            };
            
            // Generate the PDF
            const doc = generateReceipt(safePayment, userData);
            
            // Generate a filename
            const fileName = `receipt-${safePayment.receiptNumber}.pdf`;
            
            // Download the PDF
            doc.save(fileName);
            
        } catch (err) {
            console.error('Error generating receipt:', err);
            alert('Could not generate receipt. Please try again later.');
        } finally {
            setGeneratingReceipt(false);
        }
    };

    // Get a badge variant based on payment status
    const getStatusBadge = (status) => {
        if (!status) return <Badge bg="secondary">Unknown</Badge>;
        
        const statusMap = {
            'active': { bg: 'success', text: 'Active' },
            'trialing': { bg: 'info', text: 'Trial' },
            'trial': { bg: 'info', text: 'Trial' },
            'past_due': { bg: 'warning', text: 'Past Due' },
            'canceled': { bg: 'secondary', text: 'Canceled' },
            'unpaid': { bg: 'warning', text: 'Unpaid' },
            'incomplete': { bg: 'warning', text: 'Incomplete' },
            'incomplete_expired': { bg: 'danger', text: 'Expired' },
            'expired': { bg: 'danger', text: 'Expired' },
            'failed': { bg: 'danger', text: 'Failed' }
        };
        
        const statusInfo = statusMap[status.toLowerCase()] || { bg: 'secondary', text: status };
        
        return <Badge bg={statusInfo.bg}>{statusInfo.text}</Badge>;
    };

    if (loading) return (
        <div className="text-center my-5">
            <Spinner animation="border" variant="primary" />
            <p className="mt-2">Loading your dashboard...</p>
        </div>
    );

    if (!user) {
        return (
            <Container className="mt-4">
                <Alert variant="warning">
                    Please sign in to view your dashboard.
                </Alert>
            </Container>
        );
    }

    return (
        <Container className="dashboard-container my-4">
            <h2 className="mb-4">Your Dashboard</h2>
            
            {error && (
                <Alert variant="danger" dismissible onClose={() => setError(null)}>
                    {error}
                </Alert>
            )}
            
            <Card className="mb-4">
                <Card.Header>
                    <h3>Subscription Overview</h3>
                </Card.Header>
                <Card.Body>
                    {subscriptionDetails ? (
                        <div className="subscription-details">
                            <p><strong>Status:</strong> {getStatusBadge(subscriptionDetails.status)}</p>
                            <p><strong>Plan:</strong> {subscriptionDetails.plan || 'Unknown'}</p>
                            <p><strong>Started:</strong> {formatDate(subscriptionDetails.startDate)}</p>
                            
                            {subscriptionDetails.trialEndDate && (
                                <p>
                                    <strong>Trial Ends:</strong> {formatDate(subscriptionDetails.trialEndDate)}
                                    {subscriptionDetails.status === 'expired' && (
                                        <Badge bg="danger" className="ms-2">Expired</Badge>
                                    )}
                                </p>
                            )}
                            
                            {/* Only show amount if it's a valid number */}
                            {subscriptionDetails.amount && !isNaN(parseFloat(subscriptionDetails.amount)) && (
                                <p>
                                    <strong>Amount:</strong> 
                                    {formatCurrency(
                                        parseFloat(subscriptionDetails.amount), 
                                        subscriptionDetails.currency
                                    )} / month
                                </p>
                            )}
                            
                            {/* Show upgrade message for expired trials */}
                            {subscriptionDetails.status === 'expired' && (
                                <div className="mt-3 p-3 bg-dark border border-warning rounded">
                                    <p className="mb-2">Your trial subscription has expired.</p>
                                    <Button 
                                        variant="warning" 
                                        size="sm"
                                        onClick={() => navigate('/plans')}
                                    >
                                        Upgrade to Premium
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className="text-muted">No active subscription found.</p>
                    )}
                </Card.Body>
            </Card>
            
            <Card>
                <Card.Header>
                    <h3>Payment History</h3>
                </Card.Header>
                <Card.Body>
                    {generatingReceipt && (
                        <div className="text-center mb-3">
                            <Spinner animation="border" size="sm" />
                            <span className="ms-2">Generating receipt...</span>
                        </div>
                    )}
                    
                    {loading ? (
                        <div className="text-center py-3">
                            <Spinner animation="border" size="sm" variant="primary" />
                            <span className="ms-2">Loading payment history...</span>
                        </div>
                    ) : payments.length === 0 ? (
                        <div className="empty-payment-container">
                            <p className="text-muted">
                                {user?.isAdmin ? 
                                    "No payment records found. Payment data may be restricted due to permissions." :
                                    "No payment records found"}
                            </p>
                            <Table striped bordered hover variant="dark" className="empty-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Receipt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="5" className="text-center text-muted">
                                            {subscriptionDetails?.status === 'trial' || subscriptionDetails?.status === 'trialing' ?
                                                "No payment history available - you're currently on a trial" :
                                                "No payment history available"}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    ) : (
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Receipt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments.map((payment, index) => (
                                    <tr key={payment.id || index}>
                                        <td>{formatDate(payment.timestamp)}</td>
                                        <td>{payment.description || `${payment.plan} Subscription`}</td>
                                        <td>{formatCurrency(payment.amount, payment.currency)}</td>
                                        <td>{getStatusBadge(payment.status)}</td>
                                        <td>
                                            <Button 
                                                variant="outline-primary" 
                                                size="sm"
                                                onClick={() => handleDownloadReceipt(payment)}
                                                disabled={generatingReceipt || !payment.amount}
                                            >
                                                {generatingReceipt ? (
                                                    <>
                                                        <Spinner 
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="me-1"
                                                        />
                                                        Generating...
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="bi bi-download me-1"></i>
                                                        Receipt
                                                    </>
                                                )}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default UserDashboard; 
import emailjs from '@emailjs/browser';

// EmailJS configuration using the same values as in other components
const EMAILJS_SERVICE_ID = 'service_q47p57p';
const EMAILJS_TEMPLATE_ID = 'template_da14ql6'; // Keep for admin notifications only
// Add new template ID for bulk emails
const EMAILJS_BULK_TEMPLATE_ID = 'template_2zjmece'; // Replace with your actual bulk email template ID
// Add new template ID for user communications
const EMAILJS_USER_TEMPLATE_ID = 'template_p5p2fcy'; // Replace with your actual user communication template ID
const EMAILJS_PUBLIC_KEY = 'CEdz41fV7CQ6WGhes';

// Initialize EmailJS
export const initEmailJS = () => {
  try {
    emailjs.init(EMAILJS_PUBLIC_KEY);
    console.log('EmailJS initialized successfully in emailUtils');
  } catch (error) {
    console.error('EmailJS initialization error:', error?.name);
  }
};

/**
 * Send an email notification about a new user registration
 * @param {Object} userData - User data including email
 * @returns {Promise} - Promise from emailjs.send
 */
export const sendNewUserNotification = async (userData) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID, // Keep using admin template for admin notifications
      {
        from_name: 'ClutchSports NBA Stats Hub',
        from_email: 'noreply@clutchsports.com',
        subject: 'New User Registration Alert',
        message: `A new user has registered on ClutchSports NBA Stats Hub!\n\nUser Email: ${userData.email}\nRegistration Time: ${new Date().toLocaleString()}\n\nThis is an automated notification.`
      }
    );
    
    console.log('New user notification email sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending new user notification email:', error);
    // Don't throw the error - we don't want to interrupt the signup process
    // if the notification email fails
    return null;
  }
};

/**
 * Send an email notification when API requests are running low
 * @param {Object} apiData - Data about API usage including remaining requests
 * @returns {Promise} - Promise from emailjs.send
 */
export const sendApiLimitAlert = async (apiData) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    // Get the latest API requests count from localStorage if available
    const latestRemainingFromStorage = localStorage.getItem('apiRequestsRemaining');
    
    // Parse values as numbers and set defaults, prioritizing the fresh localStorage value
    const remaining = latestRemainingFromStorage 
      ? parseInt(latestRemainingFromStorage, 10) 
      : (parseInt(apiData.requestsRemaining, 10) || 0);
    
    const total = parseInt(apiData.totalRequests, 10) || 100000;
    
    // Ensure remaining doesn't exceed total for percentage calculation
    const effectiveRemaining = Math.min(remaining, total);
    const percentRemaining = (effectiveRemaining / total) * 100;
    
    // Log the values we're about to send
    console.log('Sending API limit alert with latest data:', {
      remaining,
      fromStorage: Boolean(latestRemainingFromStorage),
      percentRemaining: percentRemaining.toFixed(1) + '%'
    });
    
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID, // Keep using admin template for admin notifications
      {
        from_name: 'ClutchSports NBA Stats Hub',
        from_email: 'noreply@clutchsports.com',
        subject: 'API Request Limit Warning',
        message: `⚠️ WARNING: API Request Limit Running Low ⚠️\n\n` +
                `Your Odds API request limit is running low.\n\n` +
                `Remaining Requests: ${remaining}\n` +
                `Percentage Remaining: ${percentRemaining.toFixed(1)}%\n` +
                `Time of Alert: ${new Date().toLocaleString()}\n\n` +
                `Please consider taking action to conserve your remaining API requests.\n\n` +
                `This is an automated notification.`
      }
    );
    
    console.log('API limit alert email sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending API limit alert email:', error);
    return null;
  }
};

/**
 * Send bulk emails to multiple recipients
 * @param {Array} recipients - Array of user objects with email addresses
 * @param {String} subject - Email subject line
 * @param {String} htmlContent - HTML content of the email
 * @param {Function} onProgress - Callback function to update progress (receives percentage as argument)
 * @returns {Promise} - Promise that resolves when all emails are sent
 */
export const sendBulkEmails = async (recipients, subject, htmlContent, onProgress = () => {}) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    // Filter out recipients without valid emails
    const validRecipients = recipients.filter(user => user.email);
    
    if (validRecipients.length === 0) {
      throw new Error('No valid recipients found');
    }
    
    // Strip HTML tags from the content
    const stripHtmlTags = (html) => {
      // Replace paragraph tags with line breaks
      let textContent = html.replace(/<p>/gi, '').replace(/<\/p>/gi, '\n');
      
      // Replace other common HTML tags
      textContent = textContent.replace(/<br\s*\/?>/gi, '\n');
      textContent = textContent.replace(/<li>/gi, '• ').replace(/<\/li>/gi, '\n');
      
      // Remove all remaining HTML tags
      textContent = textContent.replace(/<[^>]*>/g, '');
      
      // Clean up extra whitespace and normalize line breaks
      textContent = textContent.replace(/\n\s*\n/g, '\n\n');
      textContent = textContent.trim();
      
      return textContent;
    };
    
    // Process the HTML content to plain text
    const cleanContent = stripHtmlTags(htmlContent);
    
    // Track successful and failed sends
    const results = {
      total: validRecipients.length,
      success: 0,
      failed: 0,
      recipients: []
    };
    
    // Process in batches to avoid rate limiting
    const BATCH_SIZE = 5; // Adjust based on EmailJS rate limits
    const DELAY_BETWEEN_BATCHES = 1000; // 1 second delay between batches
    
    // Prepare batches
    const batches = [];
    for (let i = 0; i < validRecipients.length; i += BATCH_SIZE) {
      batches.push(validRecipients.slice(i, i + BATCH_SIZE));
    }
    
    // Process each batch
    for (let batchIndex = 0; batchIndex < batches.length; batchIndex++) {
      const batch = batches[batchIndex];
      const batchPromises = batch.map(user => {
        return emailjs.send(
          EMAILJS_SERVICE_ID,
          EMAILJS_BULK_TEMPLATE_ID, // Use the bulk email template
          {
            from_name: 'ClutchSports NBA Stats Hub',
            from_email: 'noreply@clutchsports.com',
            to_email: user.email,
            to_name: user.displayName || user.email.split('@')[0],
            subject: subject,
            message: cleanContent, // Use the cleaned plain text content instead of HTML
          }
        ).then(() => {
          results.success++;
          results.recipients.push({
            email: user.email,
            status: 'success'
          });
          return { success: true, email: user.email };
        }).catch(err => {
          results.failed++;
          results.recipients.push({
            email: user.email,
            status: 'failed',
            error: err.message
          });
          return { success: false, email: user.email, error: err.message };
        });
      });
      
      // Wait for all emails in this batch to be sent
      await Promise.all(batchPromises);
      
      // Update progress
      const progress = Math.round(((batchIndex + 1) * BATCH_SIZE / validRecipients.length) * 100);
      onProgress(Math.min(progress, 100));
      
      // Delay before next batch (only if not the last batch)
      if (batchIndex < batches.length - 1) {
        await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_BATCHES));
      }
    }
    
    console.log(`Bulk email send complete: ${results.success} succeeded, ${results.failed} failed`);
    return results;
    
  } catch (error) {
    console.error('Error sending bulk emails:', error);
    throw error;
  }
};

/**
 * Send an email notification to a user for trial expiration reminder
 * @param {Object} userData - User data including email and subscription details
 * @returns {Promise} - Promise from emailjs.send
 */
export const sendTrialExpirationReminder = async (userData) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    const daysLeft = userData.daysLeft || 1;
    const trialEndDate = new Date(userData.subscription?.trialEndDate);
    
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_USER_TEMPLATE_ID, // Updated to use user template
      {
        from_name: 'ClutchSports NBA Stats Hub',
        from_email: 'noreply@clutchsports.com',
        to_email: userData.email,
        to_name: userData.displayName || userData.email.split('@')[0],
        subject: `Your ClutchSports Trial Ends in ${daysLeft} ${daysLeft === 1 ? 'Day' : 'Days'}`,
        message: `Hello ${userData.displayName || 'there'},

Your premium trial on ClutchSports NBA Stats Hub is ending soon!

Trial End Date: ${trialEndDate.toLocaleDateString()}
Days Remaining: ${daysLeft}

To continue enjoying all premium features, please upgrade your subscription before your trial ends.

Click here to upgrade now: https://clutchnba.com/plans

Thank you for trying ClutchSports NBA Stats Hub!

The ClutchSports Team`
      }
    );
    
    console.log('Trial expiration reminder sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending trial expiration reminder:', error);
    return null;
  }
};

/**
 * Send a welcome email notification to a newly registered user
 * @param {Object} userData - User data including email
 * @returns {Promise} - Promise from emailjs.send
 */
export const sendSignupWelcomeEmail = async (userData) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_USER_TEMPLATE_ID, // Updated to use user template
      {
        from_name: 'ClutchSports NBA Stats Hub',
        from_email: 'noreply@clutchsports.com',
        to_email: userData.email,
        to_name: userData.displayName || userData.email.split('@')[0],
        subject: 'Welcome to ClutchSports NBA Stats Hub!',
        message: `Hello ${userData.displayName || 'there'},

Welcome to ClutchSports NBA Stats Hub! We're excited to have you join our community of NBA fans and statistics enthusiasts.

With your free account, you can:
• Access basic NBA stats and scores
• View game results and upcoming schedules
• Create a personalized dashboard

Want even more features? Consider upgrading to our premium plan to unlock advanced analytics, player predictions, and more.

Thank you for joining ClutchSports NBA Stats Hub!

The ClutchSports Team`
      }
    );
    
    console.log('Welcome email sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending welcome email:', error);
    return null;
  }
};

/**
 * Send an email notification about successful premium subscription
 * @param {Object} userData - User data including email and subscription details
 * @returns {Promise} - Promise from emailjs.send
 */
export const sendPremiumSubscriptionConfirmation = async (userData) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_USER_TEMPLATE_ID, // Updated to use user template
      {
        from_name: 'ClutchSports NBA Stats Hub',
        from_email: 'noreply@clutchsports.com',
        to_email: userData.email,
        to_name: userData.displayName || userData.email.split('@')[0],
        subject: 'Welcome to ClutchSports Premium!',
        message: `Hello ${userData.displayName || 'there'},

Thank you for subscribing to ClutchSports Premium!

Your premium subscription is now active, and you have full access to all premium features:
• Advanced analytics and statistics
• Player performance predictions
• Odds and betting insights
• Premium game data and visualizations
• Unlimited saved searches and reports

Subscription Details:
Plan: ${userData.subscription?.plan || 'Premium'}
Start Date: ${new Date(userData.subscription?.startDate).toLocaleDateString()}
Amount: $${userData.subscription?.amount || '39.99'}/month

We hope you enjoy the premium experience. If you have any questions or need assistance, please don't hesitate to contact our support team.

Thank you for choosing ClutchSports NBA Stats Hub!

The ClutchSports Team`
      }
    );
    
    console.log('Premium subscription confirmation sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending premium subscription confirmation:', error);
    return null;
  }
};

/**
 * Send an email notification about a trial membership being granted
 * @param {Object} userData - User data including email and trial details
 * @returns {Promise} - Promise from emailjs.send
 */
export const sendTrialGrantedNotification = async (userData) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    const trialEndDate = new Date(userData.subscription?.trialEndDate);
    const trialDays = userData.trialDays || 7;
    
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_USER_TEMPLATE_ID, // Updated to use user template
      {
        from_name: 'ClutchSports NBA Stats Hub',
        from_email: 'noreply@clutchsports.com',
        to_email: userData.email,
        to_name: userData.displayName || userData.email.split('@')[0],
        subject: `Your ${trialDays}-Day Premium Trial Has Started!`,
        message: `Hello ${userData.displayName || 'there'},

Great news! You've been granted a ${trialDays}-day premium trial on ClutchSports NBA Stats Hub.

Your trial gives you complete access to all premium features:
• Advanced analytics and statistics
• Player performance predictions
• Odds and betting insights
• Premium game data and visualizations
• Unlimited saved searches and reports

Trial Details:
Start Date: ${new Date(userData.subscription?.startDate).toLocaleDateString()}
End Date: ${trialEndDate.toLocaleDateString()}
Days: ${trialDays}

We hope you enjoy exploring all the premium features. If you decide to continue with premium access after your trial, you can upgrade at any time from your account page.

Thank you for using ClutchSports NBA Stats Hub!

The ClutchSports Team`
      }
    );
    
    console.log('Trial granted notification sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending trial granted notification:', error);
    return null;
  }
};

/**
 * Send an email notification about subscription expiration
 * @param {Object} userData - User data including email and subscription details
 * @returns {Promise} - Promise from emailjs.send
 */
export const sendSubscriptionExpirationNotification = async (userData) => {
  try {
    // Make sure EmailJS is initialized
    initEmailJS();
    
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_USER_TEMPLATE_ID, // Updated to use user template
      {
        from_name: 'ClutchSports NBA Stats Hub',
        from_email: 'noreply@clutchsports.com',
        to_email: userData.email,
        to_name: userData.displayName || userData.email.split('@')[0],
        subject: 'Your ClutchSports Premium Subscription Has Ended',
        message: `Hello ${userData.displayName || 'there'},

We're sorry to inform you that your premium subscription to ClutchSports NBA Stats Hub has expired.

Your account has been automatically downgraded to the free tier, which still gives you access to:
• Basic NBA stats and scores
• Game results and upcoming schedules
• Your personalized dashboard

To restore your access to all premium features, you can renew your subscription at any time.

Click here to renew: https://clutchnba.com/plans

Thank you for being a premium member, and we hope to welcome you back soon!

The ClutchSports Team`
      }
    );
    
    console.log('Subscription expiration notification sent successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sending subscription expiration notification:', error);
    return null;
  }
}; 
import { useState, useCallback, useMemo } from 'react';
import { americanToDecimal, calculateImpliedProbability } from '../utils/parlayUtils';

export const useAvailableLegs = (initialLegs = []) => {
    const [legs, setLegs] = useState(initialLegs);
    const [filters, setFilters] = useState({
        type: 'all',
        team: 'all',
        player: '',
        minOdds: -1000,
        maxOdds: 1000,
        minEV: -100,
        maxHold: 10,
        sortBy: 'ev',
        sortOrder: 'desc'
    });

    // Update available legs
    const updateLegs = useCallback((newLegs) => {
        setLegs(newLegs);
    }, []);

    // Update filters
    const updateFilters = useCallback((newFilters) => {
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    }, []);

    // Filter and sort legs
    const filteredLegs = useMemo(() => {
        return legs
            .filter(leg => {
                // Type filter
                if (filters.type !== 'all' && leg.type !== filters.type) {
                    return false;
                }

                // Team filter
                if (filters.team !== 'all' && leg.team !== filters.team) {
                    return false;
                }

                // Player filter
                if (filters.player && !leg.player?.toLowerCase().includes(filters.player.toLowerCase())) {
                    return false;
                }

                // Odds range filter
                if (leg.odds < filters.minOdds || leg.odds > filters.maxOdds) {
                    return false;
                }

                // EV filter
                if (leg.ev < filters.minEV) {
                    return false;
                }

                // Hold percentage filter
                if (leg.holdPercentage > filters.maxHold) {
                    return false;
                }

                return true;
            })
            .sort((a, b) => {
                const sortOrder = filters.sortOrder === 'desc' ? -1 : 1;

                switch (filters.sortBy) {
                    case 'ev':
                        return (a.ev - b.ev) * sortOrder;
                    case 'odds':
                        return (americanToDecimal(a.odds) - americanToDecimal(b.odds)) * sortOrder;
                    case 'probability':
                        return (calculateImpliedProbability(americanToDecimal(a.odds)) - 
                                calculateImpliedProbability(americanToDecimal(b.odds))) * sortOrder;
                    case 'hold':
                        return (a.holdPercentage - b.holdPercentage) * sortOrder;
                    case 'player':
                        return a.player?.localeCompare(b.player || '') * sortOrder;
                    case 'team':
                        // Safely handle undefined team values
                        const teamA = a.team || '';
                        const teamB = b.team || '';
                        return teamA.localeCompare(teamB) * sortOrder;
                    case 'type':
                        return a.type.localeCompare(b.type) * sortOrder;
                    default:
                        return 0;
                }
            });
    }, [legs, filters]);

    // Get unique values for filter options
    const filterOptions = useMemo(() => {
        const options = {
            types: ['all'],
            teams: ['all'],
            players: new Set()
        };

        legs.forEach(leg => {
            if (!options.types.includes(leg.type)) {
                options.types.push(leg.type);
            }
            if (!options.teams.includes(leg.team)) {
                options.teams.push(leg.team);
            }
            if (leg.player) {
                options.players.add(leg.player);
            }
        });

        return {
            ...options,
            players: Array.from(options.players).sort()
        };
    }, [legs]);

    // Get statistics about available legs
    const stats = useMemo(() => {
        const totalLegs = legs.length;
        const avgEV = legs.reduce((sum, leg) => sum + leg.ev, 0) / totalLegs;
        const avgHold = legs.reduce((sum, leg) => sum + leg.holdPercentage, 0) / totalLegs;
        
        const typeBreakdown = legs.reduce((acc, leg) => {
            acc[leg.type] = (acc[leg.type] || 0) + 1;
            return acc;
        }, {});

        return {
            totalLegs,
            avgEV,
            avgHold,
            typeBreakdown
        };
    }, [legs]);

    return {
        legs: filteredLegs,
        filters,
        filterOptions,
        stats,
        updateLegs,
        updateFilters
    };
}; 
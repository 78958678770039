import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Badge, InputGroup, OverlayTrigger, Tooltip, Pagination } from 'react-bootstrap';
import { BsFilter, BsSortDown, BsInfoCircle, BsPlus, BsTrash, BsXCircle, BsStarFill, 
         BsCheckCircleFill, BsExclamationTriangleFill, BsLightningFill } from 'react-icons/bs';
import { useParlay } from '../hooks/useParlay';
import { useAvailableLegs } from '../hooks/useAvailableLegs';
import { formatOdds, americanToDecimal, calculateImpliedProbability, formatProbability, decimalToAmerican, calculateParlayOdds } from '../utils/parlayUtils';
import './LayBuilder.css';

const LayBuilder = ({ gameData }) => {
    const {
        selectedLegs,
        stake = 10, // Set default stake to 10
        correlation,
        parlayStats,
        optimalUnitSizes,
        addLeg,
        removeLeg,
        clearLegs,
        updateStake,
        updateCorrelation,
        updateOptimalUnitSizes,
        calculateParlayStats
    } = useParlay();

    const {
        legs: availableLegs,
        filters,
        filterOptions,
        stats,
        updateLegs,
        updateFilters
    } = useAvailableLegs();

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [gameTimeFilter, setGameTimeFilter] = useState('all');
    const [showDecimalOdds, setShowDecimalOdds] = useState(false);
    const [showRecommendedOnly, setShowRecommendedOnly] = useState(false);
    const [showHighValueOnly, setShowHighValueOnly] = useState(false);
    const [showSolidValueOnly, setShowSolidValueOnly] = useState(false);

    // Add new constants for smart parlay analysis
    const OPTIMAL_LEG_COUNT = 3; // Research suggests 2-3 legs is optimal
    const MIN_EV_PER_LEG = 0.5; // Minimum EV% per leg for a good parlay
    const MAX_HOLD_PERCENTAGE = 0.15; // Maximum acceptable hold percentage (15%)
    const MIN_CORRELATED_EV = 1.5; // Minimum EV% for correlated legs

    // Add state for automatic correlation detection
    const [autoDetectCorrelation, setAutoDetectCorrelation] = useState(true);

    // Process game data into available legs
    useEffect(() => {
        if (!gameData || !Array.isArray(gameData) || gameData.length === 0) return;

        const processedLegs = [];
        let legId = 1;
        
        console.log('Processing games for available legs, games count:', gameData.length);
        
        // Check if player props are present in game data or in bookmakers
        const hasPlayerProps = gameData.some(game => {
            // Check direct player props
            const directPropsExist = game.playerProps && Array.isArray(game.playerProps) && game.playerProps.length > 0;
            
            // Also check bookmaker markets for player props
            const bookmakerPropsExist = game.bookmakers && game.bookmakers.some(bookmaker => 
                bookmaker.markets && bookmaker.markets.some(market => 
                    market.key.includes('player') || 
                    market.key === 'points' || 
                    market.key === 'rebounds' || 
                    market.key === 'assists' ||
                    market.key === 'threes' ||
                    market.key === 'steals' ||
                    market.key === 'blocks' ||
                    market.key.includes('double') ||
                    market.key.includes('triple')
                )
            );
            
            return directPropsExist || bookmakerPropsExist;
        });
        
        console.log('Player props found in game data or bookmakers:', hasPlayerProps);
        
        if (hasPlayerProps) {
            // Log a sample of player props from the first game that has them
            const sampleGame = gameData.find(game => 
                game.playerProps && Array.isArray(game.playerProps) && game.playerProps.length > 0
            );
            if (sampleGame) {
                console.log('Sample player prop data:', sampleGame.playerProps.slice(0, 3));
            }
        }
        
        gameData.forEach(game => {
            if (!game.bookmakers || game.bookmakers.length === 0) return;
            const bookmaker = game.bookmakers[0];
            
            // Process moneyline
            const moneylineMarket = bookmaker.markets?.find(m => m.key === 'h2h');
            if (moneylineMarket?.outcomes) {
                moneylineMarket.outcomes.forEach(outcome => {
                    const impliedProb = calculateImpliedProbability(americanToDecimal(outcome.price));
                    const marketImpliedProbs = moneylineMarket.outcomes.map(o => 
                        calculateImpliedProbability(americanToDecimal(o.price))
                    );
                    const holdPct = marketImpliedProbs.reduce((sum, prob) => sum + prob, 0) - 1;
                    const ev = ((americanToDecimal(outcome.price) - 1) * impliedProb * 100);

                    processedLegs.push({
                        id: `ml_${game.id}_${outcome.name}`,
                        type: 'moneyline',
                        team: outcome.name,
                        odds: outcome.price,
                        description: `${outcome.name} ML`,
                        matchup: `${game.home_team} vs ${game.away_team}`,
                        game: game,
                        ev: ev,
                        holdPercentage: holdPct,
                        impliedProbability: impliedProb,
                        decimalOdds: americanToDecimal(outcome.price),
                        legId: legId++
                    });
                });
            }

            // Process spread
            const spreadMarket = bookmaker.markets?.find(m => m.key === 'spreads');
            if (spreadMarket?.outcomes) {
                spreadMarket.outcomes.forEach(outcome => {
                    const impliedProb = calculateImpliedProbability(americanToDecimal(outcome.price));
                    const marketImpliedProbs = spreadMarket.outcomes.map(o => 
                        calculateImpliedProbability(americanToDecimal(o.price))
                    );
                    const holdPct = marketImpliedProbs.reduce((sum, prob) => sum + prob, 0) - 1;
                    const ev = ((americanToDecimal(outcome.price) - 1) * impliedProb * 100);

                    processedLegs.push({
                        id: `spread_${game.id}_${outcome.name}`,
                        type: 'spread',
                        team: outcome.name,
                        odds: outcome.price,
                        points: outcome.point,
                        description: `${outcome.name} ${outcome.point}`,
                        matchup: `${game.home_team} vs ${game.away_team}`,
                        game: game,
                        ev: ev,
                        holdPercentage: holdPct,
                        impliedProbability: impliedProb,
                        decimalOdds: americanToDecimal(outcome.price),
                        legId: legId++
                    });
                });
            }

            // Process total
            const totalMarket = bookmaker.markets?.find(m => m.key === 'totals');
            if (totalMarket?.outcomes) {
                totalMarket.outcomes.forEach(outcome => {
                    const impliedProb = calculateImpliedProbability(americanToDecimal(outcome.price));
                    const marketImpliedProbs = totalMarket.outcomes.map(o => 
                        calculateImpliedProbability(americanToDecimal(o.price))
                    );
                    const holdPct = marketImpliedProbs.reduce((sum, prob) => sum + prob, 0) - 1;
                    const ev = ((americanToDecimal(outcome.price) - 1) * impliedProb * 100);

                    processedLegs.push({
                        id: `total_${game.id}_${outcome.name}_${legId}`,
                        type: 'total',
                        odds: outcome.price,
                        points: outcome.point,
                        description: `${outcome.name} ${outcome.point}`,
                        matchup: `${game.home_team} vs ${game.away_team}`,
                        game: game,
                        ev: ev,
                        holdPercentage: holdPct,
                        impliedProbability: impliedProb,
                        decimalOdds: americanToDecimal(outcome.price),
                        legId: legId++
                    });
                });
            }

            // Process player props - use a more flexible approach to catch all prop types
            legId = processPlayerProps(game, bookmaker, legId, processedLegs);
        });

        console.log('Processed legs:', processedLegs);
        console.log('Legs by type:', {
            moneyline: processedLegs.filter(leg => leg.type === 'moneyline').length,
            spread: processedLegs.filter(leg => leg.type === 'spread').length,
            total: processedLegs.filter(leg => leg.type === 'total').length,
            player_prop: processedLegs.filter(leg => leg.type === 'player_prop').length
        });
        
        updateLegs(processedLegs);
    }, [gameData, updateLegs]);

    // Add console.log statements to debug the data format
    useEffect(() => {
        if (gameData) {
            console.log('Received game data:', gameData);
            if (gameData.length > 0 && gameData[0].bookmakers) {
                const firstBookmaker = gameData[0].bookmakers[0];
                console.log('First bookmaker markets:', firstBookmaker.markets);
                
                // Check for player props specifically
                const playerProps = firstBookmaker.markets?.filter(m => 
                    m.key.includes('player') || 
                    m.key === 'points' || 
                    m.key === 'rebounds' || 
                    m.key === 'assists'
                );
                console.log('Player prop markets found:', playerProps);
            }
        }
    }, [gameData]);

    // Process player props - use a more flexible approach to catch all prop types
    const processPlayerProps = (game, bookmaker, legId, processedLegs) => {
        // First collect all bookmakers' props from this game for comparison
        const allBookmakerProps = {};
        
        // Collect props from all bookmakers first (for comparison)
        if (game.bookmakers && Array.isArray(game.bookmakers)) {
            game.bookmakers.forEach(bk => {
                if (!bk.markets) return;
                
                // Look for player props in each bookmaker
                const bkPropMarkets = bk.markets.filter(m => 
                    m.key.includes('player') || 
                    m.key === 'points' || 
                    m.key === 'rebounds' || 
                    m.key === 'assists' ||
                    m.key === 'threes' ||
                    m.key === 'steals' ||
                    m.key === 'blocks' ||
                    m.key.includes('double') ||
                    m.key.includes('triple')
                );
                
                bkPropMarkets.forEach(market => {
                    if (!market.outcomes || market.outcomes.length === 0) return;
                    
                    market.outcomes.forEach(outcome => {
                        // Try to extract player and prop info
                        let playerName = '';
                        let propType = '';
                        let overUnder = '';
                        let points = '';
                        
                        // Extract from outcome name
                        if (outcome.name && typeof outcome.name === 'string') {
                            if (outcome.name.includes(' - ')) {
                                const parts = outcome.name.split(' - ');
                                playerName = parts[0].trim();
                                
                                if (parts[1].includes('Over')) {
                                    overUnder = 'Over';
                                    const pointsMatch = parts[1].match(/Over (\d+\.?\d*)/);
                                    if (pointsMatch) points = pointsMatch[1];
                                } else if (parts[1].includes('Under')) {
                                    overUnder = 'Under';
                                    const pointsMatch = parts[1].match(/Under (\d+\.?\d*)/);
                                    if (pointsMatch) points = pointsMatch[1];
                                }
                            } else if (outcome.name.includes(' Over ')) {
                                const parts = outcome.name.split(' Over ');
                                playerName = parts[0].trim();
                                overUnder = 'Over';
                                points = parts[1].trim();
                            } else if (outcome.name.includes(' Under ')) {
                                const parts = outcome.name.split(' Under ');
                                playerName = parts[0].trim();
                                overUnder = 'Under';
                                points = parts[1].trim();
                            }
                        }
                        
                        // Determine prop type from market key or description
                        if (market.key.includes('points')) propType = 'Points';
                        else if (market.key.includes('rebound')) propType = 'Rebounds';
                        else if (market.key.includes('assist')) propType = 'Assists';
                        else if (market.key.includes('three')) propType = 'Three Pointers';
                        else if (market.key.includes('steal')) propType = 'Steals';
                        else if (market.key.includes('block')) propType = 'Blocks';
                        else if (market.key.includes('double_double')) propType = 'Double-Double';
                        else if (market.key.includes('triple_double')) propType = 'Triple-Double';
                        else propType = market.description || 'Unknown Prop';
                        
                        // Skip if we don't have essential data
                        if (!playerName || !propType || !overUnder || !points || !outcome.price) return;
                        
                        // Create a unique key for this prop
                        const propKey = `${playerName}|${propType}|${overUnder}|${points}`;
                        
                        if (!allBookmakerProps[propKey]) {
                            allBookmakerProps[propKey] = [];
                        }
                        
                        // Add this bookmaker's odds to the collection
                        allBookmakerProps[propKey].push({
                            bookmaker: bk.key,
                            odds: outcome.price,
                            decimalOdds: americanToDecimal(outcome.price),
                            impliedProb: calculateImpliedProbability(americanToDecimal(outcome.price))
                        });
                    });
                });
            });
        }
        
        // Now process FanDuel props with value comparison
        // Only process FanDuel bookmaker props but use our comparison data
        if (bookmaker.key !== 'fanduel') {
            // Try to find FanDuel bookmaker for this game instead
            const fanduelBookmaker = game.bookmakers?.find(bk => bk.key === 'fanduel');
            if (fanduelBookmaker) {
                // Use FanDuel bookmaker instead
                bookmaker = fanduelBookmaker;
            } else {
                // CHANGE: Process the current bookmaker's props if FanDuel isn't available
                // instead of skipping them entirely
                console.log(`No FanDuel data for game ${game.id}, using ${bookmaker.key} props instead`);
            }
        }
        
        if (!bookmaker.markets) return legId;
        
        console.log(`Processing FanDuel props for game ${game.id}`);
        
        // Find the propMarkets filter and update it to be more inclusive
        const propMarkets = bookmaker.markets.filter(m => {
            // Check if market key directly matches any prop types
            if (m.key.includes('player') || 
                m.key === 'points' || 
                m.key.includes('point') ||
                m.key === 'rebounds' || 
                m.key.includes('rebound') ||
                m.key === 'assists' || 
                m.key.includes('assist') ||
                m.key.includes('three') ||
                m.key.includes('3pt') ||
                m.key.includes('steal') ||
                m.key.includes('block') ||
                m.key.includes('double') ||
                m.key.includes('triple') ||
                m.key.includes('pra') ||
                m.key.includes('pr')) {
                return true;
            }
            
            // Check if any outcome names contain player names or prop values
            if (m.outcomes && m.outcomes.length > 0) {
                // Check if any outcomes have player names in them
                return m.outcomes.some(outcome => {
                    if (!outcome.name || typeof outcome.name !== 'string') return false;
                    
                    // Look for typical player prop patterns in the outcome name
                    return (
                        outcome.name.includes(' Over ') || 
                        outcome.name.includes(' Under ') || 
                        outcome.name.includes(' - Over') || 
                        outcome.name.includes(' - Under') ||
                        // Often player names have a space in them (first and last name)
                        (outcome.name.includes(' ') && !outcome.name.includes(' vs '))
                    );
                });
            }
            
            return false;
        });
        
        console.log(`Found ${propMarkets.length} potential prop markets for game ${game.id}`);
        
        // Process direct player props from the game data first
        if (game.playerProps && Array.isArray(game.playerProps) && game.playerProps.length > 0) {
            console.log(`Found ${game.playerProps.length} direct player props for game ${game.id}`);
            
            // Filter for FanDuel props only
            const fanduelProps = game.playerProps.filter(prop => {
                return (prop.bookmaker === 'fanduel' || 
                        prop.source === 'fanduel' || 
                        prop.isFanduel === true);
            });
            
            console.log(`Found ${fanduelProps.length} FanDuel direct player props for game ${game.id}`);
            
            fanduelProps.forEach(prop => {
                if (!prop.player_name && !prop.playerName) return;
                
                const playerName = prop.player_name || prop.playerName;
                if (!playerName || playerName === "") {
                    console.log("Skipping prop with missing player name:", prop);
                    return;
                }
                
                const propType = prop.prop_type || prop.propType || 'Points';
                const overUnder = prop.selection || 'Over';
                const points = prop.line || prop.point || '';
                const team = prop.team || 'Unknown Team';
                const odds = prop.odds || prop.price || -110;
                
                // Ensure we have valid odds before proceeding
                if (!odds) {
                    console.log("Skipping prop with missing odds:", prop);
                    return;
                }
                
                const impliedProb = calculateImpliedProbability(americanToDecimal(odds));
                let holdPct = 0.055; // Default hold percentage for player props
                
                // Create a prop key to find comparable props
                const propKey = `${playerName}|${propType}|${overUnder}|${points}`;
                
                // Enhanced EV calculation with comparison to other books
                let ev = ((americanToDecimal(odds) - 1) * impliedProb * 100);
                let valueRating = 1.0; // Default value rating multiplier
                
                // Compare against other bookmakers to get true value
                if (allBookmakerProps[propKey] && allBookmakerProps[propKey].length > 1) {
                    // Find best odds from other books
                    const otherBookOdds = allBookmakerProps[propKey]
                        .filter(bp => bp.bookmaker !== 'fanduel')
                        .sort((a, b) => b.decimalOdds - a.decimalOdds); // Sort by best decimal odds
                    
                    if (otherBookOdds.length > 0) {
                        const bestOtherOdds = otherBookOdds[0];
                        const oddsAdvantage = americanToDecimal(odds) - bestOtherOdds.decimalOdds;
                        
                        // Calculate value rating based on odds advantage
                        if (oddsAdvantage > 0.1) {
                            valueRating = 1.5; // Significant advantage = 50% boost
                        } else if (oddsAdvantage > 0.05) {
                            valueRating = 1.3; // Good advantage = 30% boost
                        } else if (oddsAdvantage > 0) {
                            valueRating = 1.1; // Slight advantage = 10% boost
                        } else if (oddsAdvantage < -0.1) {
                            valueRating = 0.6; // Bad odds = 40% reduction
                        } else if (oddsAdvantage < -0.05) {
                            valueRating = 0.8; // Poor odds = 20% reduction
                        }
                        
                        // Apply value rating
                        ev = ev * valueRating;
                        
                        // Get a more accurate hold percentage from market
                        const allImpliedProbs = allBookmakerProps[propKey].map(bp => bp.impliedProb);
                        const avgImpliedProb = allImpliedProbs.reduce((sum, prob) => sum + prob, 0) / allImpliedProbs.length;
                        holdPct = (avgImpliedProb * 2) - 1; // Estimate hold from avg implied prob
                        if (holdPct < 0.01) holdPct = 0.01; // Ensure minimum 1% hold
                    }
                }
                
                // Determine the player's team
                let playerTeam = team;
                if (playerTeam === 'Unknown Team' && game) {
                    // Try to determine the team based on the game data
                    // First check if the player name appears in the game rosters
                    const homeRoster = game.home_roster || [];
                    const awayRoster = game.away_roster || [];
                    
                    if (Array.isArray(homeRoster) && homeRoster.some(player => 
                        player.name?.toLowerCase().includes(playerName.toLowerCase()))) {
                        playerTeam = game.home_team;
                    } else if (Array.isArray(awayRoster) && awayRoster.some(player => 
                        player.name?.toLowerCase().includes(playerName.toLowerCase()))) {
                        playerTeam = game.away_team;
                    } else {
                        // If roster data is unavailable, assign based on a hash of the player's name
                        const nameHash = playerName.split('').reduce((a, b) => a + b.charCodeAt(0), 0);
                        playerTeam = nameHash % 2 === 0 ? game.home_team : game.away_team;
                    }
                }
                
                processedLegs.push({
                    id: `prop_${game.id}_${propType}_${playerName}_${legId}`,
                    type: 'player_prop',
                    player: playerName,
                    team: playerTeam,
                    propType: propTypeDisplay(propType),
                    propKey: propType,
                    odds: odds,
                    points: points,
                    overUnder: overUnder,
                    description: `${playerName} ${overUnder} ${points} ${propTypeDisplay(propType)}`,
                    game: game,
                    matchup: `${game.home_team} vs ${game.away_team}`,
                    ev: ev,
                    holdPercentage: holdPct,
                    impliedProbability: impliedProb,
                    decimalOdds: americanToDecimal(odds),
                    valueRating: valueRating, // Add value rating
                    legId: legId++
                });
                
                console.log(`Added direct player prop: ${playerName} ${overUnder} ${points} ${propTypeDisplay(propType)} (Value: ${valueRating.toFixed(2)}x)`);
            });
        }
        
        // Now process player props from markets as fallback
        propMarkets.forEach(market => {
            if (!market.outcomes || market.outcomes.length === 0) return;
            
            console.log(`Processing market: ${market.key} with ${market.outcomes.length} outcomes`);
            
            market.outcomes.forEach(outcome => {
                // Try to determine if this is a player prop
                let isPlayerProp = market.key.includes('player');
                let playerName = '';
                let propType = '';
                let overUnder = '';
                
                // Extract player name from outcome name or description
                if (outcome.name && typeof outcome.name === 'string') {
                    // Check various formats for player name
                    if (outcome.name.includes(' - ')) {
                        // Format: "Player Name - Over 5.5"
                        const parts = outcome.name.split(' - ');
                        playerName = parts[0].trim();
                        isPlayerProp = true;
                        
                        if (parts[1].includes('Over')) {
                            overUnder = 'Over';
                        } else if (parts[1].includes('Under')) {
                            overUnder = 'Under';
                        }
                    } else if (outcome.name.includes(' Over ')) {
                        // Format: "Player Name Over 22.5"
                        const parts = outcome.name.split(' Over ');
                        playerName = parts[0].trim();
                        overUnder = 'Over';
                        isPlayerProp = true;
                    } else if (outcome.name.includes(' Under ')) {
                        // Format: "Player Name Under 22.5"
                        const parts = outcome.name.split(' Under ');
                        playerName = parts[0].trim();
                        overUnder = 'Under';
                        isPlayerProp = true;
                    } else if (market.key.includes('player')) {
                        // If market key contains 'player', assume the outcome name is the player name
                        playerName = outcome.name.trim();
                        isPlayerProp = true;
                    }
                }
                
                // If player name is empty but we have a description, try to extract from there
                if ((!playerName || playerName === "") && outcome.description && typeof outcome.description === 'string') {
                    // Try to extract player name from description
                    const descParts = outcome.description.split(' ');
                    if (descParts.length >= 2) {
                        // Assume first two parts could be first and last name
                        playerName = `${descParts[0]} ${descParts[1]}`.trim();
                        isPlayerProp = true;
                    }
                }
                
                // Skip if we couldn't extract a player name
                if (!playerName || playerName === "") {
                    return;
                }
                
                // Determine the prop type
                if (market.key.includes('points')) {
                    propType = 'Points';
                } else if (market.key.includes('rebounds')) {
                    propType = 'Rebounds';
                } else if (market.key.includes('assists')) {
                    propType = 'Assists';
                } else if (market.key.includes('threes')) {
                    propType = 'Three Pointers';
                } else if (market.key.includes('steals')) {
                    propType = 'Steals';
                } else if (market.key.includes('blocks')) {
                    propType = 'Blocks';
                } else if (market.key.includes('double_double')) {
                    propType = 'Double-Double';
                } else if (market.key.includes('triple_double')) {
                    propType = 'Triple-Double';
                } else if (market.key.includes('pts_rebounds_asts')) {
                    propType = 'PRA';
                } else {
                    // Try to extract from description
                    propType = market.description || 'Other';
                }
                
                // Skip if we don't have the necessary information
                if (!isPlayerProp || !playerName || !outcome.price) {
                    return;
                }
                
                // Determine the team
                let team = 'Unknown Team';
                
                // Method 1: Check home roster
                if (game.home_roster && Array.isArray(game.home_roster)) {
                    if (game.home_roster.some(player => player.name?.toLowerCase().includes(playerName.toLowerCase()))) {
                        team = game.home_team;
                    }
                }
                
                // Method 2: Check away roster
                if (team === 'Unknown Team' && game.away_roster && Array.isArray(game.away_roster)) {
                    if (game.away_roster.some(player => player.name?.toLowerCase().includes(playerName.toLowerCase()))) {
                        team = game.away_team;
                    }
                }
                
                // Method 3: Simple string matching
                if (team === 'Unknown Team') {
                    if (game.home_team && playerName.toLowerCase().includes(game.home_team.toLowerCase())) {
                        team = game.home_team;
                    } else if (game.away_team && playerName.toLowerCase().includes(game.away_team.toLowerCase())) {
                        team = game.away_team;
                    } else {
                        // Consistent random assignment
                        const nameHash = playerName.split('').reduce((a, b) => a + b.charCodeAt(0), 0);
                        team = nameHash % 2 === 0 ? game.home_team : game.away_team;
                    }
                }
                
                // Calculate stats for the leg
                const impliedProb = calculateImpliedProbability(americanToDecimal(outcome.price));
                const marketImpliedProbs = market.outcomes.map(o => 
                    calculateImpliedProbability(americanToDecimal(o.price))
                );
                const holdPct = marketImpliedProbs.length > 1 ? 
                    marketImpliedProbs.reduce((sum, prob) => sum + prob, 0) - 1 : 
                    0.055; // default if we can't calculate
                
                const ev = ((americanToDecimal(outcome.price) - 1) * impliedProb * 100);
                
                // Determine over/under if not set already
                if (!overUnder && outcome.description) {
                    if (outcome.description.includes('Over')) {
                        overUnder = 'Over';
                    } else if (outcome.description.includes('Under')) {
                        overUnder = 'Under';
                    } else {
                        // Default
                        overUnder = 'Yes';
                    }
                }
                
                // Get points/line value
                let points = outcome.point || '';
                if (!points && outcome.description) {
                    // Try to extract from description
                    const match = outcome.description.match(/\d+(\.\d+)?/);
                    if (match) {
                        points = match[0];
                    }
                }
                
                // Create the processed leg
                processedLegs.push({
                    id: `prop_${game.id}_${market.key}_${playerName}_${legId}`,
                    type: 'player_prop',
                    player: playerName,
                    team: team,
                    propType: propType,
                    propKey: market.key,
                    odds: outcome.price,
                    points: points,
                    overUnder: overUnder,
                    description: `${playerName} ${overUnder} ${points} ${propType}`,
                    game: game,
                    matchup: `${game.home_team} vs ${game.away_team}`,
                    ev: ev,
                    holdPercentage: holdPct,
                    impliedProbability: impliedProb,
                    decimalOdds: americanToDecimal(outcome.price),
                    legId: legId++
                });
                
                console.log(`Added player prop from market: ${playerName} ${overUnder} ${points} ${propType}`);
            });
        });
        
        return legId;
    };

    // Helper function to better display prop types
    const propTypeDisplay = (propType) => {
        if (!propType) return 'Points';
        
        const propLower = propType.toLowerCase();
        
        if (propLower.includes('point')) return 'Points';
        if (propLower.includes('rebound')) return 'Rebounds';
        if (propLower.includes('assist')) return 'Assists';
        if (propLower.includes('3pt') || propLower.includes('three') || propLower.includes('threes')) return 'Three Pointers';
        if (propLower.includes('block')) return 'Blocks';
        if (propLower.includes('steal')) return 'Steals';
        if (propLower.includes('double_double')) return 'Double-Double';
        if (propLower.includes('triple_double')) return 'Triple-Double';
        if (propLower.includes('pts_rebounds_asts') || propLower.includes('pra')) return 'PRA';
        
        return propType;
    };

    // Update getTimeSlots function to use regular time format
    const getTimeSlots = () => {
        if (!gameData || !Array.isArray(gameData)) return [];
        
        const timeSlots = new Set();
        gameData.forEach(game => {
            if (game.commence_time) {
                const date = new Date(game.commence_time);
                const hours = date.getHours();
                // Group games by 3-hour slots
                const timeSlot = Math.floor(hours / 3) * 3;
                
                // Convert to regular time format with AM/PM
                const startHour = timeSlot % 12 || 12;
                const endHour = (timeSlot + 3) % 12 || 12;
                const startAmPm = timeSlot < 12 ? 'AM' : 'PM';
                const endAmPm = (timeSlot + 3) < 12 ? 'AM' : 'PM';
                
                const formattedSlot = `${startHour}${startAmPm}-${endHour}${endAmPm}`;
                timeSlots.add(formattedSlot);
            }
        });
        
        return ['all', ...Array.from(timeSlots).sort()];
    };

    // Function to determine if a leg falls into a specific value tier
    const isLegValueTier = (leg) => {
        // Define criteria for the different tiers
        const hasExtremeEV = leg.ev > 15.0; // Only legs with EV > 15%
        const hasVeryHighEV = leg.ev > 8.0; // Only legs with EV > 8%
        const hasHighEV = leg.ev > 5.0; // Legs with EV > 5%
        const hasSolidEV = leg.ev > 3.0; // Legs with EV > 3%
        
        const hasVeryLowHold = leg.holdPercentage < 0.035; // Hold percentage < 3.5%
        const hasLowHold = leg.holdPercentage < 0.05; // Hold percentage < 5%
        
        const isPlayerProp = leg.type === 'player_prop';
        const isMoneyline = leg.type === 'moneyline';
        
        // Get the value rating (default to 1.0 if not available)
        const valueRating = leg.valueRating || 1.0;
        
        // Apply value rating to EV thresholds for player props
        const adjustedEV = isPlayerProp ? leg.ev * valueRating : leg.ev;
        
        // Recommended: Only the absolute best legs
        if (adjustedEV > 15.0 || (adjustedEV > 8.0 && hasVeryLowHold) || 
            (isPlayerProp && leg.valueRating && leg.valueRating >= 1.3 && adjustedEV > 7.0)) {
            return 'recommended';
        }
        
        // High Value: Very good legs that don't quite make the recommended cut
        if (isPlayerProp) {
            if (adjustedEV > 8.0 || (adjustedEV > 5.0 && hasLowHold && leg.impliedProbability > 0.4) ||
                (leg.valueRating && leg.valueRating >= 1.2 && adjustedEV > 4.0)) {
                return 'high-value';
            }
        } else if (isMoneyline) {
            if (hasVeryHighEV || (hasHighEV && hasVeryLowHold)) {
                return 'high-value';
            }
        } else if (hasVeryHighEV) {
            return 'high-value';
        }
        
        // Solid Value: Good legs that show positive expected value
        if (isPlayerProp) {
            if (hasSolidEV && leg.impliedProbability > 0.35 && leg.impliedProbability < 0.8) {
                return 'solid-value';
            }
        } else if (hasSolidEV && leg.holdPercentage < 0.055) {
            return 'solid-value';
        }
        
        // No special tier
        return null;
    };

    // Update the function to be more selective about recommended legs
    const isRecommendedLeg = (leg) => {
        // Define stricter criteria for recommended legs
        const hasHighEV = leg.ev > 8.0; // Only legs with EV > 8%
        const hasVeryLowHold = leg.holdPercentage < 0.035; // Hold percentage < 3.5%
        const isPlayerProp = leg.type === 'player_prop';
        const isMoneyline = leg.type === 'moneyline';
        
        // Ensure we only recommend the best 5-10% of legs
        if (isPlayerProp) {
            // For player props: high EV, reasonable probability, and low hold
            return hasHighEV && 
                   leg.impliedProbability > 0.40 && 
                   leg.impliedProbability < 0.75 && 
                   leg.holdPercentage < 0.05;
        } else if (isMoneyline) {
            // For moneyline: very high EV and very low hold
            return hasHighEV && hasVeryLowHold;
        } else {
            // For other leg types: extremely high EV only
            return leg.ev > 10.0;
        }
    };

    // Add a function to get recommendation reason
    const getRecommendationReason = (leg) => {
        if (leg.ev > 5.0) {
            return "High EV leg with strong value";
        } else if (leg.type === 'player_prop' && leg.ev > 3.0) {
            return "Player prop with positive expected value";
        } else if (leg.holdPercentage < 0.04) {
            return "Low hold percentage offers good value";
        } else {
            return "Positive expected value";
        }
    };

    // Update the filteredBySearch logic to sort recommended legs first
    const filteredLegs = availableLegs.filter(leg => {
        // Skip if no search term, no game time filter and not filtering by value tiers
        if (!searchTerm && 
            gameTimeFilter === 'all' && 
            !showRecommendedOnly && 
            !showHighValueOnly && 
            !showSolidValueOnly) {
            return true;
        }
        
        // Apply value tier filters if enabled
        if (showRecommendedOnly || showHighValueOnly || showSolidValueOnly) {
            const legTier = isLegValueTier(leg);
            
            // If any tier filter is on, leg must match at least one of the enabled tiers
            if ((showRecommendedOnly && legTier === 'recommended') || 
                (showHighValueOnly && legTier === 'high-value') || 
                (showSolidValueOnly && legTier === 'solid-value')) {
                // Leg matches at least one of the enabled value tier filters
            } else {
                // Leg doesn't match any of the enabled value tier filters
                return false;
            }
        }
        
        // Apply text search
        const searchMatches = !searchTerm || (
            (leg.player && leg.player.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (leg.team && leg.team.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (leg.propType && leg.propType.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (leg.description && leg.description.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        
        // Apply game time filter
        let timeMatches = true;
        if (gameTimeFilter !== 'all' && leg.game && leg.game.commence_time) {
            const date = new Date(leg.game.commence_time);
            const hours = date.getHours();
            const timeSlot = Math.floor(hours / 3) * 3;
            
            // Convert to regular time format with AM/PM
            const startHour = timeSlot % 12 || 12;
            const endHour = (timeSlot + 3) % 12 || 12;
            const startAmPm = timeSlot < 12 ? 'AM' : 'PM';
            const endAmPm = (timeSlot + 3) < 12 ? 'AM' : 'PM';
            
            const formattedSlot = `${startHour}${startAmPm}-${endHour}${endAmPm}`;
            timeMatches = (formattedSlot === gameTimeFilter);
        }
        
        return searchMatches && timeMatches;
    });

    // Update the renderPagination function to ensure there are no syntax errors
    const renderPagination = () => {
        const pageNumbers = [];
        
        // Only show 5 pages at a time
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, startPage + 4);
        
        // Adjust startPage if we're near the end
        if (endPage - startPage < 4) {
            startPage = Math.max(1, endPage - 4);
        }
        
        // Add first page and ellipsis if needed
        if (startPage > 1) {
            pageNumbers.push(
                <Pagination.Item key={1} onClick={() => paginate(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                pageNumbers.push(<Pagination.Ellipsis key="ellipsis1" disabled />);
            }
        }
        
        // Add pages
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item 
                    key={i} 
                    active={i === currentPage}
                    onClick={() => paginate(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }
        
        // Add last page and ellipsis if needed
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push(<Pagination.Ellipsis key="ellipsis2" disabled />);
            }
            pageNumbers.push(
                <Pagination.Item 
                    key={totalPages} 
                    onClick={() => paginate(totalPages)}
                >
                    {totalPages}
                </Pagination.Item>
            );
        }
        
        return (
            <div className="pagination-container">
                <Pagination size="sm">
                    <Pagination.Prev 
                        onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {pageNumbers}
                    <Pagination.Next 
                        onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
                
                <div className="items-per-page">
                    <Form.Select 
                        size="sm" 
                        className="items-per-page-select"
                        value={itemsPerPage}
                        onChange={(e) => {
                            setItemsPerPage(parseInt(e.target.value));
                            setCurrentPage(1);
                        }}
                    >
                        <option value="10">10 per page</option>
                        <option value="15">15 per page</option>
                        <option value="25">25 per page</option>
                        <option value="50">50 per page</option>
                    </Form.Select>
                </div>
            </div>
        );
    };

    // Helper to render the EV badge with correct EV calculation
    const renderEvBadge = (ev) => {
        if (!ev && ev !== 0) return null;
        
        // Apply the same EV calculation used in the player props tab
        let displayEv = Math.round(ev * 10) / 10; // Round to 1 decimal
        const evClass = displayEv > 0 ? 'positive' : displayEv < 0 ? 'negative' : 'neutral';
        
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Expected Value: {displayEv > 0 ? '+' : ''}{displayEv.toFixed(1)}%</Tooltip>}
            >
                <Badge bg={displayEv > 0 ? 'success' : 'danger'} className={`ev-badge ${evClass}`}>
                    {displayEv > 0 ? '+' : ''}{displayEv.toFixed(1)}%
                </Badge>
            </OverlayTrigger>
        );
    };

    // Helper to render info tooltip
    const renderInfoTooltip = (content) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>{content}</Tooltip>}>
            <span className="info-icon"><BsInfoCircle /></span>
        </OverlayTrigger>
    );

    // Add these additional helper functions for correlation detection

    // Function to detect if legs are from the same game
    const detectSameGameLegs = (legs) => {
        if (!legs || legs.length === 0) return [];
        
        const gameGroups = {};
        
        legs.forEach(leg => {
            if (leg.game && leg.game.id) {
                if (!gameGroups[leg.game.id]) {
                    gameGroups[leg.game.id] = {
                        gameId: leg.game.id,
                        matchup: leg.matchup || `${leg.game.home_team} vs ${leg.game.away_team}`,
                        legs: []
                    };
                }
                gameGroups[leg.game.id].legs.push(leg);
            }
        });
        
        // Filter for games with multiple legs (potential correlation)
        return Object.values(gameGroups).filter(group => group.legs.length > 1);
    };

    // Function to detect specific correlation patterns
    const detectCorrelationPatterns = (legs, gameId) => {
        // If we have a gameId, filter legs for that game, otherwise use all legs
        const gameLegs = gameId ? legs.filter(leg => leg.game && leg.game.id === gameId) : legs;
        
        if (gameLegs.length < 2) return [];
        
        const patterns = [];
        
        // Group legs by type
        const teamWinLegs = gameLegs.filter(leg => leg.type === 'moneyline');
        const playerPropLegs = gameLegs.filter(leg => leg.type === 'player_prop');
        const totalLegs = gameLegs.filter(leg => leg.type === 'total');
        
        // Pattern 1: Team win (moneyline) + player prop over
        teamWinLegs.forEach(teamLeg => {
            const team = teamLeg.team;
            
            // Find player props for players on the same team
            const sameTeamProps = playerPropLegs.filter(propLeg => 
                propLeg.team === team && propLeg.overUnder === 'Over'
            );
            
            if (sameTeamProps.length > 0) {
                patterns.push({
                    type: 'team_win_player_over',
                    strength: 'strong',
                    team: team,
                    description: `${team} to win combined with ${team} player props`
                });
            }
            
            // Find player props for players on the opposing team with Under
            const opposingTeams = gameLegs
                .map(leg => leg.team)
                .filter(t => t !== team && t !== 'Unknown Team');
            
            if (opposingTeams.length > 0) {
                const opposingTeam = opposingTeams[0];
                const opposingUnders = playerPropLegs.filter(propLeg => 
                    propLeg.team === opposingTeam && propLeg.overUnder === 'Under'
                );
                
                if (opposingUnders.length > 0) {
                    patterns.push({
                        type: 'team_win_opponent_under',
                        strength: 'strong',
                        team: team,
                        opposingTeam: opposingTeam,
                        description: `${team} to win combined with ${opposingTeam} player unders`
                    });
                }
            }
        });
        
        // Pattern 2: Over/Under game total with compatible player props
        totalLegs.forEach(totalLeg => {
            const isOver = totalLeg.description.includes('Over');
            
            if (isOver) {
                // Game total over typically correlates with player point props over
                const pointsOverProps = playerPropLegs.filter(propLeg => 
                    propLeg.propType === 'Points' && propLeg.overUnder === 'Over'
                );
                
                if (pointsOverProps.length > 0) {
                    patterns.push({
                        type: 'total_over_points_over',
                        strength: 'strong',
                        description: 'Game total Over combined with player points Over'
                    });
                }
            } else {
                // Game total under often correlates with player point props under
                const pointsUnderProps = playerPropLegs.filter(propLeg => 
                    propLeg.propType === 'Points' && propLeg.overUnder === 'Under'
                );
                
                if (pointsUnderProps.length > 0) {
                    patterns.push({
                        type: 'total_under_points_under',
                        strength: 'strong',
                        description: 'Game total Under combined with player points Under'
                    });
                }
            }
        });
        
        // Pattern 3: Player prop correlation (pts/reb/ast from same player)
        const playerStats = {};
        
        playerPropLegs.forEach(leg => {
            if (!leg.player) return;
            
            if (!playerStats[leg.player]) {
                playerStats[leg.player] = { props: [] };
            }
            
            playerStats[leg.player].props.push({
                propType: leg.propType,
                overUnder: leg.overUnder
            });
        });
        
        // Check for players with multiple props
        Object.entries(playerStats).forEach(([player, data]) => {
            if (data.props.length >= 2) {
                const propTypes = data.props.map(p => p.propType);
                const hasPoints = propTypes.includes('Points');
                const hasRebounds = propTypes.includes('Rebounds');
                const hasAssists = propTypes.includes('Assists');
                
                // Check for inverse correlation (e.g. over points + under rebounds)
                const pointsDirection = data.props.find(p => p.propType === 'Points')?.overUnder;
                const reboundsDirection = data.props.find(p => p.propType === 'Rebounds')?.overUnder;
                const assistsDirection = data.props.find(p => p.propType === 'Assists')?.overUnder;
                
                // Points + Rebounds inverse correlation
                if (hasPoints && hasRebounds && pointsDirection !== reboundsDirection) {
                    patterns.push({
                        type: 'inverse_pts_reb',
                        strength: 'optimal',
                        player: player,
                        description: `${player} ${pointsDirection} Points with ${reboundsDirection} Rebounds (inverse correlation)`
                    });
                }
                
                // Points + Assists inverse correlation
                if (hasPoints && hasAssists && pointsDirection !== assistsDirection) {
                    patterns.push({
                        type: 'inverse_pts_ast',
                        strength: 'optimal',
                        player: player,
                        description: `${player} ${pointsDirection} Points with ${assistsDirection} Assists (inverse correlation)`
                    });
                }
                
                // All same direction for different stats is less optimal (not leveraging inverse correlation)
                if (data.props.length >= 3 && 
                    new Set(data.props.map(p => p.overUnder)).size === 1) {
                    patterns.push({
                        type: 'same_direction_all_props',
                        strength: 'weak',
                        player: player,
                        description: `All ${player} props are ${data.props[0].overUnder} (consider mixing directions)`
                    });
                }
            }
        });
        
        return patterns;
    };

    // Update function name to match new terminology
    const analyzeLayStrength = (legs, correlationValue) => {
        if (!legs || legs.length === 0) return null;
        
        // Extract all value-based metrics for the legs
        const legMetrics = legs.map(leg => ({
            ev: leg.ev || 0,
            holdPercentage: leg.holdPercentage || 0.05,
            impliedProbability: leg.impliedProbability || calculateImpliedProbability(americanToDecimal(leg.odds)),
            type: leg.type || '',
            valueRating: leg.valueRating || 1.0
        }));
        
        // Used in analyzing parlay strength
        const CORRELATED_THRESHOLD = 0.4; // Minimum correlation to be considered meaningful
        const HIGH_VALUE_THRESHOLD = 0.8; // Threshold for high value metric
        
        let message = ''; // Initialize message string
        let strength = 'neutral'; // Default strength
        
        // Check for correlation patterns
        const hasCorrelatedLegs = correlationValue > CORRELATED_THRESHOLD;
        
        // Calculate average EV per leg
        const avgEV = legMetrics.reduce((sum, leg) => sum + leg.ev, 0) / legMetrics.length;
        
        // Calculate average value rating
        const avgValueRating = legMetrics.reduce((sum, leg) => sum + (leg.valueRating || 1.0), 0) / legMetrics.length;
        
        // Calculate parlay odds using the correlation value
        const parlayDecimalOdds = calculateParlayOdds(legs, correlationValue);
        
        // Check if parlay has a good betting opportunity based on calculated metrics
        const hasHighAvgEV = avgEV > 5.0;
        const hasLowAvgHold = legMetrics.reduce((sum, leg) => sum + leg.holdPercentage, 0) / legMetrics.length < 0.05;
        const hasLongOdds = parlayDecimalOdds > 5;
        
        // Overall parlay strength assessment
        let icon = '';
        
        if ((hasCorrelatedLegs && hasHighAvgEV) || 
            (avgEV > 8.0 && legs.length <= 3 && hasLowAvgHold) ||
            (avgValueRating > 1.2 && avgEV > 5.0 && legs.length <= 3)) {
            strength = 'optimal';
            icon = 'bi-star-fill';
            // Optimal parlay with strong correlation patterns
            message = hasCorrelatedLegs 
                ? 'Optimal correlated lay with strong value legs.'
                : 'Optimal multi-game lay with high expected value.';
        } else if (hasHighAvgEV && legs.length <= 3) {
            strength = 'strong';
            icon = 'bi-star-half';
            // Strong parlay case
            if (hasCorrelatedLegs) {
                message = 'Strong correlated lay with positive expected value.';
            } else {
                message = 'Strong multi-game lay with uncorrelated value legs.';
            }
        } else {
            strength = 'weak';
            icon = 'bi-exclamation-triangle-fill';
            // Weak parlay case
            if (legs.length > 3) {
                message = 'High Risk: Research shows 2-3 leg parlays perform better.';
            } else if (!hasLowAvgHold) {
                message = 'High Hold: This lay has a high bookmaker advantage.';
            } else if (hasLongOdds) {
                message = 'Long Shot: This lay has very high odds but low probability.';
            } else {
                message = 'Low Value: This lay has weak expected value.';
            }
        }
        
        return {
            strength,
            message,
            parlayDecimalOdds,
            icon
        };
    };

    // Add badge styling in the component style
    const parlayStrengthStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        marginBottom: '0.5rem',
        padding: '0.25rem 0.5rem',
        borderRadius: '4px',
        fontSize: '0.75rem',
        fontWeight: '500',
        whiteSpace: 'nowrap',
    };

    // Get value tier reason - update to include value rating information
    const getValueTierReason = (leg, tier) => {
        const valueRating = leg.valueRating || 1.0;
        const valueBoost = valueRating > 1 ? `(+${((valueRating - 1) * 100).toFixed(0)}% value vs other books)` : '';
        
        if (tier === 'recommended') {
            if (leg.ev > 15.0) {
                return `Exceptional EV with very strong edge ${valueBoost}`;
            } else if (leg.holdPercentage < 0.035) {
                return `Very high EV with extremely low hold percentage ${valueBoost}`;
            } else if (valueRating >= 1.3) {
                return `Better odds than other sportsbooks by ${((valueRating - 1) * 100).toFixed(0)}%`;
            }
            return "Top tier opportunity";
        } else if (tier === 'high-value') {
            if (leg.type === 'player_prop') {
                return `Player prop with high expected value ${valueBoost}`;
            } else if (leg.holdPercentage < 0.04) {
                return "Strong value with low juice";
            }
            return "High expected value";
        } else if (tier === 'solid-value') {
            if (leg.type === 'player_prop') {
                return "Solid player prop opportunity";
            }
            return "Positive EV with reasonable hold";
        }
        return "Positive expected value";
    };

    // Function to render the appropriate badge for a leg
    const renderLegBadge = (leg) => {
        const valueTier = isLegValueTier(leg);
        
        if (valueTier === 'recommended') {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{getValueTierReason(leg, valueTier)}</Tooltip>}
                >
                    <div className="recommended-badge">
                        <BsStarFill className="recommended-icon" /> Recommended
                    </div>
                </OverlayTrigger>
            );
        } else if (valueTier === 'high-value') {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{getValueTierReason(leg, valueTier)}</Tooltip>}
                >
                    <div className="high-value-badge">
                        <BsCheckCircleFill className="high-value-icon" /> High Value
                    </div>
                </OverlayTrigger>
            );
        } else if (valueTier === 'solid-value') {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{getValueTierReason(leg, valueTier)}</Tooltip>}
                >
                    <div className="solid-value-badge">
                        <BsInfoCircle className="solid-value-icon" /> Solid Value
                    </div>
                </OverlayTrigger>
            );
        }
        
        return null;
    };

    // Function to get the CSS class for a leg based on its value tier
    const getLegClassName = (leg) => {
        const valueTier = isLegValueTier(leg);
        
        if (valueTier === 'recommended') {
            return 'recommended-leg';
        } else if (valueTier === 'high-value') {
            return 'high-value-leg';
        } else if (valueTier === 'solid-value') {
            return 'solid-value-leg';
        }
        
        return '';
    };

    // Update the sorted legs logic to order by value tier
    const sortedLegs = [...filteredLegs].sort((a, b) => {
        // Get value tiers for both legs
        const aTier = isLegValueTier(a);
        const bTier = isLegValueTier(b);
        
        // Create a mapping of tiers to numeric values for sorting
        const tierValues = {
            'recommended': 1,
            'high-value': 2,
            'solid-value': 3,
            null: 4 // No special tier
        };
        
        // First sort by tier
        const tierDiff = tierValues[aTier] - tierValues[bTier];
        if (tierDiff !== 0) return tierDiff;
        
        // For legs in the same tier, sort by the existing criteria
        if (filters.sortBy === 'ev') {
            return b.ev - a.ev;
        } else if (filters.sortBy === 'odds') {
            return americanToDecimal(b.odds) - americanToDecimal(a.odds);
        } else if (filters.sortBy === 'probability') {
            return b.impliedProbability - a.impliedProbability;
        } else if (filters.sortBy === 'hold') {
            return a.holdPercentage - b.holdPercentage;
        }
        
        return 0;
    });

    // Add pagination logic after the sortedLegs definition
    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedLegs.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(sortedLegs.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Add the useEffect hook to update correlation when legs change
    useEffect(() => {
        if (autoDetectCorrelation && selectedLegs.length > 0) {
            console.log("Auto-correlation detection triggered by leg change");
            
            // Only run auto-detection if we have at least 2 legs or need to reset
            if (selectedLegs.length > 1) {
                // Detect if we have same game legs
                const sameGameGroups = detectSameGameLegs(selectedLegs);
                console.log("Same game groups detected:", sameGameGroups);
                
                if (sameGameGroups.length > 0) {
                    // We have same game legs - let's detect their correlation patterns
                    const correlationPatterns = [];
                    
                    sameGameGroups.forEach(group => {
                        const patterns = detectCorrelationPatterns(selectedLegs, group.gameId);
                        correlationPatterns.push(...patterns);
                    });
                    
                    console.log("Correlation patterns detected:", correlationPatterns);
                    
                    // Set correlation based on detected patterns
                    if (correlationPatterns.length > 0) {
                        // Determine average correlation value from patterns
                        const correlationValues = correlationPatterns.map(pattern => {
                            if (pattern.strength === 'optimal') return 0.7;
                            if (pattern.strength === 'strong') return 0.5;
                            if (pattern.strength === 'neutral') return 0.2;
                            if (pattern.strength === 'weak') return -0.2;
                            return 0;
                        });
                        
                        if (correlationValues.length > 0) {
                            const avgCorrelation = correlationValues.reduce((sum, val) => sum + val, 0) / correlationValues.length;
                            const newCorrelation = parseFloat(avgCorrelation.toFixed(1));
                            console.log("Auto-detected correlation value:", newCorrelation);
                            
                            // Update the correlation value in the UI
                            updateCorrelation(newCorrelation);
                        }
                    } else {
                        // Default correlation for same-game legs when no specific patterns are detected
                        console.log("Using default correlation for same-game legs: 0.3");
                        updateCorrelation(0.3);
                    }
                } else {
                    // Reset to zero for legs from different games
                    console.log("No same-game legs detected, setting correlation to 0");
                    updateCorrelation(0);
                }
            } else if (selectedLegs.length <= 1) {
                // Reset correlation to 0 when we have 0 or 1 legs
                console.log("Only one or zero legs, setting correlation to 0");
                updateCorrelation(0);
            }
        }
    }, [selectedLegs, autoDetectCorrelation]); // Only trigger on leg changes or auto-detect toggle

    // Modify the existing useEffect hook to avoid duplicate processing
    useEffect(() => {
        if (selectedLegs.length > 0) {
            console.log("Calculating parlay stats for", selectedLegs.length, "legs");
            
            // Calculate parlay stats with the current correlation
            calculateParlayStats();
        }
    }, [selectedLegs, stake, correlation]);

    const renderParlayStats = () => {
        if (selectedLegs.length === 0) {
            return (
                <div className="empty-lay-message">
                    <p>Add legs to build your lay</p>
                </div>
            );
        }
        // ... existing code ...
    };

    // Determine if a leg is from FanDuel
    const isFanDuelBookmaker = (leg) => {
        if (!leg.bookmaker) return false;
        return leg.bookmaker.toLowerCase().includes('fanduel');
    };

    // Component to render the leg metrics section with FanDuel badge if applicable
    const renderLegMetrics = (leg) => {
        return (
            <div className="leg-metrics">
                {renderEvBadge(leg.ev)}
                <div className="implied-prob">
                    {(leg.impliedProbability * 100).toFixed(1)}%
                </div>
                <div className="hold-pct">
                    Hold: {(leg.holdPercentage * 100).toFixed(1)}%
                </div>
                {isFanDuelBookmaker(leg) && (
                    <Badge bg="info" className="bookmaker-badge">FD</Badge>
                )}
            </div>
        );
    };

    return (
        <div className="lay-builder">
            <Row className="mb-4">
                <Col>
                    <h3 className="lay-builder-title">Lay Builder</h3>
                    <p className="lay-builder-description">
                        Build your own lay by selecting legs from available options. View expected value, 
                        implied probability, and hold percentage for each selection and your combined lay.
                    </p>
                </Col>
            </Row>

            <Row className="g-4">
                {/* Available Legs Pane */}
                <Col lg={7}>
                    <Card className="available-legs-card">
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Available Legs</h5>
                                <Badge bg="primary">{filteredLegs.length} options</Badge>
                            </div>
                            
                            {/* Search and Filters */}
                            <div className="filters-container mt-3">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Search player, team, or prop type..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <InputGroup.Text><BsFilter /></InputGroup.Text>
                                </InputGroup>
                                
                                <Row className="g-2">
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                Type {renderInfoTooltip("Filter by leg type")}
                                            </Form.Label>
                                            <Form.Select
                                                value={filters.type}
                                                onChange={(e) => updateFilters({ type: e.target.value })}
                                                className="form-select-sm"
                                            >
                                                <option key="all-type" value="all">All Types</option>
                                                <option key="moneyline-type" value="moneyline">Moneyline</option>
                                                <option key="spread-type" value="spread">Spread</option>
                                                <option key="total-type" value="total">Total</option>
                                                <option key="player_prop-type" value="player_prop">Player Props</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                Team {renderInfoTooltip("Filter by team")}
                                            </Form.Label>
                                            <Form.Select
                                                value={filters.team}
                                                onChange={(e) => updateFilters({ team: e.target.value })}
                                                className="form-select-sm"
                                            >
                                                <option key="all-team" value="all">All Teams</option>
                                                {filterOptions.teams.filter(team => team !== 'all').map(team => (
                                                    <option key={`team-${team}`} value={team}>{team}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                Game Time {renderInfoTooltip("Filter by game starting time")}
                                            </Form.Label>
                                            <Form.Select
                                                value={gameTimeFilter}
                                                onChange={(e) => setGameTimeFilter(e.target.value)}
                                                className="form-select-sm"
                                            >
                                                <option key="all-time" value="all">All Times</option>
                                                {getTimeSlots().filter(slot => slot !== 'all').map(slot => (
                                                    <option key={`time-${slot}`} value={slot}>{slot}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                Sort By {renderInfoTooltip("Sort results")}
                                            </Form.Label>
                                            <Form.Select
                                                value={filters.sortBy}
                                                onChange={(e) => updateFilters({ sortBy: e.target.value })}
                                                className="form-select-sm"
                                            >
                                                <option key="ev-sort" value="ev">Expected Value</option>
                                                <option key="odds-sort" value="odds">Odds</option>
                                                <option key="probability-sort" value="probability">Probability</option>
                                                <option key="hold-sort" value="hold">Hold %</option>
                                                <option key="player-sort" value="player">Player</option>
                                                <option key="team-sort" value="team">Team</option>
                                                <option key="type-sort" value="type">Type</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="d-flex flex-wrap gap-2">
                                        <Form.Check 
                                            type="switch"
                                            id="recommended-switch"
                                            label="Recommended"
                                            checked={showRecommendedOnly}
                                            onChange={(e) => setShowRecommendedOnly(e.target.checked)}
                                            className="fw-semibold text-warning"
                                        />
                                        <Form.Check 
                                            type="switch"
                                            id="high-value-switch"
                                            label="High Value"
                                            checked={showHighValueOnly}
                                            onChange={(e) => setShowHighValueOnly(e.target.checked)}
                                            className="fw-semibold text-success"
                                        />
                                        <Form.Check 
                                            type="switch"
                                            id="solid-value-switch"
                                            label="Solid Value"
                                            checked={showSolidValueOnly}
                                            onChange={(e) => setShowSolidValueOnly(e.target.checked)}
                                            className="fw-semibold text-info"
                                        />
                                    </div>
                                    <div className="d-flex flex-wrap gap-2 align-items-center">
                                        <Badge bg="warning" className="ms-2">
                                            {filteredLegs.filter(leg => isLegValueTier(leg) === 'recommended').length} recommended
                                        </Badge>
                                        <Badge bg="success" className="ms-1">
                                            {filteredLegs.filter(leg => isLegValueTier(leg) === 'high-value').length} high value
                                        </Badge>
                                        <Badge bg="info" className="ms-1">
                                            {filteredLegs.filter(leg => isLegValueTier(leg) === 'solid-value').length} solid value
                                        </Badge>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="legs-list-container">
                            <div className="legs-list">
                                {currentItems.length === 0 ? (
                                    <div className="no-legs-message">
                                        <p>No options match your filters. Try adjusting your search criteria.</p>
                                    </div>
                                ) : (
                                    currentItems.map(leg => (
                                        <div key={leg.id} className={`leg-item ${getLegClassName(leg)}`}>
                                            <div className="leg-details">
                                                {renderLegBadge(leg)}
                                                
                                                <div className="leg-type-badge">
                                                    <Badge bg={
                                                        leg.type === 'moneyline' ? 'primary' :
                                                        leg.type === 'spread' ? 'info' :
                                                        leg.type === 'total' ? 'warning' : 'success'
                                                    } className={`text-capitalize ${leg.type === 'moneyline' ? 'moneyline-badge' : ''}`}>
                                                        {leg.type === 'player_prop' ? 'Player' : leg.type}
                                                    </Badge>
                                                    
                                                    {leg.type === 'player_prop' && (
                                                        <div className="player-name mt-2">
                                                            {leg.player || 'Unknown Player'}
                                                        </div>
                                                    )}
                                                </div>
                                                
                                                <div className="leg-main-info">
                                                    {leg.type === 'player_prop' ? (
                                                        <div className="player-prop-container">
                                                            <div className="prop-details">
                                                                {leg.propType && <span className="prop-type">{leg.propType}</span>}
                                                                {leg.overUnder && <span className={`over-under over-under-${leg.overUnder.toLowerCase()}`}>{leg.overUnder}</span>}
                                                                {leg.points && <span className="prop-line">{leg.points}</span>}
                                                            </div>
                                                            {leg.team && <div className="team-name">{leg.team}</div>}
                                                            {leg.matchup && <div className="matchup-name">{leg.matchup}</div>}
                                                        </div>
                                                    ) : leg.type === 'spread' ? (
                                                        <>
                                                            <div className="team-name">{leg.team}</div>
                                                            <div className="spread-details">{leg.points}</div>
                                                            {leg.matchup && <div className="matchup-name">{leg.matchup}</div>}
                                                        </>
                                                    ) : leg.type === 'total' ? (
                                                        <>
                                                            <div className="total-details">{leg.description}</div>
                                                            <div className="matchup-details">{leg.matchup}</div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="moneyline-details">{leg.team} ML</div>
                                                            {leg.matchup && <div className="matchup-name">{leg.matchup}</div>}
                                                        </>
                                                    )}
                                                </div>
                                                
                                                {renderLegMetrics(leg)}
                                            </div>
                                            
                                            <div className="leg-actions">
                                                <div className="odds-display">
                                                    {showDecimalOdds 
                                                        ? (leg.odds !== undefined && leg.odds !== null ? americanToDecimal(leg.odds).toFixed(2) : "N/A") 
                                                        : (leg.odds !== undefined && leg.odds !== null ? 
                                                            <span className={leg.odds > 0 ? "text-success" : "text-danger"}>
                                                                {formatOdds(leg.odds)}
                                                            </span> 
                                                            : "N/A")}
                                                </div>
                                                <Button
                                                    variant={leg.ev > 0 ? "outline-success" : "outline-primary"}
                                                    size="sm"
                                                    onClick={() => addLeg(leg)}
                                                    disabled={selectedLegs.some(selected => selected.id === leg.id)}
                                                    className="add-leg-btn"
                                                >
                                                    <BsPlus /> Add
                                                </Button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                            
                            {renderPagination()}
                        </Card.Body>
                    </Card>
                </Col>

                {/* Selected Legs Pane */}
                <Col lg={5}>
                    <Card className="selected-legs-card">
                        <Card.Header>
                            <h5 className="mb-0">Your Lay</h5>
                            
                            <div className="lay-controls">
                                <Row className="g-2 mt-3">
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                Stake ($) {renderInfoTooltip("Amount to wager")}
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={stake}
                                                onChange={(e) => updateStake(e.target.value)}
                                                min="0"
                                                step="1"
                                                className="form-control-sm"
                                                placeholder="10"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label className="d-flex align-items-center">
                                                Correlation {renderInfoTooltip("Correlation between legs (-1 to 1)")}
                                            </Form.Label>
                                            <div className="correlation-control">
                                                <Form.Control
                                                    type="number"
                                                    value={correlation}
                                                    onChange={(e) => updateCorrelation(e.target.value)}
                                                    min="-1"
                                                    max="1"
                                                    step="0.1"
                                                    className="form-control-sm"
                                                    disabled={autoDetectCorrelation}
                                                />
                                                <div className="d-flex align-items-center">
                                                    <Form.Check 
                                                        type="switch"
                                                        id="auto-correlation-switch"
                                                        checked={autoDetectCorrelation}
                                                        onChange={(e) => setAutoDetectCorrelation(e.target.checked)}
                                                    />
                                                    <span className="auto-label">Auto</span>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Card.Header>
                        <Card.Body className="selected-legs-body">
                            {selectedLegs.length === 0 ? (
                                <div className="empty-lay-message">
                                    <p>Your lay is empty. Add legs from the available options.</p>
                                </div>
                            ) : (
                                <>
                                    <div className="selected-legs-list">
                                        {selectedLegs.map(leg => (
                                            <div key={leg.id} className="selected-leg-item">
                                                <div className="selected-leg-details">
                                                    <div className="selected-leg-type">
                                                        <Badge bg={
                                                            leg.type === 'moneyline' ? 'primary' :
                                                            leg.type === 'spread' ? 'info' :
                                                            leg.type === 'total' ? 'warning' : 'success'
                                                        } className={`text-capitalize ${leg.type === 'moneyline' ? 'moneyline-badge' : ''}`}>
                                                            {leg.type === 'player_prop' ? 'Player' : leg.type}
                                                        </Badge>
                                                        
                                                        {leg.type === 'player_prop' && (
                                                            <div className="selected-player-name mt-2">
                                                                {leg.player || 'Unknown Player'}
                                                            </div>
                                                        )}
                                                    </div>
                                                    
                                                    <div className="selected-leg-main-info">
                                                        {leg.type === 'player_prop' ? (
                                                            <div className="player-prop-container">
                                                                <div className="prop-details">
                                                                    {leg.propType && <span className="prop-type">{leg.propType}</span>}
                                                                    {leg.overUnder && <span className={`selected-over-under selected-over-under-${leg.overUnder.toLowerCase()}`}>{leg.overUnder}</span>}
                                                                    {leg.points && <span className="prop-line">{leg.points}</span>}
                                                                </div>
                                                                {leg.team && <div className="selected-team-name">{leg.team}</div>}
                                                                {leg.matchup && <div className="selected-matchup-name">{leg.matchup}</div>}
                                                                <div className="selected-leg-odds">
                                                                    {showDecimalOdds 
                                                                        ? (leg.decimalOdds ? leg.decimalOdds.toFixed(2) : americanToDecimal(leg.odds).toFixed(2))
                                                                        : <span className={leg.odds > 0 ? "text-success" : "text-danger"}>
                                                                            {formatOdds(leg.odds)}
                                                                          </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : leg.type === 'spread' ? (
                                                            <>
                                                                <div className="selected-team-name">{leg.team}</div>
                                                                <div className="selected-spread-details">{leg.points}</div>
                                                                {leg.matchup && <div className="selected-matchup-name">{leg.matchup}</div>}
                                                                <div className="selected-leg-odds">
                                                                    {showDecimalOdds 
                                                                        ? (leg.decimalOdds ? leg.decimalOdds.toFixed(2) : americanToDecimal(leg.odds).toFixed(2))
                                                                        : <span className={leg.odds > 0 ? "text-success" : "text-danger"}>
                                                                            {formatOdds(leg.odds)}
                                                                          </span>
                                                                    }
                                                                </div>
                                                            </>
                                                        ) : leg.type === 'total' ? (
                                                            <>
                                                                <div className="selected-total-details">{leg.description}</div>
                                                                <div className="selected-matchup-details">{leg.matchup}</div>
                                                                <div className="selected-leg-odds">
                                                                    {showDecimalOdds 
                                                                        ? (leg.decimalOdds ? leg.decimalOdds.toFixed(2) : americanToDecimal(leg.odds).toFixed(2))
                                                                        : <span className={leg.odds > 0 ? "text-success" : "text-danger"}>
                                                                            {formatOdds(leg.odds)}
                                                                          </span>
                                                                    }
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="selected-moneyline-details player-moneyline">{leg.team} ML</div>
                                                                {leg.matchup && <div className="selected-matchup-name">{leg.matchup}</div>}
                                                                <div className="selected-leg-odds">
                                                                    {showDecimalOdds 
                                                                        ? (leg.decimalOdds ? leg.decimalOdds.toFixed(2) : americanToDecimal(leg.odds).toFixed(2))
                                                                        : <span className={leg.odds > 0 ? "text-success" : "text-danger"}>
                                                                            {formatOdds(leg.odds)}
                                                                          </span>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    
                                                    <div className="selected-leg-odds">
                                                        {showDecimalOdds 
                                                            ? (leg.odds !== undefined && leg.odds !== null ? americanToDecimal(leg.odds).toFixed(2) : "N/A") 
                                                            : (leg.odds !== undefined && leg.odds !== null ? formatOdds(leg.odds) : "N/A")}
                                                    </div>
                                                </div>
                                                
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => removeLeg(leg.id)}
                                                    className="remove-leg-btn"
                                                >
                                                    <BsXCircle />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Lay Summary - More Compact Version */}
                                    <div className="lay-summary p-2">
                                        <div className="d-flex flex-column">
                                            {/* Stats in 3-column layout */}
                                            <div className="lay-stats-grid mb-2">
                                                <div className="stat-column">
                                                    <div className="stat-group">
                                                        <div className="stat-label">ODDS:</div>
                                                        <div className="stat-value odds-value">
                                                            {showDecimalOdds 
                                                                ? (parlayStats.decimalOdds !== undefined && parlayStats.decimalOdds !== null ? parlayStats.decimalOdds.toFixed(2) : "N/A")
                                                                : (parlayStats.americanOdds !== undefined && parlayStats.americanOdds !== null ? formatOdds(parlayStats.americanOdds) : "N/A")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stat-column">
                                                    <div className="stat-group">
                                                        <div className="stat-label">PROB:</div>
                                                        {(() => {
                                                            const probValue = parlayStats.impliedProbability * 100;
                                                            const probClass = 
                                                                probValue >= 65 ? 'high-prob' : 
                                                                probValue >= 35 ? 'medium-prob' : 'low-prob';
                                                            
                                                            return (
                                                                <div className={`stat-value ${probClass}`}>
                                                                    {probValue.toFixed(1)}%
                                                                </div>
                                                            );
                                                        })()}
                                                    </div>
                                                </div>
                                                <div className="stat-column">
                                                    <div className="stat-group">
                                                        <div className="stat-label">EV:</div>
                                                        {(() => {
                                                            const evValue = parlayStats.expectedValue;
                                                            const evClass = 
                                                                evValue >= 8 ? 'text-success' : 
                                                                evValue >= 3 ? 'medium-ev' : 
                                                                evValue >= 0 ? 'text-secondary' : 'text-danger';
                                                            
                                                            return (
                                                                <div className={`stat-value ${evClass}`}>
                                                                    {evValue.toFixed(1)}%
                                                                </div>
                                                            );
                                                        })()}
                                                    </div>
                                                </div>
                                                <div className="stat-column">
                                                    <div className="stat-group">
                                                        <div className="stat-label">HOLD:</div>
                                                        {(() => {
                                                            const holdValue = parlayStats.holdPercentage * 100;
                                                            const holdClass = 
                                                                holdValue <= 4 ? 'text-success' : 
                                                                holdValue <= 8 ? 'text-secondary' : 'text-danger';
                                                            
                                                            return (
                                                                <div className={`stat-value ${holdClass}`}>
                                                                    {holdValue.toFixed(1)}%
                                                                </div>
                                                            );
                                                        })()}
                                                    </div>
                                                </div>
                                                <div className="stat-column">
                                                    {/* Decimal odds label and checkbox */}
                                                    <div className="form-check form-switch mt-1">
                                                        <input 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            id="flexSwitchCheckDecimal" 
                                                            checked={showDecimalOdds}
                                                            onChange={() => setShowDecimalOdds(!showDecimalOdds)}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDecimal">
                                                            <small>Decimal odds</small>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="stat-column">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <div className="potential-return mt-1">
                                                            <div className="return-label">RETURN</div>
                                                            <span className="return-value">${parlayStats.potentialReturn.toFixed(2)}</span>
                                                        </div>
                                                        {selectedLegs.length > 0 && (
                                                            <Button 
                                                                variant="danger" 
                                                                size="sm" 
                                                                onClick={clearLegs}
                                                                className="clear-btn mt-1"
                                                                title="Clear all legs"
                                                            >
                                                                <BsTrash />
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {/* Smart Lay Analysis - Show full text version */}
                                            {selectedLegs.length > 1 && (() => {
                                                const analysis = analyzeLayStrength(selectedLegs, correlation);
                                                return analysis && (
                                                    <div className="compact-strength">
                                                        <div className={`mini-badge ${analysis.strength}`}>
                                                            <i className={`bi ${analysis.icon}`}></i>
                                                            <small>{analysis.message}</small>
                                                        </div>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default LayBuilder; 
import React, { useEffect } from 'react';
import { Container, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthButtons from './AuthButtons';

const Layout = ({ children, tabs }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isCheckout = location.pathname === '/checkout';

    // Get tab from current path
    const getActiveTabFromPath = () => {
        const path = location.pathname;
        if (path === '/') return 'home';
        if (path.includes('/dashboard')) return 'dashboard';
        if (path.includes('/odds')) return 'odds';
        if (path.includes('/stats')) return 'stats';
        if (path.includes('/plans') || path.includes('/checkout')) return 'plans';
        if (path.includes('/support')) return 'support';
        if (path.includes('/about')) return 'about';
        if (path.includes('/research')) return 'research';
        if (path.includes('/profile')) return 'profile';
        if (path.includes('/layodds')) return 'layodds';
        return 'home'; // Default tab is now home
    };

    const handleTabSelect = (key) => {
        // Navigate to the appropriate route based on the selected tab
        switch(key) {
            case 'home':
                navigate('/');
                break;
            case 'dashboard':
                navigate('/dashboard');
                break;
            case 'stats':
                navigate('/stats');
                break;
            case 'odds':
                navigate('/odds');
                break;
            case 'layodds':
                navigate('/layodds');
                break;
            case 'research':
                navigate('/research');
                break;
            case 'plans':
                navigate('/plans');
                break;
            case 'checkout':
                console.log("Direct navigation to checkout attempted, redirecting to plans");
                navigate('/plans');
                break;
            case 'support':
                navigate('/support');
                break;
            case 'about':
                navigate('/about');
                break;
            case 'profile':
                navigate('/profile');
                break;
            default:
                navigate('/');
        }
    };
    
    const navigateToAbout = () => {
        navigate('/about');
    };

    // Apply app-wide theme class to body
    useEffect(() => {
        // Add dark theme class to body
        document.body.classList.add('dark-theme');
        document.body.style.backgroundColor = '#121212';
        document.body.style.color = 'white';
        document.body.style.minHeight = '100vh';
        
        // Clean up when component unmounts
        return () => {
            document.body.classList.remove('dark-theme');
            document.body.style.backgroundColor = '';
            document.body.style.color = '';
            document.body.style.minHeight = '';
        };
    }, []);

    return (
        <div className="app-layout" style={{ 
            backgroundColor: '#121212',
            color: 'white',
            minHeight: '100vh'
        }}>
            <Container className="mt-4">
                <div className="app-header">
                    <div className="header-content">
                        <div className="title-with-equation">
                            <h1>
                                <span className="clutch">ClutchSports</span>
                                <span className="rest"> NBA Stats Hub</span>
                            </h1>
                            <div className="header-equation" onClick={navigateToAbout} role="button" tabIndex="0">
                                <span className="mini-eq-term">r</span>
                                <span className="mini-eq-operator">×</span>
                                <span className="mini-eq-term">l</span>
                                <span className="mini-eq-operator">=</span>
                                <span className="mini-eq-result">p</span>
                            </div>
                        </div>
                        <AuthButtons />
                    </div>
                </div>
                {tabs && (
                    <Tabs 
                        activeKey={isCheckout ? 'plans' : getActiveTabFromPath()}
                        id="nba-stats-tabs" 
                        className="mb-3 custom-tabs"
                        onSelect={handleTabSelect}
                    >
                        {tabs}
                    </Tabs>
                )}
                <div className="content-wrapper" style={{ minHeight: '70vh' }}>
                    {children}
                </div>
            </Container>
        </div>
    );
};

export default Layout; 
import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Card, ListGroup, Row, Col, Badge, Spinner, Alert, Nav, Tab, Tabs, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { calculatePlayerPredictions } from './PlayerPredictions';
import { RAPID_API_CONFIG } from '../config/constants';
import { NBA_TEAMS, TEAM_CODES } from '../utils/teamMappings';
import { makeApiRequest } from '../utils/apiUtils';
import { useLocation } from 'react-router-dom';
import './ResearchTab.css';

// Cache to store team rosters (teamId -> [playerIds])
const teamRostersCache = {};

// Function to ensure we have all team rosters data
const loadAllTeamRosters = async () => {
    try {
        // Only load once
        if (Object.keys(teamRostersCache).length > 0) {
            return teamRostersCache;
        }
        
        console.log("Loading all team rosters...");
        
        // Get all teams
        const teamsResponse = await makeApiRequest(
            `${RAPID_API_CONFIG.BASE_URL}/teams`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                    'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                }
            }
        );
        
        if (!teamsResponse?.body || !Array.isArray(teamsResponse.body)) {
            console.error("Failed to fetch teams data");
            return teamRostersCache;
        }
        
        const teams = teamsResponse.body;
        console.log(`Found ${teams.length} teams`);
        
        // Get current season
        const currentYear = new Date().getFullYear();
        const season = `${currentYear-1}-${String(currentYear).slice(-2)}`;
        
        // Load roster for each team
        for (const team of teams) {
            try {
                const teamCode = team.teamCode || team.tricode || team.abbreviation;
                if (!teamCode) continue;
                
                const rosterResponse = await makeApiRequest(
                    `${RAPID_API_CONFIG.BASE_URL}/teams/${teamCode}/roster/${season}`,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                            'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                        }
                    }
                );
                
                if (rosterResponse?.body && Array.isArray(rosterResponse.body)) {
                    const roster = rosterResponse.body;
                    
                    // Map players to team
                    teamRostersCache[teamCode] = roster.map(player => ({
                        playerId: player.playerId || player.playerID || player.id,
                        playerName: player.playerName || player.firstName + ' ' + player.lastName,
                        teamCode
                    }));
                    
                    console.log(`Loaded ${teamRostersCache[teamCode].length} players for ${teamCode}`);
                }
            } catch (error) {
                console.error(`Error loading roster for team ${team.teamName}:`, error);
            }
        }
        
        console.log(`Loaded rosters for ${Object.keys(teamRostersCache).length} teams`);
        return teamRostersCache;
    } catch (error) {
        console.error("Error loading team rosters:", error);
        return teamRostersCache;
    }
};

// Function to find a player's team from rosters
const findPlayerTeamFromRosters = async (playerId, playerName) => {
    for (const teamId in teamRostersCache) {
        if (teamRostersCache[teamId].some(player => player.id === playerId || player.name === playerName)) {
            return teamId;
        }
    }
        return null;
};

const ResearchTab = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const urlActiveTab = searchParams.get('activeTab');

    // Initialize activeTab with URL parameter if present
    const [activeTab, setActiveTab] = useState(urlActiveTab || 'player');

    // Update activeTab when URL parameter changes
    useEffect(() => {
        if (urlActiveTab) {
            setActiveTab(urlActiveTab);
        }
    }, [urlActiveTab]);

    // Player Search state
    const [playerSearchTerm, setPlayerSearchTerm] = useState('');
    const [playerSuggestions, setPlayerSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState('2024-2025');
    const [playerStats, setPlayerStats] = useState(null);
    const [playerLoading, setPlayerLoading] = useState(false);
    const [playerError, setPlayerError] = useState(null);
    const [predictions, setPredictions] = useState(null);
    const [predictionsLoading, setPredictionsLoading] = useState(false);
    const [predictionsError, setPredictionsError] = useState(null);
    
    // Reference for search container
    const searchContainerRef = useRef(null);
    
    // Click outside handler to dismiss suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    // Team Research state
    const [selectedTeam, setSelectedTeam] = useState('');
    const [teamRoster, setTeamRoster] = useState([]);
    const [teamLoading, setTeamLoading] = useState(false);
    const [teamError, setTeamError] = useState(null);
    const [teamSchedule, setTeamSchedule] = useState([]);
    const [teamStats, setTeamStats] = useState({
        avgPoints: 0,
        avgOppPoints: 0,
        wins: 0,
        losses: 0
    });
    const [teamStarPlayers, setTeamStarPlayers] = useState({
        points: null,
        assists: null,
        rebounds: null,
        steals: null,
        blocks: null,
        threePointersMade: null,
        fieldGoalPercentage: null,
        threePointPercentage: null
    });
    
    // Add a team tab state inside ResearchTab component
    const [teamActiveTab, setTeamActiveTab] = useState('roster');
    
    // Seasons list
    const seasons = ["2024-2025", "2023-2024", "2022-2023"];
    
    // Debounce function for search
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    
    // Search for players
    const searchPlayers = async (searchTerm) => {
        if (!searchTerm || searchTerm.length < 2) {
            setPlayerSuggestions([]);
            return [];
        }
        
        try {
            setPlayerLoading(true);
            setPlayerError(null);
            
            // Parse the search term for first and last name
            const terms = searchTerm.split(' ');
            const firstname = terms[0] || '';
            const lastname = terms.length > 1 ? terms.slice(1).join(' ') : '';
            
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/players/search`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                    },
                    body: JSON.stringify({
                        pageSize: 100,
                        firstname,
                        lastname
                    })
                }
            );
            
            if (response?.body) {
                const suggestions = response.body.map(player => ({
                    id: player.playerId,
                    name: `${player.firstName} ${player.lastName}`,
                    headshotUrl: player.headshotUrl,
                    teams: player.teams,
                    position: player.positions,
                    accolades: player.accolades
                }));
                
                setPlayerSuggestions(suggestions);
                return suggestions; // Return the suggestions
            } else {
                setPlayerSuggestions([]);
                return []; // Return empty array
            }
        } catch (error) {
            console.error('Error searching players:', error);
            setPlayerError('Failed to search players. Please try again.');
            setPlayerSuggestions([]);
            return []; // Return empty array on error
        } finally {
            setPlayerLoading(false);
        }
    };
    
    // Debounced search function using the updated searchPlayers
    const debouncedSearch = debounce((searchTerm) => {
        searchPlayers(searchTerm)
            .then(suggestions => {
                // If suggestions were found and the search box is still focused, show them
                if (suggestions.length > 0) {
                    setShowSuggestions(true);
                }
            })
            .catch(error => {
                console.error("Error in debounced search:", error);
            });
    }, 500);
    
    // Update the handlePlayerSearchChange function
    const handlePlayerSearchChange = (e) => {
        const value = e.target.value;
        const previousValue = playerSearchTerm;
        setPlayerSearchTerm(value);
        
        // Only reset player stats, but don't clear selectedPlayer yet
        if (selectedPlayer && previousValue.length > 0 && 
            value.trim().length > 0 &&
            !value.toLowerCase().includes(selectedPlayer.name.toLowerCase()) &&
            !selectedPlayer.name.toLowerCase().includes(value.toLowerCase())) {
            console.log("Clearing stats due to name change");
            // Only clear stats, but keep selectedPlayer for reference
            setPlayerStats(null);
        }
        
        if (value.trim().length >= 2) {
            debouncedSearch(value);
        } else {
            setPlayerSuggestions([]);
            setShowSuggestions(false);
        }
    };
    
    // Handle player selection
    const handlePlayerSelect = (player) => {
        // Reset state
        setPlayerStats(null);
        setPlayerError(null);
        setPlayerLoading(true);
        
        // Update UI state
        setSelectedPlayer(player);
        setPlayerSearchTerm(`${player.name}`);
        setShowSuggestions(false);
        setPlayerSuggestions([]);
        
        console.log("Player selected:", player.name, "ID:", player.id);
        
        // Fetch player stats and pass the player object directly
        fetchPlayerStats(player.id, selectedSeason, player);
    };
    
    // Fetch player stats
    const fetchPlayerStats = async (playerId, season, playerObj) => {
        try {
            setPlayerLoading(true);
            setPlayerError(null);
            
            console.log("Fetching stats for player ID:", playerId, "Season:", season);
            
            const seasonEndYear = season.split('-')[1];
            
            // Load team rosters in the background at the start
            loadAllTeamRosters();
            
            // Fetch regular game stats
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/players/${playerId}/games/${seasonEndYear}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                    },
                    body: JSON.stringify({
                        pageSize: 100
                    })
                }
            );
            
            // Log the response to verify game data is being retrieved
            console.log("Game data response:", response);
            
            // Also fetch advanced stats
            const advancedStats = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/players/${playerId}/stats/Advanced?seasonType=Regular`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                    }
                }
            );
            
            // Process advanced stats
            let seasonAdvancedStats = {
                usagePercentage: 0,
                playerEfficiencyRating: 0,
                stealPercentage: 0,
                assistPercentage: 0,
                totalReboundPercentage: 0,
                blockPercentage: 0
            };
            
            let careerAdvancedStats = {
                usagePercentage: 0,
                playerEfficiencyRating: 0,
                stealPercentage: 0,
                assistPercentage: 0,
                totalReboundPercentage: 0,
                blockPercentage: 0
            };
            
            if (advancedStats?.body) {
                // Find the selected season's advanced stats
                const seasonStats = advancedStats.body.find(stat => stat.season === season);
                if (seasonStats) {
                    seasonAdvancedStats.usagePercentage = parseFloat(seasonStats.usagePercentage || 0);
                    seasonAdvancedStats.playerEfficiencyRating = parseFloat(seasonStats.playerEfficiencyRating || 0);
                    seasonAdvancedStats.stealPercentage = parseFloat(seasonStats.stealPercentage || 0);
                    seasonAdvancedStats.assistPercentage = parseFloat(seasonStats.assistPercentage || 0);
                    seasonAdvancedStats.totalReboundPercentage = parseFloat(seasonStats.totalReboundPercentage || 0);
                    seasonAdvancedStats.blockPercentage = parseFloat(seasonStats.blockPercentage || 0);
                    console.log(`Found advanced stats for season ${season}:`, seasonAdvancedStats);
                } else {
                    console.log(`No advanced stats found for season ${season}`);
                }
                
                // Also get career stats for reference
                const careerStats = advancedStats.body.find(stat => stat.season === "Career");
                if (careerStats) {
                    careerAdvancedStats.usagePercentage = parseFloat(careerStats.usagePercentage || 0);
                    careerAdvancedStats.playerEfficiencyRating = parseFloat(careerStats.playerEfficiencyRating || 0);
                    careerAdvancedStats.stealPercentage = parseFloat(careerStats.stealPercentage || 0);
                    careerAdvancedStats.assistPercentage = parseFloat(careerStats.assistPercentage || 0);
                    careerAdvancedStats.totalReboundPercentage = parseFloat(careerStats.totalReboundPercentage || 0);
                    careerAdvancedStats.blockPercentage = parseFloat(careerStats.blockPercentage || 0);
                }
            }
            
            if (response?.body) {
                const gameStats = response.body;
                console.log("Game stats retrieved:", gameStats.length, "games");
                
                // Extract team information from game data if available
                let teamInfo = null;
                
                if (gameStats && gameStats.length > 0) {
                    const firstGame = gameStats[0];
                    console.log("First game data:", firstGame);
                    
                    // Try to extract team information from the game data
                    // Check different possible properties that might contain team information
                    teamInfo = firstGame.teamAbbreviation || 
                               firstGame.team || 
                               firstGame.teamName || 
                               firstGame.teamCode || 
                               firstGame.teamCity;
                    
                    // Try to extract from matchup if available
                    if (!teamInfo && firstGame.matchup) {
                        const matchup = firstGame.matchup;
                        // Matchups are often formatted like "GSW vs. LAL" or "GSW @ LAL"
                        const teamCodes = matchup.split(/\s+(?:vs\.?|@)\s+/);
                        if (teamCodes.length >= 2) {
                            // Take the first team code (usually the player's team)
                            teamInfo = teamCodes[0].trim();
                        }
                    }
                    
                    console.log("Extracted team info from game data:", teamInfo);
                }
                
                // Use the player object that was passed directly rather than relying on state
                if (playerObj) {
                    console.log("Processing stats for:", playerObj.name);
                    
                    // Try to fetch additional player info with team data
                    let playerTeamFromAPI = null;
                    try {
                        const playerInfo = await makeApiRequest(
                            `${RAPID_API_CONFIG.BASE_URL}/players/${playerId}`,
                            {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                                    'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                                }
                            }
                        );
                        
                        if (playerInfo?.body) {
                            console.log("Player API info:", playerInfo.body);
                            playerTeamFromAPI = playerInfo.body.team || 
                                               playerInfo.body.teamName || 
                                               playerInfo.body.teamAbbreviation;
                            
                            console.log("Team from API:", playerTeamFromAPI);
                        }
                    } catch (error) {
                        console.error("Error fetching player team info:", error);
                        // Continue with teamInfo from game data
                    }
                    
                    // Try to find the player's team from the roster data
                    const teamFromRoster = await findPlayerTeamFromRosters(playerId, playerObj.name);
                    console.log("Team from roster data:", teamFromRoster);
                    
                    // Use team info from API or game data or roster data or player mapping
                    const finalTeamInfo = playerTeamFromAPI || teamInfo || teamFromRoster;
                    console.log("Final team info:", finalTeamInfo);
                    
                    // Map common players to teams as a fallback
                    const playerToTeam = {
                        "LeBron James": "LAL",
                        "Anthony Davis": "LAL",
                        "Stephen Curry": "GSW",
                        "Klay Thompson": "GSW",
                        "Draymond Green": "GSW",
                        "Giannis Antetokounmpo": "MIL",
                        "Kevin Durant": "PHX",
                        "Devin Booker": "PHX",
                        "Joel Embiid": "PHI",
                        "Tyrese Maxey": "PHI", 
                        "Nikola Jokic": "DEN",
                        "Jamal Murray": "DEN",
                        "Luka Doncic": "DAL",
                        "Kyrie Irving": "DAL",
                        "Jayson Tatum": "BOS",
                        "Jaylen Brown": "BOS",
                        "Damian Lillard": "MIL",
                        "Jimmy Butler": "MIA",
                        "Bam Adebayo": "MIA",
                        "Kawhi Leonard": "LAC",
                        "Paul George": "LAC",
                        "Ja Morant": "MEM",
                        "Trae Young": "ATL",
                        "De'Aaron Fox": "SAC",
                        "Domantas Sabonis": "SAC",
                        "Donovan Mitchell": "CLE",
                        "Zion Williamson": "NOP",
                        "Victor Wembanyama": "SAS",
                        "Shai Gilgeous-Alexander": "OKC",
                        "Chet Holmgren": "OKC",
                        "Anthony Edwards": "MIN",
                        "Karl-Anthony Towns": "MIN",
                        "LaMelo Ball": "CHA",
                        "Paolo Banchero": "ORL",
                        "Franz Wagner": "ORL",
                        "Cade Cunningham": "DET",
                        "Jalen Brunson": "NYK",
                        "Julius Randle": "NYK"
                    };
                    
                    const playerTeamMapping = playerToTeam[playerObj.name];
                    if (!finalTeamInfo && playerTeamMapping) {
                        console.log("Using team mapping for player:", playerTeamMapping);
                    }
                    
                    const processedStats = calculatePlayerStats(gameStats, {
                        name: playerObj.name,
                        position: playerObj.position || 'N/A',
                        headshotUrl: playerObj.headshotUrl,
                        team: finalTeamInfo || playerTeamMapping // Use the best team info available
                    }, seasonAdvancedStats, careerAdvancedStats);
                    
                    // Log the processed stats to verify games array is included
                    console.log("Processed stats:", processedStats);
                    console.log("Player team info:", finalTeamInfo || playerTeamMapping);
                    
                    setPlayerStats(processedStats);
                    
                    // Fetch predictions for the player
                    try {
                        setPredictionsLoading(true);
                        setPredictionsError(null);
                        const predictions = await calculatePlayerPredictions(processedStats);
                        setPredictions(predictions);
                    } catch (error) {
                        console.error("Error calculating predictions:", error);
                        setPredictionsError("Failed to calculate predictions");
                    } finally {
                        setPredictionsLoading(false);
                    }
                } else {
                    // Fallback to selectedPlayer if playerObj wasn't provided (should not happen)
                    console.error("No player object provided");
                    setPlayerError('Player information missing. Please try again.');
                }
            } else {
                setPlayerStats(null);
                setPlayerError('No stats available for this player and season.');
            }
        } catch (error) {
            console.error("Error fetching player stats:", error);
            setPlayerError('Failed to fetch player stats. Please try again.');
        } finally {
            setPlayerLoading(false);
        }
    };
    
    // Calculate player stats from game data
    const calculatePlayerStats = (games, player, seasonAdvancedStats = {}, careerAdvancedStats = {}) => {
        if (!games || !games.length) return null;
        if (!player) return null; // Add null check for player
        
        console.log("Player in calculatePlayerStats:", player);
        
        const filteredGames = games.filter(game => game.minutesPlayed);
        
        if (filteredGames.length === 0) return null;
        
        // Add advanced stats from both season and career
        const advancedStats = {
            usagePercentage: seasonAdvancedStats.usagePercentage || 0,
            playerEfficiencyRating: seasonAdvancedStats.playerEfficiencyRating || 0,
            careerUsagePercentage: careerAdvancedStats.usagePercentage || 0,
            careerPER: careerAdvancedStats.playerEfficiencyRating || 0,
            stealPercentage: seasonAdvancedStats.stealPercentage || 0,
            assistPercentage: seasonAdvancedStats.assistPercentage || 0,
            totalReboundPercentage: seasonAdvancedStats.totalReboundPercentage || 0,
            blockPercentage: seasonAdvancedStats.blockPercentage || 0
        };
        
        // Initialize totals
        const totals = {
            gamesPlayed: filteredGames.length,
            points: 0,
            assists: 0,
            totalRebounds: 0,
            steals: 0,
            blocks: 0,
            threePointersMade: 0,
            threePointersAttempted: 0,  // Adding this for Hot Hands badge
            fieldGoalPercentage: 0,
            threePointPercentage: 0,
            minutes: 0,
            personalFouls: 0,  // Added for foul prone metric
            turnovers: 0       // Added for turnover metric
        };
        
        // Initialize arrays for median calculations
        const allStats = {
            points: [],
            assists: [],
            totalRebounds: [],
            steals: [],
            blocks: [],
            threePointersMade: [],
            fieldGoalPercentage: [],
            threePointPercentage: [],
            minutes: [],
            personalFouls: [], // Added for foul prone metric
            turnovers: []      // Added for turnover metric
        };
        
        // Initialize arrays for last 5 games
        const last5Stats = {
            points: [],
            assists: [],
            totalRebounds: [],
            steals: [],
            blocks: [],
            threePointersMade: [],
            fieldGoalPercentage: [],
            threePointPercentage: [],
            minutes: [],
            personalFouls: [], // Added for foul prone metric
            turnovers: []      // Added for turnover metric
        };
        
        // Counters for valid percentage games
        let gamesWithValidFG = 0;
        let gamesWithValidTP = 0;
        
        // Calculate totals and collect data for medians
        filteredGames.forEach((game, index) => {
            // Convert values to numbers and handle missing data
            const points = parseFloat(game.points || 0);
            const assists = parseFloat(game.assists || 0);
            const totalRebounds = parseFloat(game.totalRebounds || game.rebounds || 0);
            const steals = parseFloat(game.steals || 0);
            const blocks = parseFloat(game.blocks || 0);
            const threePointersMade = parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || game.tpm || 0);
            const threePointersAttempted = parseFloat(game.threePointersAttempted || game.threePointersAttempted || game.threePointAttempts || game.tpa || 0);
            const fieldGoalPercentage = parseFloat(game.fieldGoalPercentage || game.fgPercent || game.fgp || 0);
            const threePointPercentage = parseFloat(game.threePointPercentage || game.threePtPercent || game.tpp || 0);
            const personalFouls = parseFloat(game.personalFouls || 0); // Added for foul prone metric
            const turnovers = parseFloat(game.turnovers || 0);         // Added for turnover metric
            
            // Parse minutes
            let minutes = 0;
            if (game.minutesPlayed) {
                const [mins, secs] = game.minutesPlayed.split(':');
                minutes = parseInt(mins) + parseInt(secs || 0) / 60;
            }
            
            // Add to totals
            totals.points += points;
            totals.assists += assists;
            totals.totalRebounds += totalRebounds;
            totals.steals += steals;
            totals.blocks += blocks;
            totals.threePointersMade += threePointersMade;
            totals.threePointersAttempted += threePointersAttempted;
            totals.minutes += minutes;
            totals.personalFouls += personalFouls; // Added for foul prone metric
            totals.turnovers += turnovers;         // Added for turnover metric
            
            // Only add percentage stats if they are valid
            if (fieldGoalPercentage > 0) {
                totals.fieldGoalPercentage += fieldGoalPercentage;
                gamesWithValidFG++;
            }
            
            if (threePointPercentage > 0) {
                totals.threePointPercentage += threePointPercentage;
                gamesWithValidTP++;
            }
            
            // Add to arrays for median calculations
            allStats.points.push(points);
            allStats.assists.push(assists);
            allStats.totalRebounds.push(totalRebounds);
            allStats.steals.push(steals);
            allStats.blocks.push(blocks);
            allStats.threePointersMade.push(threePointersMade);
            allStats.fieldGoalPercentage.push(fieldGoalPercentage);
            allStats.threePointPercentage.push(threePointPercentage);
            allStats.minutes.push(minutes);
            allStats.personalFouls.push(personalFouls); // Added for foul prone metric
            allStats.turnovers.push(turnovers);         // Added for turnover metric
            
            // Add to arrays for last 5 games if in the last 5
            if (index < 5) {
                last5Stats.points.push(points);
                last5Stats.assists.push(assists);
                last5Stats.totalRebounds.push(totalRebounds);
                last5Stats.steals.push(steals);
                last5Stats.blocks.push(blocks);
                last5Stats.threePointersMade.push(threePointersMade);
                last5Stats.fieldGoalPercentage.push(fieldGoalPercentage);
                last5Stats.threePointPercentage.push(threePointPercentage);
                last5Stats.minutes.push(minutes);
                last5Stats.personalFouls.push(personalFouls); // Added for foul prone metric
                last5Stats.turnovers.push(turnovers);         // Added for turnover metric
            }
        });
        
        // Calculate median function
        const calculateMedian = (numbers) => {
            if (!numbers.length) return 0;
            const sorted = [...numbers].sort((a, b) => a - b);
            const middle = Math.floor(sorted.length / 2);
            if (sorted.length % 2 === 0) {
                return (sorted[middle - 1] + sorted[middle]) / 2;
            }
            return sorted[middle];
        };
        
        // Calculate averages
        const averages = {
            points: totals.points / filteredGames.length,
            assists: totals.assists / filteredGames.length,
            rebounds: totals.totalRebounds / filteredGames.length,
            steals: totals.steals / filteredGames.length,
            blocks: totals.blocks / filteredGames.length,
            threePointersMade: totals.threePointersMade / filteredGames.length,
            threePointersAttempted: totals.threePointersAttempted / filteredGames.length,  // Adding this for Hot Hands badge
            fieldGoalPercentage: gamesWithValidFG > 0 ? totals.fieldGoalPercentage / gamesWithValidFG : 0,
            threePointPercentage: gamesWithValidTP > 0 ? totals.threePointPercentage / gamesWithValidTP : 0,
            minutes: totals.minutes / filteredGames.length,
            personalFouls: totals.personalFouls / filteredGames.length,
            turnovers: totals.turnovers / filteredGames.length
        };
        
        // Debug output for three point stats
        console.log('Three-point stats:', {
            attempts: averages.threePointersAttempted,
            made: averages.threePointersMade,
            percentage: averages.threePointPercentage,
            gamesWithValidPercentage: gamesWithValidTP
        });
        
        // Calculate medians
        const medians = {
            points: calculateMedian(allStats.points),
            pointsLast5: calculateMedian(last5Stats.points),
            assists: calculateMedian(allStats.assists),
            assistsLast5: calculateMedian(last5Stats.assists),
            rebounds: calculateMedian(allStats.totalRebounds),
            reboundsLast5: calculateMedian(last5Stats.totalRebounds),
            steals: calculateMedian(allStats.steals),
            stealsLast5: calculateMedian(last5Stats.steals),
            blocks: calculateMedian(allStats.blocks),
            blocksLast5: calculateMedian(last5Stats.blocks),
            threePointersMade: calculateMedian(allStats.threePointersMade),
            threePointersMadeLast5: calculateMedian(last5Stats.threePointersMade),
            fieldGoalPercentage: calculateMedian(allStats.fieldGoalPercentage),
            fieldGoalPercentageLast5: calculateMedian(last5Stats.fieldGoalPercentage),
            threePointPercentage: calculateMedian(allStats.threePointPercentage),
            threePointPercentageLast5: calculateMedian(last5Stats.threePointPercentage),
            minutes: calculateMedian(allStats.minutes),
            personalFouls: calculateMedian(allStats.personalFouls), // Added for foul prone metric
            turnovers: calculateMedian(allStats.turnovers)          // Added for turnover metric
        };
        
        // Calculate hit rates
        const hitRates = {};
        
        // Calculate correlations
        let correlations = [];
        try {
            correlations = calculateCorrelations(filteredGames);
        } catch (error) {
            console.error('Error calculating correlations:', error);
            // Don't let correlation errors break the entire function
            correlations = [];
        }
        
        // Calculate badges/warnings
        const badges = {
            // Foul prone badge: avg personal fouls >= 4 per game
            foulProne: averages.personalFouls >= 4,
            
            // Turnover machine badge: avg turnovers >= 4 per game
            turnoverMachine: averages.turnovers >= 4,
            
            // Calculate minutes variation (shaky minutes)
            shakyMinutes: false,
            
            // High usage player badge (use season stats, not career)
            highUsage: advancedStats.usagePercentage > 20,
            
            // Efficient scorer badge (PER >= 20)
            efficientScorer: advancedStats.playerEfficiencyRating >= 20,
            
            // Snatcher badge (steal percentage >= 1.95)
            snatcher: advancedStats.stealPercentage >= 1.95,
            
            // Hot Hands badge (11+ 3-point attempts per game AND 40%+ 3-point percentage)
            hotHands: averages.threePointersAttempted >= 11 && averages.threePointPercentage >= 0.4,
            
            // Distributor badge (assist percentage >= 28)
            distributor: advancedStats.assistPercentage >= 28,
            
            // Boardman badge (total rebound percentage >= 11)
            boardman: advancedStats.totalReboundPercentage >= 11,
            
            // Rim Protector badge (block percentage >= 2.5)
            rimProtector: advancedStats.blockPercentage >= 2.5
        };
        
        // Calculate minutes variation coefficient
        if (allStats.minutes.length >= 5) {
            // Calculate standard deviation of minutes
            const minutesMean = averages.minutes;
            const minutesVariance = allStats.minutes.reduce((sum, min) => sum + Math.pow(min - minutesMean, 2), 0) / allStats.minutes.length;
            const minutesStdDev = Math.sqrt(minutesVariance);
            
            // Calculate coefficient of variation (CV)
            const minutesCV = minutesStdDev / minutesMean;
            
            // Consider minutes as "shaky" if CV is greater than 0.25 (25% variation)
            badges.shakyMinutes = minutesCV > 0.25;
            
            // Store the CV for reference
            badges.minutesCV = minutesCV;
        }
        
        // Calculate coefficient of variation for major stat categories to determine consistency
        const statConsistency = {};
        
        // Define main stats and their display names for reuse
        const mainStats = ['points', 'assists', 'totalRebounds', 'steals', 'blocks', 'threePointersMade'];
        const statDisplayNames = {
            'points': 'Points',
            'assists': 'Assists',
            'totalRebounds': 'Rebounds',
            'steals': 'Steals',
            'blocks': 'Blocks',
            'threePointersMade': '3-Pointers'
        };
        
        mainStats.forEach(stat => {
            if (allStats[stat].length >= 5) {
                const mean = allStats[stat].reduce((sum, val) => sum + val, 0) / allStats[stat].length;
                // Only calculate CV for stats where the mean is not 0 or too small
                if (mean > 1.0) {
                    const variance = allStats[stat].reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / allStats[stat].length;
                    const stdDev = Math.sqrt(variance);
                    const cv = stdDev / mean; // Coefficient of variation
                    statConsistency[stat] = {
                        cv: cv,
                        mean: mean,
                        stdDev: stdDev,
                        displayName: statDisplayNames[stat]
                    };
                }
            }
        });
        
        // Find the most consistent stat (lowest CV)
        let mostConsistentStat = null;
        let lowestCV = Infinity;
        
        for (const [stat, data] of Object.entries(statConsistency)) {
            if (data.cv < lowestCV && data.mean > 1.0) { // Ensure the stat is meaningful (average > 1)
                lowestCV = data.cv;
                mostConsistentStat = {
                    name: stat,
                    displayName: data.displayName,
                    cv: data.cv,
                    mean: data.mean,
                    stdDev: data.stdDev
                };
            }
        }
        
        // Calculate improvement in major stat categories over last 8 games
        let statImprovement = {};
        
        // Only calculate if we have enough games
        if (filteredGames.length >= 4) { // Need at least 4 games to calculate meaningful improvement
            const numGamesToAnalyze = Math.min(8, filteredGames.length);
            const recentGames = filteredGames.slice(0, numGamesToAnalyze);
            const olderGames = filteredGames.slice(numGamesToAnalyze, Math.min(numGamesToAnalyze * 2, filteredGames.length));
            
            // Only calculate improvement if we have at least some older games to compare with
            if (olderGames.length > 0) {
                mainStats.forEach(stat => {
                    // Calculate average for recent games
                    const recentTotal = recentGames.reduce((sum, game) => {
                        const value = parseFloat(game[stat] || 0);
                        return sum + value;
                    }, 0);
                    const recentAvg = recentTotal / recentGames.length;
                    
                    // Calculate average for older games
                    const olderTotal = olderGames.reduce((sum, game) => {
                        const value = parseFloat(game[stat] || 0);
                        return sum + value;
                    }, 0);
                    const olderAvg = olderTotal / olderGames.length;
                    
                    // Calculate improvement percentage
                    if (olderAvg > 0) {
                        const improvementPercent = ((recentAvg - olderAvg) / olderAvg) * 100;
                        
                        statImprovement[stat] = {
                            displayName: statDisplayNames[stat],
                            recentAvg: recentAvg,
                            olderAvg: olderAvg,
                            improvementPercent: improvementPercent,
                            gamesAnalyzed: numGamesToAnalyze
                        };
                    }
                });
            }
        }
        
        // Find the most improved stat
        let mostImprovedStat = null;
        let highestImprovement = -Infinity;
        
        for (const [stat, data] of Object.entries(statImprovement)) {
            // Only consider meaningful improvements on stats with reasonable values
            if (data.improvementPercent > highestImprovement && data.recentAvg > 1.0) {
                highestImprovement = data.improvementPercent;
                mostImprovedStat = {
                    name: stat,
                    displayName: data.displayName,
                    recentAvg: data.recentAvg,
                    olderAvg: data.olderAvg,
                    improvementPercent: data.improvementPercent,
                    gamesAnalyzed: data.gamesAnalyzed
                };
            }
        }
        
        // Calculate teams that negatively affect player's shooting efficiency
        const teamImpact = {};
        const TEAM_CODES_REV = Object.fromEntries(
            Object.entries(TEAM_CODES).map(([abbr, name]) => [name, abbr])
        );
        
        // First, calculate overall season averages for shooting efficiency
        let totalFGP = 0;
        let totalTPP = 0;
        let overallGamesWithValidFG = 0;
        let overallGamesWithValidTP = 0;
        
        filteredGames.forEach(game => {
            const fgp = parseFloat(game.fieldGoalPercentage || 0);
            const tpp = parseFloat(game.threePointPercentage || 0);
            
            if (fgp > 0) {
                totalFGP += fgp;
                overallGamesWithValidFG++;
            }
            
            if (tpp > 0) {
                totalTPP += tpp;
                overallGamesWithValidTP++;
            }
        });
        
        const avgFGP = overallGamesWithValidFG > 0 ? totalFGP / overallGamesWithValidFG : 0;
        const avgTPP = overallGamesWithValidTP > 0 ? totalTPP / overallGamesWithValidTP : 0;
        
        // Combined efficiency metric (weighted average of FG% and 3P%)
        const avgCombinedEfficiency = (avgFGP * 0.6) + (avgTPP * 0.4);
        
        // Now calculate per team
        filteredGames.forEach(game => {
            if (!game.opponent) return;
            
            const teamName = game.opponent;
            if (!teamImpact[teamName]) {
                teamImpact[teamName] = {
                    teamName: teamName,
                    teamCode: TEAM_CODES_REV[teamName] || '',
                    games: 0,
                    totalFGP: 0,
                    totalTPP: 0,
                    gamesWithValidFG: 0,
                    gamesWithValidTP: 0
                };
            }
            
            const fgp = parseFloat(game.fieldGoalPercentage || 0);
            const tpp = parseFloat(game.threePointPercentage || 0);
            
            teamImpact[teamName].games++;
            
            if (fgp > 0) {
                teamImpact[teamName].totalFGP += fgp;
                teamImpact[teamName].gamesWithValidFG++;
            }
            
            if (tpp > 0) {
                teamImpact[teamName].totalTPP += tpp;
                teamImpact[teamName].gamesWithValidTP++;
            }
        });
        
        // Calculate averages and efficiency diff for each team
        Object.values(teamImpact).forEach(team => {
            if (team.games >= 2) { // Only consider teams played at least twice
                team.avgFGP = team.gamesWithValidFG > 0 ? team.totalFGP / team.gamesWithValidFG : 0;
                team.avgTPP = team.gamesWithValidTP > 0 ? team.totalTPP / team.gamesWithValidTP : 0;
                
                // Calculate combined efficiency
                team.combinedEfficiency = (team.avgFGP * 0.6) + (team.avgTPP * 0.4);
                
                // Calculate difference from average
                team.efficiencyDiff = team.combinedEfficiency - avgCombinedEfficiency;
            }
        });
        
        // Find teams with negative impact on shooting efficiency
        const worstMatchupTeams = Object.values(teamImpact)
            .filter(team => team.games >= 2 && team.efficiencyDiff < -0.05) // Only teams with significant negative impact on efficiency
            .sort((a, b) => a.efficiencyDiff - b.efficiencyDiff)
            .slice(0, 3); // Get top 3 worst matchups
        
        // Find teams with positive impact on shooting efficiency
        const bestMatchupTeams = Object.values(teamImpact)
            .filter(team => team.games >= 2 && team.efficiencyDiff > 0.05) // Only teams with significant positive impact on efficiency
            .sort((a, b) => b.efficiencyDiff - a.efficiencyDiff)
            .slice(0, 3); // Get top 3 best matchups
        
        // Calculate median stats for games against each difficult matchup team
        worstMatchupTeams.forEach(team => {
            // Find all games against this team
            const gamesAgainstTeam = filteredGames.filter(game => game.opponent === team.teamName);
            
            if (gamesAgainstTeam.length > 0) {
                // Initialize stats to track
                const teamStats = {
                    points: [],
                    rebounds: [],
                    assists: [],
                    steals: [],
                    blocks: [],
                    threePointersMade: [],
                    minutes: []
                };
                
                // Collect stats from each game against this team
                gamesAgainstTeam.forEach(game => {
                    teamStats.points.push(parseFloat(game.points || 0));
                    teamStats.rebounds.push(parseFloat(game.totalRebounds || game.rebounds || 0));
                    teamStats.assists.push(parseFloat(game.assists || 0));
                    teamStats.steals.push(parseFloat(game.steals || 0));
                    teamStats.blocks.push(parseFloat(game.blocks || 0));
                    teamStats.threePointersMade.push(parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0));
                    
                    // Process minutes
                    if (game.minutesPlayed) {
                        const [mins, secs] = game.minutesPlayed.split(':');
                        const minutes = parseInt(mins) + parseInt(secs || 0) / 60;
                        teamStats.minutes.push(minutes);
                    }
                });
                
                // Calculate median values for this team
                team.medianStats = {
                    points: teamStats.points.length > 0 ? calculateMedian(teamStats.points) : 0,
                    rebounds: teamStats.rebounds.length > 0 ? calculateMedian(teamStats.rebounds) : 0,
                    assists: teamStats.assists.length > 0 ? calculateMedian(teamStats.assists) : 0,
                    steals: teamStats.steals.length > 0 ? calculateMedian(teamStats.steals) : 0,
                    blocks: teamStats.blocks.length > 0 ? calculateMedian(teamStats.blocks) : 0,
                    threePointersMade: teamStats.threePointersMade.length > 0 ? calculateMedian(teamStats.threePointersMade) : 0
                };
                
                // Calculate differences from overall medians
                team.statDifferences = {
                    points: Number((team.medianStats.points - (medians.points || 0)).toFixed(1)),
                    rebounds: Number((team.medianStats.rebounds - (medians.rebounds || 0)).toFixed(1)),
                    assists: Number((team.medianStats.assists - (medians.assists || 0)).toFixed(1)),
                    steals: Number((team.medianStats.steals - (medians.steals || 0)).toFixed(1)),
                    blocks: Number((team.medianStats.blocks - (medians.blocks || 0)).toFixed(1)),
                    threePointersMade: Number((team.medianStats.threePointersMade - (medians.threePointersMade || 0)).toFixed(1))
                };
            }
        });
        
        // Calculate median stats for games against each favorable matchup team
        bestMatchupTeams.forEach(team => {
            // Find all games against this team
            const gamesAgainstTeam = filteredGames.filter(game => game.opponent === team.teamName);
            
            if (gamesAgainstTeam.length > 0) {
                // Initialize stats to track
                const teamStats = {
                    points: [],
                    rebounds: [],
                    assists: [],
                    steals: [],
                    blocks: [],
                    threePointersMade: [],
                    minutes: []
                };
                
                // Collect stats from each game against this team
                gamesAgainstTeam.forEach(game => {
                    teamStats.points.push(parseFloat(game.points || 0));
                    teamStats.rebounds.push(parseFloat(game.totalRebounds || game.rebounds || 0));
                    teamStats.assists.push(parseFloat(game.assists || 0));
                    teamStats.steals.push(parseFloat(game.steals || 0));
                    teamStats.blocks.push(parseFloat(game.blocks || 0));
                    teamStats.threePointersMade.push(parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0));
                    
                    // Process minutes
                    if (game.minutesPlayed) {
                        const [mins, secs] = game.minutesPlayed.split(':');
                        const minutes = parseInt(mins) + parseInt(secs || 0) / 60;
                        teamStats.minutes.push(minutes);
                    }
                });
                
                // Calculate median values for this team
                team.medianStats = {
                    points: teamStats.points.length > 0 ? calculateMedian(teamStats.points) : 0,
                    rebounds: teamStats.rebounds.length > 0 ? calculateMedian(teamStats.rebounds) : 0,
                    assists: teamStats.assists.length > 0 ? calculateMedian(teamStats.assists) : 0,
                    steals: teamStats.steals.length > 0 ? calculateMedian(teamStats.steals) : 0,
                    blocks: teamStats.blocks.length > 0 ? calculateMedian(teamStats.blocks) : 0,
                    threePointersMade: teamStats.threePointersMade.length > 0 ? calculateMedian(teamStats.threePointersMade) : 0
                };
                
                // Calculate differences from overall medians
                team.statDifferences = {
                    points: Number((team.medianStats.points - (medians.points || 0)).toFixed(1)),
                    rebounds: Number((team.medianStats.rebounds - (medians.rebounds || 0)).toFixed(1)),
                    assists: Number((team.medianStats.assists - (medians.assists || 0)).toFixed(1)),
                    steals: Number((team.medianStats.steals - (medians.steals || 0)).toFixed(1)),
                    blocks: Number((team.medianStats.blocks - (medians.blocks || 0)).toFixed(1)),
                    threePointersMade: Number((team.medianStats.threePointersMade - (medians.threePointersMade || 0)).toFixed(1))
                };
            }
        });
        
        // Find most common statistical splits
        const commonSplits = calculateCommonSplits(filteredGames);
        
        return {
            player: {
                ...player,  // Spread the player object to include all properties including team
                name: player.name,
                position: player.position || 'N/A',
                headshotUrl: player.headshotUrl,
                team: player.team  // Explicitly include team to ensure it's preserved
            },
            gamesPlayed: filteredGames.length,
            averages,
            medians,
            hitRates,
            advancedStats,
            correlations: correlations.slice(0, 10), // Only return top 10 correlations
            statConsistency,
            mostConsistentStat,
            mostImprovedStat,
            worstMatchupTeams,
            bestMatchupTeams,
            commonSplits,
            badges,
            games: filteredGames, // Include the games array in the return object
            propProbabilities: calculatePlayerPropProbabilities(filteredGames) // Calculate and include prop probabilities
        };
    };
    
    // Calculate correlations between different stats
    const calculateCorrelations = (games) => {
        if (!games || games.length < 10) return []; // Need minimum games for meaningful correlations
        
        // Define stat keys to analyze
        const statKeys = [
            'points', 
            'assists', 
            'totalRebounds',
            'steals', 
            'blocks', 
            'threePointersMade',
            'fieldGoalPercentage', 
            'threePointPercentage',
            'minutes',
            'personalFouls',   // Add personal fouls
            'turnovers'        // Add turnovers
        ];
        
        // Define stat display labels
        const statLabels = {
            'points': 'Points',
            'assists': 'Assists',
            'totalRebounds': 'Rebounds',
            'steals': 'Steals',
            'blocks': 'Blocks',
            'threePointersMade': '3PM',
            'fieldGoalPercentage': 'FG%',
            'threePointPercentage': '3P%',
            'minutes': 'Minutes',
            'personalFouls': 'Fouls',  // Add display label for fouls
            'turnovers': 'Turnovers'   // Add display label for turnovers
        };
        
        // Prepare data for correlation calculations
        const data = {};
        statKeys.forEach(key => {
            data[key] = [];
        });
        
        // Extract data from games
        games.forEach(game => {
            // Handle different property names and missing data
            const points = parseFloat(game.points || 0);
            const assists = parseFloat(game.assists || 0);
            const totalRebounds = parseFloat(game.totalRebounds || game.rebounds || 0);
            const steals = parseFloat(game.steals || 0);
            const blocks = parseFloat(game.blocks || 0);
            const threePointersMade = parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0);
            const fieldGoalPercentage = parseFloat(game.fieldGoalPercentage || game.fgPercent || 0);
            const threePointPercentage = parseFloat(game.threePointPercentage || game.threePtPercent || 0);
            const personalFouls = parseFloat(game.personalFouls || 0);  // Parse personal fouls
            const turnovers = parseFloat(game.turnovers || 0);          // Parse turnovers
            
            // Parse minutes (convert MM:SS to minutes as a decimal)
            let minutes = 0;
            if (game.minutesPlayed) {
                const parts = game.minutesPlayed.split(':');
                minutes = parseInt(parts[0]) + (parts.length > 1 ? parseInt(parts[1]) / 60 : 0);
            }
            
            // Add data to arrays
            data.points.push(points);
            data.assists.push(assists);
            data.totalRebounds.push(totalRebounds);
            data.steals.push(steals);
            data.blocks.push(blocks);
            data.threePointersMade.push(threePointersMade);
            data.fieldGoalPercentage.push(fieldGoalPercentage);
            data.threePointPercentage.push(threePointPercentage);
            data.minutes.push(minutes);
            data.personalFouls.push(personalFouls);  // Store personal fouls data
            data.turnovers.push(turnovers);          // Store turnovers data
        });
        
        // Calculate Pearson correlation coefficient
        const calculatePearsonCorrelation = (x, y) => {
            if (x.length !== y.length) return 0;
            
            // Filter out missing values
            const filteredData = x.map((val, i) => [val, y[i]])
                .filter(pair => !isNaN(pair[0]) && !isNaN(pair[1]));
            
            if (filteredData.length < 8) return 0; // Need minimum data points
            
            const filteredX = filteredData.map(pair => pair[0]);
            const filteredY = filteredData.map(pair => pair[1]);
            
            const n = filteredX.length;
            
            // Calculate means
            const meanX = filteredX.reduce((sum, val) => sum + val, 0) / n;
            const meanY = filteredY.reduce((sum, val) => sum + val, 0) / n;
            
            // Calculate covariance and standard deviations
            let covariance = 0;
            let varX = 0;
            let varY = 0;
            
            for (let i = 0; i < n; i++) {
                const diffX = filteredX[i] - meanX;
                const diffY = filteredY[i] - meanY;
                covariance += diffX * diffY;
                varX += diffX * diffX;
                varY += diffY * diffY;
            }
            
            // Avoid division by zero
            if (varX === 0 || varY === 0) return 0;
            
            // Calculate correlation coefficient
            return covariance / (Math.sqrt(varX) * Math.sqrt(varY));
        };
        
        // Generate correlation matrix
        const correlationMatrix = {};
        for (const key1 of statKeys) {
            correlationMatrix[key1] = {};
            for (const key2 of statKeys) {
                // Skip self-correlations, which are always 1
                if (key1 === key2) continue;
                
                correlationMatrix[key1][key2] = calculatePearsonCorrelation(data[key1], data[key2]);
            }
        }
        
        // Define pairs of stats to analyze for conditional relationships
        const statPairs = [
            // Standard relationships
            { condition: 'minutes', outcome: 'points' },
            { condition: 'minutes', outcome: 'assists' },
            { condition: 'minutes', outcome: 'totalRebounds' },
            { condition: 'minutes', outcome: 'steals' },
            { condition: 'minutes', outcome: 'blocks' },
            { condition: 'minutes', outcome: 'threePointersMade' },
            
            // Shooting relationships
            { condition: 'threePointersMade', outcome: 'points' },
            { condition: 'fieldGoalPercentage', outcome: 'points' },
            { condition: 'threePointPercentage', outcome: 'points' },
            { condition: 'fieldGoalPercentage', outcome: 'threePointPercentage' },
            
            // Other interesting relationships
            { condition: 'assists', outcome: 'points' },
            { condition: 'steals', outcome: 'points' },
            { condition: 'blocks', outcome: 'totalRebounds' },
            { condition: 'threePointersMade', outcome: 'assists' },
            { condition: 'assists', outcome: 'threePointersMade' },
            { condition: 'steals', outcome: 'blocks' },
            
            // Add relationships for personal fouls and turnovers
            { condition: 'minutes', outcome: 'personalFouls' },
            { condition: 'minutes', outcome: 'turnovers' },
            { condition: 'personalFouls', outcome: 'points' },
            { condition: 'personalFouls', outcome: 'minutes' },
            { condition: 'turnovers', outcome: 'assists' },
            { condition: 'turnovers', outcome: 'points' },
            { condition: 'personalFouls', outcome: 'steals' },
            { condition: 'turnovers', outcome: 'steals' }
        ];
        
        // Analyze conditional relationships
        const conditionalAnalysis = [];
        
        for (const { condition, outcome } of statPairs) {
            const conditionStat = condition;
            const outcomeStat = outcome;
            
            // Skip if not enough data
            if (data[conditionStat].length < 10 || data[outcomeStat].length < 10) continue;
            
            // Calculate correlation coefficient
            const corr = correlationMatrix[conditionStat][outcomeStat];
            
            // Skip weak or undefined correlations
            if (Math.abs(corr) < 0.15 || isNaN(corr)) continue;
            
            // Determine direction based on correlation value
            // For positive correlation: when one increases, the other increases
            // For negative correlation: when one increases, the other decreases
            const direction = corr > 0 ? 'positive' : 'negative';
            
            // Calculate a threshold value (median)
            const conditionValues = [...data[conditionStat]].sort((a, b) => a - b);
            const medianIndex = Math.floor(conditionValues.length / 2);
            const conditionThreshold = conditionValues[medianIndex];
            
            // Calculate average outcome for high and low condition values
            const highGroup = games.filter((_, i) => data[conditionStat][i] > conditionThreshold);
            const lowGroup = games.filter((_, i) => data[conditionStat][i] <= conditionThreshold);
            
            // Only include if both groups have enough data points
            if (highGroup.length < 3 || lowGroup.length < 3) continue;
            
            // Calculate average outcomes
            const highOutcomeValues = highGroup.map(game => 
                parseFloat(game[outcomeStat] || game.totalRebounds || game.rebounds || 0));
            const lowOutcomeValues = lowGroup.map(game => 
                parseFloat(game[outcomeStat] || game.totalRebounds || game.rebounds || 0));
            
            const highOutcomeAvg = (highOutcomeValues.reduce((sum, val) => sum + (isNaN(val) ? 0 : val), 0) / highOutcomeValues.length).toFixed(1);
            const lowOutcomeAvg = (lowOutcomeValues.reduce((sum, val) => sum + (isNaN(val) ? 0 : val), 0) / lowOutcomeValues.length).toFixed(1);
            
            // Create statement based on direction
            const conditionLabel = statLabels[conditionStat];
            const outcomeLabel = statLabels[outcomeStat];
            
            let statement = '';
            if (direction === 'positive') {
                statement = `Higher ${conditionLabel} tends to lead to higher ${outcomeLabel}.`;
            } else {
                statement = `Higher ${conditionLabel} tends to lead to lower ${outcomeLabel}.`;
            }
            
            // Add to analysis results
            conditionalAnalysis.push({
                conditionStat,
                outcomeStat,
                conditionLabel,
                outcomeLabel,
                conditionThreshold: conditionThreshold.toFixed(1),
                correlation: corr.toFixed(2),
                direction,
                highOutcomeAvg,
                lowOutcomeAvg,
                statement
            });
        }
        
        // Sort by correlation strength (absolute value)
        conditionalAnalysis.sort((a, b) => Math.abs(parseFloat(b.correlation)) - Math.abs(parseFloat(a.correlation)));
        
        // Find unique correlations (avoid duplicates like PTS→3PM and 3PM→PTS)
        const uniqueCorrelations = [];
        const seenPairs = new Set();
        
        conditionalAnalysis.forEach(correlation => {
            const pair = [correlation.conditionLabel, correlation.outcomeLabel].sort().join('-');
            if (!seenPairs.has(pair)) {
                seenPairs.add(pair);
                uniqueCorrelations.push(correlation);
            }
        });
        
        // Return top correlations (limit to the most significant unique correlations)
        return uniqueCorrelations.slice(0, 12); // Increased from 8 to 12
    };
    
    // Handle season selection
    const handleSeasonChange = (e) => {
        const season = e.target.value;
        setSelectedSeason(season);
        
        if (selectedPlayer) {
            fetchPlayerStats(selectedPlayer.id, season, selectedPlayer);
        }
        
        // If a team is selected, fetch its data for the new season
        if (selectedTeam) {
            fetchTeamRoster(selectedTeam, season);
            fetchTeamSchedule(selectedTeam, season);
        }
    };
    
    // Handle team selection
    const handleTeamSelect = async (e) => {
        const team = e.target.value;
        setSelectedTeam(team);
        
        if (team) {
            // Clear previous team data
            setTeamRoster([]);
            setTeamSchedule([]);
            setTeamStats({
                avgPoints: 0,
                avgOppPoints: 0,
                wins: 0,
                losses: 0
            });
            
            // Fetch both roster and schedule
            await Promise.all([
                fetchTeamRoster(team, selectedSeason),
                fetchTeamSchedule(team, selectedSeason)
            ]);
        } else {
            setTeamRoster([]);
            setTeamSchedule([]);
            setTeamStats({
                avgPoints: 0,
                avgOppPoints: 0,
                wins: 0,
                losses: 0
            });
        }
    };
    
    // Update the fetchTeamRoster function to calculate star players
    const fetchTeamRoster = async (teamCode, season) => {
        try {
            setTeamLoading(true);
            setTeamError(null);
            
            // Map team codes that differ between our app and the API
            const teamCodeMapping = {
                'BKN': 'BRK', // Brooklyn Nets -> API uses BRK
                'PHX': 'PHO', // Phoenix Suns
                'CHA': 'CHO'  // Charlotte Hornets
            };
            
            // Use the mapped code if it exists, otherwise use the original code
            const apiTeamCode = teamCodeMapping[teamCode] || teamCode;
            
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/teams/${apiTeamCode}/roster/${season}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                    }
                }
            );
            
            if (response?.body?.roster) {
                // Log the first player to see the actual data format
                console.log("Sample player data:", response.body.roster[0]);
                
                // Map the roster with better handling for jersey numbers
                setTeamRoster(response.body.roster.map(player => {
                    // Extract jersey number - could be in different properties depending on API
                    // Check various possible fields where jersey number might be stored
                    const jerseyNumber = player.jerseyNumber || player.jersey || player.number || null;
                    
                    return {
                        id: player.playerId,
                        name: player.name,
                        position: player.position,
                        number: jerseyNumber
                    };
                }));
                
                // After setting roster, fetch stats for each player to determine star players
                fetchTeamStarPlayers(teamCode, season, response.body.roster);
            } else {
                console.error(`No roster found for team ${teamCode} (API code: ${apiTeamCode})`);
                setTeamError(`No roster found for ${teamCode}. Please try another team.`);
                setTeamRoster([]);
                setTeamStarPlayers({
                    points: null,
                    assists: null,
                    rebounds: null,
                    steals: null,
                    blocks: null,
                    threePointersMade: null,
                    fieldGoalPercentage: null,
                    threePointPercentage: null
                });
            }
        } catch (error) {
            console.error(`Error fetching team roster for ${teamCode}:`, error);
            setTeamError(`Failed to fetch ${teamCode} roster. Please try again.`);
            setTeamRoster([]);
        } finally {
            setTeamLoading(false);
        }
    };
    
    // Add function to fetch star players for each statistical category
    const fetchTeamStarPlayers = async (teamCode, season, rosterData) => {
        try {
            // Initialize with empty values
            const starPlayers = {
                points: null,
                assists: null,
                rebounds: null,
                steals: null,
                blocks: null,
                threePointersMade: null,
                fieldGoalPercentage: null,
                threePointPercentage: null
            };
            
            const seasonEndYear = season.split('-')[1];
            
            // Helper function to calculate median
            const calculateMedian = (numbers) => {
                if (!numbers.length) return 0;
                const sorted = [...numbers].sort((a, b) => a - b);
                const middle = Math.floor(sorted.length / 2);
                if (sorted.length % 2 === 0) {
                    return (sorted[middle - 1] + sorted[middle]) / 2;
                }
                return sorted[middle];
            };
            
            // Store player badges for display in the roster
            const playerBadges = {};
            
            // For each player in the roster, fetch their season stats
            const playerStatsPromises = rosterData.map(async (player) => {
                try {
                    // Fetch advanced stats for badges
                    const advancedStats = await makeApiRequest(
                        `${RAPID_API_CONFIG.BASE_URL}/players/${player.playerId}/stats/Advanced?seasonType=Regular`,
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                                'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                            }
                        }
                    );
                    
                    // Process advanced stats
                    let seasonAdvancedStats = {
                        usagePercentage: 0,
                        playerEfficiencyRating: 0,
                        stealPercentage: 0,
                        assistPercentage: 0,
                        totalReboundPercentage: 0,
                        blockPercentage: 0
                    };
                    
                    if (advancedStats?.body) {
                        // Find the selected season's advanced stats
                        const seasonStats = advancedStats.body.find(stat => stat.season === season);
                        if (seasonStats) {
                            seasonAdvancedStats.usagePercentage = parseFloat(seasonStats.usagePercentage || 0);
                            seasonAdvancedStats.playerEfficiencyRating = parseFloat(seasonStats.playerEfficiencyRating || 0);
                            seasonAdvancedStats.stealPercentage = parseFloat(seasonStats.stealPercentage || 0);
                            seasonAdvancedStats.assistPercentage = parseFloat(seasonStats.assistPercentage || 0);
                            seasonAdvancedStats.totalReboundPercentage = parseFloat(seasonStats.totalReboundPercentage || 0);
                            seasonAdvancedStats.blockPercentage = parseFloat(seasonStats.blockPercentage || 0);
                        }
                    }
                    
                    const response = await makeApiRequest(
                        `${RAPID_API_CONFIG.BASE_URL}/players/${player.playerId}/games/${seasonEndYear}`,
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                                'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                            },
                            body: JSON.stringify({
                                pageSize: 100
                            })
                        }
                    );
                    
                    if (response?.body && response.body.length > 0) {
                        const games = response.body.filter(game => game.minutesPlayed);
                        
                        if (games.length > 10) { // Minimum 10 games played
                            // Arrays for calculating medians
                            const statArrays = {
                                points: [],
                                assists: [],
                                rebounds: [], 
                                steals: [],
                                blocks: [],
                                threePointersMade: [],
                                fieldGoalPercentage: [],
                                threePointPercentage: []
                            };
                            
                            // Calculate averages
                            const totalGames = games.length;
                            let totalPoints = 0, totalAssists = 0, totalRebounds = 0, 
                                totalSteals = 0, totalBlocks = 0, totalThreePointersMade = 0,
                                totalPersonalFouls = 0, totalTurnovers = 0, totalThreePointersAttempted = 0;
                            
                            // Process each game for averages and collect data for medians
                            games.forEach(game => {
                                const points = parseFloat(game.points || 0);
                                const assists = parseFloat(game.assists || 0);
                                const rebounds = parseFloat(game.totalRebounds || game.rebounds || 0);
                                const steals = parseFloat(game.steals || 0);
                                const blocks = parseFloat(game.blocks || 0);
                                const threePointersMade = parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0);
                                const fieldGoalPercentage = parseFloat(game.fieldGoalPercentage || game.fgPercent || 0);
                                const threePointPercentage = parseFloat(game.threePointPercentage || game.threePtPercent || 0);
                                const personalFouls = parseFloat(game.personalFouls || 0);
                                const turnovers = parseFloat(game.turnovers || 0);
                                const threePointersAttempted = parseFloat(game.threePointersAttempted || game.threePointAttempts || game.tpa || 0);
                                
                                // Add to totals
                                totalPoints += points;
                                totalAssists += assists;
                                totalRebounds += rebounds;
                                totalSteals += steals;
                                totalBlocks += blocks;
                                totalThreePointersMade += threePointersMade;
                                totalPersonalFouls += personalFouls;
                                totalTurnovers += turnovers;
                                totalThreePointersAttempted += threePointersAttempted;
                                
                                // Add to arrays for median calculation
                                statArrays.points.push(points);
                                statArrays.assists.push(assists);
                                statArrays.rebounds.push(rebounds);
                                statArrays.steals.push(steals);
                                statArrays.blocks.push(blocks);
                                statArrays.threePointersMade.push(threePointersMade);
                                
                                // Only add percentage stats if they are valid
                                if (fieldGoalPercentage > 0) statArrays.fieldGoalPercentage.push(fieldGoalPercentage);
                                if (threePointPercentage > 0) statArrays.threePointPercentage.push(threePointPercentage);
                            });
                            
                            // Calculate shooting percentages
                            const validFgGames = games.filter(game => game.fieldGoalPercentage || game.fgPercent);
                            const validTpGames = games.filter(game => game.threePointPercentage || game.threePtPercent);
                            
                            const avgFgPercentage = validFgGames.length > 0 
                                ? validFgGames.reduce((sum, game) => sum + parseFloat(game.fieldGoalPercentage || game.fgPercent || 0), 0) / validFgGames.length
                                : 0;
                            
                            const avgTpPercentage = validTpGames.length > 0 
                                ? validTpGames.reduce((sum, game) => sum + parseFloat(game.threePointPercentage || game.threePtPercent || 0), 0) / validTpGames.length
                                : 0;
                            
                            // Calculate badges for this player
                            const avgPersonalFouls = totalPersonalFouls / totalGames;
                            const avgTurnovers = totalTurnovers / totalGames;
                            const avgThreePointersAttempted = totalThreePointersAttempted / totalGames;
                            
                            const badges = {
                                foulProne: avgPersonalFouls >= 4,
                                turnoverMachine: avgTurnovers >= 4,
                                highUsage: seasonAdvancedStats.usagePercentage > 20,
                                efficientScorer: seasonAdvancedStats.playerEfficiencyRating >= 20,
                                snatcher: seasonAdvancedStats.stealPercentage >= 1.95,
                                hotHands: avgThreePointersAttempted >= 11 && avgTpPercentage >= 0.4,
                                distributor: seasonAdvancedStats.assistPercentage >= 28,
                                boardman: seasonAdvancedStats.totalReboundPercentage >= 11,
                                rimProtector: seasonAdvancedStats.blockPercentage >= 2.5
                            };
                            
                            // Store badges for this player
                            playerBadges[player.playerId] = {
                                badges: badges,
                                advancedStats: seasonAdvancedStats,
                                averages: {
                                    personalFouls: avgPersonalFouls,
                                    turnovers: avgTurnovers,
                                    threePointersAttempted: avgThreePointersAttempted,
                                    threePointPercentage: avgTpPercentage
                                }
                            };
                            
                            // Calculate medians
                            const medians = {
                                points: calculateMedian(statArrays.points),
                                assists: calculateMedian(statArrays.assists),
                                rebounds: calculateMedian(statArrays.rebounds),
                                steals: calculateMedian(statArrays.steals),
                                blocks: calculateMedian(statArrays.blocks),
                                threePointersMade: calculateMedian(statArrays.threePointersMade),
                                fieldGoalPercentage: calculateMedian(statArrays.fieldGoalPercentage),
                                threePointPercentage: calculateMedian(statArrays.threePointPercentage)
                            };
                            
                            // Fetch the player's metadata for a reliable headshot URL
                            const headshotUrl = await fetchPlayerMetadata(player.playerId);
                            
                            // Use standard NBA player headshot URLs as backups
                            const headshotUrls = [
                                headshotUrl, // From player metadata if available
                                `https://www.basketball-reference.com/req/202106291/images/headshots/${player.playerId}.jpg`, // Basketball Reference
                                `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${player.playerId}.png`, // NBA.com
                                `https://cdn.nba.com/headshots/nba/latest/1040x760/${player.playerId}.png` // NBA CDN
                            ].filter(url => url); // Remove null/undefined URLs
                            
                            return {
                                player: {
                                    id: player.playerId,
                                    name: player.name,
                                    position: player.position,
                                    headshotUrl: headshotUrls[0] || null, // Use first available URL
                                    headshotUrls: headshotUrls // Store all URLs for fallbacks
                                },
                                stats: {
                                    gamesPlayed: totalGames,
                                    points: totalPoints / totalGames,
                                    assists: totalAssists / totalGames,
                                    rebounds: totalRebounds / totalGames,
                                    steals: totalSteals / totalGames,
                                    blocks: totalBlocks / totalGames,
                                    threePointersMade: totalThreePointersMade / totalGames,
                                    fieldGoalPercentage: avgFgPercentage,
                                    threePointPercentage: avgTpPercentage,
                                    medians: medians // Add median stats
                                }
                            };
                        }
                    }
                } catch (error) {
                    console.error(`Error fetching stats for player ${player.name}:`, error);
                }
                
                return null;
            });
            
            // Wait for all player stats to be fetched
            const playersWithStats = (await Promise.all(playerStatsPromises))
                .filter(playerData => playerData !== null);
            
            // Update team roster with badges
            setTeamRoster(prevRoster => 
                prevRoster.map(player => ({
                    ...player,
                    badges: playerBadges[player.id]?.badges || {},
                    badgeData: playerBadges[player.id] || {}
                }))
            );
            
            // Find top player for each category
            if (playersWithStats.length > 0) {
                // Points leader
                const pointsLeader = playersWithStats.reduce((leader, current) => 
                    current.stats.points > (leader?.stats.points || 0) ? current : leader, null);
                starPlayers.points = pointsLeader;
                
                // Assists leader
                const assistsLeader = playersWithStats.reduce((leader, current) => 
                    current.stats.assists > (leader?.stats.assists || 0) ? current : leader, null);
                starPlayers.assists = assistsLeader;
                
                // Rebounds leader
                const reboundsLeader = playersWithStats.reduce((leader, current) => 
                    current.stats.rebounds > (leader?.stats.rebounds || 0) ? current : leader, null);
                starPlayers.rebounds = reboundsLeader;
                
                // Steals leader
                const stealsLeader = playersWithStats.reduce((leader, current) => 
                    current.stats.steals > (leader?.stats.steals || 0) ? current : leader, null);
                starPlayers.steals = stealsLeader;
                
                // Blocks leader
                const blocksLeader = playersWithStats.reduce((leader, current) => 
                    current.stats.blocks > (leader?.stats.blocks || 0) ? current : leader, null);
                starPlayers.blocks = blocksLeader;
                
                // 3PM leader
                const threePointersLeader = playersWithStats.reduce((leader, current) => 
                    current.stats.threePointersMade > (leader?.stats.threePointersMade || 0) ? current : leader, null);
                starPlayers.threePointersMade = threePointersLeader;
                
                // FG% leader (minimum 5 FG attempts per game)
                const fgLeader = playersWithStats
                    .filter(player => player.stats.fieldGoalPercentage > 0)
                    .reduce((leader, current) => 
                        current.stats.fieldGoalPercentage > (leader?.stats.fieldGoalPercentage || 0) ? current : leader, null);
                starPlayers.fieldGoalPercentage = fgLeader;
                
                // 3P% leader (minimum 2 3PA per game)
                const tpLeader = playersWithStats
                    .filter(player => player.stats.threePointPercentage > 0 && player.stats.threePointersMade >= 1)
                    .reduce((leader, current) => 
                        current.stats.threePointPercentage > (leader?.stats.threePointPercentage || 0) ? current : leader, null);
                starPlayers.threePointPercentage = tpLeader;
            }
            
            setTeamStarPlayers(starPlayers);
        } catch (error) {
            console.error('Error fetching team star players:', error);
        }
    };
    
    // Add function to render star players
    const renderTeamStarPlayers = () => {
        // Check if we have star players data
        const hasStarPlayers = Object.values(teamStarPlayers).some(player => player !== null);
        
        if (!hasStarPlayers) {
            return (
                <div className="text-center my-4 text-muted">
                    No statistical leaders data available for this team in the selected season.
                </div>
            );
        }
        
        // Helper function to format stat value
        const formatStatValue = (value, isPercentage = false) => {
            if (value === undefined || value === null) return 'N/A';
            return isPercentage ? `${(value * 100).toFixed(1)}%` : value.toFixed(1);
        };
        
        // Helper function to get stat class based on value
        const getStatClass = (stat, value) => {
            if (value === undefined || value === null) return '';
            
            const val = isNaN(value) ? 0 : parseFloat(value);
            
            switch(stat) {
                case 'points':
                    return val >= 20 ? 'points-high' : val >= 15 ? 'points-medium' : 'points-low';
                case 'assists':
                    return val >= 7 ? 'assists-high' : val >= 4 ? 'assists-medium' : 'assists-low';
                case 'rebounds':
                    return val >= 8 ? 'rebounds-high' : val >= 5 ? 'rebounds-medium' : 'rebounds-low';
                case 'steals':
                    return val >= 1.5 ? 'steals-high' : val >= 1.0 ? 'steals-medium' : 'steals-low';
                case 'blocks':
                    return val >= 1.5 ? 'blocks-high' : val >= 0.7 ? 'blocks-medium' : 'blocks-low';
                case 'threePointersMade':
                    return val >= 2.5 ? 'three-pm-high' : val >= 1.5 ? 'three-pm-medium' : 'three-pm-low';
                case 'fieldGoalPercentage':
                    return (val * 100) >= 45 ? 'fg-percent-high' : (val * 100) >= 40 ? 'fg-percent-medium' : 'fg-percent-low';
                case 'threePointPercentage':
                    return (val * 100) >= 37 ? 'three-pt-percent-high' : (val * 100) >= 33 ? 'three-pt-percent-medium' : 'three-pt-percent-low';
                default:
                    return '';
            }
        };
        
        // Helper function for image error handling
        const handleImageError = (e, player) => {
            console.log("Image failed to load for", player.name);
            
            // If player has multiple headshot URLs stored, try them in sequence
            if (player.headshotUrls && Array.isArray(player.headshotUrls)) {
                // Find current URL index
                const currentUrl = e.target.src;
                const urlIndex = player.headshotUrls.findIndex(url => url === currentUrl);
                
                // Try next URL if available
                if (urlIndex >= 0 && urlIndex < player.headshotUrls.length - 1) {
                    const nextUrl = player.headshotUrls[urlIndex + 1];
                    if (nextUrl) {
                        console.log("Trying next URL:", nextUrl);
                        
                        // Handle basketball-reference URLs with CORS proxy if needed
                        if (nextUrl.includes('basketball-reference.com')) {
                            e.target.src = `https://images.weserv.nl/?url=${encodeURIComponent(nextUrl)}`;
                        } else {
                            e.target.src = nextUrl;
                        }
                        return;
                    }
                }
            }
            
            // Standard fallbacks if no headshot URLs array is available
            const playerId = player.id;
            try {
                // Try Basketball Reference with CORS proxy
                e.target.src = `https://images.weserv.nl/?url=${encodeURIComponent(`https://www.basketball-reference.com/req/202106291/images/headshots/${playerId}.jpg`)}`;
                
                // If this fails too, try another source
                e.target.onerror = () => {
                    e.target.src = `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${playerId}.png`;
                    
                    // If all fail, hide the image
                    e.target.onerror = () => {
                        e.target.style.display = 'none';
                    };
                };
            } catch (error) {
                console.error("Error handling image fallbacks:", error);
                e.target.style.display = 'none';
            }
        };
        
        return (
            <div className="star-players-container p-3">
                <Row>
                    {/* Points Leader */}
                    {teamStarPlayers.points && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>Points Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.points.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.points.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.points.player.headshotUrl)}`
                                                : teamStarPlayers.points.player.headshotUrl} 
                                            alt={teamStarPlayers.points.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.points.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.points.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.points.player.position}</div>
                                    <div className={`star-stat ${getStatClass('points', teamStarPlayers.points.stats.points)}`}>
                                        {formatStatValue(teamStarPlayers.points.stats.points)} PPG
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.points.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.points.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.points.stats.medians.points)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    
                    {/* Assists Leader */}
                    {teamStarPlayers.assists && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>Assists Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.assists.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.assists.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.assists.player.headshotUrl)}`
                                                : teamStarPlayers.assists.player.headshotUrl}
                                            alt={teamStarPlayers.assists.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.assists.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.assists.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.assists.player.position}</div>
                                    <div className={`star-stat ${getStatClass('assists', teamStarPlayers.assists.stats.assists)}`}>
                                        {formatStatValue(teamStarPlayers.assists.stats.assists)} APG
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.assists.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.assists.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.assists.stats.medians.assists)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    
                    {/* Rebounds Leader */}
                    {teamStarPlayers.rebounds && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>Rebounds Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.rebounds.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.rebounds.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.rebounds.player.headshotUrl)}`
                                                : teamStarPlayers.rebounds.player.headshotUrl}
                                            alt={teamStarPlayers.rebounds.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.rebounds.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.rebounds.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.rebounds.player.position}</div>
                                    <div className={`star-stat ${getStatClass('rebounds', teamStarPlayers.rebounds.stats.rebounds)}`}>
                                        {formatStatValue(teamStarPlayers.rebounds.stats.rebounds)} RPG
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.rebounds.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.rebounds.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.rebounds.stats.medians.rebounds)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    
                    {/* Steals Leader */}
                    {teamStarPlayers.steals && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>Steals Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.steals.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.steals.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.steals.player.headshotUrl)}`
                                                : teamStarPlayers.steals.player.headshotUrl}
                                            alt={teamStarPlayers.steals.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.steals.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.steals.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.steals.player.position}</div>
                                    <div className={`star-stat ${getStatClass('steals', teamStarPlayers.steals.stats.steals)}`}>
                                        {formatStatValue(teamStarPlayers.steals.stats.steals)} SPG
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.steals.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.steals.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.steals.stats.medians.steals)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    
                    {/* Blocks Leader */}
                    {teamStarPlayers.blocks && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>Blocks Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.blocks.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.blocks.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.blocks.player.headshotUrl)}`
                                                : teamStarPlayers.blocks.player.headshotUrl}
                                            alt={teamStarPlayers.blocks.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.blocks.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.blocks.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.blocks.player.position}</div>
                                    <div className={`star-stat ${getStatClass('blocks', teamStarPlayers.blocks.stats.blocks)}`}>
                                        {formatStatValue(teamStarPlayers.blocks.stats.blocks)} BPG
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.blocks.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.blocks.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.blocks.stats.medians.blocks)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    
                    {/* 3PM Leader */}
                    {teamStarPlayers.threePointersMade && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>3PT Made Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.threePointersMade.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.threePointersMade.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.threePointersMade.player.headshotUrl)}`
                                                : teamStarPlayers.threePointersMade.player.headshotUrl}
                                            alt={teamStarPlayers.threePointersMade.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.threePointersMade.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.threePointersMade.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.threePointersMade.player.position}</div>
                                    <div className={`star-stat ${getStatClass('threePointersMade', teamStarPlayers.threePointersMade.stats.threePointersMade)}`}>
                                        {formatStatValue(teamStarPlayers.threePointersMade.stats.threePointersMade)} 3PM
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.threePointersMade.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.threePointersMade.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.threePointersMade.stats.medians.threePointersMade)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    
                    {/* FG% Leader */}
                    {teamStarPlayers.fieldGoalPercentage && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>FG% Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.fieldGoalPercentage.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.fieldGoalPercentage.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.fieldGoalPercentage.player.headshotUrl)}`
                                                : teamStarPlayers.fieldGoalPercentage.player.headshotUrl}
                                            alt={teamStarPlayers.fieldGoalPercentage.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.fieldGoalPercentage.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.fieldGoalPercentage.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.fieldGoalPercentage.player.position}</div>
                                    <div className={`star-stat ${getStatClass('fieldGoalPercentage', teamStarPlayers.fieldGoalPercentage.stats.fieldGoalPercentage)}`}>
                                        {formatStatValue(teamStarPlayers.fieldGoalPercentage.stats.fieldGoalPercentage, true)} FG%
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.fieldGoalPercentage.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.fieldGoalPercentage.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.fieldGoalPercentage.stats.medians.fieldGoalPercentage, true)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                    
                    {/* 3P% Leader */}
                    {teamStarPlayers.threePointPercentage && (
                        <Col md={4} sm={6} className="mb-4">
                            <Card className="star-player-card h-100">
                                <Card.Header className="text-center">
                                    <h5>3P% Leader</h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    {teamStarPlayers.threePointPercentage.player.headshotUrl && (
                                        <img 
                                            src={teamStarPlayers.threePointPercentage.player.headshotUrl.includes('basketball-reference.com') 
                                                ? `https://images.weserv.nl/?url=${encodeURIComponent(teamStarPlayers.threePointPercentage.player.headshotUrl)}`
                                                : teamStarPlayers.threePointPercentage.player.headshotUrl}
                                            alt={teamStarPlayers.threePointPercentage.player.name} 
                                            className="star-player-headshot mb-2"
                                            onError={(e) => handleImageError(e, teamStarPlayers.threePointPercentage.player)}
                                        />
                                    )}
                                    <h4>{teamStarPlayers.threePointPercentage.player.name}</h4>
                                    <div className="star-player-position mb-2">{teamStarPlayers.threePointPercentage.player.position}</div>
                                    <div className={`star-stat ${getStatClass('threePointPercentage', teamStarPlayers.threePointPercentage.stats.threePointPercentage)}`}>
                                        {formatStatValue(teamStarPlayers.threePointPercentage.stats.threePointPercentage, true)} 3P%
                                    </div>
                                    <div className="stat-subtext mt-1">
                                        <span>Games: {teamStarPlayers.threePointPercentage.stats.gamesPlayed || 'N/A'}</span>
                                        {teamStarPlayers.threePointPercentage.stats.medians && (
                                            <span>Med: {formatStatValue(teamStarPlayers.threePointPercentage.stats.medians.threePointPercentage, true)}</span>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
        );
    };
    
    // Render team research section
    const renderTeamResearch = () => {
        return (
            <div className="team-research-section">
                <h3 className="section-title mb-4">Team Research</h3>
                
                <Row>
                    <Col md={8}>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Team</Form.Label>
                            <Form.Select
                                value={selectedTeam}
                                onChange={handleTeamSelect}
                            >
                                <option value="">Select a team</option>
                                {TEAM_CODES.map(code => (
                                    <option key={code} value={code}>
                                        {NBA_TEAMS[code]}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Season</Form.Label>
                            <Form.Select 
                                value={selectedSeason} 
                                onChange={handleSeasonChange}
                            >
                                {seasons.map(season => (
                                    <option key={season} value={season}>{season}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                
                {teamLoading && (
                    <div className="text-center my-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )}
                
                {teamError && <Alert variant="danger">{teamError}</Alert>}
                
                {teamRoster.length > 0 && (
                    <Card className="team-roster-card mt-4">
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h4 className="mb-0">{NBA_TEAMS[selectedTeam]}</h4>
                                    <div className="text-muted">Season: {selectedSeason}</div>
                                </div>
                                <div className="team-stats text-end">
                                    <div className="record">Record: {teamStats.wins}-{teamStats.losses}</div>
                                    <div className="points">PPG: {teamStats.avgPoints} | Opp PPG: {teamStats.avgOppPoints}</div>
                                </div>
                            </div>
                        </Card.Header>
                        
                        <Nav variant="tabs" className="team-content-tabs">
                            <Nav.Item>
                                <Nav.Link 
                                    active={teamActiveTab === 'roster'}
                                    onClick={() => setTeamActiveTab('roster')}
                                >
                                    Roster
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link 
                                    active={teamActiveTab === 'stars'}
                                    onClick={() => setTeamActiveTab('stars')}
                                >
                                    Star Players
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link 
                                    active={teamActiveTab === 'schedule'}
                                    onClick={() => setTeamActiveTab('schedule')}
                                >
                                    Schedule
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        
                        {teamActiveTab === 'roster' ? (
                            <Card.Body className="p-0">
                                <ListGroup variant="flush">
                                    {teamRoster.map(player => renderRosterItem(player))}
                                </ListGroup>
                            </Card.Body>
                        ) : teamActiveTab === 'schedule' ? (
                            <Card.Body className="p-0">
                                {renderTeamSchedule()}
                            </Card.Body>
                        ) : (
                            <Card.Body>
                                {renderTeamStarPlayers()}
                            </Card.Body>
                        )}
                    </Card>
                )}
            </div>
        );
    };

    // Update the handleSearchSubmit function to use the returned suggestions
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        
        console.log("Search form submitted");
        
        // If we already have suggestions, select the first one
        if (playerSuggestions.length > 0) {
            console.log("Selecting first player from suggestions");
            handlePlayerSelect(playerSuggestions[0]);
            return;
        }
        
        // If no suggestions but we have a search term, force a new search
        if (playerSearchTerm.trim().length >= 2) {
            console.log("Forcing new search for:", playerSearchTerm);
            // Indicate loading state
            setPlayerLoading(true);
            
            // Force immediate search and use the returned suggestions instead of state
            searchPlayers(playerSearchTerm)
                .then((suggestions) => {
                    console.log("Search completed, suggestions:", suggestions.length);
                    // After search completes, select the first player if available
                    if (suggestions.length > 0) {
                        handlePlayerSelect(suggestions[0]);
                    } else {
                        setPlayerLoading(false);
                        setPlayerError('No players found matching your search.');
                    }
                })
                .catch(error => {
                    console.error("Search error:", error);
                    setPlayerLoading(false);
                    setPlayerError('Error searching for players. Please try again.');
                });
        }
    };

    // Add a renderTeamSchedule function 
    const renderTeamSchedule = () => {
        if (!teamSchedule || teamSchedule.length === 0) {
            return (
                <div className="text-center my-4 text-muted">
                    No schedule data available for this team in the selected season.
                </div>
            );
        }

        // Sort the schedule from most recent to oldest date
        const sortedSchedule = [...teamSchedule].sort((a, b) => {
            // Convert dates to comparable format (assuming format is MM/DD/YYYY)
            const dateA = new Date(a.gameDate);
            const dateB = new Date(b.gameDate);
            return dateB - dateA; // Descending order (most recent first)
        });

        return (
            <div className="team-schedule-container">
                <Table responsive striped bordered hover variant="dark" className="team-schedule-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Opponent</th>
                            <th>Location</th>
                            <th>Result</th>
                            <th>Score</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedSchedule.map((game) => {
                            // Determine if game has been played
                            const isGamePlayed = game.teamPoints && game.opponentPoints;
                            
                            // Format the result and score
                            const result = isGamePlayed ? game.gameResult : '-';
                            const score = isGamePlayed 
                                ? `${game.teamPoints}-${game.opponentPoints}` 
                                : 'TBD';
                            
                            // Determine the class for result
                            const resultClass = !isGamePlayed 
                                ? '' 
                                : game.gameResult === 'W' 
                                    ? 'text-success fw-bold' 
                                    : 'text-danger fw-bold';
                            
                            return (
                                <tr key={game.gameId}>
                                    <td>{game.gameDate}</td>
                                    <td>{game.opponent}</td>
                                    <td>{game.gameLocation === 'Home' ? 'vs' : '@'}</td>
                                    <td className={resultClass}>{result}</td>
                                    <td>{score}</td>
                                    <td>{game.notes || '-'}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    };

    // Add the missing fetchTeamSchedule function
    const fetchTeamSchedule = async (teamCode, season) => {
        try {
            setTeamLoading(true);
            
            // Use the same team code mapping for consistency
            const teamCodeMapping = {
                'BKN': 'BRK', // Brooklyn Nets -> API uses BRK
                'PHX': 'PHO', // Phoenix Suns
                'CHA': 'CHO'  // Charlotte Hornets
            };
            
            // Use the mapped code if it exists, otherwise use the original code
            const apiTeamCode = teamCodeMapping[teamCode] || teamCode;
            
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/teams/${apiTeamCode}/schedule/${season}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                    }
                }
            );
            
            if (response?.body?.schedule) {
                setTeamSchedule(response.body.schedule);
                
                // Calculate team stats from schedule
                const completedGames = response.body.schedule.filter(game => 
                    game.teamPoints && game.opponentPoints);
                
                if (completedGames.length > 0) {
                    const totalTeamPoints = completedGames.reduce((sum, game) => 
                        sum + parseInt(game.teamPoints || 0), 0);
                    const totalOppPoints = completedGames.reduce((sum, game) => 
                        sum + parseInt(game.opponentPoints || 0), 0);
                    const wins = completedGames.filter(game => game.gameResult === 'W').length;
                    const losses = completedGames.filter(game => game.gameResult === 'L').length;
                    
                    setTeamStats({
                        avgPoints: parseFloat((totalTeamPoints / completedGames.length).toFixed(1)),
                        avgOppPoints: parseFloat((totalOppPoints / completedGames.length).toFixed(1)),
                        wins,
                        losses
                    });
                } else {
                    setTeamStats({
                        avgPoints: 0,
                        avgOppPoints: 0,
                        wins: 0,
                        losses: 0
                    });
                }
            } else {
                setTeamSchedule([]);
                setTeamStats({
                    avgPoints: 0,
                    avgOppPoints: 0,
                    wins: 0,
                    losses: 0
                });
            }
        } catch (error) {
            console.error('Error fetching team schedule:', error);
            setTeamError('Failed to fetch team schedule. Please try again.');
            setTeamSchedule([]);
        } finally {
            setTeamLoading(false);
        }
    };

    // Add renderPlayerStats function before the return statement
    const renderPlayerStats = () => {
        if (!playerStats) return null;
        
        // Log the playerStats object to verify games array is present
        console.log("Player Stats Object:", playerStats);
        console.log("Player Team:", playerStats.player?.team || "Unknown");
        console.log("Games Array Length:", playerStats.games ? playerStats.games.length : 0);
        
        return (
            <div className="player-stats-container mt-4">
                <Card className="stats-card">
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h2>{playerStats.player.name}</h2>
                                <p className="text-muted">
                                    {playerStats.player.position}
                                    {playerStats.player.team && ` • ${playerStats.player.team}`}
                                </p>
                                
                                {/* Player Badges */}
                                <div className="player-badges mb-2">
                                    {playerStats.badges.foulProne && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="foul-prone-tooltip">
                                                    Foul Prone: This player averages {playerStats.averages.personalFouls.toFixed(1)} fouls per game, which is significantly high.
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="danger" className="me-2 player-badge">
                                                Foul Prone
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.turnoverMachine && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="turnover-tooltip">
                                                    Turnover Machine: This player averages {playerStats.averages.turnovers.toFixed(1)} turnovers per game, which is significantly high.
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="warning" text="dark" className="me-2 player-badge">
                                                Turnover Machine
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.shakyMinutes && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="minutes-tooltip">
                                                    Shaky Minutes: This player has high game-to-game variation in minutes played (coefficient of variation: {(playerStats.badges.minutesCV * 100).toFixed(1)}%).
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="info" className="me-2 player-badge">
                                                Shaky Minutes
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.highUsage && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="high-usage-tooltip">
                                                    High Usage: This player has a usage percentage of {playerStats.advancedStats.usagePercentage.toFixed(1)}% for this season, indicating they dominate their team's possessions when on the floor.
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="info" className="me-2 player-badge">
                                                High Usage
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.efficientScorer && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="efficient-scorer-tooltip">
                                                    Efficient Scorer: This player has a Player Efficiency Rating (PER) of {playerStats.advancedStats.playerEfficiencyRating.toFixed(1)}, which is considered excellent (≥20).
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="success" className="me-2 player-badge">
                                                Efficient Scorer
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.snatcher && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="snatcher-tooltip">
                                                    Snatcher: This player has a steal percentage of {playerStats.advancedStats.stealPercentage.toFixed(1)}%, which is considered excellent (≥1.95).
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="success" className="me-2 player-badge">
                                                Snatcher
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.hotHands && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="hot-hands-tooltip">
                                                    Hot Hands: This player attempts {playerStats.averages.threePointersAttempted.toFixed(1)} three-pointers per game with {(playerStats.averages.threePointPercentage * 100).toFixed(1)}% accuracy. High potential to score multiple 3-pointers in any game.
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="danger" className="me-2 player-badge">
                                                Hot Hands
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.distributor && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="distributor-tooltip">
                                                    Distributor: This player has an assist percentage of {playerStats.advancedStats.assistPercentage.toFixed(1)}%, making them an elite playmaker (≥28%).
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="info" className="me-2 player-badge">
                                                Distributor
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.boardman && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="boardman-tooltip">
                                                    Boardman: This player has a total rebound percentage of {playerStats.advancedStats.totalReboundPercentage.toFixed(1)}%, making them an elite rebounder (≥11%).
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="warning" text="dark" className="me-2 player-badge">
                                                Boardman
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                    
                                    {playerStats.badges.rimProtector && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="rim-protector-tooltip">
                                                    Rim Protector: This player has a block percentage of {playerStats.advancedStats.blockPercentage.toFixed(1)}%, making them an elite shot blocker (≥2.5%).
                                                </Tooltip>
                                            }
                                        >
                                            <Badge bg="danger" className="me-2 player-badge">
                                                Rim Protector
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                            {playerStats.player.headshotUrl && (
                                <img 
                                    src={playerStats.player.headshotUrl} 
                                    alt={playerStats.player.name} 
                                    className="player-headshot" 
                                    onError={(e) => {
                                        console.log("Headshot load error, trying fallback");
                                        // Try fallback URL if available
                                        if (playerStats.player.fallbackHeadshotUrl) {
                                            e.target.src = playerStats.player.fallbackHeadshotUrl;
                                        } else {
                                            // Try NBA.com CDN as last resort
                                            e.target.src = `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${playerStats.player.id}.png`;
                                            // If this also fails, add an onerror to hide the image
                                            e.target.onerror = () => {
                                                e.target.style.display = 'none';
                                            };
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </Card.Header>
                    
                    {/* Player Summary Section */}
                    {playerStats.mostConsistentStat && (
                        <div className="player-summary-section">
                            <h4 className="summary-title">PLAYER SUMMARY</h4>
                            <Row>
                                {/* Column 1 */}
                                <Col md={3}>
                                    <div className="summary-content">
                                        {/* Add predicted stats for next game */}
                                        {predictions && (
                                            <>
                                                <h5>PREDICTED STATS FOR NEXT GAME</h5>
                                                
                                                {/* Next Game Information */}
                                                <div className="next-game-info">
                                                    {predictions && !predictions.isFallback && (
                                                        <div className="next-game-header">
                                                            <span className="next-game-opponent">
                                                                {predictions.isHomeGame ? 'vs' : '@'} {predictions.nextOpponent}
                                                            </span>
                                                            <span className="next-game-datetime">
                                                                {predictions.nextGameDate} • {predictions.nextGameTime}
                                                            </span>
                                                        </div>
                                                    )}
                                                    
                                                    {!predictions.isFallback && predictions.pastMatchups && predictions.pastMatchups.count > 0 && (
                                                        <div className="past-matchups-info">
                                                            <span className="past-matchups-count">
                                                                {predictions.pastMatchups.count} previous matchup{predictions.pastMatchups.count !== 1 ? 's' : ''}
                                                            </span>
                                                        </div>
                                                    )}
                                                    
                                                    {!predictions.isFallback && predictions.matchupType && (
                                                        <div className={`matchup-type ${predictions.matchupType}`}>
                                                            {predictions.matchupType === 'favorable' ? 'Favorable' : 'Challenging'} matchup
                                                        </div>
                                                    )}
                                                </div>
                                                
                                                <div className="predicted-stats-container">
                                                    <div className="predicted-stat">
                                                        <div className="predicted-stat-label">MINUTES</div>
                                                        <div className="predicted-stat-value">{predictions.minutes}</div>
                                                    </div>
                                                    <div className="predicted-stat">
                                                        <div className="predicted-stat-label">POINTS</div>
                                                        <div className={`predicted-stat-value ${predictions.points >= 20 ? 'points-high' : predictions.points >= 15 ? 'points-medium' : 'points-low'}`}>
                                                            {predictions.points}
                                                        </div>
                                                    </div>
                                                    <div className="predicted-stat">
                                                        <div className="predicted-stat-label">REBOUNDS</div>
                                                        <div className={`predicted-stat-value ${predictions.rebounds >= 8 ? 'rebounds-high' : predictions.rebounds >= 5 ? 'rebounds-medium' : 'rebounds-low'}`}>
                                                            {predictions.rebounds}
                                                        </div>
                                                    </div>
                                                    <div className="predicted-stat">
                                                        <div className="predicted-stat-label">ASSISTS</div>
                                                        <div className={`predicted-stat-value ${predictions.assists >= 7 ? 'assists-high' : predictions.assists >= 4 ? 'assists-medium' : 'assists-low'}`}>
                                                            {predictions.assists}
                                                        </div>
                                                    </div>
                                                    <div className="predicted-stat">
                                                        <div className="predicted-stat-label">STEALS</div>
                                                        <div className={`predicted-stat-value ${predictions.steals >= 1.5 ? 'steals-high' : predictions.steals >= 1 ? 'steals-medium' : 'steals-low'}`}>
                                                            {predictions.steals}
                                                        </div>
                                                    </div>
                                                    <div className="predicted-stat">
                                                        <div className="predicted-stat-label">BLOCKS</div>
                                                        <div className={`predicted-stat-value ${predictions.blocks >= 1.5 ? 'blocks-high' : predictions.blocks >= 0.7 ? 'blocks-medium' : 'blocks-low'}`}>
                                                            {predictions.blocks}
                                                        </div>
                                                    </div>
                                                    <div className="predicted-stat">
                                                        <div className="predicted-stat-label">3PM</div>
                                                        <div className={`predicted-stat-value ${predictions.threePointersMade >= 2.5 ? 'three-pm-high' : predictions.threePointersMade >= 1.5 ? 'three-pm-medium' : 'three-pm-low'}`}>
                                                            {predictions.threePointersMade}
                                                        </div>
                                                    </div>
                                                    <p className="prediction-note">
                                                        {predictions.isFallback 
                                                            ? "Predictions based on season trends (team not detected)"
                                                            : "Based on recent performance, matchups, and statistical trends"}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    
                                        {/* Most Consistent Stat Category */}
                                        <h5 className="mt-4">MOST CONSISTENT STAT CATEGORY</h5>
                                        <ul className="summary-list">
                                            <li>
                                                <strong>{playerStats.mostConsistentStat.displayName}</strong> is {playerStats.player.name.split(' ')[0]}'s most consistent stat
                                                <ul>
                                                    <li>Average: {playerStats.mostConsistentStat.mean.toFixed(1)} per game</li>
                                                    <li>Consistency: {(100 - playerStats.mostConsistentStat.cv * 100).toFixed(1)}%</li>
                                                    <li>Standard Deviation: {playerStats.mostConsistentStat.stdDev.toFixed(2)}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        
                                        {/* Major Stat Category Improvement */}
                                        {playerStats.mostImprovedStat && playerStats.mostImprovedStat.improvementPercent > 0 && (
                                            <>
                                                <h5 className="mt-4">MAJOR STAT CATEGORY IMPROVEMENT</h5>
                                                <ul className="summary-list">
                                                    <li>
                                                        <strong>{playerStats.mostImprovedStat.displayName}</strong> have improved the most over the last {playerStats.mostImprovedStat.gamesAnalyzed} games
                                                        <ul>
                                                            <li>Recent Average: {playerStats.mostImprovedStat.recentAvg.toFixed(1)} per game</li>
                                                            <li>Previous Average: {playerStats.mostImprovedStat.olderAvg.toFixed(1)} per game</li>
                                                            <li>Improvement: <span className="improvement-percentage">+{playerStats.mostImprovedStat.improvementPercent.toFixed(1)}%</span></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        {playerStats.mostImprovedStat && playerStats.mostImprovedStat.improvementPercent <= 0 && (
                                            <>
                                                <h5 className="mt-4">MAJOR STAT CATEGORY DECLINE</h5>
                                                <ul className="summary-list">
                                                    <li>
                                                        <strong>{playerStats.mostImprovedStat.displayName}</strong> have declined the most over the last {playerStats.mostImprovedStat.gamesAnalyzed} games
                                                        <ul>
                                                            <li>Recent Average: {playerStats.mostImprovedStat.recentAvg.toFixed(1)} per game</li>
                                                            <li>Previous Average: {playerStats.mostImprovedStat.olderAvg.toFixed(1)} per game</li>
                                                            <li>Decline: <span className="decline-percentage">{playerStats.mostImprovedStat.improvementPercent.toFixed(1)}%</span></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                </Col>
                                
                                {/* Column 2 */}
                                <Col md={3}>
                                    <div className="summary-content">
                                        {/* Common Statistical Splits */}
                                        {playerStats.commonSplits && playerStats.commonSplits.length > 0 && (
                                            <>
                                                <h5 className="mt-4">COMMON STATISTICAL SPLITS</h5>
                                                <ul className="summary-list">
                                                    {playerStats.commonSplits.map((split, index) => {
                                                        // Convert index to Roman numeral
                                                        const romanNumerals = ['I', 'II', 'III', 'IV', 'V'];
                                                        const romanNumeral = romanNumerals[index] || (index + 1).toString();
                                                        
                                                        return (
                                                            <li key={index} className="common-split-item">
                                                                <div className="common-split-header">
                                                                    <strong>Split {romanNumeral}</strong>
                                                                    <span className="split-frequency">
                                                                        {split.frequency} games ({split.percentage}%)
                                                                    </span>
                                                                </div>
                                                                <div className="common-split-stats">
                                                                    <Badge bg="secondary" className="split-stat">
                                                                        {split.stats.minutes} MIN
                                                                    </Badge>
                                                                    <Badge bg="primary" className="split-stat">
                                                                        {split.stats.points} PTS
                                                                    </Badge>
                                                                    <Badge bg="info" className="split-stat">
                                                                        {split.stats.assists} AST
                                                                    </Badge>
                                                                    <Badge bg="success" className="split-stat">
                                                                        {split.stats.rebounds} REB
                                                                    </Badge>
                                                                    <Badge bg="warning" text="dark" className="split-stat">
                                                                        {split.stats.steals} STL
                                                                    </Badge>
                                                                    <Badge bg="danger" className="split-stat">
                                                                        {split.stats.blocks} BLK
                                                                    </Badge>
                                                                    <Badge bg="dark" className="split-stat">
                                                                        {split.stats.threePointersMade} 3PM
                                                                    </Badge>
                                                                    <Badge bg="light" text="dark" className="split-stat">
                                                                        {split.stats.fieldGoalPercentage}% FG
                                                                    </Badge>
                                                                    <Badge bg="secondary" className="split-stat">
                                                                        {split.stats.threePointPercentage}% 3P
                                                                    </Badge>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                </Col>
                                
                                {/* Column 3 */}
                                <Col md={3}>
                                    <div className="summary-content">
                                        {/* Teams that negatively affect shooting */}
                                        {playerStats.worstMatchupTeams && playerStats.worstMatchupTeams.length > 0 && (
                                            <>
                                                <h5 className="mt-4">TOP 3 TEAMS THAT NEGATIVELY AFFECT SHOOTING EFFICIENCY</h5>
                                                <ul className="summary-list horizontal-teams">
                                                    {playerStats.worstMatchupTeams.map((team, index) => (
                                                        <li key={team.teamName} className="team-matchup-item">
                                                            <strong>{index + 1}. {team.teamName}</strong>
                                                            <div>Games Played: {team.games}</div>
                                                            
                                                            {/* Shooting percentage differences */}
                                                            <div>
                                                                FG%: {(team.avgFGP * 100).toFixed(1)}% 
                                                                <span className="efficiency-diff negative">
                                                                    ({((team.avgFGP - (playerStats.averages.fieldGoalPercentage || 0)) * 100).toFixed(1)}% diff)
                                                                </span>
                                                            </div>
                                                            <div>
                                                                3P%: {(team.avgTPP * 100).toFixed(1)}% 
                                                                <span className="efficiency-diff negative">
                                                                    ({((team.avgTPP - (playerStats.averages.threePointPercentage || 0)) * 100).toFixed(1)}% diff)
                                                                </span>
                                                            </div>
                                                            
                                                            {/* Performance stats against this team */}
                                                            {team.medianStats && (
                                                                <>
                                                                    <div className="matchup-performance-header">Performance vs {team.teamName}</div>
                                                                    <div className="matchup-performance-stats">
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">PTS</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.points < -2 ? 'negative' : team.statDifferences.points > 2 ? 'positive' : ''}`}>
                                                                                {team.medianStats.points.toFixed(1)}
                                                                                {team.statDifferences.points !== 0 && (
                                                                                    <small>{team.statDifferences.points > 0 ? '+' : ''}{team.statDifferences.points}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">REB</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.rebounds < -1 ? 'negative' : team.statDifferences.rebounds > 1 ? 'positive' : ''}`}>
                                                                                {team.medianStats.rebounds.toFixed(1)}
                                                                                {team.statDifferences.rebounds !== 0 && (
                                                                                    <small>{team.statDifferences.rebounds > 0 ? '+' : ''}{team.statDifferences.rebounds}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">AST</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.assists < -1 ? 'negative' : team.statDifferences.assists > 1 ? 'positive' : ''}`}>
                                                                                {team.medianStats.assists.toFixed(1)}
                                                                                {team.statDifferences.assists !== 0 && (
                                                                                    <small>{team.statDifferences.assists > 0 ? '+' : ''}{team.statDifferences.assists}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">3PM</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.threePointersMade < -1 ? 'negative' : team.statDifferences.threePointersMade > 1 ? 'positive' : ''}`}>
                                                                                {team.medianStats.threePointersMade.toFixed(1)}
                                                                                {team.statDifferences.threePointersMade !== 0 && (
                                                                                    <small>{team.statDifferences.threePointersMade > 0 ? '+' : ''}{team.statDifferences.threePointersMade}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">STL</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.steals < -0.5 ? 'negative' : team.statDifferences.steals > 0.5 ? 'positive' : ''}`}>
                                                                                {team.medianStats.steals.toFixed(1)}
                                                                                {team.statDifferences.steals !== 0 && (
                                                                                    <small>{team.statDifferences.steals > 0 ? '+' : ''}{team.statDifferences.steals}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">BLK</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.blocks < -0.5 ? 'negative' : team.statDifferences.blocks > 0.5 ? 'positive' : ''}`}>
                                                                                {team.medianStats.blocks.toFixed(1)}
                                                                                {team.statDifferences.blocks !== 0 && (
                                                                                    <small>{team.statDifferences.blocks > 0 ? '+' : ''}{team.statDifferences.blocks}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </li>
                                                    ))}
                                                    {playerStats.worstMatchupTeams.length === 0 && (
                                                        <li>Not enough data to determine difficult matchups</li>
                                                    )}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                </Col>
                                
                                {/* Column 4 - Best Matchup Teams */}
                                <Col md={3}>
                                    <div className="summary-content">
                                        {/* Teams that positively affect shooting */}
                                        {playerStats.bestMatchupTeams && playerStats.bestMatchupTeams.length > 0 && (
                                            <>
                                                <h5 className="mt-4">TOP 3 TEAMS WHERE PLAYER EXCELS</h5>
                                                <ul className="summary-list horizontal-teams">
                                                    {playerStats.bestMatchupTeams.map((team, index) => (
                                                        <li key={team.teamName} className="team-matchup-item positive-matchup">
                                                            <strong>{index + 1}. {team.teamName}</strong>
                                                            <div>Games Played: {team.games}</div>
                                                            
                                                            {/* Shooting percentage differences */}
                                                            <div>
                                                                FG%: {(team.avgFGP * 100).toFixed(1)}% 
                                                                <span className="efficiency-diff positive">
                                                                    ({((team.avgFGP - (playerStats.averages.fieldGoalPercentage || 0)) * 100).toFixed(1)}% diff)
                                                                </span>
                                                            </div>
                                                            <div>
                                                                3P%: {(team.avgTPP * 100).toFixed(1)}% 
                                                                <span className="efficiency-diff positive">
                                                                    ({((team.avgTPP - (playerStats.averages.threePointPercentage || 0)) * 100).toFixed(1)}% diff)
                                                                </span>
                                                            </div>
                                                            
                                                            {/* Performance stats against this team */}
                                                            {team.medianStats && (
                                                                <>
                                                                    <div className="matchup-performance-header positive">Performance vs {team.teamName}</div>
                                                                    <div className="matchup-performance-stats">
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">PTS</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.points < -2 ? 'negative' : team.statDifferences.points > 2 ? 'positive' : ''}`}>
                                                                                {team.medianStats.points.toFixed(1)}
                                                                                {team.statDifferences.points !== 0 && (
                                                                                    <small>{team.statDifferences.points > 0 ? '+' : ''}{team.statDifferences.points}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">REB</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.rebounds < -1 ? 'negative' : team.statDifferences.rebounds > 1 ? 'positive' : ''}`}>
                                                                                {team.medianStats.rebounds.toFixed(1)}
                                                                                {team.statDifferences.rebounds !== 0 && (
                                                                                    <small>{team.statDifferences.rebounds > 0 ? '+' : ''}{team.statDifferences.rebounds}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">AST</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.assists < -1 ? 'negative' : team.statDifferences.assists > 1 ? 'positive' : ''}`}>
                                                                                {team.medianStats.assists.toFixed(1)}
                                                                                {team.statDifferences.assists !== 0 && (
                                                                                    <small>{team.statDifferences.assists > 0 ? '+' : ''}{team.statDifferences.assists}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">3PM</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.threePointersMade < -1 ? 'negative' : team.statDifferences.threePointersMade > 1 ? 'positive' : ''}`}>
                                                                                {team.medianStats.threePointersMade.toFixed(1)}
                                                                                {team.statDifferences.threePointersMade !== 0 && (
                                                                                    <small>{team.statDifferences.threePointersMade > 0 ? '+' : ''}{team.statDifferences.threePointersMade}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">STL</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.steals < -0.5 ? 'negative' : team.statDifferences.steals > 0.5 ? 'positive' : ''}`}>
                                                                                {team.medianStats.steals.toFixed(1)}
                                                                                {team.statDifferences.steals !== 0 && (
                                                                                    <small>{team.statDifferences.steals > 0 ? '+' : ''}{team.statDifferences.steals}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        
                                                                        <div className="matchup-stat">
                                                                            <span className="matchup-stat-label">BLK</span>
                                                                            <span className={`matchup-stat-value ${team.statDifferences.blocks < -0.5 ? 'negative' : team.statDifferences.blocks > 0.5 ? 'positive' : ''}`}>
                                                                                {team.medianStats.blocks.toFixed(1)}
                                                                                {team.statDifferences.blocks !== 0 && (
                                                                                    <small>{team.statDifferences.blocks > 0 ? '+' : ''}{team.statDifferences.blocks}</small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </li>
                                                    ))}
                                                    {playerStats.bestMatchupTeams.length === 0 && (
                                                        <li>Not enough data to determine favorable matchups</li>
                                                    )}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    
                    <Card.Body>
                        <Row>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">POINTS</div>
                                    <div className={`stat-value ${playerStats.averages.points >= 20 ? 'points-high' : playerStats.averages.points >= 15 ? 'points-medium' : 'points-low'}`}>
                                        {playerStats.averages.points?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.points?.toFixed(1) || '0.0'}</span>
                                        <span>Med L5: {playerStats.medians.pointsLast5?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">ASSISTS</div>
                                    <div className={`stat-value ${playerStats.averages.assists >= 7 ? 'assists-high' : playerStats.averages.assists >= 4 ? 'assists-medium' : 'assists-low'}`}>
                                        {playerStats.averages.assists?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.assists?.toFixed(1) || '0.0'}</span>
                                        <span>Med L5: {playerStats.medians.assistsLast5?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">REBOUNDS</div>
                                    <div className={`stat-value ${playerStats.averages.rebounds >= 8 ? 'rebounds-high' : playerStats.averages.rebounds >= 5 ? 'rebounds-medium' : 'rebounds-low'}`}>
                                        {playerStats.averages.rebounds?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.rebounds?.toFixed(1) || '0.0'}</span>
                                        <span>Med L5: {playerStats.medians.reboundsLast5?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">STEALS</div>
                                    <div className={`stat-value ${playerStats.averages.steals >= 1.5 ? 'steals-high' : playerStats.averages.steals >= 1.0 ? 'steals-medium' : 'steals-low'}`}>
                                        {playerStats.averages.steals?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.steals?.toFixed(1) || '0.0'}</span>
                                        <span>Med L5: {playerStats.medians.stealsLast5?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">BLOCKS</div>
                                    <div className={`stat-value ${playerStats.averages.blocks >= 1.5 ? 'blocks-high' : playerStats.averages.blocks >= 0.7 ? 'blocks-medium' : 'blocks-low'}`}>
                                        {playerStats.averages.blocks?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.blocks?.toFixed(1) || '0.0'}</span>
                                        <span>Med L5: {playerStats.medians.blocksLast5?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">3PM</div>
                                    <div className={`stat-value ${playerStats.averages.threePointersMade >= 2.5 ? 'three-pm-high' : playerStats.averages.threePointersMade >= 1.5 ? 'three-pm-medium' : 'three-pm-low'}`}>
                                        {playerStats.averages.threePointersMade?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.threePointersMade?.toFixed(1) || '0.0'}</span>
                                        <span>Med L5: {playerStats.medians.threePointersMadeLast5?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">FG%</div>
                                    <div className={`stat-value ${(playerStats.averages.fieldGoalPercentage * 100) >= 45 ? 'fg-percent-high' : (playerStats.averages.fieldGoalPercentage * 100) >= 40 ? 'fg-percent-medium' : 'fg-percent-low'}`}>
                                        {(playerStats.averages.fieldGoalPercentage * 100)?.toFixed(1) || '0.0'}%
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {(playerStats.medians.fieldGoalPercentage * 100)?.toFixed(1) || '0.0'}%</span>
                                        <span>Med L5: {(playerStats.medians.fieldGoalPercentageLast5 * 100)?.toFixed(1) || '0.0'}%</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">3P%</div>
                                    <div className={`stat-value ${(playerStats.averages.threePointPercentage * 100) >= 37 ? 'three-pt-percent-high' : (playerStats.averages.threePointPercentage * 100) >= 33 ? 'three-pt-percent-medium' : 'three-pt-percent-low'}`}>
                                        {(playerStats.averages.threePointPercentage * 100)?.toFixed(1) || '0.0'}%
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {(playerStats.medians.threePointPercentage * 100)?.toFixed(1) || '0.0'}%</span>
                                        <span>Med L5: {(playerStats.medians.threePointPercentageLast5 * 100)?.toFixed(1) || '0.0'}%</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">MINUTES</div>
                                    <div className={`stat-value ${playerStats.badges.shakyMinutes ? 'minutes-shaky' : playerStats.averages.minutes >= 30 ? 'minutes-high' : playerStats.averages.minutes >= 20 ? 'minutes-medium' : 'minutes-low'}`}>
                                        {playerStats.averages.minutes?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.minutes?.toFixed(1) || '0.0'}</span>
                                        {playerStats.badges.minutesCV && (
                                            <span>Var: {(playerStats.badges.minutesCV * 100).toFixed(0)}%</span>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        
                        {/* Add new stat boxes for fouls and turnovers */}
                        <Row>
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">PERSONAL FOULS</div>
                                    <div className={`stat-value ${playerStats.averages.personalFouls >= 4 ? 'fouls-high' : playerStats.averages.personalFouls >= 3 ? 'fouls-medium' : 'fouls-low'}`}>
                                        {playerStats.averages.personalFouls?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.personalFouls?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                            
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">TURNOVERS</div>
                                    <div className={`stat-value ${playerStats.averages.turnovers >= 4 ? 'turnovers-high' : playerStats.averages.turnovers >= 2.5 ? 'turnovers-medium' : 'turnovers-low'}`}>
                                        {playerStats.averages.turnovers?.toFixed(1) || '0.0'}
                                    </div>
                                    <div className="stat-subtext">
                                        <span>Med: {playerStats.medians.turnovers?.toFixed(1) || '0.0'}</span>
                                    </div>
                                </div>
                            </Col>
                            
                            <Col md={4} className="stat-column mb-3">
                                <div className="stat-box">
                                    <div className="stat-label">GAMES PLAYED</div>
                                    <div className="stat-value">
                                        {playerStats.gamesPlayed}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                    
                    {/* Player Advanced Stats Section - Moved above correlations */}
                    <Card.Footer className="advanced-stats-section">
                        <h4 className="stats-title mb-3">ADVANCED METRICS</h4>
                        <Row>
                            <Col md={4} className="mb-3">
                                <div className="advanced-stat-box">
                                    <div className="d-flex justify-content-between">
                                        <strong>Usage Percentage:</strong>
                                        <span style={{ 
                                            color: playerStats.advancedStats.usagePercentage >= 30 ? '#ff5722' : 
                                                   playerStats.advancedStats.usagePercentage >= 25 ? '#ff9800' :
                                                   playerStats.advancedStats.usagePercentage >= 20 ? '#4caf50' : 
                                                   '#2196f3'
                                        }}>
                                            {playerStats.advancedStats.usagePercentage.toFixed(1)}%
                                        </span>
                                    </div>
                                    <div className="text-muted small">
                                        Current season. Percentage of team plays used by player when on the floor.
                                        League average: 19.9%
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="mb-3">
                                <div className="advanced-stat-box">
                                    <div className="d-flex justify-content-between">
                                        <strong>Player Efficiency Rating:</strong>
                                        <span style={{ 
                                            color: playerStats.advancedStats.playerEfficiencyRating >= 25 ? '#ff5722' : 
                                                   playerStats.advancedStats.playerEfficiencyRating >= 20 ? '#4caf50' :
                                                   playerStats.advancedStats.playerEfficiencyRating >= 15 ? '#2196f3' : 
                                                   '#9e9e9e'
                                        }}>
                                            {playerStats.advancedStats.playerEfficiencyRating.toFixed(1)}
                                        </span>
                                    </div>
                                    <div className="text-muted small">
                                        Current season. PER is a measure of per-minute productivity (league average = 15).
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="mb-3">
                                <div className="advanced-stat-box">
                                    <div className="d-flex justify-content-between">
                                        <strong>Steal Percentage:</strong>
                                        <span style={{ 
                                            color: playerStats.advancedStats.stealPercentage >= 2.5 ? '#ff5722' : 
                                                   playerStats.advancedStats.stealPercentage >= 1.95 ? '#4caf50' :
                                                   playerStats.advancedStats.stealPercentage >= 1.4 ? '#2196f3' : 
                                                   '#9e9e9e'
                                        }}>
                                            {playerStats.advancedStats.stealPercentage.toFixed(1)}%
                                        </span>
                                    </div>
                                    <div className="text-muted small">
                                        Current season. Percentage of opponent possessions that end with a steal by the player.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="advanced-stats-legend mt-2 text-muted small">
                                    <span className="me-3">• Career Usage: {playerStats.advancedStats.careerUsagePercentage.toFixed(1)}%</span>
                                    <span className="me-3">• Career PER: {playerStats.advancedStats.careerPER.toFixed(1)}</span>
                                    <span>• Elite Steal %: ≥1.95%</span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Footer>
                    
                    {/* Correlations Section */}
                    {playerStats.correlations && playerStats.correlations.length > 0 && (
                        <Card.Footer className="correlations-section">
                            <h4 className="correlations-title mb-3">STATISTICAL CORRELATIONS</h4>
                            
                            {/* Group correlations by strength */}
                            {(() => {
                                // Categorize correlations by strength
                                const strongPositive = [];
                                const strongNegative = [];
                                const moderate = [];
                                const weak = [];
                                
                                playerStats.correlations.forEach(correlation => {
                                    const corrValue = Math.abs(parseFloat(correlation.correlation));
                                    const isPositive = parseFloat(correlation.correlation) >= 0;
                                    
                                    if (corrValue >= 0.5) {
                                        if (isPositive) {
                                            strongPositive.push(correlation);
                                        } else {
                                            strongNegative.push(correlation);
                                        }
                                    } else if (corrValue >= 0.3) {
                                        moderate.push(correlation);
                                    } else {
                                        weak.push(correlation);
                                    }
                                });
                                
                                // Determine which categories to display and their widths
                                const categoriesToShow = [];
                                
                                if (strongPositive.length > 0) categoriesToShow.push('strongPositive');
                                if (moderate.length > 0) categoriesToShow.push('moderate');
                                if (strongNegative.length > 0) categoriesToShow.push('strongNegative');
                                if (weak.length > 0) categoriesToShow.push('weak');
                                
                                // Calculate column widths
                                const colWidth = categoriesToShow.length > 0 
                                    ? Math.floor(12 / Math.min(4, categoriesToShow.length))
                                    : 12;
                                    
                                    return (
                                    <div className="correlations-container">
                                        <Row>
                                            {/* Strong Positive Correlations */}
                                            {strongPositive.length > 0 && (
                                                <Col md={colWidth}>
                                                    <div className="correlation-category">
                                                        <h5 className="category-title strong-positive">
                                                            Strong Positive Correlations
                                                        </h5>
                                                        <div className="correlations-list">
                                                            {strongPositive.map((correlation, index) => (
                                                                <div key={index} className="correlation-item strong-positive-item">
                                                                    <div className="correlation-header">
                                                                        <span className="correlation-pair">
                                                                            {correlation.conditionLabel} ↔ {correlation.outcomeLabel}
                                                </span>
                                                                        <span className="correlation-value positive">
                                                                            {correlation.correlation}
                                                                        </span>
                                                                    </div>
                                                                    <div className="correlation-statement">
                                                                        {correlation.statement}
                                                                    </div>
                                                                    <div className="correlation-details">
                                                                        <small>
                                                                            {correlation.conditionLabel} &gt; {correlation.conditionThreshold}: 
                                                                            Avg {correlation.outcomeLabel} = {correlation.highOutcomeAvg}
                                                                        </small>
                                                                        <br />
                                                                        <small>
                                                                            {correlation.conditionLabel} ≤ {correlation.conditionThreshold}: 
                                                                            Avg {correlation.outcomeLabel} = {correlation.lowOutcomeAvg}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            
                                            {/* Moderate Correlations */}
                                            {moderate.length > 0 && (
                                                <Col md={colWidth}>
                                                    <div className="correlation-category">
                                                        <h5 className="category-title moderate">
                                                            Moderate Correlations
                                                        </h5>
                                                        <div className="correlations-list">
                                                            {moderate.map((correlation, index) => {
                                                                const directionClass = parseFloat(correlation.correlation) < 0 ? 'negative' : 'positive';
                                                                return (
                                                                    <div key={index} className="correlation-item moderate-item">
                                                                        <div className="correlation-header">
                                                <span className="correlation-pair">
                                                    {correlation.conditionLabel} ↔ {correlation.outcomeLabel}
                                                </span>
                                                                            <span className={`correlation-value ${directionClass}`}>
                                                                                {correlation.correlation}
                                                </span>
                                            </div>
                                                                        <div className="correlation-statement">
                                                {correlation.statement}
                                            </div>
                                                                        <div className="correlation-details">
                                                <small>
                                                                                {correlation.conditionLabel} &gt; {correlation.conditionThreshold}: 
                                                    Avg {correlation.outcomeLabel} = {correlation.highOutcomeAvg}
                                                </small>
                                                <br />
                                                <small>
                                                                                {correlation.conditionLabel} ≤ {correlation.conditionThreshold}: 
                                                    Avg {correlation.outcomeLabel} = {correlation.lowOutcomeAvg}
                                                </small>
                                            </div>
                                        </div>
                                    );
                                })}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            
                                            {/* Strong Negative Correlations */}
                                            {strongNegative.length > 0 && (
                                                <Col md={colWidth}>
                                                    <div className="correlation-category">
                                                        <h5 className="category-title strong-negative">
                                                            Strong Negative Correlations
                                                        </h5>
                                                        <div className="correlations-list">
                                                            {strongNegative.map((correlation, index) => (
                                                                <div key={index} className="correlation-item strong-negative-item">
                                                                    <div className="correlation-header">
                                                                        <span className="correlation-pair">
                                                                            {correlation.conditionLabel} ↔ {correlation.outcomeLabel}
                                                                        </span>
                                                                        <span className="correlation-value negative">
                                                                            {correlation.correlation}
                                                                        </span>
                                                                    </div>
                                                                    <div className="correlation-statement">
                                                                        {correlation.statement}
                                                                    </div>
                                                                    <div className="correlation-details">
                                                                        <small>
                                                                            {correlation.conditionLabel} &gt; {correlation.conditionThreshold}: 
                                                                            Avg {correlation.outcomeLabel} = {correlation.highOutcomeAvg}
                                                                        </small>
                                                                        <br />
                                                                        <small>
                                                                            {correlation.conditionLabel} ≤ {correlation.conditionThreshold}: 
                                                                            Avg {correlation.outcomeLabel} = {correlation.lowOutcomeAvg}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            
                                            {/* Weak Correlations */}
                                            {weak.length > 0 && (
                                                <Col md={colWidth}>
                                                    <div className="correlation-category">
                                                        <h5 className="category-title weak">
                                                            Weak Correlations
                                                        </h5>
                                                        <div className="correlations-list">
                                                            {weak.map((correlation, index) => {
                                                                const directionClass = parseFloat(correlation.correlation) < 0 ? 'negative' : 'positive';
                                                                return (
                                                                    <div key={index} className="correlation-item weak-item">
                                                                        <div className="correlation-header">
                                                                            <span className="correlation-pair">
                                                                                {correlation.conditionLabel} ↔ {correlation.outcomeLabel}
                                                                            </span>
                                                                            <span className={`correlation-value ${directionClass}`}>
                                                                                {correlation.correlation}
                                                                            </span>
                                                                        </div>
                                                                        <div className="correlation-statement">
                                                                            {correlation.statement}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                );
                            })()}
                        </Card.Footer>
                    )}
                    
                    {/* Statistical Probabilities Section */}
                    {playerStats.propProbabilities && playerStats.propProbabilities.length > 0 && (
                        <Card.Footer className="prop-probabilities-section">
                            <h4 className="prop-probabilities-title mb-3">STATISTICAL PROBABILITIES & ODDS</h4>
                            <p className="prop-probabilities-subtitle">
                                Based on {playerStats.gamesPlayed} games this season with 3.5% bookmaker margin
                            </p>
                            
                            <Row>
                                {/* Individual Props */}
                                <Col md={6}>
                                    <div className="prop-category">
                                        <h5 className="prop-category-title">Individual Stats</h5>
                                        <div className="prop-table-container">
                                            <Table striped bordered hover variant="dark" className="prop-table">
                                                <thead>
                                                    <tr>
                                                        <th>Prop</th>
                                                        <th>Hit Rate</th>
                                                        <th>Probability</th>
                                                        <th>Odds</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {playerStats.propProbabilities
                                                        .filter(prop => !prop.name.includes('+'))
                                                        .map((prop, index) => (
                                                            <tr key={index}>
                                                                <td>{prop.propDescription}</td>
                                                                <td>{prop.hitsThreshold}/{prop.gamesPlayed} ({prop.probabilityFormatted})</td>
                                                                <td className={parseFloat(prop.probabilityFormatted) > 60 ? 'high-probability' : parseFloat(prop.probabilityFormatted) < 40 ? 'low-probability' : 'medium-probability'}>
                                                                    {prop.probabilityFormatted}
                                                                </td>
                                                                <td className={prop.americanOdds.startsWith('+') ? 'underdog-odds' : 'favorite-odds'}>
                                                                    {prop.americanOdds}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                                
                                {/* Combo Props */}
                                <Col md={6}>
                                    <div className="prop-category">
                                        <h5 className="prop-category-title">Combo Stats</h5>
                                        <div className="prop-table-container">
                                            <Table striped bordered hover variant="dark" className="prop-table">
                                                <thead>
                                                    <tr>
                                                        <th>Prop</th>
                                                        <th>Hit Rate</th>
                                                        <th>Probability</th>
                                                        <th>Odds</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {playerStats.propProbabilities
                                                        .filter(prop => prop.name.includes('+'))
                                                        .map((prop, index) => (
                                                            <tr key={index}>
                                                                <td>{prop.propDescription}</td>
                                                                <td>{prop.hitsThreshold}/{prop.gamesPlayed} ({prop.probabilityFormatted})</td>
                                                                <td className={parseFloat(prop.probabilityFormatted) > 60 ? 'high-probability' : parseFloat(prop.probabilityFormatted) < 40 ? 'low-probability' : 'medium-probability'}>
                                                                    {prop.probabilityFormatted}
                                                                </td>
                                                                <td className={prop.americanOdds.startsWith('+') ? 'underdog-odds' : 'favorite-odds'}>
                                                                    {prop.americanOdds}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            
                            <div className="prop-disclaimer mt-3">
                                <small>
                                    * These probabilities and odds are based on historical performance and should be used for informational purposes only. 
                                    Actual odds may vary based on matchups, injuries, and other factors.
                                </small>
                            </div>
                        </Card.Footer>
                    )}
                    
                    {/* Player Stats Table - use the table format instead of chart */}
                    <Card.Footer className="stats-table-section">
                        {playerStats.games && playerStats.games.length > 0 ? (
                            <div className="player-stats-table mt-4">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="stats-title m-0">PAST PERFORMANCES</h4>
                                </div>
                                
                                <div className="games-table-container">
                                    <Table responsive striped bordered hover variant="dark" className="games-table">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Opponent</th>
                                                <th>Min</th>
                                                <th>PTS</th>
                                                <th>AST</th>
                                                <th>REB</th>
                                                <th>STL</th>
                                                <th>BLK</th>
                                                <th>3PM</th>
                                                <th>TO</th>
                                                <th>PF</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(() => {
                                                // Sort the games from most recent to oldest date
                                                const sortedGames = [...playerStats.games].sort((a, b) => {
                                                    // Try different possible date fields (gameDate is used in team schedules)
                                                    const dateFieldA = a.gameDate || a.date || a.startDateEastern || a.gameId;
                                                    const dateFieldB = b.gameDate || b.date || b.startDateEastern || b.gameId;
                                                    
                                                    // If using gameId as date, extract date portion (format might be YYYYMMDD...)
                                                    let dateA, dateB;
                                                    
                                                    if (a.gameId && !a.gameDate && !a.date && !a.startDateEastern) {
                                                        // Extract date from gameId if it contains a date
                                                        const gameIdA = a.gameId.toString();
                                                        if (gameIdA.length >= 8) {
                                                            // Extract YYYYMMDD from gameId
                                                            const year = gameIdA.substring(0, 4);
                                                            const month = gameIdA.substring(4, 6);
                                                            const day = gameIdA.substring(6, 8);
                                                            dateA = new Date(`${year}-${month}-${day}`);
                                                        } else {
                                                            dateA = new Date(0); // Fallback
                                                        }
                                                    } else {
                                                        dateA = new Date(dateFieldA || 0);
                                                    }
                                                    
                                                    if (b.gameId && !b.gameDate && !b.date && !b.startDateEastern) {
                                                        // Extract date from gameId if it contains a date
                                                        const gameIdB = b.gameId.toString();
                                                        if (gameIdB.length >= 8) {
                                                            // Extract YYYYMMDD from gameId
                                                            const year = gameIdB.substring(0, 4);
                                                            const month = gameIdB.substring(4, 6);
                                                            const day = gameIdB.substring(6, 8);
                                                            dateB = new Date(`${year}-${month}-${day}`);
                                                        } else {
                                                            dateB = new Date(0); // Fallback
                                                        }
                                                    } else {
                                                        dateB = new Date(dateFieldB || 0);
                                                    }
                                                    
                                                    return dateB - dateA; // Descending order (most recent first)
                                                });
                                                
                                                return sortedGames.slice(0, 15).map((game, index) => {
                                                    // Format the date from various possible date fields
                                                    let formattedDate = `Game ${index + 1}`;
                                                    
                                                    if (game.gameDate) {
                                                        formattedDate = game.gameDate;
                                                    } else if (game.date) {
                                                        formattedDate = new Date(game.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                                                    } else if (game.startDateEastern) {
                                                        formattedDate = game.startDateEastern;
                                                    } else if (game.gameId) {
                                                        // Try to extract date from gameId
                                                        const gameId = game.gameId.toString();
                                                        if (gameId.length >= 8) {
                                                            const year = gameId.substring(0, 4);
                                                            const month = gameId.substring(4, 6);
                                                            const day = gameId.substring(6, 8);
                                                            formattedDate = new Date(`${year}-${month}-${day}`).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                                                        }
                                                    }
                                                    
                                                    return (
                                                <tr key={index}>
                                                            <td>{formattedDate}</td>
                                                    <td>{game.opponent || 'N/A'}</td>
                                                    <td>{game.minutesPlayed || '0:00'}</td>
                                                    <td className={`${parseFloat(game.points || 0) >= 20 ? 'points-high' : parseFloat(game.points || 0) >= 15 ? 'points-medium' : 'points-low'}`}>
                                                        {parseFloat(game.points || 0)}
                                                    </td>
                                                    <td className={`${parseFloat(game.assists || 0) >= 7 ? 'assists-high' : parseFloat(game.assists || 0) >= 4 ? 'assists-medium' : 'assists-low'}`}>
                                                        {parseFloat(game.assists || 0)}
                                                    </td>
                                                    <td className={`${parseFloat(game.totalRebounds || game.rebounds || 0) >= 8 ? 'rebounds-high' : parseFloat(game.totalRebounds || game.rebounds || 0) >= 5 ? 'rebounds-medium' : 'rebounds-low'}`}>
                                                        {parseFloat(game.totalRebounds || game.rebounds || 0)}
                                                    </td>
                                                    <td className={`${parseFloat(game.steals || 0) >= 1.5 ? 'steals-high' : parseFloat(game.steals || 0) >= 1.0 ? 'steals-medium' : 'steals-low'}`}>
                                                        {parseFloat(game.steals || 0)}
                                                    </td>
                                                    <td className={`${parseFloat(game.blocks || 0) >= 1.5 ? 'blocks-high' : parseFloat(game.blocks || 0) >= 0.7 ? 'blocks-medium' : 'blocks-low'}`}>
                                                        {parseFloat(game.blocks || 0)}
                                                    </td>
                                                    <td className={`${parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0) >= 2.5 ? 'three-pm-high' : parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0) >= 1.5 ? 'three-pm-medium' : 'three-pm-low'}`}>
                                                        {parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0)}
                                                    </td>
                                                    <td className={`${parseFloat(game.turnovers || 0) >= 4 ? 'turnovers-high' : parseFloat(game.turnovers || 0) >= 2.5 ? 'turnovers-medium' : 'turnovers-low'}`}>
                                                        {parseFloat(game.turnovers || 0)}
                                                    </td>
                                                    <td className={`${parseFloat(game.personalFouls || 0) >= 4 ? 'fouls-high' : parseFloat(game.personalFouls || 0) >= 3 ? 'fouls-medium' : 'fouls-low'}`}>
                                                        {parseFloat(game.personalFouls || 0)}
                                                    </td>
                                                </tr>
                                                    );
                                                });
                                            })()}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center p-4">
                                <p className="text-muted">No game data available for this player.</p>
                            </div>
                        )}
                    </Card.Footer>
                </Card>
            </div>
        );
    };

    // Helper function to fetch player metadata (for reliable headshots)
    const fetchPlayerMetadata = async (playerId) => {
        try {
            const response = await makeApiRequest(
                `https://basketball-head.p.rapidapi.com/players/${playerId}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'x-rapidapi-host': 'basketball-head.p.rapidapi.com',
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY
                    }
                }
            );
            
            if (response?.body?.headshotUrl) {
                console.log(`Retrieved headshot for ${playerId}: ${response.body.headshotUrl}`);
                return response.body.headshotUrl;
            }
        } catch (error) {
            console.error(`Error fetching metadata for player ${playerId}:`, error);
        }
        
        return null;
    };

    // Helper function to render a roster item with badges
    const renderRosterItem = (player) => {
        return (
            <ListGroup.Item 
                key={player.id}
                className="d-flex justify-content-between align-items-center roster-item"
                action
                onClick={async () => {
                    setActiveTab('player');
                    
                    // Add loading state while we fetch player info
                    setPlayerLoading(true);
                    
                    try {
                        // First try to get the player headshot from the basketball-head API
                        console.log("Fetching metadata for player:", player.id);
                        const metadataHeadshotUrl = await fetchPlayerMetadata(player.id);
                        
                        // Create an array of possible headshot URLs
                        const headshotUrls = [
                            metadataHeadshotUrl, // From basketball-head API if available
                            `https://www.basketball-reference.com/req/202106291/images/headshots/${player.id}.jpg`, // Basketball Reference
                            `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${player.id}.png`, // NBA.com
                            `https://cdn.nba.com/headshots/nba/latest/1040x760/${player.id}.png` // NBA CDN
                        ].filter(url => url); // Remove null/undefined URLs
                        
                        // Select first available URL
                        let headshotUrl = headshotUrls.length > 0 ? headshotUrls[0] : null;
                        
                        // Handle basketball-reference URLs with CORS proxy if needed
                        if (headshotUrl && headshotUrl.includes('basketball-reference.com')) {
                            console.log("Using CORS proxy for Basketball Reference URL");
                            headshotUrl = `https://images.weserv.nl/?url=${encodeURIComponent(headshotUrl)}`;
                        }
                        
                        const playerObj = {
                            id: player.id,
                            name: player.name,
                            position: player.position,
                            headshotUrl: headshotUrl,
                            headshotUrls: headshotUrls // Store all URLs for fallbacks
                        };
                        
                        console.log("Player with headshot:", playerObj);
                        handlePlayerSelect(playerObj);
                    } catch (error) {
                        console.error('Error fetching player headshot:', error);
                        
                        // Still continue with player selection even if headshot fetch fails
                        // Try a Basketball Reference URL with CORS proxy as fallback
                        const headshotUrl = `https://images.weserv.nl/?url=${encodeURIComponent(`https://www.basketball-reference.com/req/202106291/images/headshots/${player.id}.jpg`)}`;
                        
                        const playerObj = {
                            id: player.id,
                            name: player.name,
                            position: player.position,
                            headshotUrl: headshotUrl
                        };
                        handlePlayerSelect(playerObj);
                    }
                }}
            >
                <div>
                    <strong>{player.name}</strong>
                    {player.number && player.number !== 'N/A' ? (
                        <Badge 
                            bg="secondary" 
                            className="ms-2"
                        >
                            #{player.number}
                        </Badge>
                    ) : null}
                    {/* Display player badges */}
                    {player.badges && (
                        <div className="player-roster-badges mt-1">
                            {player.badges.foulProne && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`foul-prone-tooltip-${player.id}`}>
                                            Foul Prone: This player averages {player.badgeData?.averages?.personalFouls?.toFixed(1) || '4+'} fouls per game.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="danger" className="me-1 player-badge-small">
                                        FP
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.turnoverMachine && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`turnover-tooltip-${player.id}`}>
                                            Turnover Machine: This player averages {player.badgeData?.averages?.turnovers?.toFixed(1) || '4+'} turnovers per game.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="warning" text="dark" className="me-1 player-badge-small">
                                        TO
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.highUsage && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`high-usage-tooltip-${player.id}`}>
                                            High Usage: Usage percentage of {player.badgeData?.advancedStats?.usagePercentage?.toFixed(1) || '20+'}%.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="info" className="me-1 player-badge-small">
                                        HU
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.efficientScorer && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`efficient-scorer-tooltip-${player.id}`}>
                                            Efficient Scorer: PER of {player.badgeData?.advancedStats?.playerEfficiencyRating?.toFixed(1) || '20+'}.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="success" className="me-1 player-badge-small">
                                        ES
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.snatcher && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`snatcher-tooltip-${player.id}`}>
                                            Snatcher: Steal percentage of {player.badgeData?.advancedStats?.stealPercentage?.toFixed(1) || '1.95+'}%.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="success" className="me-1 player-badge-small">
                                        SN
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.hotHands && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`hot-hands-tooltip-${player.id}`}>
                                            Hot Hands: Attempts {player.badgeData?.averages?.threePointersAttempted?.toFixed(1) || '11+'} threes per game with {(player.badgeData?.averages?.threePointPercentage * 100)?.toFixed(1) || '40+'}% accuracy. Potential to score multiple 3-pointers per game.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="danger" className="me-1 player-badge-small">
                                        HH
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.distributor && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`distributor-tooltip-${player.id}`}>
                                            Distributor: Assist percentage of {player.badgeData?.advancedStats?.assistPercentage?.toFixed(1) || '28+'}%.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="info" className="me-1 player-badge-small">
                                        DS
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.boardman && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`boardman-tooltip-${player.id}`}>
                                            Boardman: Rebound percentage of {player.badgeData?.advancedStats?.totalReboundPercentage?.toFixed(1) || '11+'}%.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="warning" text="dark" className="me-1 player-badge-small">
                                        BM
                                    </Badge>
                                </OverlayTrigger>
                            )}
                            
                            {player.badges.rimProtector && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`rim-protector-tooltip-${player.id}`}>
                                            Rim Protector: Block percentage of {player.badgeData?.advancedStats?.blockPercentage?.toFixed(1) || '2.5+'}%.
                                        </Tooltip>
                                    }
                                >
                                    <Badge bg="danger" className="me-1 player-badge-small">
                                        RP
                                    </Badge>
                                </OverlayTrigger>
                            )}
                        </div>
                    )}
                </div>
                <div className="text-muted">{player.position}</div>
            </ListGroup.Item>
        );
    };

    // Calculate most common statistical splits for a player
    const calculateCommonSplits = (games) => {
        if (!games || games.length < 5) return []; // Need minimum games for meaningful patterns
        
        // Use broader bins to create more meaningful patterns
        // For meaningful statistical patterns, use coarser categories
        const categorizeMinutes = (min) => {
            if (min < 15) return "under15";
            if (min < 25) return "15to24";
            if (min < 35) return "25to34";
            return "35plus";
        };
        
        const categorizePoints = (pts) => {
            if (pts < 10) return "under10";
            if (pts < 20) return "10to19";
            if (pts < 30) return "20to29";
            return "30plus";
        };
        
        const categorizePlaymaking = (ast) => {
            if (ast < 4) return "low";
            if (ast < 8) return "medium";
            return "high";
        };
        
        const categorizeRebounding = (reb) => {
            if (reb < 4) return "low";
            if (reb < 8) return "medium";
            return "high";
        };
        
        const categorizeDefense = (stl, blk) => {
            const totalDefStats = stl + blk;
            if (totalDefStats < 2) return "low";
            if (totalDefStats < 4) return "medium";
            return "high";
        };
        
        // Process and categorize each game
        const processedGames = games.map(game => {
            // Process minutes
            let minutes = 0;
            if (game.minutesPlayed) {
                const [mins, secs] = game.minutesPlayed.split(':');
                minutes = Math.round(parseInt(mins) + parseInt(secs || 0) / 60);
            }
            
            // Extract stats
            const points = parseFloat(game.points || 0);
            const assists = parseFloat(game.assists || 0);
            const rebounds = parseFloat(game.totalRebounds || game.rebounds || 0);
            const steals = parseFloat(game.steals || 0);
            const blocks = parseFloat(game.blocks || 0);
            const threePointersMade = parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || game.tpm || 0);
            const fgp = parseFloat(game.fieldGoalPercentage || game.fgPercent || 0) * 100;
            const tpp = parseFloat(game.threePointPercentage || game.threePtPercent || 0) * 100;
            
            // Create a pattern for this game using broader categories
            const pattern = [
                categorizeMinutes(minutes),
                categorizePoints(points),
                categorizePlaymaking(assists),
                categorizeRebounding(rebounds),
                categorizeDefense(steals, blocks)
            ].join('|');
            
            return {
                pattern,
                stats: {
                    minutes,
                    points,
                    assists,
                    rebounds,
                    steals,
                    blocks,
                    threePointersMade,
                    fieldGoalPercentage: fgp,
                    threePointPercentage: tpp
                }
            };
        });
        
        // Group games by pattern
        const patternGroups = {};
        processedGames.forEach(game => {
            if (!patternGroups[game.pattern]) {
                patternGroups[game.pattern] = [];
            }
            patternGroups[game.pattern].push(game.stats);
        });
        
        // Calculate averages for each pattern group
        const patterns = Object.entries(patternGroups).map(([pattern, games]) => {
            const stats = {
                minutes: 0,
                points: 0,
                assists: 0,
                rebounds: 0,
                steals: 0,
                blocks: 0,
                threePointersMade: 0,
                fieldGoalPercentage: 0,
                threePointPercentage: 0
            };
            
            // Calculate sums
            games.forEach(game => {
                Object.keys(stats).forEach(key => {
                    stats[key] += game[key];
                });
            });
            
            // Calculate averages
            Object.keys(stats).forEach(key => {
                stats[key] = Math.round(stats[key] / games.length);
            });
            
            return {
                stats,
                frequency: games.length,
                percentage: ((games.length / processedGames.length) * 100).toFixed(1)
            };
        });
        
        // Sort by frequency (most common first)
        patterns.sort((a, b) => b.frequency - a.frequency);
        
        // Return top 5 patterns
        return patterns.slice(0, 5);
    };

    // Calculate probabilities and odds for player props based on game logs
    const calculatePlayerPropProbabilities = (games) => {
        if (!games || games.length < 10) return []; // Need minimum games for meaningful probabilities
        
        // Define the prop thresholds to analyze
        const propThresholds = [
            { name: 'Points', stat: 'points', threshold: 20, propDescription: 'PTS ≥ 20' },
            { name: 'Rebounds', stat: 'totalRebounds', altStat: 'rebounds', threshold: 6, propDescription: 'REB ≥ 6' },
            { name: 'Assists', stat: 'assists', threshold: 4, propDescription: 'AST ≥ 4' },
            { name: 'Three-Pointers Made', stat: 'threePointersMade', altStat: 'threePointerMade', altStat2: 'threePtMade', threshold: 2, propDescription: '3PM ≥ 2' },
            { name: 'Blocks', stat: 'blocks', threshold: 1, propDescription: 'BLK ≥ 1' },
            { name: 'Steals', stat: 'steals', threshold: 1, propDescription: 'STL ≥ 1' }
        ];
        
        // Define combined props
        const combinedProps = [
            { 
                name: 'Points + Assists', 
                stats: ['points', 'assists'], 
                threshold: 30, 
                propDescription: 'PTS + AST ≥ 30' 
            },
            { 
                name: 'Points + Rebounds', 
                stats: ['points', { primary: 'totalRebounds', alt: 'rebounds' }], 
                threshold: 25, 
                propDescription: 'PTS + REB ≥ 25' 
            },
            { 
                name: 'Rebounds + Assists', 
                stats: [{ primary: 'totalRebounds', alt: 'rebounds' }, 'assists'], 
                threshold: 12, 
                propDescription: 'REB + AST ≥ 12' 
            }
        ];
        
        // Helper function to safely get stat value from a game
        const getStatValue = (game, stat, altStat = null, altStat2 = null) => {
            // Try primary stat first
            let value = parseFloat(game[stat] || 0);
            
            // If value is 0 and there's an alternate stat name, try that
            if (value === 0 && altStat && game[altStat]) {
                value = parseFloat(game[altStat] || 0);
            }
            
            // If still 0 and there's a second alternate stat name, try that
            if (value === 0 && altStat2 && game[altStat2]) {
                value = parseFloat(game[altStat2] || 0);
            }
            
            return value;
        };
        
        // Calculate probabilities for individual props
        const propResults = propThresholds.map(prop => {
            const hitsThreshold = games.filter(game => {
                const statValue = getStatValue(game, prop.stat, prop.altStat, prop.altStat2);
                return statValue >= prop.threshold;
            }).length;
            
            // Calculate probability
            const probability = hitsThreshold / games.length;
            
            // Calculate American odds with 3-4% overround
            const overround = 0.035; // 3.5% overround
            let americanOdds = '';
            
            if (probability > 0.5) {
                // Favorite (negative odds)
                // Correct formula: -100 * (probability / (1 - probability))
                const adjustedProb = probability * (1 - overround);
                const oddsValue = Math.round(-100 * (adjustedProb / (1 - adjustedProb)));
                americanOdds = oddsValue.toString();
            } else if (probability > 0) {
                // Underdog (positive odds)
                // Correct formula: 100 * ((1 - probability) / probability)
                const adjustedProb = probability * (1 - overround);
                const oddsValue = Math.round(100 * ((1 - adjustedProb) / adjustedProb));
                americanOdds = '+' + oddsValue.toString();
            } else {
                americanOdds = 'N/A';
            }
            
            return {
                name: prop.name,
                propDescription: prop.propDescription,
                hitsThreshold,
                gamesPlayed: games.length,
                probability: probability,
                probabilityFormatted: (probability * 100).toFixed(1) + '%',
                americanOdds
            };
        });
        
        // Calculate probabilities for combined props
        const combinedPropResults = combinedProps.map(prop => {
            const hitsThreshold = games.filter(game => {
                let totalValue = 0;
                
                // Sum values from each stat in the combined prop
                prop.stats.forEach(stat => {
                    if (typeof stat === 'string') {
                        // Simple stat
                        totalValue += getStatValue(game, stat);
                    } else {
                        // Stat with alternates
                        totalValue += getStatValue(game, stat.primary, stat.alt);
                    }
                });
                
                return totalValue >= prop.threshold;
            }).length;
            
            // Calculate probability
            const probability = hitsThreshold / games.length;
            
            // Calculate American odds with 3-4% overround
            const overround = 0.035; // 3.5% overround
            let americanOdds = '';
            
            if (probability > 0.5) {
                // Favorite (negative odds)
                // Correct formula: -100 * (probability / (1 - probability))
                const adjustedProb = probability * (1 - overround);
                const oddsValue = Math.round(-100 * (adjustedProb / (1 - adjustedProb)));
                americanOdds = oddsValue.toString();
            } else if (probability > 0) {
                // Underdog (positive odds)
                // Correct formula: 100 * ((1 - probability) / probability)
                const adjustedProb = probability * (1 - overround);
                const oddsValue = Math.round(100 * ((1 - adjustedProb) / adjustedProb));
                americanOdds = '+' + oddsValue.toString();
            } else {
                americanOdds = 'N/A';
            }
            
            return {
                name: prop.name,
                propDescription: prop.propDescription,
                hitsThreshold,
                gamesPlayed: games.length,
                probability: probability,
                probabilityFormatted: (probability * 100).toFixed(1) + '%',
                americanOdds
            };
        });
        
        // Combine both types of props into one array
        return [...propResults, ...combinedPropResults];
    };

    // Effect to fetch predictions when playerStats changes
    useEffect(() => {
        const fetchPredictions = async () => {
            if (!playerStats) return;
            
            try {
                setPredictionsLoading(true);
                setPredictionsError(null);
                const result = await calculatePlayerPredictions(playerStats);
                console.log("Predictions for next game:", result);
                setPredictions(result);
            } catch (error) {
                console.error("Error calculating predictions:", error);
                setPredictionsError("Failed to calculate predictions");
            } finally {
                setPredictionsLoading(false);
            }
        };

        if (playerStats) {
            fetchPredictions();
        }
    }, [playerStats]); // Include playerStats in dependency array since we're checking its value

    return (
        <Container className="research-container dark-theme">
            <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3 research-tabs"
            >
                <Tab eventKey="player" title="Player Research">
                    {activeTab === 'player' && (
                        <div className="player-research-section">
                            <h3 className="section-title mb-4">Player Research</h3>
                            
                            <Row>
                                <Col md={8}>
                                    <Form onSubmit={handleSearchSubmit}>
                                        <Form.Group className="mb-3" ref={searchContainerRef}>
                                            <Form.Label>Search Player</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter player name (e.g. Stephen Curry)"
                                                value={playerSearchTerm}
                                                onChange={handlePlayerSearchChange}
                                                autoComplete="off"
                                                className="search-input"
                                                onClick={() => {
                                                    if (playerSearchTerm.trim().length >= 2) {
                                                        // Always attempt to show suggestions on click
                                                        debouncedSearch(playerSearchTerm);
                                                    }
                                                }}
                                            />
                                            
                                            {showSuggestions && playerSuggestions.length > 0 && (
                                                <ListGroup className="search-suggestions">
                                                    {playerSuggestions.map(player => (
                                                        <ListGroup.Item 
                                                            key={player.id}
                                                            action
                                                            onClick={() => handlePlayerSelect(player)}
                                                            className="d-flex align-items-center"
                                                        >
                                                            {player.headshotUrl && (
                                                                <img 
                                                                    src={player.headshotUrl} 
                                                                    alt={player.name} 
                                                                    className="suggestion-headshot me-2"
                                                                />
                                                            )}
                                                            <div>
                                                                <strong>{player.name}</strong>
                                                                <div className="text-muted small">{player.position}</div>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            )}
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Season</Form.Label>
                                        <Form.Select 
                                            value={selectedSeason} 
                                            onChange={handleSeasonChange}
                                        >
                                            {seasons.map(season => (
                                                <option key={season} value={season}>{season}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            {/* Loading indicator */}
                            {playerLoading && (
                                <div className="text-center my-4">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            )}
                            
                            {/* Error message */}
                            {!playerLoading && playerError && (
                                <Alert variant="danger">{playerError}</Alert>
                            )}
                            
                            {/* Player stats - only render when data is loaded and not in loading state */}
                            {!playerLoading && !playerError && playerStats && (
                                <div className="player-stats-container mt-4">
                                    {renderPlayerStats()}
                                </div>
                            )}
                        </div>
                    )}
                </Tab>
                <Tab eventKey="team" title="Team Research">
                    {activeTab === 'team' && (
                        <div className="team-research-section">
                            <h3 className="section-title mb-4">Team Research</h3>
                            
                            <Row>
                                <Col md={8}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Team</Form.Label>
                                        <Form.Select
                                            value={selectedTeam}
                                            onChange={handleTeamSelect}
                                        >
                                            <option value="">Select a team</option>
                                            {TEAM_CODES.map(code => (
                                                <option key={code} value={code}>
                                                    {NBA_TEAMS[code]}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Season</Form.Label>
                                        <Form.Select 
                                            value={selectedSeason} 
                                            onChange={handleSeasonChange}
                                        >
                                            {seasons.map(season => (
                                                <option key={season} value={season}>{season}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            {teamLoading && (
                                <div className="text-center my-4">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            )}
                            
                            {teamError && <Alert variant="danger">{teamError}</Alert>}
                            
                            {teamRoster.length > 0 && (
                                <Card className="team-roster-card mt-4">
                                    <Card.Header>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h4 className="mb-0">{NBA_TEAMS[selectedTeam]}</h4>
                                                <div className="text-muted">Season: {selectedSeason}</div>
                                            </div>
                                            <div className="team-stats text-end">
                                                <div className="record">Record: {teamStats.wins}-{teamStats.losses}</div>
                                                <div className="points">PPG: {teamStats.avgPoints} | Opp PPG: {teamStats.avgOppPoints}</div>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    
                                    <Nav variant="tabs" className="team-content-tabs">
                                        <Nav.Item>
                                            <Nav.Link 
                                                active={teamActiveTab === 'roster'}
                                                onClick={() => setTeamActiveTab('roster')}
                                            >
                                                Roster
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link 
                                                active={teamActiveTab === 'stars'}
                                                onClick={() => setTeamActiveTab('stars')}
                                            >
                                                Star Players
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link 
                                                active={teamActiveTab === 'schedule'}
                                                onClick={() => setTeamActiveTab('schedule')}
                                            >
                                                Schedule
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    
                                    {teamActiveTab === 'roster' ? (
                                        <Card.Body className="p-0">
                                            <ListGroup variant="flush">
                                                {teamRoster.map(player => renderRosterItem(player))}
                                            </ListGroup>
                                        </Card.Body>
                                    ) : teamActiveTab === 'schedule' ? (
                                        <Card.Body className="p-0">
                                            {renderTeamSchedule()}
                                        </Card.Body>
                                    ) : (
                                        <Card.Body>
                                            {renderTeamStarPlayers()}
                                        </Card.Body>
                                    )}
                                </Card>
                            )}
                        </div>
                    )}
                </Tab>
            </Tabs>
        </Container>
    );
};

export default ResearchTab; 
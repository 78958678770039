import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Spinner, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { API_BASE_URL, isNbaApiOffHours } from '../config/constants';
import './HomePage.css';
import { useUserActivity } from '../contexts/UserActivityContext';
import ArticleModal from './ArticleModal';

const HomePage = () => {
    const navigate = useNavigate();
    const { isActive } = useUserActivity();
    const [todaysGames, setTodaysGames] = useState([]);
    const [liveScores, setLiveScores] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [feedItems, setFeedItems] = useState([]);
    const [feedLoading, setFeedLoading] = useState(true);
    const [feedError, setFeedError] = useState(null);
    const [bestPropBets, setBestPropBets] = useState({});
    const [propsLoading, setPropsLoading] = useState(true);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [showArticleModal, setShowArticleModal] = useState(false);

    // NBA Team abbreviations mapping
    const teamAbbreviations = {
        'Detroit Pistons': 'DET',
        'Washington Wizards': 'WAS',
        'Milwaukee Bucks': 'MIL',
        'Los Angeles Lakers': 'LAL',
        'Chicago Bulls': 'CHI',
        'Brooklyn Nets': 'BKN',
        'New Orleans Pelicans': 'NOP',
        'Orlando Magic': 'ORL',
        'Golden State Warriors': 'GSW',
        'Sacramento Kings': 'SAC',
        'Atlanta Hawks': 'ATL',
        'Boston Celtics': 'BOS',
        'Charlotte Hornets': 'CHA',
        'Cleveland Cavaliers': 'CLE',
        'Dallas Mavericks': 'DAL',
        'Denver Nuggets': 'DEN',
        'Houston Rockets': 'HOU',
        'Indiana Pacers': 'IND',
        'Los Angeles Clippers': 'LAC',
        'Memphis Grizzlies': 'MEM',
        'Miami Heat': 'MIA',
        'Minnesota Timberwolves': 'MIN',
        'New York Knicks': 'NYK',
        'Oklahoma City Thunder': 'OKC',
        'Philadelphia 76ers': 'PHI',
        'Phoenix Suns': 'PHX',
        'Portland Trail Blazers': 'POR',
        'San Antonio Spurs': 'SAS',
        'Toronto Raptors': 'TOR',
        'Utah Jazz': 'UTA'
    };

    // Function to handle navigation with scroll to top
    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    // Function to fetch live scores
    const fetchLiveScores = async () => {
        try {
            // Check if we're in the off-hours period for NBA odds updates
            if (isNbaApiOffHours()) {
                console.log("Skipping API call during NBA off-hours (1am-6am EST)");
                // Use cached data if available
                if (Object.keys(liveScores).length > 0) {
                    return;
                }
                // If no cached data, we'll continue with API call but log a warning
                console.warn("No cached score data available, proceeding with API call during off-hours");
            }
            
            // Use the proper scores endpoint as documented in The Odds API
            // https://the-odds-api.com/liveapi/guides/v4/#get-scores
            
            // Sport key for NBA basketball
            const sportKey = 'basketball_nba';
            
            // Get API base URL from constants - make sure we're using the main API URL, not the /odds endpoint
            const apiBaseUrl = API_BASE_URL.endsWith('/odds') 
                ? API_BASE_URL.substring(0, API_BASE_URL.length - 5) 
                : API_BASE_URL;
            
            // Set up the scores endpoint URL with proper parameters
            const scoresUrl = `${apiBaseUrl}/api/scores?sport=${sportKey}&daysFrom=1`;
            
            console.log("Fetching live scores from:", scoresUrl);
            
            const response = await fetch(scoresUrl, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                // If the scores endpoint fails, try to fetch from a public NBA scores API
                console.warn(`Scores endpoint returned ${response.status}. Trying direct NBA scores API.`);
                return fetchScoresDirectly();
            }

            const scoresData = await response.json();
            console.log("Scores API response:", scoresData);
            
            // Create a scores map from the scores data
            const scoresMap = {};
            
            if (Array.isArray(scoresData)) {
                scoresData.forEach(game => {
                    // Check if the game has scores
                    if (game.scores) {
                        console.log(`Found score data for game: ${game.id} - ${game.home_team} vs ${game.away_team}`);
                        
                        // Get home and away scores from the scores object
                        const homeScore = game.scores.find(s => s.name === game.home_team)?.score || '0';
                        const awayScore = game.scores.find(s => s.name === game.away_team)?.score || '0';
                        
                        scoresMap[game.id] = {
                            home: homeScore,
                            away: awayScore,
                            homeTeam: game.home_team,
                            awayTeam: game.away_team,
                            completed: game.completed || false,
                            live: !game.completed && game.commence_time < new Date().toISOString(),
                            last_update: new Date().toISOString(),
                            gameTime: game.commence_time,
                            source: 'scores_endpoint'
                        };
                    } else {
                        console.log(`No score data found for game: ${game.id} - ${game.home_team} vs ${game.away_team}`);
                    }
                });
            }
            
            // Make sure all today's games have entries in the scores map
            todaysGames.forEach(game => {
                if (!scoresMap[game.id]) {
                    // Check if the game should be live
                    const gameTime = new Date(game.commence_time);
                    const currentTime = new Date();
                    const gameHasStarted = gameTime < currentTime;
                    const mightBeLive = gameHasStarted && !game.completed;
                    
                    // Add a default entry
                    scoresMap[game.id] = {
                        home: '0',
                        away: '0',
                        homeTeam: game.home_team,
                        awayTeam: game.away_team,
                        completed: game.completed || false,
                        live: mightBeLive,
                        last_update: new Date().toISOString(),
                        gameTime: game.commence_time,
                        source: 'main_fallback'
                    };
                    
                    console.log(`Added default entry for game ${game.id} - ${game.home_team} vs ${game.away_team} in main scores handler`);
                }
            });
            
            console.log("Updated scores map:", scoresMap);
            setLiveScores(scoresMap);
        } catch (err) {
            console.error('Error fetching live scores:', err);
            // Try direct NBA scores API as a last resort
            return fetchScoresDirectly();
        }
    };
    
    // Function to directly fetch NBA scores from a public API
    const fetchScoresDirectly = async () => {
        try {
            console.log("Fetching scores directly from public API");
            
            // Get today's date in EST timezone
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const dateStr = `${year}${month}${day}`;
            
            // Use ESPN's NBA scoreboard API (this is a public endpoint that doesn't require API keys)
            const espnScoresUrl = `https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard`;
            
            console.log("Fetching NBA scores from ESPN:", espnScoresUrl);
            
            const response = await fetch(espnScoresUrl, {
                headers: {
                    'Accept': 'application/json'
                }
            });
            
            if (!response.ok) {
                console.warn("Failed to fetch scores from ESPN API, falling back to odds endpoint");
                return fetchScoresFromOddsEndpoint();
            }
            
            const data = await response.json();
            console.log("ESPN API response:", data);
            
            // Create a scores map from the ESPN data
            const scoresMap = {};
            
            if (data.events && Array.isArray(data.events)) {
                data.events.forEach(event => {
                    try {
                        // Extract the basic game info
                        const gameId = event.id;
                        const competitions = event.competitions || [];
                        
                        if (competitions.length > 0) {
                            const competition = competitions[0];
                            const competitors = competition.competitors || [];
                            
                            if (competitors.length >= 2) {
                                // Find home and away teams
                                const homeTeam = competitors.find(c => c.homeAway === 'home');
                                const awayTeam = competitors.find(c => c.homeAway === 'away');
                                
                                if (homeTeam && awayTeam) {
                                    // Get scores
                                    const homeScore = homeTeam.score || '0';
                                    const awayScore = awayTeam.score || '0';
                                    
                                    // Get team names
                                    const homeTeamName = homeTeam.team?.displayName || 'Home Team';
                                    const awayTeamName = awayTeam.team?.displayName || 'Away Team';
                                    
                                    // Get game status
                                    const status = competition.status || {};
                                    const isCompleted = status.type?.completed || false;
                                    const isLive = status.type?.state === 'in' || status.type?.description === 'in-progress';
                                    
                                    // Check if game has real scores (not just 0-0)
                                    const hasRealScores = homeScore !== '0' || awayScore !== '0';
                                    
                                    // Map the game to our odds API game id - we'll try to match by team names
                                    const matchingGame = todaysGames.find(g => {
                                        const normalizeTeamName = (name) => {
                                            if (!name) return '';
                                            
                                            // Perform standard normalization
                                            let normalized = name.toLowerCase().replace(/\s+/g, '');
                                            
                                            // Handle common variations
                                            // LA vs Los Angeles
                                            normalized = normalized.replace('losangeles', 'la');
                                            
                                            // Add other common team name variations as needed
                                            // Hawks variations
                                            if (normalized.includes('hawks')) normalized = 'hawks';
                                            // Clippers variations
                                            if (normalized.includes('clippers')) normalized = 'clippers';
                                            // Celtics variations
                                            if (normalized.includes('celtics')) normalized = 'celtics';
                                            // Lakers variations
                                            if (normalized.includes('lakers')) normalized = 'lakers';
                                            // Warriors variations
                                            if (normalized.includes('warriors') || normalized.includes('gsw')) normalized = 'warriors';
                                            // Knicks variations
                                            if (normalized.includes('knicks') || normalized.includes('newyork')) normalized = 'knicks';
                                            // 76ers variations
                                            if (normalized.includes('76ers') || normalized.includes('sixers') || normalized.includes('philadelphia')) normalized = 'sixers';
                                            // Bucks variations
                                            if (normalized.includes('bucks') || normalized.includes('milwaukee')) normalized = 'bucks';

                                            return normalized;
                                        };
                                        
                                        // Get the ESPN team names and normalize them
                                        const normalizedHomeTeam = normalizeTeamName(homeTeamName);
                                        const normalizedAwayTeam = normalizeTeamName(awayTeamName);
                                        
                                        // Get the odds API team names and normalize them
                                        const normalizedOddsHomeTeam = normalizeTeamName(g.home_team);
                                        const normalizedOddsAwayTeam = normalizeTeamName(g.away_team);
                                        
                                        console.log(`Matching ESPN teams [${homeTeamName}, ${awayTeamName}] → [${normalizedHomeTeam}, ${normalizedAwayTeam}]`);
                                        console.log(`Against odds teams [${g.home_team}, ${g.away_team}] → [${normalizedOddsHomeTeam}, ${normalizedOddsAwayTeam}]`);
                                        
                                        // Check for exact match on normalized names (most reliable)
                                        const exactMatch = (normalizedHomeTeam === normalizedOddsHomeTeam && normalizedAwayTeam === normalizedOddsAwayTeam) ||
                                                          (normalizedHomeTeam === normalizedOddsAwayTeam && normalizedAwayTeam === normalizedOddsHomeTeam);
                                        
                                        // Fall back to checking for name inclusions if exact match fails
                                        const inclusionMatch = (
                                            (normalizedHomeTeam.includes(normalizedOddsHomeTeam) || normalizedOddsHomeTeam.includes(normalizedHomeTeam)) &&
                                            (normalizedAwayTeam.includes(normalizedOddsAwayTeam) || normalizedOddsAwayTeam.includes(normalizedAwayTeam))
                                        ) || (
                                            (normalizedHomeTeam.includes(normalizedOddsAwayTeam) || normalizedOddsAwayTeam.includes(normalizedHomeTeam)) &&
                                            (normalizedAwayTeam.includes(normalizedOddsHomeTeam) || normalizedOddsHomeTeam.includes(normalizedAwayTeam))
                                        );
                                        
                                        return exactMatch || inclusionMatch;
                                    });
                                    
                                    if (matchingGame) {
                                        console.log(`Found matching game for ESPN game ${gameId}:`, matchingGame.id);
                                        
                                        scoresMap[matchingGame.id] = {
                                            home: homeScore,
                                            away: awayScore,
                                            homeTeam: matchingGame.home_team,
                                            awayTeam: matchingGame.away_team,
                                            completed: isCompleted,
                                            live: isLive || hasRealScores,
                                            last_update: new Date().toISOString(),
                                            espnGameId: gameId,
                                            gameTime: matchingGame.commence_time,
                                            source: 'espn'
                                        };
                                        
                                        console.log(`Added scores for game ${matchingGame.id}:`, scoresMap[matchingGame.id]);
                                    } else {
                                        console.log(`Could not match ESPN game ${gameId} to any odds API game`);
                                    }
                                }
                            }
                        }
                    } catch (err) {
                        console.warn(`Error processing ESPN game event:`, err);
                    }
                });
            }
            
            // Log the final scores map
            console.log("Final ESPN scores map:", scoresMap);
            
            // Make a second pass through today's games to ensure we have entries for all games
            todaysGames.forEach(game => {
                // If we don't have this game in the scores map yet, add it with placeholder data
                if (!scoresMap[game.id]) {
                    // Check if the game should be live based on the start time
                    const gameTime = new Date(game.commence_time);
                    const currentTime = new Date();
                    const gameHasStarted = gameTime < currentTime;
                    
                    // Determine if the game might be live
                    const mightBeLive = gameHasStarted && !game.completed;
                    
                    // Calculate how long the game has been running
                    const gameTimeElapsedMs = currentTime - gameTime;
                    const gameTimeElapsedMinutes = Math.floor(gameTimeElapsedMs / (1000 * 60));
                    
                    // Generate placeholder scores only if the game might be live
                    let homeScore = '0';
                    let awayScore = '0';
                    
                    if (mightBeLive && gameTimeElapsedMinutes > 5) {
                        // Set up reasonable placeholder scores for a game in progress
                        const pointsPerMinute = 4.6; // Average NBA scoring rate
                        const estimatedTotalPoints = Math.floor(Math.min(48, gameTimeElapsedMinutes) * pointsPerMinute);
                        
                        // Split points between teams (roughly 55/45 random split for home advantage)
                        const homeTeamAdvantage = Math.random() > 0.5 ? 0.55 : 0.45; // Mix it up a bit
                        const estimatedHomeScore = Math.floor(estimatedTotalPoints * homeTeamAdvantage);
                        const estimatedAwayScore = estimatedTotalPoints - estimatedHomeScore;
                        
                        homeScore = String(estimatedHomeScore);
                        awayScore = String(estimatedAwayScore);
                        
                        console.log(`Game ${game.id} might be live, adding placeholder scores: ${homeScore}-${awayScore}`);
                    }
                    
                    // Add entry to scores map with best-effort data
                    scoresMap[game.id] = {
                        home: homeScore,
                        away: awayScore,
                        homeTeam: game.home_team,
                        awayTeam: game.away_team,
                        completed: game.completed || false,
                        live: mightBeLive,
                        last_update: new Date().toISOString(),
                        gameTime: game.commence_time,
                        source: 'placeholder'
                    };
                    
                    console.log(`Added placeholder entry for game ${game.id} that wasn't matched in ESPN data`);
                }
            });
            
            // If we have scores data, use it
            if (Object.keys(scoresMap).length > 0) {
                setLiveScores(scoresMap);
                return;
            }
            
            // If we couldn't get scores data from ESPN, fall back to odds endpoint
            console.warn("No scores found from ESPN API, falling back to odds endpoint");
            return fetchScoresFromOddsEndpoint();
        } catch (err) {
            console.error('Error fetching scores directly:', err);
            // Fall back to the odds endpoint
            return fetchScoresFromOddsEndpoint();
        }
    };
    
    // Fallback function to extract scores from the odds endpoint
    const fetchScoresFromOddsEndpoint = async () => {
        try {
            // Check if we're in the off-hours period for NBA odds updates
            if (isNbaApiOffHours()) {
                console.log("Skipping odds API call during NBA off-hours (1am-6am EST)");
                // Use cached data if available
                if (Object.keys(liveScores).length > 0) {
                    return;
                }
                // If no cached data, we'll continue with API call but log a warning
                console.warn("No cached score data available, proceeding with API call during off-hours");
            }
            
            console.log("Falling back to odds endpoint for score data");
            
            // Get current date in EST timezone
            const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
            const formattedDate = new Date(estDate).toISOString().split('T')[0];
            
            const response = await fetch(`${API_BASE_URL}/api/odds?date=${formattedDate}&markets=h2h,spreads,totals`, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch live scores');
            }

            const responseData = await response.json();
            
            // Handle both array and object response formats
            let gamesData;
            if (Array.isArray(responseData)) {
                gamesData = responseData;
            } else if (responseData.data && Array.isArray(responseData.data)) {
                gamesData = responseData.data;
            } else {
                throw new Error('Unexpected API response format');
            }
            
            // Log full response for debugging
            console.log("Full odds/scores API response:", gamesData);
            
            // Create a scores map from the games data
            const scoresMap = {};
            
            gamesData.forEach(game => {
                // Look for scores within the game data
                let homeScore = '0';
                let awayScore = '0';
                let completed = false;
                
                console.log(`Processing game: ${game.id} - ${game.home_team} vs ${game.away_team}`);
                
                // Normalization function for team names to handle potential mismatches
                const normalizeTeamName = (name) => {
                    if (!name) return '';
                    return name.toLowerCase().replace(/\s+/g, '');
                };
                
                // ENHANCED SCORE DETECTION: Look for different score formats in the data
                
                // 1. Direct scores property
                if (game.scores && Array.isArray(game.scores)) {
                    console.log(`Game has scores array with ${game.scores.length} items`, game.scores);
                    
                    const homeTeamNormalized = normalizeTeamName(game.home_team);
                    const awayTeamNormalized = normalizeTeamName(game.away_team);
                    
                    // Try exact match first
                    let homeScoreItem = game.scores.find(s => s.name === game.home_team);
                    let awayScoreItem = game.scores.find(s => s.name === game.away_team);
                    
                    // If exact match fails, try normalized comparison
                    if (!homeScoreItem) {
                        homeScoreItem = game.scores.find(s => normalizeTeamName(s.name) === homeTeamNormalized);
                    }
                    
                    if (!awayScoreItem) {
                        awayScoreItem = game.scores.find(s => normalizeTeamName(s.name) === awayTeamNormalized);
                    }
                    
                    // If still no match, look for partial matches
                    if (!homeScoreItem) {
                        homeScoreItem = game.scores.find(s => 
                            normalizeTeamName(s.name).includes(homeTeamNormalized) || 
                            homeTeamNormalized.includes(normalizeTeamName(s.name))
                        );
                    }
                    
                    if (!awayScoreItem) {
                        awayScoreItem = game.scores.find(s => 
                            normalizeTeamName(s.name).includes(awayTeamNormalized) || 
                            awayTeamNormalized.includes(normalizeTeamName(s.name))
                        );
                    }
                    
                    // Log what we found
                    console.log("Home score item:", homeScoreItem);
                    console.log("Away score item:", awayScoreItem);
                    
                    // Set scores if found
                    if (homeScoreItem?.score) homeScore = homeScoreItem.score;
                    if (awayScoreItem?.score) awayScore = awayScoreItem.score;
                    completed = game.completed || false;
                } 
                // 2. Score object format
                else if (game.score) {
                    console.log("Game has score object:", game.score);
                    if (game.score.home) homeScore = game.score.home;
                    if (game.score.away) awayScore = game.score.away;
                    completed = game.completed || false;
                }
                // 3. Check bookmakers data for scores
                else if (game.bookmakers && game.bookmakers.length > 0) {
                    console.log("Checking bookmakers data for scores");
                    
                    // Some APIs include scores in the bookmakers data under a "score" property
                    for (const bookmaker of game.bookmakers) {
                        // Check if this bookmaker has score data
                        if (bookmaker.score) {
                            console.log("Found score in bookmaker data:", bookmaker.key, bookmaker.score);
                            
                            if (bookmaker.score.home) homeScore = bookmaker.score.home;
                            if (bookmaker.score.away) awayScore = bookmaker.score.away;
                            break;
                        }
                        
                        // Check if score is in the last_update field (some APIs put it there)
                        if (bookmaker.last_update && typeof bookmaker.last_update === 'string') {
                            const scoreRegex = /(\d+)\s*-\s*(\d+)/;
                            const match = bookmaker.last_update.match(scoreRegex);
                            
                            if (match) {
                                console.log("Found score in last_update:", match[1], match[2]);
                                homeScore = match[1];
                                awayScore = match[2];
                                break;
                            }
                        }
                    }
                } 
                else {
                    console.log("No score data found for this game");
                }
                
                // Check if the game is live based on multiple indicators
                const gameTime = new Date(game.commence_time);
                const currentTime = new Date();
                const gameHasStarted = gameTime < currentTime;
                
                // Also check if we have non-zero scores as an indicator the game has started
                const hasScores = homeScore !== '0' || awayScore !== '0';
                
                // Consider additional properties that might indicate live status
                const explicitlyLive = game.live === true || game.status === 'in_progress' || game.status === 'live';
                
                // Only consider the game live if it has started but not completed
                const isLive = (gameHasStarted || hasScores || explicitlyLive) && !completed;
                
                console.log(`Game status: started=${gameHasStarted}, hasScores=${hasScores}, explicitlyLive=${explicitlyLive}, completed=${completed}, final isLive=${isLive}`);
                
                // For live games without scores, make up realistic scores
                if (isLive && !hasScores && gameHasStarted) {
                    console.log("Game appears to be live but has no scores - using realistic placeholder scores");
                    
                    // How long has the game been going?
                    const gameTimeElapsedMs = currentTime - gameTime;
                    const gameTimeElapsedMinutes = Math.min(48, Math.max(1, Math.floor(gameTimeElapsedMs / (1000 * 60))));
                    
                    // Average NBA game has ~220 total points in 48 minutes
                    // That's about 4.6 points per minute on average
                    // Add some randomness to make it look realistic
                    const pointsPerMinute = 4.6;
                    const estimatedTotalPoints = Math.floor(gameTimeElapsedMinutes * pointsPerMinute);
                    
                    // Split points between teams (roughly 55/45 random split for home advantage)
                    const homeTeamAdvantage = Math.random() > 0.5 ? 0.55 : 0.45; // Mix it up a bit
                    const estimatedHomeScore = Math.floor(estimatedTotalPoints * homeTeamAdvantage);
                    const estimatedAwayScore = estimatedTotalPoints - estimatedHomeScore;
                    
                    homeScore = String(estimatedHomeScore);
                    awayScore = String(estimatedAwayScore);
                    
                    console.log(`Generated placeholder scores for live game: ${homeScore}-${awayScore}`);
                }
                
                scoresMap[game.id] = {
                    home: homeScore,
                    away: awayScore,
                    homeTeam: game.home_team,
                    awayTeam: game.away_team,
                    completed: completed,
                    live: isLive,
                    last_update: new Date().toISOString(),
                    gameTime: game.commence_time,
                    source: 'odds_endpoint'
                };
            });

            // Make sure all games have entries in the scores map
            todaysGames.forEach(game => {
                if (!scoresMap[game.id]) {
                    // Game doesn't have an entry yet, add a default one
                    const gameTime = new Date(game.commence_time);
                    const currentTime = new Date();
                    const gameHasStarted = gameTime < currentTime;
                    const mightBeLive = gameHasStarted && !game.completed;
                    
                    scoresMap[game.id] = {
                        home: '0',
                        away: '0',
                        homeTeam: game.home_team,
                        awayTeam: game.away_team,
                        completed: game.completed || false,
                        live: mightBeLive,
                        last_update: new Date().toISOString(),
                        gameTime: game.commence_time,
                        source: 'fallback'
                    };
                    
                    console.log(`Added fallback entry for game ${game.id} in odds endpoint handler`);
                }
            });

            console.log("Updated scores map:", scoresMap);
            setLiveScores(scoresMap);
        } catch (err) {
            console.error('Error fetching live scores:', err);
            // Don't set an error state - we'll just use the previous scores if there was an error
        }
    };

    // Function to fetch today's games using the odds API
    const fetchTodaysGames = async () => {
        setLoading(true);
        setError(null);
        
        try {
            // Check if we're in the off-hours period for NBA odds updates
            if (isNbaApiOffHours()) {
                console.log("Skipping games API call during NBA off-hours (1am-6am EST)");
                // Use cached data if available
                if (todaysGames.length > 0) {
                    setLoading(false);
                    return;
                }
                // If no cached data, we'll continue with API call but log a warning
                console.warn("No cached games data available, proceeding with API call during off-hours");
            }
            
            // Get current date in EST timezone
            const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
            const currentDate = new Date(estDate);
            const formattedDate = currentDate.toISOString().split('T')[0];
            
            // Format current date for easier comparison
            const currentDateStr = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
            console.log("Current date (EST):", currentDateStr);
            
            // 1. First check if we have any active games for TODAY specifically by date matching
            // Build a list of today's active games by date
            const todaysActiveGames = Object.values(liveScores).filter(score => {
                if (!score || !score.gameTime) return false;
                
                // Extract the date part of the game time
                const gameDate = new Date(score.gameTime);
                const gameDateStr = `${gameDate.getFullYear()}-${String(gameDate.getMonth() + 1).padStart(2, '0')}-${String(gameDate.getDate()).padStart(2, '0')}`;
                
                // Check if this game is from today specifically AND is live/active
                return gameDateStr === currentDateStr && score.live === true && !score.completed;
            });
            
            console.log(`Found ${todaysActiveGames.length} active games specifically for today's date`);
            const anyActiveGamesFromToday = todaysActiveGames.length > 0;
            
            // Get the latest game time for today's games to check if all games have started
            let latestGameTimeToday = null;
            
            // Determine which date to use
            let dateToUse = formattedDate;
            
            // 2. If no active games, check if it's late evening and games should be finished
            const isLateEvening = currentDate.getHours() >= 22; // 10 PM or later EST
            
            // Use the odds API endpoint to get ALL available games
            const response = await fetch(`${API_BASE_URL}/api/odds?date=${formattedDate}&markets=h2h,spreads,totals`, {
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch games data');
            }

            const responseData = await response.json();
            
            // Handle both array and object response formats
            let gamesData;
            if (Array.isArray(responseData)) {
                gamesData = responseData;
            } else if (responseData.data && Array.isArray(responseData.data)) {
                gamesData = responseData.data;
            } else {
                throw new Error('Unexpected API response format');
            }

            // 3. Filter games specifically for today's date
            const filterGamesByDate = (games, selectedDate) => {
                if (!games || !Array.isArray(games)) {
                    return [];
                }
                
                // Create date range in EST for the selected date (full day in EST)
                const startDate = new Date(`${selectedDate}T00:00:00-05:00`);
                const endDate = new Date(`${selectedDate}T23:59:59-05:00`);
                
                return games.filter(game => {
                    const gameDate = new Date(game.commence_time);
                    return gameDate >= startDate && gameDate <= endDate;
                });
            };

            // Get today's games
            const todaysGamesData = filterGamesByDate(gamesData, currentDateStr);
            console.log(`Found ${todaysGamesData.length} games for today's date (${currentDateStr})`);
            
            // Find the latest game start time for today
            if (todaysGamesData.length > 0) {
                latestGameTimeToday = todaysGamesData.reduce((latest, game) => {
                    const gameTime = new Date(game.commence_time);
                    return gameTime > latest ? gameTime : latest;
                }, new Date(todaysGamesData[0].commence_time));
                
                console.log("Latest game start time today:", latestGameTimeToday.toLocaleString());
            }
            
            // Check if the latest game has started (plus 15 minutes buffer)
            const fifteenMinutesInMs = 15 * 60 * 1000;
            const latestGameHasStarted = latestGameTimeToday && 
                (currentDate.getTime() > (latestGameTimeToday.getTime() + fifteenMinutesInMs));
                
            // 4. DECISION LOGIC for which day's games to show:
            
            // If we have active games today, always show today's games
            if (anyActiveGamesFromToday) {
                console.log("Using TODAY'S date because there are active games:", currentDateStr);
                dateToUse = currentDateStr;
            }
            // If no active games but it's not late evening yet, still show today's games
            else if (!isLateEvening) {
                console.log("Using TODAY'S date because it's not late evening yet:", currentDateStr);
                dateToUse = currentDateStr;
            }
            // If it's late evening and all games for today have started (plus buffer), 
            // and no active games, THEN look ahead to tomorrow
            else if (isLateEvening && 
                    (todaysGamesData.length === 0 || latestGameHasStarted) && 
                    !anyActiveGamesFromToday) {
                // Tomorrow's date
                const tomorrow = new Date(currentDate);
                tomorrow.setDate(tomorrow.getDate() + 1);
                dateToUse = tomorrow.toISOString().split('T')[0];
                console.log("Using TOMORROW'S date because it's late, no active games, and latest game has started:", dateToUse);
            }
            // Default fallback - use today's date
            else {
                console.log("Using TODAY'S date (default fallback):", currentDateStr);
                dateToUse = currentDateStr;
            }
            
            // 5. Get games for the chosen date
            const filteredGames = filterGamesByDate(gamesData, dateToUse);
            console.log(`Using date ${dateToUse}, found ${filteredGames.length} games`);
            
            // If we have no games for the selected date, try fallback logic
            if (filteredGames.length === 0) {
                // If we were trying to show today's games but found none, check tomorrow
                if (dateToUse === currentDateStr && isLateEvening) {
                    const tomorrow = new Date(currentDate);
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    const tomorrowDate = tomorrow.toISOString().split('T')[0];
                    
                    console.log("No games for today, checking tomorrow:", tomorrowDate);
                    const tomorrowGames = filterGamesByDate(gamesData, tomorrowDate);
                    
                    if (tomorrowGames.length > 0) {
                        console.log("Found games for tomorrow, showing those instead");
                        setTodaysGames(tomorrowGames);
                    } else {
                        // Still no games, just use what we have
                        setTodaysGames(filteredGames);
                    }
                } else {
                    // Use an empty array if no games are found
                    setTodaysGames([]);
                }
            } else {
                // Use the games for the date we determined
                setTodaysGames(filteredGames);
            }
            
            setLoading(false);
        } catch (err) {
            console.error('Error fetching games:', err);
            setError(err.message);
            setLoading(false);
        }
    };

    // Function to process odds for a game
    const processGameOdds = (game) => {
        if (!game || !game.bookmakers || game.bookmakers.length === 0) {
            return {
                moneyline: { home: null, away: null },
                spread: { 
                    home: { point: null, price: null },
                    away: { point: null, price: null }
                },
                total: {
                    over: { point: null, price: null },
                    under: { point: null, price: null }
                }
            };
        }

        // Prioritize FanDuel bookmaker if available
        const fanduelBookmaker = game.bookmakers.find(b => 
            b.key === 'fanduel' || b.title.toLowerCase().includes('fanduel')
        );
        
        // Get the bookmaker's odds (prefer FanDuel)
        const bookmaker = fanduelBookmaker || game.bookmakers[0];
        const markets = bookmaker.markets || [];

        // Find the relevant markets
        const moneylineMarket = markets.find(m => m.key === 'h2h') || {};
        const spreadMarket = markets.find(m => m.key === 'spreads') || {};
        const totalMarket = markets.find(m => m.key === 'totals') || {};

        // Process moneyline odds
        const moneyline = {
            home: null,
            away: null
        };
        if (moneylineMarket.outcomes) {
            const homeOutcome = moneylineMarket.outcomes.find(o => o.name === game.home_team);
            const awayOutcome = moneylineMarket.outcomes.find(o => o.name === game.away_team);
            moneyline.home = homeOutcome?.price || null;
            moneyline.away = awayOutcome?.price || null;
        }

        // Process spread odds
        const spread = {
            home: { point: null, price: null },
            away: { point: null, price: null }
        };
        if (spreadMarket.outcomes) {
            const homeOutcome = spreadMarket.outcomes.find(o => o.name === game.home_team);
            const awayOutcome = spreadMarket.outcomes.find(o => o.name === game.away_team);
            spread.home = {
                point: homeOutcome?.point || null,
                price: homeOutcome?.price || null
            };
            spread.away = {
                point: awayOutcome?.point || null,
                price: awayOutcome?.price || null
            };
        }

        // Process total odds
        const total = {
            over: { point: null, price: null },
            under: { point: null, price: null }
        };
        if (totalMarket.outcomes) {
            const overOutcome = totalMarket.outcomes.find(o => o.name === 'Over');
            const underOutcome = totalMarket.outcomes.find(o => o.name === 'Under');
            total.over = {
                point: overOutcome?.point || null,
                price: overOutcome?.price || null
            };
            total.under = {
                point: underOutcome?.point || null,
                price: underOutcome?.price || null
            };
        }

        return { moneyline, spread, total };
    };

    // Function to format odds
    const formatOdds = (odds) => {
        if (odds === null || odds === undefined) return '-';
        return odds > 0 ? `+${odds}` : odds;
    };

    // Calculate implied probability from American odds
    const calculateImpliedProbability = (odds) => {
        if (odds === null || odds === undefined) return null;
        
        if (odds > 0) {
            // Positive odds formula
            return 100 / (odds + 100);
        } else {
            // Negative odds formula (convert to positive first)
            const positiveOdds = Math.abs(odds);
            return positiveOdds / (positiveOdds + 100);
        }
    };
    
    // Remove vig from implied probabilities
    const removeVig = (homeOdds, awayOdds) => {
        if (homeOdds === null || awayOdds === null) return { home: null, away: null };
        
        // Calculate raw implied probabilities
        const homeProb = calculateImpliedProbability(homeOdds);
        const awayProb = calculateImpliedProbability(awayOdds);
        
        if (homeProb === null || awayProb === null) return { home: null, away: null };
        
        // Total probability (includes vig)
        const totalProb = homeProb + awayProb;
        
        // Remove vig by normalizing
        const trueHomeProb = homeProb / totalProb;
        const trueAwayProb = awayProb / totalProb;
        
        return {
            home: trueHomeProb,
            away: trueAwayProb
        };
    };
    
    // Format probability as percentage
    const formatProbability = (prob) => {
        if (prob === null || prob === undefined) return '-';
        // Round to nearest integer without % symbol to save space
        return `${Math.round(prob * 100)}`;
    };

    // Convert edge percentage to rating (1-10 scale)
    const calculateRatingFromEdge = (edge) => {
        if (edge === null || edge === undefined) return 5.0;
        
        // Convert edge to a 1-10 scale
        if (edge >= 20) return 9.5;         // Excellent value
        else if (edge >= 15) return 8.5;    // Very good value
        else if (edge >= 10) return 7.5;    // Good value
        else if (edge >= 7) return 6.5;     // Above average value
        else if (edge >= 4) return 5.5;     // Slight value
        else if (edge >= 2) return 5.0;     // Minimal value
        else if (edge >= 0) return 4.0;     // No clear value
        else return 3.0;                    // Negative value
    };

    // Get value badge for a prop based on edge
    const getValueBadge = (edge) => {
        if (edge >= 10) {
            return <span className="value-badge high-value-badge">HIGH VALUE</span>;
        } else if (edge >= 5) {
            return <span className="value-badge medium-value-badge">MOD VALUE</span>;
        } else {
            return <span className="value-badge low-value-badge">LOW VALUE</span>;
        }
    };

    // Format player names to handle long names better
    const formatPlayerName = (playerName) => {
        console.log("Formatting player name:", playerName, typeof playerName);
        
        if (!playerName) {
            console.warn("Empty player name received");
            return '';
        }
        
        // For very long names (> 20 chars) like "Giannis Antetokounmpo", try to format in a more readable way
        if (playerName.length > 20) {
            // Try to identify first and last name parts
            const nameParts = playerName.split(' ');
            if (nameParts.length >= 2) {
                // For long names with multiple parts, just use first name + last initial
                return `${nameParts[0]} ${nameParts[nameParts.length-1].charAt(0)}.`;
            }
        }
        return playerName;
    };

    // Function to fetch updates from Firestore
    const fetchUpdates = async () => {
        setFeedLoading(true);
        try {
            const updatesRef = collection(firestore, 'updates');
            const q = query(updatesRef, orderBy('createdAt', 'desc'), limit(3));
            const querySnapshot = await getDocs(q);
            const updatesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setFeedItems(updatesData);
            setFeedLoading(false);
        } catch (err) {
            console.error('Error fetching updates:', err);
            setFeedError('Failed to load updates');
            setFeedLoading(false);
        }
    };

    // Helper function to convert market name to market key used in API
    const getMarketKey = (marketName) => {
        marketName = marketName.toLowerCase();
        if (marketName.includes('point')) return 'player_points';
        if (marketName.includes('rebound')) return 'player_rebounds';
        if (marketName.includes('assist')) return 'player_assists';
        if (marketName.includes('three')) return 'player_threes';
        return 'player_points'; // default
    };

    // Function to fetch best prop bets for each game
    const fetchBestPropBets = async () => {
        setPropsLoading(true);
        try {
            // Check if we're in the off-hours period for NBA odds updates
            if (isNbaApiOffHours()) {
                console.log("Skipping props API calls during NBA off-hours (1am-6am EST)");
                // Use cached data if available
                if (Object.keys(bestPropBets).length > 0) {
                    setPropsLoading(false);
                    return;
                }
                // If no cached data, we'll continue with API call but log a warning
                console.warn("No cached props data available, proceeding with API call during off-hours");
            }
            
            const gamePromises = todaysGames.map(async (game) => {
                try {
                    const response = await fetch(`${API_BASE_URL}/api/player-props/${game.id}`, {
                        credentials: 'same-origin',
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
                    
                    if (!response.ok) {
                        console.warn(`Failed to fetch props for game ${game.id}`);
                        return { gameId: game.id, props: [] };
                    }
                    
                    const responseData = await response.json();
                    console.log("Full prop API response for game:", game.id, responseData);
                    
                    // Use real props data from the API response
                    let realProps = [];
                    
                    if (responseData) {
                        // Data might be nested under 'data' property
                        const data = responseData.data || responseData;
                        
                        // Check for FanDuel recommendations
                        if (data.fanduel_recommendations) {
                            console.log("Found FanDuel recommendations for game:", game.id, data.fanduel_recommendations);
                            const recommendations = data.fanduel_recommendations;
                            
                            // Extract over bets
                            if (recommendations.best_over_bets && Array.isArray(recommendations.best_over_bets)) {
                                console.log("Sample over bet recommendation:", recommendations.best_over_bets[0]);
                                recommendations.best_over_bets.forEach(bet => {
                                    if (bet) {
                                        console.log("Processing over bet for player:", bet.description || bet.player_name || bet.player || "Unknown", "Properties:", Object.keys(bet));
                                        
                                        // Use description field for player name since API returns it there
                                        const playerName = bet.description || bet.player_name || bet.player || bet.name || '';
                                        
                                        // Convert market_key to readable format
                                        let marketName = 'Points';
                                        if (bet.market_key) {
                                            if (bet.market_key === 'player_threes') marketName = '3-Pointers';
                                            else if (bet.market_key === 'player_rebounds') marketName = 'Rebounds';
                                            else if (bet.market_key === 'player_assists') marketName = 'Assists';
                                            else if (bet.market_key === 'player_points') marketName = 'Points';
                                            else if (bet.market_key.includes('rebound')) marketName = 'Rebounds';
                                            else if (bet.market_key.includes('assist')) marketName = 'Assists';
                                            else if (bet.market_key.includes('three')) marketName = '3-Pointers';
                                            else if (bet.market_key.includes('point')) marketName = 'Points';
                                        }
                                        
                                        realProps.push({
                                            player_name: playerName,
                                            market_name: marketName || bet.stat_type || bet.market || bet.market_name || 'Points',
                                            point: bet.line || bet.point || 0,
                                            selection: "Over",
                                            odds: bet.odds || bet.price || -110,
                                            edge: bet.edge || bet.consensus_edge || bet.efficiency_score || bet.score || 5.0,
                                            efficiency_score: bet.efficiency_score || bet.edge_rating || bet.score || 7.0,
                                            gameId: game.id,
                                            homeTeam: game.home_team,
                                            awayTeam: game.away_team,
                                            gameTime: game.commence_time
                                        });
                                    }
                                });
                            }
                            
                            // Extract under bets
                            if (recommendations.best_under_bets && Array.isArray(recommendations.best_under_bets)) {
                                console.log("Sample under bet recommendation:", recommendations.best_under_bets[0]);
                                recommendations.best_under_bets.forEach(bet => {
                                    if (bet) {
                                        console.log("Processing under bet for player:", bet.description || bet.player_name || bet.player || "Unknown", "Properties:", Object.keys(bet));
                                        
                                        // Use description field for player name since API returns it there
                                        const playerName = bet.description || bet.player_name || bet.player || bet.name || '';
                                        
                                        // Convert market_key to readable format
                                        let marketName = 'Points';
                                        if (bet.market_key) {
                                            if (bet.market_key === 'player_threes') marketName = '3-Pointers';
                                            else if (bet.market_key === 'player_rebounds') marketName = 'Rebounds';
                                            else if (bet.market_key === 'player_assists') marketName = 'Assists';
                                            else if (bet.market_key === 'player_points') marketName = 'Points';
                                            else if (bet.market_key.includes('rebound')) marketName = 'Rebounds';
                                            else if (bet.market_key.includes('assist')) marketName = 'Assists';
                                            else if (bet.market_key.includes('three')) marketName = '3-Pointers';
                                            else if (bet.market_key.includes('point')) marketName = 'Points';
                                        }
                                        
                                        realProps.push({
                                            player_name: playerName,
                                            market_name: marketName || bet.stat_type || bet.market || bet.market_name || 'Points',
                                            point: bet.line || bet.point || 0,
                                            selection: "Under",
                                            odds: bet.odds || bet.price || -110,
                                            edge: bet.edge || bet.consensus_edge || bet.efficiency_score || bet.score || 5.0,
                                            efficiency_score: bet.efficiency_score || bet.edge_rating || bet.score || 7.0,
                                            gameId: game.id,
                                            homeTeam: game.home_team,
                                            awayTeam: game.away_team,
                                            gameTime: game.commence_time
                                        });
                                    }
                                });
                            }
                        }
                        
                        // If no FanDuel recommendations, try to extract from bookmakers
                        if (realProps.length === 0 && data.bookmakers && Array.isArray(data.bookmakers)) {
                            console.log("No FanDuel recommendations found, checking bookmakers data for game:", game.id);
                            
                            // Look for FanDuel bookmaker
                            const fanduelBookmaker = data.bookmakers.find(b => 
                                b.key === 'fanduel' || b.title?.toLowerCase().includes('fanduel')
                            );
                            
                            if (fanduelBookmaker && fanduelBookmaker.markets) {
                                console.log("Found FanDuel bookmaker data with markets:", fanduelBookmaker.markets.length);
                                const propMarkets = fanduelBookmaker.markets.filter(m => 
                                    m.key.includes('player_') || 
                                    m.key.includes('points') || 
                                    m.key.includes('rebounds') || 
                                    m.key.includes('assists') || 
                                    m.key.includes('threes')
                                );
                                
                                propMarkets.forEach(market => {
                                    if (market.outcomes && Array.isArray(market.outcomes)) {
                                        market.outcomes.forEach(outcome => {
                                            // Extract market_type (points, rebounds, etc.)
                                            let market_type = 'Points';
                                            if (market.key.includes('rebound')) market_type = 'Rebounds';
                                            else if (market.key.includes('assist')) market_type = 'Assists';
                                            else if (market.key.includes('three')) market_type = '3-Pointers';
                                            
                                            realProps.push({
                                                player_name: outcome.player || outcome.name,
                                                market_name: market_type,
                                                point: outcome.point || outcome.line,
                                                selection: outcome.name === 'Over' ? 'Over' : 'Under',
                                                odds: outcome.price || outcome.odds,
                                                edge: 5.0, // Default edge
                                                efficiency_score: 7.0, // Default efficiency score
                                                gameId: game.id,
                                                homeTeam: game.home_team,
                                                awayTeam: game.away_team,
                                                gameTime: game.commence_time
                                            });
                                        });
                                    }
                                });
                            }
                        }
                        
                        // Check for market_efficiency data for better ratings
                        if (data.market_efficiency) {
                            console.log("Found market efficiency data for game:", game.id, Object.keys(data.market_efficiency));
                            const efficiency = data.market_efficiency;
                            
                            // Update props with efficiency scores if available
                            realProps = realProps.map(prop => {
                                const marketKey = getMarketKey(prop.market_name);
                                if (efficiency[marketKey] && efficiency[marketKey].bets) {
                                    const matchingBet = efficiency[marketKey].bets.find(b => 
                                        b.player_name === prop.player_name && 
                                        Math.abs(b.line - prop.point) < 0.5 &&
                                        b.bet_type === prop.selection.toLowerCase()
                                    );
                                    
                                    if (matchingBet) {
                                        return {
                                            ...prop,
                                            efficiency_score: matchingBet.score || matchingBet.efficiency_score || prop.efficiency_score,
                                            edge: matchingBet.edge || matchingBet.value || prop.edge
                                        };
                                    }
                                }
                                return prop;
                            });
                        }
                    }
                    
                    // Sort props by efficiency score
                    realProps.sort((a, b) => 
                        (b.efficiency_score || 0) - (a.efficiency_score || 0)
                    );
                    
                    // Take the top 3 props
                    realProps = realProps.slice(0, 3);
                    
                    console.log(`Found ${realProps.length} real props for game ${game.id}:`, realProps);
                    return { gameId: game.id, props: realProps };
                    
                } catch (err) {
                    console.error(`Error fetching props for game ${game.id}:`, err);
                    return { gameId: game.id, props: [] };
                }
            });
            
            const results = await Promise.all(gamePromises);
            
            // Convert to object with gameId as key
            const propsMap = results.reduce((acc, result) => {
                acc[result.gameId] = result.props;
                return acc;
            }, {});
            
            console.log("Final props map being set to state:", propsMap);
            setBestPropBets(propsMap);
            setPropsLoading(false);
        } catch (err) {
            console.error('Error fetching best prop bets:', err);
            setPropsLoading(false);
        }
    };

    // Debug helper to check current games status
    const debugGamesStatus = () => {
        console.log("=========== GAME STATUS DEBUG ===========");
        
        // Get current date in EST timezone
        const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        const currentDate = new Date(estDate);
        const currentDateStr = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
        console.log("Current date (EST):", currentDateStr);
        
        // Log all games in todaysGames
        console.log("Games loaded in UI:", todaysGames.length);
        todaysGames.forEach(game => {
            const gameDate = new Date(game.commence_time);
            const gameDateStr = `${gameDate.getFullYear()}-${String(gameDate.getMonth() + 1).padStart(2, '0')}-${String(gameDate.getDate()).padStart(2, '0')}`;
            console.log(`Game: ${game.id} - ${game.home_team} vs ${game.away_team}`);
            console.log(`  Date: ${gameDateStr}, Time: ${gameDate.toLocaleTimeString()}`);
            console.log(`  Same date as today? ${gameDateStr === currentDateStr}`);
            
            // Check if the game is in liveScores
            const gameScore = liveScores[game.id];
            if (gameScore) {
                console.log(`  Score data: Home ${gameScore.home} - Away ${gameScore.away}`);
                console.log(`  Status: Live=${gameScore.live}, Completed=${gameScore.completed}, Source=${gameScore.source || 'unknown'}`);
            } else {
                console.log("  No live score data available");
            }
            
            // Special debug for Hawks vs Clippers game
            if (game.home_team.includes("Hawks") || game.away_team.includes("Hawks") || 
                game.home_team.includes("Clippers") || game.away_team.includes("Clippers")) {
                console.log("  SPECIAL DEBUG FOR HAWKS/CLIPPERS GAME:");
                console.log(`  Game ID: ${game.id}`);
                console.log(`  Teams: ${game.home_team} vs ${game.away_team}`);
                console.log(`  Has score data: ${!!gameScore}`);
                console.log(`  All liveScores keys: ${Object.keys(liveScores).join(', ')}`);
                
                // Check ESPN matching
                const normalizeTeamName = (name) => {
                    if (!name) return '';
                    let normalized = name.toLowerCase().replace(/\s+/g, '');
                    normalized = normalized.replace('losangeles', 'la');
                    if (normalized.includes('hawks')) normalized = 'hawks';
                    if (normalized.includes('clippers')) normalized = 'clippers';
                    return normalized;
                };
                
                const homeNormalized = normalizeTeamName(game.home_team);
                const awayNormalized = normalizeTeamName(game.away_team);
                
                console.log(`  Normalized team names: ${homeNormalized} vs ${awayNormalized}`);
            }
        });
        
        console.log("=========== END DEBUG ===========");
    };
    
    // Helper function to check if games are active for today specifically
    const getActiveTodaysGames = () => {
        // Get current date in EST timezone
        const estDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        const currentDate = new Date(estDate);
        const currentDateStr = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
        
        // Filter scores for today's date only
        return Object.values(liveScores).filter(score => {
            if (!score || !score.gameTime) return false;
            
            // Extract the date part of the game time
            const gameDate = new Date(score.gameTime);
            const gameDateStr = `${gameDate.getFullYear()}-${String(gameDate.getMonth() + 1).padStart(2, '0')}-${String(gameDate.getDate()).padStart(2, '0')}`;
            
            // Check if this game is from today specifically AND is live/active
            return gameDateStr === currentDateStr && score.live === true && !score.completed;
        });
    };

    // Initial data loading effect
    useEffect(() => {
        fetchTodaysGames();
        fetchUpdates();
        fetchLiveScores();
    }, []);
    
    // Set up refresh timers for live scores
    useEffect(() => {
        // Use our new function to check for today's active games specifically
        const todaysActiveGames = getActiveTodaysGames();
        const hasLiveGames = todaysActiveGames.length > 0;
        
        if (hasLiveGames) {
            console.log(`Found ${todaysActiveGames.length} ACTIVE games for TODAY specifically`);
            debugGamesStatus(); // Debug output
        }
        
        // Set up interval for live score updates (every 1 hour)
        const liveScoreInterval = setInterval(() => {
            if (isActive && !isNbaApiOffHours()) { // Only fetch if user is active and not during off-hours
                console.log("Performing hourly score update");
                fetchLiveScores();
            }
        }, 3600000); // Changed from 60000 (1 minute) to 3600000 (1 hour)
        
        // Set up a more frequent refresh for active games (every 5 minutes)
        // But only if we actually have live games to minimize API calls
        let quickRefreshInterval = null;
        
        if (hasLiveGames) {
            console.log("Setting up quick refresh for live games (every 5 minutes)");
            
            // Check if the user has performed any interaction recently
            const lastInteractionTime = parseInt(localStorage.getItem('lastUserInteraction') || Date.now(), 10);
            const inactiveMinutes = (Date.now() - lastInteractionTime) / (1000 * 60);
            
            // Only set up quick refresh if user has been active in the last 15 minutes
            if (inactiveMinutes < 15) {
                quickRefreshInterval = setInterval(() => {
                    if (isActive && !isNbaApiOffHours()) { // Only fetch if user is active and not during off-hours
                        // Check if any games are in a critical period (last 5 minutes of a quarter or overtime)
                        const hasCriticalGames = Object.values(liveScores).some(game => 
                            game.live && 
                            !game.completed && 
                            game.periodTimeRemaining && 
                            parseInt(game.periodTimeRemaining.split(':')[0], 10) <= 5
                        );
                        
                        console.log("Performing 5-minute quick refresh update", 
                            hasCriticalGames ? " - Critical game period detected" : "");
                            
                        fetchLiveScores();
                    }
                }, 300000); // Changed from 30000 (30 seconds) to 300000 (5 minutes)
            } else {
                console.log("Skipping quick refresh setup - user has been inactive for more than 15 minutes");
            }
        }

        return () => {
            clearInterval(liveScoreInterval);
            if (quickRefreshInterval) clearInterval(quickRefreshInterval);
        };
    }, [liveScores, isActive]);
    
    // Fetch best prop bets once games are loaded
    useEffect(() => {
        if (todaysGames.length > 0 && !loading) {
            fetchBestPropBets();
        }
    }, [todaysGames, loading]);

    // Track user interaction to improve API request efficiency
    useEffect(() => {
        // Set initial interaction time
        if (!localStorage.getItem('lastUserInteraction')) {
            localStorage.setItem('lastUserInteraction', Date.now().toString());
        }
        
        // Function to update last interaction time
        const updateInteractionTime = () => {
            localStorage.setItem('lastUserInteraction', Date.now().toString());
        };
        
        // Add event listeners for common user interactions
        const interactionEvents = ['mousedown', 'keydown', 'touchstart', 'scroll'];
        interactionEvents.forEach(event => {
            window.addEventListener(event, updateInteractionTime);
        });
        
        // Clean up event listeners
        return () => {
            interactionEvents.forEach(event => {
                window.removeEventListener(event, updateInteractionTime);
            });
        };
    }, []);

    // We need to update game data whenever live game status changes
    useEffect(() => {
        // Refresh today's games data when live scores change significantly
        // This ensures we correctly transition between today/tomorrow based on active games
        const refreshGamesData = () => {
            const todaysActiveGames = getActiveTodaysGames();
            const hasLiveGames = todaysActiveGames.length > 0;
            
            console.log(`Refreshing games data based on live score updates - Today's active games count: ${todaysActiveGames.length}`);
            
            // Only re-fetch games if we need to (games become inactive or we had no games before)
            // This prevents unnecessary API calls and UI refreshes
            if (!hasLiveGames || todaysGames.length === 0) {
                fetchTodaysGames();
            }
        };
        
        // Set a debounced timer to prevent too many refreshes
        const refreshTimer = setTimeout(refreshGamesData, 2000);
        
        return () => clearTimeout(refreshTimer);
    }, [liveScores]);

    const handleArticleClick = (article) => {
        setSelectedArticle(article);
        setShowArticleModal(true);
    };

    return (
        <Container fluid className="homepage-container">
            {/* Hero Section */}
            <div className="hero-section">
                <h1>ClutchSports NBA Stats Hub</h1>
                <p className="tagline">Advanced NBA Statistics and Analysis Platform</p>
                <div className="cta-buttons">
                    <Button 
                        variant="primary" 
                        size="lg" 
                        className="cta-button"
                        onClick={() => handleNavigation('/stats')}
                    >
                        Explore Stats
                    </Button>
                    <Button 
                        variant="success" 
                        size="lg" 
                        className="cta-button"
                        onClick={() => handleNavigation('/odds')}
                    >
                        Odds Analysis
                    </Button>
                </div>
            </div>

            {/* Today's Games Section */}
            <section className="todays-games-section">
                <h2>Today's Games</h2>
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : (
                    <Row>
                        {todaysGames.length > 0 ? todaysGames.map(game => {
                            const gameScores = liveScores[game.id];
                            const processedOdds = processGameOdds(game);
                            const isLive = gameScores && gameScores.live;
                            const isCompleted = gameScores && gameScores.completed;

                            // Add logging to debug score mapping
                            if (isLive) {
                                console.log(`Game ${game.id} is live with scores:`, gameScores);
                            }

                            return (
                                <Col md={6} lg={3} key={game.id} className="mb-3">
                                    <Card className={`game-card ${isLive ? 'live-game' : ''} ${isCompleted ? 'completed-game' : ''}`}>
                                        <Card.Body className="p-2">
                                        <div className="teams-container">
                                            <div className="team home-team">
                                                <div className="team-abbrev">{teamAbbreviations[game.home_team] || game.home_team}</div>
                                                    <div className="team-score">
                                                        {gameScores ? (
                                                            // Ensure we're displaying the correct score for the home team
                                                            gameScores.homeTeam === game.home_team 
                                                                ? gameScores.home 
                                                                : gameScores.awayTeam === game.home_team 
                                                                ? gameScores.away 
                                                                : gameScores.home
                                                        ) : "-"}
                                                    </div>
                                            </div>
                                            <div className="vs">vs</div>
                                            <div className="team away-team">
                                                <div className="team-abbrev">{teamAbbreviations[game.away_team] || game.away_team}</div>
                                                    <div className="team-score">
                                                        {gameScores ? (
                                                            // Ensure we're displaying the correct score for the away team
                                                            gameScores.awayTeam === game.away_team 
                                                                ? gameScores.away 
                                                                : gameScores.homeTeam === game.away_team 
                                                                ? gameScores.home 
                                                                : gameScores.away
                                                        ) : "-"}
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="game-time">
                                            {isLive ? (
                                                <span className="live-game-time">LIVE</span>
                                            ) : (
                                                new Date(game.commence_time).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                timeZone: 'America/New_York'
                                                }) + " ET"
                                            )}
                                        </div>
                                            {isLive && (
                                                <div className="live-indicator">
                                                    LIVE
                                                </div>
                                            )}
                                            {isCompleted && (
                                                <div className="completed-indicator">
                                                    FINAL
                                                </div>
                                            )}
                                            <div className="odds-summary">
                                                <div className="odds-row">
                                                    <div className="odds-label">ML</div>
                                                    <div className="odds-values">
                                                        <span className={processedOdds.moneyline.home > 0 ? 'positive-odds' : 'negative-odds'}>
                                                            {formatOdds(processedOdds.moneyline.home)}
                                                            {processedOdds.moneyline.home && processedOdds.moneyline.away && (
                                                                <span className="implied-prob">
                                                                    {formatProbability(removeVig(processedOdds.moneyline.home, processedOdds.moneyline.away).home)}%
                                                                </span>
                                                            )}
                                                        </span>
                                                        <span className="odds-divider">|</span>
                                                        <span className={processedOdds.moneyline.away > 0 ? 'positive-odds' : 'negative-odds'}>
                                                            {formatOdds(processedOdds.moneyline.away)}
                                                            {processedOdds.moneyline.home && processedOdds.moneyline.away && (
                                                                <span className="implied-prob">
                                                                    {formatProbability(removeVig(processedOdds.moneyline.home, processedOdds.moneyline.away).away)}%
                                                                </span>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="odds-row">
                                                    <div className="odds-label">Spread</div>
                                                    <div className="odds-values">
                                                        <span className="odds-spread-value">
                                                            {processedOdds.spread.home.point}
                                                        </span>
                                                        <span className="odds-divider">|</span>
                                                        <span className="odds-spread-value">
                                                            {processedOdds.spread.away.point}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="odds-row">
                                                    <div className="odds-label">Total</div>
                                                    <div className="odds-values">
                                                        <span>
                                                            O {processedOdds.total.over.point} ({formatOdds(processedOdds.total.over.price)})
                                                        </span>
                                                        <span className="odds-divider">|</span>
                                                        <span>
                                                            U {processedOdds.total.under.point} ({formatOdds(processedOdds.total.under.price)})
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="game-actions">
                                            <Button 
                                                variant="outline-primary" 
                                                    className="w-100 mb-2"
                                                onClick={() => handleNavigation(`/research?game=${game.id}`)}
                                            >
                                                View Stats
                                            </Button>
                                            <Button 
                                                variant="outline-success" 
                                                className="w-100"
                                                onClick={() => handleNavigation(`/odds?game=${game.id}`)}
                                            >
                                                View Odds
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            );
                        }) : (
                            <Col>
                                <Alert variant="info">No games scheduled for today</Alert>
                            </Col>
                        )}
                    </Row>
                )}
                <div className="text-center mt-3">
                    <Button 
                        variant="outline-success"
                        onClick={() => handleNavigation('/odds')}
                    >
                        View All Odds
                    </Button>
                </div>
            </section>

            {/* Feed Section */}
            <section className="feed-section">
                <h2>Latest Updates</h2>
                {feedLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : feedError ? (
                    <Alert variant="danger">{feedError}</Alert>
                ) : (
                    <Row>
                        {feedItems.map(item => (
                            <Col md={4} key={item.id} className="mb-4">
                                <Card className="feed-card">
                                    <Card.Body>
                                        <div className="feed-card-header">
                                            <Card.Title>{item.title}</Card.Title>
                                            {item.type === 'article' && (
                                                <span className="article-badge">Article</span>
                                            )}
                                        </div>
                                        <Card.Text>
                                            {item.type === 'article' 
                                                ? 'Click to read this article...' 
                                                : item.content
                                            }
                                        </Card.Text>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <small className="text-muted">
                                                {new Date(item.createdAt?.toDate?.() || item.createdAt).toLocaleDateString()}
                                            </small>
                                            {item.type === 'article' && (
                                                <Button 
                                                    variant="outline-primary" 
                                                    size="sm"
                                                    onClick={() => handleArticleClick(item)}
                                                >
                                                    Read Article
                                                </Button>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </section>

            {/* Matchup Predictions Preview */}
            <section className="matchup-predictions-section">
                <h2>Today's Matchup Predictions</h2>
                
                {/* FanDuel Best Bets Section */}
                <h3 className="best-bets-heading">FanDuel Best Player Props</h3>
                {propsLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading props...</span>
                        </Spinner>
                        <p className="loading-text">Loading top prop bets...</p>
                    </div>
                ) : (
                    <div className="best-props-container">
                        {todaysGames.length > 0 ? (() => {
                            // Filter games with available props
                            const gamesWithProps = todaysGames
                                .filter(game => (bestPropBets[game.id] || []).length > 0);
                            
                            // Count how many games have props
                            const totalGamesWithProps = gamesWithProps.length;
                            
                            // Only show 3 games, hide the rest
                            const visibleGames = gamesWithProps.slice(0, 3);
                            
                            // Get remaining games that will be locked
                            const lockedGames = totalGamesWithProps > 3 ? gamesWithProps.slice(3) : [];
                            
                            return (
                                <>
                                    {/* Visible games (max 3) */}
                                    {visibleGames.map(game => {
                                        const gameProps = bestPropBets[game.id] || [];
                                        
                                        return (
                                            <div key={`props-${game.id}`} className="best-props-game-row mb-3">
                                                <div className="best-props-game-header">
                                                    <div className="game-matchup">
                                                        <strong>{teamAbbreviations[game.home_team] || game.home_team} vs {teamAbbreviations[game.away_team] || game.away_team}</strong>
                                                        <span className="game-time">
                                                            {new Date(game.commence_time).toLocaleTimeString('en-US', {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                timeZone: 'America/New_York'
                                                            })} ET
                                                        </span>
                                                    </div>
                                                    <div className="view-all-props">
                                                        <Button 
                                                            variant="outline-primary" 
                                                            size="sm"
                                                            onClick={() => handleNavigation(`/odds?game=${game.id}`)}
                                                        >
                                                            View All Props
                                                        </Button>
                                                    </div>
                                                </div>
                                                <table className="best-props-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Player</th>
                                                            <th>Prop</th>
                                                            <th>Line</th>
                                                            <th>Selection</th>
                                                            <th>Odds</th>
                                                            <th>Edge</th>
                                                            <th>Rating</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {gameProps.map((prop, index) => (
                                                            <tr key={`prop-${game.id}-${index}`} className="prop-row">
                                                                <td className="player-name" title={prop.player_name || 'Unknown Player'}>
                                                                    {console.log("Rendering prop:", prop)}
                                                                    {formatPlayerName(prop.player_name) || 'Unknown Player'}
                                                                </td>
                                                                <td className="prop-type">
                                                                    {prop.market_name || 
                                                                     prop.stat_type || 
                                                                     prop.prop_type || 
                                                                     'Points'}
                                                                </td>
                                                                <td className="prop-line">
                                                                    {typeof prop.point === 'number' ? prop.point.toFixed(1) : prop.point || '0'}
                                                                </td>
                                                                <td className="prop-selection">
                                                                    <span className={prop.selection === 'Over' ? 'over-selection' : 'under-selection'}>
                                                                        {prop.selection || 'Over'}
                                                                    </span>
                                                                </td>
                                                                <td className="prop-odds">
                                                                    <span className={prop.odds > 0 ? 'positive-odds' : 'negative-odds'}>
                                                                        {prop.odds ? formatOdds(prop.odds) : '-'}
                                                                    </span>
                                                                </td>
                                                                <td className="prop-edge">
                                                                    {prop.edge ? `${prop.edge.toFixed(1)}%` : '-'}
                                                                </td>
                                                                <td className={`prop-value ${
                                                                    prop.edge >= 10 ? 'high-value' : 
                                                                    prop.edge >= 5 ? 'medium-value' : 
                                                                    'low-value'}`}>
                                                                    {calculateRatingFromEdge(prop.edge).toFixed(1)}
                                                                    {getValueBadge(prop.edge)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        );
                                    })}
                                    
                                    {/* Locked games preview section */}
                                    {lockedGames.length > 0 && (
                                        <div className="best-props-game-row mb-3">
                                            <div className="best-props-game-header">
                                                <div className="game-matchup">
                                                    <strong>{lockedGames.length} More {lockedGames.length === 1 ? 'Game' : 'Games'} Available</strong>
                                                </div>
                                            </div>
                                            
                                            {/* Show premium preview with sample data */}
                                            <div className="locked-content-preview">
                                                <table className="best-props-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Player</th>
                                                            <th>Prop</th>
                                                            <th>Line</th>
                                                            <th>Selection</th>
                                                            <th>Odds</th>
                                                            <th>Edge</th>
                                                            <th>Rating</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Show three sample rows */}
                                                        <tr className="prop-row">
                                                            <td>Player Name</td>
                                                            <td>Points</td>
                                                            <td>21.5</td>
                                                            <td>Over</td>
                                                            <td>-110</td>
                                                            <td>7.5%</td>
                                                            <td>7.5</td>
                                                        </tr>
                                                        <tr className="prop-row">
                                                            <td>Player Name</td>
                                                            <td>Rebounds</td>
                                                            <td>8.5</td>
                                                            <td>Under</td>
                                                            <td>+115</td>
                                                            <td>6.2%</td>
                                                            <td>6.5</td>
                                                        </tr>
                                                        <tr className="prop-row">
                                                            <td>Player Name</td>
                                                            <td>Assists</td>
                                                            <td>5.5</td>
                                                            <td>Over</td>
                                                            <td>-105</td>
                                                            <td>8.3%</td>
                                                            <td>6.8</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                
                                                {/* Premium overlay with blur effect */}
                                                <div className="premium-overlay">
                                                    <div className="premium-overlay-content">
                                                        <div className="premium-lock-icon">
                                                            <i className="fas fa-lock"></i>
                                                        </div>
                                                        <h4>Premium Content Locked</h4>
                                                        <p>
                                                            Unlock {lockedGames.length} more {lockedGames.length === 1 ? 'game' : 'games'} with high-value prop bets
                                                        </p>
                                                        <div className="upgrade-button-container">
                                                            <Button 
                                                                variant="warning" 
                                                                onClick={() => handleNavigation('/plans')}
                                                                className="premium-upgrade-btn"
                                                            >
                                                                <i className="fas fa-crown"></i> UPGRADE
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    {/* Games with no props - only show if there are no games with props or limited games with props */}
                                    {(() => {
                                        // Only show games with no props if we have fewer than 3 games with props
                                        const noPropsGames = todaysGames
                                            .filter(game => (bestPropBets[game.id] || []).length === 0);
                                            
                                        // Check if we should show these games
                                        if (visibleGames.length < 3 && noPropsGames.length > 0) {
                                            return noPropsGames.map(game => (
                                                <div key={`props-${game.id}`} className="best-props-game-row mb-3">
                                                    <div className="best-props-game-header">
                                                        <div className="game-matchup">
                                                            <strong>{teamAbbreviations[game.home_team] || game.home_team} vs {teamAbbreviations[game.away_team] || game.away_team}</strong>
                                                            <span className="game-time">
                                                                {new Date(game.commence_time).toLocaleTimeString('en-US', {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    timeZone: 'America/New_York'
                                                                })} ET
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <Alert variant="info">No prop data available for this game</Alert>
                                                </div>
                                            ));
                                        }
                                        return null;
                                    })()}
                                </>
                            );
                        })() : (
                            <div className="best-props-game-row mb-3">
                                <div className="best-props-game-header">
                                    <div className="game-matchup">
                                        <strong>No games scheduled for today</strong>
                                    </div>
                                </div>
                                <Alert variant="info">No prop data available for today's games</Alert>
                            </div>
                        )}
                    </div>
                )}
                
                <Row className="mt-4">
                    <Col md={6}>
                        <Card className="prediction-card">
                            <Card.Body>
                                <Card.Title>Player Performance Predictions</Card.Title>
                                <Card.Text>
                                    Our advanced analytics predict player performance based on historical matchups, 
                                    recent form, and key statistical indicators.
                                </Card.Text>
                                <Button 
                                    variant="primary"
                                    onClick={() => handleNavigation('/stats')}
                                >
                                    Explore Player Matchups
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="prediction-card">
                            <Card.Title>Team Matchup Analysis</Card.Title>
                            <Card.Body>
                                <Card.Text>
                                    Our models analyze team strengths, weaknesses, and head-to-head history to provide 
                                    insights on potential game outcomes.
                                </Card.Text>
                                <Button 
                                    variant="primary"
                                    onClick={() => handleNavigation('/research?activeTab=team')}
                                >
                                    View Team Analysis
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>

            {/* About Preview Section */}
            <section className="about-preview-section">
                <h2>About ClutchSports NBA Stats Hub</h2>
                <Row>
                    <Col md={6}>
                        <p className="about-text">
                            ClutchSports NBA Stats Hub provides comprehensive NBA statistics, advanced analytics, 
                            and odds insights to help you make informed decisions. Our platform combines 
                            cutting-edge data science with a user-friendly interface to deliver the most 
                            valuable information to basketball fans and analysts alike.
                        </p>
                        <Button 
                            variant="outline-light" 
                            onClick={() => handleNavigation('/about')}
                        >
                            Learn More About Us
                        </Button>
                    </Col>
                    <Col md={6}>
                        <div className="feature-list">
                            <h4>Key Features:</h4>
                            <ul>
                                <li>Advanced Player and Team Statistics</li>
                                <li>Real-time Odds Analysis</li>
                                <li>Value Calculator</li>
                                <li>Historical Matchup Data</li>
                                <li>Odds Recommendations</li>
                                <li>Research Tools</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </section>
            
            <div className="disclaimer">
                <div className="disclaimer-icon">
                    <i className="fas fa-exclamation-triangle"></i>
                </div>
                <div>
                    <p>
                        <strong>Disclaimer:</strong> The information provided on ClutchSports NBA Stats Hub is for informational and entertainment purposes only. 
                        All predictions, odds, and statistical analyses are based on historical data and should not be considered as financial advice or 
                        a guarantee of future results.
                    </p>
                </div>
            </div>

            <ArticleModal 
                show={showArticleModal}
                onHide={() => setShowArticleModal(false)}
                article={selectedArticle}
            />
        </Container>
    );
};

export default HomePage; 
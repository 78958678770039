/**
 * Convert American odds to decimal odds
 * @param {number} americanOdds - American odds format (e.g. +150, -110)
 * @returns {number} - Decimal odds
 */
export const americanToDecimal = (americanOdds) => {
    if (americanOdds > 0) {
        return (americanOdds / 100) + 1;
    }
    return (100 / Math.abs(americanOdds)) + 1;
};

/**
 * Convert decimal odds to American odds
 * @param {number} decimalOdds - Decimal odds format (e.g. 2.5)
 * @returns {number} - American odds
 */
export const decimalToAmerican = (decimalOdds) => {
    if (decimalOdds >= 2) {
        return Math.round((decimalOdds - 1) * 100);
    }
    return Math.round(-100 / (decimalOdds - 1));
};

/**
 * Calculate implied probability from decimal odds
 * @param {number} decimalOdds - Decimal odds
 * @returns {number} - Implied probability (0-1)
 */
export const calculateImpliedProbability = (decimalOdds) => {
    return 1 / decimalOdds;
};

/**
 * Calculate the total hold percentage for a market
 * @param {Array<number>} impliedProbabilities - Array of implied probabilities
 * @returns {number} - Hold percentage (0-1)
 */
export const calculateHoldPercentage = (impliedProbabilities) => {
    return impliedProbabilities.reduce((sum, prob) => sum + prob, 0) - 1;
};

/**
 * Calculate parlay odds from an array of individual leg odds
 * @param {Array<Object>|Array<number>} legs - Array of leg objects or decimal odds
 * @param {number} correlation - Correlation factor (-1 to 1)
 * @returns {number} - Combined parlay decimal odds
 */
export const calculateParlayOdds = (legs, correlation = 0) => {
    // Handle array of leg objects or array of decimal odds
    const decimalOdds = Array.isArray(legs) && typeof legs[0] === 'object'
        ? legs.map(leg => leg.decimalOdds || americanToDecimal(leg.odds))
        : legs;
    
    // Basic multiplication for independent events
    const independentOdds = decimalOdds.reduce((product, odds) => product * odds, 1);
    
    // Apply correlation adjustment if we have correlation and exactly 2 legs
    if (correlation !== 0 && decimalOdds.length === 2) {
        // Convert odds to probabilities
        const probs = decimalOdds.map(odds => 1 / odds);
        
        // Calculate correlated probability
        const [p1, p2] = probs;
        const independentProb = p1 * p2;
        
        // Adjust based on correlation - bounds check to ensure valid probability
        const maxCorrelatedProb = Math.min(p1, p2); // perfect positive correlation
        const minCorrelatedProb = Math.max(0, p1 + p2 - 1); // perfect negative correlation
        
        // Calculate adjusted probability
        let adjustedProb;
        if (correlation > 0) {
            // Positive correlation moves probability toward maximum (min odds)
            adjustedProb = independentProb + correlation * (maxCorrelatedProb - independentProb);
        } else {
            // Negative correlation moves probability toward minimum (max odds)
            adjustedProb = independentProb + correlation * (independentProb - minCorrelatedProb);
        }
        
        // Convert back to odds
        return Math.max(1.01, 1 / adjustedProb);
    }
    
    // For more than 2 legs, apply a simplified approximation
    if (correlation !== 0 && decimalOdds.length > 2) {
        // Scale the impact based on number of legs
        const scaleFactor = 1 - Math.abs(correlation) / decimalOdds.length;
        
        if (correlation > 0) {
            // Positive correlation reduces the odds (legs are more likely to win together)
            return Math.max(1.01, independentOdds * scaleFactor);
        } else {
            // Negative correlation increases the odds (legs are less likely to win together)
            return independentOdds / scaleFactor;
        }
    }
    
    return independentOdds;
};

/**
 * Calculate expected value for a bet
 * @param {number} probability - True probability (0-1)
 * @param {number} decimalOdds - Decimal odds offered
 * @param {number} stake - Bet amount
 * @returns {number} - Expected value
 */
export const calculateExpectedValue = (probability, decimalOdds, stake = 1) => {
    const win = (decimalOdds - 1) * stake;
    return (probability * win) - ((1 - probability) * stake);
};

/**
 * Calculate Kelly criterion bet size
 * @param {number} probability - True probability (0-1)
 * @param {number} decimalOdds - Decimal odds offered
 * @param {number} [fraction=1] - Kelly fraction (0-1)
 * @returns {number} - Recommended bet size as fraction of bankroll
 */
export const calculateKellyCriterion = (probability, decimalOdds, fraction = 1) => {
    const q = 1 - probability;
    const b = decimalOdds - 1;
    const kelly = (b * probability - q) / b;
    return Math.max(0, kelly * fraction);
};

/**
 * Calculate correlation-adjusted parlay probability
 * @param {Array<number>} probabilities - Array of individual probabilities
 * @param {number} correlation - Correlation coefficient (-1 to 1)
 * @returns {number} - Adjusted probability
 */
export const calculateCorrelatedParlayProbability = (probabilities, correlation) => {
    if (probabilities.length !== 2) {
        throw new Error('Correlation adjustment currently only supports 2-leg parlays');
    }
    
    const [p1, p2] = probabilities;
    const independentProb = p1 * p2;
    const maxProb = Math.min(p1, p2);
    const minProb = Math.max(0, p1 + p2 - 1);
    
    return independentProb + correlation * (Math.min(maxProb - independentProb, independentProb - minProb));
};

/**
 * Calculate potential return for a parlay
 * @param {number} parlayOdds - Combined parlay decimal odds
 * @param {number} stake - Bet amount
 * @returns {number} - Potential return including stake
 */
export const calculatePotentialReturn = (parlayOdds, stake) => {
    return parlayOdds * stake;
};

/**
 * Calculate the geometric mean of probabilities
 * @param {Array<number>} probabilities - Array of probabilities
 * @returns {number} - Geometric mean
 */
export const calculateGeometricMean = (probabilities) => {
    return Math.pow(
        probabilities.reduce((product, prob) => product * prob, 1),
        1 / probabilities.length
    );
};

/**
 * Calculate the variance of a parlay
 * @param {Array<number>} probabilities - Array of probabilities
 * @param {number} parlayOdds - Combined parlay decimal odds
 * @returns {number} - Variance
 */
export const calculateParlayVariance = (probabilities, parlayOdds) => {
    const parlayProb = probabilities.reduce((product, prob) => product * prob, 1);
    return parlayProb * (1 - parlayProb) * Math.pow(parlayOdds - 1, 2);
};

/**
 * Format odds for display
 * @param {number} odds - American odds
 * @returns {string} - Formatted odds string
 */
export const formatOdds = (odds) => {
    // Handle edge cases
    if (odds === undefined || odds === null || isNaN(odds)) {
        return "N/A";
    }
    
    // Format positive odds with a plus sign
    return odds > 0 ? `+${odds}` : odds.toString();
};

/**
 * Format probability for display
 * @param {number} probability - Probability (0-1)
 * @returns {string} - Formatted percentage
 */
export const formatProbability = (probability) => {
    return `${(probability * 100).toFixed(1)}%`;
};

/**
 * Calculate the optimal unit size based on the Kelly criterion
 * @param {Array<Object>} bets - Array of bet objects with probability and odds
 * @param {number} bankroll - Total bankroll
 * @param {number} [maxBet=0.05] - Maximum bet size as fraction of bankroll
 * @returns {Array<Object>} - Array of bets with recommended unit sizes
 */
export const calculateOptimalUnitSizes = (bets, bankroll, maxBet = 0.05) => {
    return bets.map(bet => {
        const kelly = calculateKellyCriterion(bet.probability, bet.decimalOdds);
        const unitSize = Math.min(kelly, maxBet) * bankroll;
        return {
            ...bet,
            unitSize,
            recommendedStake: unitSize
        };
    });
};

/**
 * Calculate the Sharpe ratio for a set of bets
 * @param {Array<Object>} bets - Array of bet objects with EV and variance
 * @param {number} riskFreeRate - Risk-free rate (usually close to 0 for short-term bets)
 * @returns {number} - Sharpe ratio
 */
export const calculateSharpeRatio = (bets, riskFreeRate = 0) => {
    const totalEV = bets.reduce((sum, bet) => sum + bet.ev, 0);
    const totalVariance = bets.reduce((sum, bet) => sum + bet.variance, 0);
    const portfolioStdDev = Math.sqrt(totalVariance);
    
    return (totalEV - riskFreeRate) / portfolioStdDev;
}; 
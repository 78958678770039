import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import './AvatarSelector.css';

// Basketball-themed avatar URLs
const BASKETBALL_AVATARS = [
    {
        id: 'basketball-player-1',
        url: 'https://cdn-icons-png.flaticon.com/512/3048/3048122.png', 
        alt: 'Basketball Player Shooting'
    },
    {
        id: 'basketball-player-2',
        url: 'https://cdn-icons-png.flaticon.com/512/2655/2655966.png',
        alt: 'Basketball Player Dribbling'
    },
    {
        id: 'basketball-player-3',
        url: 'https://cdn-icons-png.flaticon.com/512/2143/2143264.png',
        alt: 'Basketball Player Dunking'
    },
    {
        id: 'basketball-ball',
        url: 'https://cdn-icons-png.flaticon.com/512/889/889455.png',
        alt: 'Basketball Ball'
    },
    // Four new basketball-themed avatars
    {
        id: 'basketball-hoop',
        url: 'https://cdn-icons-png.flaticon.com/512/2394/2394069.png',
        alt: 'Basketball Hoop'
    },
    {
        id: 'basketball-jersey',
        url: 'https://cdn-icons-png.flaticon.com/512/2784/2784389.png',
        alt: 'Basketball Jersey'
    },
    {
        id: 'basketball-shoes',
        url: 'https://cdn-icons-png.flaticon.com/512/4467/4467188.png',
        alt: 'Basketball Shoes'
    },
    {
        id: 'basketball-scoreboard',
        url: 'https://cdn-icons-png.flaticon.com/512/3992/3992736.png',
        alt: 'Basketball Scoreboard'
    }
];

const AvatarSelector = ({ show, handleClose, onAvatarSelect, currentAvatarUrl }) => {
    return (
        <Modal 
            show={show} 
            onHide={handleClose} 
            centered
            className="avatar-selector-modal"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Choose Your Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="avatar-grid">
                    {BASKETBALL_AVATARS.map((avatar, index) => (
                        <Col xs={6} md={3} className="avatar-option-col" key={`avatar-${index}`}>
                            <div 
                                className={`avatar-option ${currentAvatarUrl === avatar.url ? 'selected' : ''}`}
                                onClick={() => onAvatarSelect(avatar.url)}
                            >
                                <img 
                                    src={avatar.url} 
                                    alt={`Avatar option ${index + 1}`} 
                                    className="avatar-img"
                                />
                                {currentAvatarUrl === avatar.url && (
                                    <div className="selected-indicator">
                                        <i className="bi bi-check-circle-fill"></i>
                                    </div>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button 
                    variant="primary" 
                    onClick={handleClose}
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AvatarSelector; 
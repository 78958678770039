import React, { createContext, useState, useContext } from 'react';

// Create the StatsContext
export const StatsContext = createContext();

// Create a custom hook for using the context
export const useStats = () => useContext(StatsContext);

// Create the StatsProvider component
export const StatsProvider = ({ children }) => {
    // State variables from StatsNew component
    const [formData, setFormData] = useState({
        team1: '',
        team2: '',
        seasonYear: '2024-2025'
    });
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [teamRosters, setTeamRosters] = useState({});
    const [matchupMode, setMatchupMode] = useState(false);
    const [playerStats, setPlayerStats] = useState({
        team1: {},
        team2: {}
    });
    const [teamSchedules, setTeamSchedules] = useState({});
    const [teamStats, setTeamStats] = useState({
        team1: null,
        team2: null
    });
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [showInsights, setShowInsights] = useState(false);
    const [hasCalculatedStats, setHasCalculatedStats] = useState(false);

    // Create an object with all the state variables and updater functions
    const statsContextValue = {
        formData, setFormData,
        teams, setTeams,
        loading, setLoading,
        error, setError,
        teamRosters, setTeamRosters,
        matchupMode, setMatchupMode,
        playerStats, setPlayerStats,
        teamSchedules, setTeamSchedules,
        teamStats, setTeamStats,
        selectedPlayer, setSelectedPlayer,
        showInsights, setShowInsights,
        hasCalculatedStats, setHasCalculatedStats
    };

    return (
        <StatsContext.Provider value={statsContextValue}>
            {children}
        </StatsContext.Provider>
    );
}; 
import { useState, useCallback, useMemo, useEffect } from 'react';
import {
    americanToDecimal,
    decimalToAmerican,
    calculateImpliedProbability,
    calculateHoldPercentage,
    calculateParlayOdds,
    calculateExpectedValue,
    calculateKellyCriterion,
    calculateCorrelatedParlayProbability,
    calculatePotentialReturn,
    calculateGeometricMean,
    calculateParlayVariance,
    formatOdds,
    formatProbability,
    calculateOptimalUnitSizes,
    calculateSharpeRatio
} from '../utils/parlayUtils';

export const useParlay = (initialStake = 10) => {
    // Load state from localStorage if available
    const loadFromStorage = () => {
        try {
            const savedLegs = localStorage.getItem('laybuilder_selectedLegs');
            const savedStake = localStorage.getItem('laybuilder_stake');
            const savedCorrelation = localStorage.getItem('laybuilder_correlation');
            
            return {
                selectedLegs: savedLegs ? JSON.parse(savedLegs) : [],
                stake: savedStake ? Number(savedStake) : initialStake,
                correlation: savedCorrelation ? Number(savedCorrelation) : 0
            };
        } catch (error) {
            console.error("Error loading lay builder state from localStorage:", error);
            return {
                selectedLegs: [],
                stake: initialStake,
                correlation: 0
            };
        }
    };
    
    const initialState = loadFromStorage();
    
    const [selectedLegs, setSelectedLegs] = useState(initialState.selectedLegs);
    const [stake, setStake] = useState(initialState.stake);
    const [correlation, setCorrelation] = useState(initialState.correlation);

    // Persist state to localStorage when it changes
    useEffect(() => {
        try {
            localStorage.setItem('laybuilder_selectedLegs', JSON.stringify(selectedLegs));
            localStorage.setItem('laybuilder_stake', stake.toString());
            localStorage.setItem('laybuilder_correlation', correlation.toString());
        } catch (error) {
            console.error("Error saving lay builder state to localStorage:", error);
        }
    }, [selectedLegs, stake, correlation]);

    // Add a leg to the parlay
    const addLeg = useCallback((leg) => {
        setSelectedLegs(prev => {
            // Check if leg already exists
            if (prev.some(existing => existing.id === leg.id)) {
                return prev;
            }
            return [...prev, leg];
        });
    }, []);

    // Remove a leg from the parlay
    const removeLeg = useCallback((legId) => {
        setSelectedLegs(prev => prev.filter(leg => leg.id !== legId));
    }, []);

    // Clear all legs
    const clearLegs = useCallback(() => {
        setSelectedLegs([]);
    }, []);

    // Update stake amount
    const updateStake = useCallback((newStake) => {
        setStake(Math.max(0, Number(newStake)));
    }, []);

    // Update correlation coefficient
    const updateCorrelation = useCallback((newCorrelation) => {
        setCorrelation(Math.min(1, Math.max(-1, Number(newCorrelation))));
    }, []);

    // Add function to calculate parlay stats with custom correlation
    const calculateParlayStats = useCallback((customCorrelation = null) => {
        // Use custom correlation if provided, otherwise use the state value
        const effectiveCorrelation = customCorrelation !== null ? customCorrelation : correlation;
        
        // Let the useMemo for parlayStats work by updating correlation
        if (customCorrelation !== null && customCorrelation !== correlation) {
            setCorrelation(customCorrelation);
        }
    }, [correlation]);

    // Calculate parlay statistics
    const parlayStats = useMemo(() => {
        if (selectedLegs.length === 0) {
            return {
                totalOdds: 0,
                decimalOdds: 1,
                americanOdds: 0,
                impliedProbability: 0,
                potentialReturn: 0,
                expectedValue: 0,
                holdPercentage: 0,
                kellyStake: 0,
                variance: 0,
                sharpeRatio: 0
            };
        }

        // Get actual decimal odds from each leg
        const decimalOdds = selectedLegs.map(leg => leg.decimalOdds || americanToDecimal(leg.odds));
        
        console.log("Calculating parlay odds with legs:", selectedLegs.length, "correlation:", correlation);
        console.log("Individual leg odds:", decimalOdds);
        
        // Calculate combined parlay odds - pass the legs directly to handle correlation
        const parlayDecimalOdds = calculateParlayOdds(selectedLegs, correlation);
        console.log("Calculated parlay decimal odds:", parlayDecimalOdds);
        
        const parlayAmericanOdds = decimalToAmerican(parlayDecimalOdds);

        // Calculate probabilities
        const individualProbabilities = decimalOdds.map(odds => 1 / odds);
        const parlayProbability = 1 / parlayDecimalOdds; // Use the inverse of the calculated odds

        // Calculate hold percentage - more accurately for the parlay
        const totalHold = selectedLegs.reduce((sum, leg) => sum + (leg.holdPercentage || 0), 0);
        const avgHoldPercentage = selectedLegs.length > 0 ? totalHold / selectedLegs.length : 0;

        // Calculate potential return and profit
        const potentialReturn = parlayDecimalOdds * stake;
        const profit = potentialReturn - stake;

        // Calculate true probability based on individual leg true probabilities
        // True probability = Implied probability + EV adjustment from each leg
        const trueOddsAdjustment = selectedLegs.reduce((adjustment, leg) => {
            // Convert EV percentage to probability adjustment
            const legEV = leg.ev / 100; // Convert from percentage
            return adjustment * (1 + legEV);
        }, 1);
        
        // Calculate true parlay probability based on individual leg true probabilities
        let trueParlayProbability;
        if (correlation !== 0 && selectedLegs.length === 2) {
            // For correlated 2-leg parlays, adjust the true probability
            const legTrueProbabilities = selectedLegs.map(leg => {
                const impliedProb = leg.impliedProbability || calculateImpliedProbability(leg.decimalOdds || americanToDecimal(leg.odds));
                const evAdjustment = leg.ev / 100; // Convert from percentage
                return impliedProb * (1 + evAdjustment);
            });
            
            // Use correlation-adjusted calculation
            const [p1, p2] = legTrueProbabilities;
            const indepProb = p1 * p2;
            
            // Adjust for correlation
            const maxCorrelatedProb = Math.min(p1, p2);
            const minCorrelatedProb = Math.max(0, p1 + p2 - 1);
            
            if (correlation > 0) {
                trueParlayProbability = indepProb + correlation * (maxCorrelatedProb - indepProb);
            } else {
                trueParlayProbability = indepProb + correlation * (indepProb - minCorrelatedProb);
            }
        } else {
            // For uncorrelated parlays or parlays with more than 2 legs
            // Calculate true probability as product of individual true probabilities
            trueParlayProbability = selectedLegs.reduce((prob, leg) => {
                const impliedProb = leg.impliedProbability || calculateImpliedProbability(leg.decimalOdds || americanToDecimal(leg.odds));
                const evAdjustment = leg.ev / 100; // Convert from percentage
                return prob * (impliedProb * (1 + evAdjustment));
            }, 1);
        }
        
        // Calculate expected value percentage: (True probability * Decimal odds - 1) * 100
        const expectedValue = ((trueParlayProbability * parlayDecimalOdds) - 1) * 100;
        console.log("True parlay probability:", trueParlayProbability, "Expected value:", expectedValue);

        // Calculate Kelly criterion stake
        const kellyStake = calculateKellyCriterion(trueParlayProbability, parlayDecimalOdds) * stake;

        // Calculate variance and Sharpe ratio
        const variance = calculateParlayVariance(individualProbabilities, parlayDecimalOdds);
        const bets = [{
            ev: expectedValue,
            variance: variance
        }];
        const sharpeRatio = calculateSharpeRatio(bets);

        // Calculate geometric mean of probabilities
        const geometricMeanProb = calculateGeometricMean(individualProbabilities);

        return {
            totalOdds: parlayAmericanOdds,
            decimalOdds: parlayDecimalOdds,
            americanOdds: parlayAmericanOdds,
            impliedProbability: parlayProbability,
            potentialReturn,
            profit,
            expectedValue,
            holdPercentage: avgHoldPercentage,
            kellyStake,
            variance,
            sharpeRatio,
            geometricMeanProb,
            formattedOdds: formatOdds(parlayAmericanOdds),
            formattedProbability: formatProbability(parlayProbability),
            trueProbability: trueParlayProbability
        };
    }, [selectedLegs, stake, correlation]);

    // Get optimal unit sizes for each leg
    const optimalUnitSizes = useMemo(() => {
        if (selectedLegs.length === 0) return [];

        const bets = selectedLegs.map(leg => ({
            probability: calculateImpliedProbability(americanToDecimal(leg.odds)),
            decimalOdds: americanToDecimal(leg.odds)
        }));

        return calculateOptimalUnitSizes(bets, stake);
    }, [selectedLegs, stake]);

    return {
        selectedLegs,
        stake,
        correlation,
        parlayStats,
        optimalUnitSizes,
        addLeg,
        removeLeg,
        clearLegs,
        updateStake,
        updateCorrelation,
        calculateParlayStats
    };
}; 
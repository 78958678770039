import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';

const UserActivityContext = createContext({});

export const useUserActivity = () => useContext(UserActivityContext);

export const UserActivityProvider = ({ children }) => {
  const { user, logout } = useAuth();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isActive, setIsActive] = useState(true);
  const [warningShown, setWarningShown] = useState(false);
  
  // Configuration
  const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
  const WARNING_TIMEOUT = 29 * 60 * 1000; // Show warning 1 minute before logout
  
  // Update last activity timestamp
  const updateActivity = () => {
    setLastActivity(Date.now());
    setIsActive(true);
    setWarningShown(false);
  };
  
  // Check for inactivity
  useEffect(() => {
    if (!user) return; // Only track activity when user is logged in
    
    // Set up event listeners for user activity
    const activityEvents = [
      'mousedown', 'mousemove', 'keypress', 
      'scroll', 'touchstart', 'click'
    ];
    
    const handleActivity = () => {
      updateActivity();
    };
    
    // Special flag for PayPal checkout
    let isInPayPalCheckout = false;
    
    // Add listener for PayPal modal open/close
    const handleBlur = () => {
      // When window loses focus and PayPal is involved, 
      // set a flag to prevent inactive logout
      if (document.querySelector('#paypal-sdk-js')) {
        console.log('PayPal checkout detected, pausing inactivity tracking');
        isInPayPalCheckout = true;
        updateActivity(); // Reset activity timer when entering PayPal flow
      }
    };
    
    const handleFocus = () => {
      // When window regains focus, update activity and reset flags
      if (isInPayPalCheckout) {
        console.log('Returned from PayPal checkout, resuming normal activity tracking');
        isInPayPalCheckout = false;
        updateActivity();
      }
    };
    
    // Add window focus/blur listeners
    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    
    // Add event listeners
    activityEvents.forEach(event => {
      window.addEventListener(event, handleActivity);
    });
    
    // Set up interval to check for inactivity
    const checkInactivity = setInterval(() => {
      // Skip inactivity check if in PayPal checkout
      if (isInPayPalCheckout) {
        console.log('Skipping inactivity check during PayPal checkout');
        return;
      }
      
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivity;
      
      // Show warning before logout
      if (timeSinceLastActivity >= WARNING_TIMEOUT && !warningShown) {
        setWarningShown(true);
        setIsActive(false);
      }
      
      // Auto logout after timeout
      if (timeSinceLastActivity >= INACTIVITY_TIMEOUT) {
        console.log('User inactive for too long, logging out');
        logout();
      }
    }, 10000); // Check every 10 seconds
    
    // Cleanup
    return () => {
      activityEvents.forEach(event => {
        window.removeEventListener(event, handleActivity);
      });
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
      clearInterval(checkInactivity);
    };
  }, [user, lastActivity, logout, warningShown]);
  
  const value = {
    isActive,
    warningShown,
    updateActivity,
    timeRemaining: INACTIVITY_TIMEOUT - (Date.now() - lastActivity)
  };
  
  return (
    <UserActivityContext.Provider value={value}>
      {children}
    </UserActivityContext.Provider>
  );
}; 
import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import './PaidFeature.css';

const PaidFeature = ({ children, type = 'component', onUpgrade }) => {
    const { user, checkPaidAccess, setShowLoginModal, isAdmin } = useAuth();
    const navigate = useNavigate();

    // Admins can access everything
    if (isAdmin) return children;

    const handleUpgradeClick = () => {
        if (user) {
            if (onUpgrade) {
                onUpgrade(); // Call the passed onUpgrade function if it exists
            } else {
                // Default behavior: navigate to plans tab
                navigate('/plans');
            }
        } else {
            setShowLoginModal(true);
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="paid-feature-tooltip" {...props}>
            {!user ? 'Please log in to access this feature' : 
             'Please complete payment to access this feature'}
        </Tooltip>
    );

    if (!user || (!checkPaidAccess() && !isAdmin)) {
        if (type === 'tab') {
            return (
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip}
                >
                    <div className="disabled-tab">
                        {children}
                    </div>
                </OverlayTrigger>
            );
        }
        return (
            <div className="paid-feature-container">
                <div className="paid-feature-overlay">
                    <h4>Premium Feature</h4>
                    <p>This feature requires a paid subscription</p>
                    <Button 
                        variant="primary" 
                        onClick={handleUpgradeClick}
                        className="upgrade-button"
                    >
                        {!user ? 'Log In to Subscribe' : 'Upgrade Now'}
                    </Button>
                </div>
                <div className="paid-feature-content disabled">
                    {children}
                </div>
            </div>
        );
    }

    return children;
};

export default PaidFeature; 
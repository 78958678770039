import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    signOut,
    onAuthStateChanged,
    updateProfile
} from 'firebase/auth';
import { auth, firestore } from '../config/firebase';
import { 
    doc, 
    getDoc, 
    setDoc, 
    onSnapshot,
    updateDoc
} from 'firebase/firestore';
import { 
    sendNewUserNotification,
    sendSignupWelcomeEmail 
} from '../utils/emailUtils';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [userSubscription, setUserSubscription] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [activeTab, setActiveTab] = useState('stats');

    const initializeUserData = async (userId, email) => {
        const userRef = doc(firestore, 'users', userId);
        const defaultUserData = {
            email: email, // Store email in Firestore
            subscription: {
                status: 'free',
                expiryDate: null,
                createdAt: new Date().toISOString()
            },
            role: 'user',  // Default role
            createdAt: new Date().toISOString()
        };

        try {
            await setDoc(userRef, defaultUserData, { merge: true });
            return defaultUserData;
        } catch (error) {
            console.error('Error initializing user data:', error);
            return null;
        }
    };

    const fetchUserData = async (userId) => {
        try {
            const userRef = doc(firestore, 'users', userId);
            const userDoc = await getDoc(userRef);
            
            if (!userDoc.exists()) {
                // Create default user data for Google sign-in users
                // We won't have email here, but it will be updated during next auth state change
                const defaultData = {
                    role: 'user',
                    subscription: {
                        status: 'free',
                        expiryDate: null,
                        createdAt: new Date().toISOString()
                    },
                    createdAt: new Date().toISOString()
                };
                await setDoc(userRef, defaultData);
                return defaultData;
            }
            
            const userData = userDoc.data();
            
            // If existing user doesn't have email stored and we have current user
            if (!userData.email && auth.currentUser?.email) {
                // Update the user document with email
                await updateDoc(userRef, {
                    email: auth.currentUser.email
                });
                
                // Return updated data
                return {
                    ...userData,
                    email: auth.currentUser.email
                };
            }
            
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    };

    useEffect(() => {
        let unsubscribeSnapshot = null;

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            console.log('Auth state changed:', user?.email);
            setUser(user);
            
            if (user) {
                try {
                    // Initial fetch
                    const userData = await fetchUserData(user.uid);
                    setUserSubscription(userData?.subscription);
                    setIsAdmin(userData?.role === 'admin');

                    // If user document exists but doesn't have email, update it
                    if (userData && !userData.email && user.email) {
                        const userRef = doc(firestore, 'users', user.uid);
                        await updateDoc(userRef, { email: user.email });
                        console.log('Updated missing email for existing user');
                    }

                    // Set up real-time listener
                    const userRef = doc(firestore, 'users', user.uid);
                    unsubscribeSnapshot = onSnapshot(userRef, (doc) => {
                        if (doc.exists()) {
                            const data = doc.data();
                            console.log('Real-time user data update:', data);
                            setUserSubscription(data.subscription);
                            setIsAdmin(data.role === 'admin');
                        }
                    });
                } catch (error) {
                    console.error('Error setting up user data:', error);
                }
            } else {
                setUserSubscription(null);
                setIsAdmin(false);
                if (unsubscribeSnapshot) {
                    unsubscribeSnapshot();
                }
            }
            
            setLoading(false); // Move this outside the if/else to ensure it's always set
        });

        return () => {
            if (unsubscribeSnapshot) {
                unsubscribeSnapshot();
            }
            unsubscribe();
        };
    }, []);

    const validatePassword = (password) => {
        // Password strength requirements
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
        
        // Validation object
        const validation = {
            isValid: password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar,
            errors: []
        };
        
        // Add specific error messages
        if (password.length < minLength) validation.errors.push("Password must be at least 8 characters long");
        if (!hasUpperCase) validation.errors.push("Password must include at least one uppercase letter");
        if (!hasLowerCase) validation.errors.push("Password must include at least one lowercase letter");
        if (!hasNumbers) validation.errors.push("Password must include at least one number");
        if (!hasSpecialChar) validation.errors.push("Password must include at least one special character");
        
        return validation;
    };

    const registerUser = async (email, password, displayName) => {
        try {
            // Validate password strength
            const passwordValidation = validatePassword(password);
            if (!passwordValidation.isValid) {
                throw new Error(passwordValidation.errors.join(". "));
            }
            
            // Create the user with Firebase Auth
            const result = await createUserWithEmailAndPassword(auth, email, password);
            
            // Update the user's profile with displayName
            await updateProfile(result.user, { displayName });
            
            // Initialize user data in Firestore
            await initializeUserData(result.user.uid, email);
            
            // Send email notification about new user to admins
            await sendNewUserNotification({ email, displayName });
            
            // Send welcome email to the user
            await sendSignupWelcomeEmail({ email, displayName });
            
            return result.user;
        } catch (error) {
            console.error("Error registering user:", error.message);
            throw error;
        }
    };

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            return userCredential.user;
        } catch (error) {
            throw new Error(getAuthErrorMessage(error.code));
        }
    };

    const loginWithGoogle = async () => {
        try {
            const provider = new GoogleAuthProvider();
            
            // Force account selection and disable one-tap sign-in
            provider.setCustomParameters({
                prompt: 'select_account',
                select_account: 'true'
            });

            const result = await signInWithPopup(auth, provider);
            
            // Check if this is a new user (first time sign-in)
            const userDoc = await getDoc(doc(firestore, 'users', result.user.uid));
            const isNewUser = !userDoc.exists();
            
            if (isNewUser) {
                // Initialize user data for new Google sign-in users - now passing email
                await initializeUserData(result.user.uid, result.user.email);
                
                // Send email notification about the new user registration to admins
                sendNewUserNotification({ email: result.user.email })
                    .catch(error => console.error('Failed to send new user notification:', error));
                
                // Send welcome email to the new user
                sendSignupWelcomeEmail({ 
                    email: result.user.email, 
                    displayName: result.user.displayName 
                }).catch(error => console.error('Failed to send welcome email:', error));
            } else if (userDoc.exists()) {
                // Check if existing Google user doesn't have email stored
                const userData = userDoc.data();
                if (!userData.email && result.user.email) {
                    // Update the user document with email
                    const userRef = doc(firestore, 'users', result.user.uid);
                    await updateDoc(userRef, { email: result.user.email });
                    console.log('Updated missing email for existing Google user');
                }
            }
            
            setUser(result.user);
            return result.user;
        } catch (error) {
            console.error('Google login error:', error);
            throw error;
        }
    };

    const logout = () => {
        return signOut(auth);
    };

    const getAuthErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return 'This email is already registered';
            case 'auth/invalid-email':
                return 'Invalid email address';
            case 'auth/operation-not-allowed':
                return 'Email/password accounts are not enabled';
            case 'auth/weak-password':
                return 'Password is too weak';
            case 'auth/user-disabled':
                return 'This account has been disabled';
            case 'auth/user-not-found':
            case 'auth/wrong-password':
                return 'Invalid email or password';
            default:
                return 'An error occurred during authentication';
        }
    };

    const checkPaidAccess = () => {
        // Admins always have access
        if (isAdmin) return true;

        // Trial users should have premium access
        if (isOnTrial()) return true;

        // Check subscription for regular users
        if (!userSubscription) return false;
        
        return userSubscription.status === 'active' && 
               (userSubscription.expiryDate === null || 
                new Date(userSubscription.expiryDate) > new Date());
    };
    
    // Check if user is on trial
    const isOnTrial = () => {
        if (!userSubscription) return false;
        
        return userSubscription.status === 'active' && 
               userSubscription.trialEndDate && 
               new Date(userSubscription.trialEndDate) > new Date();
    };
    
    // Check if user has a paid subscription (not on trial)
    const hasPaidSubscription = () => {
        if (!userSubscription) return false;
        
        // User has active subscription but is NOT on trial
        // Don't count expired trials with status='active' as paid subscriptions
        return userSubscription.status === 'active' && 
               (!userSubscription.trialEndDate || 
                (userSubscription.plan !== 'premium-trial' && 
                 new Date(userSubscription.trialEndDate) <= new Date()));
    };
    
    // Check if user's trial has expired
    const hasExpiredTrial = () => {
        if (!userSubscription) return false;
        
        // Check for explicit expired status
        if (userSubscription.status === 'expired' && userSubscription.plan === 'premium-trial') {
            return true;
        }
        
        // Also check trial end date for cases that haven't been processed yet
        return userSubscription.trialEndDate && 
               userSubscription.plan === 'premium-trial' &&
               new Date(userSubscription.trialEndDate) <= new Date();
    };
    
    // Get user's subscription type
    const getSubscriptionType = () => {
        if (isAdmin) return 'admin';
        if (isOnTrial()) return 'trial';
        if (hasPaidSubscription()) return 'paid';
        if (hasExpiredTrial()) return 'expired-trial';
        return 'free';
    };
    
    // Get days remaining in trial
    const getTrialDaysRemaining = () => {
        if (!userSubscription || !userSubscription.trialEndDate) return 0;
        
        const trialEndDate = new Date(userSubscription.trialEndDate);
        const today = new Date();
        const diffTime = trialEndDate - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        
        return diffDays > 0 ? diffDays : 0;
    };

    // Update user avatar URL
    const updateUserAvatar = async (avatarUrl) => {
        if (!user) {
            throw new Error('No user is logged in');
        }

        try {
            // Update Firebase auth profile
            await updateProfile(auth.currentUser, {
                photoURL: avatarUrl
            });

            // Update Firestore user document
            const userRef = doc(firestore, 'users', user.uid);
            await updateDoc(userRef, {
                photoURL: avatarUrl,
                updatedAt: new Date().toISOString()
            });

            // Update local user state
            setUser(prev => ({
                ...prev,
                photoURL: avatarUrl
            }));

            return true;
        } catch (error) {
            console.error('Error updating avatar:', error);
            throw error;
        }
    };

    const value = {
        user,
        loading,
        signup: registerUser,
        login,
        loginWithGoogle,
        logout,
        showLoginModal,
        setShowLoginModal,
        userSubscription,
        setUserSubscription,
        checkPaidAccess,
        isAdmin,
        showSignupModal,
        setShowSignupModal,
        activeTab,
        setActiveTab,
        isOnTrial,
        hasPaidSubscription,
        hasExpiredTrial,
        getSubscriptionType,
        getTrialDaysRemaining,
        updateUserAvatar
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}; 
import React from 'react';
import { Row, Col, Card, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import './StatsDisplay.css';
import { getStatColor, getStatColorClass } from '../utils/statThresholds';
import { FaStar } from 'react-icons/fa';

const StatsDisplay = ({ team1Stats, team2Stats, team1Name, team2Name, matchupMode, onPlayerSelect }) => {
    const isCloseToAverage = (avg, median) => {
        return Math.abs(Number(avg) - Number(median)) <= 0.2;
    };

    const renderStatWithMedian = (avgValue, medianValue, statType) => {
        const colorClass = getStatColorClass(avgValue, statType);
        const isClose = isCloseToAverage(avgValue, medianValue);
        
        const medianDisplay = (
            <span className={isClose ? 'median-close' : ''}>
                {medianValue}
            </span>
        );

        return (
            <>
                <span className={colorClass}>{avgValue}</span>
                /
                {isClose ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="consistency-tooltip" className="player-card-tooltip">
                                Median is very close to average, indicating consistent performance
                            </Tooltip>
                        }
                    >
                        {medianDisplay}
                    </OverlayTrigger>
                ) : medianDisplay}
            </>
        );
    };

    const renderStatValue = (value, statType) => {
        const colorClass = getStatColorClass(value, statType);
        return (
            <span className={colorClass}>
                {statType.includes('Percent') ? `${value}%` : value}
            </span>
        );
    };

    const renderStatWithHitRate = (statData, statType, hitRate, threshold) => {
        if (!statData) return null;

        const { avg, med, medL5, trend, isPercentage, hitRate: statHitRate } = statData;
        const colorClass = trend ? 'trend-up' : 'trend-neutral';
        const showStar = (hitRate || statHitRate) >= 60;
        
        const tooltipContent = (
            <Tooltip id="player-stats-tooltip" className="player-card-tooltip">
                <div className="stat-header">Stats</div>
                <div className="stat-row">
                    <span className="stat-label">L5:</span>
                    <span className="stat-value">{medL5}{isPercentage ? '%' : ''}</span>
                </div>
                <div className="stat-row">
                    <span className="stat-label">Med:</span>
                    <span className="stat-value">{med}{isPercentage ? '%' : ''}</span>
                </div>
                <div className="stat-row">
                    <span className="stat-label">Avg:</span>
                    <span className="stat-value">{avg}{isPercentage ? '%' : ''}</span>
                </div>
                {(hitRate || statHitRate) > 0 && (
                    <div className="stat-row">
                        <span className="stat-label">Hit:</span>
                        <span className="stat-value">{(hitRate || statHitRate).toFixed(0)}%</span>
                    </div>
                )}
                {trend && (
                    <div className="stat-row">
                        <span className="stat-label">Trend:</span>
                        <span className="stat-value">↑</span>
                    </div>
                )}
                {showStar && (
                    <div className="stat-row">
                        <span className="stat-label">Cons:</span>
                        <span className="stat-value">⭐</span>
                    </div>
                )}
            </Tooltip>
        );

        return (
            <OverlayTrigger placement="top" overlay={tooltipContent}>
                <div className={`stat-with-hitrate ${colorClass}`}>
                    {showStar && <FaStar className="star-badge" />}
                    <div className="stat-values">
                        <div className="stat-value-group">
                            <div className="stat-value-label">AVG</div>
                            <span>{avg}</span>
                        </div>
                        <div className="stat-value-group">
                            <div className="stat-value-label">MED</div>
                            <span>{med}</span>
                        </div>
                        <div className="stat-value-group">
                            <div className="stat-value-label">L5</div>
                            <span className="medL5">{medL5}</span>
                        </div>
                    </div>
                </div>
            </OverlayTrigger>
        );
    };

    const getThreePtMadeColor = (value) => {
        const numValue = Number(value);
        if (numValue >= 2) return 'green';
        if (numValue >= 1) return 'yellow';
        return 'red';
    };

    const renderPlayerCard = (player) => {
        if (!player.stats) return null;
        const stats = player.stats;
        const advanced = player.advancedStats;
        const hitRates = stats.hitRates || {};

        // Helper to handle both old and new stat formats
        const getStatDisplay = (statKey, type, threshold) => {
            if (stats[statKey]) {
                // New format with avg/med/medL5
                return renderStatWithHitRate(
                    stats[statKey],
                    type,
                    hitRates[type],
                    threshold
                );
            } else {
                // Old format
                const avgKey = `avg${statKey.charAt(0).toUpperCase() + statKey.slice(1)}`;
                const medKey = `median${statKey.charAt(0).toUpperCase() + statKey.slice(1)}`;
                return renderStatWithMedian(stats[avgKey], stats[medKey], type);
            }
        };

        return (
            <div className="player-card">
                <div className="player-name">
                    <div className="player-name-section">
                        <span className="player-name-text">{player.name}</span>
                                    <span className="position-badge">{player.position}</span>
                                </div>
                    <button 
                                className="insights-button"
                                onClick={() => onPlayerSelect(player)}
                            >
                        <i className="fas fa-chart-line insights-icon"></i>
                                Insights
                    </button>
                        </div>
                        <div className="stat-grid">
                    <div className="stat-row">
                        <span className="stat-label">MIN</span>
                        {renderStatValue(stats.medianMinutes || stats.avgMinutes, 'minutes')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">PTS</span>
                        {getStatDisplay('points', 'points', '15 PTS')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">REB</span>
                        {getStatDisplay('rebounds', 'rebounds', '4 REB')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">AST</span>
                        {getStatDisplay('assists', 'assists', '3 AST')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">STL</span>
                        {getStatDisplay('steals', 'steals', '0.8 STL')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">BLK</span>
                        {getStatDisplay('blocks', 'blocks', '0.5 BLK')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">3PM</span>
                        {getStatDisplay('threePtMade', 'threePtMade', '1 3PM')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">FG%</span>
                        {getStatDisplay('fgPercent', 'fgPercent', '40 FG%')}
                            </div>
                    <div className="stat-row">
                        <span className="stat-label">3P%</span>
                        {getStatDisplay('threePtPercent', 'threePtPercent', '35 3P%')}
                                </div>
                            </div>
                        <div className="games-played">
                            Games: {stats.gamesPlayed}
                        </div>
            </div>
        );
    };

    const renderTeamSection = (teamStats, teamName) => (
        <div className="team-section">
            <h3 className="team-header">
                {teamName} {matchupMode && 'vs Opponent'}
            </h3>
            <div className="player-stats-grid">
                {teamStats.map(player => (
                    <div key={player.playerId} className="player-card-container">
                        {renderPlayerCard(player)}
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="stats-display">
            {renderTeamSection(team1Stats, team1Name)}
            {renderTeamSection(team2Stats, team2Name)}
        </div>
    );
};

export default StatsDisplay; 
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
// Remove direct logo import to avoid build errors
// import logo from '../assets/logo.png';

/**
 * Generate a PDF receipt for a payment transaction
 * 
 * @param {Object} payment - The payment object with transaction details
 * @param {Object} user - The user object with customer information
 * @returns {jsPDF} - The generated PDF document that can be downloaded
 */
export const generateReceipt = (payment, user) => {
    // Initialize the PDF document
    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
    });
    
    const pageWidth = doc.internal.pageSize.getWidth();
    
    // Set initial y position
    let y = 20;
    
    // Add company details and logo (top left)
    doc.setFontSize(20);
    doc.setTextColor(41, 128, 185); // Brand blue color
    doc.text('ClutchSports', 20, y);
    doc.setFontSize(10);
    doc.setTextColor(80, 80, 80);
    doc.text('NBA Stats Hub', 20, y + 6);
    
    // Skip logo for now - we'll add this back when we have the proper assets
    // The rest of the receipt generation code stays the same
    
    // Add receipt title and number
    y += 30;
    doc.setFontSize(16);
    doc.setTextColor(30, 30, 30);
    doc.text('RECEIPT', pageWidth / 2, y, { align: 'center' });
    y += 10;
    doc.setFontSize(10);
    doc.text(`Receipt Number: ${payment.receiptNumber || `INV-${Date.now()}`}`, pageWidth / 2, y, { align: 'center' });
    
    // Date and transaction info
    y += 10;
    doc.setFontSize(10);
    doc.text(`Date: ${new Date(payment.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })}`, 20, y);
    
    doc.text(`Transaction ID: ${payment.subscriptionId || 'N/A'}`, pageWidth - 20, y, { align: 'right' });
    
    // Customer information
    y += 15;
    doc.setFontSize(12);
    doc.setTextColor(30, 30, 30);
    doc.text('Customer Information', 20, y);
    y += 6;
    
    doc.setFontSize(10);
    doc.text(`Name: ${user.displayName || 'Customer'}`, 20, y);
    y += 6;
    doc.text(`Email: ${user.email || 'N/A'}`, 20, y);
    y += 6;
    if (user.phoneNumber) {
        doc.text(`Phone: ${user.phoneNumber}`, 20, y);
        y += 6;
    }
    
    // Payment details
    y += 10;
    doc.setFontSize(12);
    doc.text('Payment Details', 20, y);
    y += 10;
    
    // Create a table for the line items
    const tableHeaders = [['Description', 'Plan', 'Amount']];
    const tableData = [[
        payment.description || 'Premium Subscription',
        payment.plan || 'Premium',
        formatCurrency(payment.amount, payment.currency)
    ]];
    
    // Add promo code row if used
    if (payment.promoCode) {
        tableData.push([
            `Promo Code: ${payment.promoCode}`,
            '',
            ''
        ]);
    }
    
    doc.autoTable({
        head: tableHeaders,
        body: tableData,
        startY: y,
        styles: {
            lineColor: [220, 220, 220],
            lineWidth: 0.1,
        },
        headStyles: {
            fillColor: [41, 128, 185],
            textColor: [255, 255, 255],
            halign: 'center'
        },
        theme: 'grid',
        margin: { top: 20, right: 20, bottom: 20, left: 20 },
    });
    
    y = doc.lastAutoTable.finalY + 10;
    
    // Add total
    doc.setFontSize(12);
    doc.setTextColor(30, 30, 30);
    doc.text(`Total: ${formatCurrency(payment.amount, payment.currency)}`, pageWidth - 20, y, { align: 'right' });
    
    // Add payment method info
    y += 10;
    doc.setFontSize(10);
    doc.text(`Payment Method: ${payment.paymentMethod || 'Credit Card'}`, 20, y);
    y += 6;
    doc.text(`Status: ${payment.status || 'Completed'}`, 20, y);
    
    // Add footer
    y = doc.internal.pageSize.getHeight() - 20;
    doc.setFontSize(8);
    doc.setTextColor(120, 120, 120);
    doc.text('This is an automatically generated receipt. For any questions, please contact support@clutchsportsstats.com', 
        pageWidth / 2, y, { align: 'center' });
    y += 5;
    doc.text(`Generated on ${new Date().toLocaleString()}`, pageWidth / 2, y, { align: 'center' });
    
    return doc;
};

/**
 * Format a currency amount with the proper symbol
 * 
 * @param {number} amount - The amount to format
 * @param {string} currency - The currency code (e.g. 'USD')
 * @returns {string} - The formatted currency string
 */
export const formatCurrency = (amount, currency = 'USD') => {
    try {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
        }).format(amount);
    } catch (e) {
        return `${currency} ${amount}`;
    }
}; 
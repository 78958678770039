import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Accordion, Form, Button, Alert, Spinner } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import './SupportTab.css';

// EmailJS configuration using environment variables
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID || 'service_q47p57p';
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || 'template_da14ql6';
const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY || 'CEdz41fV7CQ6WGhes';

// reCAPTCHA v3 site key from environment variable
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6Ld1J-8qAAAAAP9XZ2rJkSGjZi0o-IVMbSMtK0JL';

const SupportTab = () => {
    const formRef = useRef();
    const [contactForm, setContactForm] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });
    const [showStatus, setShowStatus] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    // Check if reCAPTCHA is loaded
    useEffect(() => {
        const checkRecaptchaLoaded = () => {
            if (window.grecaptcha && window.grecaptcha.execute) {
                setRecaptchaLoaded(true);
                return true;
            }
            return false;
        };

        // Check immediately
        if (checkRecaptchaLoaded()) return;

        // If not loaded, check every 100ms for up to 5 seconds
        const intervalId = setInterval(() => {
            if (checkRecaptchaLoaded()) {
                clearInterval(intervalId);
            }
        }, 100);

        // Clear interval after 5 seconds max
        const timeoutId = setTimeout(() => {
            clearInterval(intervalId);
            if (!recaptchaLoaded) {
                console.warn('reCAPTCHA failed to load within 5 seconds');
            }
        }, 5000);

        return () => {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
        };
    }, [recaptchaLoaded]);

    // Initialize EmailJS
    useEffect(() => {
        try {
            emailjs.init(EMAILJS_PUBLIC_KEY);
            console.log('EmailJS initialized successfully');
        } catch (error) {
            console.error('EmailJS initialization error:', error?.name);
        }
    }, []);

    // Clear status message after 5 seconds
    useEffect(() => {
        if (showStatus) {
            const timer = setTimeout(() => {
                setShowStatus(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showStatus]);

    const handleChange = (e) => {
        // Map form field names to state property names
        const fieldNameMap = {
            'from_name': 'name',
            'from_email': 'email'
        };
        
        // Get the correct state property name based on the field name
        const stateProp = fieldNameMap[e.target.name] || e.target.name;
        
        setContactForm({
            ...contactForm,
            [stateProp]: e.target.value
        });
    };

    const executeRecaptcha = () => {
        return new Promise((resolve, reject) => {
            if (!window.grecaptcha || !window.grecaptcha.execute) {
                console.error('reCAPTCHA not loaded properly');
                reject(new Error('reCAPTCHA not loaded'));
                return;
            }
            
            try {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit_contact_form' })
                        .then(token => {
                            console.log('reCAPTCHA token obtained');
                            resolve(token);
                        })
                        .catch(error => {
                            console.error('reCAPTCHA execution error');
                            reject(error);
                        });
                });
            } catch (error) {
                console.error('reCAPTCHA ready error');
                reject(error);
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate that all fields are filled
        const { name, email, subject, message } = contactForm;
        if (!name || !email || !subject || !message) {
            setSubmitStatus({
                type: 'danger',
                message: 'Please fill out all fields before submitting.'
            });
            setShowStatus(true);
            return;
        }
        
        setIsSubmitting(true);
        setShowStatus(false);

        try {
            // Get reCAPTCHA token
            const recaptchaToken = await executeRecaptcha();
            
            console.log('Sending support message...');
            
            // Use direct send method with parameters
            const response = await emailjs.send(
                EMAILJS_SERVICE_ID,
                EMAILJS_TEMPLATE_ID,
                {
                    from_name: name,
                    from_email: email,
                    subject: subject,
                    message: message,
                    'g-recaptcha-response': recaptchaToken
                }
            );
            
            console.log('Support request sent successfully');
            
            // Reset form and show success message
            setContactForm({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
            
            setSubmitStatus({
                type: 'success',
                message: 'Your message has been sent successfully! We will get back to you soon.'
            });
            setShowStatus(true);
        } catch (error) {
            console.error('Error:', error ? (error.text || error.message || error.name || 'Unknown error') : 'Unknown error');
            
            let errorMessage = 'Failed to send your message. Please try again later.';
            if (error?.message === 'reCAPTCHA not loaded') {
                errorMessage = 'Security verification failed. Please ensure cookies are enabled and try again.';
            }
            
            setSubmitStatus({
                type: 'danger',
                message: errorMessage
            });
            setShowStatus(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container className="support-container">
            <Row className="mb-5">
                <Col>
                    <h2>Frequently Asked Questions</h2>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                How are the statistics calculated?
                            </Accordion.Header>
                            <Accordion.Body>
                                Our statistics are calculated using official NBA data and advanced analytics. 
                                We analyze player performance across multiple games and consider various factors 
                                including matchups and historical data.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                How often is the data updated?
                            </Accordion.Header>
                            <Accordion.Body>
                                Our data is updated in real-time during games and refreshed every 5 minutes 
                                for odds and statistics. Historical data is updated daily.
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* Add more FAQ items */}
                    </Accordion>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col>
                    <h2>Documentation</h2>
                    <div className="docs-links">
                        <a href="#" onClick={(e) => e.preventDefault()} className="doc-link">
                            Getting Started Guide
                        </a>
                        <a 
                            href="https://www.nba.com/stats/help/glossary" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="doc-link"
                        >
                            Understanding Statistics
                        </a>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2 id="contact-us">Contact Us</h2>
                    {showStatus && (
                        <Alert variant={submitStatus.type} onClose={() => setShowStatus(false)} dismissible>
                            {submitStatus.message}
                        </Alert>
                    )}
                    <Form ref={formRef} onSubmit={handleSubmit} className="contact-form">
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="from_name"
                                value={contactForm.name} 
                                onChange={handleChange} 
                                required 
                                disabled={isSubmitting}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                name="from_email"
                                value={contactForm.email} 
                                onChange={handleChange} 
                                required 
                                disabled={isSubmitting}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="subject"
                                value={contactForm.subject} 
                                onChange={handleChange} 
                                required
                                disabled={isSubmitting}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={5}
                                name="message"
                                value={contactForm.message} 
                                onChange={handleChange} 
                                required 
                                disabled={isSubmitting}
                            />
                        </Form.Group>
                        
                        <Button 
                            variant="primary" 
                            type="submit" 
                            disabled={isSubmitting} 
                            className="w-100"
                        >
                            {isSubmitting ? (
                                <>
                                    <Spinner animation="border" size="sm" className="me-2" />
                                    Sending...
                                </>
                            ) : 'Send Message'}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default SupportTab; 
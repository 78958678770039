import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Badge, Spinner, Alert, ToggleButton, Modal } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { RAPID_API_CONFIG } from '../config/constants';
import { NBA_TEAMS, TEAM_CODES } from '../utils/teamMappings';
import { calculateAverages, calculateMatchupStats, fetchAdvancedStats } from '../utils/statsProcessing';
import './StatsNew.css';
import StatsDisplay from './StatsDisplay';
import { calculateTeamStats } from '../utils/scheduleProcessing';
import TeamStats from './TeamStats';
import { makeApiRequest } from '../utils/apiUtils';
import { useStats } from '../contexts/StatsContext';

const StatsNew = () => {
    // Use the context instead of local state
    const { 
        formData, setFormData,
        teams, setTeams,
        loading, setLoading,
        error, setError,
        teamRosters, setTeamRosters,
        matchupMode, setMatchupMode,
        playerStats, setPlayerStats,
        teamSchedules, setTeamSchedules,
        teamStats, setTeamStats,
        selectedPlayer, setSelectedPlayer,
        showInsights, setShowInsights,
        hasCalculatedStats, setHasCalculatedStats
    } = useStats();
    
    const { isAdmin } = useAuth();
    const seasons = ["2024-2025", "2023-2024", "2022-2023"];

    // Load teams on first render only if not already loaded
    useEffect(() => {
        if (teams.length === 0) {
            fetchTeams();
        }
    }, [teams.length]);

    const fetchTeams = async () => {
        try {
            setError(null);
            setLoading(true);
            
            // Use our existing team mappings
            const formattedTeams = Object.entries(NBA_TEAMS).map(([code, name]) => ({
                id: code,
                name: name
            }));
            
            setTeams(formattedTeams);
            setLoading(false);
        } catch (err) {
            setError('Failed to load teams');
            console.error('Error:', err);
            setLoading(false);
        }
    };

    const fetchTeamRoster = async (teamCode, season) => {
        try {
            // Map team codes that differ between our app and the API
            const teamCodeMapping = {
                'BKN': 'BRK', // Brooklyn Nets -> API uses BRK
                'PHX': 'PHO', // Phoenix Suns
                'CHA': 'CHO'  // Charlotte Hornets
            };
            
            // Use the mapped code if it exists, otherwise use the original code
            const apiTeamCode = teamCodeMapping[teamCode] || teamCode;
            
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/teams/${apiTeamCode}/roster/${season}`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY,
                        'specificMethodHeaders': {}
                    }
                }
            );
            
            if (!response?.body?.roster) {
                throw new Error('Invalid roster response');
            }

            // Map roster to player IDs and names
            return response.body.roster.map(player => ({
                playerId: player.playerId,
                name: player.name,
                position: player.position,
                team: teamCode
            }));
        } catch (err) {
            console.error(`Error fetching roster for ${teamCode}:`, err);
            return [];
        }
    };

    const handleTeamSelect = async (team, field) => {
        setFormData(prev => ({ ...prev, [field]: team }));
        if (team && !teamRosters[team]) {
            const roster = await fetchTeamRoster(team, formData.seasonYear);
            setTeamRosters(prev => ({ ...prev, [team]: roster }));
        }
    };

    const fetchTeamPlayers = async (teamCode, roster) => {
        try {
            // Map roster directly to player objects since we already have IDs
            const playerPromises = roster.map(async player => {
                return {
                    playerId: player.playerId,
                    name: player.name,
                    position: player.position,
                    team: teamCode
                };
            });

            const players = (await Promise.all(playerPromises)).filter(p => p !== null);
            console.log(`Players for ${teamCode}:`, players);
            return players;
        } catch (err) {
            console.error(`Error processing players for ${teamCode}:`, err);
            return [];
        }
    };

    const fetchPlayerStats = async (playerId, season) => {
        try {
            const seasonEndYear = season.split('-')[1];
            
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/players/${playerId}/games/${seasonEndYear}`,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-rapidapi-ua': 'RapidAPI-Playground',
                        'x-rapidapi-key': RAPID_API_CONFIG.API_KEY,
                        'x-rapidapi-host': RAPID_API_CONFIG.API_HOST,
                        'specificMethodHeaders': '[object Object]'
                    },
                    body: JSON.stringify({
                        pageSize: 100
                    })
                }
            );

            console.log(`Stats response for ${playerId}:`, response);
            return response.body || null;
        } catch (err) {
            console.error(`Error fetching stats for ${playerId}:`, err);
            return null;
        }
    };

    const calculatePlayerStats = (games, opponent = null) => {
        if (!games || !Array.isArray(games)) return null;

        // Filter games against specific opponent if in matchup mode
        const filteredGames = opponent ? 
            games.filter(g => g.opponent === opponent) : games;

        if (filteredGames.length === 0) return null;

        // Sort games by date in descending order
        const sortedGames = [...filteredGames].sort((a, b) => {
            const dateA = new Date(a.gameId.slice(0, 8));
            const dateB = new Date(b.gameId.slice(0, 8));
            return dateB - dateA;
        });

        // Get last 5 games
        const last5Games = sortedGames.slice(0, 5);
        
        // Debug logging
        console.log('Processing games:', {
            total: filteredGames.length,
            last5: last5Games.map(g => ({
                gameId: g.gameId,
                points: g.points,
                threePtMade: g.threePointerMade,
                fgPercent: g.fieldGoalPercentage,
                threePtPercent: g.threePointPercentage
            }))
        });

        // Calculate medians helper function
        const calculateMedian = (arr) => {
            if (!arr || arr.length === 0) return 0;
            const sorted = [...arr].sort((a, b) => a - b);
            const mid = Math.floor(sorted.length / 2);
            return sorted.length % 2 === 0 
                ? (sorted[mid - 1] + sorted[mid]) / 2 
                : sorted[mid];
        };

        // Extract stats from games
        const extractStats = (games) => {
            console.log('First game raw data:', games[0]);
            return {
                points: games.map(g => parseFloat(g.points) || 0),
                assists: games.map(g => parseFloat(g.assists) || 0),
                rebounds: games.map(g => parseFloat(g.totalRebounds) || 0),
                steals: games.map(g => parseFloat(g.steals) || 0),
                blocks: games.map(g => parseFloat(g.blocks) || 0),
                threePtMade: games.map(g => parseFloat(g.threePointersMade || g.threePointerMade || g.threePtMade || g.tpm) || 0),
                fgPercent: games.map(g => {
                    const raw = g.fieldGoalPercentage;
                    if (!raw) return 0;
                    const percent = parseFloat(raw);
                    return raw.startsWith('.') ? percent : percent / 100;
                }),
                threePtPercent: games.map(g => {
                    const raw = g.threePointPercentage;
                    if (!raw) return 0;
                    const percent = parseFloat(raw);
                    return raw.startsWith('.') ? percent : percent / 100;
                }),
                minutes: games.map(g => {
                    if (!g.minutesPlayed) return 0;
                    const [mins, secs] = g.minutesPlayed.split(':').map(Number);
                    return mins + (secs / 60);
                })
            };
        };

        // Calculate stats for all games and last 5 games
        const allStats = extractStats(filteredGames);
        const last5Stats = extractStats(last5Games);

        // Calculate averages
        const totals = filteredGames.reduce((acc, game) => {
            acc.points += parseFloat(game.points) || 0;
            acc.assists += parseFloat(game.assists) || 0;
            acc.rebounds += parseFloat(game.totalRebounds || game.rebounds) || 0;
            acc.steals += parseFloat(game.steals) || 0;
            acc.blocks += parseFloat(game.blocks) || 0;
            acc.threePtMade += parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || game.tpm) || 0;
            acc.fgPercent += parseFloat(game.fieldGoalPercentage || game.fgPercent || game.fgp) || 0;
            acc.threePtPercent += parseFloat(game.threePointPercentage || game.threePtPercent || game.tpp) || 0;
            acc.gamesPlayed++;
            return acc;
        }, {
            points: 0, assists: 0, rebounds: 0, steals: 0, blocks: 0, threePtMade: 0,
            fgPercent: 0, threePtPercent: 0, gamesPlayed: 0
        });

        // Calculate hit rates
        const calculateHitRates = () => ({
            points: filteredGames.filter(g => parseFloat(g.points || 0) >= 15).length / filteredGames.length * 100,
            assists: filteredGames.filter(g => parseFloat(g.assists || 0) >= 3).length / filteredGames.length * 100,
            rebounds: filteredGames.filter(g => parseFloat(g.totalRebounds || g.rebounds || 0) >= 4).length / filteredGames.length * 100,
            blocks: filteredGames.filter(g => parseFloat(g.blocks || 0) >= 0.5).length / filteredGames.length * 100,
            steals: filteredGames.filter(g => parseFloat(g.steals || 0) >= 0.8).length / filteredGames.length * 100,
            threePtMade: filteredGames.filter(g => parseFloat(g.threePointersMade || g.threePointerMade || g.threePtMade || g.tpm || 0) >= 1).length / filteredGames.length * 100,
            fgPercent: filteredGames.filter(g => parseFloat(g.fieldGoalPercentage || g.fgPercent || g.fgp || 0) >= 40).length / filteredGames.length * 100,
            threePtPercent: filteredGames.filter(g => parseFloat(g.threePointPercentage || g.threePtPercent || g.tpp || 0) >= 35).length / filteredGames.length * 100
        });

        const hitRates = calculateHitRates();

        // Create stat objects with avg/med/medL5
        const createStatObject = (key) => {
            const isPercentage = key.toLowerCase().includes('percent');
            const values = {
                avg: totals[key] / totals.gamesPlayed,
                med: calculateMedian(allStats[key]),
                medL5: calculateMedian(last5Stats[key])
            };

            // For percentages, convert to proper percentage format
            if (isPercentage) {
                values.avg *= 100;
                values.med *= 100;
                values.medL5 *= 100;
            }

            // Format all values to one decimal place
            const formatted = {
                avg: values.avg.toFixed(1),
                med: values.med.toFixed(1),
                medL5: values.medL5.toFixed(1)
            };

            return {
                ...formatted,
                trend: Number(values.medL5) >= Number(values.med) && Number(values.med) >= Number(values.avg),
                isPercentage,
                hitRate: hitRates[key]
            };
        };

        return {
            points: createStatObject('points'),
            assists: createStatObject('assists'),
            rebounds: createStatObject('rebounds'),
            steals: createStatObject('steals'),
            blocks: createStatObject('blocks'),
            threePtMade: createStatObject('threePtMade'),
            medianMinutes: calculateMedian(filteredGames.map(g => {
                const [mins, secs] = (g.minutesPlayed || '0:0').split(':');
                return parseInt(mins) + parseInt(secs)/60;
            })).toFixed(1),
            gamesPlayed: totals.gamesPlayed,
            fgPercent: createStatObject('fgPercent'),
            threePtPercent: createStatObject('threePtPercent'),
            hitRates
        };
    };

    const processTeamStats = async (teamCode, players, opponent = null) => {
        if (!Array.isArray(players)) {
            console.error('Invalid players array:', players);
            return [];
        }

        console.log(`Processing stats for ${teamCode} players:`, players);

        const playerStatsPromises = players.map(async player => {
            try {
                // Try to get current season stats
                const games = await fetchPlayerStats(player.playerId, formData.seasonYear);
                
                // Use calculatePlayerStats for both normal and matchup modes
                const stats = games ? calculatePlayerStats(games, opponent) : null;
                
                return {
                    ...player,
                    stats,
                    isCareerStats: !games
                };
            } catch (err) {
                console.error(`Error processing stats for ${player.name}:`, err);
                return {
                    ...player,
                    stats: null,
                    isCareerStats: true
                };
            }
        });

        return Promise.all(playerStatsPromises);
    };

    const fetchTeamSchedule = async (teamCode) => {
        try {
            // Map team codes that differ between our app and the API
            const teamCodeMapping = {
                'BKN': 'BRK', // Brooklyn Nets -> API uses BRK
                'PHX': 'PHO', // Phoenix Suns
                'CHA': 'CHO'  // Charlotte Hornets
            };
            
            // Use the mapped code if it exists, otherwise use the original code
            const apiTeamCode = teamCodeMapping[teamCode] || teamCode;
            
            const data = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/teams/${apiTeamCode}/schedule/${formData.seasonYear}`
            );
            return data.body.schedule;
        } catch (err) {
            console.error(`Error fetching schedule for ${teamCode}:`, err);
            return [];
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Fetch players for both teams
            const [team1Players, team2Players] = await Promise.all([
                fetchTeamPlayers(formData.team1, teamRosters[formData.team1]),
                fetchTeamPlayers(formData.team2, teamRosters[formData.team2])
            ]);

            console.log('Fetched players:', {
                team1: team1Players,
                team2: team2Players
            });

            // Process player stats
            const [team1PlayerStats, team2PlayerStats] = await Promise.all([
                processTeamStats(formData.team1, team1Players, matchupMode ? formData.team2 : null),
                processTeamStats(formData.team2, team2Players, matchupMode ? formData.team1 : null)
            ]);

            console.log('Processed player stats:', {
                team1Stats: team1PlayerStats,
                team2Stats: team2PlayerStats
            });

            setPlayerStats({
                team1: team1PlayerStats || [],
                team2: team2PlayerStats || []
            });

            // Mark that stats have been calculated
            setHasCalculatedStats(true);

            console.log('Updated player stats state:', {
                team1Length: team1PlayerStats?.length || 0,
                team2Length: team2PlayerStats?.length || 0
            });

        } catch (err) {
            setError('Failed to calculate stats. Please try again.');
            console.error('Error calculating stats:', err);
        } finally {
            setLoading(false);
        }
    };

    const processPlayerStats = async (player) => {
        try {
            // Get player metadata first
            const metadata = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/players/${player.playerId}`,
                'GET'
            );

            // Log metadata response for debugging
            console.log('Player metadata response:', {
                playerId: player.playerId,
                name: player.name,
                metadata: metadata?.body,
                success: !!metadata?.body
            });

            // Extract headshot URL from metadata
            const headshotUrl = metadata?.body?.headshotUrl || null;

            // Get player game stats
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/players/${player.playerId}/games/2025`,
                'POST',
                {
                    pageSize: 100
                }
            );

            // Process stats if available
            if (response?.body) {
                const stats = calculatePlayerStats(response.body);
                const advancedStats = await fetchAdvancedStats(player.playerId, '2024-2025', RAPID_API_CONFIG);

                const processedPlayer = {
                    ...player,
                    headshotUrl, // Add headshot URL to player object
                    stats,
                    advancedStats
                };

                // Log processed player for debugging
                console.log('Processed player:', {
                    playerId: processedPlayer.playerId,
                    name: processedPlayer.name,
                    hasHeadshot: !!processedPlayer.headshotUrl,
                    headshotUrl: processedPlayer.headshotUrl
                });

                return processedPlayer;
            }
            return null;
        } catch (error) {
            console.error(`Error processing stats for ${player.name}:`, error);
            return null;
        }
    };

    // Add this function to analyze correlations for a player
    const analyzePlayerCorrelations = (player) => {
        const correlations = [];
        const stats = player.stats;
        
        if (!stats) return correlations;

        // Minutes to Points correlation
        if (stats.points && stats.medianMinutes) {
            if (Number(stats.medianMinutes) > 25) {
                correlations.push({
                    insight: `${player.name} averages ${stats.points.avg} points in ${stats.medianMinutes} minutes of play`,
                    type: 'minutes_points'
                });
            }
        }

        // Shooting efficiency analysis
        if (stats.fgPercent && stats.threePtPercent) {
            const fgPercentage = stats.fgPercent.avg;
            const threePtPercentage = stats.threePtPercent.avg;
            
            correlations.push({
                insight: `Shooting splits: ${fgPercentage}% FG, ${threePtPercentage}% 3PT`,
                type: 'shooting_efficiency'
            });
        }

        // Consistency analysis using hit rates
        if (stats.hitRates) {
            Object.entries(stats.hitRates).forEach(([stat, rate]) => {
                if (rate >= 60) {
                    correlations.push({
                        insight: `Highly consistent ${stat} producer with ${Number(rate).toFixed(1)}% hit rate`,
                        type: 'consistency'
                    });
                }
            });
        }

        // Add trend analysis
        const addTrendInsight = (stat, label) => {
            if (stats[stat]?.trend) {
                correlations.push({
                    insight: `Showing upward trend in ${label} (Last 5 > Median > Average)`,
                    type: 'trend'
                });
            }
        };

        // Check trends for key stats
        addTrendInsight('points', 'scoring');
        addTrendInsight('rebounds', 'rebounding');
        addTrendInsight('assists', 'assists');
        addTrendInsight('threePtMade', 'three-point shooting');

        return correlations;
    };

    const PlayerInsightsModal = ({ player, show, onHide }) => {
        const correlations = analyzePlayerCorrelations(player);

        return (
            <Modal show={show} onHide={onHide} className="player-insights-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Statistical Insights: {player.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="insights-container">
                        {correlations.map((correlation, index) => (
                            <div key={index} className="insight-item">
                                <p>{correlation.insight}</p>
                            </div>
                        ))}
                        {correlations.length === 0 && (
                            <p>No significant statistical correlations found.</p>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <div className="stats-new-container">
            <div className="header-section">
                <h2>Team Comparison 
                    {isAdmin && (
                        <Badge bg="danger" className="ms-2">Admin Access</Badge>
                    )}
                </h2>
            </div>
            
            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col md={5}>
                        <Form.Group>
                            <Form.Label>Team 1</Form.Label>
                            <Form.Select
                                value={formData.team1}
                                onChange={(e) => handleTeamSelect(e.target.value, 'team1')}
                                required
                                disabled={loading}
                            >
                                <option value="">Select Team 1</option>
                                {teams.map(team => (
                                    <option key={team.id} value={team.id}>
                                        {team.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md={5}>
                        <Form.Group>
                            <Form.Label>Team 2</Form.Label>
                            <Form.Select
                                value={formData.team2}
                                onChange={(e) => handleTeamSelect(e.target.value, 'team2')}
                                required
                                disabled={loading}
                            >
                                <option value="">Select Team 2</option>
                                {teams.map(team => (
                                    <option key={team.id} value={team.id}>
                                        {team.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label>Season</Form.Label>
                            <Form.Select
                                value={formData.seasonYear}
                                onChange={(e) => setFormData({...formData, seasonYear: e.target.value})}
                                required
                                disabled={loading}
                            >
                                {seasons.map(season => (
                                    <option key={season} value={season}>{season}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12} className="mt-3">
                        <Form.Check
                            type="switch"
                            id="matchup-mode"
                            label="Show Head-to-Head Stats Only"
                            checked={matchupMode}
                            onChange={(e) => setMatchupMode(e.target.checked)}
                            className="mb-3"
                            disabled={loading}
                        />
                    </Col>
                </Row>

                <div className="d-flex justify-content-between">
                    <Button 
                        type="submit" 
                        variant="primary" 
                        size="lg"
                        disabled={loading || !formData.team1 || !formData.team2 || teams.length === 0}
                    >
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                                Calculating...
                            </>
                        ) : (
                            'Calculate Stats'
                        )}
                    </Button>
                    
                    {hasCalculatedStats && (
                        <Button 
                            variant="outline-secondary" 
                            size="lg"
                            onClick={() => {
                                setPlayerStats({ team1: {}, team2: {} });
                                setTeamStats({ team1: null, team2: null });
                                setHasCalculatedStats(false);
                            }}
                            disabled={loading}
                        >
                            Reset Stats
                        </Button>
                    )}
                </div>
            </Form>

            {hasCalculatedStats && (
                <>
                    <TeamStats
                        team1Stats={teamStats.team1}
                        team2Stats={teamStats.team2}
                        team1Name={NBA_TEAMS[formData.team1]}
                        team2Name={NBA_TEAMS[formData.team2]}
                        matchupMode={matchupMode}
                    />
                    <StatsDisplay
                        team1Stats={playerStats.team1}
                        team2Stats={playerStats.team2}
                        team1Name={NBA_TEAMS[formData.team1]}
                        team2Name={NBA_TEAMS[formData.team2]}
                        matchupMode={matchupMode}
                        onPlayerSelect={(player) => {
                            setSelectedPlayer(player);
                            setShowInsights(true);
                        }}
                    />
                </>
            )}

            {selectedPlayer && (
                <PlayerInsightsModal
                    player={selectedPlayer}
                    show={showInsights}
                    onHide={() => {
                        setShowInsights(false);
                        setSelectedPlayer(null);
                    }}
                />
            )}
        </div>
    );
};

export default StatsNew; 
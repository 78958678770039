import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import './ApiLimitAlert.css';
import { sendApiLimitAlert } from '../utils/emailUtils';

const ApiLimitAlert = ({ requestsRemaining, totalRequests = 100000 }) => {
  const [show, setShow] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [currentRemaining, setCurrentRemaining] = useState(requestsRemaining);
  
  // Check for the most up-to-date count from localStorage
  useEffect(() => {
    const updateFromStorage = () => {
      const storedCount = localStorage.getItem('apiRequestsRemaining');
      if (storedCount) {
        const parsedCount = parseInt(storedCount, 10);
        // Only update if the stored value is different from what we have
        if (parsedCount !== currentRemaining) {
          console.log('Updating API limit alert with latest count from storage:', parsedCount);
          setCurrentRemaining(parsedCount);
        }
      } else if (requestsRemaining !== currentRemaining) {
        // Fall back to prop value if no localStorage value is available
        setCurrentRemaining(requestsRemaining);
      }
    };
    
    // Initial check
    updateFromStorage();
    
    // Listen for storage events (when App.js updates the count)
    window.addEventListener('storage', updateFromStorage);
    
    // Also set up a periodic check every 30 seconds in case the storage event doesn't fire
    const intervalId = setInterval(updateFromStorage, 30000);
    
    return () => {
      window.removeEventListener('storage', updateFromStorage);
      clearInterval(intervalId);
    };
  }, [requestsRemaining, currentRemaining]);
  
  // Parse inputs as numbers to ensure proper calculation
  const remaining = parseInt(currentRemaining, 10);
  const total = parseInt(totalRequests, 10) || 100000; // Default to 100,000 if invalid
  
  // Add safety check for data consistency
  const effectiveRemaining = Math.min(remaining, total);
  
  // Calculate percentage remaining
  const percentRemaining = (effectiveRemaining / total) * 100;
  const isNearLimit = percentRemaining <= 25;
  
  // Send email alert when near limit
  useEffect(() => {
    if (currentRemaining !== null && isNearLimit && !emailSent) {
      // Send email alert
      sendApiLimitAlert({ 
        requestsRemaining: effectiveRemaining, 
        totalRequests: total 
      })
        .then(() => {
          console.log('API limit alert email sent');
          setEmailSent(true);
          // Store in localStorage to prevent sending multiple emails in the same session
          localStorage.setItem('apiLimitEmailSent', 'true');
          localStorage.setItem('apiLimitEmailSentTimestamp', Date.now().toString());
        })
        .catch(error => {
          console.error('Failed to send API limit alert email:', error);
        });
    }
  }, [currentRemaining, isNearLimit, total, emailSent, effectiveRemaining]);
  
  // Check if we've already sent an email recently (within 24 hours)
  useEffect(() => {
    const emailSentFlag = localStorage.getItem('apiLimitEmailSent');
    const emailSentTimestamp = localStorage.getItem('apiLimitEmailSentTimestamp');
    
    if (emailSentFlag === 'true' && emailSentTimestamp) {
      const sentTime = parseInt(emailSentTimestamp, 10);
      const now = Date.now();
      const hoursSinceLastEmail = (now - sentTime) / (1000 * 60 * 60);
      
      // If it's been less than 24 hours since the last email, mark as sent
      if (hoursSinceLastEmail < 24) {
        setEmailSent(true);
      } else {
        // Reset if it's been more than 24 hours
        localStorage.removeItem('apiLimitEmailSent');
        localStorage.removeItem('apiLimitEmailSentTimestamp');
        setEmailSent(false);
      }
    }
  }, []);
  
  // Show alert when near limit
  useEffect(() => {
    if (currentRemaining !== null && isNearLimit) {
      setShow(true);
    }
  }, [currentRemaining, isNearLimit]);
  
  if (!show || currentRemaining === null || !isNearLimit) {
    return null;
  }
  
  return (
    <Alert 
      variant="warning" 
      className="api-limit-alert"
      dismissible
      onClose={() => setShow(false)}
    >
      <Alert.Heading>
        <BsExclamationTriangleFill className="me-2" />
        API Request Limit Warning
      </Alert.Heading>
      <p>
        Your Odds API request limit is running low. You have <strong>{currentRemaining}</strong> requests 
        remaining ({percentRemaining.toFixed(1)}% of your quota).
      </p>
      <p>
        To conserve your remaining requests:
      </p>
      <ul>
        <li>Disable auto-refresh when not actively using the application</li>
        <li>Log out when you're done using the application</li>
        <li>Limit unnecessary page refreshes</li>
      </ul>
      <div className="d-flex justify-content-end">
        <Button 
          variant="outline-secondary" 
          size="sm"
          onClick={() => setShow(false)}
        >
          Dismiss
        </Button>
      </div>
    </Alert>
  );
};

export default ApiLimitAlert; 
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, getCountFromServer } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import { BsArrowClockwise } from 'react-icons/bs';
import './AboutTab.css';
import { useUserActivity } from '../contexts/UserActivityContext';

const AboutTab = () => {
    const navigate = useNavigate();
    const [metrics, setMetrics] = useState({
        eventsTracked: 1200, // Manual value as requested
        registeredUsers: 0,
        loading: true,
        lastUpdated: null
    });
    const { isActive } = useUserActivity();

    const fetchMetrics = async () => {
        setMetrics(prev => ({ ...prev, loading: true }));
        try {
            // Try to get registered users count from Firestore
            let registeredUsers = 0;
            try {
                const usersRef = collection(firestore, 'users');
                const usersSnapshot = await getCountFromServer(usersRef);
                registeredUsers = usersSnapshot.data().count;
            } catch (error) {
                console.warn('Could not fetch exact user count, using fallback:', error);
                // Fallback to approximate user count
                registeredUsers = 5; // Use an approximate value if query fails
            }

            // Use manual value for events tracked instead of fetching from Firebase
            const eventsTracked = 1200;

            setMetrics({
                eventsTracked,
                registeredUsers,
                loading: false,
                lastUpdated: new Date()
            });
        } catch (error) {
            console.error('Error fetching metrics:', error);
            // Fallback to default values
            setMetrics({ 
                registeredUsers: 5, // Fallback user count 
                eventsTracked: 1200,   // Manual events value
                loading: false, 
                lastUpdated: new Date()
            });
        }
    };

    // Fetch metrics on component mount
    useEffect(() => {
        fetchMetrics();
        
        // Set up interval to refresh metrics every 5 minutes
        const intervalId = setInterval(() => {
            if (isActive) { // Only fetch if user is active
                fetchMetrics();
            }
        }, 5 * 60 * 1000);
        
        return () => clearInterval(intervalId);
    }, [isActive]); // Add isActive to dependencies

    const features = [
        {
            title: "Player Statistics",
            description: "Comprehensive NBA player statistics with advanced metrics and historical data"
        },
        {
            title: "Team Analysis",
            description: "In-depth team performance analysis and head-to-head comparisons"
        },
        {
            title: "Real Time Odds Analysis",
            description: "Advanced analytics for NBA game odds with line movement tracking and value identification"
        },
        {
            title: "Game-time Support",
            description: "Live game-time assistance and expert guidance via email for real-time decision making"
        }
    ];

    const team = [
        {
            name: "Akuma Akuma-Ukpo",
            role: "Development"
        },
        {
            name: "Akuma Akuma-Ukpo ",
            role: "Data Science"
        },
        {
            name: "Akuma Akuma-Ukpo",
            role: "UI/UX"
        }
    ];

    const useCases = [
        {
            title: "Player Performance Prediction",
            description: "Predict player stats for upcoming games using historical data, opponent matchup analysis, and recent performance trends."
        },
        {
            title: "Value Identification",
            description: "Identify value opportunities in player props markets by comparing current lines with statistical projections."
        },
        {
            title: "Team Matchup Analysis",
            description: "Analyze team strengths and weaknesses to better understand how matchups affect game outcomes."
        },
        {
            title: "Historical Pattern Recognition",
            description: "Identify recurring patterns in player and team performance to anticipate future results."
        },
        {
            title: "NBA Draft Analysis",
            description: "Evaluate draft prospects and their potential impact, especially valuable for fantasy basketball and daily fantasy sports decisions."
        }
    ];

    const navigateToContactUs = () => {
        navigate('/support');
        // Use setTimeout to ensure navigation completes before trying to scroll
        setTimeout(() => {
            const contactForm = document.getElementById('contact-us');
            if (contactForm) {
                contactForm.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <Container className="about-container">
            <section className="about-section">
                <h2 className="section-title">About ClutchSports NBA Stats Hub</h2>
                <div className="slogan-equation">
                    <span className="eq-term">research</span>
                    <span className="eq-operator">×</span>
                    <span className="eq-term">luck</span>
                    <span className="eq-operator">=</span>
                    <span className="eq-result">possibilities</span>
                </div>
                <p className="section-description">
                    ClutchSports NBA Stats Hub is your comprehensive platform for NBA statistics,
                    analysis, and insights. We provide real-time data and advanced analytics
                    to help you make informed decisions about your favorite teams and players.
                </p>
            </section>

            <section className="metrics-section">
                <h3 className="section-title">
                    Platform Metrics
                    <Button 
                        variant="link" 
                        className="metrics-refresh-btn"
                        onClick={fetchMetrics}
                        disabled={metrics.loading}
                        title="Refresh metrics"
                    >
                        <BsArrowClockwise className={metrics.loading ? 'spinning' : ''} />
                    </Button>
                </h3>
                <Row>
                    <Col md={6}>
                        <Card className="metric-card">
                            {metrics.lastUpdated && <div className="metrics-update-icon" title={`Last updated: ${metrics.lastUpdated.toLocaleTimeString()}`}></div>}
                            <Card.Body>
                                <Card.Title className="metric-title">NBA Events Tracked</Card.Title>
                                <Card.Text className="metric-value">
                                    {metrics.loading ? 'Loading...' : metrics.eventsTracked.toLocaleString()}
                                </Card.Text>
                                <Card.Text className="metric-description">
                                    Total NBA events covered since our initial deployment
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className="metric-card">
                            {metrics.lastUpdated && <div className="metrics-update-icon" title={`Last updated: ${metrics.lastUpdated.toLocaleTimeString()}`}></div>}
                            <Card.Body>
                                <Card.Title className="metric-title">Active Users</Card.Title>
                                <Card.Text className="metric-value">
                                    {metrics.loading ? 'Loading...' : metrics.registeredUsers.toLocaleString()}
                                </Card.Text>
                                <Card.Text className="metric-description">
                                    Total registered users
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>

            <section className="features-section">
                <h3 className="section-title">Features</h3>
                <Row>
                    {features.map((feature, index) => (
                        <Col md={6} lg={3} key={index}>
                            <Card className="feature-card">
                                <Card.Body>
                                    <Card.Title className="feature-title">{feature.title}</Card.Title>
                                    <Card.Text className="feature-description">
                                        {feature.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </section>

            <section className="use-cases-section">
                <h3 className="section-title">Top Use Cases</h3>
                <Row>
                    {useCases.map((useCase, index) => (
                        <Col md={6} key={index}>
                            <Card className="use-case-card">
                                <Card.Body>
                                    <Card.Title className="use-case-title">{useCase.title}</Card.Title>
                                    <Card.Text className="use-case-description">
                                        {useCase.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </section>

            <section className="team-section">
                <h3 className="section-title">Development Team</h3>
                <Row>
                    {team.map((member, index) => (
                        <Col md={4} key={index}>
                            <Card className="team-card">
                                <Card.Body>
                                    <Card.Title className="member-name">{member.name}</Card.Title>
                                    <Card.Subtitle className="member-role">{member.role}</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </section>

            <section className="feedback-section text-center">
                <h3 className="section-title">We Value Your Feedback</h3>
                <p className="section-description">
                    Help us improve our platform by sharing your thoughts, suggestions, or reporting issues.
                </p>
                <Button 
                    variant="primary" 
                    size="lg" 
                    className="feedback-button"
                    onClick={navigateToContactUs}
                >
                    Give Feedback
                </Button>
            </section>
        </Container>
    );
};

export default AboutTab; 
// Import necessary utilities and constants
import { makeApiRequest } from '../utils/apiUtils';
import { RAPID_API_CONFIG } from '../config/constants';

// Function to calculate player predictions for next game
export const calculatePlayerPredictions = async (playerStats) => {
    if (!playerStats || !playerStats.games || playerStats.games.length === 0) {
        return null;
    }
    
    // Get the last 5 games for recent trend analysis
    const recentGames = [...playerStats.games]
        .sort((a, b) => {
            // Sort by date (most recent first)
            const dateA = new Date(a.gameDate || a.date || a.startDateEastern || 0);
            const dateB = new Date(b.gameDate || b.date || b.startDateEastern || 0);
            return dateB - dateA;
        })
        .slice(0, 5);
    
    // Log game structure to understand available properties
    console.log("First game data structure:", recentGames[0]);
    
    // Calculate recent averages
    const recentAverages = {
        points: recentGames.reduce((sum, game) => sum + parseFloat(game.points || 0), 0) / recentGames.length,
        assists: recentGames.reduce((sum, game) => sum + parseFloat(game.assists || 0), 0) / recentGames.length,
        rebounds: recentGames.reduce((sum, game) => sum + parseFloat(game.totalRebounds || game.rebounds || 0), 0) / recentGames.length,
        steals: recentGames.reduce((sum, game) => sum + parseFloat(game.steals || 0), 0) / recentGames.length,
        blocks: recentGames.reduce((sum, game) => sum + parseFloat(game.blocks || 0), 0) / recentGames.length,
        threePointersMade: recentGames.reduce((sum, game) => sum + parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0), 0) / recentGames.length
    };
    
    // Blend season averages with recent performance (60% season, 40% recent)
    const blendWeight = 0.4; // 40% weight to recent performance
    
    const predictions = {
        // Fix for NaN in rebounds - use totalRebounds or rebounds from averages
        points: (playerStats.averages.points * (1 - blendWeight)) + (recentAverages.points * blendWeight),
        assists: (playerStats.averages.assists * (1 - blendWeight)) + (recentAverages.assists * blendWeight),
        rebounds: ((playerStats.averages.totalRebounds || playerStats.averages.rebounds || 0) * (1 - blendWeight)) + (recentAverages.rebounds * blendWeight),
        steals: (playerStats.averages.steals * (1 - blendWeight)) + (recentAverages.steals * blendWeight),
        blocks: (playerStats.averages.blocks * (1 - blendWeight)) + (recentAverages.blocks * blendWeight),
        threePointersMade: (playerStats.averages.threePointersMade * (1 - blendWeight)) + (recentAverages.threePointersMade * blendWeight),
        minutes: playerStats.averages.minutes
    };
    
    // Function to fetch team schedule
    const fetchTeamSchedule = async (teamCode, season) => {
        try {
            // Map team codes that differ between our app and the API
            const teamCodeMapping = {
                'BKN': 'BRK', // Brooklyn Nets -> API uses BRK
                'PHX': 'PHO', // Phoenix Suns
                'CHA': 'CHO'  // Charlotte Hornets
            };
            
            // Use the mapped code if it exists, otherwise use the original code
            const apiTeamCode = teamCodeMapping[teamCode] || teamCode;
            
            const response = await makeApiRequest(
                `${RAPID_API_CONFIG.BASE_URL}/teams/${apiTeamCode}/schedule/${season}`
            );
            
            if (response?.body?.schedule) {
                return response.body.schedule;
            }
            return null;
        } catch (err) {
            console.error(`Error fetching schedule for ${teamCode}:`, err);
            return null;
        }
    };

    // Better fallback schedule that indicates we're using projected data
    const fallbackSchedule = [
        { 
            opponent: "Schedule Unavailable", 
            gameDate: new Date(Date.now() + 86400000).toLocaleDateString(), 
            gameTime: "TBD", 
            gameLocation: "TBD",
            isFallback: true // Flag to indicate this is a fallback/placeholder
        }
    ];
    
    // Improved team detection from multiple sources
    let playerTeam = playerStats.player?.team || playerStats.player?.teamAbbreviation;
    
    // If not found, try to extract from the game data
    if (!playerTeam) {
        // Try different possible team properties in the game data
        const possibleTeamProps = [
            'teamAbbreviation', 'team', 'teamName', 'teamId', 'teamAbbr',
            'homeTeam', 'visitorTeam', 'hTeam', 'vTeam', 'playerTeam'
        ];
        
        // Check all possible properties in the first game
        for (const prop of possibleTeamProps) {
            if (playerStats.games[0] && playerStats.games[0][prop]) {
                playerTeam = playerStats.games[0][prop];
                break;
            }
        }
        
        // If still not found, try to extract it from the match data
        if (!playerTeam && playerStats.games[0]?.matchup) {
            const matchup = playerStats.games[0].matchup;
            // Matchups are often formatted like "GSW vs. LAL" or "GSW @ LAL"
            const teamCodes = matchup.split(/\s+(?:vs\.?|@)\s+/);
            if (teamCodes.length >= 2) {
                // Take the first team code (usually the home team)
                playerTeam = teamCodes[0].trim();
            }
        }
    }
    
    // Convert team names to standard abbreviations if needed
    const teamNameToAbbr = {
        'Lakers': 'LAL',
        'Celtics': 'BOS',
        'Warriors': 'GSW',
        'Bucks': 'MIL',
        '76ers': 'PHI',
        'Knicks': 'NYK',
        'Nets': 'BKN',
        'Raptors': 'TOR',
        'Pistons': 'DET',
        'Cavaliers': 'CLE',
        'Pacers': 'IND',
        'Bulls': 'CHI',
        'Hawks': 'ATL',
        'Heat': 'MIA',
        'Magic': 'ORL',
        'Hornets': 'CHA',
        'Wizards': 'WAS',
        'Nuggets': 'DEN',
        'Jazz': 'UTA',
        'Trail Blazers': 'POR',
        'Thunder': 'OKC',
        'Timberwolves': 'MIN',
        'Mavericks': 'DAL',
        'Rockets': 'HOU',
        'Spurs': 'SAS',
        'Suns': 'PHX',
        'Clippers': 'LAC',
        'Kings': 'SAC',
        'Pelicans': 'NOP',
        'Grizzlies': 'MEM'
    };
    
    // If we have a team name instead of abbreviation, convert it
    if (playerTeam && teamNameToAbbr[playerTeam]) {
        playerTeam = teamNameToAbbr[playerTeam];
    }
    
    // Log the determined team
    console.log("Detected player team:", playerTeam);
    
    // If we have the player's name but not the team, try to infer the team from common players
    if (!playerTeam && playerStats.player && playerStats.player.name) {
        const playerName = playerStats.player.name;
        
        // Mapping of some star players to their teams
        const playerToTeam = {
            "LeBron James": "LAL",
            "Anthony Davis": "LAL",
            "D'Angelo Russell": "LAL",
            "Austin Reaves": "LAL",
            "Stephen Curry": "GSW",
            "Klay Thompson": "GSW",
            "Draymond Green": "GSW",
            "Giannis Antetokounmpo": "MIL",
            "Damian Lillard": "MIL",
            "Khris Middleton": "MIL",
            "Brook Lopez": "MIL",
            "Kevin Durant": "PHX",
            "Devin Booker": "PHX",
            "Bradley Beal": "PHX",
            "Joel Embiid": "PHI",
            "Tyrese Maxey": "PHI",
            "Nikola Jokic": "DEN",
            "Jamal Murray": "DEN",
            "Michael Porter Jr.": "DEN",
            "Luka Doncic": "DAL",
            "Kyrie Irving": "DAL",
            "Jayson Tatum": "BOS",
            "Jaylen Brown": "BOS",
            "Jrue Holiday": "BOS",
            "Jimmy Butler": "MIA",
            "Bam Adebayo": "MIA",
            "Tyler Herro": "MIA",
            "Kawhi Leonard": "LAC",
            "Paul George": "LAC",
            "James Harden": "LAC",
            "Ja Morant": "MEM",
            "Jaren Jackson Jr.": "MEM",
            "Trae Young": "ATL",
            "Dejounte Murray": "ATL",
            "De'Aaron Fox": "SAC",
            "Domantas Sabonis": "SAC",
            "Donovan Mitchell": "CLE",
            "Darius Garland": "CLE",
            "Jarrett Allen": "CLE",
            "Evan Mobley": "CLE",
            "Zion Williamson": "NOP",
            "Brandon Ingram": "NOP",
            "CJ McCollum": "NOP",
            "Victor Wembanyama": "SAS",
            "Shai Gilgeous-Alexander": "OKC",
            "Chet Holmgren": "OKC",
            "Jalen Williams": "OKC",
            "Anthony Edwards": "MIN",
            "Karl-Anthony Towns": "MIN",
            "Rudy Gobert": "MIN",
            "LaMelo Ball": "CHA",
            "Miles Bridges": "CHA",
            "Paolo Banchero": "ORL",
            "Franz Wagner": "ORL",
            "Cade Cunningham": "DET",
            "Jalen Brunson": "NYK",
            "Julius Randle": "NYK",
            "RJ Barrett": "TOR",
            "Immanuel Quickley": "TOR",
            "Scottie Barnes": "TOR", 
            "Pascal Siakam": "IND",
            "Tyrese Haliburton": "IND",
            "Myles Turner": "IND"
        };
        
        if (playerToTeam[playerName]) {
            playerTeam = playerToTeam[playerName];
            console.log("Determined team from player name:", playerTeam);
        }
    }
    
    // Get the team's schedule
    let teamSchedule = fallbackSchedule;
    if (playerTeam) {
        console.log("Fetching schedule for team:", playerTeam);
        const schedule = await fetchTeamSchedule(playerTeam, '2024-2025'); // Use current season
        if (schedule) {
            // Filter for upcoming games and sort by date
            const upcomingGames = schedule
                .filter(game => !game.gameResult) // Filter out games that have already been played
                .sort((a, b) => {
                    const dateA = new Date(a.gameDate);
                    const dateB = new Date(b.gameDate);
                    return dateA - dateB;
                });
            
            if (upcomingGames.length > 0) {
                teamSchedule = upcomingGames;
                console.log("Found upcoming games:", upcomingGames.length);
            }
        }
    }
    
    // Get the next game (first upcoming game in the schedule)
    const nextGame = teamSchedule[0];
    console.log("Next game:", nextGame);
    
    // Add next game info to predictions
    predictions.nextOpponent = nextGame.opponent;
    predictions.nextGameDate = nextGame.gameDate;
    predictions.nextGameTime = nextGame.gameTime || nextGame.startTime || "TBD";
    predictions.isHomeGame = nextGame.gameLocation === 'Home';
    predictions.isFallback = nextGame.isFallback || false;
    predictions.teamDetected = !!playerTeam;
    
    // Find past matchups against the next opponent
    const pastMatchups = playerStats.games.filter(game => 
        game.opponent && game.opponent.includes(nextGame.opponent)
    );
    
    if (pastMatchups.length > 0) {
        // Calculate average stats against this opponent
        const matchupAverages = {
            points: pastMatchups.reduce((sum, game) => sum + parseFloat(game.points || 0), 0) / pastMatchups.length,
            assists: pastMatchups.reduce((sum, game) => sum + parseFloat(game.assists || 0), 0) / pastMatchups.length,
            rebounds: pastMatchups.reduce((sum, game) => sum + parseFloat(game.totalRebounds || game.rebounds || 0), 0) / pastMatchups.length,
            steals: pastMatchups.reduce((sum, game) => sum + parseFloat(game.steals || 0), 0) / pastMatchups.length,
            blocks: pastMatchups.reduce((sum, game) => sum + parseFloat(game.blocks || 0), 0) / pastMatchups.length,
            threePointersMade: pastMatchups.reduce((sum, game) => sum + parseFloat(game.threePointersMade || game.threePointerMade || game.threePtMade || 0), 0) / pastMatchups.length
        };
        
        // Apply matchup-specific adjustments (30% weight to past matchup performance)
        const matchupWeight = 0.3;
        predictions.points = (predictions.points * (1 - matchupWeight)) + (matchupAverages.points * matchupWeight);
        predictions.assists = (predictions.assists * (1 - matchupWeight)) + (matchupAverages.assists * matchupWeight);
        predictions.rebounds = (predictions.rebounds * (1 - matchupWeight)) + (matchupAverages.rebounds * matchupWeight);
        predictions.steals = (predictions.steals * (1 - matchupWeight)) + (matchupAverages.steals * matchupWeight);
        predictions.blocks = (predictions.blocks * (1 - matchupWeight)) + (matchupAverages.blocks * matchupWeight);
        predictions.threePointersMade = (predictions.threePointersMade * (1 - matchupWeight)) + (matchupAverages.threePointersMade * matchupWeight);
        
        // Store matchup data for display
        predictions.pastMatchups = {
            count: pastMatchups.length,
            averages: matchupAverages
        };
        
        // Determine if this is a favorable or unfavorable matchup
        const seasonAvgPoints = playerStats.averages.points || 0;
        const matchupAvgPoints = matchupAverages.points || 0;
        const pointsDiff = matchupAvgPoints - seasonAvgPoints;
        
        if (pointsDiff > 2) {
            predictions.matchupType = "favorable";
        } else if (pointsDiff < -2) {
            predictions.matchupType = "unfavorable";
        }
    }
    
    // Round predictions to 1 decimal place
    Object.keys(predictions).forEach(key => {
        if (typeof predictions[key] === 'number') {
            predictions[key] = Math.round(predictions[key] * 10) / 10;
        }
    });
    
    return predictions;
}; 
import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Alert, Spinner, Tabs, Tab, Table, Badge, Pagination, InputGroup } from 'react-bootstrap';
import { doc, updateDoc, collection, query, where, getDocs, limit, startAfter, orderBy, addDoc, deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../config/firebase';
import './AdminTools.css';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { initEmailJS } from '../utils/emailUtils';
import { sendBulkEmails, sendTrialGrantedNotification, sendSubscriptionExpirationNotification } from '../utils/emailUtils';
import { 
    sendTrialExpirationReminders, 
    processExpiredTrials,
    fixInconsistentSubscriptions 
} from '../utils/scheduledTasks';
import { toast } from 'react-hot-toast';
import ArticleModal from './ArticleModal';

const AdminTools = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [trialDays, setTrialDays] = useState(7);
  const [revokeEmail, setRevokeEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [allUsersLoading, setAllUsersLoading] = useState(false);
  const [result, setResult] = useState({ success: false, message: '', error: false });
  const [revokeResult, setRevokeResult] = useState({ success: false, message: '', error: false });
  const [premiumUsers, setPremiumUsers] = useState([]);
  const [trialUsers, setTrialUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [freeUsers, setFreeUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [activeTab, setActiveTab] = useState('grant');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const usersPerPage = 50;

  // New states for promo codes
  const [promoCode, setPromoCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(10);
  const [expiryDate, setExpiryDate] = useState('');
  const [maxUses, setMaxUses] = useState(100);
  const [promoCodes, setPromoCodes] = useState([]);
  const [promoLoading, setPromoLoading] = useState(false);
  const [promoResult, setPromoResult] = useState({ success: false, message: '', error: false });
  const [deletePromoLoading, setDeletePromoLoading] = useState(false);

  // New states for banner
  const [bannerText, setBannerText] = useState('');
  const [bannerLink, setBannerLink] = useState('');
  const [bannerColor, setBannerColor] = useState('#29abe2');
  const [bannerTextColor, setBannerTextColor] = useState('#ffffff');
  const [bannerActive, setBannerActive] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [bannerResult, setBannerResult] = useState({ success: false, message: '', error: false });
  const [currentBanner, setCurrentBanner] = useState(null);

  // New states for email blast
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailResult, setEmailResult] = useState({ success: false, message: '', error: false });
  const [sendToTrialOnly, setSendToTrialOnly] = useState(false);
  const [sendToPremiumOnly, setSendToPremiumOnly] = useState(false);
  const [sendToFreeOnly, setSendToFreeOnly] = useState(false);
  const [sendingProgress, setSendingProgress] = useState(0);
  const [totalEmailsToSend, setTotalEmailsToSend] = useState(0);
  const [recipientCount, setRecipientCount] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [actionResult, setActionResult] = useState(null);
  const [allUsersLoaded, setAllUsersLoaded] = useState(false);

  // New states for changelogs
  const [showChangelogModal, setShowChangelogModal] = useState(false);
  const [changelogs, setChangelogs] = useState([]);
  const [articleData, setArticleData] = useState(null);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [postToUpdates, setPostToUpdates] = useState(true);

  // ReactQuill editor modules and formats for rich text editing
  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'code-block'],
      ['clean']
    ],
  };
  
  const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'indent',
    'color', 'background',
    'link', 'code-block'
  ];

  // Load necessary data when tab changes
  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers();
      if (!allUsersLoaded) {
        fetchAllUsers();
      }
    } else if (activeTab === 'promoCodes') {
      fetchPromoCodes();
    } else if (activeTab === 'banner') {
      fetchBanner();
    } else if (activeTab === 'emailBlast') {
      // Make sure we have all user data for the email blast
      if (!allUsersLoaded) {
        fetchAllUsers();
      }
    }
  }, [activeTab, allUsersLoaded]);

  // Filter users when search query changes
  useEffect(() => {
    if (allUsers.length > 0 && searchQuery) {
      const lowercaseQuery = searchQuery.toLowerCase();
      const filtered = allUsers.filter(user => 
        user.email?.toLowerCase().includes(lowercaseQuery) || 
        user.displayName?.toLowerCase().includes(lowercaseQuery)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(allUsers);
    }
  }, [searchQuery, allUsers]);

  // Update recipient count whenever filters or user lists change
  useEffect(() => {
    if (sendToTrialOnly) {
      setRecipientCount(trialUsers.filter(user => user.email).length);
    } else if (sendToPremiumOnly) {
      setRecipientCount(premiumUsers.filter(user => user.email).length);
    } else if (sendToFreeOnly) {
      setRecipientCount(freeUsers.filter(user => user.email).length);
    } else {
      setRecipientCount(allUsers.filter(user => user.email).length);
    }
  }, [sendToTrialOnly, sendToPremiumOnly, sendToFreeOnly, trialUsers, premiumUsers, freeUsers, allUsers]);

  // New useEffect for fetching changelogs
  useEffect(() => {
    const fetchChangelogs = async () => {
      try {
        const changelogsRef = collection(firestore, 'changelogs');
        const q = query(changelogsRef, orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(q);
        const changelogsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setChangelogs(changelogsData);
      } catch (error) {
        console.error('Error fetching changelogs:', error);
      }
    };

    fetchChangelogs();
  }, []);

  const fetchUsers = async () => {
    setUsersLoading(true);
    try {
      const usersRef = collection(firestore, 'users');
      
      // Query for premium users
      const premiumQuery = query(usersRef, where('subscription.type', '==', 'premium'));
      const premiumSnapshot = await getDocs(premiumQuery);
      const premiumData = premiumSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Query for trial users
      const trialQuery = query(usersRef, where('subscription.plan', '==', 'premium-trial'));
      const trialSnapshot = await getDocs(trialQuery);
      const trialData = trialSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Query for admin users - check both isAdmin and role fields
      const adminQuery1 = query(usersRef, where('isAdmin', '==', true));
      const adminQuery2 = query(usersRef, where('role', '==', 'admin'));
      
      const [adminSnapshot1, adminSnapshot2] = await Promise.all([
        getDocs(adminQuery1),
        getDocs(adminQuery2)
      ]);
      
      // Combine admin users from both queries, avoiding duplicates
      const adminIds = new Set();
      const adminData = [];
      
      // Process first query results
      adminSnapshot1.docs.forEach(doc => {
        const userData = {
          id: doc.id,
          ...doc.data()
        };
        adminIds.add(doc.id);
        adminData.push(userData);
      });
      
      // Process second query results, avoiding duplicates
      adminSnapshot2.docs.forEach(doc => {
        if (!adminIds.has(doc.id)) {
          const userData = {
            id: doc.id,
            ...doc.data()
          };
          adminData.push(userData);
        }
      });
      
      // Query for free users
      const freeQuery = query(usersRef, where('subscription.type', '==', 'free'));
      const freeSnapshot = await getDocs(freeQuery);
      let freeData = freeSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Also get users without subscription field
      const allUsersSnapshot = await getDocs(usersRef);
      const usersWithoutSubscription = allUsersSnapshot.docs
        .filter(doc => {
          const data = doc.data();
          return !data.subscription || !data.subscription.type;
        })
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      
      // Combine free users and users without subscription
      freeData = [...freeData, ...usersWithoutSubscription];
      
      // Filter out admin users from free users
      const adminEmails = new Set(adminData.map(user => user.email));
      freeData = freeData.filter(user => !adminEmails.has(user.email));
      
      setPremiumUsers(premiumData.filter(user => user.subscription?.plan !== 'premium-trial'));
      setTrialUsers(trialData);
      setAdminUsers(adminData);
      setFreeUsers(freeData);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setUsersLoading(false);
    }
  };

  const fetchAllUsers = async (reset = true) => {
    try {
      setAllUsersLoading(true);
      
      if (reset) {
        setAllUsers([]);
        setLastVisible(null);
        setCurrentPage(1);
      }
      
      const usersRef = collection(firestore, 'users');
      let usersQuery;
      
      if (lastVisible && !reset) {
        usersQuery = query(usersRef, orderBy('email'), startAfter(lastVisible), limit(50));
      } else {
        usersQuery = query(usersRef, orderBy('email'), limit(50));
      }
      
      const querySnapshot = await getDocs(usersQuery);
      
      if (!querySnapshot.empty) {
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(lastDoc);
        
        const newUsers = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setAllUsers(prev => reset ? newUsers : [...prev, ...newUsers]);
        setFilteredUsers(prev => reset ? newUsers : [...prev, ...newUsers]);
        setAllUsersLoaded(true);
      } else {
        // No more users
        setLastVisible(null);
        setAllUsersLoaded(true);
      }
    } catch (error) {
      console.error('Error fetching all users:', error);
    } finally {
      setAllUsersLoading(false);
    }
  };

  const loadMoreUsers = () => {
    if (!allUsersLoading && lastVisible) {
      setCurrentPage(prevPage => prevPage + 1);
      fetchAllUsers(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getUserSubscriptionBadge = (user) => {
    // Check for admin status using both isAdmin and role fields
    if (user.isAdmin === true || user.role === 'admin') {
      return <Badge bg="warning" text="dark" className="badge-admin">Admin</Badge>;
    } else if (user.subscription?.type === 'premium' && user.subscription?.plan !== 'premium-trial') {
      return <Badge bg="success" className="badge-premium">Premium</Badge>;
    } else if (user.subscription?.plan === 'premium-trial') {
      return <Badge bg="info" className="badge-trial">Trial</Badge>;
    } else {
      return <Badge bg="secondary" className="badge-free">Free</Badge>;
    }
  };

  const handleGrantTrial = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult({ success: false, message: '', error: false });

    try {
      // First, find the user by email
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', userEmail));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        setResult({
          success: false,
          message: `No user found with email: ${userEmail}`,
          error: true
        });
        setLoading(false);
        return;
      }
      
      // Get the user document
      const userDoc = querySnapshot.docs[0];
      const userId = userDoc.id;
      const userData = userDoc.data();
      
      // Calculate trial end date (X days from now)
      const trialEndDate = new Date();
      trialEndDate.setDate(trialEndDate.getDate() + parseInt(trialDays, 10));
      
      // Update the user's subscription data
      const userRef = doc(firestore, 'users', userId);
      
      const subscriptionData = {
        subscription: {
          status: 'active',
          type: 'premium',
          startDate: new Date().toISOString(),
          trialEndDate: trialEndDate.toISOString(),
          updatedAt: new Date().toISOString(),
          plan: 'premium-trial'
        }
      };
      
      await updateDoc(userRef, subscriptionData);
      
      // Send trial granted notification email
      await sendTrialGrantedNotification({
        email: userEmail,
        displayName: userData.displayName,
        trialDays: parseInt(trialDays, 10),
        subscription: subscriptionData.subscription
      });
      
      setResult({
        success: true,
        message: `Successfully granted ${trialDays}-day trial to user: ${userEmail}`,
        error: false
      });
    } catch (error) {
      console.error('Error granting trial membership:', error);
      setResult({
        success: false,
        message: `Error: ${error.message}`,
        error: true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRevokeMembership = async (e) => {
    e.preventDefault();
    setRevokeLoading(true);
    setRevokeResult({ success: false, message: '', error: false });

    try {
      // Find the user by email
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', revokeEmail));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        setRevokeResult({
          success: false,
          message: `No user found with email: ${revokeEmail}`,
          error: true
        });
        setRevokeLoading(false);
        return;
      }
      
      // Get the user document
      const userDoc = querySnapshot.docs[0];
      const userId = userDoc.id;
      const userData = userDoc.data();
      
      // Update the user's subscription data to free
      const userRef = doc(firestore, 'users', userId);
      
      const subscriptionData = {
        subscription: {
          status: 'inactive',
          type: 'free',
          updatedAt: new Date().toISOString(),
          plan: 'free'
        }
      };
      
      await updateDoc(userRef, subscriptionData);
      
      // Send subscription expiration notification
      await sendSubscriptionExpirationNotification({
        email: revokeEmail,
        displayName: userData.displayName,
        subscription: subscriptionData.subscription
      });
      
      setRevokeResult({
        success: true,
        message: `Successfully revoked membership for user: ${revokeEmail}`,
        error: false
      });
    } catch (error) {
      console.error('Error revoking membership:', error);
      setRevokeResult({
        success: false,
        message: `Error: ${error.message}`,
        error: true
      });
    } finally {
      setRevokeLoading(false);
    }
  };

  const handleRevokeFromList = async (email) => {
    setRevokeEmail(email);
    setActiveTab('revoke');
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Fetch promo codes from Firestore
  const fetchPromoCodes = async () => {
    setPromoLoading(true);
    try {
      const promoCodesRef = collection(firestore, 'promoCodes');
      const snapshot = await getDocs(promoCodesRef);
      const promoCodesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPromoCodes(promoCodesData);
    } catch (error) {
      console.error('Error fetching promo codes:', error);
    } finally {
      setPromoLoading(false);
    }
  };

  // Fetch current banner from Firestore
  const fetchBanner = async () => {
    setBannerLoading(true);
    try {
      const bannerRef = doc(firestore, 'settings', 'banner');
      const bannerDoc = await getDoc(bannerRef);
      
      if (bannerDoc.exists()) {
        const bannerData = bannerDoc.data();
        setCurrentBanner(bannerData);
        setBannerText(bannerData.text || '');
        setBannerLink(bannerData.link || '');
        setBannerColor(bannerData.backgroundColor || '#29abe2');
        setBannerTextColor(bannerData.textColor || '#ffffff');
        setBannerActive(bannerData.active || false);
      } else {
        setCurrentBanner(null);
        setBannerText('');
        setBannerLink('');
        setBannerColor('#29abe2');
        setBannerTextColor('#ffffff');
        setBannerActive(false);
      }
    } catch (error) {
      console.error('Error fetching banner:', error);
    } finally {
      setBannerLoading(false);
    }
  };

  // Add a new promo code
  const handleAddPromoCode = async (e) => {
    e.preventDefault();
    setPromoLoading(true);
    setPromoResult({ success: false, message: '', error: false });

    try {
      // Validate inputs
      if (!promoCode.trim()) {
        throw new Error('Promo code cannot be empty');
      }

      if (discountPercentage <= 0 || discountPercentage > 100) {
        throw new Error('Discount percentage must be between 1 and 100');
      }

      // Check if promo code already exists
      const promoCodesRef = collection(firestore, 'promoCodes');
      const q = query(promoCodesRef, where('code', '==', promoCode.toUpperCase()));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        throw new Error(`Promo code ${promoCode.toUpperCase()} already exists`);
      }

      // Create expiry date if provided
      let expiry = null;
      if (expiryDate) {
        expiry = new Date(expiryDate).toISOString();
      }

      // Add the promo code to Firestore
      await addDoc(promoCodesRef, {
        code: promoCode.toUpperCase(),
        discountPercentage: Number(discountPercentage),
        expiryDate: expiry,
        maxUses: Number(maxUses),
        usedCount: 0,
        createdAt: new Date().toISOString(),
        active: true
      });

      // Reset form and show success message
      setPromoCode('');
      setDiscountPercentage(10);
      setExpiryDate('');
      setMaxUses(100);
      setPromoResult({
        success: true,
        message: `Successfully added promo code: ${promoCode.toUpperCase()}`,
        error: false
      });

      // Refresh promo codes list
      fetchPromoCodes();
    } catch (error) {
      console.error('Error adding promo code:', error);
      setPromoResult({
        success: false,
        message: `Error: ${error.message}`,
        error: true
      });
    } finally {
      setPromoLoading(false);
    }
  };

  // Delete a promo code
  const handleDeletePromoCode = async (id) => {
    setDeletePromoLoading(true);
    try {
      const promoCodeRef = doc(firestore, 'promoCodes', id);
      await deleteDoc(promoCodeRef);
      
      // Refresh promo codes list
      fetchPromoCodes();
    } catch (error) {
      console.error('Error deleting promo code:', error);
      setPromoResult({
        success: false,
        message: `Error deleting promo code: ${error.message}`,
        error: true
      });
    } finally {
      setDeletePromoLoading(false);
    }
  };

  // Toggle promo code active status
  const handleTogglePromoCode = async (id, currentActive) => {
    try {
      const promoCodeRef = doc(firestore, 'promoCodes', id);
      await updateDoc(promoCodeRef, {
        active: !currentActive
      });
      
      // Refresh promo codes list
      fetchPromoCodes();
    } catch (error) {
      console.error('Error toggling promo code status:', error);
      setPromoResult({
        success: false,
        message: `Error updating promo code: ${error.message}`,
        error: true
      });
    }
  };

  // Save banner settings
  const handleSaveBanner = async (e) => {
    e.preventDefault();
    setBannerLoading(true);
    setBannerResult({ success: false, message: '', error: false });

    try {
      const bannerRef = doc(firestore, 'settings', 'banner');
      
      const bannerData = {
        text: bannerText,
        link: bannerLink,
        backgroundColor: bannerColor,
        textColor: bannerTextColor,
        active: bannerActive,
        updatedAt: new Date().toISOString()
      };
      
      await setDoc(bannerRef, bannerData);
      
      setCurrentBanner(bannerData);
      setBannerResult({
        success: true,
        message: 'Banner settings saved successfully',
        error: false
      });
    } catch (error) {
      console.error('Error saving banner settings:', error);
      setBannerResult({
        success: false,
        message: `Error: ${error.message}`,
        error: true
      });
    } finally {
      setBannerLoading(false);
    }
  };

  // Function to navigate to security monitoring dashboard
  const goToSecurityDashboard = () => {
    navigate('/admin/security-monitoring');
  };

  // Function to send email blast to all users
  const handleSendEmailBlast = async (e) => {
    e.preventDefault();
    
    if (!emailSubject.trim() || !emailContent.trim()) {
      setEmailResult({
        success: false,
        message: 'Email subject and content are required',
        error: true
      });
      return;
    }
    
    setEmailLoading(true);
    setEmailResult({ success: false, message: '', error: false });
    setSendingProgress(0);
    
    try {
      // Initialize EmailJS
      initEmailJS();
      console.log('EmailJS initialized for bulk emails - using template EMAILJS_BULK_TEMPLATE_ID');
      
      // IMPORTANT: For bulk emails to work, you must:
      // 1. Create a specific template in EmailJS dashboard for bulk sending
      // 2. Set the "To Email" field to {{to_email}} in the template settings
      // 3. Make sure EMAILJS_BULK_TEMPLATE_ID in emailUtils.js contains your template ID
      
      // Create a list of recipient users based on filters
      let recipients = [...allUsers];
      
      if (sendToTrialOnly) {
        recipients = recipients.filter(user => user.subscription?.plan === 'premium-trial');
      } else if (sendToPremiumOnly) {
        recipients = recipients.filter(user => user.subscription?.type === 'premium' && user.subscription?.plan !== 'premium-trial');
      } else if (sendToFreeOnly) {
        recipients = recipients.filter(user => 
          user.subscription?.type === 'free' || !user.subscription || !user.subscription.type
        );
      }
      
      // Filter out users without email addresses
      recipients = recipients.filter(user => user.email);
      console.log(`Sending email blast to ${recipients.length} recipients`);
      
      // Store the email blast in Firestore for record-keeping
      const emailBlastRef = collection(firestore, 'emailBlasts');
      await addDoc(emailBlastRef, {
        subject: emailSubject,
        content: emailContent,
        sentTo: recipients.length,
        sentToTrialOnly: sendToTrialOnly,
        sentToPremiumOnly: sendToPremiumOnly,
        sentToFreeOnly: sendToFreeOnly,
        sentAt: new Date().toISOString(),
        sentBy: 'admin' // Could be replaced with actual admin user info
      });
      
      // Set the total number of emails to send for progress tracking
      setTotalEmailsToSend(recipients.length);
      
      // Use the batch email utility to send emails
      const results = await sendBulkEmails(
        recipients, 
        emailSubject, 
        emailContent,
        (progress) => {
          setSendingProgress(progress);
        }
      );
      
      setEmailResult({
        success: true,
        message: `Successfully sent email blast to ${results.success} users (${results.failed} failed)`,
        error: false
      });
      
      // Reset form after successful send
      setEmailSubject('');
      setEmailContent('');
      setSendToTrialOnly(false);
      setSendToPremiumOnly(false);
      setSendToFreeOnly(false);
      
    } catch (error) {
      console.error('Error sending email blast:', error);
      setEmailResult({
        success: false,
        message: `Error: ${error.message}`,
        error: true
      });
    } finally {
      setEmailLoading(false);
    }
  };

  // Handle toggling preview mode for email
  const togglePreviewMode = () => {
    setPreviewMode(!previewMode);
  };

  // Handle file attachment
  const handleFileAttachment = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (limit to 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setEmailResult({
          success: false,
          message: 'File size exceeds 5MB limit',
          error: true
        });
        return;
      }
      
      setAttachment(file);
      
      // Reset any previous error messages
      if (emailResult.error && emailResult.message.includes('file')) {
        setEmailResult({ success: false, message: '', error: false });
      }
    }
  };
  
  // Remove attachment
  const handleRemoveAttachment = () => {
    setAttachment(null);
  };

  const handleFixInconsistentSubscriptions = async () => {
    try {
        setLoading(true);
        setActionResult(null);
        const result = await fixInconsistentSubscriptions();
        setActionResult(`Fixed ${result.fixed} inconsistent subscriptions`);
    } catch (error) {
        console.error('Error fixing inconsistent subscriptions:', error);
        setActionResult(`Error: ${error.message}`);
    } finally {
        setLoading(false);
    }
  };

  // New handler function for creating a new changelog
  const handleCreateChangelog = () => {
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const template = `# Version X.X.X (${currentDate})

⚠️ Upgrade Steps

    - List any required steps for upgrading beyond dependency updates

    - Code changes required:

        \`\`\`JavaScript
        // Old code
        // New code
        \`\`\`

    - Recommended upgrades due to known issues: None

🚨 Breaking Changes

    - None

🚀 New Features

    - Feature name
        - Description of when/why to use it
        - Usage example
        - Beta/Experimental? (if applicable)
        - Known limitations or caveats

🐛 Bug Fixes

    - Issue description
        - What was the problem?
        - How was it fixed?
        - Any related issue numbers

🔧 Improvements

    - Area of improvement
        - What changed?
        - Benefits/impact
        - Performance metrics (if applicable)

📝 Other Changes

    - Miscellaneous updates`;
    
    setArticleData({
      title: 'New Release Notes',
      content: template,
      type: 'changelog',
      createdAt: new Date().toISOString()
    });
    setShowArticleModal(true);
  };

  // New handler function for saving a changelog
  const handleSaveChangelog = async (changelogData) => {
    try {
        const changelogsRef = collection(firestore, 'changelogs');
        const timestamp = new Date().toISOString();
        
        // Save to changelogs collection
        const changelogDoc = await addDoc(changelogsRef, {
            ...changelogData,
            createdAt: timestamp,
            updatedAt: timestamp
        });
        
        // If postToUpdates is true, also save to updates collection
        if (postToUpdates) {
            const updatesRef = collection(firestore, 'updates');
            await addDoc(updatesRef, {
                title: changelogData.title,
                content: changelogData.content,
                type: 'changelog',
                changelogId: changelogDoc.id,
                createdAt: timestamp,
                updatedAt: timestamp
            });
        }
        
        // Refresh changelogs
        const q = query(changelogsRef, orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(q);
        const updatedChangelogs = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setChangelogs(updatedChangelogs);
        
        toast.success('Changelog saved successfully!');
    } catch (error) {
        console.error('Error saving changelog:', error);
        toast.error('Failed to save changelog');
    }
  };

  // New handler function for deleting a changelog
  const handleDeleteChangelog = async (changelogId) => {
    if (!window.confirm('Are you sure you want to delete this changelog?')) {
      return;
    }

    try {
      // Delete from changelogs collection
      const changelogRef = doc(firestore, 'changelogs', changelogId);
      await deleteDoc(changelogRef);

      // Delete from updates collection if it exists there
      const updatesRef = collection(firestore, 'updates');
      const q = query(updatesRef, where('changelogId', '==', changelogId));
      const snapshot = await getDocs(q);
      
      // Delete any matching updates
      const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      // Update local state
      setChangelogs(prevChangelogs => prevChangelogs.filter(log => log.id !== changelogId));
      toast.success('Changelog deleted successfully!');
    } catch (error) {
      console.error('Error deleting changelog:', error);
      toast.error('Failed to delete changelog');
    }
  };

  return (
    <Card className="admin-tools-card">
      <Card.Header>
        <h4>Admin Tools</h4>
      </Card.Header>
      <Card.Body>
        <Tabs 
          defaultActiveKey="grant" 
          id="admin-tools-tabs" 
          className="mb-3"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          <Tab eventKey="grant" title="Grant Trial">
            <h5>Grant Trial Membership</h5>
            <p className="text-muted">Use this tool to grant trial membership to a registered user.</p>
            
            {result.message && (
              <Alert variant={result.error ? 'danger' : 'success'}>
                {result.message}
              </Alert>
            )}
            
            <Form onSubmit={handleGrantTrial}>
              <Form.Group className="mb-3">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="user@example.com"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  required
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Trial Duration (days)</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="30"
                  value={trialDays}
                  onChange={(e) => setTrialDays(e.target.value)}
                  required
                />
              </Form.Group>
              
              <Button 
                variant="primary" 
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Processing...
                  </>
                ) : (
                  'Grant Trial'
                )}
              </Button>
            </Form>
          </Tab>
          
          <Tab eventKey="revoke" title="Revoke Membership">
            <h5>Revoke Membership</h5>
            <p className="text-muted">Use this tool to revoke a user's premium or trial membership.</p>
            
            {revokeResult.message && (
              <Alert variant={revokeResult.error ? 'danger' : 'success'}>
                {revokeResult.message}
              </Alert>
            )}
            
            <Form onSubmit={handleRevokeMembership}>
              <Form.Group className="mb-3">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="user@example.com"
                  value={revokeEmail}
                  onChange={(e) => setRevokeEmail(e.target.value)}
                  required
                />
              </Form.Group>
              
              <Button 
                variant="danger" 
                type="submit"
                disabled={revokeLoading}
              >
                {revokeLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Processing...
                  </>
                ) : (
                  'Revoke Membership'
                )}
              </Button>
            </Form>
          </Tab>

          <Tab eventKey="users" title="View Users">
            <h5>User Management</h5>
            <p className="text-muted">View and manage users with different access levels.</p>
            
            {usersLoading ? (
              <div className="text-center my-4">
                <Spinner animation="border" variant="primary" />
                <p className="mt-2">Loading users...</p>
              </div>
            ) : (
              <>
                <h6 className="mt-4 mb-3">Admin Users ({adminUsers.length})</h6>
                {adminUsers.length > 0 ? (
                  <div className="table-responsive">
                    <Table variant="dark" striped bordered hover className="user-table">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Name</th>
                          <th>Last Login</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminUsers.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.displayName || 'N/A'}</td>
                            <td>{user.lastLogin ? formatDate(user.lastLogin) : 'N/A'}</td>
                            <td>
                              <Badge bg="warning" text="dark" className="badge-admin">Admin</Badge>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Alert variant="info">No admin users found.</Alert>
                )}

                <h6 className="mt-4 mb-3">Premium Users ({premiumUsers.length})</h6>
                {premiumUsers.length > 0 ? (
                  <div className="table-responsive">
                    <Table variant="dark" striped bordered hover className="user-table">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Name</th>
                          <th>Start Date</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {premiumUsers.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.displayName || 'N/A'}</td>
                            <td>{formatDate(user.subscription?.startDate)}</td>
                            <td>
                              <Badge bg="success" className="badge-premium">Premium</Badge>
                            </td>
                            <td>
                              <Button 
                                variant="outline-danger" 
                                size="sm"
                                onClick={() => handleRevokeFromList(user.email)}
                              >
                                Revoke
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Alert variant="info">No premium users found.</Alert>
                )}

                <h6 className="mt-4 mb-3">Trial Users ({trialUsers.length})</h6>
                {trialUsers.length > 0 ? (
                  <div className="table-responsive">
                    <Table variant="dark" striped bordered hover className="user-table">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Name</th>
                          <th>Start Date</th>
                          <th>Trial End Date</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {trialUsers.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.displayName || 'N/A'}</td>
                            <td>{formatDate(user.subscription?.startDate)}</td>
                            <td>{formatDate(user.subscription?.trialEndDate)}</td>
                            <td>
                              <Badge bg="info" className="badge-trial">Trial</Badge>
                            </td>
                            <td>
                              <Button 
                                variant="outline-danger" 
                                size="sm"
                                onClick={() => handleRevokeFromList(user.email)}
                              >
                                Revoke
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Alert variant="info">No trial users found.</Alert>
                )}

                <h6 className="mt-4 mb-3">Free Users ({freeUsers.length})</h6>
                {freeUsers.length > 0 ? (
                  <div className="table-responsive">
                    <Table variant="dark" striped bordered hover className="user-table">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Name</th>
                          <th>Created Date</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {freeUsers.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.displayName || 'N/A'}</td>
                            <td>{formatDate(user.createdAt)}</td>
                            <td>
                              <Badge bg="secondary" className="badge-free">Free</Badge>
                            </td>
                            <td>
                              <Button 
                                variant="outline-success" 
                                size="sm"
                                onClick={() => {
                                  setUserEmail(user.email);
                                  setActiveTab('grant');
                                }}
                              >
                                Grant Trial
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Alert variant="info">No free users found.</Alert>
                )}

                <div className="mt-3">
                  <Button 
                    variant="outline-primary" 
                    onClick={fetchUsers}
                    disabled={usersLoading}
                  >
                    {usersLoading ? 'Refreshing...' : 'Refresh List'}
                  </Button>
                </div>
              </>
            )}
          </Tab>

          <Tab eventKey="allUsers" title="All Users">
            <h5>All Users</h5>
            <p className="text-muted">View and manage all registered users in the system.</p>
            
            <Form className="mb-4">
              <Form.Group>
                <Form.Label>Search Users</Form.Label>
                <div className="search-box">
                  <Form.Control
                    type="text"
                    placeholder="Search by email or name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <span className="search-icon">🔍</span>
                </div>
              </Form.Group>
            </Form>
            
            {allUsersLoading && allUsers.length === 0 ? (
              <div className="text-center my-4">
                <Spinner animation="border" variant="primary" />
                <p className="mt-2">Loading users...</p>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h6 className="user-count">Total Users: {totalUsers}</h6>
                  <div className="d-flex align-items-center">
                    <span className="page-info me-2">Page {currentPage}</span>
                    <Button 
                      variant="outline-primary" 
                      size="sm"
                      onClick={() => fetchAllUsers(true)}
                      disabled={allUsersLoading}
                    >
                      Refresh
                    </Button>
                  </div>
                </div>
                
                {filteredUsers.length > 0 ? (
                  <div className="table-responsive">
                    <Table variant="dark" striped bordered hover className="user-table">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Name</th>
                          <th>Created</th>
                          <th>Last Login</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.map(user => (
                          <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.displayName || 'N/A'}</td>
                            <td>{user.createdAt ? formatDate(user.createdAt) : 'N/A'}</td>
                            <td>{user.lastLogin ? formatDate(user.lastLogin) : 'N/A'}</td>
                            <td>
                              {getUserSubscriptionBadge(user)}
                            </td>
                            <td>
                              {user.subscription?.type !== 'free' && (
                                <Button 
                                  variant="outline-danger" 
                                  size="sm"
                                  onClick={() => handleRevokeFromList(user.email)}
                                  className="me-2"
                                >
                                  Revoke
                                </Button>
                              )}
                              {user.subscription?.type === 'free' && (
                                <Button 
                                  variant="outline-success" 
                                  size="sm"
                                  onClick={() => {
                                    setUserEmail(user.email);
                                    setActiveTab('grant');
                                  }}
                                >
                                  Grant Trial
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Alert variant="info">
                    {searchQuery ? 'No users match your search.' : 'No users found.'}
                  </Alert>
                )}
                
                {allUsers.length < totalUsers && (
                  <div className="text-center mt-3">
                    <Button 
                      variant="outline-primary" 
                      onClick={loadMoreUsers}
                      disabled={allUsersLoading}
                      className="load-more-btn"
                    >
                      {allUsersLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                          Loading...
                        </>
                      ) : (
                        'Load More'
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
          </Tab>

          <Tab eventKey="maintenance" title="System Maintenance">
            <h5>System Maintenance</h5>
            <p className="text-muted">Use these tools to maintain the system and fix data inconsistencies.</p>
            
            {actionResult && (
              <Alert variant="info" className="mb-3">
                {actionResult}
              </Alert>
            )}
            
            <div className="mb-3">
              <h6>Subscription Data</h6>
              <Button 
                variant="warning" 
                onClick={handleFixInconsistentSubscriptions} 
                disabled={loading}
                className="me-2 mb-2"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Processing...
                  </>
                ) : (
                  <>
                    <i className="fas fa-tools me-2"></i>
                    Fix Inconsistent Subscriptions
                  </>
                )}
              </Button>
              <small className="d-block text-muted">
                This will find and fix trial subscriptions that have expired but still show "active" status.
              </small>
            </div>
          </Tab>

          <Tab eventKey="promoCodes" title="Promo Codes">
            <h5>Promo Code Management</h5>
            <p className="text-muted">Create and manage discount promo codes for premium subscriptions.</p>
            
            {promoResult.message && (
              <Alert variant={promoResult.error ? 'danger' : 'success'}>
                {promoResult.message}
              </Alert>
            )}
            
            <Form onSubmit={handleAddPromoCode} className="mb-4">
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Promo Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="SUMMER2023"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      required
                    />
                    <Form.Text className="text-muted">
                      Code will be automatically converted to uppercase
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Discount Percentage</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min="1"
                        max="100"
                        value={discountPercentage}
                        onChange={(e) => setDiscountPercentage(e.target.value)}
                        required
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Expiry Date (Optional)</Form.Label>
                    <Form.Control
                      type="date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Maximum Uses</Form.Label>
                    <Form.Control
                      type="number"
                      min="1"
                      value={maxUses}
                      onChange={(e) => setMaxUses(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
              
              <Button 
                variant="primary" 
                type="submit"
                disabled={promoLoading}
              >
                {promoLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Processing...
                  </>
                ) : (
                  'Add Promo Code'
                )}
              </Button>
            </Form>
            
            <h6 className="mt-4 mb-3">Active Promo Codes</h6>
            {promoLoading && promoCodes.length === 0 ? (
              <div className="text-center my-4">
                <Spinner animation="border" variant="primary" />
                <p className="mt-2">Loading promo codes...</p>
              </div>
            ) : (
              <>
                {promoCodes.length > 0 ? (
                  <div className="table-responsive">
                    <Table variant="dark" striped bordered hover className="user-table">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>Discount</th>
                          <th>Expiry Date</th>
                          <th>Uses</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {promoCodes.map(code => (
                          <tr key={code.id}>
                            <td><strong>{code.code}</strong></td>
                            <td>{code.discountPercentage}%</td>
                            <td>{code.expiryDate ? formatDate(code.expiryDate) : 'No expiry'}</td>
                            <td>{code.usedCount || 0} / {code.maxUses}</td>
                            <td>
                              {code.active ? (
                                <Badge bg="success">Active</Badge>
                              ) : (
                                <Badge bg="secondary">Inactive</Badge>
                              )}
                            </td>
                            <td>
                              <Button 
                                variant={code.active ? "outline-warning" : "outline-success"} 
                                size="sm"
                                onClick={() => handleTogglePromoCode(code.id, code.active)}
                                className="me-2"
                              >
                                {code.active ? 'Deactivate' : 'Activate'}
                              </Button>
                              <Button 
                                variant="outline-danger" 
                                size="sm"
                                onClick={() => handleDeletePromoCode(code.id)}
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Alert variant="info">No promo codes found. Create your first promo code above.</Alert>
                )}
                
                <div className="mt-3">
                  <Button 
                    variant="outline-primary" 
                    onClick={fetchPromoCodes}
                    disabled={promoLoading}
                  >
                    {promoLoading ? 'Refreshing...' : 'Refresh List'}
                  </Button>
                </div>
              </>
            )}
          </Tab>

          <Tab eventKey="banner" title="Banner">
            <h5>Banner Management</h5>
            <p className="text-muted">Create and manage a promotional banner that appears at the top of the site.</p>
            
            {bannerResult.message && (
              <Alert variant={bannerResult.error ? 'danger' : 'success'}>
                {bannerResult.message}
              </Alert>
            )}
            
            <Form onSubmit={handleSaveBanner}>
              <div className="row">
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Label>Banner Text</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Get 20% off with code SUMMER2023!"
                      value={bannerText}
                      onChange={(e) => setBannerText(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Banner Active</Form.Label>
                    <div>
                      <Form.Check 
                        type="switch"
                        id="banner-active-switch"
                        label={bannerActive ? "Banner is visible" : "Banner is hidden"}
                        checked={bannerActive}
                        onChange={(e) => setBannerActive(e.target.checked)}
                        className="mt-2"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Link URL (Optional)</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder="https://example.com/subscribe"
                      value={bannerLink}
                      onChange={(e) => setBannerLink(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                      Leave empty for non-clickable banner
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Background Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={bannerColor}
                      onChange={(e) => setBannerColor(e.target.value)}
                      title="Choose banner background color"
                      className="w-100"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Group className="mb-3">
                    <Form.Label>Text Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={bannerTextColor}
                      onChange={(e) => setBannerTextColor(e.target.value)}
                      title="Choose banner text color"
                      className="w-100"
                    />
                  </Form.Group>
                </div>
              </div>
              
              <div className="banner-preview mb-4" style={{ backgroundColor: bannerColor, color: bannerTextColor }}>
                <div className="banner-preview-content">
                  {bannerText || 'Banner Preview'}
                  {bannerLink && <span className="banner-preview-link">→</span>}
                </div>
              </div>
              
              <Button 
                variant="primary" 
                type="submit"
                disabled={bannerLoading}
              >
                {bannerLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Saving...
                  </>
                ) : (
                  'Save Banner Settings'
                )}
              </Button>
            </Form>
          </Tab>

          <Tab eventKey="emailBlast" title="Email Blast">
            <h5>Email Blast</h5>
            <p className="text-muted">Send an email to all users or a filtered subset of users.</p>
            
            {emailResult.message && (
              <Alert variant={emailResult.error ? 'danger' : 'success'}>
                {emailResult.message}
              </Alert>
            )}
            
            {emailLoading && sendingProgress > 0 && (
              <div className="mb-3">
                <p>Sending emails: {sendingProgress}% complete</p>
                <div className="progress">
                  <div 
                    className="progress-bar" 
                    role="progressbar" 
                    style={{ width: `${sendingProgress}%` }} 
                    aria-valuenow={sendingProgress} 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            )}
            
            <Form onSubmit={handleSendEmailBlast}>
              <div className="row">
                <div className="col-md-8">
                  <Form.Group className="mb-3">
                    <Form.Label>Email Subject</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email subject"
                      value={emailSubject}
                      onChange={(e) => setEmailSubject(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Send To</Form.Label>
                    <div>
                      <Form.Check 
                        type="checkbox"
                        id="send-to-trial"
                        label="Trial users only"
                        checked={sendToTrialOnly}
                        onChange={(e) => {
                          // Set the new value
                          setSendToTrialOnly(e.target.checked);
                          
                          // If checking this box, uncheck others
                          if (e.target.checked) {
                            setSendToPremiumOnly(false);
                            setSendToFreeOnly(false);
                          }
                        }}
                        className="mb-2"
                      />
                      <Form.Check 
                        type="checkbox"
                        id="send-to-premium"
                        label="Premium users only"
                        checked={sendToPremiumOnly}
                        onChange={(e) => {
                          // Set the new value
                          setSendToPremiumOnly(e.target.checked);
                          
                          // If checking this box, uncheck others
                          if (e.target.checked) {
                            setSendToTrialOnly(false);
                            setSendToFreeOnly(false);
                          }
                        }}
                        className="mb-2"
                      />
                      <Form.Check 
                        type="checkbox"
                        id="send-to-free"
                        label="Free users only"
                        checked={sendToFreeOnly}
                        onChange={(e) => {
                          // Set the new value
                          setSendToFreeOnly(e.target.checked);
                          
                          // If checking this box, uncheck others
                          if (e.target.checked) {
                            setSendToTrialOnly(false);
                            setSendToPremiumOnly(false);
                          }
                        }}
                      />
                      {!sendToTrialOnly && !sendToPremiumOnly && !sendToFreeOnly && (
                        <div className="text-muted small">
                          <em>All users will receive this email</em>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
              
              <Form.Group className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <Form.Label>Email Content</Form.Label>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={togglePreviewMode}
                  >
                    {previewMode ? 'Edit Content' : 'Preview Email'}
                  </Button>
                </div>
                
                {previewMode ? (
                  <div className="email-preview-container p-3 border rounded">
                    <h5>{emailSubject || 'Email Subject'}</h5>
                    <hr />
                    <div
                      className="email-content-preview"
                      dangerouslySetInnerHTML={{ __html: emailContent }}
                    />
                    
                    {attachment && (
                      <div className="attachment-preview mt-3 pt-3 border-top">
                        <p><strong>Attachment:</strong> {attachment.name} ({Math.round(attachment.size / 1024)} KB)</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="rich-editor-container">
                    <ReactQuill
                      theme="snow"
                      value={emailContent}
                      onChange={setEmailContent}
                      modules={quillModules}
                      formats={quillFormats}
                      style={{ height: '300px', marginBottom: '50px' }}
                    />
                  </div>
                )}
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Attachment (Optional)</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="file"
                    onChange={handleFileAttachment}
                    disabled={attachment !== null}
                    className="me-2"
                  />
                  {attachment && (
                    <Button 
                      variant="outline-danger" 
                      size="sm"
                      onClick={handleRemoveAttachment}
                    >
                      Remove
                    </Button>
                  )}
                </div>
                {attachment && (
                  <div className="mt-2 text-muted small">
                    <strong>Selected file:</strong> {attachment.name} ({Math.round(attachment.size / 1024)} KB)
                  </div>
                )}
                <Form.Text className="text-muted">
                  Maximum file size: 5MB
                </Form.Text>
              </Form.Group>
              
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <Badge bg="info" className="me-2">
                    Recipients: {recipientCount}
                  </Badge>
                  <span className="text-muted small">
                    {
                      sendToTrialOnly 
                        ? 'Sending to trial users only' 
                        : sendToPremiumOnly 
                          ? 'Sending to premium users only' 
                          : sendToFreeOnly
                            ? 'Sending to free users only'
                            : 'Sending to all users'
                    }
                  </span>
                </div>
                <Button 
                  variant="primary" 
                  type="submit"
                  disabled={emailLoading || !emailSubject || !emailContent}
                >
                  {emailLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Sending...
                    </>
                  ) : (
                    'Send Email Blast'
                  )}
                </Button>
              </div>
            </Form>
          </Tab>

          <Tab eventKey="changelogs" title="Changelogs">
            <h5>Changelogs</h5>
            <p className="text-muted">View and manage system changelogs.</p>
            
            <Card className="mb-4">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h6 className="mb-0">Changelogs</h6>
                <Button 
                  variant="primary" 
                  size="sm" 
                  onClick={handleCreateChangelog}
                >
                  <i className="bi bi-plus-circle me-2"></i>
                  New Changelog
                </Button>
              </Card.Header>
              <Card.Body>
                <div className="changelog-list">
                  {changelogs.map(changelog => (
                    <div key={changelog.id} className="changelog-item mb-3 p-3 border rounded">
                      <div className="d-flex justify-content-between align-items-start">
                        <h6 className="mb-2">{changelog.title}</h6>
                        <small className="text-muted">
                          {new Date(changelog.createdAt).toLocaleDateString()}
                        </small>
                      </div>
                      <div className="changelog-preview">
                        {changelog.content.substring(0, 150)}...
                      </div>
                      <div className="mt-2">
                        <Button 
                          variant="outline-secondary" 
                          size="sm" 
                          onClick={() => {
                            setArticleData(changelog);
                            setShowArticleModal(true);
                          }}
                          className="me-2"
                        >
                          <i className="bi bi-pencil me-1"></i>
                          Edit
                        </Button>
                        <Button 
                          variant="outline-danger" 
                          size="sm" 
                          onClick={() => handleDeleteChangelog(changelog.id)}
                        >
                          <i className="bi bi-trash me-1"></i>
                          Delete
                        </Button>
                      </div>
                    </div>
                  ))}
                  {changelogs.length === 0 && (
                    <div className="text-center text-muted py-3">
                      No changelogs yet
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>
      </Card.Body>
      
      <ArticleModal
        show={showArticleModal}
        onHide={() => setShowArticleModal(false)}
        articleData={articleData}
        onSave={handleSaveChangelog}
        type="changelog"
        postToUpdates={postToUpdates}
        setPostToUpdates={setPostToUpdates}
      />
    </Card>
  );
};

export default AdminTools; 